import { themeGet } from '@styled-system/theme-get'
import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import { border, color, compose, space, textStyle } from 'styled-system'
import { useFileTypeIcon } from '../FileType/useFileTypeIcon'
import formatDate from '../../../Utils/formatDate'
import { DocumentFormData, DocumentType } from './AddDocumentForm'

type DocumentFileProps = {
  formData: DocumentFormData
  type: DocumentType
}

export function DocumentFile({
  formData,
  type,
}: DocumentFileProps): JSX.Element {
  const { mime, body } = formData
  const FileTypeIcon = useFileTypeIcon({ mime })

  if (type !== DocumentType.FILE || !(body instanceof Blob)) {
    return <Fragment />
  }

  return (
    <>
      <FileInfoContainer>
        <FileTypeIcon width="120px" height="120px" />
        <p className="filename">{body.name}</p>
        <p className="last-modified">
          Modified on {formatDate(body.lastModified)}
        </p>
      </FileInfoContainer>
    </>
  )
}

const FileInfoContainer = styled.div.attrs({
  p: 's',
  border: '1px solid',
  borderColor: 'border.neutral.light',
  bg: 'bg.secondary',
  color: 'text.default',
  textStyle: 'form.disabled',
})`
  ${compose(space, border, color, textStyle)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 240px;

  :hover {
    background-color: white;
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.secondary.dark')};
    outline: none;
  }

  .filename {
    ${themeGet('textStyles.subtitle.1')};
    margin-bottom: ${themeGet('space.xxs')}px;
  }

  .last-modified {
    ${themeGet('textStyles.subtitle.caption')}
  }
`
