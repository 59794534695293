import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  FrameworkFilter,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  ListPoliciesRequest,
  PolicyFrameworkFilter,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { NONE_ID } from 'src/Utils/globalConstants'
import { FRAMEWORK_MODEL_IDS } from 'src/Utils/globalEnums'

export const applyFrameworkFilter = (
  request: ListPoliciesRequest | ListControlsRequest,
  complianceFrameworkId: FRAMEWORK_MODEL_IDS,
  filterParams: string[],
  modelType: MODEL_TYPE.CONTROL | MODEL_TYPE.POLICY,
): void => {
  const isControl = modelType === MODEL_TYPE.CONTROL
  const filter = isControl ? new FrameworkFilter() : new PolicyFrameworkFilter()

  filter.setComplianceFrameworkId(complianceFrameworkId)
  const frameworkIds = new Set(filterParams)
  if (frameworkIds.has(NONE_ID)) {
    frameworkIds.delete(NONE_ID)
    filter.setIncludeNoFramework(new BoolValue().setValue(true))
  }
  filter.setFrameworkIdsList(Array.from(frameworkIds))
  request.addFrameworkFilters(filter)
}
