import { ErrorSVG } from 'src/components/Icons/Basic'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { compose, textStyle } from 'styled-system'

export const DisconnectedLinkerIcon = styled(ErrorSVG)`
  path {
    fill: ${palette.magenta['600']};
  }
  height: 20px;
  width: 20px;
`

export const LinkerErrorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`

export const StyledLinkerError = styled.p`
  ${compose(textStyle)}
  color: ${palette.magenta['600']};
`
