import React from 'react'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { CheckSVG } from 'src/components/Icons/Basic'
import { GridHeader, GridRow } from 'src/components/Reusable/Grid'
import {
  SecondaryButton,
  StandardButtonSize,
} from 'src/components/Reusable/Buttons'
import { formatTimestamp } from 'src/Utils/formatDate'
import { useInAudit } from 'src/context/AuditContext'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { EVIDENCE_AUDIT_HEADERS } from './RelevantDateForm.constants'
import {
  EvidenceAuditGridColumn,
  RelevantDateErrorIcon,
  StyledEvidenceAuditGrid,
  StyledEvidenceAuditGridRow,
} from './RelevantDateForm.styles'

export const EvidenceAuditGridHeader = (): JSX.Element => (
  <GridRow>
    {EVIDENCE_AUDIT_HEADERS.map((header) => (
      <GridHeader key={header}>{header}</GridHeader>
    ))}
  </GridRow>
)

export const EvidenceAuditGridRow = ({
  audit,
  isInclusive,
  onSelectAuditStart,
  onSelectAuditEnd,
  currentAuditId,
}: {
  audit: AuditRecord
  isInclusive: boolean
  onSelectAuditStart: (auditId: string) => void
  onSelectAuditEnd: (auditId: string) => void
  currentAuditId?: string
}): JSX.Element => {
  const auditId = audit.getId()
  const startDate = audit.getStartDate() || new Timestamp()
  const endDate = audit.getEndDate() || new Timestamp()
  const currentAuditNotInclusive = currentAuditId === auditId && !isInclusive
  return (
    <StyledEvidenceAuditGridRow>
      <EvidenceAuditGridColumn>{audit.getName()}</EvidenceAuditGridColumn>
      <EvidenceAuditGridColumn>{`${formatTimestamp(
        startDate,
        DATE_FORMATS.ISO,
      )} to ${formatTimestamp(
        endDate,
        DATE_FORMATS.ISO,
      )}`}</EvidenceAuditGridColumn>
      <EvidenceAuditGridColumn isCentered>
        {isInclusive && <CheckSVG />}
        {currentAuditNotInclusive && (
          <Tooltip
            id={`audit-${auditId}-not-inclusive`}
            placement={TooltipPositions.top}
            tooltipBody={`The selected relevant date is outside of the current audit's date range.`}
            overlayType={TooltipOverlayType.popover}
          >
            <RelevantDateErrorIcon />
          </Tooltip>
        )}
      </EvidenceAuditGridColumn>
      <EvidenceAuditGridColumn isCentered>
        <SecondaryButton
          onClick={() => onSelectAuditStart(audit.getId())}
          text="Set Relevant Date"
          size={StandardButtonSize.SMALL}
        />
      </EvidenceAuditGridColumn>
      <EvidenceAuditGridColumn isCentered>
        <SecondaryButton
          onClick={() => onSelectAuditEnd(audit.getId())}
          text="Set Relevant Date"
          size={StandardButtonSize.SMALL}
        />
      </EvidenceAuditGridColumn>
    </StyledEvidenceAuditGridRow>
  )
}

export const EvidenceAuditGrid = ({
  audits,
  inclusiveAudits,
  onSelectAuditStart,
  onSelectAuditEnd,
}: {
  audits: AuditRecord[]
  inclusiveAudits: string[]
  onSelectAuditStart: (auditId: string) => void
  onSelectAuditEnd: (auditId: string) => void
}): JSX.Element => {
  const { auditId } = useInAudit()
  return (
    <StyledEvidenceAuditGrid>
      <EvidenceAuditGridHeader />
      {audits.map((audit: AuditRecord) => (
        <EvidenceAuditGridRow
          key={audit.getId()}
          audit={audit}
          isInclusive={inclusiveAudits.includes(audit.getId())}
          onSelectAuditStart={onSelectAuditStart}
          onSelectAuditEnd={onSelectAuditEnd}
          currentAuditId={auditId}
        />
      ))}
    </StyledEvidenceAuditGrid>
  )
}
