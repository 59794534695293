import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ThrobberContext } from './ThrobberContext'

export function Throbber(): JSX.Element {
  const { throbberState } = useContext(ThrobberContext)

  return (
    <Modal
      show={throbberState.isShown}
      centered
      size="xl"
      backdrop="static"
      backdropClassName="throbber"
      contentClassName="throbber"
      keyboard={false}
    >
      {throbberState.Display}
    </Modal>
  )
}
