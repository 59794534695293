import { useCallback } from 'react'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import {
  DismissRequest,
  GetPolicyControlsRequest,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { useAuthorizedGrpcClient } from '../../../adapter'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { useInvalidatePoliciesCache } from './usePolicies'
import { usePolicy } from './usePolicy'

/**
 * useDeletePolicy
 * Disassociates the policy from all it's controls and
 * sets the policy out of scope.s
 * @param policyId  rowId
 */
export const useDeletePolicy = (policyId: string): (() => Promise<void>) => {
  const invalidatePoliciesCache = useInvalidatePoliciesCache()
  const { mutate } = usePolicy(policyId)
  const policyClient = useAuthorizedGrpcClient(PolicyPromiseClient)
  const { response: totalControls } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.getControls,
    new GetPolicyControlsRequest().setId(policyId),
    Boolean(policyId),
  )

  return useCallback(async () => {
    const controlIds = totalControls.data?.getItemList() ?? []
    if (controlIds.length > 0) {
      const request = new StringList().setItemList(controlIds)
      await policyClient.disassociateControlFromPolicy(request)
    }
    const request = new DismissRequest().setId(policyId).setDismiss(true)
    await policyClient.dismiss(request)
    // invalidate policies cache
    await invalidatePoliciesCache()
    await mutate()
  }, [
    invalidatePoliciesCache,
    mutate,
    policyClient,
    policyId,
    totalControls.data,
  ])
}
