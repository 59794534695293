import React from 'react'
import {
  ShowPageBody,
  ShowPageItem,
} from '../../../components/PageLayout/ShowPage'
import { ServicesDescriptionContainer } from '../services.styles'
import {
  ProviderSection,
  ReceptorSection,
  ServiceInstancesSection,
  ServiceRolesSection,
} from '../services.components'

export const ServicesShowPageContent = (): JSX.Element => (
  <ShowPageItem>
    <ShowPageBody>
      <ServicesDescriptionContainer>
        <ServiceRolesSection />
        <ReceptorSection />
        <ProviderSection />
        <ServiceInstancesSection />
      </ServicesDescriptionContainer>
    </ShowPageBody>
  </ShowPageItem>
)
