import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { TextButton } from '../Buttons/TextButton'

export const BannerContainer = styled.div<{
  $isWarning?: boolean
  $noMargin?: boolean
}>`
  background-color: ${({ $isWarning }) =>
    $isWarning ? palette.orange[900] : palette.magenta[500]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${({ $noMargin }) => ($noMargin ? '0px' : '15px 20px 0px 20px')};
  padding: 8px;

  p,
  a {
    color: ${palette.neutral.white};
  }

  a {
    text-decoration: none;
    font-weight: 500;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const BannerInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
`

export const StyledInternalLink = styled(Link)`
  color: ${palette.neutral
    .white} !important; // not sure where this is getting overwritten
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

export const BannerDismiss = styled(TextButton)`
  color: ${palette.neutral.white};
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${palette.neutral.white};
  }
`
