import { Dispatch } from 'react'

export enum GridColumnSortControls {
  DEPARTMENT = 'department',
  ID = 'id',
  NAME = 'name',
  AUTOMATED_EVIDENCE = 'automated_evidence',
  MANUAL_EVIDENCE = 'manual_evidence',
  ASSIGNEE = 'assignee',
  POLICY_MATCH = 'policy_match',
  COMPLETENESS = 'completeness',
  SPOT_CHECK = 'spot_check',
}

export enum GridColumnSortPolicies {
  DEPARTMENT = 'department',
  NAME = 'name',
  CONTROLS = 'controls',
  DOCUMENTATION = 'documentation',
  ASSIGNEE = 'assignee',
}

export enum GridColumnSortRequests {
  EVIDENCE = 'evidence',
  CONTROLS = 'controls',
}

export enum GridColumnSortVendors {
  NAME = 'name',
  RISK_TIER = 'risk_tier',
  ATTESTATION = 'attestation',
  RISKS_COUNT = 'risks_count',
}

export enum GridColumnSortEvidence {
  NAME = 'name',
  RELEVANT_DATE = 'relevant_date',
  CREATED_AT = 'created_at',
}

export enum GridColumnSortType {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum GridColumnSortParams {
  SORT_BY = 'sort_by',
  SORT_COL = 'sort_col',
}

export interface GridColumnSortActionControls {
  type: GridColumnSortType
  colName: GridColumnSortControls
}

export interface GridColumnSortActionPolicies {
  type: GridColumnSortType
  colName: GridColumnSortPolicies
}

export interface GridColumnSortActionRequests {
  type: GridColumnSortType
  colName: GridColumnSortRequests
}

export interface GridColumnSortActionVendors {
  type: GridColumnSortType
  colName: GridColumnSortVendors
}

export interface GridColumnSortActionEvidence {
  type: GridColumnSortType
  colName: GridColumnSortEvidence
}

export interface GridColumnSortProps {
  colName:
    | GridColumnSortControls
    | GridColumnSortPolicies
    | GridColumnSortRequests
    | GridColumnSortVendors
    | GridColumnSortEvidence
  showSpinner: boolean
  currIcon: JSX.Element
  applySorting: (e: React.MouseEvent) => void
}

export interface GridColumnSortControlsProps
  extends GridColumnSortActionControls {
  isActive: boolean
  onSort: Dispatch<GridColumnSortActionControls>
}

export interface GridColumnSortPoliciesProps
  extends GridColumnSortActionPolicies {
  isActive: boolean
  onSort: Dispatch<GridColumnSortActionPolicies>
}

export interface GridColumnSortRequestsProps
  extends GridColumnSortActionRequests {
  isActive: boolean
  onSort: Dispatch<GridColumnSortActionRequests>
}

export interface GridColumnSortVendorsProps
  extends GridColumnSortActionVendors {
  isActive: boolean
  onSort: Dispatch<GridColumnSortActionVendors>
}
export interface GridColumnSortEvidenceProps
  extends GridColumnSortActionEvidence {
  isActive: boolean
  onSort: Dispatch<GridColumnSortActionEvidence>
}

export interface GridColumnSortState {
  type: GridColumnSortType
  icon: JSX.Element
}

interface GridColumnSortParamConfig {
  type: GridColumnSortType
  isActive: boolean
}

export type GridColumnSortConfigControls = {
  [key in GridColumnSortControls]: GridColumnSortParamConfig
}

export type GridColumnSortConfigPolicies = {
  [key in GridColumnSortPolicies]: GridColumnSortParamConfig
}
export type GridColumnSortConfigRequests = {
  [key in GridColumnSortRequests]: GridColumnSortParamConfig
}

export type GridColumnSortConfigVendors = {
  [key in GridColumnSortVendors]: GridColumnSortParamConfig
}

export type GridColumnSortConfigEvidence = {
  [key in GridColumnSortEvidence]: GridColumnSortParamConfig
}

// Generate the shared reducer state for the column header configs
export const GRID_COL_SORT_CONFIGS_CONTROLS = (
  sortBy?: GridColumnSortType,
  sortCol?: GridColumnSortControls,
): GridColumnSortConfigControls => {
  return Object.values(GridColumnSortControls).reduce((acc, ctrlColName) => {
    acc[ctrlColName] = {
      type: sortBy || GridColumnSortType.DESCENDING,
      isActive: sortCol === ctrlColName,
    }
    return acc
  }, {} as GridColumnSortConfigControls)
}

export const GRID_COL_SORT_CONFIGS_POLICIES = (
  sortBy?: GridColumnSortType,
  sortCol?: GridColumnSortPolicies,
): GridColumnSortConfigPolicies => {
  return Object.values(GridColumnSortPolicies).reduce((acc, ctrlColName) => {
    acc[ctrlColName] = {
      type: sortBy || GridColumnSortType.DESCENDING,
      isActive: sortCol === ctrlColName,
    }
    return acc
  }, {} as GridColumnSortConfigPolicies)
}

export const GRID_COL_SORT_CONFIGS_REQUESTS = (
  sortBy?: GridColumnSortType,
  sortCol?: GridColumnSortRequests,
): GridColumnSortConfigRequests => {
  return Object.values(GridColumnSortRequests).reduce((acc, reqColName) => {
    acc[reqColName] = {
      type: sortBy || GridColumnSortType.DESCENDING,
      isActive: sortCol === reqColName,
    }
    return acc
  }, {} as GridColumnSortConfigRequests)
}

export const GRID_COL_SORT_CONFIGS_VENDORS = (
  sortBy?: GridColumnSortType,
  sortCol?: GridColumnSortVendors,
): GridColumnSortConfigVendors => {
  return Object.values(GridColumnSortVendors).reduce((acc, colName) => {
    acc[colName] = {
      type: sortBy || GridColumnSortType.DESCENDING,
      isActive: sortCol === colName,
    }
    return acc
  }, {} as GridColumnSortConfigVendors)
}

export const GRID_COL_SORT_CONFIGS_EVIDENCE = (
  sortBy?: GridColumnSortType,
  sortCol?: GridColumnSortEvidence,
): GridColumnSortConfigEvidence => {
  return Object.values(GridColumnSortEvidence).reduce((acc, colName) => {
    acc[colName] = {
      type: sortBy || GridColumnSortType.DESCENDING,
      isActive: sortCol === colName,
    }
    return acc
  }, {} as GridColumnSortConfigEvidence)
}

export const SORT_ORDER = {
  [GridColumnSortType.ASCENDING]: 0,
  [GridColumnSortType.DESCENDING]: 1,
}
Object.freeze(SORT_ORDER)

export enum ControlsSortPrecedence {
  CONTROLS = 'controls',
  CONTROL_CHECKS = 'control_checks',
}
