import styled from 'styled-components/macro'
import { compose, margin, MarginProps, variant } from 'styled-system'

const sizeVariant = variant({
  prop: 'size',
  variants: {
    xs: {
      width: '12px',
      height: '12px',
      border: '2px solid',
    },
    s: {
      width: '16px',
      height: '16px',
      border: '2px solid',
    },
    m: {
      width: '32px',
      height: '32px',
      border: '3px solid',
    },
    l: {
      width: '64px',
      height: '64px',
      border: '6px solid',
    },
    xl: {
      width: '128px',
      height: '128px',
      border: '12px solid',
    },
    xxl: {
      width: '164px',
      height: '164px',
      border: '14px solid',
    },
  },
})

const colorVariant = variant({
  prop: 'color',
  variants: {
    primary: {
      color: 'fill.primary.light',
      borderColor: 'fill.primary.light',
    },
    secondary: {
      color: 'fill.secondary.light',
      borderColor: 'fill.secondary.light',
    },
  },
})

export type ThrobberSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'

export type ThrobberColor = 'primary' | 'secondary'

export type SpinnerProps = {
  color: ThrobberColor
  size: ThrobberSize
} & MarginProps

export const Spinner = styled.div<SpinnerProps>`
  ${compose(sizeVariant, colorVariant, margin)}
  display: inline-block;
  vertical-align: text-bottom;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
`
