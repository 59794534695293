import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from 'react'
import { ModalFormId } from 'src/components/ModalForms'
import { GlobalDragAndDropOverlay } from './DragAndDropOverlay'

type DragAndDropContextType = {
  files: File[]
  setFiles: (files: File[]) => void
  clearFiles: () => void
  targetModal: ModalFormId | null
  setTargetModal: (modalId: ModalFormId | null) => void
}

const DragAndDropContext = createContext<DragAndDropContextType>({
  files: [],
  setFiles: () => undefined,
  clearFiles: () => undefined,
  targetModal: null,
  setTargetModal: () => undefined,
})

// Hook to use the context
export const useDragAndDrop = (): DragAndDropContextType =>
  useContext(DragAndDropContext)

// Provider component
export const DragAndDropProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [files, setFilesState] = useState<File[]>([])
  const [targetModal, setTargetModalState] = useState<ModalFormId | null>(null)

  const setFiles = (newFiles: File[]) => {
    setFilesState(newFiles)
  }

  const clearFiles = () => {
    setFilesState([])
    setTargetModalState(null)
  }

  const setTargetModal = (modalId: ModalFormId | null) => {
    setTargetModalState(modalId)
  }

  const contextValue = useMemo(
    () => ({ files, setFiles, clearFiles, targetModal, setTargetModal }),
    [files, targetModal],
  )

  return (
    <DragAndDropContext.Provider value={contextValue}>
      <GlobalDragAndDropOverlay />
      {children}
    </DragAndDropContext.Provider>
  )
}
