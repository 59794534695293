import React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { GridColumn, GridRow } from 'src/components/Reusable/Grid'
import { DepartmentChip } from 'src/pages/Controls/DepartmentChip'
import { generatePermalink } from 'src/components/PageLayout/Permalink'
import { ControlPolicyInfo } from 'src/components/Reusable/ListItem/ListItem.components'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import {
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { DescriptionMarkup } from 'src/pages/Controls/IndexItem/DescriptionMarkup'
import { useControl } from 'src/components/async/model/control/useControl'
import { ControlModelId } from 'src/pages/Controls/IndexItem/ControlsListItem.components'
import { TitleText } from 'src/components/Reusable/Text/Title'
import { CenteredRiskGridColumn, PolicyGridColumn } from '../risks.styles'
import { RiskControlRowMenu } from '../menus'

export const RisksControlsGridRow = ({
  controlId,
  riskId,
}: {
  controlId: string
  riskId: string
}): JSX.Element => {
  const { data: control, isLoading } = useControl(controlId)

  const params = useParams()
  if (isLoading || !control) return <></>

  const objective = control.getObjective()
  const policyModelId = control.getPolicyId()
  const isNotApplicable = control.getStatus() === CONTROL_STATUS.NOTAPPLICABLE
  const departmentId = control.getDepartmentId()
  const modelId = control.getModelId()
  const name = control.getName()
  const permalink = generatePermalink({
    pageContext: params.pageContext as string,
    modelType: MODEL_TYPE.CONTROL,
    modelId,
    isInternalLink: true,
  })
  const controlName = `${modelId} ${name}`

  return (
    <GridRow id={`risks-controls-row-${modelId}`} as={Link} to={permalink}>
      <GridColumn>
        <DepartmentChip departmentId={departmentId} />
      </GridColumn>
      <GridColumn>
        <ControlModelId modelId={modelId} />
      </GridColumn>
      <PolicyGridColumn>
        <ControlPolicyInfo
          policyModelId={policyModelId}
          isControlNa={isNotApplicable}
        />
        <Tooltip
          id={`controls-index-row-tooltip-${modelId}`}
          placement={TooltipPositions.right}
          overlayType={TooltipOverlayType.popover}
          tooltipBody={<DescriptionMarkup objective={objective} />}
        >
          <TitleText>{name}</TitleText>
        </Tooltip>
      </PolicyGridColumn>
      <CenteredRiskGridColumn>
        <RiskControlRowMenu
          riskId={riskId}
          controlId={controlId}
          name={controlName}
        />
      </CenteredRiskGridColumn>
    </GridRow>
  )
}
