import { ReceptorID, ReceptorRecords } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { ReceptorPromiseClient } from '@trustero/trustero-api-web/lib/agent/receptor_grpc_web_pb'
import { useCallback } from 'react'
import { useSwrImmutableGrpc } from '../../useSwrImmutableGrpc'
import { useGrpcRevalidateByMethod } from '../../useGrpcMutate'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { ReceptorStaticHelper } from '../../../../context/Content/statichelpers'

export const useInvalidateReceptorsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await mutateFunc(ReceptorPromiseClient.prototype.getReceptors)
    await mutateFunc(ReceptorPromiseClient.prototype.getReceptor)
    await mutateFunc(ReceptorPromiseClient.prototype.listInProgressScans)
    await mutateFunc(ReceptorPromiseClient.prototype.getControlMappingsForReceptor)
  }, [mutateFunc])
}

export function useAccountReceptors(): GrpcResponse<ReceptorRecords> {
  const { response } = useSwrImmutableGrpc(
    ReceptorPromiseClient.prototype.getReceptors,
    new ReceptorID(),
  )

  return NewGrpcResponse(response)
}

export const useHasReceptorError = (): (() => boolean) => {
  const response = useAccountReceptors()

  return useCallback(() => {
    const receptors = response.data?.getReceptorsList()

    return !!receptors?.some(
      (receptor) =>
        ReceptorStaticHelper.isAccredited(receptor) &&
        receptor.getIsenabled() &&
        !receptor.getIscredvalid(),
    )
  }, [response.data])
}
