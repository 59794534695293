import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import { color } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { GridColumn } from '../../../../components/Reusable/Grid'
import { FixitSVG } from '../../../../components/Icons/Basic'

export const TestColumn = styled(GridColumn)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return defaultValidatorFn(prop) || ['to', 'replace'].includes(prop)
    },
  })
  .attrs({
    as: Link,
  })<LinkProps>`
  text-decoration: none;
`

export const StatusIndicator = styled.div<{ isPassing: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: ${(props) => {
    return props.isPassing
      ? themeGet('colors.fill.status.success.base')(props)
      : themeGet('colors.fill.status.error.base')(props)
  }};
`

type TextFixitIconProps = {
  didPass: boolean
  isAutomated: boolean
}

export function TestFixitIcon(props: TextFixitIconProps): JSX.Element {
  if (props.didPass || !props.isAutomated) {
    return <></>
  }
  return <FixitIcon />
}

const FixitIcon = styled.div.attrs({
  bg: 'fill.status.error.base',
  children: <FixitSVG width="12.5px" height="12.5px" />,
})`
  ${color}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
`

export const GridHeaderText = styled.p`
  margin-left: ${themeGet('space.m')}px;
`
