import styled from 'styled-components/macro'
import { FilterBarContainer } from '../../../components/Reusable/IndexPage/FilterBar/FilterBar.styles'

export const AutomatedControlsContainer = styled.div`
  max-height: 400px;
  margin-top: 16px;
  overflow-y: auto;
`

export const CustomReceptorButtonContainer = styled.div`
  padding: 16px;
`
export const CustomReceptorButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px;
  gap: 10px;
`

export const ReceptorsFilterBarContainer = styled(FilterBarContainer)`
  justify-content: start;
  margin-bottom: 1em;
`
