import React from 'react'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import { Tooltip, TooltipOverlayType, TooltipPositions } from './Tooltip'

export const InfoTooltip = ({
  id,
  body,
  placement,
  overlay,
}: {
  id: string
  body: string
  placement?: TooltipPositions
  overlay?: TooltipOverlayType
}): JSX.Element => (
  <Tooltip
    id={id}
    placement={placement || TooltipPositions.right}
    overlayType={overlay || TooltipOverlayType.popover}
    tooltipBody={body}
  >
    <InfoButton as="span" ml="xs" />
  </Tooltip>
)
