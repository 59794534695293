import {
  Control,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import {
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useInAudit } from 'src/context/AuditContext'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { useLookupControlContext } from '../../fetchingContext/LookupControlContext'
import { useControls, useInvalidateControlsCache } from './useControls'

export const mutateDependencies = async (
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
): Promise<void> => {
  await Promise.all([
    methodMutator(
      ModelStatsServicePromiseClient.prototype.listControlComplianceStats,
    ),
    methodMutator(ModelPromiseClient.prototype.getControlStats),
    methodMutator(ModelPromiseClient.prototype.listControlIds),
    methodMutator(ModelStatsServicePromiseClient.prototype.getTrusteroScore),
  ])
}

/**
 * @param controlModelId modelId
 * Can take control id (PK) or model id
 * Will only have instant lookup in the cache if model id is passed in
 * This is meant to always respect the current audit
 */
export const useControl = (controlModelId?: string, shouldFetch = true): GrpcResponse<Control> => {
  const { auditId } = useInAudit()
  const request = new ListControlsRequest()
  if (auditId) {
    request.setAuditId(new StringValue().setValue(auditId))
  }

  useControls(request, !!controlModelId && shouldFetch, {
    ignoreAuditContext: true,
  })
  const getControlFromCache = useLookupControlContext()
  const mutate = useInvalidateControlsCache()
  const swrResponse = getControlFromCache(controlModelId || '')

  return NewGrpcResponse({
    ...swrResponse,
    mutate,
  })
}

/**
 * @param controlModelId modelId
 * Can take control id (PK) or model id
 * Will only have instant lookup in the cache if model id is passed in
 */
export const useControlIgnoreAudit = (controlModelId?: string, shouldFetch = true): GrpcResponse<Control> => {
  const request = new ListControlsRequest()

  const response = useControls(request, !!controlModelId && shouldFetch, {
    ignoreAuditContext: true,
  }, undefined, undefined, true)
  const controls = response.data

  const control = controls?.getItemsList().find((c) => (c.getId() === controlModelId || c.getModelId() === controlModelId))

  return NewGrpcResponse({
    ...response,
    data: control,
  })
}
