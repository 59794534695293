import React from 'react'
import styled from 'styled-components/macro'
import {
  compose,
  textStyle,
  color,
  GridProps,
  grid,
  padding,
  border,
  margin,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { Link } from 'react-router-dom'

export type RteTypeSelectorProps = {
  links: {
    to: string
    Icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined
      }
    >
    title: string
    description: string
  }[]
}

export function RteTypeSelector({ links }: RteTypeSelectorProps): JSX.Element {
  return (
    <RteTypeSelectorContainer>
      <RteTypeSelectorHeader>
        How would you like to start?
      </RteTypeSelectorHeader>
      <RteTypeGrid gridTemplateColumns={`repeat(${links.length}, 1fr)`}>
        {links.map((link, idx) => {
          return (
            <TypeContainer key={idx} to={link.to}>
              <link.Icon width="48px" height="48px" />
              <TypeTitle>{link.title}</TypeTitle>
              <span>{link.description}</span>
            </TypeContainer>
          )
        })}
      </RteTypeGrid>
    </RteTypeSelectorContainer>
  )
}

const RteTypeSelectorContainer = styled.section.attrs({
  pt: '110px',
  px: 'xxl',
  pb: 'xxl',
  bg: 'white',
  color: 'text.low',
  textStyle: 'form.disabled',
  role: 'listbox',
})`
  ${compose(padding, color, textStyle)}
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  line-height: 24px;

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
  }
`

const RteTypeSelectorHeader = styled.h1.attrs({
  mb: 'xl',
  color: 'text.default',
  textStyle: 'header.3',
})`
  ${compose(margin, color, textStyle)}
`

const RteTypeGrid = styled.article<GridProps>`
  ${grid}
  display: grid;
  column-gap: 20px;
  width: 100%;
`

const TypeContainer = styled(Link)
  .attrs({
    p: 'm',
    border: '1px solid',
    borderColor: 'border.neutral.light',
    borderRadius: '2px',
    role: 'button',
    tabIndex: 0,
  })
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return (
        defaultValidatorFn(prop) ||
        ['reloadDocument', 'replace', 'state', 'to'].includes(prop)
      )
    },
  })`
  ${compose(padding, border, color, textStyle)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  appearance: none !important;
  background-color: white;
  color: inherit;

  :hover {
    background-color: ${(props) => props.theme.colors.bg.neutralHover};
    border-color: ${(props) => props.theme.colors.border.neutral.base};
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }

  > svg path {
    fill: ${themeGet('colors.text.icon.low')};
  }
`

const TypeTitle = styled.p.attrs({
  mt: 's',
  mb: 'm',
  textStyle: 'body.big',
})`
  ${compose(margin, textStyle)}
`
