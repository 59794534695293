import React, { useMemo, useContext } from 'react'
import queryString, { ParsedQuery } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { PERMISSIONS } from 'src/config/roleConfig'
import { FilterName, FilterParam } from '../FilterBar.types'

import { Spinner } from '../../../../../Throbber'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Chips/BasicChip'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { AuditContext } from '../../../../../context/AuditContext'
import { useAudits } from '../../../../async/model/audit/useAudit'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'

export const AuditFilterDropdown = (): JSX.Element => {
  const { auditId } = useContext(AuditContext)
  const location = useLocation()
  const { data, error, isLoading, isValidating } = useAudits()
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]

    const preprocessed = searchParams[FilterParam.AUDIT]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams])

  if (error || auditId) return <></>
  if (!data || isLoading || isValidating) {
    return (
      <>
        <Dropdown.Toggle
          as={FilterDropdownToggle}
          id={`${FilterName.AUDIT}-filter-dropdown`}
        >
          <p>{FilterName.AUDIT}</p>
          <Spinner size="s" color="primary" mx="xxs" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      </>
    )
  }

  const audits = data.getItemsList()

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        id={`${FilterName.AUDIT}-filter-dropdown`}
      >
        <p>{FilterName.AUDIT}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      <AuditDropdownMenu
        audits={audits}
        searchParams={searchParams}
        appliedFilters={appliedFilters}
      />
    </Dropdown>
  )
}

function AuditDropdownMenu({
  audits,
  searchParams,
  appliedFilters,
}: {
  audits: AuditRecord[]
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element {
  return (
    <Dropdown.Menu>
      {audits
        .filter((a) => !a.getIsClosed())
        .map((audit) => (
          <AuditItem
            key={audit.getId()}
            audit={audit}
            searchParams={searchParams}
            appliedFilters={appliedFilters}
          />
        ))}
      {!audits.length && <p style={{ textAlign: 'center' }}>No open audits.</p>}
    </Dropdown.Menu>
  )
}

function AuditItem({
  audit,
  searchParams,
  appliedFilters,
}: {
  audit: AuditRecord
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element {
  const navigate = useNavigate()

  const newAppliedFilters = new Set(appliedFilters)
  const isChecked = newAppliedFilters.has(audit.getId())

  isChecked
    ? newAppliedFilters.delete(audit.getId())
    : newAppliedFilters.add(audit.getId())

  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...searchParams,
        [FilterParam.AUDIT]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <FilterDropdownItem replace to={href}>
      <CheckBoxInput
        id={audit.getId()}
        label={audit.getName()}
        checked={isChecked}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={() => {
          navigate(href, { replace: true })
        }}
        tabIndex={-1}
        requiredPermissions={[PERMISSIONS.READ]}
      />
    </FilterDropdownItem>
  )
}
