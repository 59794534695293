import React, { Dispatch, SetStateAction } from 'react'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { SelectControlsRowItem } from 'src/components/Reusable/Forms/MultiSelectFromComponents/SelectControlsRowItem'
import { NoRecommendedTemplates } from 'src/components/ModalForms/Frameworks/AddFramework/NoRecommendedTemplates'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { SelectRisksRowItem } from 'src/components/Reusable/Forms/MultiSelectFromComponents/SelectRisksRowItem'
import { ModalDescription } from 'src/components/ModalForms/ModalForm.styles'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { SelectVendorsRowItem } from 'src/components/Reusable/Forms/MultiSelectFromComponents/SelectVendorsRowItem'

export const RiskControlsMultiSelectGridItems = ({
  controls,
  selectedControls,
  setSelectedControls,
}: {
  controls: Control[]
  selectedControls: string[]
  setSelectedControls: Dispatch<SetStateAction<string[]>>
}): JSX.Element =>
  controls.length ? (
    <>
      {controls.map((control) => (
        <SelectControlsRowItem
          key={control.getId()}
          control={control}
          setSelectedControlIds={setSelectedControls}
          selectedControlIds={selectedControls}
        />
      ))}
    </>
  ) : (
    <NoRecommendedTemplates templateType="controls" />
  )

export const ControlRisksMultiSelectGridItems = ({
  risks,
  selectedRisks,
  setSelectedRisks,
}: {
  risks: Risk[]
  selectedRisks: string[]
  setSelectedRisks: Dispatch<SetStateAction<string[]>>
}): JSX.Element =>
  risks.length ? (
    <>
      {risks.map((risk) => (
        <SelectRisksRowItem
          key={risk.getId()}
          risk={risk}
          setSelectedRiskIds={setSelectedRisks}
          selectedRiskIds={selectedRisks}
        />
      ))}
    </>
  ) : (
    <NoRecommendedTemplates templateType="risks" />
  )

export const RiskVendorsMultiSelectGridItems = ({
  vendors,
  selectedVendors,
  setSelectedVendors,
}: {
  vendors: VendorRecord[]
  selectedVendors: string[]
  setSelectedVendors: Dispatch<SetStateAction<string[]>>
}): JSX.Element =>
  vendors.length ? (
    <>
      {vendors.map((vendor) => (
        <SelectVendorsRowItem
          key={vendor.getId()}
          vendorId={vendor.getId()}
          vendorName={vendor.getName()}
          selectedVendorIds={selectedVendors}
          setSelectedVendorIds={setSelectedVendors}
        />
      ))}
    </>
  ) : (
    <NoRecommendedTemplates templateType="vendors" />
  )

export const ConfirmationBody = ({
  name,
  customId,
}: {
  name: string
  customId: string
}): JSX.Element => (
  <p>
    {customId} {name} will be deleted entirely for your organization.
  </p>
)

export const AdditionalRiskDetails = (): JSX.Element => (
  <ModalDescription>
    <b>Category</b> must use the following values
    <br />
    Customer Commitments, Vendor Management, Leadership Commitment, Systems and
    Technology Changes, Laws, Regulations, and Standards, Intellectual Property
    <br />
    <br />
    <b>Likelihood of Occurrence, Adverse Impact,</b> and <b>Residual Risk</b>{' '}
    must use the following values
    <br />
    Very Low, Low, Moderate, High, Very High
    <br />
    <br />
    <b>Response</b> must use the following values
    <br />
    Accept, Avoid, Mitigate, Share, Transfer
  </ModalDescription>
)
