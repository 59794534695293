import React from 'react'
import ContentLoader from 'react-content-loader'

export function MenuPlaceholder({
  width,
  height,
}: Pick<React.SVGAttributes<SVGElement>, 'width' | 'height'>): JSX.Element {
  return (
    <ContentLoader
      speed={0.5}
      width={width}
      height={height}
      viewBox="0 0 385 96"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="16" y="9" rx="0.25em" width="14" height="14" />
      <rect x="38" y="11.375" rx="0.25em" width="331" height="9.25" />
      <rect x="16" y="41" rx="0.25em" width="14" height="14" />
      <rect x="38" y="43.375" rx="0.25em" width="331" height="9.25" />
      <rect x="16" y="73" rx="0.25em" width="14" height="14" />
      <rect x="38" y="75.375" rx="0.25em" width="331" height="9.25" />
    </ContentLoader>
  )
}
