import React from 'react'
import { RootPage } from 'src/components/Reusable/RootPage'
import {
  SubRoutes,
  ROOT_ID_PARAM_TYPE,
} from '../../components/Reusable/RootPage/RootPage.constants'
import { RisksIndexPage } from './index/RisksIndexPage'
import { RisksShowPageRouter } from './show/RisksShowPageRouter'

export const RisksRootPage = (): JSX.Element => {
  return (
    <RootPage
      idParamType={ROOT_ID_PARAM_TYPE.RISK_ID}
      indexRoute={SubRoutes.INDEX}
      indexPage={<RisksIndexPage />}
      showRoute={SubRoutes.SHOW}
      showPage={<RisksShowPageRouter />}
    />
  )
}
