import React, { useMemo } from 'react'
import {
  GetServiceInstanceRequest,
  Instance,
} from '@trustero/trustero-api-web/lib/model/service_instance_pb'
import { ServiceInstancePromiseClient } from '@trustero/trustero-api-web/lib/model/service_instance_grpc_web_pb'
import { Message } from 'google-protobuf'
import { AsyncImmutableComponent } from '../../AsyncImmutableComponent'
import { GrpcCall, ResponseProps } from '../../utils'
import { BaseProps, OnUnpackedResponse } from '../../types'
import { useGrpcRevalidateByMethod } from '../../useGrpcMutate'

type Props<ExternalProps> = {
  id: string
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<
    ExternalProps,
    Instance,
    {
      instance: Instance
    }
  >
>

export function ServiceInstance<ExternalProps>(
  externalProps: Props<ExternalProps>,
): JSX.Element {
  const methodMutator = useGrpcRevalidateByMethod()
  const request = useMemo(
    () => new GetServiceInstanceRequest().setId(externalProps.id),
    [externalProps.id],
  )

  const onResponse = useMemo(
    () => (p: { props?: ExternalProps } & ResponseProps<Instance>) =>
      externalProps.child({
        instance: p.response,
        mutate: async () => {
          const instance = await p.mutate()
          if (!instance) {
            return
          }
          mutateDependencies(methodMutator)
          return instance
        },
        ...(p.props ?? {}),
      }),
    [externalProps, methodMutator],
  )

  return (
    <AsyncImmutableComponent
      {...externalProps}
      asyncCall={ServiceInstancePromiseClient.prototype.get}
      request={request}
      child={onResponse}
    />
  )
}

export async function mutate(
  id: string,
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
  methodRequestMutator: (
    asyncCall: GrpcCall<Message, Message>,
    request: Message,
  ) => Promise<Message>,
): Promise<void> {
  await Promise.all([
    methodRequestMutator(
      ServiceInstancePromiseClient.prototype.get as unknown as GrpcCall<
        Message,
        Message
      >,
      new GetServiceInstanceRequest().setId(id),
    ),
  ])
}

async function mutateDependencies(
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
): Promise<void> {
  await Promise.all([
    methodMutator(ServiceInstancePromiseClient.prototype.list),
    methodMutator(ServiceInstancePromiseClient.prototype.listIds),
  ])
}
