import React from 'react'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { Receptor } from 'src/xgenerated'
import { ReceptorsGridRows } from 'src/pages/Receptors/grid/ReceptorsGrid'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { ViewReceptorsModalContainer } from '../services.styles'

export const ViewReceptorsModal = ({
  serviceName,
  receptors,
}: {
  serviceName: string
  receptors: Receptor[]
}): JSX.Element => {
  const modalFormId = ModalFormId.VIEW_RECEPTORS
  const show = useIsShowModal(modalFormId)
  const hide = useHideModal({ modalId: modalFormId })

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={modalFormId}
      title={`Receptors for ${serviceName}`}
      hideButtons
    >
      <ViewReceptorsModalContainer>
        <ReceptorsGridRows receptors={receptors} />
      </ViewReceptorsModalContainer>
    </ModalForm>
  )
}
