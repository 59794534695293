import React, { useMemo } from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { ModelRecord } from '@trustero/trustero-api-web/lib/model/model_pb'
import { Message } from 'google-protobuf'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { GrpcCall, ResponseProps } from '../utils'
import { BaseProps, OnUnpackedResponse } from '../types'
import { AsyncImmutableComponent } from '../AsyncImmutableComponent'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../useGrpcMutate'

type Props<ExternalProps> = {
  modelId: string
  modelType: MODEL_TYPE
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<ExternalProps, ModelRecord, { model: ModelRecord }>
>

export function ModelComponent<ExternalProps>(
  props: Props<ExternalProps>,
): JSX.Element {
  const methodRequestMutator = useGrpcRevalidateByMethodRequest()
  const methodMutator = useGrpcRevalidateByMethod()
  const request = useMemo(
    () =>
      new ModelRecord().setModelid(props.modelId).setModeltype(props.modelType),
    [props.modelId, props.modelType],
  )

  const onResponse = useMemo(
    () => (p: { props?: ExternalProps } & ResponseProps<ModelRecord>) =>
      props.child({
        ...p,
        mutate: async () => {
          const result = await p.mutate()
          if (!result) {
            return
          }
          mutate(
            result.getModeltype(),
            result.getModelid(),
            methodMutator,
            methodRequestMutator,
          )
          return result
        },
        model: p.response,
      }),
    [methodRequestMutator, methodMutator, props],
  )

  return (
    <AsyncImmutableComponent
      {...props}
      asyncCall={ModelPromiseClient.prototype.getOrCreate}
      request={request}
      child={onResponse}
    />
  )
}

export async function mutate(
  modelType: MODEL_TYPE,
  modelId: string,
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
  methodRequestMutator: (
    asyncCall: GrpcCall<Message, Message>,
    request: Message,
  ) => Promise<Message>,
): Promise<void> {
  if (modelType === MODEL_TYPE.SERVICE) {
    const request = new ModelRecord()
      .setModelid(modelId)
      .setModeltype(MODEL_TYPE.SERVICE) as Message
    const asyncCall = ModelPromiseClient.prototype
      .getOrCreate as unknown as GrpcCall<Message, Message>
    await methodRequestMutator(asyncCall, request)
  }
}
