import React from 'react'
import {
  ATTESTATION_STATUS,
  RISK_LEVEL,
  VENDOR_SORT_COLUMN,
  VendorRecord,
} from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import palette from 'src/designSystem/variables/palette'
import { GridColumnSortVendors } from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import {
  AttestationFreshIcon,
  AttestationStaleIcon,
  AttestationStatusContainer,
} from './vendors.styles'

export enum RiskTierLabels {
  TIER1 = 'Tier 1',
  TIER2 = 'Tier 2',
  TIER3 = 'Tier 3',
  TIER4 = 'Tier 4',
}
export enum AttestationStatusLabels {
  NONE = 'None',
  STALE = 'Stale',
  FRESH = 'Fresh',
}

export const RISK_LEVEL_TO_LABEL: Record<RISK_LEVEL, RiskTierLabels> = {
  [RISK_LEVEL.VERY_LOW]: RiskTierLabels.TIER4,
  [RISK_LEVEL.LOW]: RiskTierLabels.TIER3,
  [RISK_LEVEL.MODERATE]: RiskTierLabels.TIER2,
  [RISK_LEVEL.HIGH]: RiskTierLabels.TIER1,
}

export const RISK_TIER_LABEL_TO_LEVEL: Record<RiskTierLabels, RISK_LEVEL> = {
  [RiskTierLabels.TIER1]: RISK_LEVEL.HIGH,
  [RiskTierLabels.TIER2]: RISK_LEVEL.MODERATE,
  [RiskTierLabels.TIER3]: RISK_LEVEL.LOW,
  [RiskTierLabels.TIER4]: RISK_LEVEL.VERY_LOW,
}

export const RISK_LEVEL_TO_COLOR: Record<RISK_LEVEL, string> = {
  [RISK_LEVEL.VERY_LOW]: palette.orange['30'],
  [RISK_LEVEL.LOW]: palette.orange['100'],
  [RISK_LEVEL.MODERATE]: palette.orange['200'],
  [RISK_LEVEL.HIGH]: palette.orange['400'],
}

export const RISK_LEVEL_SUMMARY: Record<RISK_LEVEL, string> = {
  [RISK_LEVEL.VERY_LOW]: 'Least risk exposure',
  [RISK_LEVEL.LOW]: 'Low risk exposure',
  [RISK_LEVEL.MODERATE]: 'Substantial risk exposure',
  [RISK_LEVEL.HIGH]: 'Highest risk exposure',
}

export const RISK_LEVEL_DESCRIPTIONS: Record<RiskTierLabels, string> = {
  [RiskTierLabels.TIER4]:
    'Least risk exposure, with almost no access to sensitive data or important infrastructure. For example, an online training platform.',
  [RiskTierLabels.TIER3]:
    'Low risk exposure, common for systems with limited access to business critical data, such as Applicant Tracking Systems.',
  [RiskTierLabels.TIER2]:
    'Substantial risk exposure, use this for systems that help you do work but would not cause downtime.',
  [RiskTierLabels.TIER1]:
    'Highest risk exposure, common for cloud providers and critical infrastructure.',
}

export const ATTESTATION_STATUS_TO_ICON: Record<
  ATTESTATION_STATUS,
  JSX.Element
> = {
  [ATTESTATION_STATUS.FRESH]: <AttestationFreshIcon />,
  [ATTESTATION_STATUS.STALE]: (
    <AttestationStatusContainer>
      <AttestationStaleIcon />
      Out of date
    </AttestationStatusContainer>
  ),
  [ATTESTATION_STATUS.NONE]: (
    <AttestationStatusContainer>
      <AttestationStaleIcon />
      None
    </AttestationStatusContainer>
  ),
}

export const ATTESTATION_LABEL_TO_STATUS: Record<
  AttestationStatusLabels,
  ATTESTATION_STATUS
> = {
  [AttestationStatusLabels.NONE]: ATTESTATION_STATUS.NONE,
  [AttestationStatusLabels.STALE]: ATTESTATION_STATUS.STALE,
  [AttestationStatusLabels.FRESH]: ATTESTATION_STATUS.FRESH,
}

export const VENDORS_HEADERS = Object.freeze({
  NAME: {
    name: 'Name',
    isCentered: false,
  },
  RISK_TIER: {
    name: 'Risk Tier',
    isCentered: true,
  },
  ATTESTATION: {
    name: 'Attestation',
    isCentered: true,
  },
  RELATED_RISKS: {
    name: 'Related Risks',
    isCentered: true,
  },
  MENU: {
    name: '', // Empty column for item menu
    isCentered: true,
  },
})

export const VENDORS_HEADERS_TO_SORT_COL: Record<
  string,
  GridColumnSortVendors
> = {
  [VENDORS_HEADERS.NAME.name]: GridColumnSortVendors.NAME,
  [VENDORS_HEADERS.RISK_TIER.name]: GridColumnSortVendors.RISK_TIER,
  [VENDORS_HEADERS.ATTESTATION.name]: GridColumnSortVendors.ATTESTATION,
  [VENDORS_HEADERS.RELATED_RISKS.name]: GridColumnSortVendors.RISKS_COUNT,
}

export const VENDOR_SORT_COLUMNS = Object.freeze({
  [GridColumnSortVendors.NAME]: VENDOR_SORT_COLUMN.NAME,
  [GridColumnSortVendors.RISK_TIER]: VENDOR_SORT_COLUMN.RISK_TIER,
  [GridColumnSortVendors.ATTESTATION]: VENDOR_SORT_COLUMN.ATTESTATION,
  [GridColumnSortVendors.RISKS_COUNT]: VENDOR_SORT_COLUMN.RISKS_COUNT,
})

// dummy data for testing, will be replaced with actual data from the API

export const TOTAL_VENDORS = Math.random()

export const dummyVendors: VendorRecord[] = [
  new VendorRecord()
    .setId('1')
    .setName('Vendor 1')
    .setRiskLevel(RISK_LEVEL.LOW)
    .setAttestationStatus(ATTESTATION_STATUS.FRESH)
    .setRisksCount(2),
  new VendorRecord()
    .setId('2')
    .setName('Vendor 2')
    .setRiskLevel(RISK_LEVEL.MODERATE)
    .setAttestationStatus(ATTESTATION_STATUS.STALE)
    .setRisksCount(1),
  new VendorRecord()
    .setId('3')
    .setName('Vendor 3')
    .setRiskLevel(RISK_LEVEL.HIGH)
    .setAttestationStatus(ATTESTATION_STATUS.FRESH)
    .setRisksCount(5),
  new VendorRecord()
    .setId('4')
    .setName('Vendor 4')
    .setRiskLevel(RISK_LEVEL.VERY_LOW)
    .setAttestationStatus(ATTESTATION_STATUS.NONE)
    .setRisksCount(0),
]

export const UPDATE_RISK_LEVEL_DELAY = 1000
