import React from 'react'
import log from 'loglevel'
import { Link, useParams } from 'react-router-dom'
import { VendorsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useVendor } from 'src/pages/Vendors/vendors.hooks'
import { SubjectContainer } from './Subject.styles'

export const VendorContent = ({
  vendorId,
  vendorName,
}: {
  vendorId: string
  vendorName?: string
}): JSX.Element => {
  const { data, isLoading, error } = useVendor(vendorId)
  const { pageContext } = useParams()

  if (!vendorName || isLoading) {
    return <></>
  } else if (!data || error) {
    log.error(`Error loading log entry for vendor id ${vendorId}`, error)
    return <></>
  }

  const url = `/${pageContext}/${VendorsAbsoluteRoutes.SHOW}/${vendorId}`
  const vendorExists = !!data.getId()

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {vendorExists ? <Link to={url}>{vendorName}</Link> : <>{vendorName}</>}
    </SubjectContainer>
  )
}
