// source: schedulemanager/schedulemanager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.schedulemanager.CreateUpdateScheduleRequest', null, global);
goog.exportSymbol('proto.schedulemanager.DeleteScheduleRequest', null, global);
goog.exportSymbol('proto.schedulemanager.GetScheduleRequest', null, global);
goog.exportSymbol('proto.schedulemanager.SCHEDULE_TYPE', null, global);
goog.exportSymbol('proto.schedulemanager.ScheduleRecord', null, global);
goog.exportSymbol('proto.schedulemanager.TickRequest', null, global);
goog.exportSymbol('proto.schedulemanager.TickResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.schedulemanager.TickRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.schedulemanager.TickRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.schedulemanager.TickRequest.displayName = 'proto.schedulemanager.TickRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.schedulemanager.TickResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.schedulemanager.TickResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.schedulemanager.TickResponse.displayName = 'proto.schedulemanager.TickResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.schedulemanager.GetScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.schedulemanager.GetScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.schedulemanager.GetScheduleRequest.displayName = 'proto.schedulemanager.GetScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.schedulemanager.ScheduleRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.schedulemanager.ScheduleRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.schedulemanager.ScheduleRecord.displayName = 'proto.schedulemanager.ScheduleRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.schedulemanager.CreateUpdateScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.schedulemanager.CreateUpdateScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.schedulemanager.CreateUpdateScheduleRequest.displayName = 'proto.schedulemanager.CreateUpdateScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.schedulemanager.DeleteScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.schedulemanager.DeleteScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.schedulemanager.DeleteScheduleRequest.displayName = 'proto.schedulemanager.DeleteScheduleRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.schedulemanager.TickRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.schedulemanager.TickRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.schedulemanager.TickRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.TickRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isdryrun: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.schedulemanager.TickRequest}
 */
proto.schedulemanager.TickRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.schedulemanager.TickRequest;
  return proto.schedulemanager.TickRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.schedulemanager.TickRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.schedulemanager.TickRequest}
 */
proto.schedulemanager.TickRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdryrun(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.schedulemanager.TickRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.schedulemanager.TickRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.schedulemanager.TickRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.TickRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsdryrun();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool isDryRun = 1;
 * @return {boolean}
 */
proto.schedulemanager.TickRequest.prototype.getIsdryrun = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.schedulemanager.TickRequest} returns this
 */
proto.schedulemanager.TickRequest.prototype.setIsdryrun = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.schedulemanager.TickResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.schedulemanager.TickResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.schedulemanager.TickResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.TickResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.schedulemanager.TickResponse}
 */
proto.schedulemanager.TickResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.schedulemanager.TickResponse;
  return proto.schedulemanager.TickResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.schedulemanager.TickResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.schedulemanager.TickResponse}
 */
proto.schedulemanager.TickResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.schedulemanager.TickResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.schedulemanager.TickResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.schedulemanager.TickResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.TickResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.schedulemanager.TickResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.schedulemanager.TickResponse} returns this
 */
proto.schedulemanager.TickResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.schedulemanager.GetScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.schedulemanager.GetScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.schedulemanager.GetScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.GetScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.schedulemanager.GetScheduleRequest}
 */
proto.schedulemanager.GetScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.schedulemanager.GetScheduleRequest;
  return proto.schedulemanager.GetScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.schedulemanager.GetScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.schedulemanager.GetScheduleRequest}
 */
proto.schedulemanager.GetScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (reader.readEnum());
      msg.setScheduleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.schedulemanager.GetScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.schedulemanager.GetScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.schedulemanager.GetScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.GetScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional SCHEDULE_TYPE schedule_type = 1;
 * @return {!proto.schedulemanager.SCHEDULE_TYPE}
 */
proto.schedulemanager.GetScheduleRequest.prototype.getScheduleType = function() {
  return /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.schedulemanager.SCHEDULE_TYPE} value
 * @return {!proto.schedulemanager.GetScheduleRequest} returns this
 */
proto.schedulemanager.GetScheduleRequest.prototype.setScheduleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.schedulemanager.ScheduleRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.schedulemanager.ScheduleRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.schedulemanager.ScheduleRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.ScheduleRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    scheduleType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastRunAt: (f = msg.getLastRunAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    nextRunAt: (f = msg.getNextRunAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastRunTaskId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.schedulemanager.ScheduleRecord}
 */
proto.schedulemanager.ScheduleRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.schedulemanager.ScheduleRecord;
  return proto.schedulemanager.ScheduleRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.schedulemanager.ScheduleRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.schedulemanager.ScheduleRecord}
 */
proto.schedulemanager.ScheduleRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (reader.readEnum());
      msg.setScheduleType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrequency(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastRunAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextRunAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastRunTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.schedulemanager.ScheduleRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.schedulemanager.ScheduleRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.schedulemanager.ScheduleRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.ScheduleRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScheduleType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLastRunAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNextRunAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastRunTaskId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.schedulemanager.ScheduleRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.schedulemanager.ScheduleRecord.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
*/
proto.schedulemanager.ScheduleRecord.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.schedulemanager.ScheduleRecord.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.schedulemanager.ScheduleRecord.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
*/
proto.schedulemanager.ScheduleRecord.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.schedulemanager.ScheduleRecord.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SCHEDULE_TYPE schedule_type = 4;
 * @return {!proto.schedulemanager.SCHEDULE_TYPE}
 */
proto.schedulemanager.ScheduleRecord.prototype.getScheduleType = function() {
  return /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.schedulemanager.SCHEDULE_TYPE} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.setScheduleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 frequency = 5;
 * @return {number}
 */
proto.schedulemanager.ScheduleRecord.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp last_run_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.schedulemanager.ScheduleRecord.prototype.getLastRunAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
*/
proto.schedulemanager.ScheduleRecord.prototype.setLastRunAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.clearLastRunAt = function() {
  return this.setLastRunAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.schedulemanager.ScheduleRecord.prototype.hasLastRunAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp next_run_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.schedulemanager.ScheduleRecord.prototype.getNextRunAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
*/
proto.schedulemanager.ScheduleRecord.prototype.setNextRunAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.clearNextRunAt = function() {
  return this.setNextRunAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.schedulemanager.ScheduleRecord.prototype.hasNextRunAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string last_run_task_id = 9;
 * @return {string}
 */
proto.schedulemanager.ScheduleRecord.prototype.getLastRunTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.schedulemanager.ScheduleRecord} returns this
 */
proto.schedulemanager.ScheduleRecord.prototype.setLastRunTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.schedulemanager.CreateUpdateScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.schedulemanager.CreateUpdateScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.CreateUpdateScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    parameters: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.schedulemanager.CreateUpdateScheduleRequest}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.schedulemanager.CreateUpdateScheduleRequest;
  return proto.schedulemanager.CreateUpdateScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.schedulemanager.CreateUpdateScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.schedulemanager.CreateUpdateScheduleRequest}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (reader.readEnum());
      msg.setScheduleType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrequency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.schedulemanager.CreateUpdateScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.schedulemanager.CreateUpdateScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.CreateUpdateScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getParameters();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional SCHEDULE_TYPE schedule_type = 1;
 * @return {!proto.schedulemanager.SCHEDULE_TYPE}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.getScheduleType = function() {
  return /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.schedulemanager.SCHEDULE_TYPE} value
 * @return {!proto.schedulemanager.CreateUpdateScheduleRequest} returns this
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.setScheduleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 frequency = 2;
 * @return {number}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.schedulemanager.CreateUpdateScheduleRequest} returns this
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string parameters = 3;
 * @return {string}
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.getParameters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.schedulemanager.CreateUpdateScheduleRequest} returns this
 */
proto.schedulemanager.CreateUpdateScheduleRequest.prototype.setParameters = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.schedulemanager.DeleteScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.schedulemanager.DeleteScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.schedulemanager.DeleteScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.DeleteScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.schedulemanager.DeleteScheduleRequest}
 */
proto.schedulemanager.DeleteScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.schedulemanager.DeleteScheduleRequest;
  return proto.schedulemanager.DeleteScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.schedulemanager.DeleteScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.schedulemanager.DeleteScheduleRequest}
 */
proto.schedulemanager.DeleteScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (reader.readEnum());
      msg.setScheduleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.schedulemanager.DeleteScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.schedulemanager.DeleteScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.schedulemanager.DeleteScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.schedulemanager.DeleteScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional SCHEDULE_TYPE schedule_type = 1;
 * @return {!proto.schedulemanager.SCHEDULE_TYPE}
 */
proto.schedulemanager.DeleteScheduleRequest.prototype.getScheduleType = function() {
  return /** @type {!proto.schedulemanager.SCHEDULE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.schedulemanager.SCHEDULE_TYPE} value
 * @return {!proto.schedulemanager.DeleteScheduleRequest} returns this
 */
proto.schedulemanager.DeleteScheduleRequest.prototype.setScheduleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.schedulemanager.SCHEDULE_TYPE = {
  AUDIT_SCAN: 0
};

goog.object.extend(exports, proto.schedulemanager);
