import React, { useState } from 'react'
import {
  DocumentRequest,
  DOCUMENT_REQUEST_STATUS,
} from '@trustero/trustero-api-web/lib/request/request_pb'
import { DOCUMENT_REQUEST_STATUS_TO_LABEL } from 'src/pages/Requests/requests.constants'
import { useAddOrUpdateDocumentRequest } from '../../../../../components/async/DocumentRequest/useDocumentRequests'
import useClickOutside from '../../../../../Utils/useClickOutside'
import {
  StatusDropdownContainer,
  StatusDropdownItem,
  StatusDropdownMenuContainer,
  StatusDropdownToggle,
} from './styles'

type RequestStatusDropdownProps = {
  request: DocumentRequest
  isDisabled: boolean
}

export const RequestStatusDropdown = ({
  request,
  isDisabled = false,
}: RequestStatusDropdownProps): JSX.Element => {
  const updateDocumentRequest = useAddOrUpdateDocumentRequest()
  const [isVisible, setIsVisible] = useState(false)
  const currentStatus = request.getStatus()
  const statusRef = useClickOutside(() =>
    setIsVisible(false),
  ) as React.RefObject<HTMLDivElement>

  const toggleVisibility = () => {
    setIsVisible((isVisible) => !isVisible)
    return
  }

  const selectStatus = async (newStatus: number) => {
    await updateDocumentRequest({
      id: request.getId(),
      status: newStatus,
    })
    setIsVisible(false)
  }

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    toggleVisibility()
  }

  return (
    <StatusDropdownContainer ref={statusRef}>
      <StatusDropdownToggle
        disabled={isDisabled}
        isVisible={isVisible}
        status={currentStatus}
        onClick={handleToggle}
      >
        {DOCUMENT_REQUEST_STATUS_TO_LABEL[currentStatus]}
      </StatusDropdownToggle>
      <StatusDropdownMenuContainer isVisible={isVisible}>
        {Object.values(DOCUMENT_REQUEST_STATUS).map((statusValue) => (
          <StatusDropdownItem
            key={statusValue}
            selected={currentStatus}
            status={statusValue as DOCUMENT_REQUEST_STATUS}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              selectStatus(statusValue as DOCUMENT_REQUEST_STATUS)
            }}
          />
        ))}
      </StatusDropdownMenuContainer>
    </StatusDropdownContainer>
  )
}
