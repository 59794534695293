import log from 'loglevel'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { useFetchDocumentBody } from 'src/components/async/document/useDocument'
import { downloadEvidenceTable } from './ViewEvidenceForm.utils'

export const useDownloadEvidenceTable = ({
  contentId,
  mime,
}: {
  contentId: string
  mime: string
}): (() => Promise<void>) => {
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  return async () => {
    try {
      const body = await fetchDocumentBody()
      if (!body || !(body instanceof Uint8Array)) {
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
        return
      }
      downloadEvidenceTable(body)
    } catch (err) {
      log.error('error when downloading evidence body', err)
      showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
    }
  }
}
