import React from 'react'
import { LogEntries } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { EmptyChangelog } from './Changelog.styles'

export const CHANGELOG_PAGE_SIZE = 10

export interface ChangeLogEntriesProps {
  entries: LogEntries
  itemsPerPage: number
  setLastPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmptyLog = ({ content }: { content: string }): JSX.Element => {
  return <EmptyChangelog>{content}</EmptyChangelog>
}
