import React from 'react'
import { Range } from 'react-date-range'
import {
  GetTestResultsRequest,
  LatestTestResult,
  TestResult,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { AsyncComponent } from 'src/components'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { GridRow } from 'src/components/Reusable/Grid'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { AsyncRowItemExpandableElement } from 'src/components/Reusable/Grid/Accordion/RowItemProp'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { testResultAsRowItem } from './TestGrid/testResult'

export const getEvidenceDateRangeForAudit = (audit: AuditRecord): Range => {
  return {
    startDate: audit.getStartDate()?.toDate(),
    endDate: audit.getEndDate()?.toDate(),
  }
}

export const getLatestTestResultsRowItems = (
  testResults: LatestTestResult[],
  props: {
    modelId: string
    isDismissed: boolean
  },
  timeRange?: TimeRange,
): unknown => {
  return testResults
    .filter((p) => p.getTestResult())
    .map((item) => {
      const request = new GetTestResultsRequest()
      if (timeRange) request.setCreationRange(timeRange)
      const testResult = item.getTestResult()
      if (!testResult) {
        return <></>
      }
      return {
        ...testResultAsRowItem(testResult, {
          isDeleteDisabled: props.isDismissed,
          timeRange,
        }),
        total: item.getTotal(),
        asyncCall: {
          RowItem: (rowProps) => {
            const getTestResultsRequest = request
              .setControlIdsList([testResult.getControlId()])
              .setCaption(new StringValue().setValue(testResult.getName()))
            return (
              <AsyncComponent
                request={getTestResultsRequest}
                asyncCall={TestingPromiseClient.prototype.getTestResults}
                child={({ response }) => (
                  <>
                    {response
                      .getTestresultsList()
                      .slice(1)
                      .map((p: TestResult, idx: number) => (
                        <GridRow key={`${idx}`}>
                          {testResultAsRowItem(p, {
                            isDeleteDisabled: props.isDismissed,
                            leftMargin: 'm',
                          }).RowItem({
                            gridRow: rowProps.gridRow
                              ? rowProps.gridRow + idx
                              : undefined,
                          })}
                        </GridRow>
                      ))}
                  </>
                )}
              />
            )
          },
        },
      } as AsyncRowItemExpandableElement
    })
}
