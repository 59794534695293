import React, {
  DetailedHTMLProps,
  FunctionComponent,
  ImgHTMLAttributes,
} from 'react'

import TrusteroLogoFullPath from './trustero_logo_full.svg'
import TrusteroLogoMiniPath from './trustero_logo_mini.svg'
import TrusteroLogoWhitePath from './trustero_logo_white.svg'
import EroLightPath from './ero_light.svg'
import EroDarkPath from './ero_dark.svg'
import EroDarkEastPath from './ero_dark_east.svg'
import EroDarkWordmarkPath from './ero_dark_wordmark.svg'
import UserEditedPath from './user_edited.svg'
import UserEditedWhitePath from './user_edited_white.svg'
import UserEditedFillPath from './user_edited_fill.svg'

export { ReactComponent as TrusteroLogoFullSVG } from './trustero_logo_full.svg'
export { ReactComponent as TrusteroLogoMiniSVG } from './trustero_logo_mini.svg'
export { ReactComponent as TrusteroLogoWhiteSVG } from './trustero_logo_white.svg'
export { ReactComponent as EroLightSVG } from './ero_light.svg'
export { ReactComponent as EroDarkSVG } from './ero_dark.svg'
export { ReactComponent as EroDarkEastSVG } from './ero_dark_east.svg'
export { ReactComponent as EroConcernedSVG } from './ero_concerned.svg'
export { ReactComponent as EroConcernedEastSVG } from './ero_concerned_east.svg'
export { ReactComponent as EroDarkWordmarkSVG } from './ero_dark_wordmark.svg'
export { ReactComponent as UserEditedSVG } from './user_edited.svg'
export { ReactComponent as UserEditedWhiteSVG } from './user_edited_white.svg'
export { ReactComponent as UserEditedFillSVG } from './user_edited_fill.svg'

export const TrusteroLogoFullImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={TrusteroLogoFullPath} {...props} />
}

export const TrusteroLogoWhiteImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={TrusteroLogoWhitePath} {...props} />
}

export const TrusteroLogoMiniImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={TrusteroLogoMiniPath} {...props} />
}

export const EroLightImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={EroLightPath} {...props} />
}

export const EroDarkImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={EroDarkPath} {...props} />
}

export const EroDarkEastImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={EroDarkEastPath} {...props} />
}

export const EroDarkWordmarkImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={EroDarkWordmarkPath} {...props} />
}

export const UserEditedImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={UserEditedPath} {...props} />
}

export const UserEditedWhiteImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={UserEditedWhitePath} {...props} />
}

export const UserEditedFillImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  return <img src={UserEditedFillPath} {...props} />
}

export { TrusteroLogoFullPath, TrusteroLogoMiniPath }
