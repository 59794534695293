import { SERVICE_ROLE } from '@trustero/trustero-api-web/lib/service/service_pb'

export type CustomServiceRoleRow = {
  name: string
  isNameValid: boolean
  roles: SERVICE_ROLE[]
  errorMessage: string
}

const TRUSTERO_SERVICE_MODEL_ID = 'trs0'
const CUSTOM_SERVICE_MODEL_ID = 'trs97'
export const excludedServiceIds = [
  TRUSTERO_SERVICE_MODEL_ID,
  CUSTOM_SERVICE_MODEL_ID,
]

export const SERVICES_HEADERS = Object.freeze({
  NAME: 'Name',
  RECEPTOR: 'Receptor',
  ROLE: 'How You Use It',
  DISMISS: '', // Empty column for delete icon
})

export const SERVICES_TAB_HEADERS = Object.freeze({
  NAME: 'Service Name',
  INSTANCES: 'Instances Detected',
  INCLUDE: '', // Empty column for include button
})

// Service Roles - We will need to update this as we add more roles

export enum ServiceRoleLabels {
  OTHER = 'Other',
  APPLICANT_TRACKING_SYSTEM = 'Applicant Tracking System',
  APPLICATION_LOGGING_MONITORING_AND_ALERTING = 'Application Logging, Monitoring, and Alerting',
  BACKGROUND_CHECKS = 'Background Checks',
  BUSINESS_PRODUCTIVITY_AND_COLLABORATION_SUITE = 'Business Productivity and Collaboration Suite',
  CLOUD_INFRASTRUCTURE_VULNERABILITY_SCANNING = 'Cloud Infrastructure Vulnerability Scanning',
  CLOUD_MALWARE_AND_THREAT_PREVENTION = 'Cloud Malware and Threat Prevention',
  CODE_REPOSITORY = 'Code Repository',
  CODE_SCANNING_AND_ANALYSIS = 'Code Scanning and Analysis',
  CONFIGURATION_MANAGEMENT = 'Configuration Management/Infrastructure as Code',
  CONFIGURATION_MANAGEMENT_MONITORING = 'Configuration Management and Monitoring',
  CONTRACT_MANAGEMENT = 'Contract Management',
  CRM = 'Customer Relationship Management (CRM)',
  CUSTOMER_SUPPORT_MANAGEMENT = 'Customer Support Management',
  EMPLOYEE_PERFORMANCE_MANAGEMENT = 'Employee Performance Management',
  HRIS = 'Human Resources Information System (HRIS)',
  IAM = 'Identity and Access Management',
  INCIDENT_MANAGEMENT_AND_COMMUNICATION = 'Incident Management and External Communication',
  INTERNAL_WIKI = 'Internal Wiki/Intranet',
  IT_REQUEST_MANAGEMENT = 'IT Request Management',
  MOBILE_DEVICE_MANAGEMENT = 'Mobile Device Management',
  NETWORK_PENETRATION_TESTING_SERVICE = 'Network Penetration Testing Service',
  SECURITY_AWARENESS_TRAINING = 'Security Awareness Training',
  SOFTWARE_DEVELOPMENT_PROJECT_MANAGEMENT = 'Software Development Project Management',
  TECHNICAL_OPERATIONAL_CHANGE_MANAGEMENT = 'Technical/Operational Change Management',
  INFRASTRUCTURE_COMPUTE = 'Infrastructure - Compute',
  INFRASTRUCTURE_NETWORK = 'Infrastructure - Network',
  INFRASTRUCTURE_STORAGE = 'Infrastructure - Storage',
  INFRASTRUCTURE_DATASTORE = 'Infrastructure - Datastore',
  CI_CD = 'CI/CD',
  FILE_STORAGE = 'File Storage',
  CLOUD_PLATFORM_IAM = 'Cloud Platform Identity and Access Management',
  APPLICATION_PLATFORM_AS_A_SERVICE = 'Application Platform as a Service',
  ENDPOINT_PROTECTION_AND_ANTIVIRUS = 'Endpoint Protection and Antivirus',
  CLOUD_SECURITY_POSTURE_MANAGEMENT = 'Cloud Security Posture Management',
  INTERNAL_SECURITY_EVENT_AND_INCIDENT_MANAGEMENT_COMMUNICATION = 'Internal Security Event and Incident Management Communication',
}

export const SERVICE_ROLE_TO_LABEL: Record<number, ServiceRoleLabels> = {
  [SERVICE_ROLE.OTHER]: ServiceRoleLabels.OTHER,
  [SERVICE_ROLE.APPLICANT_TRACKING_SYSTEM]:
    ServiceRoleLabels.APPLICANT_TRACKING_SYSTEM,
  [SERVICE_ROLE.APPLICATION_LOGGING_MONITORING_AND_ALERTING]:
    ServiceRoleLabels.APPLICATION_LOGGING_MONITORING_AND_ALERTING,
  [SERVICE_ROLE.BACKGROUND_CHECKS]: ServiceRoleLabels.BACKGROUND_CHECKS,
  [SERVICE_ROLE.BUSINESS_PRODUCTIVITY_AND_COLLABORATION_SUITE]:
    ServiceRoleLabels.BUSINESS_PRODUCTIVITY_AND_COLLABORATION_SUITE,
  [SERVICE_ROLE.CLOUD_INFRASTRUCTURE_VULNERABILITY_SCANNING]:
    ServiceRoleLabels.CLOUD_INFRASTRUCTURE_VULNERABILITY_SCANNING,
  [SERVICE_ROLE.CLOUD_MALWARE_AND_THREAT_PREVENTION]:
    ServiceRoleLabels.CLOUD_MALWARE_AND_THREAT_PREVENTION,
  [SERVICE_ROLE.CODE_REPOSITORY]: ServiceRoleLabels.CODE_REPOSITORY,
  [SERVICE_ROLE.CODE_SCANNING_AND_ANALYSIS]:
    ServiceRoleLabels.CODE_SCANNING_AND_ANALYSIS,
  [SERVICE_ROLE.CONFIGURATION_MANAGEMENT]:
    ServiceRoleLabels.CONFIGURATION_MANAGEMENT,
  [SERVICE_ROLE.CONFIGURATION_MANAGEMENT_MONITORING]:
    ServiceRoleLabels.CONFIGURATION_MANAGEMENT_MONITORING,
  [SERVICE_ROLE.CONTRACT_MANAGEMENT]: ServiceRoleLabels.CONTRACT_MANAGEMENT,
  [SERVICE_ROLE.CRM]: ServiceRoleLabels.CRM,
  [SERVICE_ROLE.CUSTOMER_SUPPORT_MANAGEMENT]:
    ServiceRoleLabels.CUSTOMER_SUPPORT_MANAGEMENT,
  [SERVICE_ROLE.EMPLOYEE_PERFORMANCE_MANAGEMENT]:
    ServiceRoleLabels.EMPLOYEE_PERFORMANCE_MANAGEMENT,
  [SERVICE_ROLE.HRIS]: ServiceRoleLabels.HRIS,
  [SERVICE_ROLE.IAM]: ServiceRoleLabels.IAM,
  [SERVICE_ROLE.INCIDENT_MANAGEMENT_AND_COMMUNICATION]:
    ServiceRoleLabels.INCIDENT_MANAGEMENT_AND_COMMUNICATION,
  [SERVICE_ROLE.INTERNAL_WIKI]: ServiceRoleLabels.INTERNAL_WIKI,
  [SERVICE_ROLE.IT_REQUEST_MANAGEMENT]: ServiceRoleLabels.IT_REQUEST_MANAGEMENT,
  [SERVICE_ROLE.MOBILE_DEVICE_MANAGEMENT]:
    ServiceRoleLabels.MOBILE_DEVICE_MANAGEMENT,
  [SERVICE_ROLE.NETWORK_PENETRATION_TESTING_SERVICE]:
    ServiceRoleLabels.NETWORK_PENETRATION_TESTING_SERVICE,
  [SERVICE_ROLE.SECURITY_AWARENESS_TRAINING]:
    ServiceRoleLabels.SECURITY_AWARENESS_TRAINING,
  [SERVICE_ROLE.SOFTWARE_DEVELOPMENT_PROJECT_MANAGEMENT]:
    ServiceRoleLabels.SOFTWARE_DEVELOPMENT_PROJECT_MANAGEMENT,
  [SERVICE_ROLE.TECHNICAL_OPERATIONAL_CHANGE_MANAGEMENT]:
    ServiceRoleLabels.TECHNICAL_OPERATIONAL_CHANGE_MANAGEMENT,
  [SERVICE_ROLE.INFRASTRUCTURE_COMPUTE]:
    ServiceRoleLabels.INFRASTRUCTURE_COMPUTE,
  [SERVICE_ROLE.INFRASTRUCTURE_NETWORK]:
    ServiceRoleLabels.INFRASTRUCTURE_NETWORK,
  [SERVICE_ROLE.INFRASTRUCTURE_STORAGE]:
    ServiceRoleLabels.INFRASTRUCTURE_STORAGE,
  [SERVICE_ROLE.INFRASTRUCTURE_DATASTORE]:
    ServiceRoleLabels.INFRASTRUCTURE_DATASTORE,
  [SERVICE_ROLE.CI_CD]: ServiceRoleLabels.CI_CD,
  [SERVICE_ROLE.FILE_STORAGE]: ServiceRoleLabels.FILE_STORAGE,
  [SERVICE_ROLE.CLOUD_PLATFORM_IAM]: ServiceRoleLabels.CLOUD_PLATFORM_IAM,
  [SERVICE_ROLE.APPLICATION_PLATFORM_AS_A_SERVICE]:
    ServiceRoleLabels.APPLICATION_PLATFORM_AS_A_SERVICE,
  [SERVICE_ROLE.ENDPOINT_PROTECTION_AND_ANTIVIRUS]:
    ServiceRoleLabels.ENDPOINT_PROTECTION_AND_ANTIVIRUS,
  [SERVICE_ROLE.CLOUD_SECURITY_POSTURE_MANAGEMENT]:
    ServiceRoleLabels.CLOUD_SECURITY_POSTURE_MANAGEMENT,
  [SERVICE_ROLE.INTERNAL_SECURITY_EVENT_AND_INCIDENT_MANAGEMENT_COMMUNICATION]:
    ServiceRoleLabels.INTERNAL_SECURITY_EVENT_AND_INCIDENT_MANAGEMENT_COMMUNICATION,
}

export const SERVICE_ROLE_LABEL_TO_ROLE: Record<
  ServiceRoleLabels,
  SERVICE_ROLE
> = {
  [ServiceRoleLabels.OTHER]: SERVICE_ROLE.OTHER,
  [ServiceRoleLabels.APPLICANT_TRACKING_SYSTEM]:
    SERVICE_ROLE.APPLICANT_TRACKING_SYSTEM,
  [ServiceRoleLabels.APPLICATION_LOGGING_MONITORING_AND_ALERTING]:
    SERVICE_ROLE.APPLICATION_LOGGING_MONITORING_AND_ALERTING,
  [ServiceRoleLabels.BACKGROUND_CHECKS]: SERVICE_ROLE.BACKGROUND_CHECKS,
  [ServiceRoleLabels.BUSINESS_PRODUCTIVITY_AND_COLLABORATION_SUITE]:
    SERVICE_ROLE.BUSINESS_PRODUCTIVITY_AND_COLLABORATION_SUITE,
  [ServiceRoleLabels.CLOUD_INFRASTRUCTURE_VULNERABILITY_SCANNING]:
    SERVICE_ROLE.CLOUD_INFRASTRUCTURE_VULNERABILITY_SCANNING,
  [ServiceRoleLabels.CLOUD_MALWARE_AND_THREAT_PREVENTION]:
    SERVICE_ROLE.CLOUD_MALWARE_AND_THREAT_PREVENTION,
  [ServiceRoleLabels.CODE_REPOSITORY]: SERVICE_ROLE.CODE_REPOSITORY,
  [ServiceRoleLabels.CODE_SCANNING_AND_ANALYSIS]:
    SERVICE_ROLE.CODE_SCANNING_AND_ANALYSIS,
  [ServiceRoleLabels.CONFIGURATION_MANAGEMENT]:
    SERVICE_ROLE.CONFIGURATION_MANAGEMENT,
  [ServiceRoleLabels.CONFIGURATION_MANAGEMENT_MONITORING]:
    SERVICE_ROLE.CONFIGURATION_MANAGEMENT_MONITORING,
  [ServiceRoleLabels.CONTRACT_MANAGEMENT]: SERVICE_ROLE.CONTRACT_MANAGEMENT,
  [ServiceRoleLabels.CRM]: SERVICE_ROLE.CRM,
  [ServiceRoleLabels.CUSTOMER_SUPPORT_MANAGEMENT]:
    SERVICE_ROLE.CUSTOMER_SUPPORT_MANAGEMENT,
  [ServiceRoleLabels.EMPLOYEE_PERFORMANCE_MANAGEMENT]:
    SERVICE_ROLE.EMPLOYEE_PERFORMANCE_MANAGEMENT,
  [ServiceRoleLabels.HRIS]: SERVICE_ROLE.HRIS,
  [ServiceRoleLabels.IAM]: SERVICE_ROLE.IAM,
  [ServiceRoleLabels.INCIDENT_MANAGEMENT_AND_COMMUNICATION]:
    SERVICE_ROLE.INCIDENT_MANAGEMENT_AND_COMMUNICATION,
  [ServiceRoleLabels.INTERNAL_WIKI]: SERVICE_ROLE.INTERNAL_WIKI,
  [ServiceRoleLabels.IT_REQUEST_MANAGEMENT]: SERVICE_ROLE.IT_REQUEST_MANAGEMENT,
  [ServiceRoleLabels.MOBILE_DEVICE_MANAGEMENT]:
    SERVICE_ROLE.MOBILE_DEVICE_MANAGEMENT,
  [ServiceRoleLabels.NETWORK_PENETRATION_TESTING_SERVICE]:
    SERVICE_ROLE.NETWORK_PENETRATION_TESTING_SERVICE,
  [ServiceRoleLabels.SECURITY_AWARENESS_TRAINING]:
    SERVICE_ROLE.SECURITY_AWARENESS_TRAINING,
  [ServiceRoleLabels.SOFTWARE_DEVELOPMENT_PROJECT_MANAGEMENT]:
    SERVICE_ROLE.SOFTWARE_DEVELOPMENT_PROJECT_MANAGEMENT,
  [ServiceRoleLabels.TECHNICAL_OPERATIONAL_CHANGE_MANAGEMENT]:
    SERVICE_ROLE.TECHNICAL_OPERATIONAL_CHANGE_MANAGEMENT,
  [ServiceRoleLabels.INFRASTRUCTURE_COMPUTE]:
    SERVICE_ROLE.INFRASTRUCTURE_COMPUTE,
  [ServiceRoleLabels.INFRASTRUCTURE_NETWORK]:
    SERVICE_ROLE.INFRASTRUCTURE_NETWORK,
  [ServiceRoleLabels.INFRASTRUCTURE_STORAGE]:
    SERVICE_ROLE.INFRASTRUCTURE_STORAGE,
  [ServiceRoleLabels.INFRASTRUCTURE_DATASTORE]:
    SERVICE_ROLE.INFRASTRUCTURE_DATASTORE,
  [ServiceRoleLabels.CI_CD]: SERVICE_ROLE.CI_CD,
  [ServiceRoleLabels.FILE_STORAGE]: SERVICE_ROLE.FILE_STORAGE,
  [ServiceRoleLabels.CLOUD_PLATFORM_IAM]: SERVICE_ROLE.CLOUD_PLATFORM_IAM,
  [ServiceRoleLabels.APPLICATION_PLATFORM_AS_A_SERVICE]:
    SERVICE_ROLE.APPLICATION_PLATFORM_AS_A_SERVICE,
  [ServiceRoleLabels.ENDPOINT_PROTECTION_AND_ANTIVIRUS]:
    SERVICE_ROLE.ENDPOINT_PROTECTION_AND_ANTIVIRUS,
  [ServiceRoleLabels.CLOUD_SECURITY_POSTURE_MANAGEMENT]:
    SERVICE_ROLE.CLOUD_SECURITY_POSTURE_MANAGEMENT,
  [ServiceRoleLabels.INTERNAL_SECURITY_EVENT_AND_INCIDENT_MANAGEMENT_COMMUNICATION]:
    SERVICE_ROLE.INTERNAL_SECURITY_EVENT_AND_INCIDENT_MANAGEMENT_COMMUNICATION,
}

export const SELECTED_ROLES_MAX = 3
export const UPDATE_SERVICE_DELAY = 1000

// Service Instances

export const SERVICE_INTANCES_HEADERS = Object.freeze({
  NAME: 'Instance Name',
  RECEPTOR: 'Receptor Nickname',
  SERVICE_ACCOUNT: 'Service Account',
  INCLUDE: 'Include',
})

export const RECEPTORS_SECTION_MAX_LENGTH = 3

export const PRIMARY_INFRASTRUCTURE_CSV_HEADERS = ['Role,Service(s)']

// This will need to be updated if we ever change which service roles are considered primary infrastructure
// We will also need to update the corresponding constant in service.go
export const PRIMARY_INFRA_ROLES: SERVICE_ROLE[] = [
  SERVICE_ROLE.CODE_REPOSITORY,
  SERVICE_ROLE.IAM,
  SERVICE_ROLE.INFRASTRUCTURE_COMPUTE,
  SERVICE_ROLE.INFRASTRUCTURE_NETWORK,
  SERVICE_ROLE.INFRASTRUCTURE_STORAGE,
  SERVICE_ROLE.INFRASTRUCTURE_DATASTORE,
]
