import React, { createContext, useReducer } from 'react'
import { contentReducer } from './Content/reducer'
import { Content, contentInitialState, Model } from './Content/defs'

export type { Model, Content }

export const ContentContext = createContext({
  content: contentInitialState(),
  contentDispatch: contentReducer,
})

export const ContentProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [content, contentDispatch] = useReducer(
    contentReducer as (s: Content, d: Content) => Content,
    contentInitialState(),
  )

  return (
    <ContentContext.Provider value={{ content, contentDispatch }}>
      {children}
    </ContentContext.Provider>
  )
}
export default ContentContext
