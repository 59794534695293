import styled from 'styled-components/macro'

export const ChangelogContainer = styled.article<{ $isWide?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 14px;
  button {
    ${({ $isWide }) =>
      $isWide
        ? `
    margin: 10px;
    width: max-content;
    align-self: center;
  `
        : `margin: 10px;`}
  }

  .log-item-container {
    padding: ${({ $isWide }) => ($isWide ? `8px 24px 4px` : `22px 22px 11px`)};
  }

  .log-item-details {
    ${({ $isWide }) =>
      !$isWide
        ? `
      flex-direction: column;
      `
        : `
        gap: 12px;  
        justify-content: center;
        align-items: center;
        p {
          margin: 0;
        }
      `}
  }
`

export const EntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChangelogHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #cbd4de;
`

export const ChangelogHeaderText = styled.span`
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
`

export const Logs = styled.ul`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  font-size: 12px;
  overflow-y: auto;
`

type LogItemContainerProps = {
  borderTop?: boolean
  borderBottom?: boolean
}

export const LogItemContainer = styled.div.attrs({
  className: 'log-item-container',
})<LogItemContainerProps>`
  display: flex;
  width: 100%;
  height: fit-content;
  border-top: ${(props) => {
    if (props.borderTop) return '1px solid rgba(151, 151, 151, 0.23)'
  }};

  :not(:last-child) {
    border-bottom: ${(props) => {
      if (props.borderBottom) return '1px solid rgba(151, 151, 151, 0.23)'
    }};
  }
`

export const LogDetails = styled.section.attrs({
  className: 'log-item-details',
})`
  display: flex;
  margin-left: 10px;
  text-align: left;
  overflow: hidden;
`

export const LogActor = styled.p`
  color: #1f2532;
  font-size: 14px;
  font-weight: 600;
`

export const LogText = styled.p`
  color: #1f2532;
  font-size: 14px;
  line-height: 21px;
`

export const LogTime = styled.p`
  position: relative;
  margin-top: 11px;
  color: #515d75;
  font-size: 12px;
  line-height: 18px;
`

export const EmptyChangelog = styled.div`
  margin: 0 auto;
  padding: 2rem 0;
`
