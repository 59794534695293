import React, { useEffect, useState } from 'react'
import { StyledTextInput } from './TextInput/styles'
import { EditOnClick } from './EditOnClick'
import { EditOnClickProps } from './inputs.constants'

type EditTextOnClickProps = Omit<EditOnClickProps, 'editMode' | 'onSave'> & {
  initialValue: string
  onSave: (newValue: string) => Promise<void>
}

export const EditTextOnClick = ({
  initialValue,
  onSave,
  children,
  onCancel,
}: EditTextOnClickProps): JSX.Element => {
  const [textValue, setTextValue] = useState<string>(initialValue)
  const onSaveFunc = async () => {
    await onSave(textValue)
  }

  useEffect(() => {
    setTextValue(initialValue)
  }, [initialValue])

  return (
    <EditOnClick
      isInline
      onSave={onSaveFunc}
      isEmpty={!textValue.length}
      onCancel={onCancel}
      editMode={
        <StyledTextInput
          onChange={(e) => setTextValue(e.target.value)}
          value={textValue}
          showError={false}
        />
      }
    >
      {children}
    </EditOnClick>
  )
}
