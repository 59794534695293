import { HeaderValues } from 'src/Utils/csv/csv.constants'

export const RISK_CSV_TEMPLATE = [
  {
    'Risk ID': 'R-0001',
    Threat: 'This is a sample risk',
    Category: 'Customer Commitments',
    'Predisposing Condition / Vulnerability':
      'This is a sample predisposing condition',
    'Asset at Risk': 'This is a sample asset at risk',
    'Impact to Customer': 'This is a sample impact to customer',
    'Likelihood of Occurrence': 'Very High',
    'Adverse Impact': 'Moderate',
    Response: 'Accept',
    'Risk Owner': '',
    'Residual Risk': 'High',
    'Control IDs': 'IM02, AU01',
  },
  {
    'Risk ID': 'R-0002',
    Threat: 'This is a sample risk',
    Category: 'Laws, Regulations, and Standards',
    'Predisposing Condition / Vulnerability':
      'This is a sample predisposing condition',
    'Asset at Risk': 'This is a sample asset at risk',
    'Impact to Customer': 'This is a sample impact to customer',
    'Likelihood of Occurrence': 'Low',
    'Adverse Impact': 'Moderate',
    Response: 'Accept',
    'Risk Owner': '',
    'Residual Risk': 'Low',
    'Control IDs': 'SD02, IAM03',
  },
]

export interface ImportRiskCSVRow {
  riskId: string
  threat: string
  category: string
  predisposingCondition: string
  assetAtRisk: string
  impactToCustomer: string
  likelihood: string
  impact: string
  inherentRisk: string
  response: string
  residualRisk: string
  riskOwner: string
  controlIds: string[]
}

export const RISK_FORMAT_TEXT =
  'Risk ID, Threat, Category, Predisposing Condition / Vulnerability, Asset at Risk, Impact to Customer, Likelihood of Occurrence, Adverse Impact, Response, Risk Owner, Residual Risk, Control IDs'

export const RISK_CSV_HEADERS: HeaderValues[] = [
  {
    input: 'Risk ID',
    output: 'riskId',
  },
  {
    input: 'Threat',
    output: 'threat',
  },
  {
    input: 'Category',
    output: 'category',
  },
  {
    input: 'Predisposing Condition / Vulnerability',
    output: 'predisposingCondition',
  },
  {
    input: 'Asset at Risk',
    output: 'assetAtRisk',
  },
  {
    input: 'Impact to Customer',
    output: 'impactToCustomer',
  },
  {
    input: 'Likelihood of Occurrence',
    output: 'likelihood',
  },
  {
    input: 'Adverse Impact',
    output: 'impact',
  },
  {
    input: 'Response',
    output: 'response',
  },
  {
    input: 'Risk Owner',
    output: 'riskOwner',
  },
  {
    input: 'Residual Risk',
    output: 'residualRisk',
  },
  {
    input: 'Control IDs',
    output: 'controlIds',
  },
]
