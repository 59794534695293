export enum LINKER_MODEL_IDS {
  GOOGLE_DRIVE = 'trl-gdrive',
}

export enum LINKER_LABELS {
  GOOGLE_DRIVE = 'Google Drive',
}

export const LinkerModelIdToLabel = Object.freeze({
  [LINKER_MODEL_IDS.GOOGLE_DRIVE]: LINKER_LABELS.GOOGLE_DRIVE,
})

type InjectorType = 'init' | 'loading' | 'loaded' | 'error'
export interface InjectorState {
  queue: Record<string, ((e: boolean) => void)[]>
  injectorMap: Record<string, InjectorType>
  scriptMap: Record<string, HTMLScriptElement>
}

export type StateType = {
  loaded: boolean
  error: boolean
}

export type PickerConfiguration = {
  cbFunc: (tokendata: {
    action: string
    docs: google.picker.DocumentObject[]
  }) => Promise<void>
  allowMultiselect: boolean
  viewId: google.picker.ViewId
  selectFolderEnabled?: boolean
}

export type PickerResponse = {
  isShown: boolean
  invalidCredentials: boolean
  exceptions: string
}

export const GOOGLE_PICKER_SUPPORTED_FILE_TYPES =
  'application/vnd.google-apps.folder,image/png,image/jpeg,.rtf,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pages,.csv,.xlsx,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,.pptx,.key,.numbers,application/vnd.google-apps.document,application/vnd.google-apps.spreadsheet,application/vnd.google-apps.presentation,application/pdf'
