import { useCallback } from 'react'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import {
  GetLatestTestResultRequest,
  GetTestResultsRequest,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import { ListControlComplianceStatsRequest } from '@trustero/trustero-api-web/lib/model/stats_pb'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../../components'

/**
 * ! Should be used every time TestResult is added or deleted from a Control
 *
 * Returns a function that invalidates all cache keys affiliated with
 * the fetching of test result list and evidence count for the given control
 *
 * This then triggers a refetch of a Control's
 * test result list and test result count
 * @returns Returns a function to invalidate cache keys
 */
export const useInvalidateOnTestResultMutation = (
  controlId: string,
  timeRange?: TimeRange,
): (() => Promise<unknown>) => {
  const grpcRevalidatedByMethodRequest = useGrpcRevalidateByMethodRequest()

  return useCallback(() => {
    return Promise.all([
      grpcRevalidatedByMethodRequest(
        TestingPromiseClient.prototype.getLatestTestResults,
        new GetLatestTestResultRequest()
          .setTimeRange(timeRange)
          .setControlIdsList([controlId]),
      ),
      grpcRevalidatedByMethodRequest(
        TestingPromiseClient.prototype.getTestResults,
        new GetTestResultsRequest().setControlIdsList([controlId]),
      ),
      grpcRevalidatedByMethodRequest(
        ModelStatsServicePromiseClient.prototype.listControlComplianceStats,
        new ListControlComplianceStatsRequest(),
      ),
    ])
  }, [controlId, grpcRevalidatedByMethodRequest, timeRange])
}

export const useHardTestInvalidation = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(TestingPromiseClient.prototype.getLatestTestResults),
      mutateFunc(TestingPromiseClient.prototype.getTestResults),
      mutateFunc(
        ModelStatsServicePromiseClient.prototype.listControlComplianceStats,
      ),
    ])
  }, [mutateFunc])
}
