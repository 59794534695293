import React, { useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { useConfirmationModal } from '../../../components/ModalForms'
import { MediumCancelButton } from '../../../components/Reusable/Buttons'
import { useRemoveControlFromAudit } from '../../../components/async/model/audit'
import { useDeleteControl } from '../../../components/async/model/control'
import {
  ConfirmationContext,
  initialConfirmationState,
} from '../../../Confirmation'
import { Spinner, ThrobberContext } from '../../../Throbber'
import { useInAudit } from '../../../context/AuditContext'
import { ControlsAbsoluteRoutes } from '../../../components/Reusable/RootPage/RootPage.constants'

const title = 'Delete This Control Entirely?'
const confirmText = 'Delete Control'

type useDeleteControlType = {
  control: Control.AsObject
  props?: { onCancel?: () => void }
}

/**
 * useRemoveControl
 *
 * removed
 * @param id row id, not model id
 * @param props
 */
export const useDeleteControlModal = ({
  control,
  control: { id, name },
  props,
}: useDeleteControlType): (() => void) => {
  const params = useParams() as { pageContext: string }
  const navigate = useNavigate()
  const { auditId } = useInAudit()
  const { setConfirmationState } = useContext(ConfirmationContext)
  const { setThrobberState } = useContext(ThrobberContext)

  const redirect = useCallback(
    () => navigate(`/${params.pageContext}/${ControlsAbsoluteRoutes.INDEX}`),
    [navigate, params.pageContext],
  )
  const onRemoveFromAudit = useRemoveControlFromAudit(id)
  const deleteControl = useDeleteControl({ control })
  const onConfirmCB = async () => {
    await deleteControl()
    redirect()
  }

  return useConfirmationModal({
    ...props,
    title,
    body: (
      <p>
        {name} will be deleted entirely from your organization, across all open
        audits.
      </p>
    ),
    confirmText,
    onConfirmCB,
    footer: auditId ? (
      <MediumCancelButton
        onClick={async () => {
          setThrobberState({
            isShown: true,
            Display: <Spinner color="primary" size="l" />,
          })
          await onRemoveFromAudit()
          redirect()
          setThrobberState({
            isShown: false,
            Display: <></>,
          })
          setConfirmationState(initialConfirmationState)
        }}
      >
        Only Remove it from this Audit
      </MediumCancelButton>
    ) : (
      <></>
    ),
    throbber: {
      color: 'primary',
      size: 'xl',
    },
  })
}
