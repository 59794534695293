import React from 'react'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { InfoTooltip } from 'src/components/Reusable/Tooltip/InfoTooltip'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import {
  ResidualRiskContainer,
  RiskMathField,
  RiskResponseContainer,
} from '../risks.styles'
import { RisksMathMenu, RisksTreatmentMenu } from '../menus'
import { RiskFields } from '../risks.constants'
import { getRiskMathCalculation } from '../risks.helpers'

export const RisksResponse = ({ risk }: { risk: Risk }): JSX.Element => {
  const riskId = risk.getId()
  const impact = risk.getAdverseImpact()
  const likelihood = risk.getLikelihood()
  const maxResidualRisk = getRiskMathCalculation(impact, likelihood)

  return (
    <RiskResponseContainer>
      <FlexRow>
        <label>Selected Treatment</label>
        <InfoTooltip
          id="selected_treatment"
          body="Show how you plan to deal with this risk."
        />
      </FlexRow>
      <RisksTreatmentMenu riskId={riskId} />
      <ResidualRiskContainer>
        <FlexRow justify={FlexAlign.FLEX_START}>
          <label>Residual Risk</label>
          <InfoTooltip
            id="residual_risk"
            body="Overall risk level after response. If you can mitigate, this will generally be lower than the inherent risk."
          />
        </FlexRow>
        <RiskMathField>
          <span>Risk after treatment</span>
          <RisksMathMenu
            riskId={riskId}
            field={RiskFields.riskAfterTreatment}
            maxValue={maxResidualRisk}
          />
        </RiskMathField>
      </ResidualRiskContainer>
    </RiskResponseContainer>
  )
}
