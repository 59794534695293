import React, { useCallback } from 'react'
import log from 'loglevel'
import {
  Risk,
  RISK_LINK_TYPE,
  RiskLink,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { RisksAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useNavigate, useParams } from 'react-router'
import { useConfirmationModal } from '../../../components/ModalForms'
import { useDeleteRisks, useToggleLinks } from '../risks.hooks'
import { ConfirmationBody } from './RiskModal.components'

export const useDeleteRiskModal = (risk: Risk): (() => void) => {
  const params = useParams() as { pageContext: string }
  const navigate = useNavigate()
  const deleteRisks = useDeleteRisks()

  const redirect = useCallback(
    () => navigate(`/${params.pageContext}/${RisksAbsoluteRoutes.INDEX}`),
    [navigate, params.pageContext],
  )

  const deleteRisk = async (): Promise<void> => {
    const riskIds = [risk.getId()]
    try {
      await deleteRisks(riskIds)
      redirect()
    } catch (err) {
      log.error(`Failed to delete risks: ${riskIds.join(', ')}`, err)
    }
  }

  return useConfirmationModal({
    title: 'Delete This Risk?',
    confirmText: 'Delete Risk',
    body: (
      <ConfirmationBody name={risk.getName()} customId={risk.getCustomId()} />
    ),
    onConfirmCB: deleteRisk,
    throbber: {
      color: 'primary',
      size: 'xl',
    },
  })
}

export const useUnlinkControlFromRiskModal = ({
  controlId,
  name,
  riskId,
}: {
  controlId: string
  riskId: string
  name: string
}): (() => void) => {
  const { controlModelId } = useParams()
  const unlinkControls = useToggleLinks()
  const isControlPage = controlModelId && controlModelId.length
  const title = isControlPage ? 'Unlink Risk from Control?' : 'Unlink Control?'
  const confirm = isControlPage ? 'Unlink Risk' : 'Unlink Control'

  const links = [
    new RiskLink()
      .setId(controlId)
      .setRiskId(riskId)
      .setModelType(MODEL_TYPE.CONTROL),
  ]
  const onConfirmCB = async () => {
    unlinkControls({ links, type: RISK_LINK_TYPE.UNLINK })
  }

  return useConfirmationModal({
    title,
    body: <p>This will remove {name}.</p>,
    confirmText: confirm,
    onConfirmCB,
    throbber: {
      color: 'primary',
      size: 'xl',
    },
  })
}
