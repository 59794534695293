import React, { FormEventHandler, useEffect, useState } from 'react'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import {
  RISK_LINK_TYPE,
  RiskLink,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { useListControls } from 'src/pages/Controls/ControlsIndexPage/AddControl/BringIntoAudit'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { MultiSelectForm } from 'src/components/Reusable/Forms/MultiSelectForm'
import log from 'loglevel'
import { useToggleLinks } from '../risks.hooks'
import { AddControlsToRiskProps } from '../risks.constants'
import { RiskControlsMultiSelectGridItems } from './RiskModal.components'

const FORM_TITLE =
  'Associate controls with this risk. Select controls that help you mitigate or compensate for the risk.'
// This is mostly copy-pasta from AddControlsToRequestModal.tsx
// If you are making a similar component in the future, it's *probably* time to refactor and make it reusable ;)
export const AddControlsToRiskModal = ({
  existingControlIds,
  riskId,
}: AddControlsToRiskProps): JSX.Element => {
  const [selectedControls, setSelectedControls] = useState<string[]>([])
  const [availableControls, setAvailableControls] = useState<Control[]>([])
  const updateRequest = useToggleLinks()
  const onHide = useHideModal({})

  const response = useListControls({
    excludeFromAudit: false,
    isDismissed: false,
    complianceFrameworkIds: [],
    excludeNotApplicable: true,
  })

  useEffect(() => {
    const allControlsForAudit = response.data?.getItemsList() || []
    const controlsList = allControlsForAudit.filter(
      (elem: Control) => !existingControlIds.includes(elem.getId()),
    )
    setAvailableControls(controlsList)
  }, [response.loading, response.data, existingControlIds])

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    const links = selectedControls.map((controlId: string) =>
      new RiskLink()
        .setId(controlId)
        .setRiskId(riskId)
        .setModelType(MODEL_TYPE.CONTROL),
    )
    try {
      await updateRequest({
        type: RISK_LINK_TYPE.LINK, // we are currently ALWAYS adding links in this form
        links,
      })
      onHide()
    } catch (err) {
      log.error('Error adding control(s) to risk: ', err)
    } finally {
      setSelectedControls([])
    }
  }

  return (
    <ModalForm
      show={useIsShowModal(ModalFormId.LINK_CONTROL_TO_RISK)}
      hide={onHide}
      formId={ModalFormId.LINK_CONTROL_TO_RISK}
      size="xl"
      title="Link Risk to Controls"
      submitText="Link Controls"
    >
      <MultiSelectForm
        formId={ModalFormId.LINK_CONTROL_TO_RISK}
        title={FORM_TITLE}
        loading={response.isLoading}
        gridTemplateColumnStyling="min-content minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 1fr)"
        gridItems={
          <RiskControlsMultiSelectGridItems
            controls={availableControls}
            selectedControls={selectedControls}
            setSelectedControls={setSelectedControls}
          />
        }
        onSubmit={onSubmit}
      />
    </ModalForm>
  )
}
