import { QUESTIONNAIRE_ACCEPTANCE } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'

export enum QuestionnaireAcceptanceLabels {
  IN_PROGRESS = 'In Progress',
  ACCEPTED = 'Accepted',
}

export const QUESTIONNAIRE_STATUS_DROPDOWN = [
  QUESTIONNAIRE_ACCEPTANCE.IN_PROGRESS,
  QUESTIONNAIRE_ACCEPTANCE.ACCEPTED_QUESTIONNAIRE,
]

export const QUESTIONNAIRE_ACCEPTANCE_LABEL: Record<
  QUESTIONNAIRE_ACCEPTANCE,
  string
> = {
  [QUESTIONNAIRE_ACCEPTANCE.IN_PROGRESS]:
    QuestionnaireAcceptanceLabels.IN_PROGRESS,
  [QUESTIONNAIRE_ACCEPTANCE.ACCEPTED_QUESTIONNAIRE]:
    QuestionnaireAcceptanceLabels.ACCEPTED,
}

export const SEC_QUESTIONNAIRE_POLL = 1500
