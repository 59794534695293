import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import Dropdown from 'react-bootstrap/Dropdown'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { PERMISSIONS } from 'src/config/roleConfig'
import { FilterName, FilterParam } from '../FilterBar.types'
import { Spinner } from '../../../../../Throbber'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Chips/BasicChip'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { useComplianceFrameworks } from '../../../../async/complianceframework/useComplianceFrameworks'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'
import { useCurrentAudit } from '../../../../async/model/audit/useCurrentAudit'
import { isFullySupportedFramework } from '../../../../../Utils/globalHelpers'
import { FRAMEWORK_MODEL_IDS } from '../../../../../Utils/globalEnums'
import { FrameworkFilterDropdown } from './FrameworkFilterDropdown'

const ComplianceFrameworkItem = ({
  complianceFramework,
  searchParams,
  appliedFilters,
}: {
  complianceFramework?: ComplianceFramework
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element => {
  const navigate = useNavigate()

  const complianceFrameworkId = complianceFramework?.getId() ?? NONE_ID
  const complianceFrameworkName = complianceFramework?.getName() ?? NONE_NAME

  const newAppliedFilters = new Set(appliedFilters)
  const isChecked = newAppliedFilters.has(complianceFrameworkId)
  isChecked
    ? newAppliedFilters.delete(complianceFrameworkId)
    : newAppliedFilters.add(complianceFrameworkId)
  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...searchParams,
        [FilterParam.COMPLIANCE_FRAMEWORK]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )
  return (
    <FilterDropdownItem replace to={href}>
      <CheckBoxInput
        id={complianceFrameworkId}
        label={complianceFrameworkName}
        checked={isChecked}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={() => {
          navigate(href, { replace: true })
        }}
        tabIndex={-1}
        requiredPermissions={[PERMISSIONS.READ]}
      />
    </FilterDropdownItem>
  )
}

const ComplianceFrameworkDropdownMenu = ({
  searchParams,
  appliedFilters,
  complianceFrameworks,
}: {
  searchParams: ParsedQuery
  appliedFilters: string[]
  complianceFrameworks: ComplianceFramework[]
}): JSX.Element => {
  return (
    <Dropdown.Menu>
      <ComplianceFrameworkItem
        searchParams={searchParams}
        appliedFilters={appliedFilters}
      />
      {complianceFrameworks.map((complianceFramework) => (
        <ComplianceFrameworkItem
          key={complianceFramework.getId()}
          complianceFramework={complianceFramework}
          searchParams={searchParams}
          appliedFilters={appliedFilters}
        />
      ))}
    </Dropdown.Menu>
  )
}

const ComplianceFrameworkDropdown = ({
  appliedFilters,
  searchParams,
}: {
  appliedFilters: string[]
  searchParams: ParsedQuery
}): JSX.Element => {
  const { data, isLoading } = useComplianceFrameworks(true)
  if (!data || isLoading) {
    return (
      <>
        <Dropdown.Toggle
          as={FilterDropdownToggle}
          id={`${FilterName.COMPLIANCE_FRAMEWORK}-filter-dropdown`}
        >
          <p>{FilterName.COMPLIANCE_FRAMEWORK}</p>
          <Spinner size="s" color="primary" mx="xxs" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      </>
    )
  }
  return (
    <>
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        id={`${FilterName.COMPLIANCE_FRAMEWORK}-filter-dropdown`}
      >
        <p>{FilterName.COMPLIANCE_FRAMEWORK}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      <ComplianceFrameworkDropdownMenu
        searchParams={searchParams}
        appliedFilters={appliedFilters}
        complianceFrameworks={data.getItemsList()}
      />
    </>
  )
}

const ComplianceFrameworkFilterDropdown = (): JSX.Element => {
  const location = useLocation()

  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[FilterParam.COMPLIANCE_FRAMEWORK]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams])

  return (
    <Dropdown autoClose="outside">
      <ComplianceFrameworkDropdown
        appliedFilters={appliedFilters}
        searchParams={searchParams}
      />
    </Dropdown>
  )
}

export const ComplianceFrameworkFilterDropdowns = (): JSX.Element => {
  const { data: audit, isLoading: isAuditLoading } = useCurrentAudit()
  const { data: cfs, isLoading: isCfLoading } = useComplianceFrameworks(true)

  if (isAuditLoading || isCfLoading) return <></>

  const complianceFrameworksInAccount = cfs?.getItemsList() || []
  const hasComplianceFramework = !!complianceFrameworksInAccount.length
  const complianceFrameworks = audit
    ? audit.getComplianceFrameworksList()
    : complianceFrameworksInAccount

  // Filter out frameworks that are not SOC2 or ISO27001 - STUB Frameworks don't have criteria
  const filteredComplianceFrameworks = complianceFrameworks.filter(
    (complianceFramework: ComplianceFramework) =>
      isFullySupportedFramework(
        complianceFramework.getModelId() as FRAMEWORK_MODEL_IDS,
      ),
  )

  return !hasComplianceFramework ? (
    <></>
  ) : (
    <>
      <ComplianceFrameworkFilterDropdown />
      {filteredComplianceFrameworks?.map(
        (complianceFramework: ComplianceFramework) => {
          return (
            <FrameworkFilterDropdown
              key={complianceFramework.getId()}
              complianceFramework={complianceFramework}
            />
          )
        },
      )}
    </>
  )
}
