import React from 'react'
import { ComplianceFrameworkOption } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { Dropdown } from 'react-bootstrap'
import {
  MultiSelectDropdownCheckBox,
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
} from '../../../Reusable/MultiSelectDropdown'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '../../../Reusable/StandardDropdown/StandardDropdown.styles'
import { useComplianceFrameworkOptions } from '../../../async/complianceframework/useComplianceFrameworkOptions'
import { MenuPlaceholder } from '../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Reusable/Chips/BasicChip'

function ComplianceFrameworkItem({
  complianceFramework,
  onComplianceFrameworkSelected,
}: {
  complianceFramework: ComplianceFrameworkOption
  onComplianceFrameworkSelected: (
    framework: ComplianceFrameworkOption,
  ) => React.ChangeEventHandler<HTMLElement>
}) {
  return (
    <Dropdown.Item
      eventKey={complianceFramework.getId()}
      as={MultiSelectDropdownItem}
    >
      <MultiSelectDropdownCheckBoxLabel>
        <>
          <MultiSelectDropdownCheckBox
            id={complianceFramework.getId()}
            onChange={onComplianceFrameworkSelected(complianceFramework)}
          />
          {complianceFramework.getName()}
        </>
      </MultiSelectDropdownCheckBoxLabel>
    </Dropdown.Item>
  )
}

function ComplianceFrameworksDropdownMenu({
  onComplianceFrameworkSelected,
}: {
  onComplianceFrameworkSelected: (
    complianceFramework: ComplianceFrameworkOption,
  ) => React.ChangeEventHandler<HTMLElement>
}): JSX.Element {
  const complianceFrameworks = useComplianceFrameworkOptions()

  if (complianceFrameworks.loading) {
    return (
      <Dropdown.Menu as={StyledDropdownMenu}>
        <MenuPlaceholder height={96} />
      </Dropdown.Menu>
    )
  }

  return (
    <Dropdown.Menu as={StyledDropdownMenu}>
      {complianceFrameworks.data?.getItemsList()?.map((complianceFramework) => (
        <ComplianceFrameworkItem
          key={complianceFramework.getId()}
          complianceFramework={complianceFramework}
          onComplianceFrameworkSelected={onComplianceFrameworkSelected}
        />
      ))}
    </Dropdown.Menu>
  )
}

export function ComplianceFrameworksDropdown({
  readOnly,
  selectedComplianceFrameworks,
  onComplianceFrameworkSelected,
  showCount = false,
}: {
  readOnly: boolean
  selectedComplianceFrameworks: ComplianceFrameworkOption[]
  onComplianceFrameworkSelected: (
    complianceFramework: ComplianceFrameworkOption,
  ) => React.ChangeEventHandler<HTMLElement>
  showCount?: boolean
}): JSX.Element {
  return (
    <Dropdown id="add-audit-form-frameworks-dropdown" drop="down">
      {readOnly ? (
        <ul>
          {selectedComplianceFrameworks.map((complianceFramework) => (
            <li key={complianceFramework.getId()}>
              {complianceFramework.getName()}
            </li>
          ))}
        </ul>
      ) : (
        <>
          <Dropdown.Toggle as={StyledDropdownToggle} width="100%">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {selectedComplianceFrameworks
                .map((complianceFramework) => complianceFramework.getName())
                .join(', ') || 'Select a Framework'}
              {showCount && (
                <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
                  {selectedComplianceFrameworks.length}
                </CountChip>
              )}
            </div>
          </Dropdown.Toggle>
          <ComplianceFrameworksDropdownMenu
            onComplianceFrameworkSelected={onComplianceFrameworkSelected}
          />
        </>
      )}
    </Dropdown>
  )
}
