import React, { useEffect } from 'react'
import { LogEntry } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { LogItem } from './LogItem'
import { ChangeLogEntriesProps } from './ChangelogPage.helpers'

export const ChangeLogEntries = ({
  entries,
  itemsPerPage,
  setLastPage,
}: ChangeLogEntriesProps): JSX.Element => {
  const logs = entries.getLogentriesList()

  useEffect(() => {
    logs.length < itemsPerPage && setLastPage(true)
  }, [logs.length, entries, itemsPerPage, setLastPage])

  return (
    <>
      {logs.map((entry: LogEntry) => (
        <LogItem key={entry.getId()} logItem={entry.toObject()} />
      ))}
    </>
  )
}
