import { SWRResponse } from 'swr'
import { GrpcCall } from './utils'
import { SwrGrpcResponse, useSwrGrpc } from './useSwrGrpc' // Helper hook for
// executing a GRPC
// request with the
// SWR library and

// Helper hook for executing a GRPC request with the SWR library and
// automatic revalidations disabled.
export function useSwrImmutableGrpc<Request, Response>(
  asyncCall: GrpcCall<Request, Response>,
  request: Request,
  shouldFetch = true,
  config?: SwrGrpcResponse,
): { response: SWRResponse<Response>; cacheKey: unknown[] } {
  // Delegate to useSwrGrpc and set the immutable options:
  // https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
  return useSwrGrpc(asyncCall, request, shouldFetch, {
    ...config,
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true, // TODO: Revisit this usage as it is causing many fetches/re-renders under the hood
  })
}
