import React from 'react'
import {
  ListPoliciesRequest,
  PolicyRecord,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import Dropdown from 'react-bootstrap/Dropdown'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import styled from 'styled-components/macro'
import { TestIds } from 'src/Utils/testIds'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '../../../Reusable/StandardDropdown/StandardDropdown.styles'
import { MenuPlaceholder } from '../../../Placeholders/MenuPlaceholder'
import { ListItemPlaceholder } from '../../../Placeholders/ListItemPlaceholder'
import { useSwrImmutableGrpc } from '../../../async/useSwrImmutableGrpc'
import { boolValue } from '../../../../Utils/primitives'
import { usePolicy } from '../../../async/model'

const StyledPolicyDropdownMenu = styled(StyledDropdownMenu)`
  max-height: 250px;
  overflow-y: auto;
`

export const PolicyDropdown = ({
  selectedItem,
  onPolicySelected,
}: {
  selectedItem?: string
  onPolicySelected: (
    p: PolicyRecord.AsObject,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const {
    response: { data: policyIds },
  } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.listIds,
    new ListPoliciesRequest().setIsDismissed(boolValue(false)),
  )

  if (!policyIds) {
    return (
      <Dropdown.Menu as={StyledDropdownMenu}>
        <MenuPlaceholder height={96} />
      </Dropdown.Menu>
    )
  }

  return (
    <Dropdown
      id="add-model-form-policy-dropdown"
      data-testid={TestIds.POLICIES_DROPDOWN}
      drop="up"
    >
      <Dropdown.Toggle as={StyledDropdownToggle} width="100%">
        {selectedItem ? selectedItem : 'Select a policy'}
      </Dropdown.Toggle>
      <Dropdown.Menu as={StyledPolicyDropdownMenu}>
        {policyIds
          ?.getItemList()
          .map((policyId: string) => (
            <PolicyDropdownItem
              key={policyId}
              policyId={policyId}
              onPolicySelected={onPolicySelected}
            />
          )) ?? <MenuPlaceholder height={96} />}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const PolicyDropdownItem = ({
  policyId,
  onPolicySelected,
}: {
  policyId: string
  onPolicySelected: (
    p: PolicyRecord.AsObject,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const { data: policy } = usePolicy(policyId)

  if (!policy) {
    return <ListItemPlaceholder height={32} />
  }
  return (
    <Dropdown.Item
      id={policy.getId()}
      eventKey={policy.getId()}
      onClick={onPolicySelected(policy.toObject())}
    >
      {policy.getName()}
    </Dropdown.Item>
  )
}
