import styled from 'styled-components/macro'

export const StyledModelId = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10ch;
`

export const RESULT_TAB_GRID_COLUMNS =
  'min-content 1fr repeat(4, auto) 186px repeat(2, min-content)'
