import React from 'react'
import { FlexAlign, FlexColumn } from '../Flex'
import { CostContainer, CostDiv } from './Cost.styles'

export const SoftLimitBody = (): JSX.Element => (
  <FlexColumn gap={10} align={FlexAlign.FLEX_START} isFullWidth>
    <p>
      You&apos;ve reached your account limit for AI-powered features. To keep
      our AI Copilot running smoothly for you, it&apos;s time to have a quick
      chat with our team.
    </p>
    <p>
      Contact your Trustero customer success team or email{' '}
      <b>sales@trustero.com</b>.
    </p>
  </FlexColumn>
)

export const HardLimitBody = (): JSX.Element => (
  <FlexColumn gap={10} align={FlexAlign.FLEX_START} isFullWidth>
    <p>
      You&apos;ve hit your account limit for AI-powered features, so we&apos;ve
      had to pause the AI Copilot for now. Let&apos;s get it back up and running
      for you.
    </p>
    <p>
      Contact your Trustero customer success team or email{' '}
      <b>sales@trustero.com</b>.
    </p>
  </FlexColumn>
)

export const DisplayCost = ({
  message,
  isLarge,
}: {
  message: string
  isLarge?: boolean
}): JSX.Element =>
  isLarge ? (
    <CostContainer>{message}</CostContainer>
  ) : (
    <CostDiv>{message}</CostDiv>
  )
