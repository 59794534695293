import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { GridColumn, GridRow } from 'src/components/Reusable/Grid'
import { Receptor } from 'src/xgenerated'
import { ActivateSVG } from 'src/components/Icons/Basic'
import { CenteredGridColumn } from 'src/components/Reusable/Grid/Grid.styles'
import { generatePermalink } from 'src/components/PageLayout/Permalink'
import { openInNewTab } from 'src/Utils/globalHelpers'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import {
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { ReceptorsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import {
  InsufficientPermissionsIcon,
  InvalidCredentialsIcon,
  ReceptorNameContainer,
  ReceptorOkayIcon,
  StyledReceptorsGridHeader,
  StyledReceptorsGridRow,
} from '../receptors.styles'
import { RECEPTORS_HEADERS } from '../receptors.constants'

export const ReceptorsGridHeader = (): JSX.Element => {
  const location = useLocation()
  const isReceptorsIndex = location.pathname.includes(
    ReceptorsAbsoluteRoutes.INDEX,
  )
  return (
    <GridRow>
      {Object.values(RECEPTORS_HEADERS).map((header: string, idx: number) => (
        <StyledReceptorsGridHeader
          key={`${header}-${idx}`}
          isIndexPage={isReceptorsIndex}
        >
          {header}
        </StyledReceptorsGridHeader>
      ))}
    </GridRow>
  )
}

export const ReceptorStatusIcon = ({
  isDeactivated,
}: {
  isDeactivated: boolean
}): JSX.Element => {
  if (isDeactivated) {
    return <ActivateSVG />
  }
  return <ReceptorOkayIcon />
}

export const ReceptorReadyIcon = ({
  isDeactivated,
  notReady,
}: {
  isDeactivated: boolean
  notReady: boolean
}): JSX.Element => {
  if (isDeactivated) {
    return <></>
  } else if (notReady) {
    return <InvalidCredentialsIcon />
  }
  return <ReceptorOkayIcon />
}

export const ReceptorsGridRow = ({
  receptor,
}: {
  receptor: Receptor
}): JSX.Element => {
  const { pageContext } = useParams()
  const location = useLocation()
  const isReceptorsIndex = location.pathname.includes(
    ReceptorsAbsoluteRoutes.INDEX,
  )
  const {
    name,
    tenantid,
    iscredvalid,
    isenabled,
    exceptions,
    logo: ReceptorLogo,
  } = receptor
  const gridRowType = isReceptorsIndex ? Link : GridRow
  const permalink = isReceptorsIndex
    ? generatePermalink({
        pageContext: pageContext as string,
        modelType: MODEL_TYPE.RECEPTOROBJ,
        modelId: receptor.id,
        isInternalLink: true,
        includeSearchParams: true,
      })
    : ''

  const onClickHandler = () => {
    !isReceptorsIndex &&
      openInNewTab(
        `${window.location.origin}/${pageContext}/${ReceptorsAbsoluteRoutes.SHOW}/${receptor.id}`,
      )
  }

  return (
    <StyledReceptorsGridRow
      as={gridRowType}
      to={permalink}
      onClick={onClickHandler}
      $isDeactivated={!isenabled}
    >
      <GridColumn>
        <ReceptorNameContainer>
          <ReceptorLogo height={24} width={24} />
          {name} - {tenantid}
        </ReceptorNameContainer>
      </GridColumn>
      <CenteredGridColumn>
        <ReceptorStatusIcon isDeactivated={!isenabled} />
      </CenteredGridColumn>
      <CenteredGridColumn>
        {!isenabled ? 'N/A' : ''}
        {isenabled && exceptions.length > 0 ? (
          <Tooltip
            id={`insufficient-permissions-${receptor.id}`}
            placement={TooltipPositions.top}
            overlayType={TooltipOverlayType.popover}
            tooltipBody={exceptions}
          >
            <div>
              <InsufficientPermissionsIcon />
            </div>
          </Tooltip>
        ) : (
          <ReceptorReadyIcon
            notReady={!iscredvalid}
            isDeactivated={!isenabled}
          />
        )}
      </CenteredGridColumn>
    </StyledReceptorsGridRow>
  )
}
