import utf8 from 'utf8'
import {
  Document as DocumentMsg,
  DOCUMENT_TYPE,
  Documents,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import AttachmentService from '../services/attachment.service'
import { AuthAction, AuthRecord } from '../context/authContext'
import { extension } from '../Utils'
import { interceptTokenErrors } from './gRpcAdapter'
import { modelIdToType } from './typeUtils'

export type DocumentObj = DocumentMsg.AsObject & {
  material: string | File
}

export function documentBodyAsFile(
  body: Uint8Array | string,
  caption: string,
  mime: string,
): File {
  const filename = caption.endsWith(extension(mime) ?? '')
    ? caption
    : `${caption}.${extension(mime)}`

  return new File([body], filename, {
    type: mime,
  })
}

export const convertDocToObject = (docMsg: DocumentMsg): DocumentObj => {
  const docObj = docMsg.toObject() as DocumentObj

  // `body` must be decoded into a string OR converted into a file manually
  const body = docMsg.getBody()
  switch (docObj.mime) {
    case 'text/markdown':
    case 'text/uri-list': {
      docObj.material =
        typeof body === 'string'
          ? (body as string)
          : new TextDecoder().decode(body as Uint8Array)
      break
    }
    case 'application/protobuf': {
      docObj.mime = 'application/protobuf'
      break
    }
    default: {
      docObj.material = documentBodyAsFile(body, docObj.caption, docObj.mime)
    }
  }

  return docObj
}

type Header =
  | {
      Authorization: string
    }
  | { [key: string]: never }

export type AddDocumentType = {
  modelId: string
  docType: DOCUMENT_TYPE
  mime: string
  body: Blob | string
  caption: string
}

export type DeleteDocumentType = { objectId?: string }

class AttachmentAdapter {
  static addDocument(
    { modelId, docType, mime, body, caption }: AddDocumentType,
    authCtx: AuthRecord,
    authDispatch: React.Dispatch<AuthAction>,
  ): Promise<DocumentObj> {
    // IF there is no modelId, throw error
    if (!modelId) throw new Error('Cannot add document without modelId')

    // The underlying API does not allow empty strings, or NULLs
    // IF body is falsey, assign it to a whitespace character
    if (!body) body = ' '

    return interceptTokenErrors(
      authCtx,
      authDispatch,
      async (header: Header): Promise<DocumentObj> => {
        const modelType = modelIdToType(modelId)

        // Convert body into a uint8 byte array
        const bodyBytes = new Uint8Array(
          body instanceof Blob
            ? // Convert blob to arrayBuffer (aka byte array) first
              await new Response(body).arrayBuffer()
            : // Convert string into utf8 encoded codes
              Array.from(utf8.encode(body)).map((s) => s.charCodeAt(0)),
        )

        const documentMsg = (await AttachmentService.addDocument(
          modelType,
          modelId,
          docType,
          mime,
          bodyBytes,
          caption,
          header,
        )) as DocumentMsg

        return convertDocToObject(documentMsg)
      },
    )
  }

  static deleteDocument(
    { objectId }: DeleteDocumentType,
    authCtx: AuthRecord,
    authDispatch: React.Dispatch<AuthAction>,
  ): Promise<void> | void {
    if (!objectId) {
      return undefined
    }
    return interceptTokenErrors(
      authCtx,
      authDispatch,
      async (header: Header) => {
        await AttachmentService.deleteDocument(objectId, header)
      },
    )
  }

  static getDocumentById(
    documentId: string,
    authCtx: AuthRecord,
    authDispatch: React.Dispatch<AuthAction>,
  ): Promise<DocumentObj> {
    return interceptTokenErrors(
      authCtx,
      authDispatch,
      async (header: Header): Promise<DocumentObj> => {
        const documentMsg = (await AttachmentService.getDocumentById(
          documentId,
          header,
        )) as DocumentMsg
        return convertDocToObject(documentMsg)
      },
    )
  }

  static getDocumentsBySubjectIds(
    {
      modelIds,
      beginTime,
      endTime,
      excludeBody,
    }: {
      modelIds: string[]
      beginTime: number
      endTime: number
      excludeBody: boolean
    },
    authCtx: AuthRecord | null,
    authDispatch: React.Dispatch<AuthAction> | null,
  ): Promise<DocumentObj[]> {
    if (modelIds.length === 0) {
      return Promise.resolve([])
    }
    return interceptTokenErrors(
      authCtx,
      authDispatch,
      async (header: Header): Promise<DocumentObj[]> => {
        const documentMsgs = (await AttachmentService.getDocumentsBySubjectIds(
          modelIds,
          beginTime,
          endTime,
          excludeBody,
          header,
        ).then((documentsMsg: Documents) =>
          documentsMsg.getDocumentsList(),
        )) as DocumentMsg[]
        return documentMsgs.map((documentMsg: DocumentMsg) =>
          convertDocToObject(documentMsg),
        )
      },
    )
  }
}

export default AttachmentAdapter
