import React, { useState, FormEventHandler } from 'react'
import {
  useIsShowModal,
  useSetActiveModal,
  useHideModal,
} from 'src/Modal/ModalStateContext'
import { FlexColumn } from 'src/components/Reusable/Flex'
import { parseCSV } from 'src/Utils/csv/parseCSV'
import { getErrorMessage } from 'src/Utils/globalHelpers'
import { RpcError } from 'grpc-web'
import isFunction from 'lodash/isFunction'
import { FileSelector } from 'src/components/Reusable/Forms/FileSelector/FileSelector'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { ModalDescription } from '../ModalForm.styles'
import { ModalForm } from '../ModalForm'
import { DownloadFileWithIcon, UploadedFile } from './UploadCSVModal.components'
import {
  AdditionalDetailsContainer,
  DownloadFileContainer,
  TypeContainer,
} from './UploadCSVModal.styles'
import {
  getDownloadCSVFunc,
  getSubmitText,
  useUploadCSVFileAsDocument,
} from './UploadCSVModal.helpers'
import {
  CSV_UPLOAD_TYPES,
  UploadCSVModalProps,
} from './UploadCSVModal.constants'

export const UploadCSVModal = <T,>({
  modalId,
  prevModalId,
  uploadItemsFunc,
  titleText,
  csvHeaders,
  csvColumnParsers,
  description,
  instruction,
  formatSampleText,
  submitText,
  csvTemplate,
  validateForm,
  customCSVDownload,
  additionalDetails,
  uploadType,
  processUploadedFile,
  gap = 20,
  onAttach,
  customAboveSubmit,
  onHide,
  isAIPowered,
  showOverflow,
  showCustomForm,
  customForm,
  customSubmitButton,
  enforceFocus,
  openOnDrag = false,
}: UploadCSVModalProps<T>): JSX.Element => {
  const [attached, setAttached] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [showAttachmentError, setShowAttachmentError] = useState(false)
  const [uploadItems, setUploadItems] = useState<T[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const show = useIsShowModal(modalId)
  const [file, setFile] = useState<File | null>(null)
  const clearData = () => {
    setErrors([])
    setAttached(false)
    setFile(null)
    setUploadItems([])
    setIsSubmitting(false)
    isFunction(onAttach) && onAttach([])
    isFunction(onHide) && onHide()
  }
  const hide = useHideModal({
    modalId,
    onHide: clearData,
  })
  const handleData = (data: T[]) => {
    setUploadItems(data)
    isFunction(onAttach) && onAttach(data)
  }
  const setPrevModal = useSetActiveModal(prevModalId)
  const uploadCSVFileAsDocument = useUploadCSVFileAsDocument()
  const navigateBack = prevModalId ? setPrevModal : hide
  const back = () => (attached ? clearData() : navigateBack())

  const parseCSVCallback = parseCSV<T>({
    headers: csvHeaders,
    columnParsers: csvColumnParsers,
    setErrors: (errors: string[]) => setErrors(errors),
    setData: handleData,
  })

  const handleFileUpload = async (files: File[]) => {
    const file = files[0]

    setAttached(true)
    setFile(file)
    setShowAttachmentError(false)
    await parseCSVCallback(file)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (validateForm) {
      const isValid = validateForm()
      if (!isValid) {
        return
      }
    }
    if (!attached) {
      setShowAttachmentError(true)
      return
    } else if (!uploadItems.length) {
      hide()
      return
    }
    setIsSubmitting(true)
    // DO NOT REMOVE AWAIT
    let resError: RpcError | undefined
    try {
      if (processUploadedFile && file) {
        await uploadCSVFileAsDocument(file)
      }
      await uploadItemsFunc(uploadItems, file, setErrors)
    } catch (e) {
      setIsSubmitting(false)
      resError = e as RpcError
      const errorMessage = getErrorMessage(resError)
      errorMessage && setErrors((prev) => [...prev, errorMessage.message])
    } finally {
      !resError && hide()
    }
  }

  const submitTextValue = getSubmitText(
    !!errors.length,
    attached,
    !!uploadItems.length,
    submitText,
  )

  const downloadTemplate = getDownloadCSVFunc(csvTemplate || [], 'example')

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={modalId}
      size="xl"
      title={titleText}
      isAIPowered={isAIPowered}
      showOverflow={showOverflow}
      description={description}
      submitText={submitTextValue}
      onBack={prevModalId ? back : undefined}
      customSubmitButton={customSubmitButton ? customSubmitButton : undefined}
      enforceFocus={enforceFocus}
      openOnDrag={openOnDrag}
      disableSubmitButton={isSubmitting}
    >
      <form id={modalId} onSubmit={onSubmit}>
        <ModalDescription>{instruction}</ModalDescription>
        <FlexColumn gap={gap}>
          {formatSampleText && (
            <TypeContainer>
              <p>{formatSampleText}</p>
            </TypeContainer>
          )}
          <AdditionalDetailsContainer>
            {additionalDetails}
          </AdditionalDetailsContainer>
          {showCustomForm && customForm ? (
            <>{customForm}</>
          ) : file ? (
            <UploadedFile
              errors={errors}
              dataLength={uploadItems.length}
              showSpinner={isSubmitting}
              uploadType={uploadType || CSV_UPLOAD_TYPES.DEFAULT}
            />
          ) : (
            <FileSelector
              files={file ? [file] : undefined}
              text="Upload CSV"
              onChange={handleFileUpload}
              onClear={clearData}
              isLoading={isSubmitting}
              isValid={!showAttachmentError}
              errorMessage={'Please add a CSV file to upload'}
              accept={[MIME_TYPE.TEXT_CSV]}
            />
          )}
        </FlexColumn>
        <DownloadFileContainer>
          {customCSVDownload ? (
            <DownloadFileWithIcon
              downloadFunc={customCSVDownload.downloadFunc}
              text={customCSVDownload.text}
            />
          ) : csvTemplate ? (
            <DownloadFileWithIcon
              downloadFunc={downloadTemplate}
              text="Download Template"
            />
          ) : null}
          {customAboveSubmit}
        </DownloadFileContainer>
      </form>
    </ModalForm>
  )
}
