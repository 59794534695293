export enum HelpAbsoluteRoutes {
  ROOT = 'help',
  OVERVIEW = 'help/overview',
  LEARN_ABOUT = 'help/learn-about',
  GLOSSARY = 'help/glossary',
  QUICKSTART = 'help/quickstart',
}

export enum HelpSubRoutes {
  OVERVIEW = 'overview',
  LEARN_ABOUT_SOC2 = 'learn-about-soc2',
  LEARN_ABOUT_ISO = 'learn-about-iso',
  GLOSSARY = 'glossary',
  QUICKSTART = 'quickstart',
}
