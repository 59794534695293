import { useCallback, useMemo } from 'react'
import {
  AddOrUpdateEvidenceGroupRequest,
  BulkAddOrUpdateEvidenceGroupsRequest,
  Evidence,
  EvidenceGroup,
  EvidenceGroupId,
  GetEvidenceGroupControlsRequest,
  GetEvidenceGroupControlsResponse,
  GetEvidenceGroupDocumentsRequest,
  GetEvidenceGroupDocumentsResponse,
  GetEvidenceGroupDRLResponse,
  GetEvidenceGroupRequest,
  GetEvidenceGroupResponse,
  GetLatestEvidenceGroupsRequest,
  GetLatestEvidenceGroupsResponse,
  GetLatestEvidenceRequest,
  ListEvidenceGroupsRequest,
  ListEvidenceGroupsResponse,
  ListEvidenceRequest,
  UpdateDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useLocation, useParams } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import {
  AuditRecords,
  EvidenceFilter,
  GetAuditsRequest,
} from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useGrpcRevalidateByMethod } from 'src/components'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { useHardEvidenceInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateEvidence'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { useFetchDocumentBodyAllowMultipart } from 'src/components/async/document/useDocument'
import { isParsedPartArray } from 'src/Utils/globalHelpers'
import { isEvidenceFile } from 'src/components/ModalForms/Evidence/evidenceUtils'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import { useInAudit } from 'src/context/AuditContext'
import { FlexAlign } from 'src/components/Reusable/Flex'
import {
  applyEvidenceFilters,
  applyEvidenceGroupFilters,
  applyEvidenceGroupSort,
  getEvidenceLink,
  getSourcesMarkdown,
} from './evidence.helpers'
import {
  EVIDENCE_INDEX_HEADERS,
  EvidenceConfigType,
} from './evidence.constants'

export const useInvalidateEvidenceGroups = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(AttachmentPromiseClient.prototype.getLatestEvidenceGroups),
      mutateFunc(AttachmentPromiseClient.prototype.listEvidenceGroups),
    ])
  }, [mutateFunc])
}

export const useLatestEvidenceRequest = (): GetLatestEvidenceRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new GetLatestEvidenceRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceFilters(req, queryParams, filterType),
    )
    return req
  }, [location.search])
}

export const useEvidenceRequest = (): ListEvidenceRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListEvidenceRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceFilters(req, queryParams, filterType),
    )
    return req
  }, [location.search])
}

export const useEvidenceAudits = ({
  caption,
  contentId,
  discoveryId,
  relevantDate,
}: {
  caption: string
  contentId: string
  discoveryId: string
  relevantDate: Timestamp
}): GrpcResponse<AuditRecords> => {
  const req = useMemo(() => {
    const evidenceFilter = new EvidenceFilter()
      .setCaption(caption)
      .setContentId(contentId)
      .setDiscoveryId(discoveryId)
      .setRelevantDate(relevantDate)
    const req = new GetAuditsRequest()
    req
      .setEvidenceFilter(evidenceFilter)
      .setIsClosed(new BoolValue().setValue(false))
    return req
  }, [caption, contentId, discoveryId, relevantDate])

  const { response } = useSwrImmutableGrpc(
    AuditPromiseClient.prototype.getAudits,
    req,
    true,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceGroupsRequest = (): ListEvidenceGroupsRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListEvidenceGroupsRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceGroupFilters(req, queryParams, filterType),
    )
    applyEvidenceGroupSort(req, queryParams)
    return req
  }, [location.search])
}

export const useLatestEvidenceGroupsRequest =
  (): GetLatestEvidenceGroupsRequest => {
    const location = useLocation()

    return useMemo(() => {
      const req = new GetLatestEvidenceGroupsRequest()
      const queryParams: ParsedQuery<string> = queryString.parse(
        location.search,
        {
          arrayFormat: 'bracket',
        },
      )
      // Apply all filters for each parameter in URL
      Object.values(FilterParam).forEach((filterType: FilterParam) =>
        applyEvidenceGroupFilters(req, queryParams, filterType),
      )
      applyEvidenceGroupSort(req, queryParams)
      return req
    }, [location.search])
  }

export const useLatestEvidenceGroups = (
  req: GetLatestEvidenceGroupsRequest = new GetLatestEvidenceGroupsRequest(),
  shouldFetch = true,
): GrpcResponse<GetLatestEvidenceGroupsResponse> => {
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getLatestEvidenceGroups,
    req,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceGroups = (
  req: ListEvidenceGroupsRequest = new ListEvidenceGroupsRequest(),
  shouldFetch = true,
): GrpcResponse<ListEvidenceGroupsResponse> => {
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.listEvidenceGroups,
    req,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useUpdateEvidence = ({
  evidence,
  isEvidenceGroup,
  requestId,
  shouldMutate = true,
}: {
  evidence: Evidence | EvidenceGroup
  isEvidenceGroup: boolean
  requestId?: string
  shouldMutate?: boolean
}): (({
  newCaption,
  relevantDate,
}: {
  newCaption?: string
  relevantDate?: Timestamp
}) => Promise<string>) => {
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const mutate = useHardEvidenceInvalidation()
  const mutateGroups = useInvalidateEvidenceGroups()
  if (isEvidenceGroup) {
    return async ({
      newCaption,
      relevantDate,
    }: {
      newCaption?: string
      relevantDate?: Timestamp
    }) => {
      const updateRequest =
        new AddOrUpdateEvidenceGroupRequest().setEvidenceGroup(
          evidence as EvidenceGroup,
        )
      relevantDate && updateRequest.setNewRelevantDate(relevantDate)
      newCaption &&
        updateRequest.setNewCaption(new StringValue().setValue(newCaption))
      const updated = await client.addOrUpdateEvidenceGroup(updateRequest)
      shouldMutate && (await mutateGroups())
      return updated.getId()
    }
  } else {
    return async ({
      newCaption,
      relevantDate,
    }: {
      newCaption?: string
      relevantDate?: Timestamp
    }) => {
      const request = new UpdateDocumentRequest()
      request.setId(evidence.getId())
      requestId && request.setRequestId(new StringValue().setValue(requestId))
      newCaption && request.setCaption(new StringValue().setValue(newCaption))
      relevantDate && request.setRelevantDate(relevantDate)
      const updated = await client.updateDocument(request)
      shouldMutate && (await mutate())
      return updated.getId()
    }
  }
}

export const useEvidenceConfig = (
  contentId: string,
  mime: string,
  isAutomated: boolean,
  caption: string,
): (() => Promise<EvidenceConfigType | null>) => {
  const fetchDocumentBody = useFetchDocumentBodyAllowMultipart(contentId, mime)
  return useCallback(async () => {
    const docBody = await fetchDocumentBody()
    if (docBody) {
      if (isParsedPartArray(docBody)) {
        const bodyPart = docBody.find((part) =>
          part.headers.get('content-disposition')?.includes('file'),
        )
        const sourcesPart = docBody.find((part) =>
          part.headers
            .get('content-disposition')
            ?.includes('receptor_v1.Sources'),
        )
        if (!bodyPart || !sourcesPart) return null
        const body = bodyPart.body
        const mimeType = bodyPart.headers.get('content-type') || ''
        const sourcesBytes = sourcesPart.body as Uint8Array
        const isMultipartAutomated = mimeType === 'application/protobuf'
        const sourcesMarkdown = getSourcesMarkdown(sourcesBytes, true)
        const file = isEvidenceFile(mimeType)
          ? documentBodyAsFile(body, caption, mimeType)
          : null
        return {
          body,
          mimeType,
          sourcesMarkdown,
          isAutomated: isMultipartAutomated,
          file,
        }
      }
      const file = isEvidenceFile(mime)
        ? documentBodyAsFile(docBody, caption, mime)
        : null
      const sources =
        isAutomated && docBody instanceof Uint8Array
          ? getSourcesMarkdown(docBody, false)
          : ''
      return {
        body: docBody,
        mimeType: mime,
        sourcesMarkdown: sources,
        isAutomated,
        file,
      }
    }
    return null
  }, [mime, isAutomated, fetchDocumentBody, caption])
}

export const useAddOrUpdateEvidenceGroups = (): ((
  requests: AddOrUpdateEvidenceGroupRequest[],
) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)

  return useCallback(
    async (requests: AddOrUpdateEvidenceGroupRequest[]) => {
      const request = new BulkAddOrUpdateEvidenceGroupsRequest().setItemsList(
        requests,
      )
      await client.bulkAddOrUpdateEvidenceGroups(request)
    },
    [client],
  )
}

export const useEvidenceGroup = (
  caption: string,
  contentId: string,
  discoveryId?: string,
  shouldFetch = true,
): GrpcResponse<GetEvidenceGroupResponse> => {
  const { auditId } = useInAudit()
  const evidenceGroup = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  discoveryId &&
    evidenceGroup.setDiscoveryId(new StringValue().setValue(discoveryId))
  const request = new GetEvidenceGroupRequest().setId(evidenceGroup)
  auditId && request.setAuditId(new StringValue().setValue(auditId))

  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getEvidenceGroup,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceLink = (
  caption: string,
  contentId: string,
  discoveryId?: string,
  controlId?: string,
  requestId?: string,
  showLatest = true,
): string => {
  const location = useLocation()
  const { pageContext } = useParams()
  const { data } = useEvidenceGroup(caption, contentId, discoveryId)
  const evidenceId = data?.getEvidenceGroup()?.getId() || ''
  const evidenceLink = useMemo(
    () =>
      data
        ? getEvidenceLink(
            evidenceId,
            location.search,
            showLatest,
            pageContext,
            controlId,
            requestId,
          )
        : '',
    [
      data,
      location.search,
      showLatest,
      pageContext,
      controlId,
      requestId,
      evidenceId,
    ],
  )

  return evidenceLink
}

export const useEvidenceIndexHeaders = (): {
  name: string
  gridAlign: FlexAlign
}[] => {
  const { auditId } = useInAudit()
  if (auditId) {
    return Object.values(EVIDENCE_INDEX_HEADERS)
  }
  return Object.values(EVIDENCE_INDEX_HEADERS).filter(
    (header: { name: string; gridAlign: FlexAlign }) =>
      header.name !== 'Linked Requests',
  )
}

export const useEvidenceIndexGridTemplateColumns = (): string => {
  const { auditId } = useInAudit()
  if (auditId) {
    return 'max-content 1fr repeat(2, 10em) max-content repeat(2, 12em) repeat(2, min-content)'
  }
  return 'max-content 1fr 10em max-content repeat(2, 12em) repeat(2, min-content)'
}

export const useEvidenceGroupControlIds = (
  caption: string,
  contentId: string,
  discoveryId: string,
  shouldFetch = true,
): GrpcResponse<GetEvidenceGroupControlsResponse> => {
  const { auditId } = useInAudit()
  const evidenceGroup = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  discoveryId &&
    evidenceGroup.setDiscoveryId(new StringValue().setValue(discoveryId))
  const request = new GetEvidenceGroupControlsRequest().setEvidenceGroupId(
    evidenceGroup,
  )
  auditId && request.setAuditId(new StringValue().setValue(auditId))

  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getEvidenceGroupControlIds,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceGroupRequestIds = (
  caption: string,
  contentId: string,
  discoveryId: string,
  shouldFetch = true,
): GrpcResponse<GetEvidenceGroupDRLResponse> => {
  const { auditId } = useInAudit()
  const evidenceGroup = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  discoveryId &&
    evidenceGroup.setDiscoveryId(new StringValue().setValue(discoveryId))
  const request = new GetEvidenceGroupControlsRequest().setEvidenceGroupId(
    evidenceGroup,
  )
  auditId && request.setAuditId(new StringValue().setValue(auditId))

  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getEvidenceGroupRequestIds,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceGroupDocIds = ({
  caption,
  contentId,
  discoveryId,
}: {
  caption: string
  contentId: string
  discoveryId?: string
}): GrpcResponse<GetEvidenceGroupDocumentsResponse> => {
  const { auditId } = useInAudit()
  const evidenceGroup = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  discoveryId &&
    evidenceGroup.setDiscoveryId(new StringValue().setValue(discoveryId))
  const request = new GetEvidenceGroupDocumentsRequest().setEvidenceGroupId(
    evidenceGroup,
  )
  auditId && request.setAuditId(new StringValue().setValue(auditId))

  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getEvidenceGroupDocumentIds,
    request,
    true,
  )
  return NewGrpcResponse(response)
}
