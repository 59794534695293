import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  ACTOR_TYPE,
  AddDocumentRequest,
  DOCUMENT_TYPE,
  Document,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { isFunction } from 'lodash'
import { CsvTemplateType } from 'src/Utils/csv/csv.constants'
import { getCSVBlob } from 'src/Utils/csv/csv.helpers'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'

export const getSubmitText = (
  hasErrors: boolean,
  hasAttachedFile: boolean,
  hasRequestData: boolean,
  submitText?: string,
): string => {
  if (hasErrors) {
    return 'Close'
  } else if (hasAttachedFile && hasRequestData) {
    return 'Confirm'
  } else {
    return submitText || 'Upload'
  }
}

export const useUploadCSVFileAsDocument = (): ((
  file: File,
) => Promise<void>) => {
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  return async (file: File) => {
    const { name, size, type } = file
    const bytes = new Uint8Array(await new Response(file).arrayBuffer())
    attachmentClient.addDocument(
      new AddDocumentRequest().setDocument(
        new Document()
          .setActortype(ACTOR_TYPE.USER)
          .setBody(bytes)
          .setBodysize(size)
          .setDoctype(DOCUMENT_TYPE.USER_ACTION)
          .setMime(type)
          .setCaption(name)
          .setSubjectmodeltype(MODEL_TYPE.USEROBJ),
      ),
    )
  }
}

export const getDownloadCSVFunc = (
  csv: CsvTemplateType[],
  name: string,
): (() => void) => {
  const link = document.createElement('a')
  if (csv && isFunction(window?.URL?.createObjectURL)) {
    const blob = getCSVBlob(csv)
    link.href = window.URL.createObjectURL(blob)
    link.download = `${name}.csv`
  }
  return () => link.click()
}
