import React, { createContext, useContext, useState } from 'react'

export type InfoPanelState = {
  isShown: boolean
  render: JSX.Element
}

type InfoPanelContextType = {
  infoPanelState: InfoPanelState
  setInfoPanelState: React.Dispatch<React.SetStateAction<InfoPanelState>>
}

export const InfoPanelContext = createContext<InfoPanelContextType>({
  infoPanelState: {
    isShown: false,
    render: <></>,
  },
  setInfoPanelState: () => null,
})

export function InfoPanelProvider({
  children,
}: {
  readonly children:
    | NonNullable<React.ReactNode>
    | Array<NonNullable<React.ReactNode>>
}): JSX.Element {
  const [infoPanelState, setInfoPanelState] = useState({
    isShown: false,
    render: <div></div>,
  })

  return (
    <InfoPanelContext.Provider value={{ infoPanelState, setInfoPanelState }}>
      {children}
    </InfoPanelContext.Provider>
  )
}

export const useInfoPanelContext = (): InfoPanelContextType =>
  useContext(InfoPanelContext)
