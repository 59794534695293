import { SmartControlTestRecord } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import React, { createContext, useState } from 'react'

export type SmartChecksCtx = {
  selectedTest: string
  setSelectedTest: React.Dispatch<React.SetStateAction<string>>
  controlTestMap: Record<string, SmartControlTestRecord>
  setControlTestMap: React.Dispatch<
    React.SetStateAction<Record<string, SmartControlTestRecord>>
  >
  selectedControlIds: string[]
  setSelectedControlIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const SmartChecksContext = createContext<SmartChecksCtx>({
  selectedTest: '',
  setSelectedTest: () => null,
  controlTestMap: {},
  setControlTestMap: () => null,
  selectedControlIds: [],
  setSelectedControlIds: () => null,
})

export const SmartChecksProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [selectedTest, setSelectedTest] = useState<string>('')
  const [controlTestMap, setControlTestMap] = useState<
    Record<string, SmartControlTestRecord>
  >({})
  const [selectedControlIds, setSelectedControlIds] = useState<string[]>([])

  return (
    <SmartChecksContext.Provider
      value={{
        selectedTest,
        setSelectedTest,
        controlTestMap,
        setControlTestMap,
        selectedControlIds,
        setSelectedControlIds,
      }}
    >
      {children}
    </SmartChecksContext.Provider>
  )
}
