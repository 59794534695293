import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  AddDocumentRequest,
  DeleteDocumentRequest,
  Document as DocumentMsg,
  GetDocumentByOIDRequest,
  IDsQuery,
  TimeQuery,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { Identifier } from '@trustero/trustero-api-web/lib/common/model_pb'
import { NTRCE_API_URL } from '../adapter/gRpcAdapter'
import { modelIdToType } from '../adapter'

export const attachmentClient = new AttachmentPromiseClient(NTRCE_API_URL)

class AttachmentService {
  /**
   * Takes in a `model` object containing its `type`, `id`, and `owner`,
   * creates an `Owner` (service.proto Message),
   * and returns a Promise to set the model's owner
   * @param beginTime
   * @param endTime
   * @param header
   */
  static getChangeSince(beginTime, endTime, header) {
    const timeQuery = new TimeQuery()
      .setBegintime(beginTime)
      .setEndtime(endTime)
    return attachmentClient.getChangeSince(timeQuery, header)
  }

  static addDocument(modelType, modelId, docType, mime, body, caption, header) {
    const documentMsg = new DocumentMsg()
    documentMsg.setSubjectmodeltype(modelType)
    documentMsg.setSubjectmodelid(modelId)
    documentMsg.setDoctype(docType)
    documentMsg.setMime(mime)
    documentMsg.setBody(body)
    documentMsg.setCaption(caption)
    return attachmentClient.addDocument(
      new AddDocumentRequest().setDocument(documentMsg),
      header,
    )
  }

  static deleteDocument(objectId, header) {
    return attachmentClient.deleteDocument(
      new DeleteDocumentRequest().setDocumentId(objectId),
      header,
    )
  }

  static getDocumentById(documentId, header) {
    const objectIdMsg = new GetDocumentByOIDRequest()
    objectIdMsg.setId(documentId).setIncludeBody(true)
    return attachmentClient.getDocumentByOID(objectIdMsg, header)
  }

  static getDocumentsBySubjectIds(
    subjectModelIds,
    beginTime,
    endTime,
    excludeBody,
    header,
  ) {
    const identifiersMsg = subjectModelIds.map((modelId) => {
      const idMsg = new Identifier()
      idMsg.setModeltype(modelIdToType(modelId))
      idMsg.setModelid(modelId)
      return idMsg
    })
    const idsQueryMsg = new IDsQuery()
    idsQueryMsg.setSubjectidsList(identifiersMsg)
    idsQueryMsg.setBegintime(beginTime)
    idsQueryMsg.setEndtime(endTime)
    idsQueryMsg.setExcludebody(excludeBody)
    return attachmentClient.getDocumentsBySubjectIDs(idsQueryMsg, header)
  }
}

export default AttachmentService
