import React, { useMemo } from 'react'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import {
  ControlComplianceStats as ControlComplianceStatsRecord,
  ListControlComplianceStatsRequest,
  ListControlComplianceStatsResponse,
} from '@trustero/trustero-api-web/lib/model/stats_pb'
import { AsyncImmutableComponent } from '../../AsyncImmutableComponent'
import { BaseProps, OnUnpackedResponse } from '../../types'
import { useCurrentAudit } from '../audit'
import { ControlComplianceStatsResponse } from './ControlComplianceStatsResponse'

type Props<ExternalProps> = {
  controlId: string
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<
    ExternalProps,
    ListControlComplianceStatsResponse,
    { stats: ControlComplianceStatsRecord }
  >
>

export function ControlComplianceStatsComponent<ExternalProps>(
  props: Props<ExternalProps>,
): JSX.Element {
  const currentAudit = useCurrentAudit()?.data
  const listControlComplianceStatsRequest = useMemo(() => {
    const res = new ListControlComplianceStatsRequest()
    if (currentAudit) {
      res.setFrom(currentAudit.getStartDate())
      res.setTo(currentAudit.getEndDate())
    }
    return res
  }, [currentAudit])

  return (
    <AsyncImmutableComponent
      props={props}
      asyncCall={
        ModelStatsServicePromiseClient.prototype.listControlComplianceStats
      }
      request={listControlComplianceStatsRequest}
      placeholder={
        <>
          <p>-</p>
          <p>-</p>
        </>
      }
      onError={({ error, props }) => {
        if (props?.onError) {
          return props?.onError({ props: props?.props, error })
        } else {
          return <></>
        }
      }}
      child={({ response, mutate, props }) => {
        if (!props) {
          return <></>
        }
        const stats = response.getControlStatsList()
        return (
          <ControlComplianceStatsResponse
            {...props}
            mutate={mutate}
            stats={stats}
          />
        )
      }}
    />
  )
}
