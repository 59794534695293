import React from 'react'
import { ReactComponent as GustoSVG } from '../Dependencies/gusto/gusto.svg'
import { ReactComponent as CheckrSVG } from '../Dependencies/general/checkr.svg'
import { ReactComponent as DockerSVG } from '../Dependencies/docker/Docker.svg'
import { ReactComponent as SAPSVG } from '../Dependencies/SAP/SapLitmos.svg'
import { ReactComponent as JiraSVG } from '../Dependencies/atlassian/jira_logo.svg'
import { ReactComponent as AzureSVG } from '../Dependencies/azure/azure.svg'
import { ReactComponent as BoxSVG } from '../Dependencies/box/box.svg'
import { ReactComponent as LatticeSVG } from '../Dependencies/general/lattice_logo_nest_color.svg'
import { small } from './styles'

export const NoServicesIcons = (): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4em',
        marginBottom: '10px',
      }}
    >
      <GustoSVG style={small} />
      <CheckrSVG style={small} />
      <DockerSVG style={small} />
      <SAPSVG style={small} />
      <JiraSVG style={small} />
      <AzureSVG style={small} />
      <BoxSVG style={small} />
      <LatticeSVG style={small} />
    </div>
  )
}
