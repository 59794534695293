import React from 'react'
import { FlexRow } from '../../components/Reusable/Flex'
import {
  LabsTrusteroLogo,
  TrusteroBeaker,
  LabsP,
  WhiteLabsTrusteroLogo,
} from './ReportScan.styles'

export const TrusteroLabsLogo = (): JSX.Element => (
  <FlexRow gap={3}>
    <LabsTrusteroLogo />
    <TrusteroBeaker />
    <LabsP>labs</LabsP>
  </FlexRow>
)

export const WhiteTrusteroLabsLogo = (): JSX.Element => (
  <FlexRow gap={3}>
    <WhiteLabsTrusteroLogo />
    <TrusteroBeaker />
    <LabsP>labs</LabsP>
  </FlexRow>
)
