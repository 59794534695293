import React, { useState } from 'react'
import showdown from 'showdown'
import ReactMde from 'react-mde'
import { EditableOnClick } from 'src/components/Reusable/Inputs/EditableOnClick'
import { EditableMarkupProps } from 'src/components/Reusable/Inputs/EditableMarkupOnClick'
import { EditOnClickContainer } from '../RequestsItem.styles'
const converter = new showdown.Converter()
converter.setFlavor('github')
converter.setOption('simpleLineBreaks', false)

export const RequestItemEditableOnClick = (
  props: EditableMarkupProps,
): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')

  return (
    <EditableOnClick
      {...props}
      editMode={({ value, onChange }) => (
        <EditOnClickContainer>
          <ReactMde
            value={value}
            onChange={onChange}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(converter.makeHtml(markdown))
            }
          />
        </EditOnClickContainer>
      )}
    />
  )
}
