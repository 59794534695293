import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import { border, color, compose, margin, padding } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../../../components/Reusable/Buttons'
import { CheckSVG, InfoFullSVG } from '../../../components/Icons/Basic'
import { FormStage } from './EmailLogin'

export const EmailAuthForm = styled.form.attrs({
  mt: 'l',
})`
  ${margin}
  display: flex;
  flex-direction: column;
`

export const StartFlow = styled(StandardButton).attrs({
  variant: StandardButtonVariant.PRIMARY,
  buttonSize: StandardButtonSize.LARGE,
})`
  position: relative;
  background-color: white;

  &&,
  &&:visited {
    color: ${themeGet('colors.text.default')};
  }

  &:hover,
  &:active {
    color: white;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    margin: 4px 0 4px 4px;
    padding: 8px;
    border-radius: 6px;
    background-color: white;
  }
`

export const EmailFieldSet = styled.fieldset.attrs<{ formStage: FormStage }>(
  ({ formStage }) => {
    let bg, borderColor
    switch (formStage) {
      case FormStage.ERROR: {
        bg = 'fill.status.error.base'
        borderColor = 'fill.status.error.base'
        break
      }
      case FormStage.SUCCESS: {
        bg = 'fill.status.success.base'
        borderColor = 'fill.status.success.base'
        break
      }
      default: {
        bg = 'white'
        borderColor = 'border.neutral.light'
        break
      }
    }

    return {
      bg: bg,
      border: '1px solid',
      borderColor: borderColor,
      borderRadius: '6px',
    }
  },
)<{ formStage: FormStage }>`
  ${compose(color, border)}
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  grid-template-rows: minmax(0, 1fr);
  height: 48px;
  overflow: hidden;
`

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 3px 4px 3px 3px;
  padding: 8px;
  border: none;
  border-radius: 6px;
  background-color: white;

  :hover {
    background-color: ${themeGet('colors.fill.primary.dark')};
    svg {
      fill: white;
    }
  }
  :active {
    background-color: black;
    svg {
      fill: white;
    }
  }

  svg {
    flex-shrink: 0;
    transform: rotate(180deg);
    fill: ${themeGet('colors.text.icon.default')};
  }
`

export const EmailInput = styled.input`
  flex: 1;
  height: 100%;
  margin-right: 4px;
  border: 1px solid transparent;
  border-radius: 6px;
`

export const ResponseMessage = styled.p.attrs<{ formStage: FormStage }>(
  ({ formStage }) => {
    let Icon, message
    switch (formStage) {
      case FormStage.ERROR: {
        Icon = <InfoFullSVG fill="fill.status.error.base" mr="m" />
        message = 'Try Again'
        break
      }
      case FormStage.SUCCESS: {
        Icon = <CheckSVG mr="m" />
        message = 'Check Email for Access Link'
        break
      }
      default: {
        Icon = <Fragment />
        message = ''
        break
      }
    }

    return {
      p: 'm',
      bg: 'fill.primary.extraDark',
      children: (
        <Fragment>
          {Icon}
          {message}
        </Fragment>
      ),
    }
  },
)<{ formStage: FormStage }>`
  ${compose(padding, color)}
  display: flex;
  align-items: center;
  grid-column: span 2;
  color: white;
`

export const ConfirmButton = styled.button.attrs<{ formStage: FormStage }>(
  ({ formStage }) => {
    let text
    switch (formStage) {
      case FormStage.INPUT: {
        text = 'Send'
        break
      }
      case FormStage.ERROR: {
        text = 'Try Again'
        break
      }
      case FormStage.SUCCESS: {
        text = 'Okay'
        break
      }
      default: {
        text = ''
        break
      }
    }

    return {
      children: text,
    }
  },
)<{
  formStage: FormStage
}>`
  height: 100%;
  padding: 8px;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${themeGet('colors.fill.primary.dark')};
  color: white;

  :hover,
  :focus {
    background-color: ${themeGet('colors.fill.primary.base')};
  }
  :active {
    background-color: black;
  }
`
