import styled from 'styled-components/macro'
import {
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  variant,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export enum IconButtonVariant {
  icon = 'icon',
  chip = 'chip',
  'toggle.off' = 'toggle.off',
  'toggle.on' = 'toggle.on',
  'toggle.warning' = 'toggle.warning',
  'badge.off' = 'badge.off',
  'badge.on' = 'badge.on',
}

export type IconButtonProps = LayoutProps &
  FlexboxProps &
  PositionProps &
  SpaceProps &
  ColorProps & {
    variant: IconButtonVariant
  }

export const IconButton = styled.button.attrs<IconButtonProps>(
  ({ type, width, height }) => {
    return {
      type: type || 'button',
      width: width || '32px',
      height: height || '32px',
    }
  },
)<IconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;

  ${compose(
    variant({
      variants: {
        icon: {
          border: 'none',
          bg: 'transparent',
          color: 'text.icon.default',
          'svg path': {
            fill: 'text.icon.default',
          },
          ':hover': {
            bg: 'fill.neutral.base',
            'svg path': {
              fill: 'white',
            },
          },
          ':active': {
            bg: 'black',
            color: 'white',
            'svg path': {
              fill: 'white',
            },
          },
        },
        chip: {
          px: 'xs',
          border: '1px solid',
          borderRadius: '2px',
          borderColor: 'border.neutral.light',
          bg: 'white',
          color: 'text.default',
          fontWeight: 'regular',
          fontSize: 2,
          lineHeight: 'body',
          letterSpacing: 'none',
          p: {
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            overflow: 'hidden',
          },
          ':hover': {
            bg: 'fill.neutral.base',
            color: 'white',
          },
          ':active': {
            bg: 'black',
            color: 'white',
          },
        },
        toggle: {
          off: {
            border: '1px solid',
            borderColor: 'border.neutral.light',
            bg: 'white',
            color: 'text.icon.default',
            'svg path': {
              fill: 'text.icon.default',
            },
            ':hover': {
              bg: 'text.icon.hover',
            },
            ':active': {
              bg: 'text.icon.default',
              color: 'white',
              'svg path': {
                fill: 'white',
              },
            },
          },
          on: {
            border: '1px solid',
            borderColor: 'border.neutral.light',
            bg: 'fill.status.success.base',
            color: 'white',
            'svg path': {
              fill: 'white',
            },
            ':hover': {
              bg: 'fill.status.success.light',
            },
            ':active': {
              bg: 'fill.status.success.dark',
            },
          },
          warning: {
            border: '1px solid',
            borderColor: 'border.neutral.light',
            bg: 'fill.status.warning.base',
            color: 'white',
            'svg path': {
              fill: 'white',
            },
            ':hover': {
              bg: 'fill.status.warning.light',
            },
            ':active': {
              bg: 'fill.status.warning.dark',
            },
          },
        },
        badge: {
          off: {
            border: '1px solid',
            borderColor: 'white',
            bg: 'white',
            ':hover': {
              borderColor: 'text.icon.hover',
              bg: 'text.icon.hover',
            },
            ':active': {
              borderColor: 'text.icon.default',
              bg: 'text.icon.default',
            },
          },
          on: {
            position: 'relative',
            border: '1px solid',
            borderColor: 'transparent',
            bg: 'white',
            animation: 'spin 0.8s ease-in 1 forwards',
            ':hover': {
              bg: 'text.icon.hover',
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              animation: 'pop 0.5s ease-in 0.5s 1 forwards',
            },
          },
        },
      },
    }),
    layout,
    flexbox,
    position,
    space,
    color,
    typography,
  )}
  &:disabled,
  &:disabled:hover {
    background-color: transparent;

    svg path {
      fill: ${themeGet('colors.text.icon.disabled')};
    }
  }

  @keyframes spin {
    from {
      border-color: transparent;
    }

    20% {
      border-top-color: ${themeGet('colors.fill.status.success.base')};
    }

    40% {
      border-top-color: ${themeGet('colors.fill.status.success.base')};
      border-right-color: ${themeGet('colors.fill.status.success.base')};
    }

    60% {
      border-top-color: ${themeGet('colors.fill.status.success.base')};
      border-right-color: ${themeGet('colors.fill.status.success.base')};
      border-bottom-color: ${themeGet('colors.fill.status.success.base')};
    }

    80% {
      border-color: ${themeGet('colors.fill.status.success.base')};
    }

    to {
      border-color: ${themeGet('colors.border.neutral.light')};
    }
  }

  @keyframes pop {
    from {
      opacity: 0;
      background-color: transparent;
    }

    to {
      opacity: 1;
      background-color: ${themeGet('colors.fill.status.success.base')};
    }
  }
`
