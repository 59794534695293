/**
 * @fileoverview gRPC-Web generated client stub for model
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: model/policy.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var common_payload_pb = require('../common/payload_pb.js')

var common_collections_pb = require('../common/collections_pb.js')

var common_model_pb = require('../common/model_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var model_framework_pb = require('../model/framework_pb.js')

var audit_audit_pb = require('../audit/audit_pb.js')
const proto = {};
proto.model = require('./policy_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.PolicyClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.PolicyPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.CreateOrUpdatePolicyRequest,
 *   !proto.model.PolicyRecord>}
 */
const methodDescriptor_Policy_CreateOrUpdate = new grpc.web.MethodDescriptor(
  '/model.Policy/CreateOrUpdate',
  grpc.web.MethodType.UNARY,
  proto.model.CreateOrUpdatePolicyRequest,
  proto.model.PolicyRecord,
  /**
   * @param {!proto.model.CreateOrUpdatePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.PolicyRecord.deserializeBinary
);


/**
 * @param {!proto.model.CreateOrUpdatePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.createOrUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/CreateOrUpdate',
      request,
      metadata || {},
      methodDescriptor_Policy_CreateOrUpdate,
      callback);
};


/**
 * @param {!proto.model.CreateOrUpdatePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyRecord>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.createOrUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/CreateOrUpdate',
      request,
      metadata || {},
      methodDescriptor_Policy_CreateOrUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPolicyRequest,
 *   !proto.model.PolicyRecord>}
 */
const methodDescriptor_Policy_Get = new grpc.web.MethodDescriptor(
  '/model.Policy/Get',
  grpc.web.MethodType.UNARY,
  proto.model.GetPolicyRequest,
  proto.model.PolicyRecord,
  /**
   * @param {!proto.model.GetPolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.PolicyRecord.deserializeBinary
);


/**
 * @param {!proto.model.GetPolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/Get',
      request,
      metadata || {},
      methodDescriptor_Policy_Get,
      callback);
};


/**
 * @param {!proto.model.GetPolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyRecord>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/Get',
      request,
      metadata || {},
      methodDescriptor_Policy_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPolicyFrameworksRequest,
 *   !proto.model.Frameworks>}
 */
const methodDescriptor_Policy_GetFrameworks = new grpc.web.MethodDescriptor(
  '/model.Policy/GetFrameworks',
  grpc.web.MethodType.UNARY,
  proto.model.GetPolicyFrameworksRequest,
  model_framework_pb.Frameworks,
  /**
   * @param {!proto.model.GetPolicyFrameworksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_framework_pb.Frameworks.deserializeBinary
);


/**
 * @param {!proto.model.GetPolicyFrameworksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Frameworks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Frameworks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.getFrameworks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/GetFrameworks',
      request,
      metadata || {},
      methodDescriptor_Policy_GetFrameworks,
      callback);
};


/**
 * @param {!proto.model.GetPolicyFrameworksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Frameworks>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.getFrameworks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/GetFrameworks',
      request,
      metadata || {},
      methodDescriptor_Policy_GetFrameworks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListPoliciesRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Policy_Find = new grpc.web.MethodDescriptor(
  '/model.Policy/Find',
  grpc.web.MethodType.UNARY,
  proto.model.ListPoliciesRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.model.ListPoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.model.ListPoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.find =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/Find',
      request,
      metadata || {},
      methodDescriptor_Policy_Find,
      callback);
};


/**
 * @param {!proto.model.ListPoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.find =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/Find',
      request,
      metadata || {},
      methodDescriptor_Policy_Find);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListPoliciesRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Policy_ListIds = new grpc.web.MethodDescriptor(
  '/model.Policy/ListIds',
  grpc.web.MethodType.UNARY,
  proto.model.ListPoliciesRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.model.ListPoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.model.ListPoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.listIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/ListIds',
      request,
      metadata || {},
      methodDescriptor_Policy_ListIds,
      callback);
};


/**
 * @param {!proto.model.ListPoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.listIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/ListIds',
      request,
      metadata || {},
      methodDescriptor_Policy_ListIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListPoliciesRequest,
 *   !proto.model.PolicyRecords>}
 */
const methodDescriptor_Policy_List = new grpc.web.MethodDescriptor(
  '/model.Policy/List',
  grpc.web.MethodType.UNARY,
  proto.model.ListPoliciesRequest,
  proto.model.PolicyRecords,
  /**
   * @param {!proto.model.ListPoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.PolicyRecords.deserializeBinary
);


/**
 * @param {!proto.model.ListPoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/List',
      request,
      metadata || {},
      methodDescriptor_Policy_List,
      callback);
};


/**
 * @param {!proto.model.ListPoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyRecords>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/List',
      request,
      metadata || {},
      methodDescriptor_Policy_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetDocumentRequest,
 *   !proto.model.PolicyDocument>}
 */
const methodDescriptor_Policy_GetDocument = new grpc.web.MethodDescriptor(
  '/model.Policy/GetDocument',
  grpc.web.MethodType.UNARY,
  proto.model.GetDocumentRequest,
  proto.model.PolicyDocument,
  /**
   * @param {!proto.model.GetDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.PolicyDocument.deserializeBinary
);


/**
 * @param {!proto.model.GetDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyDocument)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyDocument>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.getDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/GetDocument',
      request,
      metadata || {},
      methodDescriptor_Policy_GetDocument,
      callback);
};


/**
 * @param {!proto.model.GetDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyDocument>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.getDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/GetDocument',
      request,
      metadata || {},
      methodDescriptor_Policy_GetDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPolicyApprovalHistoryRequest,
 *   !proto.model.GetPolicyApprovalHistoryResponse>}
 */
const methodDescriptor_Policy_GetApprovalHistory = new grpc.web.MethodDescriptor(
  '/model.Policy/GetApprovalHistory',
  grpc.web.MethodType.UNARY,
  proto.model.GetPolicyApprovalHistoryRequest,
  proto.model.GetPolicyApprovalHistoryResponse,
  /**
   * @param {!proto.model.GetPolicyApprovalHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.GetPolicyApprovalHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.model.GetPolicyApprovalHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.GetPolicyApprovalHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.GetPolicyApprovalHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.getApprovalHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/GetApprovalHistory',
      request,
      metadata || {},
      methodDescriptor_Policy_GetApprovalHistory,
      callback);
};


/**
 * @param {!proto.model.GetPolicyApprovalHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.GetPolicyApprovalHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.getApprovalHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/GetApprovalHistory',
      request,
      metadata || {},
      methodDescriptor_Policy_GetApprovalHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ApprovePolicyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_Approve = new grpc.web.MethodDescriptor(
  '/model.Policy/Approve',
  grpc.web.MethodType.UNARY,
  proto.model.ApprovePolicyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.model.ApprovePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.model.ApprovePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.approve =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/Approve',
      request,
      metadata || {},
      methodDescriptor_Policy_Approve,
      callback);
};


/**
 * @param {!proto.model.ApprovePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.approve =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/Approve',
      request,
      metadata || {},
      methodDescriptor_Policy_Approve);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.RevertPolicyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_Revert = new grpc.web.MethodDescriptor(
  '/model.Policy/Revert',
  grpc.web.MethodType.UNARY,
  proto.model.RevertPolicyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.model.RevertPolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.model.RevertPolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.revert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/Revert',
      request,
      metadata || {},
      methodDescriptor_Policy_Revert,
      callback);
};


/**
 * @param {!proto.model.RevertPolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.revert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/Revert',
      request,
      metadata || {},
      methodDescriptor_Policy_Revert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_Reset = new grpc.web.MethodDescriptor(
  '/model.Policy/Reset',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.reset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/Reset',
      request,
      metadata || {},
      methodDescriptor_Policy_Reset,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.reset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/Reset',
      request,
      metadata || {},
      methodDescriptor_Policy_Reset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPolicyControlsRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Policy_GetControls = new grpc.web.MethodDescriptor(
  '/model.Policy/GetControls',
  grpc.web.MethodType.UNARY,
  proto.model.GetPolicyControlsRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.model.GetPolicyControlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.model.GetPolicyControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.getControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/GetControls',
      request,
      metadata || {},
      methodDescriptor_Policy_GetControls,
      callback);
};


/**
 * @param {!proto.model.GetPolicyControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.getControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/GetControls',
      request,
      metadata || {},
      methodDescriptor_Policy_GetControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.common.StringList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_AddControls = new grpc.web.MethodDescriptor(
  '/model.Policy/AddControls',
  grpc.web.MethodType.UNARY,
  common_collections_pb.StringList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.common.StringList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.common.StringList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.addControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/AddControls',
      request,
      metadata || {},
      methodDescriptor_Policy_AddControls,
      callback);
};


/**
 * @param {!proto.common.StringList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.addControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/AddControls',
      request,
      metadata || {},
      methodDescriptor_Policy_AddControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.DismissRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_Dismiss = new grpc.web.MethodDescriptor(
  '/model.Policy/Dismiss',
  grpc.web.MethodType.UNARY,
  proto.model.DismissRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.model.DismissRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.model.DismissRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.dismiss =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/Dismiss',
      request,
      metadata || {},
      methodDescriptor_Policy_Dismiss,
      callback);
};


/**
 * @param {!proto.model.DismissRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.dismiss =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/Dismiss',
      request,
      metadata || {},
      methodDescriptor_Policy_Dismiss);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.common.StringList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_RemoveControls = new grpc.web.MethodDescriptor(
  '/model.Policy/RemoveControls',
  grpc.web.MethodType.UNARY,
  common_collections_pb.StringList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.common.StringList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.common.StringList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.removeControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/RemoveControls',
      request,
      metadata || {},
      methodDescriptor_Policy_RemoveControls,
      callback);
};


/**
 * @param {!proto.common.StringList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.removeControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/RemoveControls',
      request,
      metadata || {},
      methodDescriptor_Policy_RemoveControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPolicyAuditsRequest,
 *   !proto.model.GetPolicyAuditsResponse>}
 */
const methodDescriptor_Policy_GetPolicyAudits = new grpc.web.MethodDescriptor(
  '/model.Policy/GetPolicyAudits',
  grpc.web.MethodType.UNARY,
  proto.model.GetPolicyAuditsRequest,
  proto.model.GetPolicyAuditsResponse,
  /**
   * @param {!proto.model.GetPolicyAuditsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.GetPolicyAuditsResponse.deserializeBinary
);


/**
 * @param {!proto.model.GetPolicyAuditsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.GetPolicyAuditsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.GetPolicyAuditsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.getPolicyAudits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/GetPolicyAudits',
      request,
      metadata || {},
      methodDescriptor_Policy_GetPolicyAudits,
      callback);
};


/**
 * @param {!proto.model.GetPolicyAuditsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.GetPolicyAuditsResponse>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.getPolicyAudits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/GetPolicyAudits',
      request,
      metadata || {},
      methodDescriptor_Policy_GetPolicyAudits);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.common.StringList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Policy_DisassociateControlFromPolicy = new grpc.web.MethodDescriptor(
  '/model.Policy/DisassociateControlFromPolicy',
  grpc.web.MethodType.UNARY,
  common_collections_pb.StringList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.common.StringList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.common.StringList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.disassociateControlFromPolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/DisassociateControlFromPolicy',
      request,
      metadata || {},
      methodDescriptor_Policy_DisassociateControlFromPolicy,
      callback);
};


/**
 * @param {!proto.common.StringList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.disassociateControlFromPolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/DisassociateControlFromPolicy',
      request,
      metadata || {},
      methodDescriptor_Policy_DisassociateControlFromPolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPreparedPoliciesBreakdownRequest,
 *   !proto.model.GetPreparedPoliciesBreakdownResponse>}
 */
const methodDescriptor_Policy_GetPreparedPoliciesBreakdown = new grpc.web.MethodDescriptor(
  '/model.Policy/GetPreparedPoliciesBreakdown',
  grpc.web.MethodType.UNARY,
  proto.model.GetPreparedPoliciesBreakdownRequest,
  proto.model.GetPreparedPoliciesBreakdownResponse,
  /**
   * @param {!proto.model.GetPreparedPoliciesBreakdownRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.GetPreparedPoliciesBreakdownResponse.deserializeBinary
);


/**
 * @param {!proto.model.GetPreparedPoliciesBreakdownRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.GetPreparedPoliciesBreakdownResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.GetPreparedPoliciesBreakdownResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.PolicyClient.prototype.getPreparedPoliciesBreakdown =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Policy/GetPreparedPoliciesBreakdown',
      request,
      metadata || {},
      methodDescriptor_Policy_GetPreparedPoliciesBreakdown,
      callback);
};


/**
 * @param {!proto.model.GetPreparedPoliciesBreakdownRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.GetPreparedPoliciesBreakdownResponse>}
 *     Promise that resolves to the response
 */
proto.model.PolicyPromiseClient.prototype.getPreparedPoliciesBreakdown =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Policy/GetPreparedPoliciesBreakdown',
      request,
      metadata || {},
      methodDescriptor_Policy_GetPreparedPoliciesBreakdown);
};


module.exports = proto.model;

