import React from 'react'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function EyeIcon(): JSX.Element {
  return (
    <FontAwesomeIcon
      icon={faEye}
      color="#4C5E6E"
      style={{ marginRight: '5px' }}
    />
  )
}
