import { useCallback } from 'react'
import { RemoveControlsFromAllAuditsRequest } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import log from 'loglevel'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useCurrentAudit } from './useCurrentAudit'

/**
 * TODO: Logic should be  moved to the backend once the multi-audit flag is
 * @param controlId
 * @param onSuccess
 * @param shouldFetch
 */
export function useRemoveControlFromAllAudits(
  controlId: string | string[],
  onSuccess = () => {
    return
  },
): () => Promise<void> {
  const { data: currentAudit, mutate: mutateAudit } = useCurrentAudit()
  const auditClient = useAuthorizedGrpcClient(AuditPromiseClient)

  return useCallback(async () => {
    try {
      const request =
        new RemoveControlsFromAllAuditsRequest().setControlsIdsList(
          Array.isArray(controlId) ? controlId : [controlId],
        )
      await auditClient.removeControlsFromAllAudits(request)
      if (currentAudit) {
        await mutateAudit()
      }
      onSuccess()
    } catch (e) {
      log.warn(
        'Caught an error while attempting to remove the control from all audits',
        e,
      )
    }
  }, [auditClient, controlId, currentAudit, mutateAudit, onSuccess])
}
