import React from 'react'
import { SubjectContainer } from './Subject.styles'

export const LinkerContent = ({
  linkerName,
}: {
  linkerName?: string
}): JSX.Element => (
  <SubjectContainer>
    <span>{`\u2022`}</span>
    {linkerName}
  </SubjectContainer>
)
