import React from 'react'
import { CheckSVG, DropdownSVG } from 'src/components/Icons/Basic'
import styled from 'styled-components/macro'
import {
  border,
  compose,
  flexbox,
  layout,
  margin,
  padding,
  shadow,
} from 'styled-system'
import themeGet from '@styled-system/theme-get'
import {
  DecoratedDropdownItemChipProps,
  DecoratedDropdownItemProps,
  DecoratedDropdownMenuContainerProps,
  DecoratedDropdownToggleProps,
} from './DecoratedDropdown.constants'

export const DecoratedDropdownContainer = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2em;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 4px;
`

export const DecoratedDropdownToggle = styled.button.attrs<DecoratedDropdownToggleProps>(
  ({ children, isDisabled }) => ({
    children: (
      <>
        {children}
        {!isDisabled && <DropdownSVG />}
      </>
    ),
  }),
)<DecoratedDropdownToggleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ isDisabled, $isLarge }) =>
    !isDisabled ? ($isLarge ? '255px' : '205px') : '165px'};
  height: 29px;
  padding: 8px 12px;
  border: none;
  color: ${({ colors: { text } }) => text};
  background-color: ${({ colors: { background } }) => background};
  text-align: left;

  :hover {
    color: ${({ isDisabled, colors: { textHover } }) =>
      !isDisabled ? textHover : null};
    background-color: ${({
      isDisabled,
      colors: { backgroundHover, background },
    }) => (!isDisabled ? backgroundHover : background)};
    svg {
      fill: ${({ colors: { textHover } }) => textHover};
    }
  }

  svg {
    fill: ${({ colors: { text } }) => text};
    transform: ${({ isVisible }) => {
      return isVisible ? 'scaleY(-1)' : 'scaleY(1)'
    }};
    transition: transform 0.35s;
  }

  ${compose(layout, flexbox)}
`

export const DecoratedDropdownMenuContainer = styled.div.attrs({
  border: '1px solid',
  borderColor: 'border.neutral.light',
  boxShadow: 'outer.base',
})<DecoratedDropdownMenuContainerProps>`
  ${compose(border, shadow)}
  margin-top: 30px;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: max-content;
  background-color: white;
  cursor: pointer;
`

export const DecoratedDropdownItem = styled.button.attrs<DecoratedDropdownItemProps>(
  ({ isSelected, backgroundColor, textColor, label }) => ({
    pl: 's',
    children: (
      <>
        <DecoratedDropdownItemChip
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          {label}
        </DecoratedDropdownItemChip>
        <CheckSVG
          height="20px"
          fill={isSelected ? 'text.icon.default' : 'transparent'}
        />
      </>
    ),
  }),
)<DecoratedDropdownItemProps>`
  ${padding}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: none;
  color: ${({ textColor }) => textColor};
  text-align: left;

  background-color: white;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.text.icon.hover')};
    outline: none;
  }
`

export const DecoratedDropdownItemChip = styled.div.attrs({
  mr: 'xs',
})<DecoratedDropdownItemChipProps>`
  ${margin}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 24px;
  padding: 8px 12px;
  border: none;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  text-align: left;
`
