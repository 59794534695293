import { useCallback } from 'react'
import { MutatorCallback, MutatorOptions } from 'swr'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcCall } from '../utils'
import { GrpcResponse } from './types'

type Id<T> = T & {
  getId: () => string
  getModelId?: () => string
}

type Collection<T> = {
  getItemsList(): Array<T>
  setItemsList(value: Array<T>): Collection<T>
  clearItemsList(): Collection<T>
  addItems(value?: T, index?: number): T
}
type ListMemberProps<Request, Item> = {
  id: string
  asyncCall: GrpcCall<Request, Collection<Item>>
  request: Request
  newCollection: () => Collection<Item>
}

function itemEquals<Item>(a: Id<Item>, b: Id<Item>): boolean {
  if (!a || !b) return false
  return !!(
    b.getId() === a.getId() ||
    (b.getModelId && a.getModelId && b.getModelId() === a.getModelId())
  )
}

function idEquals<Item>(item: Id<Item>, id: string): boolean {
  if (!id || !item) return false
  return !!(
    item.getId() === id ||
    (item.getModelId && item.getModelId() === id)
  )
}

function populateCache<Item>(
  delta: Collection<Id<Item>>,
  cached: Collection<Id<Item>>,
) {
  const itemsList = cached.getItemsList()
  for (const updated of delta.getItemsList()) {
    const idx = itemsList.findIndex((p) => itemEquals(p, updated))
    if (idx < 0) {
      itemsList.push(updated)
    } else {
      itemsList[idx] = updated
    }
  }
  cached.setItemsList(itemsList)
  return cached
}

function isCallable(p: unknown): p is () => unknown {
  return typeof p === 'function'
}

export function useListMember<Request, Item>(
  props: ListMemberProps<Request, Id<Item>>,
  shouldFetch = true,
): GrpcResponse<Id<Item>> {
  const { response } = useSwrImmutableGrpc(
    props.asyncCall,
    props.request,
    shouldFetch,
    {
      ignoreAuditContext: true,
    },
  )

  const item = response?.data
    ?.getItemsList()
    ?.find((p: Id<Item>) => idEquals(p, props.id))

  // TODO: Come back and cleanup this old hook to avoid using ts-ignore
  const mutate = useCallback(
    async (
      data?: Id<Item> | Promise<Id<Item>> | MutatorCallback<Id<Item>>,
      _opts?: boolean | MutatorOptions<Id<Item>>,
    ): Promise<Id<Item> | undefined> => {
      if (!data) {
        const updated = await response.mutate()
        const items = updated?.getItemsList()
        return items?.find((p: Id<Item>) => idEquals(p, props.id))
      }

      const result = await (isCallable(data) ? data() : data)
      if (!result) {
        return undefined
      }
      const delta = props.newCollection()
      delta.addItems(result)

      const updated = await response.mutate(delta, {
        // @ts-ignore
        populateCache,
        rollbackOnError: true,
        revalidate: false,
      })
      // @ts-ignore
      return (
        updated
          ?.getItemsList()
          // @ts-ignore
          .find((p: Id<Item>) => idEquals(p, props.id))
      )
    },
    [props, response],
  )
  return {
    error: response.error,
    isValidating: response.isValidating,
    isLoading: response.isLoading,
    data: item,
    // @ts-ignore
    mutate,
  }
}
