import { useCallback } from 'react'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { SWRResponse, KeyedMutator } from 'swr'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcCall } from '../utils'

export function useFetchStringList<Request>(
  asyncCall: GrpcCall<Request, StringList>,
  request: Request,
  shouldFetch = true,
): SWRResponse<string[]> {
  const { response } = useSwrImmutableGrpc(asyncCall, request, shouldFetch)

  const mutate: KeyedMutator<string[]> = useCallback(
    async (p) => {
      if (!p) {
        const updated = await response.mutate()
        return updated?.getItemList()
      }
      const resolved = await p
      if (Array.isArray(resolved)) {
        const updated = await response.mutate(
          new StringList().setItemList(resolved),
        )
        return updated?.getItemList()
      }
      return mutate()
    },
    [response],
  )
  return {
    ...response,
    data: response.data?.getItemList(),
    mutate,
  }
}
