import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { ReceptorPromiseClient } from '@trustero/trustero-api-web/lib/agent/receptor_grpc_web_pb'
import { LinkerPromiseClient } from '@trustero/trustero-api-web/lib/linker/linker_grpc_web_pb'
import log from 'loglevel'
import {
  GetControlMappingsForReceptorRequest,
  GetControlMappingsForReceptorResponse,
  GetHasReceptorRequest,
  GetHasReceptorResponse,
  ListInProgressScansResponse,
  ReceptorID,
  ReceptorRecord,
  ReceptorRecords,
} from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useInvalidateReceptorsCache } from 'src/components/async/model/receptor/useReceptors'
import { LINKER_MODEL_IDS } from 'src/lib/Linkers/Linkers.constants'
import { isGrpcError } from 'src/Utils/isGrpcError'
import {
  GetHasLinkerRequest,
  GetHasLinkerResponse,
  GetLinkerRequest,
} from '@trustero/trustero-api-web/lib/linker/linker_pb'
import { getLinkerTemplate } from 'src/lib/Linkers/Linkers.helpers'
import { useCallback } from 'react'

export const useReceptors = (
  modelId?: string,
  shouldFetch = true,
): GrpcResponse<ReceptorRecords> => {
  const request = new ReceptorID()
  modelId && request.setModelid(modelId)
  const { response } = useSwrImmutableGrpc(
    ReceptorPromiseClient.prototype.getReceptors,
    request,
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export const useHasReceptor = (
  modelId: string,
  includeDeactivated = false,
  shouldFetch = true,
): GrpcResponse<GetHasReceptorResponse> => {
  const request = new GetHasReceptorRequest()
    .setModelId(modelId)
    .setIncludeDeactivated(includeDeactivated)
  const { response } = useSwrImmutableGrpc(
    ReceptorPromiseClient.prototype.getHasReceptor,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useReceptor = ({
  receptorId,
  modelId,
  shouldFetch = false,
}: {
  receptorId?: string
  modelId?: string
  shouldFetch?: boolean
}): GrpcResponse<ReceptorRecord> => {
  const request = new ReceptorID()
  const fetchReceptor = shouldFetch && (!!receptorId || !!modelId)
  receptorId && request.setOid(receptorId)
  modelId && request.setModelid(modelId)
  const { response } = useSwrImmutableGrpc(
    ReceptorPromiseClient.prototype.getReceptor,
    request,
    fetchReceptor,
  )
  return NewGrpcResponse(response)
}

export const useScanReceptor = (): (({
  receptorId,
  receptorModelId,
}: {
  receptorId: string
  receptorModelId: string
}) => Promise<void>) => {
  const receptorClient = useAuthorizedGrpcClient(ReceptorPromiseClient)
  const mutate = useInvalidateReceptorsCache()

  return async ({
    receptorId,
    receptorModelId,
  }: {
    receptorId: string
    receptorModelId: string
  }) => {
    const request = new ReceptorID()
      .setOid(receptorId)
      .setModelid(receptorModelId)
    await receptorClient.scanNow(request)
    showInfoToast('Receptor scan started')
    mutate()
  }
}

export const useInProgressReceptorScans = (
  shouldFetch = true,
): GrpcResponse<ListInProgressScansResponse> => {
  const { response } = useSwrImmutableGrpc(
    ReceptorPromiseClient.prototype.listInProgressScans,
    new Empty(),
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useControlMappingsForReceptor = (
  receptorId: string,
): GrpcResponse<GetControlMappingsForReceptorResponse> => {
  const request = new GetControlMappingsForReceptorRequest().setReceptorId(
    receptorId,
  )
  const { response } = useSwrImmutableGrpc(
    ReceptorPromiseClient.prototype.getControlMappingsForReceptor,
    request,
    !!receptorId,
  )
  return NewGrpcResponse(response)
}

export const useLinker = (
  modelId: LINKER_MODEL_IDS,
  shouldFetch = false,
): GrpcResponse<ReceptorRecord> => {
  const request = new GetLinkerRequest().setModelId(modelId)
  const { response } = useSwrImmutableGrpc(
    LinkerPromiseClient.prototype.getLinker,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useGetLinker = (): ((
  modelId: LINKER_MODEL_IDS,
) => Promise<ReceptorRecord | null>) => {
  const client = useAuthorizedGrpcClient(LinkerPromiseClient)
  return useCallback(
    async (modelId: LINKER_MODEL_IDS) => {
      const linkerTemplate = getLinkerTemplate(modelId)
      try {
        const request = new GetLinkerRequest().setModelId(modelId)
        return await client.getLinker(request)
      } catch (e) {
        if (isGrpcError(e) && e.code === 5) {
          return new ReceptorRecord()
        }
        log.error(`Error getting ${linkerTemplate.name} linker`, e)
        return null
      }
    },
    [client],
  )
}

export const useHasLinker = (
  linkerId: LINKER_MODEL_IDS,
  shouldFetch = true,
): GrpcResponse<GetHasLinkerResponse> => {
  const request = new GetHasLinkerRequest()
    .setModelId(linkerId)
    .setIncludeDeactivated(false)
  const { response } = useSwrImmutableGrpc(
    LinkerPromiseClient.prototype.getHasLinker,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}
