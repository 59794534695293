import React from 'react'
import styled from 'styled-components/macro'
import {
  border,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  margin,
  padding,
  shadow,
} from 'styled-system'
import { DOCUMENT_REQUEST_STATUS } from '@trustero/trustero-api-web/lib/request/request_pb'
import { themeGet } from '@styled-system/theme-get'
import { DOCUMENT_REQUEST_STATUS_TO_LABEL } from 'src/pages/Requests/requests.constants'
import { CheckSVG, DropdownSVG } from '../../../../../components/Icons/Basic'
import palette from '../../../../../designSystem/variables/palette'

type StatusCssType = {
  color: string
  bg: string
  hover: string
}

export const RequestStatusStyles: Record<
  DOCUMENT_REQUEST_STATUS,
  StatusCssType
> = {
  [DOCUMENT_REQUEST_STATUS.OPEN]: {
    color: palette.neutral['800'],
    bg: palette.neutral['50'],
    hover: palette.neutral['100'],
  },
  [DOCUMENT_REQUEST_STATUS.READY]: {
    color: palette.green['800'],
    bg: palette.green['50'],
    hover: palette.green['100'],
  },
  [DOCUMENT_REQUEST_STATUS.ACCEPTED]: {
    color: palette.blue['800'],
    bg: palette.blue['50'],
    hover: palette.blue['100'],
  },
  [DOCUMENT_REQUEST_STATUS.RETURNED]: {
    color: palette.magenta['800'],
    bg: palette.magenta['50'],
    hover: palette.magenta['100'],
  },
}

export const StatusDropdownContainer = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.2em;
  border-radius: 2px;
  cursor: pointer;
`

interface StatusDropdownMenuContainerProps {
  readonly isVisible: boolean
}

export const StatusDropdownMenuContainer = styled.div.attrs({
  border: '1px solid',
  borderColor: 'border.neutral.light',
  boxShadow: 'outer.base',
})<StatusDropdownMenuContainerProps>`
  ${compose(border, shadow)}
  margin-top: 30px;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: max-content;
  background-color: white;
`

export type StatusDropdownToggleProps = Pick<LayoutProps, 'width' | 'height'> &
  FlexboxProps & {
    isVisible?: boolean
    status: DOCUMENT_REQUEST_STATUS
  }

export const StatusDropdownToggle = styled.button.attrs<StatusDropdownToggleProps>(
  (props) => ({
    children: (
      <>
        {props.children}
        {!props.disabled && <DropdownSVG />}
      </>
    ),
  }),
)<StatusDropdownToggleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (!props.disabled ? '145px' : '120px')};
  height: 24px;
  padding: 8px 12px;
  border: none;
  color: ${(props) => RequestStatusStyles[props.status].color};
  text-align: left;

  background-color: ${(props) => RequestStatusStyles[props.status].bg};

  :hover {
    background-color: ${(props) =>
      !props.disabled
        ? RequestStatusStyles[props.status].hover
        : RequestStatusStyles[props.status].bg};
  }

  svg {
    fill: ${(props) => RequestStatusStyles[props.status].color};
    transform: ${(props) => {
      return props.isVisible ? 'scaleY(-1)' : 'scaleY(1)'
    }};
    transition: transform 0.35s;
  }

  ${compose(layout, flexbox)}
`

export const StatusDropdownItemChip = styled.div.attrs({
  mr: 'xs',
})<Omit<StatusDropdownToggleProps, 'isVisible'>>`
  ${margin}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 24px;
  padding: 8px 12px;
  border: none;
  background-color: ${(props) => RequestStatusStyles[props.status].bg};
  color: ${(props) => RequestStatusStyles[props.status].color};
  text-align: left;
`

type StatusDropdownItemProps = Omit<StatusDropdownToggleProps, 'isVisible'> & {
  selected: DOCUMENT_REQUEST_STATUS
}

export const StatusDropdownItem = styled.button.attrs<StatusDropdownItemProps>(
  (props) => ({
    pl: 's',
    children: (
      <>
        <StatusDropdownItemChip status={props.status}>
          {DOCUMENT_REQUEST_STATUS_TO_LABEL[props.status]}
        </StatusDropdownItemChip>
        <CheckSVG
          height="20px"
          fill={
            props.status === props.selected
              ? 'text.icon.default'
              : 'transparent'
          }
        />
        {props.children}
      </>
    ),
  }),
)<StatusDropdownItemProps>`
  ${padding}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: none;
  color: ${(props) => RequestStatusStyles[props.status].color};
  text-align: left;

  background-color: white;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.text.icon.hover')};
    outline: none;
  }
`
