import React, { ReactElement, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { getCognitoAuthGrant, msRedirect } from 'src/Utils/oidc'
import Cookies from 'js-cookie'
import { COOKIES } from 'src/Utils/globalEnums'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { ButtonContainer } from '../SignUpPage/SignUpPage.styles'
import { EmailLogin } from './EmailLogin/EmailLogin'
import { AttemptLogin } from './AttemptLogin'
import { LoginProviders } from './LoginPage.constants'
import {
  LineContent,
  LoginPageContainer,
  LoginPageContent,
  LoginPageGrid,
  TrusteroLine,
  TrusteroLogo,
} from './LoginPage.styles'
import {
  Auth0Icon,
  GoogleIcon,
  LoginButton,
  MicrosoftIcon,
  OktaIcon,
  OneLoginIcon,
  RedirectToHubspot,
  EntraIdIcon,
} from './Login.components'

export const LoginPage = ({ isInApp }: { isInApp?: boolean }): ReactElement => {
  const location = useLocation()
  const [isRedirecting, setIsRedirecting] = React.useState<boolean>(false)
  const originalURL = useMemo(() => {
    const state = location.state as { redirect?: string } | undefined
    return state?.redirect
  }, [location])

  useEffect(() => {
    // we only want to remove this cookie if the user stays on this page
    // We don't want to remove it from if we are redirecting to log into the app
    const timeoutId = setTimeout(
      () => Cookies.remove(COOKIES.FROM_SIGNUP, { path: '' }),
      2500,
    )
    const cleanup = () => clearTimeout(timeoutId)

    return cleanup
  }, [])

  return (
    <LoginPageContainer isInApp={isInApp}>
      <LoginPageGrid>
        <LoginPageContent>
          <TrusteroLogo width="100%" height="100%" />
          <TrusteroLine />
          <AttemptLogin setIsRedirecting={setIsRedirecting} />
          {isRedirecting ? (
            <RedirectToHubspot />
          ) : (
            <>
              <ButtonContainer>
                <LoginButton
                  leftIcon={<GoogleIcon />}
                  onClickFunc={() =>
                    getCognitoAuthGrant({ provider: LoginProviders.GOOGLE })
                  }
                  providerName="Google"
                />
                <LoginButton
                  leftIcon={<Auth0Icon />}
                  onClickFunc={() =>
                    getCognitoAuthGrant({ provider: LoginProviders.OKTA })
                  }
                  providerName="Auth0"
                />
                <LoginButton
                  leftIcon={<OktaIcon />}
                  onClickFunc={() =>
                    getCognitoAuthGrant({ provider: LoginProviders.OKTA })
                  }
                  providerName="Okta"
                />
                <LoginButton
                  leftIcon={<OneLoginIcon />}
                  onClickFunc={() =>
                    getCognitoAuthGrant({ provider: LoginProviders.ONELOGIN })
                  }
                  providerName="OneLogin"
                />
                {IsGatekeeperEnabled(GatekeeperFlag.MS_LOGIN) ? (
                  <>
                    <LoginButton
                      leftIcon={<MicrosoftIcon />}
                      onClickFunc={() => msRedirect(false)}
                      providerName="Microsoft"
                    />
                    <LoginButton
                      leftIcon={<EntraIdIcon />}
                      onClickFunc={() => msRedirect(true)}
                      providerName="Microsoft Entra ID"
                    />
                  </>
                ) : (
                  <LoginButton
                    leftIcon={<MicrosoftIcon />}
                    onClickFunc={() =>
                      getCognitoAuthGrant({ provider: LoginProviders.OKTA })
                    }
                    providerName="Microsoft (via Auth0)"
                  />
                )}
              </ButtonContainer>
              <LineContent>or</LineContent>
              <EmailLogin originalURL={originalURL} />
            </>
          )}
        </LoginPageContent>
      </LoginPageGrid>
    </LoginPageContainer>
  )
}
