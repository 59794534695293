import React, { useContext, useMemo, useState } from 'react'
import { PolicyRecord } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { RESPONSIBILITY } from '@trustero/trustero-api-web/lib/common/model_pb'

type CurrentPolicy = {
  currentPolicy: PolicyRecord.AsObject
  setCurrentPolicy: React.Dispatch<React.SetStateAction<PolicyRecord.AsObject>>
  updatedResponsibility?: RESPONSIBILITY
  setUpdatedResponsibility: React.Dispatch<
    React.SetStateAction<RESPONSIBILITY | undefined>
  >
}

const CurrentPolicyContext = React.createContext<CurrentPolicy>({
  currentPolicy: new PolicyRecord().toObject(),
  setCurrentPolicy: () => new PolicyRecord().toObject(),
  setUpdatedResponsibility: () => undefined,
})

export const useCurrentPolicy = (): CurrentPolicy =>
  useContext(CurrentPolicyContext)

export const CurrentPolicyContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [currentPolicy, setCurrentPolicy] = useState<PolicyRecord.AsObject>(
    new PolicyRecord().toObject(),
  )
  const [updatedResponsibility, setUpdatedResponsibility] = useState<
    RESPONSIBILITY | undefined
  >()

  const policyData: CurrentPolicy = useMemo(
    () => ({
      currentPolicy,
      setCurrentPolicy,
      updatedResponsibility,
      setUpdatedResponsibility,
    }),
    [
      currentPolicy,
      setCurrentPolicy,
      updatedResponsibility,
      setUpdatedResponsibility,
    ],
  )
  return (
    <CurrentPolicyContext.Provider value={policyData}>
      {children}
    </CurrentPolicyContext.Provider>
  )
}
