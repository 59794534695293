import log from 'loglevel'
// WARNING: any imports should not use the logger
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { AppConfig, EnvConfig } from '../config/config'

// Note: the order of initialization events may matter so be careful when adding
// new code here or rearranging the existing code.

// Configure Datadog error monitoring
// https://docs.datadoghq.com/logs/log_collection/javascript/
const datadogConfig = EnvConfig.monitoring?.datadog
datadogLogs.init({
  clientToken: datadogConfig?.clientToken || '',
  env: EnvConfig.name,
  forwardErrorsToLogs: datadogConfig?.forwardErrorsToLogs || false,
  sampleRate: datadogConfig?.sampleRate || 0,
  service: 'app',
  silentMultipleInit: true,
  version: process.env.REACT_APP_VERSION,
})

// Configure Datadog performance monitoring
// https://docs.datadoghq.com/real_user_monitoring/browser/
datadogRum.init({
  applicationId: AppConfig.monitoring.datadogRumApplicationId,
  clientToken: AppConfig.monitoring.datadogRumClientToken,
  service: 'app',
  env: EnvConfig.name,
  version: process.env.REACT_APP_VERSION,
  sessionSampleRate: datadogConfig?.sampleRate,
  sessionReplaySampleRate: datadogConfig?.sampleRate,
  silentMultipleInit: true,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
})

// -----------------------------------------------------------------------------
// Configure the logging library _after_ the monitoring library initialization.

// put "loglevel" into the window for easy access
declare global {
  interface Window {
    loglevel: log.RootLogger
  }
}
window.loglevel = log

// the following logger initialization needs to happen before other files are
// imported that depend on `log.getContext()`.
log.setDefaultLevel(EnvConfig.debugLevel)

const logger = log.getLogger('app')

logger.debug('Environment ', EnvConfig.name)
logger.debug(
  'git-hash: ',
  process.env.REACT_APP_VERSION ?? 'env var GIT_HASH was not set',
)
logger.debug(
  'git-branch: ',
  process.env.REACT_APP_BRANCH ?? 'env var GIT_BRANCH was not set',
)
logger.debug(
  'git-user: ',
  process.env.REACT_APP_USER ?? 'env var GIT_USER was not set',
)
logger.debug('ntrcedUrl', EnvConfig.ntrceApiUrl)

export const AppInit = (): void => {
  // initialization work goes here
  logger.debug('init: global initialization is complete')
}
