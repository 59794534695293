import * as R from 'rambda'
import log from 'loglevel'
import { contentInitialState } from './defs'

const logger = log.getLogger('context')

export const contentReducer = (state, delta) => {
  if (!delta) {
    return state
  }
  if (delta.loaded === false) {
    logger.debug('Resetting ContentContext to initial state')
    // if `loaded` is explicitly set to false by the
    // caller, we want to trigger a clearing of the
    // ContentContext
    return contentInitialState()
  }

  const changes = [
    { field: 'overviews', primary_key: 'id' },
    { field: 'receptors', primary_key: 'modelid' },
    { field: 'services', primary_key: 'id' },
  ].map(({ field, primary_key }) =>
    _mergeDelta(field, state, delta, primary_key),
  )

  // users are a special case because we don't receive the delta -- we
  // receive the full user list WHEN we receive anything at all.
  const user_data = 'users' in delta ? delta.users : state.users
  if ('users' in delta) {
    changes.push({
      idIndex: R.mergeAll(
        user_data.map((u) => {
          return { [u.email]: u }
        }),
      ),
    })
  }

  const concatAll = R.reduce(R.concat, [])
  return {
    ...state,
    idIndex: R.mergeAll([state.idIndex, ...changes.map((c) => c.idIndex)]),
    overviews: concatAll(changes.map((c) => c.overviews || [])),
    receptors: concatAll(changes.map((c) => c.receptors || [])),
    services: concatAll(changes.map((c) => c.services || [])),
    users: user_data,

    loaded: delta.loaded ?? state.loaded,
    deltaUpdatePeriod: delta.deltaUpdatePeriod || state.deltaUpdatePeriod,
    deltaUpdateRunning: delta.deltaUpdateRunning ?? state.deltaUpdateRunning,
    lastUpdateStamp: delta?.lastUpdateStamp ?? state.lastUpdateStamp,
  }
}

/**
 * Merge the delta information for a field into the current
 * state of the field in the global UI cache.
 * Returns an object with the updated field and an index to the delta
 */
const _mergeDelta = (field, state, delta, primary_key) => {
  const delta_data = delta[field] || []
  const id_to_object = {}
  delta_data.forEach((obj) => (id_to_object[obj[primary_key]] = obj))
  // compute the updated objects
  const updated_objects = state[field].map(
    (obj) => id_to_object[obj[primary_key]] || obj,
  )
  const new_objects = delta_data.filter(
    (obj) => !(obj[primary_key] in state.idIndex),
  )

  return {
    idIndex: id_to_object,
    [field]: [...new_objects, ...updated_objects],
  }
}

export default contentReducer
