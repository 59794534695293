export const large = {
  height: '100px',
  width: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const small = {
  height: '50px',
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
