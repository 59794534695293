import themeGet from '@styled-system/theme-get'
import React from 'react'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const PercentageCompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

type ProgressCircleProps = {
  radius: number
  fontSize?: number
}

export const ProgressCircle = styled.div.attrs({
  className: 'progress-circle',
})<ProgressCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => `${props.radius * 2}px`};
  height: ${(props) => `${props.radius * 2}px`};
  border-radius: 50%;
  background-color: ${themeGet('colors.bg.neutralDark')};

  color: ${palette.neutral[600]};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 26)}px;
  font-weight: 400;
`

export const ProgressContainer = styled.div<ProgressCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  width: ${(props) => `${props.radius * 2}px`};
  height: ${(props) => `${props.radius * 2}px`};
`

export const ProgressRing = styled.svg<ProgressCircleProps>`
  width: ${(props) => `${props.radius * 2}px`};
  height: ${(props) => `${props.radius * 2}px`};
  position: absolute;
  overflow: visible;
`

type ProgressRingCircleProps = ProgressCircleProps & {
  progress: number
  strokeWidth?: number
}

const StyledProgressCircle = ({ ...props }) => <circle {...props} />

export const ProgressRingCircle = styled(
  StyledProgressCircle,
).attrs<ProgressRingCircleProps>((props) => {
  const circumference = props.radius * 2 * Math.PI
  return {
    r: props.radius,
    cx: props.radius,
    cy: props.radius,
    stroke: props.theme.colors?.fill.status.success.light,
    strokeWidth: props.strokeWidth ? props.strokeWidth : '6',
    strokeLinecap: 'round',
    strokeDasharray: `${circumference} ${circumference}`,
    fill: 'transparent',
    strokeDashoffset: circumference * (1 - (props.progress || 0)),
  }
})<ProgressRingCircleProps>`
  transform: rotate(-90deg);
  transform-origin: center;
  width: ${(props) => `${props.radius * 2}px`};
  height: ${(props) => `${props.radius * 2}px`};
  overflow: visible;

  @keyframes animate_checklist_ring {
    from {
      stroke-dashoffset: ${(props) => `${props.radius * 2 * Math.PI}`};
    }
  }

  animation: animate_checklist_ring 0.35s ease 1;
  transition: stroke-dashoffset 0.35s ease;
`
