import React from 'react'
import { StyledExternalLink } from './Link.styles'

type ExternalLinkProps = {
  text: string
  href: string
  openInNewTab: boolean
}

export const ExternalLink = ({
  text,
  href,
  openInNewTab,
}: ExternalLinkProps): JSX.Element => (
  <StyledExternalLink
    href={href}
    rel={openInNewTab ? 'noreferrer' : undefined}
    target={openInNewTab ? '_blank' : undefined}
  >
    {text}
  </StyledExternalLink>
)

ExternalLink.defaultProps = {
  openInNewTab: true,
}
