import {
  PURPOSE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { NAVBAR_ACCORDION_TITLES } from 'src/components/Reusable/Accordions/NavBar/NavBarAccordions.constants'
import {
  AuditBotAbsoluteRoutes,
  DirectRoutes,
  ReportScanAbsoluteRoutes,
  RoadmapAbsoluteRoutes,
  SecurityQuestionnaireAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'

export enum PERMISSIONS {
  ADMIN_ACTION = 'admin_action', // this could get more granular but it is a step in the right direction
  AUDITOR_ACTION = 'auditor_action',
  READ = 'read',
  EDIT = 'edit',
  EXPORT = 'export',
  ASK_QUESTIONS = 'ask_questions',
}

export const DEFAULT_REQUIRED_PERMISSIONS = [
  PERMISSIONS.READ,
  PERMISSIONS.EDIT,
  PERMISSIONS.EXPORT,
]

export enum ROLES {
  ADMIN = 'admin',
  USER = 'user',
  READONLY_STANDARD = 'readonly_standard',
  READONLY_TRUSTERO_DEMO = 'readonly_trustero_demo',
  AUDITOR = 'auditor',
  AUDITOR_QC = 'auditor_qc',
}

export type RoleConfig = {
  routeConfig: {
    denyRoutes: string[]
    hideNavSections: (typeof NAVBAR_ACCORDION_TITLES)[keyof typeof NAVBAR_ACCORDION_TITLES][]
    ccHideNavSections?: (typeof NAVBAR_ACCORDION_TITLES)[keyof typeof NAVBAR_ACCORDION_TITLES][]
    hideNavRoutes: string[]
    explicitAllowRoutes: string[]
    restrictToAuditView: boolean
  }
  bannerConfig: {
    showBanner: boolean
    bannerMessage: string
    linkText?: string
    internalLink?: string
    link?: string
  }
  permissionsConfig: PERMISSIONS[]
}

export const ROLE_CONFIG = <Record<ROLES, RoleConfig>>{
  [ROLES.ADMIN]: {
    routeConfig: {
      denyRoutes: [],
      hideNavSections: [],
      hideNavRoutes: [],
      explicitAllowRoutes: [],
      restrictToAuditView: false,
    },
    bannerConfig: {
      showBanner: false,
      bannerMessage: '',
    },
    permissionsConfig: [
      PERMISSIONS.ADMIN_ACTION,
      PERMISSIONS.AUDITOR_ACTION,
      PERMISSIONS.READ,
      PERMISSIONS.EDIT,
      PERMISSIONS.EXPORT,
      PERMISSIONS.ASK_QUESTIONS,
    ],
  },
  [ROLES.USER]: {
    routeConfig: {
      denyRoutes: [],
      hideNavSections: [],
      hideNavRoutes: [],
      explicitAllowRoutes: [],
      restrictToAuditView: false,
    },
    bannerConfig: {
      showBanner: false,
      bannerMessage: '',
    },
    permissionsConfig: [
      PERMISSIONS.AUDITOR_ACTION,
      PERMISSIONS.READ,
      PERMISSIONS.EDIT,
      PERMISSIONS.EXPORT,
      PERMISSIONS.ASK_QUESTIONS,
    ],
  },
  [ROLES.READONLY_STANDARD]: {
    routeConfig: {
      denyRoutes: [],
      hideNavSections: [],
      hideNavRoutes: [],
      explicitAllowRoutes: [],
      restrictToAuditView: false,
    },
    bannerConfig: {
      showBanner: true,
      bannerMessage: 'You have read-only access to this account.',
    },
    permissionsConfig: [PERMISSIONS.READ, PERMISSIONS.EXPORT],
  },
  [ROLES.READONLY_TRUSTERO_DEMO]: {
    routeConfig: {
      denyRoutes: [
        DirectRoutes.ACCOUNT_SETTINGS,
        DirectRoutes.USERS,
        DirectRoutes.SCOPE,
      ],
      hideNavSections: [],
      hideNavRoutes: [
        DirectRoutes.ACCOUNT_SETTINGS,
        DirectRoutes.USERS,
        DirectRoutes.SCOPE,
      ],
      explicitAllowRoutes: [],
      restrictToAuditView: false,
    },
    bannerConfig: {
      showBanner: true,
      bannerMessage:
        'This is a Trustero Demo Account. You have read-only access.',
      linkText: 'Switch Account',
      internalLink: `/${DirectRoutes.ACCOUNTS}`,
    },
    // NOTE: non-read apis will fail when called. but this is the correct behavior.
    permissionsConfig: [
      PERMISSIONS.AUDITOR_ACTION,
      PERMISSIONS.READ,
      PERMISSIONS.EDIT,
      PERMISSIONS.EXPORT,
      PERMISSIONS.ASK_QUESTIONS,
    ],
  },
  [ROLES.AUDITOR]: {
    routeConfig: {
      denyRoutes: [
        AuditBotAbsoluteRoutes.ROOT,
        SecurityQuestionnaireAbsoluteRoutes.ROOT,
        ReportScanAbsoluteRoutes.ROOT,
        RoadmapAbsoluteRoutes.ROOT,
      ],
      hideNavSections: [NAVBAR_ACCORDION_TITLES.ANALYZE],
      ccHideNavSections: [
        NAVBAR_ACCORDION_TITLES.HOME,
        NAVBAR_ACCORDION_TITLES.CONTENT,
        NAVBAR_ACCORDION_TITLES.ANALYZE,
      ],
      hideNavRoutes: [
        RoadmapAbsoluteRoutes.ROOT,
        RoadmapAbsoluteRoutes.INDEX,
        DirectRoutes.ACCOUNT_SETTINGS,
      ],
      explicitAllowRoutes: [DirectRoutes.AUDITS, DirectRoutes.ACCOUNTS],
      restrictToAuditView: true,
    },
    bannerConfig: {
      showBanner: true,
      bannerMessage:
        'You have auditor access. Please contact your account administrator if you need additional access.',
    },
    permissionsConfig: [
      PERMISSIONS.AUDITOR_ACTION,
      PERMISSIONS.READ,
      PERMISSIONS.EXPORT,
    ],
  },
  [ROLES.AUDITOR_QC]: {
    routeConfig: {
      denyRoutes: [
        AuditBotAbsoluteRoutes.ROOT,
        ReportScanAbsoluteRoutes.ROOT,
        RoadmapAbsoluteRoutes.ROOT,
      ],
      hideNavSections: [],
      ccHideNavSections: [
        NAVBAR_ACCORDION_TITLES.HOME,
        NAVBAR_ACCORDION_TITLES.CONTENT,
      ],
      hideNavRoutes: [
        RoadmapAbsoluteRoutes.ROOT,
        RoadmapAbsoluteRoutes.INDEX,
        DirectRoutes.ACCOUNT_SETTINGS,
        AuditBotAbsoluteRoutes.ROOT,
        AuditBotAbsoluteRoutes.INDEX,
        ReportScanAbsoluteRoutes.ROOT,
        ReportScanAbsoluteRoutes.INDEX,
      ],
      explicitAllowRoutes: [
        DirectRoutes.AUDITS,
        DirectRoutes.ACCOUNTS,
        SecurityQuestionnaireAbsoluteRoutes.INDEX,
        SecurityQuestionnaireAbsoluteRoutes.ROOT,
        SecurityQuestionnaireAbsoluteRoutes.SHOW,
      ],
      restrictToAuditView: true,
    },
    bannerConfig: {
      showBanner: true,
      bannerMessage:
        'You have auditor access. Please contact your account administrator if you need additional access.',
    },
    permissionsConfig: [
      PERMISSIONS.AUDITOR_ACTION,
      PERMISSIONS.READ,
      PERMISSIONS.EXPORT,
      PERMISSIONS.ASK_QUESTIONS,
    ],
  },
}

export const getRoleConfig = ({
  userRole,
  accountPurpose,
  hasQc,
  isLoading,
}: {
  userRole: USER_ROLE
  accountPurpose?: PURPOSE
  isLoading: boolean
  hasQc?: boolean // only used for auditor users
}): RoleConfig => {
  switch (userRole) {
    case USER_ROLE.ADMIN:
      return ROLE_CONFIG[ROLES.ADMIN]
    case USER_ROLE.USER:
      return ROLE_CONFIG[ROLES.USER]
    case USER_ROLE.READONLY:
      if (accountPurpose === PURPOSE.READONLY_ACCOUNT) {
        return ROLE_CONFIG[ROLES.READONLY_TRUSTERO_DEMO]
      } else {
        return ROLE_CONFIG[ROLES.READONLY_STANDARD]
      }
    case USER_ROLE.AUDITOR:
      if (hasQc || isLoading) {
        return ROLE_CONFIG[ROLES.AUDITOR_QC]
      } else {
        return ROLE_CONFIG[ROLES.AUDITOR]
      }
    default:
      return ROLE_CONFIG[ROLES.READONLY_STANDARD]
  }
}
