import React, { createContext, ReactNode, useContext, useState } from 'react'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import { formatTimestamp, hasDatePassed } from 'src/Utils/formatDate'
import { useCurrentAudit } from 'src/components/async/model/audit'

export type AuditContextType = {
  auditId?: string
  setAuditId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const AuditContext = createContext<AuditContextType>({
  auditId: undefined,
  setAuditId: () => null,
})

export const AuditProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [auditId, setAuditId] = useState<string>()

  return (
    <AuditContext.Provider
      value={{
        auditId,
        setAuditId: setAuditId,
      }}
    >
      {children}
    </AuditContext.Provider>
  )
}

/***
 * This is to replace having to import useContext and AuditContext in a component to check audit status
 * @params none
 * @returns { auditId, setAuditId } - auditId is undefined in CC and a string in audit
 */

export const useInAudit = (): AuditContextType => useContext(AuditContext)

export const usePastAudit = (): { isPastAudit: boolean; endDate: string } => {
  const { data: audit } = useCurrentAudit()
  const endDayTimestamp = audit?.getEndDate()

  return {
    isPastAudit: !!endDayTimestamp && hasDatePassed(endDayTimestamp),
    endDate: endDayTimestamp
      ? formatTimestamp(endDayTimestamp, DATE_FORMATS.MONTH_DAY)
      : '',
  }
}
