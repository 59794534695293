import React, { useMemo } from 'react'
import { FilterName, FilterParam } from '../FilterBar.types'
import { DocumentationFilterValues } from '../FilterBar.constants'
import { FilterDropdown } from './FilterDropdown'

export function HasDocumentationFilterDropdown({
  param,
  name,
}: {
  param: FilterParam.HAS_DOCUMENTATION | FilterParam.IS_CUSTOMIZED
  name: FilterName.HAS_DOCUMENTATION | FilterName.IS_CUSTOMIZED
}): JSX.Element {
  const filterValues = useMemo(
    () => [
      {
        value: DocumentationFilterValues.TRUE,
        label: 'True',
      },
      {
        value: DocumentationFilterValues.FALSE,
        label: 'False',
      },
    ],
    [],
  )

  return (
    <FilterDropdown
      filterParam={param}
      filterName={name}
      filterValues={filterValues}
    />
  )
}
