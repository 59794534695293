import styled from 'styled-components/macro'
import { compose, padding, border, color, textStyle } from 'styled-system'
import palette from '../../../designSystem/variables/palette'

export const TypeContainer = styled.div.attrs({
  mr: 'l',
  p: 0,
  border: '1px solid',
  borderColor: 'border.neutral.light',
  borderRadius: '2px',
})`
  ${compose(padding, border, color, textStyle)}
  flex-grow: 1;
  background-color: white;
  color: inherit;
  width: 100%;
  padding: 24px;
  text-align: left;

  p {
    font-family: Courier New;
    font-size: 14px;
    font-weight: 400;
  }
`

export const UploadContainer = styled(TypeContainer)`
  color: ${palette.neutral['900']};
  font-family: poppins;
  font-weight: 400;
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
  }
  li,
  p {
    font-family: poppins;
    font-size: 14px;
  }
`

export const LoadingUploadContainer = styled(UploadContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UploadHeader = styled.h2`
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 8px;
  display: flex;
  gap: 8px;
`

export const StyledP = styled.p`
  text-decoration: underline;
  white-space: nowrap;
  flex-grow: 1;
`

export const AdditionalDetailsContainer = styled.div`
  align-self: flex-start;
  article {
    margin-top: 0;
  }
  width: 100%;
`

export const DownloadFileContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const DownloadContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    font-size: 1em;
  }
`
