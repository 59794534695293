import React, { useEffect, useState } from 'react'
import { useFileTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { TextButton } from '../Buttons/TextButton'
import {
  ExpandButtonRow,
  PreviewDiv,
  StyledCloseButton,
  StyledEvidenceIframe,
  StyledEvidenceImage,
  StyledImagePreview,
  StyledPreviewModal,
  StyledPreviewModalIframe,
  StyledPreviewModalImage,
} from './DocumentViewer.styles'

export const ImagePreview = ({
  file,
  isEvidenceShowPage,
}: {
  file: File
  isEvidenceShowPage?: boolean
}): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [link, setLink] = useState<string | null>(null)
  const FileTypeIcon = useFileTypeIcon({ mime: file.type })

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  const isImage = file.type.startsWith('image/')
  const isPdf = file.type === 'application/pdf'

  useEffect(() => {
    const blob = new Blob([file], { type: file.type })
    const url = URL.createObjectURL(blob)
    setLink(url)

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [file])

  if ((!isImage && !isPdf) || !link) {
    return <></>
  }

  const hasNewEvidence = IsGatekeeperEnabled(GatekeeperFlag.NEW_EVIDENCE_TABLE)

  return (
    <>
      {isEvidenceShowPage && hasNewEvidence && (
        <ExpandButtonRow>
          <TextButton onClick={toggleModal}>Expand</TextButton>
        </ExpandButtonRow>
      )}
      {isImage ? (
        <>
          {hasNewEvidence && isEvidenceShowPage ? (
            <StyledEvidenceImage
              src={link as string}
              alt={file.name}
              onClick={toggleModal}
            />
          ) : (
            <PreviewDiv>
              <StyledImagePreview
                src={link}
                alt="Preview"
                onClick={toggleModal}
              />
            </PreviewDiv>
          )}
          {isModalOpen && (
            <StyledPreviewModal onClick={toggleModal}>
              <StyledPreviewModalImage
                src={link as string}
                alt="Expanded Preview"
              />
              <CloseButton />
            </StyledPreviewModal>
          )}
        </>
      ) : (
        <>
          {hasNewEvidence && isEvidenceShowPage ? (
            <StyledEvidenceIframe
              src={link as string}
              typeof="application/pdf"
            />
          ) : (
            <PreviewDiv onClick={toggleModal}>
              <FileTypeIcon width="120px" height="120px" />
              <p>View PDF</p>
            </PreviewDiv>
          )}
          {isModalOpen && (
            <StyledPreviewModal onClick={toggleModal}>
              <StyledPreviewModalIframe
                src={link as string}
                typeof="application/pdf"
              />
              <CloseButton />
            </StyledPreviewModal>
          )}
        </>
      )}
    </>
  )
}

export const CloseButton = (): JSX.Element => (
  <StyledCloseButton>close</StyledCloseButton>
)
