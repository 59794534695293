import React, { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'
import { margin, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { ReactComponent as UnassignedSVG } from '../Icons/assets/unassigned-icon.svg'
import { TrusteroLogoMiniSVG } from '../Icons/Brand'

type GravatarProps = MarginProps & {
  size: number
}

export const GravatarParent = styled.div<GravatarProps>`
  ${margin};
  position: relative;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  flex-shrink: 0;
  border-radius: 50%;
`

export const GravatarImg = styled.img<GravatarProps>`
  top: 0;
  left: 0;
  position: absolute;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: 50%;
  background-color: transparent !important;
`

export const DefaultIcon = styled.div<GravatarProps>`
  ${margin}
  top: 0;
  left: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  min-width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: 50%;
  color: white;
  font-size: ${(props) => `${props.size / 2}px !important`};
  font-weight: 500 !important;
  background-color: ${themeGet('colors.fill.primary.dark')} !important;
`

export const UnassignedIcon = styled(UnassignedSVG)<GravatarProps>`
  ${margin}
  min-width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
`

export const TrusteroAutomationIcon = styled(
  GravatarParent,
).attrs<GravatarProps>((props) => ({
  children: (
    <TrusteroLogoMiniSVG
      width={`${props.size * (16 / 24)}px`}
      height={`${props.size * (16 / 24)}px`}
    />
  ),
}))<PropsWithChildren<GravatarProps>>`
  ${margin}
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.colors?.fill.neutral.extraDark};
`
