import React from 'react'
import isFunction from 'lodash/isFunction'
import { useModalState } from 'src/Modal/ModalStateContext'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from '../Reusable/Buttons/StandardButton'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../Reusable/Tooltip/Tooltip'
import { ModalFormId } from './ModalForm'
import {
  ModalButton,
  StyledModalButton,
  StyledToggleDiv,
} from './ModalForm.styles'

type StandardOpenModalButtonProps = {
  modalId: ModalFormId
  text: string
  variant: StandardButtonVariant
  size: StandardButtonSize
  isHidden: boolean
  isDisabled: boolean
  onClick?: () => void
  children?: React.ReactNode
  testId?: string
  disabledReason?: string
  tooltipValue?: string
  requiredPermissions?: PERMISSIONS[]
}

export const StandardOpenModalButton = ({
  modalId,
  text,
  variant,
  size,
  isHidden,
  isDisabled,
  onClick,
  children,
  testId,
  disabledReason,
  tooltipValue,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
}: StandardOpenModalButtonProps): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)

  let tooltipText = disabledReason || tooltipValue
  let isButtonDisabled = isDisabled
  if (!hasPermission) {
    tooltipText = 'You do not have permission to perform this action.'
    isButtonDisabled = true
  }

  const { openModal } = useModalState()
  const onClickFunction = (e: React.MouseEvent) => {
    e.preventDefault()
    isFunction(onClick) && onClick()
    openModal(modalId)
  }

  return (
    <>
      <Tooltip
        id={text}
        tooltipBody={tooltipText}
        disabled={!tooltipText}
        overlayType={TooltipOverlayType.popover}
        placement={TooltipPositions.top}
      >
        <div>
          <StyledModalButton
            type="button"
            variant={variant}
            buttonSize={size}
            hidden={isHidden}
            disabled={isButtonDisabled}
            onClick={onClickFunction}
            data-testid={testId}
          >
            {children || text}
          </StyledModalButton>
        </div>
      </Tooltip>
    </>
  )
}

StandardOpenModalButton.defaultProps = {
  text: 'Continue',
  variant: StandardButtonVariant.PRIMARY,
  size: StandardButtonSize.MEDIUM,
  isHidden: false,
  isDisabled: false,
}

type LargeOpenModalButtonProps = {
  modalId: ModalFormId
  children: React.ReactNode
  onClick?: () => void
  testId?: string
}

export const LargeOpenModalButton = ({
  modalId,
  children,
  onClick,
  testId,
}: LargeOpenModalButtonProps): JSX.Element => {
  const { openModal } = useModalState()
  const onClickFunction = (e: React.MouseEvent) => {
    e.preventDefault()
    isFunction(onClick) && onClick()
    openModal(modalId)
  }

  return (
    <ModalButton data-testid={testId} onClick={onClickFunction}>
      {children}
    </ModalButton>
  )
}

type OpenModalWrapperProps = {
  modalFormId: ModalFormId
  children: React.ReactNode
  onClick?: () => void
}

export const OpenModalWrapper = ({
  modalFormId,
  children,
  onClick,
}: OpenModalWrapperProps): JSX.Element => {
  const { openModal } = useModalState()

  const onClickFunction = (e: React.MouseEvent) => {
    e.preventDefault()
    isFunction(onClick) && onClick()
    openModal(modalFormId)
  }
  return <StyledToggleDiv onClick={onClickFunction}>{children}</StyledToggleDiv>
}
