import themeGet from '@styled-system/theme-get'
import { CheckSVG, XSVG } from 'src/components/Icons/Basic'
import { TypeButton } from 'src/components/ModalForms/FileType/TypeSelector.styles'
import { StandardButton } from 'src/components/Reusable/Buttons'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const AddFileButton = styled(TypeButton)`
  width: 100%;
  padding: 2.25em 0;
  gap: 0.75em;
`

export const ReplaceFileButton = styled(StandardButton)`
  &:hover {
    cursor: pointer;
  }
`

export const ClearFileButton = styled(TextButton)`
  margin-left: 0.5em;
`

export const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${themeGet('colors.border.neutral.light')};
  height: 240px;
  gap: 10px;

  svg {
    width 80px;
    height: 80px;
  }

  .filename {
    ${themeGet('textStyles.subtitle.1')};
    margin-bottom: ${themeGet('space.xxs')}px;
    line-height: 21px;
  }

  .size {
    ${themeGet('textStyles.subtitle.caption')}
    line-height: 18px;
  }

  &:hover {
    cursor: pointer;
  }
`

// Wrapper for the loading spinner area
export const LoadingContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
`

// Wrapper for each DisplayField
export const DisplayFieldContainer = styled.div`
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 30px;
  align-items: center;
`

// Wrapper for the acknowledgment section
export const AcknowledgmentRow = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`

// Wrapper for DisplayField icons with right margin adjustment
export const IconWrapper = styled.div`
  margin-right: 5px;
`

// Styled component for the check icon in descriptions
export const StyledCheckIcon = styled(CheckSVG)`
  width: 20px;
`

// Styled component for the X icon in descriptions
export const StyledXIcon = styled(XSVG)`
  width: 20px;
  fill: ${palette.magenta['500']};
`

// Styling for the DisplayStatusContainer
export const DisplayStatusContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`
