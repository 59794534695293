import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { StyledIcon } from './index'

export function CopyIcon(): JSX.Element {
  return (
    <StyledIcon>
      <FontAwesomeIcon icon={faCopy} />
    </StyledIcon>
  )
}
