import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'styled-components/macro'
import { BrowserRouter } from 'react-router-dom'
import theme from '../designSystem/theme'
import { GlobalStyle } from '../globalStyles'
import { EnvConfig } from '../config/config'
import { GatekeeperProvider } from './Gatekeeper'
import { AuthProvider } from './authContext'
import { AnalyticsProvider } from './AnalyticsProvider'
import { SWRProvider } from './SWRProvider'

// Workaround for React 18 compatibility issues https://github.com/styled-components/styled-components/issues/3738
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalStyleProxy: any = GlobalStyle

export const AppProviders = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const childProviders = (
    <HelmetProvider>
      <CookiesProvider>{children}</CookiesProvider>
    </HelmetProvider>
  )

  return (
    <React.StrictMode>
      {/* @ts-ignore */}
      <ThemeProvider theme={theme}>
        <GlobalStyleProxy color="text.default" bg="bg.neutral" />
        <GatekeeperProvider>
          <AuthProvider>
            <BrowserRouter>
              <SWRProvider>
                {EnvConfig.gaTrackerId && EnvConfig.name ? (
                  <AnalyticsProvider>{childProviders}</AnalyticsProvider>
                ) : (
                  childProviders
                )}
              </SWRProvider>
            </BrowserRouter>
          </AuthProvider>
        </GatekeeperProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}
