import { NAV_ITEM_KEYS } from 'src/navbar/Navbar.constants'

export enum NAVBAR_ACCORDION_KEYS {
  HOME = '1',
  CONTENT = '2',
  AUDIT = '3',
  ANALYZE = '4',
  SETTINGS = '5',
}

export const NAVBAR_ACCORDION_TITLES = Object.freeze({
  HOME: 'HOME',
  CONTENT: 'CONTENT',
  AUDIT: 'AUDIT',
  ANALYZE: 'ANALYZE',
  SETTINGS: 'SETTINGS',
})

export const NAVBAR_ACCORDION_CONTENTS = {
  [NAVBAR_ACCORDION_KEYS.HOME]: [
    NAV_ITEM_KEYS.DASHBOARD,
    NAV_ITEM_KEYS.ROADMAP,
    NAV_ITEM_KEYS.HELP,
    NAV_ITEM_KEYS.SCOPE,
  ],
  [NAVBAR_ACCORDION_KEYS.CONTENT]: [
    NAV_ITEM_KEYS.SERVICES,
    NAV_ITEM_KEYS.RECEPTORS,
    NAV_ITEM_KEYS.COMPANY_INFO,
    NAV_ITEM_KEYS.POLICIES,
    NAV_ITEM_KEYS.CONTROLS,
    NAV_ITEM_KEYS.RISKS,
    NAV_ITEM_KEYS.EVIDENCE,
    NAV_ITEM_KEYS.VENDORS,
  ],
  [NAVBAR_ACCORDION_KEYS.AUDIT]: [NAV_ITEM_KEYS.AUDITS, NAV_ITEM_KEYS.REQUESTS],
  [NAVBAR_ACCORDION_KEYS.ANALYZE]: [
    NAV_ITEM_KEYS.QUESTIONNAIRE,
    NAV_ITEM_KEYS.KNOWLEDGE_BASE,
    NAV_ITEM_KEYS.REPORT_SCAN,
    NAV_ITEM_KEYS.AUDIT_BOT,
  ],
  [NAVBAR_ACCORDION_KEYS.SETTINGS]: [
    NAV_ITEM_KEYS.USERS,
    NAV_ITEM_KEYS.ACCOUNT_SETTINGS,
    NAV_ITEM_KEYS.ACCOUNTS,
  ],
}
