import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import styled from 'styled-components/macro'
import palette from 'src/designSystem/variables/palette'

import { ShowPageAccordionIcon } from '../ShowPageAccordions.components'

export const SectionAccordion = styled(Accordion)`
  padding-bottom: 20px;
`

export const SectionAccordionItem = styled(Accordion.Item)`
  border: none;
`

export const SectionAccordionHeader = styled(Accordion.Header).attrs(
  ({ $currentActiveKeys, $currentAccordionKey, children }) => {
    const isActive = $currentActiveKeys.includes($currentAccordionKey)
    return {
      forwardedAs: 'header',
      children: (
        <>
          {children}
          <ShowPageAccordionIcon isActive={isActive} />
        </>
      ),
    }
  },
)<{ $currentActiveKeys: string[]; $currentAccordionKey: string }>`
  .accordion-button {
    color: ${palette.blue[700]};
    font-size: 16px;
    font-weight: 500;
    display: flex;
    gap: 12px;
    padding: 16px 0 0;
    
    :focus,
    :hover,
    :not(.collapsed) {
      outline: none;
      background-color: unset;
      box-shadow: none;
    }
    
    ::after {
      display: none;
    }
    `

export const SectionAccordionBody = styled(Accordion.Body)`
  padding: 0;
`
