/**
 * @fileoverview gRPC-Web generated client stub for schedulemanager
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: schedulemanager/schedulemanager.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.schedulemanager = require('./schedulemanager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.schedulemanager.ScheduleManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.schedulemanager.ScheduleManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.schedulemanager.TickRequest,
 *   !proto.schedulemanager.TickResponse>}
 */
const methodDescriptor_ScheduleManager_Tick = new grpc.web.MethodDescriptor(
  '/schedulemanager.ScheduleManager/Tick',
  grpc.web.MethodType.UNARY,
  proto.schedulemanager.TickRequest,
  proto.schedulemanager.TickResponse,
  /**
   * @param {!proto.schedulemanager.TickRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.schedulemanager.TickResponse.deserializeBinary
);


/**
 * @param {!proto.schedulemanager.TickRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.schedulemanager.TickResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.schedulemanager.TickResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.schedulemanager.ScheduleManagerClient.prototype.tick =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/Tick',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_Tick,
      callback);
};


/**
 * @param {!proto.schedulemanager.TickRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.schedulemanager.TickResponse>}
 *     Promise that resolves to the response
 */
proto.schedulemanager.ScheduleManagerPromiseClient.prototype.tick =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/Tick',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_Tick);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.schedulemanager.GetScheduleRequest,
 *   !proto.schedulemanager.ScheduleRecord>}
 */
const methodDescriptor_ScheduleManager_GetSchedule = new grpc.web.MethodDescriptor(
  '/schedulemanager.ScheduleManager/GetSchedule',
  grpc.web.MethodType.UNARY,
  proto.schedulemanager.GetScheduleRequest,
  proto.schedulemanager.ScheduleRecord,
  /**
   * @param {!proto.schedulemanager.GetScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.schedulemanager.ScheduleRecord.deserializeBinary
);


/**
 * @param {!proto.schedulemanager.GetScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.schedulemanager.ScheduleRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.schedulemanager.ScheduleRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.schedulemanager.ScheduleManagerClient.prototype.getSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/GetSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_GetSchedule,
      callback);
};


/**
 * @param {!proto.schedulemanager.GetScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.schedulemanager.ScheduleRecord>}
 *     Promise that resolves to the response
 */
proto.schedulemanager.ScheduleManagerPromiseClient.prototype.getSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/GetSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_GetSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.schedulemanager.CreateUpdateScheduleRequest,
 *   !proto.schedulemanager.ScheduleRecord>}
 */
const methodDescriptor_ScheduleManager_CreateOrUpdateSchedule = new grpc.web.MethodDescriptor(
  '/schedulemanager.ScheduleManager/CreateOrUpdateSchedule',
  grpc.web.MethodType.UNARY,
  proto.schedulemanager.CreateUpdateScheduleRequest,
  proto.schedulemanager.ScheduleRecord,
  /**
   * @param {!proto.schedulemanager.CreateUpdateScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.schedulemanager.ScheduleRecord.deserializeBinary
);


/**
 * @param {!proto.schedulemanager.CreateUpdateScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.schedulemanager.ScheduleRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.schedulemanager.ScheduleRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.schedulemanager.ScheduleManagerClient.prototype.createOrUpdateSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/CreateOrUpdateSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_CreateOrUpdateSchedule,
      callback);
};


/**
 * @param {!proto.schedulemanager.CreateUpdateScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.schedulemanager.ScheduleRecord>}
 *     Promise that resolves to the response
 */
proto.schedulemanager.ScheduleManagerPromiseClient.prototype.createOrUpdateSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/CreateOrUpdateSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_CreateOrUpdateSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.schedulemanager.DeleteScheduleRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ScheduleManager_DeleteSchedule = new grpc.web.MethodDescriptor(
  '/schedulemanager.ScheduleManager/DeleteSchedule',
  grpc.web.MethodType.UNARY,
  proto.schedulemanager.DeleteScheduleRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.schedulemanager.DeleteScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.schedulemanager.DeleteScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.schedulemanager.ScheduleManagerClient.prototype.deleteSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/DeleteSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_DeleteSchedule,
      callback);
};


/**
 * @param {!proto.schedulemanager.DeleteScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.schedulemanager.ScheduleManagerPromiseClient.prototype.deleteSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/schedulemanager.ScheduleManager/DeleteSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleManager_DeleteSchedule);
};


module.exports = proto.schedulemanager;

