/**
 * @fileoverview gRPC-Web generated client stub for agent
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: agent/receptor.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var common_collections_pb = require('../common/collections_pb.js')
const proto = {};
proto.agent = require('./receptor_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.agent.ReceptorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.agent.ReceptorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorID,
 *   !proto.agent.ReceptorRecords>}
 */
const methodDescriptor_Receptor_GetReceptors = new grpc.web.MethodDescriptor(
  '/agent.Receptor/GetReceptors',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorID,
  proto.agent.ReceptorRecords,
  /**
   * @param {!proto.agent.ReceptorID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecords.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.getReceptors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/GetReceptors',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetReceptors,
      callback);
};


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecords>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.getReceptors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/GetReceptors',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetReceptors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorID,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_GetReceptor = new grpc.web.MethodDescriptor(
  '/agent.Receptor/GetReceptor',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorID,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.ReceptorID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.getReceptor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/GetReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetReceptor,
      callback);
};


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.getReceptor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/GetReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetReceptor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorRecord,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_CreateReceptor = new grpc.web.MethodDescriptor(
  '/agent.Receptor/CreateReceptor',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorRecord,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.ReceptorRecord} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorRecord} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.createReceptor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/CreateReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_CreateReceptor,
      callback);
};


/**
 * @param {!proto.agent.ReceptorRecord} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.createReceptor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/CreateReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_CreateReceptor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.StringMessage,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_SetCredentials = new grpc.web.MethodDescriptor(
  '/agent.Receptor/SetCredentials',
  grpc.web.MethodType.UNARY,
  proto.agent.StringMessage,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.StringMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.StringMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.setCredentials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/SetCredentials',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetCredentials,
      callback);
};


/**
 * @param {!proto.agent.StringMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.setCredentials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/SetCredentials',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetCredentials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.StringMessage,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_SetConfig = new grpc.web.MethodDescriptor(
  '/agent.Receptor/SetConfig',
  grpc.web.MethodType.UNARY,
  proto.agent.StringMessage,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.StringMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.StringMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.setConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/SetConfig',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetConfig,
      callback);
};


/**
 * @param {!proto.agent.StringMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.setConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/SetConfig',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.Enabled,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_SetIsEnabled = new grpc.web.MethodDescriptor(
  '/agent.Receptor/SetIsEnabled',
  grpc.web.MethodType.UNARY,
  proto.agent.Enabled,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.Enabled} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.Enabled} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.setIsEnabled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/SetIsEnabled',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetIsEnabled,
      callback);
};


/**
 * @param {!proto.agent.Enabled} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.setIsEnabled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/SetIsEnabled',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetIsEnabled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.VerifyRequest,
 *   !proto.agent.Tracer>}
 */
const methodDescriptor_Receptor_VerifyCredentials = new grpc.web.MethodDescriptor(
  '/agent.Receptor/VerifyCredentials',
  grpc.web.MethodType.UNARY,
  proto.agent.VerifyRequest,
  proto.agent.Tracer,
  /**
   * @param {!proto.agent.VerifyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.Tracer.deserializeBinary
);


/**
 * @param {!proto.agent.VerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.Tracer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.Tracer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.verifyCredentials =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/VerifyCredentials',
      request,
      metadata || {},
      methodDescriptor_Receptor_VerifyCredentials,
      callback);
};


/**
 * @param {!proto.agent.VerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.Tracer>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.verifyCredentials =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/VerifyCredentials',
      request,
      metadata || {},
      methodDescriptor_Receptor_VerifyCredentials);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorID,
 *   !proto.agent.Tracer>}
 */
const methodDescriptor_Receptor_ScanNow = new grpc.web.MethodDescriptor(
  '/agent.Receptor/ScanNow',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorID,
  proto.agent.Tracer,
  /**
   * @param {!proto.agent.ReceptorID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.Tracer.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.Tracer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.Tracer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.scanNow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/ScanNow',
      request,
      metadata || {},
      methodDescriptor_Receptor_ScanNow,
      callback);
};


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.Tracer>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.scanNow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/ScanNow',
      request,
      metadata || {},
      methodDescriptor_Receptor_ScanNow);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorID,
 *   !proto.agent.Tracer>}
 */
const methodDescriptor_Receptor_Discover = new grpc.web.MethodDescriptor(
  '/agent.Receptor/Discover',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorID,
  proto.agent.Tracer,
  /**
   * @param {!proto.agent.ReceptorID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.Tracer.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.Tracer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.Tracer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.discover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/Discover',
      request,
      metadata || {},
      methodDescriptor_Receptor_Discover,
      callback);
};


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.Tracer>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.discover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/Discover',
      request,
      metadata || {},
      methodDescriptor_Receptor_Discover);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.VerifyResult,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_SetIsCredValid = new grpc.web.MethodDescriptor(
  '/agent.Receptor/SetIsCredValid',
  grpc.web.MethodType.UNARY,
  proto.agent.VerifyResult,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.VerifyResult} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.VerifyResult} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.setIsCredValid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/SetIsCredValid',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetIsCredValid,
      callback);
};


/**
 * @param {!proto.agent.VerifyResult} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.setIsCredValid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/SetIsCredValid',
      request,
      metadata || {},
      methodDescriptor_Receptor_SetIsCredValid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Receptor_Delete = new grpc.web.MethodDescriptor(
  '/agent.Receptor/Delete',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.ReceptorID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/Delete',
      request,
      metadata || {},
      methodDescriptor_Receptor_Delete,
      callback);
};


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/Delete',
      request,
      metadata || {},
      methodDescriptor_Receptor_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ListIdsRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Receptor_ListIds = new grpc.web.MethodDescriptor(
  '/agent.Receptor/ListIds',
  grpc.web.MethodType.UNARY,
  proto.agent.ListIdsRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.agent.ListIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.agent.ListIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.listIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/ListIds',
      request,
      metadata || {},
      methodDescriptor_Receptor_ListIds,
      callback);
};


/**
 * @param {!proto.agent.ListIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.listIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/ListIds',
      request,
      metadata || {},
      methodDescriptor_Receptor_ListIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ReceptorID,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_Deactivate = new grpc.web.MethodDescriptor(
  '/agent.Receptor/Deactivate',
  grpc.web.MethodType.UNARY,
  proto.agent.ReceptorID,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.ReceptorID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.deactivate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/Deactivate',
      request,
      metadata || {},
      methodDescriptor_Receptor_Deactivate,
      callback);
};


/**
 * @param {!proto.agent.ReceptorID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.deactivate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/Deactivate',
      request,
      metadata || {},
      methodDescriptor_Receptor_Deactivate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.agent.ListInProgressScansResponse>}
 */
const methodDescriptor_Receptor_ListInProgressScans = new grpc.web.MethodDescriptor(
  '/agent.Receptor/ListInProgressScans',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.agent.ListInProgressScansResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ListInProgressScansResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ListInProgressScansResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ListInProgressScansResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.listInProgressScans =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/ListInProgressScans',
      request,
      metadata || {},
      methodDescriptor_Receptor_ListInProgressScans,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ListInProgressScansResponse>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.listInProgressScans =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/ListInProgressScans',
      request,
      metadata || {},
      methodDescriptor_Receptor_ListInProgressScans);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.UpdateConfigRequest,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Receptor_UpdateConfig = new grpc.web.MethodDescriptor(
  '/agent.Receptor/UpdateConfig',
  grpc.web.MethodType.UNARY,
  proto.agent.UpdateConfigRequest,
  proto.agent.ReceptorRecord,
  /**
   * @param {!proto.agent.UpdateConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.agent.UpdateConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.updateConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/UpdateConfig',
      request,
      metadata || {},
      methodDescriptor_Receptor_UpdateConfig,
      callback);
};


/**
 * @param {!proto.agent.UpdateConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.updateConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/UpdateConfig',
      request,
      metadata || {},
      methodDescriptor_Receptor_UpdateConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetControlMappingsForReceptorRequest,
 *   !proto.agent.GetControlMappingsForReceptorResponse>}
 */
const methodDescriptor_Receptor_GetControlMappingsForReceptor = new grpc.web.MethodDescriptor(
  '/agent.Receptor/GetControlMappingsForReceptor',
  grpc.web.MethodType.UNARY,
  proto.agent.GetControlMappingsForReceptorRequest,
  proto.agent.GetControlMappingsForReceptorResponse,
  /**
   * @param {!proto.agent.GetControlMappingsForReceptorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.GetControlMappingsForReceptorResponse.deserializeBinary
);


/**
 * @param {!proto.agent.GetControlMappingsForReceptorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.GetControlMappingsForReceptorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.GetControlMappingsForReceptorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.getControlMappingsForReceptor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/GetControlMappingsForReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetControlMappingsForReceptor,
      callback);
};


/**
 * @param {!proto.agent.GetControlMappingsForReceptorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.GetControlMappingsForReceptorResponse>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.getControlMappingsForReceptor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/GetControlMappingsForReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetControlMappingsForReceptor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetHasReceptorRequest,
 *   !proto.agent.GetHasReceptorResponse>}
 */
const methodDescriptor_Receptor_GetHasReceptor = new grpc.web.MethodDescriptor(
  '/agent.Receptor/GetHasReceptor',
  grpc.web.MethodType.UNARY,
  proto.agent.GetHasReceptorRequest,
  proto.agent.GetHasReceptorResponse,
  /**
   * @param {!proto.agent.GetHasReceptorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.GetHasReceptorResponse.deserializeBinary
);


/**
 * @param {!proto.agent.GetHasReceptorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.GetHasReceptorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.GetHasReceptorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.ReceptorClient.prototype.getHasReceptor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Receptor/GetHasReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetHasReceptor,
      callback);
};


/**
 * @param {!proto.agent.GetHasReceptorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.GetHasReceptorResponse>}
 *     Promise that resolves to the response
 */
proto.agent.ReceptorPromiseClient.prototype.getHasReceptor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Receptor/GetHasReceptor',
      request,
      metadata || {},
      methodDescriptor_Receptor_GetHasReceptor);
};


module.exports = proto.agent;

