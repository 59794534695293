import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { INDEX_PAGE_WITH_FILTERS } from '../IndexPage.constants'

export type FilterBarConfig = {
  department: boolean
  policy: boolean
  status: boolean
  owner: boolean
  documentation: boolean
  complianceFramework: boolean
  audit: boolean
  risk: boolean
  receptor: boolean
  serviceRole: boolean
  riskTier: boolean
  attestation: boolean
  smartChecks: boolean
  source: boolean
  fileType: boolean
  dateRange: boolean
  control: boolean
  addedBy: boolean
}

type FilterBarConfigType = {
  [key in INDEX_PAGE_WITH_FILTERS]: FilterBarConfig
}

export const FilterBarConfigs: FilterBarConfigType = {
  [INDEX_PAGE_WITH_FILTERS.CONTROLS]: {
    department: true,
    policy: true,
    status: true,
    owner: true,
    documentation: false,
    complianceFramework: true,
    audit: true,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.CONTROLS_AI]: {
    department: true,
    policy: true,
    status: true,
    owner: true,
    documentation: false,
    complianceFramework: true,
    audit: true,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: true,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.POLICIES]: {
    department: true,
    policy: false,
    status: false,
    owner: true,
    documentation: true,
    complianceFramework: true,
    audit: true,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.RISKS]: {
    department: true,
    policy: false,
    status: true,
    owner: true,
    documentation: false,
    complianceFramework: false,
    audit: false,
    risk: true,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.REQUESTS]: {
    department: false,
    policy: false,
    status: true,
    owner: true,
    documentation: false,
    complianceFramework: false,
    audit: false,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.SERVICES]: {
    department: false,
    policy: false,
    status: false,
    owner: false,
    documentation: false,
    complianceFramework: false,
    audit: false,
    risk: false,
    receptor: true,
    serviceRole: true,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.RECEPTORS]: {
    department: false,
    policy: false,
    status: true,
    owner: false,
    documentation: false,
    complianceFramework: false,
    audit: false,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.VENDORS]: {
    department: false,
    policy: false,
    status: false,
    owner: false,
    documentation: false,
    complianceFramework: false,
    audit: false,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: true,
    attestation: true,
    smartChecks: false,
    source: false,
    fileType: false,
    dateRange: false,
    control: false,
    addedBy: false,
  },
  [INDEX_PAGE_WITH_FILTERS.EVIDENCE]: {
    department: false,
    policy: false,
    status: false,
    owner: false,
    documentation: false,
    complianceFramework: false,
    audit: false,
    risk: false,
    receptor: false,
    serviceRole: false,
    riskTier: false,
    attestation: false,
    smartChecks: false,
    source: true,
    fileType: true,
    dateRange: true,
    control: true,
    addedBy: true,
  },
}

export enum RiskFilterValues {
  NOT_SET = 'not_set',
  SET = 'set',
}

export enum DocumentationFilterValues {
  TRUE = 'true',
  FALSE = 'false',
}

export const NONE_VALUE = {
  value: NONE_ID,
  label: NONE_NAME,
}
