import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled, { keyframes } from 'styled-components/macro'
import { useModalState } from 'src/Modal/ModalStateContext'
import { ReactComponent as FileUploadIcon } from 'src/components/Icons/assets/file-upload-icon.svg'
import { P } from 'src/components/Reusable/Text/Text.styles'
import palette from 'src/designSystem/variables/palette'
import { useDragAndDrop } from './DragAndDrop.context'

const springBounce = keyframes`
  0% {
    transform: scale(.4);
  }
  100% {
    transform: scale(1);
  }
`

const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`

const AnimatedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${springBounce} 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
`

export const GlobalDragAndDropOverlay: React.FC = () => {
  const { setFiles, targetModal } = useDragAndDrop()
  const [isDragActive, setIsDragActive] = useState(false)
  const { openModal, activeModal } = useModalState()
  const dragCounter = React.useRef(0)

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      openModal(targetModal)
      setIsDragActive(false)
      setFiles(acceptedFiles)
      dragCounter.current = 0
    },
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    const handleDragEnter = (event: DragEvent) => {
      if (event.dataTransfer?.types.includes('Files')) {
        dragCounter.current += 1
        if (dragCounter.current === 1) {
          setIsDragActive(true)
        }
      }
    }

    const handleDragLeave = (event: DragEvent) => {
      if (event.dataTransfer?.types.includes('Files')) {
        dragCounter.current -= 1
        if (dragCounter.current === 0) {
          setIsDragActive(false)
        }
      }
    }

    const handleDrop = (event: DragEvent) => {
      if (event.dataTransfer?.types.includes('Files')) {
        setIsDragActive(false)
        dragCounter.current = 0
      }
    }

    window.addEventListener('dragenter', handleDragEnter)
    window.addEventListener('dragleave', handleDragLeave)
    window.addEventListener('drop', handleDrop)

    return () => {
      window.removeEventListener('dragenter', handleDragEnter)
      window.removeEventListener('dragleave', handleDragLeave)
      window.removeEventListener('drop', handleDrop)
    }
  }, [])

  return activeModal || !targetModal ? null : (
    <div {...getRootProps()}>
      <Overlay isVisible={isDragActive}>
        <AnimatedContent>
          <FileUploadIcon />
          <P $isBold $fontSize={14} $color={palette.neutral.white}>
            Upload Files
          </P>
        </AnimatedContent>
      </Overlay>
      <input {...getInputProps()} />
    </div>
  )
}
