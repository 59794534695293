import React from 'react'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { Dropdown } from '../../Reusable/Dropdown'
import { ToolbarGroup } from '../styles'

type TextStyleControlsProps = {
  editorRef: React.RefObject<Editor>
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
}

export default function TextStyleControls({
  editorRef,
  setEditorState,
}: TextStyleControlsProps): JSX.Element {
  const items = [
    { id: 'unstyled', value: 'Normal Text', info: 'unstyled' },
    { id: 'header-one', value: 'Heading 1', info: 'header-one' },
    { id: 'header-two', value: 'Heading 2', info: 'header-two' },
  ]

  const toggleBlockType = (selectedBlockType: string) => {
    editorRef?.current?.focus()
    setEditorState((editorState) =>
      RichUtils.toggleBlockType(editorState, selectedBlockType),
    )
  }

  return (
    <ToolbarGroup>
      <Dropdown
        items={items}
        initVal="Normal Text"
        selectCB={toggleBlockType}
        containerProps={{
          height: '100%',
        }}
      />
    </ToolbarGroup>
  )
}
