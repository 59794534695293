/* eslint-disable @typescript-eslint/no-empty-interface */
import { unstable_serialize, useSWRConfig } from 'swr'
import { Message } from 'google-protobuf'
import { useCallback, useContext } from 'react'
import { AuthContext } from '../../context/authContext'
import { getCacheKey, GrpcCall } from './utils'

/**
 *
 * A hook that returns a method to refresh cached grpc methods by method name.
 *
 * Adapted from
 * https://swr.vercel.app/docs/advanced/cache#mutate-multiple-keys-from-regex
 *
 * @param asyncCall The type is unknown to bypass type-checking, as the actual
 *   type is generic. The type is the same as the asyncCall parameter used in
 *   AsyncComponent
 */
export function useGrpcRevalidateByMethod(): (
  asyncCall: unknown,
) => Promise<unknown> {
  const { cache, mutate } = useSWRConfig()
  const {
    authCtx: { accountId },
  } = useContext(AuthContext)

  return useCallback(
    (asyncCall: unknown) => {
      if (!(cache instanceof Map)) {
        throw new Error(
          'matchMutate requires the cache provider to be a Map instance',
        )
      }
      const matcherKey = getCacheKey(accountId, asyncCall)
      return Promise.all(
        Array.from(cache.keys())
          .filter((key) => key.includes(matcherKey[0]))
          .map((key) => mutate(key)),
      )
    },
    [cache, mutate, accountId],
  )
}

/**
 * # useGrpcRevalidateByRequest
 * A hook that returns a method to refresh cached grpc methods by request name.
 * Since grpc service methods have for the most part a 1-1 relationship with
 * their request object type, this is can work as a shorter version of
 * useGrpcRevalidateByMethodRequest.
 *
 *
 * ## Advantages of using this update mechanism
 * Since the hash is invariant of the request type, this method works well
 * for mutating service calls with the same request content.
 * For example, the grpc service `Model` has the following methods:
 *  ```
 *  service Model {
 *    rpc ListIds(ListModelsRequest) returns (common.StringList)
 *
 *    rpc ListControls(model.ListControlsRequest) returns (model.Controls);
 *
 *    rpc ListControlIds(model.ListControlsRequest) returns
 * (common.StringList);
 *  }
 *  ```
 *  While the type of the request for these methods is different, a request
 * like:
 *  ```
 *  {
 *    "model_ids":  ['trc1']
 *  }
 *  ```
 *  Would be hashed to the same value.
 *
 * ## Cases where this assumption will break
 * APIS where the request is the same but the APIs are semantically different.
 *
 * ```
 * service Receptor {
 *  rpc GetReceptors(google.protobuf.Empty) returns (ReceptorRecords);
 * }
 * service Account {
 *  rpc Get(google.protobuf.Empty) returns (AccountRecord);
 * ```
 * For such casesUse `useGrpcRevalidateByMethod` instead
 *
 * In cases where the request is a generic
 *
 * ## References:
 *
 *  [usSwr](https://swr.vercel.app/docs/advanced/cache#mutate-multiple-keys-from-regex)
 *  [hash
 * implementation](https://github.com/vercel/swr/blob/7dfd89081d818cba940b7d6bc786e9cdcba24c8e/src/utils/hash.ts#L20)
 *
 * @param request an instance of `jsp.Message` use in a grpc service call
 */
export function useGrpcRevalidateByRequest(): <Request extends Message>(
  request: Request,
  delay?: number,
) => Promise<void> {
  const { cache, mutate } = useSWRConfig()
  return useCallback(
    async <Request extends Message>(request: Request, delay?: number) => {
      if (!(cache instanceof Map)) {
        throw new Error(
          'matchMutate requires the cache provider to be a Map instance',
        )
      }
      const hash = unstable_serialize(request.toObject())
      await Promise.all(
        Array.from(cache.keys())
          .filter((key) => key.includes(hash))
          .map((key) => mutate(key), delay),
      )
    },
    [cache, mutate],
  )
}

export function useGrpcRevalidateByMethodRequest(): <Request, Response>(
  asyncCall: GrpcCall<Request, Response>,
  request: Request,
  delay?: number,
) => Promise<Response> {
  const { mutate } = useSWRConfig()
  const { authCtx } = useContext(AuthContext)
  return useCallback(
    <Request, Response>(
      asyncCall: GrpcCall<Request, Response>,
      request: Request,
    ): Promise<Response> => {
      const key = unstable_serialize(
        getCacheKey(authCtx.accountId, asyncCall, request),
      )
      return mutate(key) as Promise<Response>
    },
    [authCtx.accountId, mutate],
  )
}

export function useUpdateCache(): <
  Request extends Message,
  Response extends Message,
>(
  asyncCall: GrpcCall<Request, Response>,
  request: Request,
  response: Response,
) => Promise<Response> {
  const { mutate } = useSWRConfig()
  const { authCtx } = useContext(AuthContext)
  // @ts-ignore
  return (asyncCall, request, response) => {
    const cacheKey = unstable_serialize(
      getCacheKey(authCtx.accountId, asyncCall, request),
    )
    return mutate(cacheKey, response, false)
  }
}

export function useGetFromCache(): <
  Request extends Message,
  Response extends Message,
>(
  asyncCall: GrpcCall<Request, Response>,
  request: Request,
) => Promise<Response> {
  const { cache } = useSWRConfig()
  const { authCtx } = useContext(AuthContext)
  // @ts-ignore
  return (asyncCall, request) => {
    return cache.get(
      unstable_serialize(getCacheKey(authCtx.accountId, asyncCall, request)),
    )
  }
}
