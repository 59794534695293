import { faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { StyledIcon } from './index'

export const BrokenLinkIcon = (): JSX.Element => {
  return (
    <StyledIcon>
      <FontAwesomeIcon icon={faLinkSlash} height={24} width={24} />
    </StyledIcon>
  )
}
