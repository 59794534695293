import React, { useEffect, useState } from 'react'
import {
  ControlsFilter,
  GetAuditsRequest,
} from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { Range } from 'react-date-range'
import { Dispatch, SetStateAction } from 'react'
import { useAudits } from 'src/components/async/model/audit/useAudit'
import { TrusteroDateRange } from 'src/components/Reusable/TrusteroDateRange'
import { ExternalLink } from 'src/components/Reusable/Text/Link'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { AuditsSVG } from 'src/components/Icons/Basic'
import { FlexAlign, FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { Spinner } from 'src/Throbber'
import {
  PinkInfoBox,
  PinkInfoBoxBodyColumn,
  PinkInfoBoxHeader,
} from 'src/components/Reusable/Text/PinkInfoBox.styles'
import { isRelevantDateInAudit } from '../../evidence.helpers'
import { EvidenceAuditGrid } from './RelevantDateForm.components'
import { NoAuditsLabel, RelevantDateFormLabel } from './RelevantDateForm.styles'

export const RelevantDateForm = ({
  range,
  setRange,
  errorSection,
  controlIds,
}: {
  range: Range
  setRange: Dispatch<SetStateAction<Range>>
  errorSection?: JSX.Element
  controlIds?: string[]
}): JSX.Element => {
  const [inclusiveAudits, setInclusiveAudits] = useState<string[]>([])
  const request = new GetAuditsRequest().setIsClosed(
    new BoolValue().setValue(false),
  )
  controlIds &&
    controlIds.length > 0 &&
    request.setControlsFilter(
      new ControlsFilter().setControlIdsList(controlIds),
    )
  const hasRelevantDateForm = IsGatekeeperEnabled(
    GatekeeperFlag.RELEVANT_DATE_FORM,
  )
  const { data, isLoading } = useAudits(request, hasRelevantDateForm)
  const audits = (data?.getItemsList() || []).sort((a, b) => {
    const startDateA = a.getStartDate()?.getSeconds() || 0
    const startDateB = b.getStartDate()?.getSeconds() || 0
    return startDateA - startDateB
  })

  useEffect(() => {
    if (!data || !range.endDate) return
    const controlAudits = data.getItemsList()
    const inclusiveAudits = controlAudits
      .filter((audit) => isRelevantDateInAudit(audit, range.endDate))
      .map((audit) => audit.getId())
    setInclusiveAudits(inclusiveAudits)
  }, [data, range.endDate])

  if (isLoading) {
    return <Spinner size="l" color="primary" />
  }

  const onSelectAuditStart = (auditId: string) => {
    const audit = audits.find((audit) => audit.getId() === auditId)
    const startDate = audit?.getStartDate()
    if (!startDate) {
      return
    }
    setRange({
      startDate: new Date(),
      endDate: startDate.toDate(),
    })
    const newInclusiveAudits = audits
      .filter((audit) => isRelevantDateInAudit(audit, range.endDate))
      .map((audit) => audit.getId())
    setInclusiveAudits(newInclusiveAudits)
  }

  const onSelectAuditEnd = (auditId: string) => {
    const audit = audits.find((audit) => audit.getId() === auditId)
    const endDate = audit?.getEndDate()
    if (!endDate) {
      return
    }
    setRange({
      startDate: new Date(),
      endDate: endDate.toDate(),
    })
    const newInclusiveAudits = audits
      .filter((audit) => isRelevantDateInAudit(audit, range.endDate))
      .map((audit) => audit.getId())
    setInclusiveAudits(newInclusiveAudits)
  }

  const relevantDateLink = (
    <ExternalLink
      href={HUBSPOT.RELEVANT_DATE_DOCUMENTATION}
      text="Learn more"
    />
  )

  const datePickerLabel: string | JSX.Element = audits.length ? (
    <RelevantDateFormLabel>
      Or set the relevant date manually:
    </RelevantDateFormLabel>
  ) : (
    <FlexRow align={FlexAlign.CENTER} justify={FlexAlign.FLEX_START}>
      <NoAuditsLabel>
        Relevant Date. Useful when you&apos;re adding evidence outside the audit
        period. {relevantDateLink}
      </NoAuditsLabel>
    </FlexRow>
  )

  return (
    <FlexColumn align={FlexAlign.FLEX_START}>
      {audits.length > 0 && (
        <>
          <PinkInfoBox>
            <PinkInfoBoxHeader>
              <AuditsSVG width="16px" height="16px" />
              Relevant Date Determines Audit Inclusion
            </PinkInfoBoxHeader>
            <PinkInfoBoxBodyColumn>
              The relevant date of the evidence determines whether it is
              included in an audit. Below, you can select an audit to set the
              relevant date to the audit&apos;s start or end date, or you can
              set the relevant date manually. If the evidence is linked to a
              control that is part of an audit, and the relevant date falls
              within the audit&apos;s date range, the evidence will be included
              in the audit.
              <ExternalLink
                href={HUBSPOT.RELEVANT_DATE_DOCUMENTATION}
                text="Learn more"
              />
            </PinkInfoBoxBodyColumn>
          </PinkInfoBox>
          <RelevantDateFormLabel>
            Select an audit below to set the relevant date to the audit&apos;s
            start or end date:
          </RelevantDateFormLabel>
          <EvidenceAuditGrid
            audits={audits}
            inclusiveAudits={inclusiveAudits}
            onSelectAuditStart={onSelectAuditStart}
            onSelectAuditEnd={onSelectAuditEnd}
          />
          <br />
        </>
      )}
      {datePickerLabel}
      <TrusteroDateRange
        single
        range={range}
        rangeSet={setRange}
        dateDisplayFormat={'yyyy-MM-dd h:mm a'}
      />
      {errorSection}
    </FlexColumn>
  )
}
