import React, { ReactElement } from 'react'
import { ACTOR_TYPE } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useContent } from 'src/context/hooks'
import { Overview } from 'src/xgenerated'
import { Gravatar } from '../../components/Gravatar'
import formatDate from '../../Utils/formatDate'
import { LogEntry } from '../../adapter'
import { useUsers } from '../../components/async/Users/useUsers'
import {
  LogActor,
  LogDetails,
  LogItemContainer,
  LogText,
  LogTime,
} from './Changelog.styles'
import { Subject } from './Subject'

export const useActorName = (actor: string, type: ACTOR_TYPE): string => {
  const { data } = useUsers()
  const { usersList } = data?.toObject() || { usersList: [] }
  const user = usersList.find((user) => user.email === actor)
  if (type === ACTOR_TYPE.USER) {
    return user?.name ? user.name : actor
  }
  if (type === ACTOR_TYPE.TRUSTERO) {
    return 'Trustero'
  }
  if (type === ACTOR_TYPE.RECEPTOR) {
    return actor
  }
  return 'Unknown'
}

export type LogItemProps = {
  logItem: LogEntry
  borderBottom?: boolean
}

export const LogItem = ({
  logItem,
  borderBottom,
}: LogItemProps): ReactElement => {
  const actorName = useActorName(logItem.actor, logItem.actortype)
  const content = useContent()
  if (logItem.subjectmodeltype === MODEL_TYPE.OVERVIEW) {
    content.overviews.forEach((overview: Overview) => {
      if (overview.id !== logItem.subjectmodelid) return
      logItem.subjectmodelname = new StringValue()
        .setValue(overview.name)
        .toObject()
    })
  }

  return (
    <LogItemContainer borderBottom={borderBottom}>
      <Gravatar email={logItem.actor} emptyIsUnassigned={false} />
      <LogDetails>
        <LogActor>{actorName}</LogActor>
        <LogText>{logItem.message}</LogText>
        <Subject
          actorName={actorName}
          subjectModelName={logItem.subjectmodelname?.value}
          verb={logItem.message.split(' ')[0]}
          message={logItem.message}
          modelId={logItem.subjectmodelid}
          modelType={logItem.subjectmodeltype}
          attachmentId={logItem.attachmentid}
          attachmentType={logItem.attachmenttype}
        />
        <LogTime>{formatDate(logItem.createdat)}</LogTime>
      </LogDetails>
    </LogItemContainer>
  )
}
