import React, { ComponentType } from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import queryString from 'query-string'
import { ModalFormId, ModalFormIdQueryParam } from './ModalForm'
/**
 * @deprecated
 * Please use StandardOpenModalButton, LargeOpenModalButton, or OpenModalWrapper instead.
 * we are moving away from using url params to control modals
 */
export const ModalFormToggle = <T,>({
  modalFormId,
  optQueries,
  as,
  replace,
  children,
  ...rest
}: Omit<T, 'to'> &
  Omit<LinkProps, 'to' | 'component'> & {
    modalFormId: ModalFormId
    optQueries?: { [key: string]: string } | undefined
    as?: ComponentType<T & Omit<LinkProps, 'component' | 'innerRef'>>
  }): JSX.Element => {
  const location = useLocation()

  const url = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...queryString.parse(location.search, {
          arrayFormat: 'bracket',
        }),
        [ModalFormIdQueryParam]: modalFormId,
        ...optQueries,
      },
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <ToggleLink
      as={as as unknown as undefined} // TODO: Cleanup this alias
      to={url}
      replace={replace}
      {...rest}
    >
      {children}
    </ToggleLink>
  )
}

const ToggleLink = styled(Link)<LinkProps>``
