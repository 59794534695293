import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { TabType } from '../../../../components/Reusable/Tabs'
import { Spinner } from '../../../../Throbber'
import { useEvidenceGenerators } from '../../../../components/async/attachment/useEvidenceGenerators'
import { TabBody } from './TabBody'
import { TabNote } from './TabNote'

export const useConnectedTab = ({
  controlId,
  isNotApplicable,
}: {
  controlId: string
  isNotApplicable: boolean
}): TabType => {
  const response = useEvidenceGenerators(MODEL_TYPE.CONTROL, controlId, true)
  const count = response.data?.getItemsList().length ?? (
    <Spinner size="s" color="primary" />
  )

  return {
    title: 'Automated Evidence',
    count,
    note: <TabNote controlId={controlId} isNotApplicable={isNotApplicable} />,
    body: (
      <TabBody
        controlId={controlId}
        evidenceGenerators={response.data?.getItemsList() ?? []}
      />
    ),
  }
}
