import { Message } from 'google-protobuf'
import * as grpcWeb from 'grpc-web'
import { useContext } from 'react'
import { AuthContext } from '../../context/authContext'

export type ResponseProps<Response> = {
  response: Response
  mutate: () => Promise<Response | undefined>
}

export type GrpcCall<Request, Response> = (
  r: Request,
  metadata?: grpcWeb.Metadata,
) => Promise<Response>

// Create a separate Mock type to avoid relying on a dev dependency.
type Mock = {
  getMockName(): string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isMock(object: any): object is Mock {
  return 'getMockName' in object
}

/**
 * Generates the cache key passed to useSWR when making grpc requests.
 * The key is composed of a string representation of the GRPC call, and
 * a request object.
 *
 * @param accountId
 * @param asyncCall the GRPC call. The unknown type is a hack to circumvent
 *                  type-checking when called by useGrpcMutate as generics in
 *                  high-order functions get a big tricky
 * @param request The request object that will be passed to the call in the SWR
 *                fetcher lambda.
 */
export function getCacheKey<Request>(
  accountId: string,
  asyncCall: unknown,
  request?: Request,
): unknown[] {
  let asyncCallStr = `${asyncCall}`
  // Use the mock name if the async call is a mocked object so that requests
  // with the same method and requests are not assigned the same cache key.
  if (isMock(asyncCall) && asyncCall.getMockName() !== 'jest.fn()') {
    asyncCallStr = asyncCall.getMockName()
  }

  let from = asyncCallStr.indexOf('/')
  if (from < 0) {
    from = asyncCallStr.indexOf('"')
    from = from >= 0 ? from : 0
  }
  let to = asyncCallStr.lastIndexOf("'")
  if (to < 0) {
    to = asyncCallStr.lastIndexOf('"')
    to = to >= 0 ? to : asyncCallStr.length - 1
  }

  const key: unknown[] = [asyncCallStr.slice(from, to)]
  if (request) {
    key.push(isMessage(request) ? request.toObject() : request)
  }
  key.push(accountId)
  return key
}

export function useGetCacheKey<Request>(
  asyncCall: unknown,
  request?: Request,
): unknown[] {
  const { authCtx } = useContext(AuthContext)
  return getCacheKey(authCtx.accountId, asyncCall, request)
}

export function useGetCacheKeys<Request extends Message>(
  props: {
    asyncCall: unknown
    request?: Request
  }[],
): unknown[] {
  const { authCtx } = useContext(AuthContext)
  return props.map(({ asyncCall, request }) =>
    getCacheKey(authCtx.accountId, asyncCall, request),
  )
}

/**
 * Generates the cache key passed to useSWRInfinite when making grpc requests.
 * Similar to the getCacheKey() function but will an additional parameter for
 * page index. This is useful since the entire cache key is passed to the
 * fetcher method and can be used for making requests.
 */
export function useGetInfiniteCacheKey<Request>(): (
  asyncCall: unknown,
  request: Request,
  pageIndex: number,
) => unknown[] {
  const { authCtx } = useContext(AuthContext)

  return (asyncCall: unknown, request: Request, pageIndex: number) => [
    ...getCacheKey(authCtx.accountId, asyncCall, request),
    pageIndex,
  ]
}

function isMessage(obj: unknown | undefined): obj is Message {
  return obj !== undefined && (obj as Message).toObject !== undefined
}
