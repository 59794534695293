import styled from 'styled-components/macro'
import { GridColumn } from '../Grid'
import { EmptyStateTextOnlySize } from './EmptyStateTextOnly.constants'

export const EmptyStateTextColumn = styled(GridColumn)<{
  $size?: EmptyStateTextOnlySize
}>`
  font-size: ${({ $size }) => {
    switch ($size) {
      case EmptyStateTextOnlySize.SMALL:
        return '16px'
      case EmptyStateTextOnlySize.MEDIUM:
        return '20px'
      case EmptyStateTextOnlySize.LARGE:
      default:
        return '28px'
    }
  }};
  padding: 24px;
  grid-column: 1 / -1;
  justify-content: center;
`
