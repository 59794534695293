import React from 'react'
import { useCurrentAudit } from '../../../components/async/model/audit'
import { useControl } from '../../../components/async/model'

type RemoveFromAuditProps = {
  controlModelId: string
}

export function RemoveControlFromFromAudit({
  controlModelId,
}: RemoveFromAuditProps): JSX.Element {
  const { data: audit } = useCurrentAudit()
  const { data } = useControl(controlModelId)
  return (
    <p>
      {data?.getName()} will be removed from the audit {audit?.getName()}. This{' '}
      {"won't"} affect other open audits or your overall continuous compliance.
    </p>
  )
}
