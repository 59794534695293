import React from 'react'
import log from 'loglevel'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { EditableMarkupOnClick } from 'src/components/Reusable/Inputs'
import { Markup } from 'src/components/Reusable/Text/Markup'
import { useCreateOrUpdateRisks } from '../risks.hooks'

export const RisksNotes = ({ risk }: { risk: Risk }): JSX.Element => {
  const updateRisk = useCreateOrUpdateRisks()

  const id = risk.getId()
  const note = risk.getNotes()

  const onSave = async (newNote: string) => {
    try {
      if (note === newNote || !risk) {
        return
      }
      risk.setNotes(newNote)
      await updateRisk([risk])
    } catch (err) {
      log.error(`Failed to update note for riskId: ${id}`, err)
    }
  }

  return (
    <EditableMarkupOnClick initialValue={note} onSave={onSave}>
      {note.length ? <Markup markdown={note} /> : <p>None</p>}
    </EditableMarkupOnClick>
  )
}
