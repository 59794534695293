/**
 * This is our legacy handling for Google Analytics
 * We have now switched to a Segment -> Mixpanel approach
 *
 * Please use our `useAnalytics` hook as of Feb 2023
 */

import ReactGA from 'react-ga'
import md5 from 'md5'
import { isNullOrEmtpy } from '../Utils'
import { EventCategory, UserAction } from './analytics.constants'

export class GoogleAnalyticsService {
  constructor(
    private gaId: string,
    private testMode: boolean = false,
    private debug: boolean = false,
  ) {}

  init(): void {
    ReactGA.initialize(this.gaId, {
      debug: this.debug,
      testMode: this.testMode,
      titleCase: false,
      gaOptions: {
        cookieDomain: window.location.hostname,
      },
    })
  }

  pageView(pathName: string): void {
    ReactGA.pageview(pathName)
  }

  event(category: EventCategory, action: UserAction, value?: string): void {
    ReactGA.event({
      category,
      action: action.toString() + value ? `:${value}` : '',
    })
  }

  updateUser(id?: string): void {
    const userId = isNullOrEmtpy(id) ? '' : `${md5(id as string)}`
    ReactGA.set({
      userId,
    })
    !isNullOrEmtpy(id) &&
      ReactGA.ga('send', 'event', 'authentication', 'user-id available')
  }
}
