import React, {
  ReactElement,
  ReactNode,
  ReactNodeArray,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../Buttons'
import { ButtonGroup, EditableOnClickContainer } from './Inputs.styles'

export interface BaseEditableOnClickProps {
  initialValue: string
  onSave: (newValue: string) => Promise<void>
  children: ReactNode | ReactNodeArray
  isObjective?: boolean
  requiredPermissions?: PERMISSIONS[]
}

export interface EditableOnClickProps extends BaseEditableOnClickProps {
  inline?: boolean
  editMode: (props: {
    value?: string
    onChange: (s?: string) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  }) => ReactElement
}

export interface NoButtonsEditableOnClickProps
  extends BaseEditableOnClickProps {
  height?: number
}

/**
 * generally you should use EditOnClick instead
 */
export const EditableOnClick = ({
  inline,
  initialValue,
  editMode,
  onSave,
  children,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
}: EditableOnClickProps): JSX.Element => {
  const hasPermissions = useHasRequiredPermissions(requiredPermissions)
  const [isEditing, toggleEditMode] = useReducer((p) => !p, false)
  const [value, setValue] = useState<string | undefined>(initialValue)
  const [isEmpty, setIsEmpty] = useState<boolean>(initialValue === '')

  useEffect(() => {
    setValue(initialValue)
    setIsEmpty(initialValue === '')
  }, [initialValue])

  return (
    <EditableOnClickContainer
      inline={inline}
      isEditing={isEditing}
      isEmpty={isEmpty}
      onClick={() => !isEditing && toggleEditMode()}
    >
      {hasPermissions && isEditing ? (
        <>
          {editMode({ onChange: setValue, value })}
          <ButtonGroup inline={inline}>
            <StandardButton
              variant={StandardButtonVariant.PRIMARY}
              buttonSize={StandardButtonSize.SMALL}
              onClick={() => {
                if (!value?.trim()) {
                  setValue('')
                  setIsEmpty(true)
                  onSave('')
                } else {
                  setValue(value.trim())
                  setIsEmpty(false)
                  onSave(value.trim())
                }
                toggleEditMode()
              }}
            >
              Save
            </StandardButton>
            <StandardButton
              variant={StandardButtonVariant.PRIMARY}
              buttonSize={StandardButtonSize.SMALL}
              onClick={() => {
                setValue(initialValue)
                toggleEditMode()
              }}
            >
              Cancel
            </StandardButton>
          </ButtonGroup>
        </>
      ) : (
        <>{children}</>
      )}
    </EditableOnClickContainer>
  )
}
