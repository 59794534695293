import React, { Dispatch, SetStateAction } from 'react'
import { GridColumn } from '../../Grid'
import { Title } from '../../Text/Title'
import { CheckBoxInput } from '../../Inputs/CheckBox/CheckBoxInput'
import { MultiSelectRow } from './MultiSelectFormComponents.styles'

export const SelectVendorsRowItem = ({
  vendorId,
  vendorName,
  selectedVendorIds,
  setSelectedVendorIds,
}: {
  vendorId: string
  vendorName: string
  selectedVendorIds: string[]
  setSelectedVendorIds: Dispatch<SetStateAction<string[]>>
}): JSX.Element => {
  const selectedVendors = new Set(selectedVendorIds)

  const onChangeHandler = () => {
    selectedVendors.has(vendorId)
      ? selectedVendors.delete(vendorId)
      : selectedVendors.add(vendorId)
    setSelectedVendorIds(Array.from(selectedVendors))
  }

  return (
    <MultiSelectRow
      id={`select-vendors-item-${vendorId}`}
      onClick={onChangeHandler}
    >
      <GridColumn>
        <CheckBoxInput
          id={vendorId}
          checked={selectedVendors.has(vendorId)}
          onClick={(e) => e.stopPropagation()}
          onChange={onChangeHandler}
        />
      </GridColumn>
      <GridColumn>
        <Title name={vendorName} />
      </GridColumn>
    </MultiSelectRow>
  )
}
