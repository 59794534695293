import React from 'react'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

export const SmartCheckFilterValues = {
  FAILING: 'failing',
  PASSING: 'passing',
  STALE: 'stale',
}

const SmartCheckFilterLabels = {
  [SmartCheckFilterValues.FAILING]: 'Has Issues',
  [SmartCheckFilterValues.PASSING]: 'Passing',
  [SmartCheckFilterValues.STALE]: 'Out of Date',
}

export const SmartChecksFilterDropdown = (): JSX.Element => {
  const filterValues = [
    {
      value: SmartCheckFilterValues.FAILING,
      label: SmartCheckFilterLabels[SmartCheckFilterValues.FAILING],
    },
    {
      value: SmartCheckFilterValues.PASSING,
      label: SmartCheckFilterLabels[SmartCheckFilterValues.PASSING],
    },
    {
      value: SmartCheckFilterValues.STALE,
      label: SmartCheckFilterLabels[SmartCheckFilterValues.STALE],
    },
  ]

  return (
    <FilterDropdown
      filterParam={FilterParam.SMART_CHECKS}
      filterName={FilterName.SMART_CHECKS}
      filterValues={filterValues}
    />
  )
}
