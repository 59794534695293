import React, { Dispatch, SetStateAction } from 'react'
import { DerivedTextArea } from '../../../Modal/Type/styles'
import { DocumentFormData, DocumentType } from './AddDocumentForm'

type DocumentLinkProps = {
  formData: DocumentFormData
  setFormData: Dispatch<SetStateAction<DocumentFormData>>
  isValidLink: boolean
  formId: string
  type: DocumentType
}

const placeholder = `Please enter a valid url that begins with "https://" or "http://"`
const errMsg = `Please ensure your url begins with "https://" or "http://"`

export function DocumentLink({
  formData,
  setFormData,
  isValidLink,
  formId,
  type,
}: DocumentLinkProps): JSX.Element {
  return (
    <>
      {type === DocumentType.LINK && (
        <DerivedTextArea
          required
          name="body"
          initVal={formData.body as string}
          placeholder={placeholder}
          form={formId}
          setFormData={
            setFormData as Dispatch<SetStateAction<{ [key: string]: string }>>
          }
          isValid={isValidLink}
          errorMessage={type === DocumentType.LINK ? errMsg : undefined}
        />
      )}
    </>
  )
}
