import React, { useCallback, useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { getCognitoAuthGrant, msRedirect } from 'src/Utils/oidc'
import Cookies from 'js-cookie'
import { COOKIES } from 'src/Utils/globalEnums'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { DirectRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { P } from 'src/components/Reusable/Text/Text.styles'
import log from 'loglevel'
import palette from 'src/designSystem/variables/palette'
import { TrusteroLogoWhiteImg } from 'src/components/Icons/Brand'
import { useHandleSignupLogout } from 'src/context/authContext'
import { LoginProviders } from '../Login/LoginPage.constants'
import { LoginPageContainer } from '../Login/LoginPage.styles'
import {
  Auth0Icon,
  EntraIdIcon,
  GoogleIcon,
  LoginButton,
  MicrosoftIcon,
  OktaIcon,
  OneLoginIcon,
} from '../Login/Login.components'
import { useAttemptEmailLogin } from '../Login/EmailLogin/EmailLogin.hooks'
import {
  ButtonContainer,
  SignupFormContainer,
  StyledHeaderContainer,
  StyledLineContent,
  StyledLink,
  StyledNonLink,
  StyledSubHeader,
  StyledText,
} from './SignUpPage.styles'
import { SignUpProps } from './SignUpPage.constants'
import {
  useAttemptEmailSignup,
  useReceiveCognitoVerificationCode,
  useResendVerificationCode,
} from './SignUpPage.hooks'
import { SignupPageInput } from './SignUpPage.components'

export const SignUpPage = ({
  header,
  subHeader,
  description,
  signupType,
  redirectUrl,
}: SignUpProps): JSX.Element => {
  const { track, events } = useAnalytics()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState<string>('')
  const [color, setColor] = useState<string>(palette.green[500])
  const [code, setCode] = useState('')
  const [token, setToken] = useState('')
  const [showOptions, setShowOptions] = useState<boolean | null>(null) // null is loading
  const [showCode, setShowCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const handleEmailSignup = useAttemptEmailSignup()
  const handleVerificationAttempt = useReceiveCognitoVerificationCode()
  const resendVerificationCode = useResendVerificationCode()
  const ensureLogout = useHandleSignupLogout()
  useAttemptEmailLogin({ token, redirectUrl })

  const handleColorChange = (pass: boolean) =>
    setColor(pass ? palette.green[500] : palette.magenta[500])

  const createUser = useCallback(
    async (email: string) => {
      const trimEmail = email.trim()

      if (!trimEmail.length || !trimEmail.includes('@')) {
        handleColorChange(false)
        setMessage('Please enter a valid email address')
        return
      }
      let response
      try {
        setIsLoading(true)
        response = await handleEmailSignup(trimEmail)
        setShowOptions(false)
        setShowCode(response.getIsVerificationNeeded())
        setMessage(response.getMessage())
        handleColorChange(response.getIsResponseOk())
        setIsLoading(false)
      } catch (err) {
        log.error(
          `error occurred when attempting to create a user (cognito email signup) email: ${trimEmail} err: ${err}`,
        )
        setMessage(
          'An error has occurred. Please try again or email support@trustero.com for assistance.',
        )
        handleColorChange(false)
        setIsLoading(false)
      }
    },
    [handleEmailSignup],
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const emailParam = params.get('email')
    if (emailParam) {
      setShowOptions(false)
      setEmail(emailParam)
      createUser(emailParam)
    } else {
      setShowOptions(true)
    }
  }, [createUser, location.search])

  const handleVerification = async () => {
    try {
      setIsLoading(true)
      const response = await handleVerificationAttempt({ email, code })
      setMessage(response.getMessage())
      if (
        response.getIsCodeAccepted() ||
        response.getIsLoginIssued() ||
        response.getToken().length
      ) {
        setShowCode(false)
        handleColorChange(true)
      } else {
        setShowCode(true)
        handleColorChange(false)
      }
      if (response.getToken()) {
        setToken(response.getToken())
      }
      setIsLoading(false)
    } catch (err) {
      log.error(
        `error occurred when attempting to verify user code (cognito email signup) for email: ${email} err: ${err}`,
      )
      setMessage(
        'An error has occurred. Please try again or email support@trustero.com for assistance.',
      )
      handleColorChange(false)
      setIsLoading(false)
    }
  }

  const handleResendVerificationCode = async () => {
    try {
      setIsLoading(true)
      const response = await resendVerificationCode(email)
      setMessage(response.getMessage())
      handleColorChange(response.getIsCodeIssued())
      setIsLoading(false)
    } catch (err) {
      log.error(
        `error occurred when attempting to verify user code (cognito email signup) for email: ${email} err: ${err}`,
      )
      setMessage(
        'An error has occurred. Please try again or email support@trustero.com for assistance.',
      )
      handleColorChange(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    // make sure that the user is logged out
    ensureLogout()
    // This is used to determine if a user logged in from the signup page
    // This allows us to open a welcome back modal for the user
    if (!Cookies.get(COOKIES.FROM_SIGNUP)) {
      Cookies.set(COOKIES.FROM_SIGNUP, 'true')
    }
  }, [ensureLogout])

  // This will tell us that the user clicked to go back to the `trustero.com` website
  const fireWebsiteEvent = () => track(events.C_SIGNUP_WEBSITE, { signupType })

  const trackOIDC = (provider: string) =>
    track(events.C_SIGNUP_OIDC, { provider, signupType })

  const isQuestionnaire = location.pathname.includes(
    DirectRoutes.SECURITY_QUESTIONNAIRE_SIGNUP,
  )

  const clearForm = () => {
    setShowOptions(true)
    setShowCode(false)
    setMessage('')
  }

  return (
    <LoginPageContainer>
      <SignupFormContainer $isLargeContainer={isQuestionnaire}>
        <StyledHeaderContainer>
          {showOptions ? (
            <StyledLink onClick={fireWebsiteEvent} href="https://trustero.com/">
              <FontAwesomeIcon icon={faArrowLeft} />
              Trustero Website
            </StyledLink>
          ) : (
            <StyledNonLink onClick={clearForm}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back
            </StyledNonLink>
          )}
          <TrusteroLogoWhiteImg />
          <h1>{header}</h1>
        </StyledHeaderContainer>
        <StyledSubHeader>{subHeader}</StyledSubHeader>
        <StyledText>{description}</StyledText>
        {showOptions && (
          <>
            <ButtonContainer>
              <LoginButton
                leftIcon={<GoogleIcon />}
                onClickFunc={() =>
                  getCognitoAuthGrant({
                    provider: LoginProviders.GOOGLE,
                    isSignup: true,
                    track: trackOIDC.bind(LoginProviders.GOOGLE),
                    signupType,
                  })
                }
                providerName="Google"
                isSignUp
              />
              <LoginButton
                leftIcon={<Auth0Icon />}
                onClickFunc={() =>
                  getCognitoAuthGrant({
                    provider: LoginProviders.OKTA, // Auth0 & Okta are the same now, but users may not know that
                    isSignup: true,
                    track: trackOIDC.bind(LoginProviders.AUTH0),
                    signupType,
                  })
                }
                providerName="Auth0"
                isSignUp
              />
              <LoginButton
                leftIcon={<OktaIcon />}
                onClickFunc={() =>
                  getCognitoAuthGrant({
                    provider: LoginProviders.OKTA,
                    isSignup: true,
                    track: trackOIDC.bind(LoginProviders.OKTA),
                    signupType,
                  })
                }
                providerName="Okta"
                isSignUp
              />
              <LoginButton
                leftIcon={<OneLoginIcon />}
                onClickFunc={() =>
                  getCognitoAuthGrant({
                    provider: LoginProviders.ONELOGIN,
                    isSignup: true,
                    track: trackOIDC.bind(LoginProviders.ONELOGIN),
                    signupType,
                  })
                }
                providerName="OneLogin"
                isSignUp
              />
              <LoginButton
                leftIcon={<MicrosoftIcon />}
                onClickFunc={() =>
                  msRedirect(false, signupType, () =>
                    trackOIDC(LoginProviders.MICROSOFT),
                  )
                }
                providerName="Microsoft"
                isSignUp
              />
              <LoginButton
                leftIcon={<EntraIdIcon />}
                onClickFunc={() =>
                  msRedirect(true, signupType, () =>
                    trackOIDC(LoginProviders.ENTRA),
                  )
                }
                providerName="Microsoft Entra ID"
                isSignUp
              />
            </ButtonContainer>
            <StyledLineContent>or</StyledLineContent>
            <SignupPageInput
              handleSubmit={() => createUser(email)}
              value={email}
              setValue={setEmail}
              placeholder="Sign Up with Email"
              isLoading={isLoading}
            />
          </>
        )}
        {message && (
          <P $isCentered $isBold $color={color} $fontSize={20}>
            {message}
          </P>
        )}
        {showCode && (
          <>
            <SignupPageInput
              handleSubmit={handleVerification}
              value={code}
              setValue={setCode}
              placeholder="Enter verification code"
              isLoading={isLoading}
            />
            <P
              $pointer
              $color={palette.neutral.white}
              $isUnderline
              $fontSize={14}
              onClick={handleResendVerificationCode}
            >
              Resend code
            </P>
          </>
        )}
      </SignupFormContainer>
    </LoginPageContainer>
  )
}
