import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components/macro'
import palette from '../../designSystem/variables/palette'
import { TrusteroLogoFullImg } from '../../components/Icons/Brand'
import { TrusteroLogoWhiteImg } from '../../components/Icons/Brand'
import { GridHeader } from '../../components/Reusable/Grid'

export const TrusteroBeaker = styled(FontAwesomeIcon).attrs({
  icon: faFlask,
  color: palette.magenta[300],
})`
  font-size: 18px;
`

export const LabsP = styled.p`
  font-size: 18px;
  font-weight: 300;
`

export const LabsTrusteroLogo = styled(TrusteroLogoFullImg)`
  height: 18px;
`

export const WhiteLabsTrusteroLogo = styled(TrusteroLogoWhiteImg)`
  height: 18px;
`

export const OwnerGridHeader = styled(GridHeader)`
  text-align: left;
  left: -1.6rem;
`
