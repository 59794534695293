import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import { GrpcWebClientBase, Metadata } from 'grpc-web'
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { useContext, useMemo } from 'react'
import { useAuthHeader } from '../../context/authContext'
import { NTRCE_API_URL } from '../../adapter/gRpcAdapter'
import { stringValue } from '../../Utils'
import { AuditContext } from '../../context/AuditContext'
import { GrpcCall, useGetCacheKey } from './utils'
import { queue } from './queue'

export function callGrpc<Request, Response>(
  asyncCall: GrpcCall<Request, Response>,
  request: Request,
  header: Metadata,
): Promise<Response> {
  return asyncCall.call(
    {
      client_: new GrpcWebClientBase({ format: 'text' }),
      hostname_: NTRCE_API_URL,
    },
    request,
    header,
  )
}

export type SwrGrpcResponse = SWRConfiguration & {
  ignoreAuditContext?: boolean
  persistSize?: boolean
  revalidateFirstPage?: boolean
}

// Helper hook for executing a GRPC request with the SWR library.
export function useSwrGrpc<Request, Response>(
  asyncCall: GrpcCall<Request, Response>,
  request: Request,
  shouldFetch = true,
  config?: SwrGrpcResponse,
): { response: SWRResponse<Response>; cacheKey: unknown[] } {
  const cacheKey = useGetCacheKey(asyncCall, request)
  const header = useAuthHeader()
  const { auditId } = useContext(AuditContext)
  const contextualisedRequest = useMemo(() => {
    if (config?.ignoreAuditContext) {
      return request
    }
    if (!isAuditDependent(request) || !auditId) {
      return request
    }

    // Allow user to set their own audit id and don't override it
    if (request.getAuditId()?.getValue() !== undefined) {
      return request
    }

    return request.setAuditId(stringValue(auditId))
  }, [auditId, config?.ignoreAuditContext, request])

  const response = useSWR(
    shouldFetch ? cacheKey : null,
    () => queue.add(() => callGrpc(asyncCall, contextualisedRequest, header)),
    config,
  )
  // @ts-ignore
  return { response, cacheKey }
}

type AuditDependent<T> = T & {
  getAuditId(): google_protobuf_wrappers_pb.StringValue | undefined
  setAuditId(value?: google_protobuf_wrappers_pb.StringValue): AuditDependent<T>
}

export function isAuditDependent<T>(p: T): p is AuditDependent<T> {
  if (
    p instanceof Object &&
    !!(p as Record<string, unknown>)['setAuditId'] &&
    !!(p as Record<string, unknown>['getAuditId'])
  ) {
    const request = p as AuditDependent<T>
    return typeof request.getAuditId() !== 'string'
  }

  return false
}
