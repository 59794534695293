import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useMemo,
  useState,
} from 'react'
import { PolicyRecord } from '@trustero/trustero-api-web/lib/model/policy_pb'
import styled from 'styled-components/macro'
import { Framework } from '@trustero/trustero-api-web/lib/model/framework_pb'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import {
  useHideModal,
  useIsShowModal,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import {
  DerivedResizableTextArea,
  DerivedTextInput,
} from 'src/components/Reusable/Inputs'
import { RpcError } from 'grpc-web'
import { boolValue, stringValue } from 'src/Utils/primitives'
import {
  CONTROL_STATUS,
  ControlStatusValue,
  CreateUpdateControlRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { FlexRow } from 'src/components/Reusable/Flex'
import { ModalForm, ModalFormId } from '../../../../../components/ModalForms'
import { FieldLabel } from '../../../../../components/ModalForms/ModalForm.styles'
import {
  MultipleFrameworkDropdown,
  PolicyDropdown,
} from '../../../../../components/ModalForms/Models/Dropdowns'
import { Spinner } from '../../../../../Throbber'
import { ComplianceFrameworkBasesDropdown } from '../../../../../components/ModalForms/Models/Dropdowns'
import {
  getErrorMessage,
  isFullySupportedFramework,
} from '../../../../../Utils/globalHelpers'
import { FRAMEWORK_MODEL_IDS } from '../../../../../Utils/globalEnums'
import { useCreateCustomControl } from './useCreateCustomControl'

export const SectionDivider = styled.hr`
  margin: 0 0 1rem 0;
`

const MAX_LENGTHS = {
  model_id: 10,
  name: 200,
}

const PLACEHOLDERS = {
  name: "Enter a short name you'll see around the app",
  objective: 'Enter exact wording for the control',
  controlId:
    'Unique Control IDs give short reliable identifiers to each control. For example, use AC-01 for your first Access Control',
}

export const AddCustomControlModal = (): JSX.Element => {
  const [error, setError] = useState({
    objective: '',
    modelId: '',
  })
  const [name, setName] = useState<string>('')
  const [modelId, setModelId] = useState<string>('')
  const [objective, setObjective] = useState<string>('')
  const [policy, setPolicy] = useState<PolicyRecord.AsObject>()
  const [frameworks, setFrameworks] = useState<Framework.AsObject[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedComplianceFrameworks, setSelectedComplianceFrameworks] =
    useState<ComplianceFramework[]>([])

  const onHide = useHideModal({
    onHide: () => {
      setError({
        objective: '',
        modelId: '',
      })
      setModelId('')
      setName('')
      setObjective('')
      setPolicy(undefined)
      setFrameworks([])
      setSelectedComplianceFrameworks([])
    },
    modalId: ModalFormId.ADD_CUSTOM_CONTROL,
  })
  const show = useIsShowModal(ModalFormId.ADD_CUSTOM_CONTROL)
  const back = useSetActiveModal(ModalFormId.ADD_CONTROL_OPTIONS)
  const formSet = useMemo(() => {
    const updateObjectives = (complianceFrameworks: ComplianceFramework[]) => {
      const newComplianceFrameworkIds = new Set(
        complianceFrameworks.map((complianceFramework) =>
          complianceFramework.getId(),
        ),
      )
      const newFrameworks: Framework.AsObject[] = []
      for (const framework of frameworks) {
        if (newComplianceFrameworkIds.has(framework.complianceFrameworkId)) {
          newFrameworks.push(framework)
        }
      }
      setFrameworks(newFrameworks)
    }
    return {
      policy:
        (selectedPolicy: PolicyRecord.AsObject) =>
        (e: MouseEvent<HTMLElement>) => {
          e.preventDefault()
          setPolicy(selectedPolicy)
        },
      frameworks: (selectedFramework: Framework.AsObject) => () => {
        setFrameworks((state) => {
          const newState = [...state]
          const idx = newState.findIndex(
            (framework) => framework.modelId === selectedFramework.modelId,
          )
          idx !== -1
            ? newState.splice(idx, 1)
            : newState.push(selectedFramework)
          return newState
        })
      },
      modelId: ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        setModelId(currentTarget?.value || '')
      },
      name: ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
        setName(currentTarget?.value || '')
      },
      objective: ({ currentTarget }: ChangeEvent<HTMLTextAreaElement>) => {
        setObjective(currentTarget.value)
      },
      complianceFramework: (complianceFramework: ComplianceFramework) => () => {
        setSelectedComplianceFrameworks((prevState): ComplianceFramework[] => {
          const newState = [...prevState]
          const idx = newState.findIndex((framework) => {
            return framework.getId() === complianceFramework.getId()
          })
          idx !== -1
            ? newState.splice(idx, 1)
            : newState.push(complianceFramework)
          updateObjectives(newState)
          return newState
        })
      },
    }
  }, [frameworks])

  const createCustomControl = useCreateCustomControl()

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    const newModelId = modelId.trim()
    e.preventDefault()
    setError({
      objective: objective ? '' : 'Objective is required',
      modelId: newModelId ? '' : 'Control ID is required',
    })
    if (!objective || !newModelId) {
      return
    }
    let resError: RpcError | undefined
    setLoading(true)
    const request = new CreateUpdateControlRequest()
      .setModelId(stringValue(newModelId))
      .setDescription(stringValue(''))
      .setIsCustom(boolValue(true))
      .setIsDismissed(boolValue(false))
      .setStatus(
        new ControlStatusValue().setValue(CONTROL_STATUS.NEEDSATTENTION),
      )
      .setPolicyId(stringValue(policy?.modelId))
      .setFrameworkIds(
        new StringList().setItemList(frameworks.map((f) => f.modelId)),
      )
      .setName(stringValue(name))
      .setObjective(stringValue(objective))
      .setComplianceFrameworkIds(
        new StringList().setItemList(
          selectedComplianceFrameworks.map((f) => f.getModelId()),
        ),
      )
    try {
      await createCustomControl(request)
    } catch (e) {
      resError = e as RpcError
      const errorMessage = getErrorMessage(resError)
      errorMessage &&
        setError((prev) => ({
          ...prev,
          modelId: errorMessage.message,
        }))
    } finally {
      setLoading(false)
      !resError && onHide()
    }
  }

  return (
    <ModalForm
      show={show}
      hide={onHide}
      formId={ModalFormId.ADD_CUSTOM_CONTROL}
      size="xl"
      title="Add Custom Control"
      onBack={back}
      submitText="Add Control"
    >
      <form id={ModalFormId.ADD_CUSTOM_CONTROL} onSubmit={onSubmitHandler}>
        {loading ? (
          <FlexRow>
            <Spinner color="primary" size="m" />
          </FlexRow>
        ) : (
          <>
            <fieldset>
              <DerivedTextInput
                label="Control ID"
                name="modelId"
                initVal={modelId}
                placeholder={PLACEHOLDERS.controlId}
                form={ModalFormId.ADD_CUSTOM_CONTROL}
                customOnChange={formSet.modelId}
                errorMessage={error.modelId}
                isValid={!error.modelId}
                maxInputLength={MAX_LENGTHS.model_id}
              />
            </fieldset>
            <fieldset>
              <DerivedTextInput
                label="Name"
                name="name"
                initVal={name}
                placeholder={PLACEHOLDERS.name}
                form={ModalFormId.ADD_CUSTOM_CONTROL}
                customOnChange={formSet.name}
                maxInputLength={MAX_LENGTHS.name}
              />
            </fieldset>
            <fieldset>
              <DerivedResizableTextArea
                label="Objective"
                name="body"
                initVal={objective}
                placeholder={PLACEHOLDERS.objective}
                errorMessage={error.objective}
                isValid={!error.objective}
                form={ModalFormId.ADD_CUSTOM_CONTROL}
                customOnChange={formSet.objective}
              />
            </fieldset>
            <SectionDivider />
            <fieldset>
              <FieldLabel>
                Policy
                <PolicyDropdown
                  selectedItem={policy?.name}
                  onPolicySelected={formSet.policy}
                />
              </FieldLabel>
            </fieldset>
            <fieldset>
              <FieldLabel message="Please select a Compliance Framework">
                Compliance Framework
                <ComplianceFrameworkBasesDropdown
                  selectedComplianceFrameworks={selectedComplianceFrameworks}
                  onComplianceFrameworkSelected={formSet.complianceFramework}
                  showCount={!!selectedComplianceFrameworks.length}
                />
              </FieldLabel>
            </fieldset>
            {selectedComplianceFrameworks.map((complianceFramework) => {
              // AP-5006 TODO: Clean up this map
              const descriptor = {
                lower: complianceFramework.getName().startsWith('SOC')
                  ? 'criteria'
                  : complianceFramework.getName().startsWith('ISO')
                  ? 'objective'
                  : 'section',
                capital: complianceFramework.getName().startsWith('SOC')
                  ? 'SOC 2 Criteria'
                  : complianceFramework.getName().startsWith('ISO')
                  ? 'ISO 27001 Objective'
                  : 'HIPAA Section',
              }
              const id = complianceFramework.getId()
              const modelId = complianceFramework.getModelId()
              const hasFullSupport = isFullySupportedFramework(
                modelId as FRAMEWORK_MODEL_IDS,
              )
              return (
                <fieldset key={id}>
                  {hasFullSupport && (
                    <FieldLabel>
                      {descriptor.capital}
                      <MultipleFrameworkDropdown
                        onFrameworkSelected={formSet.frameworks}
                        placeholder={`Select ${descriptor.lower}`}
                        complianceFrameworkId={id}
                        showCount={true}
                      />
                    </FieldLabel>
                  )}
                </fieldset>
              )
            })}
          </>
        )}
      </form>
    </ModalForm>
  )
}
