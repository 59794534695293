import React from 'react'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import isFunction from 'lodash/isFunction'
import { FlexRow } from '../Flex'
import {
  NextIcon,
  PrevNext,
  PlainButton,
  PrevNextContainer,
} from './prevNext.styles'
import { PrevNextProps, usePrevNext } from './usePrevNext'

export const PrevNextButtons = ({
  baseRoute,
  idArray,
  param,
  idxOverride,
  includeHash,
}: PrevNextProps): JSX.Element => {
  const { current, total, routeToNext, routeToPrev } = usePrevNext({
    baseRoute,
    idArray,
    param,
    idxOverride,
    includeHash,
  })

  return (
    <PrevNextContainer>
      <PrevNext>
        {current} of {total}
      </PrevNext>
      <FlexRow gap={32}>
        <PlainButton
          disabled={!isFunction(routeToPrev)}
          onClick={routeToPrev || (() => undefined)}
        >
          <NextIcon icon={faChevronLeft} disabled={!isFunction(routeToPrev)} />
        </PlainButton>
        <PlainButton
          disabled={!isFunction(routeToNext)}
          onClick={routeToNext || (() => undefined)}
        >
          <NextIcon icon={faChevronRight} disabled={!isFunction(routeToNext)} />
        </PlainButton>
      </FlexRow>
    </PrevNextContainer>
  )
}
