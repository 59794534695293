import { useCallback } from 'react'
import { NotificationPromiseClient } from '@trustero/trustero-api-web/lib/notification/notification_grpc_web_pb'
import {
  AddSubscriptionRequest,
  GetSubscriptionRequest,
  NOTIFICATION_TYPE,
  RemoveSubscriptionRequest,
  Subscription,
} from '@trustero/trustero-api-web/lib/notification/notification_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { useCurrentUserEmail } from 'src/context/authContext'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useGrpcRevalidateByMethod } from 'src/components/async/useGrpcMutate'
import log from 'loglevel'
import { showInfoToast } from 'src/Utils/helpers/toast'

export const useInvalidateSubscriptionCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await mutateFunc(
        NotificationPromiseClient.prototype.getSubscriptionBySubjectId,
      )
    } catch (err) {
      log.error('Error when invalidating notifications cache', err)
    }
  }, [mutateFunc])
}

export const useAddSubscription = (): (({
  subjectId,
  notificationType,
}: {
  subjectId: string
  notificationType?: NOTIFICATION_TYPE
}) => Promise<void>) => {
  const email = useCurrentUserEmail()
  const notificationClient = useAuthorizedGrpcClient(NotificationPromiseClient)
  const mutate = useInvalidateSubscriptionCache()

  return async ({ subjectId, notificationType = NOTIFICATION_TYPE.EMAIL }) => {
    try {
      const req = new AddSubscriptionRequest()
        .setUserEmail(email)
        .setSubjectId(subjectId)
        .setType(notificationType)
      await notificationClient.addSubscription(req)
      await mutate()
      showInfoToast('You are now watching this request.')
    } catch (err) {
      log.error(
        `Error when adding subscription with subjectId: ${subjectId}`,
        err,
      )
    }
  }
}

export const useRemoveSubscription = (): (({
  subscriptionId,
}: {
  subscriptionId: string
}) => Promise<void>) => {
  const notificationClient = useAuthorizedGrpcClient(NotificationPromiseClient)
  const mutate = useInvalidateSubscriptionCache()

  return async ({ subscriptionId }) => {
    try {
      const req = new RemoveSubscriptionRequest().setSubscriptionId(
        subscriptionId,
      )
      await notificationClient.removeSubscription(req)
      await mutate()
      showInfoToast('You are no longer watching this request.')
    } catch (err) {
      log.error(
        `Error when removing subscription with subscriptionId: ${subscriptionId}`,
        err,
      )
    }
  }
}

export const useGetSubscriptionBySubjectId = ({
  subjectId,
}: {
  subjectId: string
}): GrpcResponse<Subscription> => {
  const email = useCurrentUserEmail()
  const req = new GetSubscriptionRequest()
    .setSubjectId(subjectId)
    .setUserEmail(email)
  const { response } = useSwrImmutableGrpc(
    NotificationPromiseClient.prototype.getSubscriptionBySubjectId,
    req,
  )
  return NewGrpcResponse(response)
}
