import styled from 'styled-components/macro'
import { color, compose, padding } from 'styled-system'

export const NoResultsContainer = styled.div.attrs({
  p: 'xl',
  bg: 'bg.neutral',
})`
  ${compose(padding, color)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const LargeTitle = styled.h2`
  font-weight: normal;
  margin: 24px;
  margin-bottom: 48px;
`

export const SmallTitle = styled.p`
  font-weight: normal;
  font-size: 20px;
  margin: 16px;
  font-weight: 600;
`

export const Note = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`
