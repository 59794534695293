import React, { useContext } from 'react'
import { DropdownItemType } from 'src/components/Reusable/IndexPage/FilterBar/FilterDropdowns/FilterDropdown.constants'

type FileTypeFilterContextType = {
  filterValues: DropdownItemType[]
  setFilterValues: React.Dispatch<React.SetStateAction<DropdownItemType[]>>
}

export const FileTypeFilterContext =
  React.createContext<FileTypeFilterContextType>({
    filterValues: [],
    setFilterValues: () => [],
  })

export const useFileTypeFilterContext = (): FileTypeFilterContextType =>
  useContext(FileTypeFilterContext)

export const FileTypeFilterProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [filterValues, setFilterValues] = React.useState<DropdownItemType[]>([])

  return (
    <FileTypeFilterContext.Provider value={{ filterValues, setFilterValues }}>
      {children}
    </FileTypeFilterContext.Provider>
  )
}
