import React from 'react'
import {
  faSquareMinus,
  faSquarePlus,
} from '@fortawesome/free-regular-svg-icons'
import { StyledShowPageAccordionIcon } from './ShowPageAccordions.styles'

export const ShowPageAccordionIcon = ({
  isActive,
  isDisabled,
}: {
  isActive: boolean
  isDisabled?: boolean
}): JSX.Element => {
  return (
    <StyledShowPageAccordionIcon
      icon={isActive ? faSquareMinus : faSquarePlus}
      disabled={isDisabled}
    />
  )
}
