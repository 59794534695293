import {
  RISK_CATEGORY,
  RISK_MATH,
  RISK_STATUS,
  RISK_TREATMENT,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'

export type RiskValue =
  | string
  | RISK_STATUS
  | RISK_MATH
  | RISK_CATEGORY
  | RISK_TREATMENT

export enum RiskFields {
  id = 'id',
  accountId = 'accountId',
  name = 'name',
  description = 'description',
  departmentId = 'departmentId',
  customId = 'customId',
  status = 'status',
  ownerEmail = 'ownerEmail',
  predisposingCondition = 'preDisposingCondition',
  assetAtRisk = 'assetAtRisk',
  impactToCustomer = 'impactToCustomer',
  likelihood = 'likelihood',
  adverseImpact = 'adverseImpact',
  treatment = 'treatment',
  riskAfterTreatment = 'riskAfterTreatment',
  category = 'category',
  // Calculated Risk
  inherentRisk = 'inherentRisk',
}

export const RISK_HEADERS = [
  {
    name: 'Dept',
    isCentered: false,
  },
  {
    name: 'ID',
    isCentered: false,
  },
  {
    name: 'Threat', // This is the name property on a Risk
    isCentered: false,
  },
  {
    name: 'Controls',
    isCentered: true,
  },
  {
    name: 'Inherent Risk',
    isCentered: true,
  },
  {
    name: 'Residual Risk',
    isCentered: true,
  },
  {
    name: 'Status',
    isCentered: true,
  },
  {
    name: 'Assignee',
    isCentered: true,
  },
  {
    name: '', // Empty column for 3-dot menu
    isCentered: true,
  },
]

export const RISKS_STATUS_DROPDOWN = [
  RISK_STATUS.TODO,
  RISK_STATUS.IN_PROGRESS,
  RISK_STATUS.PENDING,
  RISK_STATUS.COMPLETED,
]

export enum RiskStatusLabels {
  TODO = 'To Do',
  IN_PROGRESS = 'In Progress',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
}

export const RISK_STATUS_LABEL: Record<RISK_STATUS, string> = {
  [RISK_STATUS.TODO]: RiskStatusLabels.TODO,
  [RISK_STATUS.IN_PROGRESS]: RiskStatusLabels.IN_PROGRESS,
  [RISK_STATUS.PENDING]: RiskStatusLabels.PENDING,
  [RISK_STATUS.COMPLETED]: RiskStatusLabels.COMPLETED,
}

export const RISK_LABEL_TO_STATUS: Record<string, RISK_STATUS> = {
  [RiskStatusLabels.TODO]: RISK_STATUS.TODO,
  [RiskStatusLabels.IN_PROGRESS]: RISK_STATUS.IN_PROGRESS,
  [RiskStatusLabels.PENDING]: RISK_STATUS.PENDING,
  [RiskStatusLabels.COMPLETED]: RISK_STATUS.COMPLETED,
}

export const RISK_STATUS_FILTER = [
  {
    value: RiskStatusLabels.TODO,
    label: RiskStatusLabels.TODO,
  },
  {
    value: RiskStatusLabels.IN_PROGRESS,
    label: RiskStatusLabels.IN_PROGRESS,
  },
  {
    value: RiskStatusLabels.PENDING,
    label: RiskStatusLabels.PENDING,
  },
  {
    value: RiskStatusLabels.COMPLETED,
    label: RiskStatusLabels.COMPLETED,
  },
]

export enum RiskMathLabels {
  NOT_SET = 'Not Set',
  VERY_LOW = 'Very Low',
  LOW = 'Low',
  MODERATE = 'Moderate ',
  HIGH = 'High',
  VERY_HIGH = 'Very High',
}

export const RISK_MATH_LABEL: Record<RISK_MATH, RiskMathLabels> = {
  [RISK_MATH.NOT_SET]: RiskMathLabels.NOT_SET,
  [RISK_MATH.VERY_LOW]: RiskMathLabels.VERY_LOW,
  [RISK_MATH.LOW]: RiskMathLabels.LOW,
  [RISK_MATH.MODERATE]: RiskMathLabels.MODERATE,
  [RISK_MATH.HIGH]: RiskMathLabels.HIGH,
  [RISK_MATH.VERY_HIGH]: RiskMathLabels.VERY_HIGH,
}

// RISK_MATH.NOT_SET will be the default value for all dropdowns but cannot be selected once unset
export const RISK_MATH_DROPDOWN = [
  {
    name: RiskMathLabels.VERY_LOW,
    value: RISK_MATH.VERY_LOW,
  },
  {
    name: RiskMathLabels.LOW,
    value: RISK_MATH.LOW,
  },
  {
    name: RiskMathLabels.MODERATE,
    value: RISK_MATH.MODERATE,
  },
  {
    name: RiskMathLabels.HIGH,
    value: RISK_MATH.HIGH,
  },
  {
    name: RiskMathLabels.VERY_HIGH,
    value: RISK_MATH.VERY_HIGH,
  },
]

/**
 * This is the hard-coded math calculations for our Impact and Likelihood risk matrix
 *
 * The first level is the impact which then gives the lookup for the likelihood
 */
export const RISK_MATH_MAP: Record<RISK_MATH, Record<RISK_MATH, RISK_MATH>> = {
  [RISK_MATH.NOT_SET]: {
    [RISK_MATH.NOT_SET]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_LOW]: RISK_MATH.NOT_SET,
    [RISK_MATH.LOW]: RISK_MATH.NOT_SET,
    [RISK_MATH.MODERATE]: RISK_MATH.NOT_SET,
    [RISK_MATH.HIGH]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_HIGH]: RISK_MATH.NOT_SET,
  },
  // Impact Level
  [RISK_MATH.VERY_LOW]: {
    // Likelihood Level
    [RISK_MATH.NOT_SET]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_LOW]: RISK_MATH.VERY_LOW,
    [RISK_MATH.LOW]: RISK_MATH.VERY_LOW,
    [RISK_MATH.MODERATE]: RISK_MATH.VERY_LOW,
    [RISK_MATH.HIGH]: RISK_MATH.VERY_LOW,
    [RISK_MATH.VERY_HIGH]: RISK_MATH.VERY_LOW,
  },
  [RISK_MATH.LOW]: {
    [RISK_MATH.NOT_SET]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_LOW]: RISK_MATH.VERY_LOW,
    [RISK_MATH.LOW]: RISK_MATH.LOW,
    [RISK_MATH.MODERATE]: RISK_MATH.LOW,
    [RISK_MATH.HIGH]: RISK_MATH.LOW,
    [RISK_MATH.VERY_HIGH]: RISK_MATH.LOW,
  },
  [RISK_MATH.MODERATE]: {
    [RISK_MATH.NOT_SET]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_LOW]: RISK_MATH.VERY_LOW,
    [RISK_MATH.LOW]: RISK_MATH.LOW,
    [RISK_MATH.MODERATE]: RISK_MATH.MODERATE,
    [RISK_MATH.HIGH]: RISK_MATH.MODERATE,
    [RISK_MATH.VERY_HIGH]: RISK_MATH.MODERATE,
  },
  [RISK_MATH.HIGH]: {
    [RISK_MATH.NOT_SET]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_LOW]: RISK_MATH.LOW,
    [RISK_MATH.LOW]: RISK_MATH.LOW,
    [RISK_MATH.MODERATE]: RISK_MATH.MODERATE,
    [RISK_MATH.HIGH]: RISK_MATH.HIGH,
    [RISK_MATH.VERY_HIGH]: RISK_MATH.HIGH,
  },
  [RISK_MATH.VERY_HIGH]: {
    [RISK_MATH.NOT_SET]: RISK_MATH.NOT_SET,
    [RISK_MATH.VERY_LOW]: RISK_MATH.LOW,
    [RISK_MATH.LOW]: RISK_MATH.MODERATE,
    [RISK_MATH.MODERATE]: RISK_MATH.HIGH,
    [RISK_MATH.HIGH]: RISK_MATH.VERY_HIGH,
    [RISK_MATH.VERY_HIGH]: RISK_MATH.VERY_HIGH,
  },
}

export enum RiskCategoryLabels {
  NO_CATEGORY = 'No Category',
  CUSTOMER = 'Customer Commitments',
  VENDOR = 'Vendor Management',
  LEADERSHIP = 'Leadership Commitment',
  SYSTEMS = 'Systems and Technology Changes',
  LAWS = 'Laws, Regulations, and Standards',
  IP = 'Intellectual Property',
}

export const RISK_CATEGORY_LABEL: Record<RISK_CATEGORY, RiskCategoryLabels> = {
  [RISK_CATEGORY.NO_CATEGORY]: RiskCategoryLabels.NO_CATEGORY,
  [RISK_CATEGORY.CUSTOMER]: RiskCategoryLabels.CUSTOMER,
  [RISK_CATEGORY.VENDOR]: RiskCategoryLabels.VENDOR,
  [RISK_CATEGORY.LEADERSHIP]: RiskCategoryLabels.LEADERSHIP,
  [RISK_CATEGORY.SYSTEMS]: RiskCategoryLabels.SYSTEMS,
  [RISK_CATEGORY.LAWS]: RiskCategoryLabels.LAWS,
  [RISK_CATEGORY.IP]: RiskCategoryLabels.IP,
}

// RISK_CATEGORY.NO_CATEGORY will be set by default for all dropdowns but cannot be selected once unset
export const RISK_CATEGORY_DROPDOWN = [
  {
    name: RiskCategoryLabels.CUSTOMER,
    value: RISK_CATEGORY.CUSTOMER,
  },
  {
    name: RiskCategoryLabels.VENDOR,
    value: RISK_CATEGORY.VENDOR,
  },
  {
    name: RiskCategoryLabels.LEADERSHIP,
    value: RISK_CATEGORY.LEADERSHIP,
  },
  {
    name: RiskCategoryLabels.SYSTEMS,
    value: RISK_CATEGORY.SYSTEMS,
  },
  {
    name: RiskCategoryLabels.LAWS,
    value: RISK_CATEGORY.LAWS,
  },
  {
    name: RiskCategoryLabels.IP,
    value: RISK_CATEGORY.IP,
  },
]

export enum RiskTreatmentLabels {
  ACCEPT = 'Accept',
  AVOID = 'Avoid',
  MITIGATE = 'Mitigate',
  SHARE = 'Share',
  TRANSFER = 'Transfer',
}

export const RISK_TREATMENT_LABEL: Record<RISK_TREATMENT, string> = {
  [RISK_TREATMENT.ACCEPT]: RiskTreatmentLabels.ACCEPT,
  [RISK_TREATMENT.AVOID]: RiskTreatmentLabels.AVOID,
  [RISK_TREATMENT.MITIGATE]: RiskTreatmentLabels.MITIGATE,
  [RISK_TREATMENT.SHARE]: RiskTreatmentLabels.SHARE,
  [RISK_TREATMENT.TRANSFER]: RiskTreatmentLabels.TRANSFER,
}

export const RISK_TREATMENT_DROPDOWN = [
  {
    name: RiskTreatmentLabels.ACCEPT,
    value: RISK_TREATMENT.ACCEPT,
  },
  {
    name: RiskTreatmentLabels.AVOID,
    value: RISK_TREATMENT.AVOID,
  },
  {
    name: RiskTreatmentLabels.MITIGATE,
    value: RISK_TREATMENT.MITIGATE,
  },
  {
    name: RiskTreatmentLabels.SHARE,
    value: RISK_TREATMENT.SHARE,
  },
  {
    name: RiskTreatmentLabels.TRANSFER,
    value: RISK_TREATMENT.TRANSFER,
  },
]

export const RISK_VALIDATORS = {
  ID: 10,
  NAME: 255,
}

export interface AddControlsToRiskProps {
  existingControlIds: string[]
  riskId: string
}

export interface AddRisksToControlProps {
  existingRiskIds: string[]
  controlId: string
}
