import { useMemo } from 'react'
import queryString from 'query-string'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { FilterParam } from '../../components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  GridColumnSortControls,
  GridColumnSortEvidence,
  GridColumnSortParams,
  GridColumnSortPolicies,
  GridColumnSortRequests,
  GridColumnSortType,
  GridColumnSortVendors,
} from '../../components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'

type FilterUrlSearchParams = {
  [key in FilterParam | GridColumnSortParams]?:
    | string
    | GridColumnSortControls[]
    | GridColumnSortPolicies[]
    | GridColumnSortRequests[]
    | GridColumnSortVendors[]
    | GridColumnSortEvidence[]
    | GridColumnSortType[]
}

interface UrlSearchParamsHook {
  searchParams: queryString.ParsedQuery
  updateUrlParams: (params: FilterUrlSearchParams) => void
  location: Location
  navigate: NavigateFunction
}

export const useUrlSearchParams = (): UrlSearchParamsHook => {
  const navigate = useNavigate()
  const location = useLocation() as unknown as Location
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, { arrayFormat: 'bracket' })
  }, [location.search])

  const updateUrlParams = (newParams: FilterUrlSearchParams) => {
    const path = queryString.stringifyUrl(
      {
        url: location.pathname,
        query: {
          ...searchParams,
          ...newParams,
        },
      },
      { arrayFormat: 'bracket' },
    )
    navigate(path, { replace: true })
  }

  return {
    searchParams,
    updateUrlParams,
    location,
    navigate,
  }
}
