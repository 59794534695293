import { Location, NavigateFunction } from 'react-router-dom'
import queryString from 'query-string'
import { RteModalQueryParams } from 'src/components/RichTextEditor'

export const editDocumentMarkdown = (
  documentId: string,
  material: string,
  setMarkdownBody: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
  location: Location,
): void => {
  setMarkdownBody(material)
  navigate(
    queryString.stringifyUrl(
      {
        url: location.pathname,
        query: {
          ...queryString.parse(location.search, {
            arrayFormat: 'bracket',
          }),
          [RteModalQueryParams.SHOW_RTE_MODAL]: true,
          [RteModalQueryParams.UNIQUE_ID]: documentId,
          [RteModalQueryParams.SHOW_WIZARD]: false,
        },
      },
      {
        arrayFormat: 'bracket',
      },
    ),
  )
}
