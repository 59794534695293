import React, { useContext, useMemo, useState } from 'react'
import { Framework } from '@trustero/trustero-api-web/lib/model/framework_pb'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'

type CurrentFrameworkObjective = {
  objectives: Framework[]
  setObjectives: React.Dispatch<React.SetStateAction<Framework[]>>
  complianceFramework: ComplianceFramework
  setComplianceFramework: React.Dispatch<
    React.SetStateAction<ComplianceFramework>
  >
  clear: () => void
  setFrameworkObjectives: ({
    objectives,
    complianceFramework,
  }: {
    objectives: Framework[]
    complianceFramework: ComplianceFramework
  }) => void
}

const CurrentComplianceFrameworkObjectivesContext =
  React.createContext<CurrentFrameworkObjective>({
    objectives: [],
    setObjectives: () => [],
    complianceFramework: new ComplianceFramework(),
    setComplianceFramework: () => new ComplianceFramework(),
    clear: () => {
      return
    },
    setFrameworkObjectives: () => {
      return
    },
  })

export const useCurrentComplianceFrameworkObjectives =
  (): CurrentFrameworkObjective =>
    useContext(CurrentComplianceFrameworkObjectivesContext)

export const CurrentComplianceFrameworkObjectivesContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [objectives, setObjectives] = useState<Framework[]>([])
  const [complianceFramework, setComplianceFramework] =
    useState<ComplianceFramework>(new ComplianceFramework())

  const initState: CurrentFrameworkObjective = useMemo(
    () => ({
      objectives,
      setObjectives,
      complianceFramework,
      setComplianceFramework,
      clear: () => {
        setObjectives([])
        setComplianceFramework(new ComplianceFramework())
      },
      setFrameworkObjectives: ({
        objectives,
        complianceFramework,
      }: {
        objectives: Framework[]
        complianceFramework: ComplianceFramework
      }) => {
        setObjectives(objectives)
        setComplianceFramework(complianceFramework)
      },
    }),
    [objectives, setObjectives, complianceFramework, setComplianceFramework],
  )
  return (
    <CurrentComplianceFrameworkObjectivesContext.Provider value={initState}>
      {children}
    </CurrentComplianceFrameworkObjectivesContext.Provider>
  )
}
