import { getReceptorTemplate, Receptor } from '../../../../xgenerated'
import { useAccountReceptors } from './useReceptors'

type Props = {
  receptorModelId: string
}

export function useReceptorTemplate(props: Props): Receptor | undefined {
  const response = useAccountReceptors()
  const record = response.data
    ?.getReceptorsList()
    .find((p) => p.getModelid() === props.receptorModelId)

  if (!record) {
    return undefined
  }

  return getReceptorTemplate(record.getModelid())
}
