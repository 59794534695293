import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { LineContent } from '../Login/LoginPage.styles'

export const StandaloneScannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  margin: 0 25%;
  background-color: ${palette.neutral.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

export const WebsiteLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
`

export const StyledHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  word-wrap: normal;
  word-break: normal;
  text-align: center;
`

export const StyledLineContent = styled(LineContent)`
  margin: 0;
  width: 100%;
  ::before {
    margin-left: 0;
  }
  ::after {
    margin-right: 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.71rem;
  width: 100%;
`

export const StyledText = styled.p`
  color: ${palette.neutral.white};
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
`

export const StyledSubHeader = styled.h2`
  color: ${palette.neutral.white};
  text-align: center;
  font-size: 2.85rem;
  font-weight: 400;
  font-size: 2em;
`

export const SignupFormContainer = styled.div<{ $isLargeContainer?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${palette.neutral.white};
  gap: 2em;
  max-width: 550px;
  height: 100vh;
  margin-top: 70px;
  // 845px for the /scan_report page and 960px for the /answer_questionnaire page
  min-height: ${({ $isLargeContainer }) =>
    $isLargeContainer ? '960px' : '845px'};
`

export const StyledLink = styled.a`
  align-self: flex-start;
  color: ${palette.neutral.white};
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  :hover {
    text-decoration: underline;
  }
  &:visited {
    color: ${palette.neutral.white};
  }
`

export const StyledNonLink = styled.div`
  align-self: flex-start;
  color: ${palette.neutral.white};
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  :hover {
    text-decoration: underline;
  }
  &:visited {
    color: ${palette.neutral.white};
  }
`

export const SignupInputContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  background-color: ${palette.neutral.white};
  border: 1px solid ${palette.neutral.black};
  padding: 10px;
  padding-left: 20px;
  padding-right: 0px;
  border-radius: 6px;
  top: 0;
`

export const SignupInput = styled.input`
  outline: none;
  background: none;
  border: none;
  width: 100%;
`

export const SignupButton = styled.button`
  all: unset;
  border-left: 1px solid ${palette.neutral['900']};
  background-color: none;
  padding: 0px 24px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  margin: auto;
`
