import React from 'react'
import {
  TestResult,
  TestStatus,
  TestType,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import {
  RowItemElement,
  RowItemProp,
} from '../../../../components/Reusable/Grid/Accordion/RowItemProp'
import { GridColumn } from '../../../../components/Reusable/Grid'
import { TestResultRowContent } from './TestResultRowContent'
import { DeleteButton } from './DeleteButton'

type TestResultAsRowItemProps = {
  hideDidPass?: boolean
  leftMargin?: string
  isDeleteDisabled?: boolean
  timeRange?: TimeRange
  getName?: (p: TestResult) => string | JSX.Element
  getPermalink?: (p: TestResult) => string
}

export const testResultAsRowItem = (
  testResult: TestResult,
  props: TestResultAsRowItemProps,
): RowItemElement => {
  return {
    RowItem: (p: RowItemProp) => (
      <TestResultRowContent
        {...p}
        leftMargin={props.leftMargin}
        id={testResult.getId()}
        name={props?.getName ? props.getName(testResult) : testResult.getName()}
        permalink={
          props.getPermalink ? props.getPermalink(testResult) : undefined
        }
        actorName={testResult.getActor()}
        actorEmail={testResult.getActor()}
        didPass={testResult.getStatus() === TestStatus.PASS}
        hideDidPass={props.hideDidPass}
        isAutomated={testResult.getType() === TestType.AUTOMATED}
        evidenceTestResultCount={testResult.getEvidencetestresultsList().length}
        createdAt={testResult.getCreatedat()}
      >
        {props?.isDeleteDisabled && (
          <GridColumn gridRow={p.gridRow} px="0" pr="s">
            <DeleteButton
              isDeleteDisabled={props.isDeleteDisabled}
              modelId={testResult.getModelid()}
              testId={testResult.getId()}
              timeRange={props.timeRange}
            />
          </GridColumn>
        )}
      </TestResultRowContent>
    ),
    key: testResult.getId(),
  }
}
