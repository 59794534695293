import React, { useEffect, useRef, useState } from 'react'
import { LogEntries } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../../components/Reusable/Buttons'
import { useChangeLogEntries } from './useChangeLogEntries'
import { EntriesContainer } from './Changelog.styles'
import { ChangeLogEntries } from './ChangeLogEntries'
import { CHANGELOG_PAGE_SIZE, EmptyLog } from './ChangelogPage.helpers'

export const ChangelogPage = ({
  itemsPerPage = CHANGELOG_PAGE_SIZE,
  subjectModelIds,
  subjectIds,
  isWide,
}: {
  itemsPerPage: number
  subjectModelIds?: string[]
  subjectIds?: string[]
  isWide?: boolean
}): JSX.Element => {
  const currentPage = useRef<number>(0)
  const hasLoadedMore = useRef<boolean>(false)
  const [entryPages, setEntryPages] = useState<LogEntries[]>([])
  const [isLastPage, setIsLastPage] = useState<boolean>(false)
  // Generate log entries for the current page

  const { isLoading, data, size, setSize } = useChangeLogEntries(
    itemsPerPage,
    currentPage.current,
    subjectModelIds,
    subjectIds,
  )

  useEffect(() => {
    if (!data || isLoading) {
      return
    } else if (!hasLoadedMore.current) {
      setEntryPages([...data])
    } else {
      // Ensure all new pages get added to the end of the list
      setEntryPages((prev) => [...prev, ...data])
    }
  }, [data, isLoading])

  const page = data ? [...data] : []
  const pageLogEntries = page.length ? page[0].getLogentriesList() : []
  const noneToShow = page.length === 1 && pageLogEntries.length === 0
  const hasEntries = entryPages.length

  if (isLoading && !hasEntries) {
    return <TableListLoader rows={3} />
  } else if (noneToShow || (!data && !hasEntries)) {
    return <EmptyLog content="No activities to show." />
  }

  const loadMore = () => {
    if (!hasLoadedMore.current) {
      hasLoadedMore.current = true
    }
    currentPage.current += 1
    setSize(size + 1)
  }

  const buttonVariant = isWide
    ? StandardButtonVariant.SECONDARY
    : StandardButtonVariant.PRIMARY

  return (
    <EntriesContainer>
      {entryPages.map((entries: LogEntries, idx: number) => (
        <ChangeLogEntries
          key={`${idx}`}
          entries={entries}
          itemsPerPage={itemsPerPage}
          setLastPage={setIsLastPage}
        />
      ))}
      {!isLastPage && (
        <StandardButton
          buttonSize={StandardButtonSize.MEDIUM}
          variant={buttonVariant}
          onClick={loadMore}
        >
          Load More
        </StandardButton>
      )}
    </EntriesContainer>
  )
}
