import { useCallback } from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  ListControlsRequest,
  Controls,
  CreateUpdateControlRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import log from 'loglevel'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { GetHasNoControlsResponse } from '@trustero/trustero-api-web/lib/model/control_pb'
import { ControlServicePromiseClient } from '@trustero/trustero-api-web/lib/model/control_grpc_web_pb'
import { BoolValue, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  ControlStats,
  GetControlStatsRequest,
} from '@trustero/trustero-api-web/lib/model/model_pb'
import { useInAudit } from 'src/context/AuditContext'
import { useGrpcRevalidateByMethod } from '../../../../components/async/useGrpcMutate'
import { useSwrImmutableGrpc } from '../../../../components/async/useSwrImmutableGrpc'
import { SwrGrpcResponse } from '../../../../components/async/useSwrGrpc'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useInvalidateDocumentRequestsCache } from '../../DocumentRequest/useDocumentRequests'

const mutators: Set<() => void> = new Set()

export const useControlsWithAuditId = (auditId?: string): GrpcResponse<Controls> => useControls(undefined, undefined, undefined, auditId)

export const useControls = (
  request?: ListControlsRequest,
  shouldFetch = true,
  config?: SwrGrpcResponse,
  auditId?: string,
  includeDismissed = false,
  skipAuditCheck = false,
): GrpcResponse<Controls> => {
  const { auditId: currentAuditId } = useInAudit()
  const listControlsRequest = request ?? new ListControlsRequest()
    .setIsDismissed(new BoolValue().setValue(includeDismissed))

  if (!skipAuditCheck && (auditId || currentAuditId)) {
    const requestAuditId = auditId || currentAuditId
    if (requestAuditId) {
      listControlsRequest.setAuditId(new StringValue().setValue(requestAuditId))
    }
  }

  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listControls,
    listControlsRequest,
    shouldFetch,
    config,
  )
  mutators.add(response.mutate)
  return NewGrpcResponse(response)
}

// Returns a boolean indicating whether or not the account has no controls
export const useHasNoControls = (): GrpcResponse<GetHasNoControlsResponse> => {
  const { response } = useSwrImmutableGrpc(
    ControlServicePromiseClient.prototype.getHasNoControls,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

export const useInvalidateControlsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await Promise.all([
        mutateFunc(ModelPromiseClient.prototype.listControls),
        mutateFunc(ModelPromiseClient.prototype.listControlIds),
        mutateFunc(ControlServicePromiseClient.prototype.getHasNoControls),
      ])
    } catch (error) {
      log.error('Error when invalidating controls cache', error)
    }
  }, [mutateFunc])
}

export const useAddOrUpdateControl = (): ((request: CreateUpdateControlRequest) => Promise<void>) => {
  const mutator = useInvalidateControlsCache()
  const requestMutator = useInvalidateDocumentRequestsCache()
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)

  return async (request: CreateUpdateControlRequest): Promise<void> => {
    await modelClient.createOrUpdateControl(request)
    await mutator()
    await requestMutator()
  }
}

export const useControlStats = (): GrpcResponse<ControlStats> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getControlStats,
    new GetControlStatsRequest(),
  )
  return NewGrpcResponse(response)
}
