import { Frameworks } from '@trustero/trustero-api-web/lib/model/framework_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { GetComplianceFrameworkObjectivesRequest } from '@trustero/trustero-api-web/lib/model/model_pb'

import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useComplianceFrameworkObjectives = (
  complianceFrameworkIds: string[],
): GrpcResponse<Frameworks> => {
  const call = ModelPromiseClient.prototype.getComplianceFrameworkObjectives
  const request =
    new GetComplianceFrameworkObjectivesRequest().setComplianceFrameworkIdsList(
      complianceFrameworkIds,
    )
  const { response } = useSwrImmutableGrpc(call, request)
  return NewGrpcResponse(response)
}
