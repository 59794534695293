import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { ViewEvidenceQueryParams } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { ParsedQuery, parse, stringifyUrl } from 'query-string'
import { ModalFormId } from 'src/components/ModalForms/ModalForm'
import log from 'loglevel'
import { modelIdToType } from '../../../adapter'
import {
  AuditBotAbsoluteRoutes,
  ControlsAbsoluteRoutes,
  PoliciesAbsoluteRoutes,
  ReceptorsAbsoluteRoutes,
  RisksAbsoluteRoutes,
  SecurityQuestionnaireAbsoluteRoutes,
  ServicesAbsoluteRoutes,
  VendorsAbsoluteRoutes,
} from '../../Reusable/RootPage/RootPage.constants'

interface GeneratePermalinkArgs {
  pageContext: string
  modelType?: MODEL_TYPE
  modelId: string
  isInternalLink: boolean
  includeSearchParams?: boolean
  search?: ParsedQuery<string>
}

export const linkWithSearch = (
  link: string,
  search?: ParsedQuery<string>,
  includeHash?: boolean,
): string =>
  `${stringifyUrl(
    {
      url: link,
      query: search
        ? search
        : location.search.toString()
        ? parse(location.search, {
            arrayFormat: 'bracket',
          })
        : undefined,
    },
    { arrayFormat: 'bracket' },
  )}${includeHash ? location.hash : ''}`

export const useNavigateWithSearch = (): ((link: string) => void) => {
  const navigate = useNavigate()

  return useCallback(
    (link: string) => navigate(linkWithSearch(link)),
    [navigate],
  )
}

export const generatePermalink = ({
  pageContext,
  modelType,
  modelId,
  isInternalLink,
  includeSearchParams,
  search,
}: GeneratePermalinkArgs): string => {
  const origin = isInternalLink
    ? `/${pageContext}`
    : `${window.location.origin}/${pageContext}`

  let baseLink: string

  switch (modelType) {
    case MODEL_TYPE.POLICY:
      baseLink = `${origin}/${PoliciesAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.CONTROL:
      baseLink = `${origin}/${ControlsAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.OVERVIEW:
      baseLink = `${origin}/company_info`
      break
    case MODEL_TYPE.RISK:
      baseLink = `${origin}/${RisksAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.AUDITBOT_RUN:
      baseLink = `${origin}/${AuditBotAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.SERVICE:
      baseLink = `${origin}/${ServicesAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.RECEPTOROBJ:
      baseLink = `${origin}/${ReceptorsAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.QUESTIONNAIRE:
      baseLink = `${origin}/${SecurityQuestionnaireAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.VENDOR:
      baseLink = `${origin}/${VendorsAbsoluteRoutes.SHOW}/${modelId}`
      break
    case MODEL_TYPE.EVIDENCE:
      baseLink = `${origin}/evidence/show/${modelId}`
      break
    case MODEL_TYPE.REQUEST:
      baseLink = `${origin}/requests/show/${modelId}`
      break
    default:
      baseLink = origin
  }

  return includeSearchParams ? linkWithSearch(baseLink, search) : baseLink
}

// if auditId is not passed in, this will generate permalink for continuous
// compliance view
export const generateAuditPermalink = ({
  pageContext,
  overrideAuditId,
  isInternalLink,
  destination,
}: {
  pageContext?: string
  overrideAuditId?: string
  isInternalLink: boolean
  destination: string
}): string => {
  const environment = (pageContext ?? '').split(':')[0]

  let origin = isInternalLink ? '/' : `${window.location.origin}`

  origin += environment
  if (overrideAuditId) {
    origin += `:${overrideAuditId}`
  }
  origin += `/${destination}`

  return origin
}

/**
 * Generates a permalink from a ID.
 * @param {string} pageContext - The page context.
 * @param {string} modelId - This can be the modelID or any ID.
 * @param {boolean} isInternalLink - Whether the link is internal or not.
 * @returns {string} The generated permalink.
 */
export const generatePermalinkFromId = ({
  pageContext,
  modelId,
  isInternalLink,
  includeSearchParams,
  search,
}: GeneratePermalinkArgs): string => {
  const modelType = modelIdToType(modelId)
  return generatePermalink({
    pageContext,
    modelType,
    modelId,
    isInternalLink,
    includeSearchParams,
    search,
  })
}

export const generateEvidencePermalink = ({
  controlId,
  evidenceId,
  pageContext,
}: {
  evidenceId: string
  pageContext: string
  controlId?: string
}): string =>
  stringifyUrl({
    url: controlId
      ? generatePermalinkFromId({
          pageContext,
          modelId: controlId,
          isInternalLink: false,
        })
      : location.href,
    query: {
      ...parse(location.search, {
        arrayFormat: 'bracket',
      }),
      [ViewEvidenceQueryParams.MODAL_FORM_ID]: ModalFormId.VIEW_EVIDENCE,
      [ViewEvidenceQueryParams.EVIDENCE_ID]: evidenceId,
    },
  })

export const generateTestPermalinkFromId = ({
  pageContext,
  controlId,
  testId,
  isInternalLink,
}: {
  pageContext: string
  controlId: string
  testId: string
  isInternalLink: boolean
}): string => {
  const origin = isInternalLink
    ? `/${pageContext}`
    : `${window.location.origin}/${pageContext}`

  const url = `/${ControlsAbsoluteRoutes.SHOW}/${controlId}/test/${testId}`

  return origin + url
}

export const generatePermalinkFromCurrentLocation = (): string => {
  return window.location.toString()
}

export const openLinkInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank')
  if (newWindow) {
    newWindow.focus()
  } else {
    log.error('Failed to open new window')
  }
}
