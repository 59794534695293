import React from 'react'
import { GridRow } from 'src/components/Reusable/Grid'
import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { StyledRisksGridHeader } from '../risks.styles'
import { RISK_HEADERS } from '../risks.constants'

export const RisksGridHeader = ({
  hasSorting,
}: {
  hasSorting?: boolean
}): JSX.Element =>
  // TODO: Add sorting behavior here
  hasSorting ? (
    <></>
  ) : (
    <GridRow>
      {RISK_HEADERS.map((header: { name: string; isCentered: boolean }) => (
        <StyledRisksGridHeader
          key={header.name}
          top={headerHeight + filterBarHeight}
          justifyContent={header.isCentered ? 'center' : 'flex-start'}
        >
          {header.name}
        </StyledRisksGridHeader>
      ))}
    </GridRow>
  )
