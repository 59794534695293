import React, { Dispatch, Fragment, SetStateAction, useState } from 'react'
import { Calendar, DateRange, Range, RangeKeyDict } from 'react-date-range'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'
import { padding } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../Buttons'
import { TestIds } from '../../../Utils/testIds'

// Workaround for React 18 compatibility issues https://github.com/styled-components/styled-components/issues/3738
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CalendarProxy: any = Calendar
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateRangeProxy: any = DateRange
export const DateRangeModal = ({
  single,
  range,
  rangeSet,
  editingSet,
  dateDisplayFormat,
}: {
  single?: boolean
  range: Range
  rangeSet: Dispatch<SetStateAction<Range>>
  editingSet: Dispatch<SetStateAction<boolean>>
  dateDisplayFormat?: string
}): JSX.Element => {
  const [tempRange, tempRangeSet] = useState(range)

  return (
    <>
      {createPortal(
        <BlurBackground />,
        document.getElementsByTagName('body')[0],
      )}
      {createPortal(
        <DateRangeModalContainer>
          <DateModalBody>
            {single ? (
              <CalendarProxy
                editableDateInputs
                ranges={[tempRange]}
                date={tempRange.endDate as Date}
                minDate={new Date(2020, 1, 1, 23, 59, 59, 999)}
                startDatePlaceholder="Select Date"
                endDatePlaceholder="Select Date"
                onChange={(newRange: Date) => {
                  tempRangeSet({
                    // When type 1 audit, will default to 90 day windows ending
                    // with selected date
                    startDate: new Date(
                      newRange.getFullYear(),
                      newRange.getMonth(),
                      newRange.getDate() - 90,
                    ),
                    endDate: new Date(newRange),
                  })
                }}
                dateDisplayFormat={dateDisplayFormat}
              />
            ) : (
              <DateRangeProxy
                editableDateInputs
                ranges={[tempRange]}
                minDate={new Date(2020, 1, 1, 23, 59, 59, 999)}
                months={2}
                direction="horizontal"
                startDatePlaceholder="Select Start Date"
                endDatePlaceholder="Select End Date"
                onChange={({ range1 }: RangeKeyDict) => {
                  // Set the end date to the very end of the selected day
                  range1.endDate?.setHours(23, 59, 59, 999)
                  tempRangeSet(range1)
                }}
                dateDisplayFormat={dateDisplayFormat}
              />
            )}
            <DateModalFooter>
              <StandardButton
                variant={StandardButtonVariant.SECONDARY}
                buttonSize={StandardButtonSize.MEDIUM}
                mr="m"
                onClick={() => editingSet(false)}
              >
                Cancel
              </StandardButton>
              <StandardButton
                variant={StandardButtonVariant.PRIMARY}
                buttonSize={StandardButtonSize.MEDIUM}
                onClick={() => {
                  rangeSet(tempRange)
                  editingSet(false)
                }}
              >
                Save
              </StandardButton>
            </DateModalFooter>
          </DateModalBody>
        </DateRangeModalContainer>,
        document.getElementsByTagName('body')[0],
      )}
    </>
  )
}

export const DateRangeWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 450px;

  .rdrDateDisplayWrapper {
    height: 33px;
    border: 1px solid ${themeGet('colors.border.neutral.light')};
    border-radius: 4px;
    background-color: white;
    opacity: ${(props) => (props.disabled ? '0.5' : '')};

    .rdrDateDisplay {
      height: 100%;
      margin: 0;
      padding: 4px 8px;

      .rdrDateInput {
        height: 100%;
        box-shadow: none;

        :nth-child(2) {
          position: relative;
          margin-left: 17px;

          ::before {
            content: '';
            position: absolute;
            /*
              Place in between the two .rdrDateInput elements
              Shift to the left 1px to account for parent's border
              Shift to the left 9px to reach middle of margin
            */
            left: -10px;
            height: 100%;
            border-left: 1px solid ${themeGet('colors.border.neutral.light')};
          }
        }

        input {
          height: 100%;
          color: ${themeGet('colors.text.default')};
          font-weight: ${themeGet('fontWeights.medium')};
          ::placeholder {
            color: ${themeGet('colors.text.default')};
            font-weight: ${themeGet('fontWeights.medium')};
          }
        }
      }
    }
  }

  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDateDisplayWrapper {
    .rdrDateDisplay .rdrDateInput {
      :first-child {
        display: none;
      }
      :nth-child(2) {
        margin: 0;
        ::before {
          display: none;
        }
      }
    }
  }
`

export const DateRangeEditButton = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
`

const BlurBackground = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
`

const DateRangeModalContainer = styled.section`
  position: fixed;
  z-index: 10000;
  top: 0;
  width: 100vw;
  height: 100vh;
`

const DateModalBody = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 25vh auto;
  background-color: white;
  border-radius: 4px;
  overflow: clip;
  .rdrCalendarWrapper {
    border-radius: 4px;
    overflow: clip;

    .rdrMonth .rdrDays .rdrDay {
      .rdrInRange,
      .rdrSelected,
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        color: ${themeGet('colors.fill.primary.extraDark')}!important;
      }

      &.rdrDayHovered,
      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        color: ${themeGet('colors.fill.primary.light')}!important;
      }

      &.rdrDayToday .rdrDayNumber span:after {
        background: ${themeGet('colors.fill.primary.extraDark')}!important;
      }
    }

    &.rdrDateRangeWrapper {
      .rdrDateDisplayWrapper
        .rdrDateDisplay
        .rdrDateInput.rdrDateDisplayItemActive {
        border-color: ${themeGet('colors.border.primary.base')};
      }
    }

    &:not(.rdrDateRangeWrapper) .rdrDateDisplayWrapper {
      .rdrDateDisplay .rdrDateInput {
        :first-child {
          display: none;
        }
        :nth-child(2) {
          margin: 0;
          ::before {
            display: none;
          }
        }
      }
    }
  }
`

const DateModalFooter = styled.footer.attrs({
  p: 'm',
})`
  ${padding}
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
`

export const TrusteroDateRange = ({
  range,
  rangeSet,
  disabled,
  single,
  dateDisplayFormat,
}: {
  range: Range
  rangeSet: Dispatch<SetStateAction<Range>>
  disabled?: boolean
  single?: boolean
  dateDisplayFormat?: string
}): JSX.Element => {
  const [editing, editingSet] = useState(false)

  return (
    <DateRangeWrapper disabled={disabled}>
      <>
        {!disabled && (
          <DateRangeEditButton
            data-testid={TestIds.DATE_RANGE_EDIT_BUTTON}
            onClick={(e) => {
              e.preventDefault()
              editingSet(true)
            }}
          />
        )}
        {single ? (
          <CalendarProxy
            ranges={[
              {
                startDate: range.startDate,
                endDate: range.endDate,
                disabled: true,
                showDateDisplay: true,
              },
            ]}
            months={0}
            startDatePlaceholder="Select Date"
            endDatePlaceholder="Select Date"
            navigatorRenderer={() => <Fragment />}
            dateDisplayFormat={dateDisplayFormat}
          />
        ) : (
          <DateRangeProxy
            ranges={[{ ...range, disabled: true, showDateDisplay: true }]}
            months={0}
            startDatePlaceholder="Select Start Date"
            endDatePlaceholder="Select End Date"
            navigatorRenderer={() => <Fragment />}
            dateDisplayFormat={dateDisplayFormat}
          />
        )}
        {editing && (
          <DateRangeModal
            single={single}
            range={range}
            rangeSet={rangeSet}
            editingSet={editingSet}
            dateDisplayFormat={dateDisplayFormat}
          />
        )}
      </>
    </DateRangeWrapper>
  )
}
