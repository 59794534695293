import React, { useMemo } from 'react'
import { ModalFormId } from 'src/components/ModalForms'
import { UploadCSVModal } from 'src/components/ModalForms/Reusable/UploadCSVModal'
import { useUsers } from 'src/components/async/Users/useUsers'
import {
  Control,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  useControls,
  useListControlIds,
} from 'src/components/async/model/control'
import { UserRecord } from '@trustero/trustero-api-web/lib/account/account_pb'
import { CSV_UPLOAD_TYPES } from 'src/components/ModalForms/Reusable/UploadCSVModal.constants'
import { useCreateOrUpdateRisks } from '../risks.hooks'
import {
  ImportRiskCSVRow,
  RISK_CSV_HEADERS,
  RISK_CSV_TEMPLATE,
  RISK_FORMAT_TEXT,
} from './RiskModals.constants'
import { getRiskParsers, prepareRisksUpload } from './RiskModals.helpers'
import { AdditionalRiskDetails } from './RiskModal.components'

export const AddRiskCSVModal = (): JSX.Element => {
  const { data: users, isLoading: usersLoading } = useUsers()
  const uploadFunc = useCreateOrUpdateRisks(true)
  const { data: controlIds, isLoading: controlIdsLoading } = useListControlIds(
    new ListControlsRequest(),
  )
  const { data: controls, isLoading: controlsLoading } = useControls()
  const controlsMap =
    controls
      ?.getItemsList()
      .reduce((acc: { [x: string]: string }, item: Control) => {
        acc[item.getModelId()] = item.getId()
        return acc
      }, {}) || {}
  const riskParsers = useMemo(() => {
    const controlIdSet: Set<string> = new Set(controlIds?.getItemList() || [])
    const userEmails: Set<string> = new Set(
      users?.getUsersList().map((user: UserRecord) => user.getEmail()) || [],
    )
    return getRiskParsers(userEmails, controlIdSet)
  }, [controlIds, users])

  const uploadRiskCSV = async (items: ImportRiskCSVRow[]) => {
    const risks = prepareRisksUpload(items, controlsMap)
    await uploadFunc(risks)
  }

  if (
    !users ||
    !controlIds ||
    controlIdsLoading ||
    usersLoading ||
    !controls ||
    controlsLoading
  ) {
    return <></>
  }

  return (
    <UploadCSVModal<ImportRiskCSVRow>
      modalId={ModalFormId.ADD_RISK_CSV}
      prevModalId={ModalFormId.ADD_RISK}
      uploadItemsFunc={uploadRiskCSV}
      csvHeaders={RISK_CSV_HEADERS}
      csvColumnParsers={riskParsers}
      titleText="Upload Risks in Bulk"
      description="Upload a CSV file of risks. You can add them all at once, provided you follow this simple format."
      instruction="Your CSV should have one row for each risk with the following columns:"
      formatSampleText={RISK_FORMAT_TEXT}
      submitText="Upload Request List"
      csvTemplate={RISK_CSV_TEMPLATE}
      additionalDetails={<AdditionalRiskDetails />}
      uploadType={CSV_UPLOAD_TYPES.RISK}
    />
  )
}
