export enum INDEX_PAGE_WITH_FILTERS {
  CONTROLS = 'controls',
  CONTROLS_AI = 'controls_ai',
  POLICIES = 'policies',
  RISKS = 'risks',
  REQUESTS = 'requests',
  SERVICES = 'services',
  RECEPTORS = 'receptors',
  VENDORS = 'vendors',
  EVIDENCE = 'evidence',
}

const DEFAULT_INDEX_PAGE_FILTERS = {
  RECEPTORS: '?status[]=Active',
}

export const DEFAULT_INDEX_STATE_PARAMS = {
  RECEPTORS: DEFAULT_INDEX_PAGE_FILTERS.RECEPTORS,
}
