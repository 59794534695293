import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { useCallback } from 'react'
import { ModelID } from '@trustero/trustero-api-web/lib/account/account_pb'
import { useAuthorizedGrpcClient } from '../../adapter'

export function useGetReceptorToken(): (request: ModelID) => Promise<string> {
  const client = useAuthorizedGrpcClient(AccountPromiseClient)
  return useCallback(
    async (request: ModelID) => {
      const result = await client.getReceptorToken(request)
      return result.getToken()
    },
    [client],
  )
}
