import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import themeGet from '@styled-system/theme-get'
import {
  ActivateSVG,
  ControlsSVG,
  PoliciesSVG,
} from 'src/components/Icons/Basic'
import {
  color,
  compose,
  fontSize,
  margin,
  padding,
  textStyle,
} from 'styled-system'
import palette from '../../../../designSystem/variables/palette'
import { ReactComponent as ArrowSVG } from '../../../../components/Icons/assets/arrow-forward-icon.svg'
import { ReactComponent as OriginalCircleCheck } from '../../../../components/Icons/Basic/icons_circle_check_solid.svg'

export const ChecklistCardContainer = styled.div.attrs({
  mr: 'xl',
  p: 'l',
})`
  ${padding}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 135px;
  border: 1px solid #d9dfe8;
  border-radius: 4px;
  color: unset !important;
  text-decoration: none;

  &:not(:last-child) {
    ${margin}
  }

  &:hover {
    color: white !important;
    background-color: ${palette.neutral['800']};

    .finished-count {
      display: none;
    }

    .unfinished-count {
      display: none;
    }

    .empty-message {
      display: none;
    }

    .icon {
      display: none;
    }

    .complete {
      display: none;
    }

    .small-complete {
      display: inline-block;
    }

    .card-link-section {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
`

export const Title = styled.h1.attrs({
  textStyle: 'subtitle.1',
})`
  ${textStyle}
  display: flex;
  align-items: center;
  margin: 0;
`

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const Ratio = styled.p`
  line-height: 1;
`

export const Finished = styled.span.attrs({
  className: 'finished-count',
  textStyle: 'display',
})`
  ${textStyle}
`

export const EmptyMessage = styled.span.attrs({
  className: 'empty-message',
  color: 'text.low',
})`
  ${compose(color)}
`

export const Total = styled.span.attrs({
  className: 'unfinished-count',
  color: 'text.low',
  textStyle: 'header.4',
})`
  ${compose(color, textStyle)}
  :before {
    content: '/';
  }
`

export const IconContainer = styled.div.attrs({
  className: 'icon',
  bg: 'bg.neutralDark',
})<{ height?: number; width?: number }>`
  ${color}
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ width }) => (width ? width : 40)}px;
  height: ${({ height }) => (height ? height : 40)}px;
  border-radius: 50%;
  background-clip: padding-box;

  svg:not(.progress-ring) {
    width: 24px;
    height: 24px;
    filter: grayscale(1);
    opacity: 0.8;
  }
`

export const CardLinkSection = styled.div.attrs({
  className: 'card-link-section',
})`
  display: none;
`

export const IconProgressRing = styled.svg.attrs({
  className: 'progress-ring',
})`
  position: absolute;
  width: ${({ width }) => (width ? width : 56)}px;
  height: ${({ height }) => (height ? height : 56)}px;
`

type AcceptProps = {
  progress: number
  radius?: number
}

type ReturnProps = {
  progress: number
  offsetstart: number
  offsetend: number
}

const ProgressCircle = ({ ...props }) => <circle {...props} />

/**
 * @deprecated Use `<ProgressCircle />` moving forward (/Reusable/ProgressCircle/ProgressCircle.tsx)
 */
export const IconProgressRingCircle = styled(ProgressCircle).attrs<
  AcceptProps,
  ReturnProps
>((props) => {
  const radius = props.radius || 26
  const circumference = radius * 2 * Math.PI

  return {
    ...props,
    r: `${radius}`,
    cx: `${radius + 2}`,
    cy: `${radius + 2}`,
    stroke: props.theme.colors?.fill.status.success.light,
    strokeWidth: '4',
    strokeLinecap: 'round',
    strokeDasharray: `${circumference} ${circumference}`,
    fill: 'transparent',
    offsetstart: circumference,
    offsetend: circumference * (1 - (props.progress || 0)),
  }
})`
  transform: rotate(-90deg);
  transform-origin: center;
  stroke-dashoffset: ${(props) => props.offsetend};

  @keyframes animate_checklist_ring {
    from {
      stroke-dashoffset: ${(props) => `${props.offsetstart}`};
    }
  }

  animation: animate_checklist_ring 0.35s ease 1;
  transition: stroke-dashoffset 0.35s ease;
`

export const ArrowIcon = styled(ArrowSVG).attrs({
  width: 16,
  height: 16,
  ml: 'xs',
})`
  ${margin}
  fill: ${(props) => props.theme.colors?.text.icon.hover};
`

export const CompleteIcon = styled(OriginalCircleCheck).attrs({
  className: 'complete',
})`
  ${color}
  fill: fill.tertiary.light
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-clip: padding-box;

  path {
    fill: ${themeGet('colors.fill.tertiary.light')};
  }
`

export const SmallCompleteIcon = styled(CompleteIcon).attrs({
  className: 'small-complete',
  ml: 'xs',
})`
  ${margin}
  height: 16px;
  width: 16px;
`

export const CardLinkWithArrow = styled(Link).attrs<{ text: string }>(
  ({ text }) => ({
    className: 'card-link',
    fontSize: '2',
    children: (
      <>
        {text}
        <ArrowIcon />
      </>
    ),
  }),
)<{ text: string }>`
  ${compose(fontSize)}
  color: white !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const CardLinkComplete = styled.div.attrs<{ text: string }>(
  ({ text }) => ({
    className: 'card-link',
    fontSize: '2',
    children: (
      <>
        {text}
        <SmallCompleteIcon />
      </>
    ),
  }),
)<{ text: string }>`
  ${compose(fontSize)}
`

export const ControlCircleIcon = styled(ControlsSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const PolicyCircleIcon = styled(PoliciesSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const ReceptorCircleIcon = styled(ActivateSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`
