import React, { Dispatch, SetStateAction } from 'react'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { GridColumn } from '../../Grid'
import { CheckBoxInput } from '../../Inputs/CheckBox/CheckBoxInput'
import { MultiSelectRow } from './MultiSelectFormComponents.styles'

interface SelectRisksRowItemBaseProps {
  risk: Risk
  selectedRiskIds: string[]
  setSelectedRiskIds: Dispatch<SetStateAction<string[]>>
}

export const SelectRisksRowItem = ({
  risk,
  selectedRiskIds,
  setSelectedRiskIds,
}: SelectRisksRowItemBaseProps): JSX.Element => {
  const selectedRisks = new Set(selectedRiskIds)
  const riskId = risk.getId()
  const riskName = risk.getName()
  const riskCustomId = risk.getCustomId()

  const onChangeHandler = () => {
    selectedRisks.has(riskId)
      ? selectedRisks.delete(riskId)
      : selectedRisks.add(riskId)
    setSelectedRiskIds(Array.from(selectedRisks))
  }

  return (
    <MultiSelectRow id={`select-risk-item-${riskId}`} onClick={onChangeHandler}>
      <GridColumn>
        <CheckBoxInput
          id={riskId}
          checked={selectedRisks.has(riskId)}
          onClick={(e) => e.stopPropagation()}
          onChange={onChangeHandler}
        />
      </GridColumn>
      <GridColumn>{riskCustomId}</GridColumn>
      <GridColumn>{riskName}</GridColumn>
    </MultiSelectRow>
  )
}
