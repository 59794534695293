import * as R from 'rambda'
import log from 'loglevel'
import {
  GetByModelIDsRequest,
  ModelRecord,
} from '@trustero/trustero-api-web/lib/model/model_pb'
import { ATTACHMENT_TYPE } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  GetTestResultsRequest,
  TestResult,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import React from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { Message } from 'google-protobuf'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import * as logos from '../components/Icons/Dependencies'
import { Content, Model } from '../context/Content/defs'
import { ContentStaticHelper } from '../context/Content/statichelpers'
import { AuthAction, AuthRecord } from '../context/authContext'
import { Service } from '../xgenerated'
import { ServiceCategory, ServiceType } from '../xgenerated/service'
import TestDefinitions from '../xgenerated/testdefinition'
import { KeyedPromises } from '../Utils'
import { GrpcCall } from '../components/async/utils'
import { LogEntry } from './ChangelogAdapter'
import AttachmentAdapter, { DocumentObj } from './AttachmentAdapter'
import { authorizedGrpcClientFactory } from './grpcClient'
import DataModelAdapter from './DataModelAdapter'

const logger = log.getLogger('adapter')

// THIS IS THE CORE FUNCTION IN THIS FILE
// 1. make the API call
// 2. use the Changelog API to find+fetch the changed Objects
// 3. pass the changed objects to the `ContentContext` for merging
export async function callApiAndUpdateContent<T>(
  api_func: () => Promise<T | undefined>,
  content: Content,
  contentDispatch: React.Dispatch<Content>,
  authCtx: AuthRecord,
  authDispatch: React.Dispatch<AuthAction>,
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
  methodRequestMutator: (
    asyncCall: GrpcCall<Message, Message>,
    request: Message,
  ) => Promise<Message>,
): Promise<T | undefined> {
  const result = await api_func()
  const delta = await DataModelAdapter.getDeltaUpdate(
    content.lastUpdateStamp + 1,
    content,
    authCtx,
    authDispatch,
    methodMutator,
    methodRequestMutator,
  )
  if (delta && Object.keys(delta).length > 0) {
    contentDispatch(delta)
  }
  return result
}

/**
 * Clones an existing local model object or creates a new one if necessary.
 */
export function createLocalModel(
  content: Content,
  modelType: MODEL_TYPE,
  modelId: string,
): Model | undefined {
  const doCreateLocalModel = () => {
    if (modelType === MODEL_TYPE.USEROBJ) {
      const userModel = ContentStaticHelper.getObjById(content, modelId)
      return userModel ? R.clone(userModel) : null
    }
    if (modelType === MODEL_TYPE.SERVICE && !content.idIndex[modelId]) {
      return {
        ...({} as ModelRecord.AsObject),
        id: modelId,
        name: 'Unknown Service',
        content: 'This service is specific to your organization.',
        custom: true,
        component_category: ServiceCategory['Business Services and Tools'],
        component_type: ServiceType['SaaS'],
        control_ids: [],
        recommended_service: false,
        detected: true,
        logo: logos.UnknownSaaS,
        logo_name: 'UnknownSaaS',
      } as Service
    }
    const modelObj = content.idIndex[modelId]
    return modelObj ? R.clone(modelObj) : null
  }

  const localModel = doCreateLocalModel()
  if (localModel) {
    return localModel
  }

  logger.warn(`Unsupported model type: ${modelType}  , ${modelId}`)
  return
}

export type ModelData = {
  modelRecords: ModelRecord.AsObject[]
  docsForSubjectModelIds: string[]
  documents: DocumentObj[]
  testResultsForSubjectModelIds: string[]
  testResults: TestResult.AsObject[]
}

/**
 * Fetch model info from server
 */
export async function fetchModelDataFromServer(
  modelType: number,
  modelIds: string[],
  changelogEntries: LogEntry[],
  excludeBody: boolean,
  authCtx: AuthRecord | null,
  authDispatch: React.Dispatch<AuthAction> | null,
): Promise<ModelData> {
  const docsForSubjectModelIds = R.pipe(
    R.filter(
      (logEntry: LogEntry) =>
        logEntry.attachmenttype === ATTACHMENT_TYPE.DOCUMENT,
    ),
    R.pluck('subjectmodelid'),
    R.uniq,
  )(changelogEntries)

  const testResultsForSubjectModelIds = R.pipe(
    R.filter(
      (logEntry: LogEntry) =>
        // @ts-ignore
        logEntry.attachmenttype === ATTACHMENT_TYPE.TESTRESULT,
    ),
    R.pluck('subjectmodelid'),
    R.uniq,
  )(changelogEntries)

  const modelClient = authorizedGrpcClientFactory(
    ModelPromiseClient,
    authCtx,
    authDispatch,
  )

  const promises = {
    modelRecords: getModelsByIds(modelClient, modelIds, modelType),
    docsForSubjectModelIds,
    documents: AttachmentAdapter.getDocumentsBySubjectIds(
      {
        modelIds: docsForSubjectModelIds as string[],
        beginTime: 0,
        endTime: 0,
        excludeBody,
      },
      authCtx,
      authDispatch,
    ),
    testResultsForSubjectModelIds,
    testResults: (async () => {
      if (testResultsForSubjectModelIds.length === 0) {
        return []
      }
      const testingClient = authorizedGrpcClientFactory(
        TestingPromiseClient,
        authCtx,
        authDispatch,
      )
      const request = new GetTestResultsRequest().setModelIdsList(
        testResultsForSubjectModelIds.map((p) =>
          new Identifier().setModelid(p).setModeltype(MODEL_TYPE.CONTROL),
        ),
      )
      const response = await testingClient.getTestResults(request)
      const testResults = response.toObject().testresultsList
      // Append the proper names and descriptions for automated tests
      testResults.forEach((testResult) => {
        if (testResult.testdefinitionid) {
          const testDefinition = TestDefinitions.find(
            (td) => td.id === testResult.testdefinitionid,
          )
          if (testDefinition) {
            testResult.name = testDefinition.name
            testResult.description = testDefinition.description
          }
        }
      })
      return testResults
    })(),
  }

  return (await KeyedPromises(promises)) as ModelData
}

async function getModelsByIds(
  modelClient: ModelPromiseClient,
  modelIds: string[],
  modelType: MODEL_TYPE,
): Promise<ModelRecord.AsObject[]> {
  if (modelIds.length === 0) {
    return []
  }
  const response = await modelClient.getByModelIDs(
    new GetByModelIDsRequest().setIdsList(
      modelIds.map((id) =>
        new Identifier().setModeltype(modelType).setModelid(id),
      ),
    ),
  )
  return response.getRecordsList().map((p) => p.toObject())
}
