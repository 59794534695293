// source: evidence/testing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_time_pb = require('../common/time_pb.js');
goog.object.extend(proto, common_time_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
var attachment_attachment_pb = require('../attachment/attachment_pb.js');
goog.object.extend(proto, attachment_attachment_pb);
goog.exportSymbol('proto.evidence.CreateUpdateTestDefinitionRequest', null, global);
goog.exportSymbol('proto.evidence.EvidenceIssue', null, global);
goog.exportSymbol('proto.evidence.EvidenceLocation', null, global);
goog.exportSymbol('proto.evidence.EvidenceTestResult', null, global);
goog.exportSymbol('proto.evidence.GetEvidenceIssuesRequest', null, global);
goog.exportSymbol('proto.evidence.GetEvidenceIssuesResponse', null, global);
goog.exportSymbol('proto.evidence.GetFailedTestsBreakdownResponse', null, global);
goog.exportSymbol('proto.evidence.GetLatestTestResultRequest', null, global);
goog.exportSymbol('proto.evidence.GetLatestTestResultRequest.TestStatusValue', null, global);
goog.exportSymbol('proto.evidence.GetLatestTestResultsResponse', null, global);
goog.exportSymbol('proto.evidence.GetTestResultRequest', null, global);
goog.exportSymbol('proto.evidence.GetTestResultResponse', null, global);
goog.exportSymbol('proto.evidence.GetTestResultsRequest', null, global);
goog.exportSymbol('proto.evidence.GetTestResultsResponse', null, global);
goog.exportSymbol('proto.evidence.LatestTestResult', null, global);
goog.exportSymbol('proto.evidence.PassingCount', null, global);
goog.exportSymbol('proto.evidence.RunAccountTestsByReceptorModelIDRequest', null, global);
goog.exportSymbol('proto.evidence.StatusResponse', null, global);
goog.exportSymbol('proto.evidence.TestDefinition', null, global);
goog.exportSymbol('proto.evidence.TestResult', null, global);
goog.exportSymbol('proto.evidence.TestStatus', null, global);
goog.exportSymbol('proto.evidence.TestType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetTestResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.GetTestResultsRequest.repeatedFields_, null);
};
goog.inherits(proto.evidence.GetTestResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetTestResultsRequest.displayName = 'proto.evidence.GetTestResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetTestResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.GetTestResultsResponse.repeatedFields_, null);
};
goog.inherits(proto.evidence.GetTestResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetTestResultsResponse.displayName = 'proto.evidence.GetTestResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetTestResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.GetTestResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetTestResultRequest.displayName = 'proto.evidence.GetTestResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetTestResultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.GetTestResultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetTestResultResponse.displayName = 'proto.evidence.GetTestResultResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetLatestTestResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.GetLatestTestResultRequest.repeatedFields_, null);
};
goog.inherits(proto.evidence.GetLatestTestResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetLatestTestResultRequest.displayName = 'proto.evidence.GetLatestTestResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.GetLatestTestResultRequest.TestStatusValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetLatestTestResultRequest.TestStatusValue.displayName = 'proto.evidence.GetLatestTestResultRequest.TestStatusValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetLatestTestResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.GetLatestTestResultsResponse.repeatedFields_, null);
};
goog.inherits(proto.evidence.GetLatestTestResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetLatestTestResultsResponse.displayName = 'proto.evidence.GetLatestTestResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.LatestTestResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.LatestTestResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.LatestTestResult.displayName = 'proto.evidence.LatestTestResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.PassingCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.PassingCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.PassingCount.displayName = 'proto.evidence.PassingCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetFailedTestsBreakdownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.GetFailedTestsBreakdownResponse.repeatedFields_, null);
};
goog.inherits(proto.evidence.GetFailedTestsBreakdownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetFailedTestsBreakdownResponse.displayName = 'proto.evidence.GetFailedTestsBreakdownResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.TestResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.TestResult.repeatedFields_, null);
};
goog.inherits(proto.evidence.TestResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.TestResult.displayName = 'proto.evidence.TestResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.EvidenceTestResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.EvidenceTestResult.repeatedFields_, null);
};
goog.inherits(proto.evidence.EvidenceTestResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.EvidenceTestResult.displayName = 'proto.evidence.EvidenceTestResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.EvidenceLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.EvidenceLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.EvidenceLocation.displayName = 'proto.evidence.EvidenceLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.EvidenceIssue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.EvidenceIssue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.EvidenceIssue.displayName = 'proto.evidence.EvidenceIssue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.StatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.StatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.StatusResponse.displayName = 'proto.evidence.StatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.CreateUpdateTestDefinitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.CreateUpdateTestDefinitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.CreateUpdateTestDefinitionRequest.displayName = 'proto.evidence.CreateUpdateTestDefinitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.TestDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.TestDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.TestDefinition.displayName = 'proto.evidence.TestDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.RunAccountTestsByReceptorModelIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.RunAccountTestsByReceptorModelIDRequest.displayName = 'proto.evidence.RunAccountTestsByReceptorModelIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetEvidenceIssuesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.evidence.GetEvidenceIssuesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetEvidenceIssuesRequest.displayName = 'proto.evidence.GetEvidenceIssuesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.evidence.GetEvidenceIssuesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.evidence.GetEvidenceIssuesResponse.repeatedFields_, null);
};
goog.inherits(proto.evidence.GetEvidenceIssuesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.evidence.GetEvidenceIssuesResponse.displayName = 'proto.evidence.GetEvidenceIssuesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.GetTestResultsRequest.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetTestResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetTestResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetTestResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelIdsList: jspb.Message.toObjectList(msg.getModelIdsList(),
    common_model_pb.Identifier.toObject, includeInstance),
    creationRange: (f = msg.getCreationRange()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    evidenceId: (f = msg.getEvidenceId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    caption: (f = msg.getCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetTestResultsRequest}
 */
proto.evidence.GetTestResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetTestResultsRequest;
  return proto.evidence.GetTestResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetTestResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetTestResultsRequest}
 */
proto.evidence.GetTestResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.addModelIds(value);
      break;
    case 2:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setCreationRange(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEvidenceId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCaption(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetTestResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetTestResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetTestResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getCreationRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getEvidenceId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCaption();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * repeated common.Identifier model_ids = 1;
 * @return {!Array<!proto.common.Identifier>}
 */
proto.evidence.GetTestResultsRequest.prototype.getModelIdsList = function() {
  return /** @type{!Array<!proto.common.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {!Array<!proto.common.Identifier>} value
 * @return {!proto.evidence.GetTestResultsRequest} returns this
*/
proto.evidence.GetTestResultsRequest.prototype.setModelIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Identifier}
 */
proto.evidence.GetTestResultsRequest.prototype.addModelIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.clearModelIdsList = function() {
  return this.setModelIdsList([]);
};


/**
 * optional common.TimeRange creation_range = 2;
 * @return {?proto.common.TimeRange}
 */
proto.evidence.GetTestResultsRequest.prototype.getCreationRange = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 2));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.evidence.GetTestResultsRequest} returns this
*/
proto.evidence.GetTestResultsRequest.prototype.setCreationRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.clearCreationRange = function() {
  return this.setCreationRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetTestResultsRequest.prototype.hasCreationRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue evidence_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.evidence.GetTestResultsRequest.prototype.getEvidenceId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.evidence.GetTestResultsRequest} returns this
*/
proto.evidence.GetTestResultsRequest.prototype.setEvidenceId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.clearEvidenceId = function() {
  return this.setEvidenceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetTestResultsRequest.prototype.hasEvidenceId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue caption = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.evidence.GetTestResultsRequest.prototype.getCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.evidence.GetTestResultsRequest} returns this
*/
proto.evidence.GetTestResultsRequest.prototype.setCaption = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.clearCaption = function() {
  return this.setCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetTestResultsRequest.prototype.hasCaption = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string control_ids = 5;
 * @return {!Array<string>}
 */
proto.evidence.GetTestResultsRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetTestResultsRequest} returns this
 */
proto.evidence.GetTestResultsRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.GetTestResultsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetTestResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetTestResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetTestResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    testresultsList: jspb.Message.toObjectList(msg.getTestresultsList(),
    proto.evidence.TestResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetTestResultsResponse}
 */
proto.evidence.GetTestResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetTestResultsResponse;
  return proto.evidence.GetTestResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetTestResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetTestResultsResponse}
 */
proto.evidence.GetTestResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.evidence.TestResult;
      reader.readMessage(value,proto.evidence.TestResult.deserializeBinaryFromReader);
      msg.addTestresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetTestResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetTestResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetTestResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestresultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.evidence.TestResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TestResult TestResults = 1;
 * @return {!Array<!proto.evidence.TestResult>}
 */
proto.evidence.GetTestResultsResponse.prototype.getTestresultsList = function() {
  return /** @type{!Array<!proto.evidence.TestResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.evidence.TestResult, 1));
};


/**
 * @param {!Array<!proto.evidence.TestResult>} value
 * @return {!proto.evidence.GetTestResultsResponse} returns this
*/
proto.evidence.GetTestResultsResponse.prototype.setTestresultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.evidence.TestResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.evidence.TestResult}
 */
proto.evidence.GetTestResultsResponse.prototype.addTestresults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.evidence.TestResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetTestResultsResponse} returns this
 */
proto.evidence.GetTestResultsResponse.prototype.clearTestresultsList = function() {
  return this.setTestresultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetTestResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetTestResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetTestResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    testId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetTestResultRequest}
 */
proto.evidence.GetTestResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetTestResultRequest;
  return proto.evidence.GetTestResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetTestResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetTestResultRequest}
 */
proto.evidence.GetTestResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetTestResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetTestResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetTestResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string test_id = 1;
 * @return {string}
 */
proto.evidence.GetTestResultRequest.prototype.getTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.GetTestResultRequest} returns this
 */
proto.evidence.GetTestResultRequest.prototype.setTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetTestResultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetTestResultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetTestResultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    testResult: (f = msg.getTestResult()) && proto.evidence.TestResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetTestResultResponse}
 */
proto.evidence.GetTestResultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetTestResultResponse;
  return proto.evidence.GetTestResultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetTestResultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetTestResultResponse}
 */
proto.evidence.GetTestResultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.evidence.TestResult;
      reader.readMessage(value,proto.evidence.TestResult.deserializeBinaryFromReader);
      msg.setTestResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetTestResultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetTestResultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetTestResultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetTestResultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.evidence.TestResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional TestResult test_result = 1;
 * @return {?proto.evidence.TestResult}
 */
proto.evidence.GetTestResultResponse.prototype.getTestResult = function() {
  return /** @type{?proto.evidence.TestResult} */ (
    jspb.Message.getWrapperField(this, proto.evidence.TestResult, 1));
};


/**
 * @param {?proto.evidence.TestResult|undefined} value
 * @return {!proto.evidence.GetTestResultResponse} returns this
*/
proto.evidence.GetTestResultResponse.prototype.setTestResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetTestResultResponse} returns this
 */
proto.evidence.GetTestResultResponse.prototype.clearTestResult = function() {
  return this.setTestResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetTestResultResponse.prototype.hasTestResult = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.GetLatestTestResultRequest.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetLatestTestResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetLatestTestResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetLatestTestResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetLatestTestResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelIdsList: jspb.Message.toObjectList(msg.getModelIdsList(),
    common_model_pb.Identifier.toObject, includeInstance),
    testStatus: (f = msg.getTestStatus()) && proto.evidence.GetLatestTestResultRequest.TestStatusValue.toObject(includeInstance, f),
    timeRange: (f = msg.getTimeRange()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetLatestTestResultRequest}
 */
proto.evidence.GetLatestTestResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetLatestTestResultRequest;
  return proto.evidence.GetLatestTestResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetLatestTestResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetLatestTestResultRequest}
 */
proto.evidence.GetLatestTestResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.addModelIds(value);
      break;
    case 3:
      var value = new proto.evidence.GetLatestTestResultRequest.TestStatusValue;
      reader.readMessage(value,proto.evidence.GetLatestTestResultRequest.TestStatusValue.deserializeBinaryFromReader);
      msg.setTestStatus(value);
      break;
    case 4:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetLatestTestResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetLatestTestResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetLatestTestResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetLatestTestResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getTestStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.evidence.GetLatestTestResultRequest.TestStatusValue.serializeBinaryToWriter
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetLatestTestResultRequest.TestStatusValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetLatestTestResultRequest.TestStatusValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetLatestTestResultRequest.TestStatusValue}
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetLatestTestResultRequest.TestStatusValue;
  return proto.evidence.GetLatestTestResultRequest.TestStatusValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetLatestTestResultRequest.TestStatusValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetLatestTestResultRequest.TestStatusValue}
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.evidence.TestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetLatestTestResultRequest.TestStatusValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetLatestTestResultRequest.TestStatusValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional TestStatus status = 1;
 * @return {!proto.evidence.TestStatus}
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.prototype.getStatus = function() {
  return /** @type {!proto.evidence.TestStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.evidence.TestStatus} value
 * @return {!proto.evidence.GetLatestTestResultRequest.TestStatusValue} returns this
 */
proto.evidence.GetLatestTestResultRequest.TestStatusValue.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated common.Identifier model_ids = 1;
 * @return {!Array<!proto.common.Identifier>}
 */
proto.evidence.GetLatestTestResultRequest.prototype.getModelIdsList = function() {
  return /** @type{!Array<!proto.common.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {!Array<!proto.common.Identifier>} value
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
*/
proto.evidence.GetLatestTestResultRequest.prototype.setModelIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Identifier}
 */
proto.evidence.GetLatestTestResultRequest.prototype.addModelIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
 */
proto.evidence.GetLatestTestResultRequest.prototype.clearModelIdsList = function() {
  return this.setModelIdsList([]);
};


/**
 * optional TestStatusValue test_status = 3;
 * @return {?proto.evidence.GetLatestTestResultRequest.TestStatusValue}
 */
proto.evidence.GetLatestTestResultRequest.prototype.getTestStatus = function() {
  return /** @type{?proto.evidence.GetLatestTestResultRequest.TestStatusValue} */ (
    jspb.Message.getWrapperField(this, proto.evidence.GetLatestTestResultRequest.TestStatusValue, 3));
};


/**
 * @param {?proto.evidence.GetLatestTestResultRequest.TestStatusValue|undefined} value
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
*/
proto.evidence.GetLatestTestResultRequest.prototype.setTestStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
 */
proto.evidence.GetLatestTestResultRequest.prototype.clearTestStatus = function() {
  return this.setTestStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetLatestTestResultRequest.prototype.hasTestStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.TimeRange time_range = 4;
 * @return {?proto.common.TimeRange}
 */
proto.evidence.GetLatestTestResultRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 4));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
*/
proto.evidence.GetLatestTestResultRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
 */
proto.evidence.GetLatestTestResultRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetLatestTestResultRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string control_ids = 5;
 * @return {!Array<string>}
 */
proto.evidence.GetLatestTestResultRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
 */
proto.evidence.GetLatestTestResultRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
 */
proto.evidence.GetLatestTestResultRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetLatestTestResultRequest} returns this
 */
proto.evidence.GetLatestTestResultRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.GetLatestTestResultsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetLatestTestResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetLatestTestResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetLatestTestResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetLatestTestResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.evidence.LatestTestResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetLatestTestResultsResponse}
 */
proto.evidence.GetLatestTestResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetLatestTestResultsResponse;
  return proto.evidence.GetLatestTestResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetLatestTestResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetLatestTestResultsResponse}
 */
proto.evidence.GetLatestTestResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.evidence.LatestTestResult;
      reader.readMessage(value,proto.evidence.LatestTestResult.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetLatestTestResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetLatestTestResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetLatestTestResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetLatestTestResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.evidence.LatestTestResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LatestTestResult items = 1;
 * @return {!Array<!proto.evidence.LatestTestResult>}
 */
proto.evidence.GetLatestTestResultsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.evidence.LatestTestResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.evidence.LatestTestResult, 1));
};


/**
 * @param {!Array<!proto.evidence.LatestTestResult>} value
 * @return {!proto.evidence.GetLatestTestResultsResponse} returns this
*/
proto.evidence.GetLatestTestResultsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.evidence.LatestTestResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.evidence.LatestTestResult}
 */
proto.evidence.GetLatestTestResultsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.evidence.LatestTestResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetLatestTestResultsResponse} returns this
 */
proto.evidence.GetLatestTestResultsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.LatestTestResult.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.LatestTestResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.LatestTestResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.LatestTestResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    testResult: (f = msg.getTestResult()) && proto.evidence.TestResult.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.LatestTestResult}
 */
proto.evidence.LatestTestResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.LatestTestResult;
  return proto.evidence.LatestTestResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.LatestTestResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.LatestTestResult}
 */
proto.evidence.LatestTestResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.evidence.TestResult;
      reader.readMessage(value,proto.evidence.TestResult.deserializeBinaryFromReader);
      msg.setTestResult(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.LatestTestResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.LatestTestResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.LatestTestResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.LatestTestResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.evidence.TestResult.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional TestResult test_result = 1;
 * @return {?proto.evidence.TestResult}
 */
proto.evidence.LatestTestResult.prototype.getTestResult = function() {
  return /** @type{?proto.evidence.TestResult} */ (
    jspb.Message.getWrapperField(this, proto.evidence.TestResult, 1));
};


/**
 * @param {?proto.evidence.TestResult|undefined} value
 * @return {!proto.evidence.LatestTestResult} returns this
*/
proto.evidence.LatestTestResult.prototype.setTestResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.LatestTestResult} returns this
 */
proto.evidence.LatestTestResult.prototype.clearTestResult = function() {
  return this.setTestResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.LatestTestResult.prototype.hasTestResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.evidence.LatestTestResult.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.evidence.LatestTestResult} returns this
 */
proto.evidence.LatestTestResult.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.PassingCount.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.PassingCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.PassingCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.PassingCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.PassingCount}
 */
proto.evidence.PassingCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.PassingCount;
  return proto.evidence.PassingCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.PassingCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.PassingCount}
 */
proto.evidence.PassingCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.PassingCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.PassingCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.PassingCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.PassingCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.evidence.PassingCount.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.evidence.PassingCount} returns this
 */
proto.evidence.PassingCount.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.GetFailedTestsBreakdownResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetFailedTestsBreakdownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetFailedTestsBreakdownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetFailedTestsBreakdownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    failedTestsList: jspb.Message.toObjectList(msg.getFailedTestsList(),
    proto.evidence.TestResult.toObject, includeInstance),
    passingCount: (f = msg.getPassingCount()) && proto.evidence.PassingCount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetFailedTestsBreakdownResponse}
 */
proto.evidence.GetFailedTestsBreakdownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetFailedTestsBreakdownResponse;
  return proto.evidence.GetFailedTestsBreakdownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetFailedTestsBreakdownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetFailedTestsBreakdownResponse}
 */
proto.evidence.GetFailedTestsBreakdownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.evidence.TestResult;
      reader.readMessage(value,proto.evidence.TestResult.deserializeBinaryFromReader);
      msg.addFailedTests(value);
      break;
    case 2:
      var value = new proto.evidence.PassingCount;
      reader.readMessage(value,proto.evidence.PassingCount.deserializeBinaryFromReader);
      msg.setPassingCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetFailedTestsBreakdownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetFailedTestsBreakdownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetFailedTestsBreakdownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFailedTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.evidence.TestResult.serializeBinaryToWriter
    );
  }
  f = message.getPassingCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.evidence.PassingCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TestResult failed_tests = 1;
 * @return {!Array<!proto.evidence.TestResult>}
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.getFailedTestsList = function() {
  return /** @type{!Array<!proto.evidence.TestResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.evidence.TestResult, 1));
};


/**
 * @param {!Array<!proto.evidence.TestResult>} value
 * @return {!proto.evidence.GetFailedTestsBreakdownResponse} returns this
*/
proto.evidence.GetFailedTestsBreakdownResponse.prototype.setFailedTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.evidence.TestResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.evidence.TestResult}
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.addFailedTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.evidence.TestResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetFailedTestsBreakdownResponse} returns this
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.clearFailedTestsList = function() {
  return this.setFailedTestsList([]);
};


/**
 * optional PassingCount passing_count = 2;
 * @return {?proto.evidence.PassingCount}
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.getPassingCount = function() {
  return /** @type{?proto.evidence.PassingCount} */ (
    jspb.Message.getWrapperField(this, proto.evidence.PassingCount, 2));
};


/**
 * @param {?proto.evidence.PassingCount|undefined} value
 * @return {!proto.evidence.GetFailedTestsBreakdownResponse} returns this
*/
proto.evidence.GetFailedTestsBreakdownResponse.prototype.setPassingCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetFailedTestsBreakdownResponse} returns this
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.clearPassingCount = function() {
  return this.setPassingCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetFailedTestsBreakdownResponse.prototype.hasPassingCount = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.TestResult.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.TestResult.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.TestResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.TestResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.TestResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    modelid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    modeltype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    actor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    actortype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    evidencetestresultsList: jspb.Message.toObjectList(msg.getEvidencetestresultsList(),
    proto.evidence.EvidenceTestResult.toObject, includeInstance),
    testdefinitionid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 12, 0),
    actorname: jspb.Message.getFieldWithDefault(msg, 13, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.TestResult}
 */
proto.evidence.TestResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.TestResult;
  return proto.evidence.TestResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.TestResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.TestResult}
 */
proto.evidence.TestResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.evidence.TestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.evidence.TestType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 7:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 9:
      var value = /** @type {!proto.attachment.ACTOR_TYPE} */ (reader.readEnum());
      msg.setActortype(value);
      break;
    case 10:
      var value = new proto.evidence.EvidenceTestResult;
      reader.readMessage(value,proto.evidence.EvidenceTestResult.deserializeBinaryFromReader);
      msg.addEvidencetestresults(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestdefinitionid(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorname(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.TestResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.TestResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.TestResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.TestResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActortype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getEvidencetestresultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.evidence.EvidenceTestResult.serializeBinaryToWriter
    );
  }
  f = message.getTestdefinitionid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getActorname();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Description = 3;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TestStatus Status = 4;
 * @return {!proto.evidence.TestStatus}
 */
proto.evidence.TestResult.prototype.getStatus = function() {
  return /** @type {!proto.evidence.TestStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.evidence.TestStatus} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional TestType Type = 5;
 * @return {!proto.evidence.TestType}
 */
proto.evidence.TestResult.prototype.getType = function() {
  return /** @type {!proto.evidence.TestType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.evidence.TestType} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string ModelID = 6;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional common.MODEL_TYPE ModelType = 7;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.evidence.TestResult.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string Actor = 8;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional attachment.ACTOR_TYPE ActorType = 9;
 * @return {!proto.attachment.ACTOR_TYPE}
 */
proto.evidence.TestResult.prototype.getActortype = function() {
  return /** @type {!proto.attachment.ACTOR_TYPE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.attachment.ACTOR_TYPE} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setActortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated EvidenceTestResult EvidenceTestResults = 10;
 * @return {!Array<!proto.evidence.EvidenceTestResult>}
 */
proto.evidence.TestResult.prototype.getEvidencetestresultsList = function() {
  return /** @type{!Array<!proto.evidence.EvidenceTestResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.evidence.EvidenceTestResult, 10));
};


/**
 * @param {!Array<!proto.evidence.EvidenceTestResult>} value
 * @return {!proto.evidence.TestResult} returns this
*/
proto.evidence.TestResult.prototype.setEvidencetestresultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.evidence.EvidenceTestResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.evidence.EvidenceTestResult}
 */
proto.evidence.TestResult.prototype.addEvidencetestresults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.evidence.EvidenceTestResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.clearEvidencetestresultsList = function() {
  return this.setEvidencetestresultsList([]);
};


/**
 * optional string TestDefinitionID = 11;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getTestdefinitionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setTestdefinitionid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 CreatedAt = 12;
 * @return {number}
 */
proto.evidence.TestResult.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string ActorName = 13;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getActorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setActorname = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string control_id = 14;
 * @return {string}
 */
proto.evidence.TestResult.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestResult} returns this
 */
proto.evidence.TestResult.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.EvidenceTestResult.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.EvidenceTestResult.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.EvidenceTestResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.EvidenceTestResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.EvidenceTestResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.evidence.EvidenceIssue.toObject, includeInstance),
    fixitInstructionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    id: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.EvidenceTestResult}
 */
proto.evidence.EvidenceTestResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.EvidenceTestResult;
  return proto.evidence.EvidenceTestResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.EvidenceTestResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.EvidenceTestResult}
 */
proto.evidence.EvidenceTestResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {!proto.evidence.TestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.evidence.EvidenceIssue;
      reader.readMessage(value,proto.evidence.EvidenceIssue.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFixitInstructionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.EvidenceTestResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.EvidenceTestResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.EvidenceTestResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.EvidenceTestResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.evidence.EvidenceIssue.serializeBinaryToWriter
    );
  }
  f = message.getFixitInstructionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.evidence.EvidenceTestResult.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.EvidenceTestResult} returns this
 */
proto.evidence.EvidenceTestResult.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TestStatus status = 2;
 * @return {!proto.evidence.TestStatus}
 */
proto.evidence.EvidenceTestResult.prototype.getStatus = function() {
  return /** @type {!proto.evidence.TestStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.evidence.TestStatus} value
 * @return {!proto.evidence.EvidenceTestResult} returns this
 */
proto.evidence.EvidenceTestResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.evidence.EvidenceTestResult.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.evidence.EvidenceTestResult} returns this
 */
proto.evidence.EvidenceTestResult.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated EvidenceIssue issues = 4;
 * @return {!Array<!proto.evidence.EvidenceIssue>}
 */
proto.evidence.EvidenceTestResult.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.evidence.EvidenceIssue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.evidence.EvidenceIssue, 4));
};


/**
 * @param {!Array<!proto.evidence.EvidenceIssue>} value
 * @return {!proto.evidence.EvidenceTestResult} returns this
*/
proto.evidence.EvidenceTestResult.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.evidence.EvidenceIssue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.evidence.EvidenceIssue}
 */
proto.evidence.EvidenceTestResult.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.evidence.EvidenceIssue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.EvidenceTestResult} returns this
 */
proto.evidence.EvidenceTestResult.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


/**
 * optional string fixit_instruction_id = 5;
 * @return {string}
 */
proto.evidence.EvidenceTestResult.prototype.getFixitInstructionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.EvidenceTestResult} returns this
 */
proto.evidence.EvidenceTestResult.prototype.setFixitInstructionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.evidence.EvidenceTestResult.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.EvidenceTestResult} returns this
 */
proto.evidence.EvidenceTestResult.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.EvidenceLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.EvidenceLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.EvidenceLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.EvidenceLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    findingsindex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    observationsindex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.EvidenceLocation}
 */
proto.evidence.EvidenceLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.EvidenceLocation;
  return proto.evidence.EvidenceLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.EvidenceLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.EvidenceLocation}
 */
proto.evidence.EvidenceLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFindingsindex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setObservationsindex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.EvidenceLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.EvidenceLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.EvidenceLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.EvidenceLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFindingsindex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getObservationsindex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 FindingsIndex = 1;
 * @return {number}
 */
proto.evidence.EvidenceLocation.prototype.getFindingsindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.evidence.EvidenceLocation} returns this
 */
proto.evidence.EvidenceLocation.prototype.setFindingsindex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 ObservationsIndex = 2;
 * @return {number}
 */
proto.evidence.EvidenceLocation.prototype.getObservationsindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.evidence.EvidenceLocation} returns this
 */
proto.evidence.EvidenceLocation.prototype.setObservationsindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.EvidenceIssue.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.EvidenceIssue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.EvidenceIssue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.EvidenceIssue.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.evidence.EvidenceLocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.EvidenceIssue}
 */
proto.evidence.EvidenceIssue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.EvidenceIssue;
  return proto.evidence.EvidenceIssue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.EvidenceIssue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.EvidenceIssue}
 */
proto.evidence.EvidenceIssue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.evidence.EvidenceLocation;
      reader.readMessage(value,proto.evidence.EvidenceLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.EvidenceIssue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.EvidenceIssue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.EvidenceIssue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.EvidenceIssue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.evidence.EvidenceLocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Message = 1;
 * @return {string}
 */
proto.evidence.EvidenceIssue.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.EvidenceIssue} returns this
 */
proto.evidence.EvidenceIssue.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EvidenceLocation Location = 2;
 * @return {?proto.evidence.EvidenceLocation}
 */
proto.evidence.EvidenceIssue.prototype.getLocation = function() {
  return /** @type{?proto.evidence.EvidenceLocation} */ (
    jspb.Message.getWrapperField(this, proto.evidence.EvidenceLocation, 2));
};


/**
 * @param {?proto.evidence.EvidenceLocation|undefined} value
 * @return {!proto.evidence.EvidenceIssue} returns this
*/
proto.evidence.EvidenceIssue.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.EvidenceIssue} returns this
 */
proto.evidence.EvidenceIssue.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.EvidenceIssue.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.StatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.StatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.StatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.StatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.StatusResponse}
 */
proto.evidence.StatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.StatusResponse;
  return proto.evidence.StatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.StatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.StatusResponse}
 */
proto.evidence.StatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.StatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.StatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.StatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.StatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool Success = 1;
 * @return {boolean}
 */
proto.evidence.StatusResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.evidence.StatusResponse} returns this
 */
proto.evidence.StatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.evidence.StatusResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.StatusResponse} returns this
 */
proto.evidence.StatusResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.CreateUpdateTestDefinitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.CreateUpdateTestDefinitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.CreateUpdateTestDefinitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isTemplate: (f = msg.getIsTemplate()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isCustom: (f = msg.getIsCustom()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.CreateUpdateTestDefinitionRequest;
  return proto.evidence.CreateUpdateTestDefinitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.CreateUpdateTestDefinitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsTemplate(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.CreateUpdateTestDefinitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.CreateUpdateTestDefinitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.CreateUpdateTestDefinitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsCustom();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
*/
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
*/
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue is_template = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.getIsTemplate = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
*/
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.setIsTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.clearIsTemplate = function() {
  return this.setIsTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.hasIsTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue is_custom = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.getIsCustom = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
*/
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.setIsCustom = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.CreateUpdateTestDefinitionRequest} returns this
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.clearIsCustom = function() {
  return this.setIsCustom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.CreateUpdateTestDefinitionRequest.prototype.hasIsCustom = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.TestDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.TestDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.TestDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.TestDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.TestDefinition}
 */
proto.evidence.TestDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.TestDefinition;
  return proto.evidence.TestDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.TestDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.TestDefinition}
 */
proto.evidence.TestDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.TestDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.TestDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.TestDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.TestDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.evidence.TestDefinition.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.evidence.TestDefinition} returns this
*/
proto.evidence.TestDefinition.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.TestDefinition} returns this
 */
proto.evidence.TestDefinition.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.TestDefinition.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.evidence.TestDefinition.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.evidence.TestDefinition} returns this
*/
proto.evidence.TestDefinition.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.TestDefinition} returns this
 */
proto.evidence.TestDefinition.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.TestDefinition.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.evidence.TestDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestDefinition} returns this
 */
proto.evidence.TestDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model_id = 4;
 * @return {string}
 */
proto.evidence.TestDefinition.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestDefinition} returns this
 */
proto.evidence.TestDefinition.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.evidence.TestDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestDefinition} returns this
 */
proto.evidence.TestDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.evidence.TestDefinition.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.TestDefinition} returns this
 */
proto.evidence.TestDefinition.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.RunAccountTestsByReceptorModelIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptormodelid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.RunAccountTestsByReceptorModelIDRequest}
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.RunAccountTestsByReceptorModelIDRequest;
  return proto.evidence.RunAccountTestsByReceptorModelIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.RunAccountTestsByReceptorModelIDRequest}
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptormodelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.RunAccountTestsByReceptorModelIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptormodelid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ReceptorModelID = 1;
 * @return {string}
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.prototype.getReceptormodelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} returns this
 */
proto.evidence.RunAccountTestsByReceptorModelIDRequest.prototype.setReceptormodelid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetEvidenceIssuesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetEvidenceIssuesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetEvidenceIssuesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentid: (f = msg.getDocumentid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    evidencetestresultid: (f = msg.getEvidencetestresultid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetEvidenceIssuesRequest}
 */
proto.evidence.GetEvidenceIssuesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetEvidenceIssuesRequest;
  return proto.evidence.GetEvidenceIssuesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetEvidenceIssuesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetEvidenceIssuesRequest}
 */
proto.evidence.GetEvidenceIssuesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEvidencetestresultid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetEvidenceIssuesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetEvidenceIssuesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetEvidenceIssuesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEvidencetestresultid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue documentId = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.getDocumentid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.evidence.GetEvidenceIssuesRequest} returns this
*/
proto.evidence.GetEvidenceIssuesRequest.prototype.setDocumentid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetEvidenceIssuesRequest} returns this
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.clearDocumentid = function() {
  return this.setDocumentid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.hasDocumentid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue evidenceTestResultId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.getEvidencetestresultid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.evidence.GetEvidenceIssuesRequest} returns this
*/
proto.evidence.GetEvidenceIssuesRequest.prototype.setEvidencetestresultid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.evidence.GetEvidenceIssuesRequest} returns this
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.clearEvidencetestresultid = function() {
  return this.setEvidencetestresultid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.evidence.GetEvidenceIssuesRequest.prototype.hasEvidencetestresultid = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.evidence.GetEvidenceIssuesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.evidence.GetEvidenceIssuesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.evidence.GetEvidenceIssuesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.evidence.GetEvidenceIssuesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetEvidenceIssuesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceIssuesList: jspb.Message.toObjectList(msg.getEvidenceIssuesList(),
    proto.evidence.EvidenceIssue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.evidence.GetEvidenceIssuesResponse}
 */
proto.evidence.GetEvidenceIssuesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.evidence.GetEvidenceIssuesResponse;
  return proto.evidence.GetEvidenceIssuesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.evidence.GetEvidenceIssuesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.evidence.GetEvidenceIssuesResponse}
 */
proto.evidence.GetEvidenceIssuesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.evidence.EvidenceIssue;
      reader.readMessage(value,proto.evidence.EvidenceIssue.deserializeBinaryFromReader);
      msg.addEvidenceIssues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.evidence.GetEvidenceIssuesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.evidence.GetEvidenceIssuesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.evidence.GetEvidenceIssuesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.evidence.GetEvidenceIssuesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.evidence.EvidenceIssue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EvidenceIssue evidence_issues = 1;
 * @return {!Array<!proto.evidence.EvidenceIssue>}
 */
proto.evidence.GetEvidenceIssuesResponse.prototype.getEvidenceIssuesList = function() {
  return /** @type{!Array<!proto.evidence.EvidenceIssue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.evidence.EvidenceIssue, 1));
};


/**
 * @param {!Array<!proto.evidence.EvidenceIssue>} value
 * @return {!proto.evidence.GetEvidenceIssuesResponse} returns this
*/
proto.evidence.GetEvidenceIssuesResponse.prototype.setEvidenceIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.evidence.EvidenceIssue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.evidence.EvidenceIssue}
 */
proto.evidence.GetEvidenceIssuesResponse.prototype.addEvidenceIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.evidence.EvidenceIssue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.evidence.GetEvidenceIssuesResponse} returns this
 */
proto.evidence.GetEvidenceIssuesResponse.prototype.clearEvidenceIssuesList = function() {
  return this.setEvidenceIssuesList([]);
};


/**
 * @enum {number}
 */
proto.evidence.TestStatus = {
  PASS: 0,
  FAIL: 1
};

/**
 * @enum {number}
 */
proto.evidence.TestType = {
  AUTOMATED: 0,
  MANUAL: 1
};

goog.object.extend(exports, proto.evidence);
