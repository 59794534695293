import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { StyledIcon } from './index'

export const DownloadIcon = ({
  height,
  width,
  fontSize,
}: {
  height?: number
  width?: number
  fontSize?: number
}): JSX.Element => (
  <StyledIcon height={height} width={width} fontSize={fontSize}>
    <FontAwesomeIcon icon={faDownload} />
  </StyledIcon>
)
