import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useControl } from '../../../components/async/model'
import { LinkContent } from './LinkedContent'
import { SubjectContainer } from './Subject.styles'

type Props = {
  modelId: string
  linkable: boolean
}

export const ControlContent = (props: Props): JSX.Element => {
  const { data: control } = useControl(props.modelId)
  if (!control) {
    return <></>
  }
  const { name, modelId } = control.toObject()

  if (props.linkable) {
    return (
      <LinkContent
        name={name}
        modelId={modelId}
        modelType={MODEL_TYPE.CONTROL}
      />
    )
  }

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {name}
    </SubjectContainer>
  )
}
