import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  GetChangeLogEntriesRequest,
  LogEntries,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import {
  GrpcInfiniteResponse,
  NewGrpcInfiniteResponse,
} from '../../components/async/hooks/types'
import { useSwrInfiniteGrpc } from '../../components/async/useSwrInfiniteGrpc'

export const useChangeLogEntries = (
  itemsPerPage: number,
  pageNumber: number,
  subjectModelIds?: string[],
  subjectIds?: string[],
): GrpcInfiniteResponse<LogEntries> => {
  const requestBuilder = () => {
    const request = new GetChangeLogEntriesRequest()
      .setEntriesPerPage(itemsPerPage)
      .setPageNumber(pageNumber)
    if (subjectModelIds) {
      request.setSubjectModelIds(new StringList().setItemList(subjectModelIds))
    }
    if (subjectIds) {
      request.setSubjectIds(new StringList().setItemList(subjectIds))
    }
    return request
  }
  const { response } = useSwrInfiniteGrpc(
    AttachmentPromiseClient.prototype.getChangeLogEntries,
    requestBuilder,
    pageNumber,
  )
  return NewGrpcInfiniteResponse(response)
}
