import React from 'react'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { PrimaryButton } from 'src/components/Reusable/Buttons'
import { EvidenceAuditRowItem } from '../evidence.components'

export const ShowEvidenceAuditsModal = ({
  audits,
}: {
  audits: AuditRecord[]
}): JSX.Element => {
  const show = useIsShowModal(ModalFormId.EVIDENCE_AUDITS)
  const hide = useHideModal({ modalId: ModalFormId.EVIDENCE_AUDITS })

  return (
    <ModalForm
      show={show}
      hide={hide}
      hideCancel={true}
      customSubmitButton={
        <PrimaryButton
          onClick={() => {
            hide()
          }}
          text="Close"
        />
      }
      formId={ModalFormId.EVIDENCE_AUDITS}
      title="Linked Audits"
      description="Evidence is automatically included in an audit if it has a Relevant Date that falls within the audit’s date range."
    >
      {audits.length ? (
        <ul>
          {audits.map((audit) => (
            <EvidenceAuditRowItem
              audit={audit}
              key={`evidence-audits-${audit.getId()}`}
            />
          ))}
        </ul>
      ) : (
        <>This evidence is not associated with any open audit.</>
      )}
    </ModalForm>
  )
}
