import React, { ReactElement } from 'react'
import { TrusteroLabsLogo } from 'src/pages/ReportScan/ReportScan.components'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../Reusable/Tooltip/Tooltip'
import { HeaderBanner } from '../Reusable/Banners/HeaderBanner'
import { InfoButton } from './InfoButton'
import {
  AIHeaderContent,
  HeaderText,
  HeaderTitle,
  MainHeaderContent,
  PageHeaderStyle,
  TrusteroLabsIndexRow,
} from './PageLayout.styles'
import { AICopilotLogo } from './PageLayout.components'

type Props = {
  title: string | JSX.Element
  infoTooltipBody?: string
  subHeader?: ReactElement
  customEl?: JSX.Element
  showBottomBorder?: boolean
  isAIPowered?: boolean
  isTrusteroLabs?: boolean
  nowrap?: boolean
}

export const PageHeader = ({
  title,
  infoTooltipBody,
  subHeader,
  customEl,
  showBottomBorder,
  isAIPowered,
  isTrusteroLabs,
  nowrap,
}: Props): ReactElement => {
  const content = (
    <>
      <HeaderText $nowrap={nowrap}>
        <HeaderTitle>{title}</HeaderTitle>
        {infoTooltipBody && (
          <Tooltip
            id="page-header-info-tooltip"
            placement={TooltipPositions.right}
            overlayType={TooltipOverlayType.popover}
            tooltipBody={infoTooltipBody}
          >
            <InfoButton as="div" alignSelf="center" />
          </Tooltip>
        )}
      </HeaderText>
      {isAIPowered ? <AICopilotLogo /> : customEl}
    </>
  )

  return (
    <>
      <PageHeaderStyle
        showBottomBorder={showBottomBorder}
        isAIPowered={isAIPowered}
      >
        {!isAIPowered && <HeaderBanner />}
        {isAIPowered ? (
          <AIHeaderContent>{content}</AIHeaderContent>
        ) : (
          <MainHeaderContent>{content}</MainHeaderContent>
        )}
        {subHeader}
      </PageHeaderStyle>
      {isAIPowered && isTrusteroLabs && (
        <TrusteroLabsIndexRow>
          <TrusteroLabsLogo />
        </TrusteroLabsIndexRow>
      )}
      {isAIPowered && <HeaderBanner mb={10} />}
    </>
  )
}
