import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  border,
  BorderProps,
  color,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import isString from 'lodash/isString'
import ReactMde from 'react-mde'
import { converter, noLinkConverter } from '../../../Utils/ShowDownConverter'

type MarkupSectionProps = LayoutProps &
  GridProps &
  FlexboxProps &
  SpaceProps &
  BorderProps &
  TypographyProps &
  TextStyleProps & {
    color?: string
    bg?: string
    style?: React.CSSProperties
    isLarge?: boolean
    isEvidence?: boolean
  }

export type MarkupProps = {
  rawHtml?: string
  markdown?: string
  sectionProps?: MarkupSectionProps
  isEvidence?: boolean
  isLarge?: boolean
  truncate?: number
}

/**
 * Markup component
 * @param rawHtml - raw html string
 * @param markdown - markdown string
 * @param sectionProps - styled-system props
 * @param isEvidence - boolean to determine if the markup is for evidence. If true, will not render links. The reason we don't render links for evidence is because it causes an error from showdown.js.
 */
export const Markup = ({
  rawHtml,
  markdown,
  sectionProps,
  isEvidence,
  isLarge,
  truncate,
}: MarkupProps): JSX.Element => {
  let html
  if (rawHtml) {
    html = rawHtml
  } else if (isString(markdown)) {
    html = isEvidence
      ? noLinkConverter.makeHtml(markdown)
      : converter.makeHtml(markdown)
  } else {
    html = ''
  }

  if (truncate) {
    html = html
      .split('\n')
      .slice(0, truncate + 1)
      .join('\n')
  }

  return (
    <MarkupSection
      {...sectionProps}
      isLarge={isLarge}
      isEvidence={isEvidence}
      dangerouslySetInnerHTML={{ __html: html }} // nosemgrep
    />
  )
}

export const MarkupSection = styled.section.attrs<MarkupSectionProps>({
  className: 'markup-section',
})<MarkupSectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Poppins;
  font-size: ${({ isLarge }) => (isLarge ? '18px' : '14px')};
  font-weight: initial;
  letter-spacing: -0.03;
  margin: 0;
  margin-bottom: ${(props) => `${themeGet('space.xs')(props)}px`};
  padding: 0;

  ${({ isEvidence }) =>
    isEvidence
      ? compose(layout, grid, flexbox, border, color, typography, textStyle)
      : compose(
          space,
          layout,
          grid,
          flexbox,
          border,
          color,
          typography,
          textStyle,
        )}

  ${(isEvidence) =>
    isEvidence &&
    `padding: 0 12px;
  table {
    position: relative;
  }
  thead {
    background: white;
    white-space: nowrap;
    position: sticky;
    top: 0;
    th {
      padding: 12px;
      tr {
      }
    }
  }

  td {
    vertical-align: top;
    padding: 12px;
    width: 100%;
  }
  `}

  > * {
    word-break: break-word;
  }

  tbody > tr:nth-child(odd) {
    background-color: ${themeGet('colors.bg.neutral')};
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > ul,
  > ol,
  > p,
  > span {
    :only-child {
      margin: 0 !important;
    }
  }

  h1 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.5;
    margin-bottom: ${(props) => `${themeGet('space.xs')(props)}px`};
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.4;
    margin-bottom: ${(props) => `${themeGet('space.xs')(props)}px`};
  }

  table:not(:last-child) {
    margin-bottom: ${(props) => `${themeGet('space.xl')(props)}px`};
  }

  ul,
  ol {
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: -0.03;
    line-height: 22px;
    margin: 0;
    margin-top: ${(props) => `${themeGet('space.xs')(props)}px`};
    margin-bottom: ${(props) => `${themeGet('space.xs')(props)}px`};
    margin-left: ${(props) => `${themeGet('space.xs')(props)}px`};
  }

  a,
  p,
  span {
    margin-left: 8px;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03;
    text-decoration: underline;
  }

  code {
    border: 1px solid;
    border-color: ${themeGet('colors.border.neutral.light')};
    border-radius: 6px;
    background-color: ${themeGet('colors.bg.neutral')};
    color: ${themeGet('colors.text.default')};
    font-family: Courier;
    font-size: 85%;
    letter-spacing: -0.03px;
    line-height: 22px;
    padding: 0.2em 0.4em;
    margin: 0;
  }

  pre > code {
    word-break: normal;
    white-space: pre;
    background: transparent;
    font-size: 100%;
    padding: 0;
    margin: 0;
  }

  pre code {
    display: inline;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    background-color: ${themeGet('colors.bg.neutral')};
    border: 0;
    max-width: auto;
    word-wrap: normal;
  }

  pre {
    word-wrap: normal;
    padding: 16px;
    font-size: 85%;
    line-height: 1.45;
    background-color: ${themeGet('colors.bg.neutral')};
    border-radius: 6px;
    overflow: visible;
  }
`

export const MdEditor = ({
  value,
  onChange,
}: {
  value: string
  onChange: (v: string) => void
}): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')

  return (
    <ReactMde
      value={value}
      onChange={onChange}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={(markdown) =>
        Promise.resolve(converter.makeHtml(markdown))
      }
    />
  )
}
