import Papa from 'papaparse'
import { FileStats, HeaderValues, ParseColumnType } from './csv.constants'
import { transformHeader, transformValue } from './csv.helpers'

const missingHeaders = (
  headers: HeaderValues[],
  seen: Set<string>,
): HeaderValues[] => {
  return headers
    .filter((header) => {
      return header.optional === undefined || !header.optional
    })
    .filter((header) => {
      return !seen.has(header.input)
    })
}

const parseCSVData = async <T>(
  data: string,
  setErrors: (errors: string[]) => void,
  headers: HeaderValues[],
  columnParsers: ParseColumnType,
) => {
  const headerErrors: string[] = []
  const seenHeaders: Set<string> = new Set<string>()
  const bodyErrors: string[] = []
  const fileStats: FileStats = {
    headersLen: 0,
    currCell: 0,
    row() {
      return Math.ceil(this.currCell / this.headersLen) + 1
    },
  }

  const csv = Papa.parse<T>(data, {
    header: true,
    skipEmptyLines: true,
    transformHeader: (header: string) =>
      transformHeader(header, headers, fileStats, seenHeaders, headerErrors),
    transform: (value: string, columnName: string) =>
      transformValue(value, columnName, fileStats, columnParsers, bodyErrors),
  })

  if (headerErrors.length) {
    setErrors(headerErrors)
    return []
  }

  const missing = missingHeaders(headers, seenHeaders)
  if (missing.length > 0) {
    setErrors([
      `The following headers were missing but are required: [${missing
        .map((header) => {
          return header.input
        })
        .join(', ')}]`,
    ])
  }

  if (bodyErrors.length) {
    setErrors(bodyErrors)
    return []
  }

  return csv.data
}

export const parseCSV =
  <T>({
    headers,
    columnParsers,
    setErrors,
    setData,
  }: {
    headers: HeaderValues[]
    columnParsers: ParseColumnType
    setErrors: (errors: string[]) => void
    setData: (data: T[]) => void
  }): ((file: File) => Promise<void>) =>
  async (file: File): Promise<void> => {
    const data = await file.text()
    const res = await parseCSVData<T>(data, setErrors, headers, columnParsers)

    return setData(res)
  }
