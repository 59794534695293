import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import React, { useContext, useMemo, useState } from 'react'

type CurrentReceptor = {
  receptor: ReceptorRecord.AsObject
  setReceptor: React.Dispatch<React.SetStateAction<ReceptorRecord.AsObject>>
}

const CurrentReceptorContext = React.createContext<CurrentReceptor>({
  receptor: new ReceptorRecord().toObject(),
  setReceptor: () => new ReceptorRecord().toObject(),
})

export const useCurrentReceptor = (): CurrentReceptor =>
  useContext(CurrentReceptorContext)

export const CurrentReceptorContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [receptor, setReceptor] = useState<ReceptorRecord.AsObject>(
    new ReceptorRecord().toObject(),
  )

  const receptorData: CurrentReceptor = useMemo(
    () => ({
      receptor,
      setReceptor,
    }),
    [receptor, setReceptor],
  )
  return (
    <CurrentReceptorContext.Provider value={receptorData}>
      {children}
    </CurrentReceptorContext.Provider>
  )
}
