import React from 'react'
import { useIsShowModal, useHideModal } from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { ModalButtonContainer } from 'src/components/ModalForms/ModalForm.styles'
import { LargeOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { ModalDescription } from '../risks.styles'
import { ReactComponent as AddIcon } from '../../../components/Icons/assets/add-icon.svg'
import { ReactComponent as BringIntoIcon } from '../../../components/Icons/assets/bring-into.svg'

export const AddRiskModal = (): JSX.Element => {
  const show = useIsShowModal(ModalFormId.ADD_RISK)
  const onHide = useHideModal({
    modalId: ModalFormId.ADD_RISK,
  })

  return (
    <ModalForm
      show={show}
      hide={onHide}
      formId={ModalFormId.ADD_RISK}
      size="xl"
      title="Add Risk Scenarios"
      submitText="Add Risk"
    >
      <form id={ModalFormId.ADD_RISK}>
        <ModalDescription>
          Risks represent bad things that could happen to your business
        </ModalDescription>
        <ModalButtonContainer>
          <LargeOpenModalButton modalId={ModalFormId.ADD_RISK_CSV}>
            <BringIntoIcon />
            <p>Upload a spreadsheet</p>
          </LargeOpenModalButton>
          <LargeOpenModalButton modalId={ModalFormId.ADD_MANUAL_RISK}>
            <AddIcon />
            <p>Add Manually </p>
          </LargeOpenModalButton>
        </ModalButtonContainer>
      </form>
    </ModalForm>
  )
}
