import { Assignee } from 'src/Utils/globalEnums'
import {
  ListDocumentRequestsRequest,
  OwnerFilter,
  RequestSort,
  StatusFilter,
} from '@trustero/trustero-api-web/lib/request/request_pb'
import queryString, { ParsedQuery } from 'query-string'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  GridColumnSortRequests,
  GridColumnSortType,
  SORT_ORDER,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  DOCUMENT_REQUEST_LABEL_TO_STATUS,
  DocumentRequestStatusLabels,
  REQUEST_SORT_COLUMNS,
} from './requests.constants'

export const applyRequestFilters = (
  req: ListDocumentRequestsRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.STATUS: {
      const statuses = (filterParams as DocumentRequestStatusLabels[])?.map(
        (label) => DOCUMENT_REQUEST_LABEL_TO_STATUS[label],
      )
      const statusFilter = new StatusFilter().setStatusesList(statuses)
      req.setStatusFilter(statusFilter)
      break
    }
    case FilterParam.OWNER: {
      const ownerIds = new Set(filterParams)
      const ownerFilter = new OwnerFilter()
      if (ownerIds.has(Assignee.UNASSIGNED_BE)) {
        ownerIds.delete(Assignee.UNASSIGNED_BE)
        ownerFilter.setIncludeUnassigned(new BoolValue().setValue(true))
      }
      ownerFilter.setOwnerIdsList(Array.from(ownerIds))
      req.setOwnerFilter(ownerFilter)
      break
    }
    default:
      break
  }
}

export const applyRequestSort = (
  req: ListDocumentRequestsRequest,
  queryParams: ParsedQuery<string>,
): void => {
  const sortOrder =
    (queryParams.sort_by && (queryParams.sort_by[0] as GridColumnSortType)) ||
    undefined
  const sortCol =
    (queryParams.sort_col &&
      (queryParams.sort_col[0] as GridColumnSortRequests)) ||
    undefined
  const shouldApplySort = sortOrder && sortCol
  if (!shouldApplySort) {
    return
  }
  const sort = new RequestSort()
  sort.setSortOrder(SORT_ORDER[sortOrder])
  sort.setSortColumn(REQUEST_SORT_COLUMNS[sortCol])
  req.setRequestSort(sort)
}

export const getDRLFilterRequest = (
  locSearch: string,
): ListDocumentRequestsRequest => {
  const req = new ListDocumentRequestsRequest()
  const queryParams: ParsedQuery<string> = queryString.parse(locSearch, {
    arrayFormat: 'bracket',
  })
  // Apply all filters for each parameter in URL
  Object.values(FilterParam).forEach((filterType: FilterParam) =>
    applyRequestFilters(req, queryParams, filterType),
  )
  applyRequestSort(req, queryParams)
  return req
}
