import styled from 'styled-components/macro'

export enum FlexAlign {
  CENTER = 'center',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
}

interface FlexProps {
  gap?: number
  align?: FlexAlign
  justify?: FlexAlign
  isRelative?: boolean
  isFullWidth?: boolean
  customWidth?: string
  isFullHeight?: boolean
  customHeight?: string
  children: React.ReactNode | React.ReactNode[]
  $backgroundColor?: string
  $maxWidth?: number
  $m?: number
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
  $padding?: number
}

export const FlexRow = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => (align ? align : FlexAlign.CENTER)};
  justify-content: ${({ justify }) => (justify ? justify : FlexAlign.CENTER)};
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
  width: ${({ isFullWidth }) => (isFullWidth ? `100%` : 'auto')};
  width: ${({ customWidth }) => customWidth && customWidth};
  height: ${({ isFullHeight }) => (isFullHeight ? `100%` : 'auto')};
  height: ${({ customHeight }) => customHeight && customHeight};
  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor}`};
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px`};
  ${({ $m }) => $m && `margin: ${$m}px`};
  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
  ${({ $mr }) => $mr && `margin-right: ${$mr}px`};
  ${({ $padding }) => $padding && `padding: ${$padding}px`}
`

export const FlexColumn = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align ? align : FlexAlign.CENTER)};
  justify-content: ${({ justify }) => (justify ? justify : FlexAlign.CENTER)};
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
  width: ${({ isFullWidth }) => (isFullWidth ? `100%` : 'auto')};
  width: ${({ customWidth }) => customWidth && customWidth};
  height: ${({ isFullHeight }) => (isFullHeight ? `100%` : 'auto')};
  height: ${({ customHeight }) => customHeight && customHeight};
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px`};
  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor}`};
  ${({ $m }) => $m && `margin: ${$m}px`};
  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
  ${({ $mr }) => $mr && `margin-right: ${$mr}px`};
  ${({ $padding }) => $padding && `padding: ${$padding}px`}
`

export const FullWidthDiv = styled.div`
  width: 100%;
`

export const CenteredDiv = styled.div`
  margin: auto;
`
