import React, { ReactElement } from 'react'
import { AsyncComponentResponse } from './AsyncComponentResponse'
import { Props } from './AsyncComponent'
import { useSwrImmutableGrpc } from './useSwrImmutableGrpc'

/**
 * @deprecated use useSwrImmutableGrpc instead
 * @param props
 * @constructor
 */
export function AsyncImmutableComponent<ElementProps, Request, Response>(
  props: Props<ElementProps, Request, Response>,
): ReactElement {
  const { response, cacheKey } = useSwrImmutableGrpc(
    props.asyncCall,
    props.request,
  )
  return (
    <AsyncComponentResponse
      props={props.props}
      placeholder={props.placeholder ?? <></>}
      child={props.child}
      onValidationStarted={props.onValidationStarted}
      onValidationCompleted={props.onValidationCompleted}
      onError={props.onError}
      data={response.data}
      error={response.error}
      mutate={response.mutate}
      isLoading={response.isLoading}
      isValidating={response.isValidating}
      cacheKey={cacheKey}
    />
  )
}
