import styled from 'styled-components/macro'
import themeGet from '@styled-system/theme-get'
import { compose, grid, space, color } from 'styled-system'
import { TrusteroLogoFullSVG } from 'src/components/Icons/Brand'
import palette from 'src/designSystem/variables/palette'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { StartFlow } from './EmailLogin/EmailLogin.styles'

// The `isInApp` flag is needed when we access the /login route from within the AppProvider
// This is needed to avoid some whitespace when seeing the OIDC providers
export const LoginPageContainer = styled.div<{ isInApp?: boolean }>`
  background: rgb(29, 37, 46);
  background: linear-gradient(
    146deg,
    rgba(29, 37, 46, 1) 0%,
    rgba(29, 37, 46, 1) 48%,
    rgba(162, 165, 168, 1) 100%
  );
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.7rem;
  justify-content: center;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: ${({ isInApp }) => (isInApp ? '0px' : '-108px')};
  padding-bottom: 100px;
  overflow: auto;
`

export const LoginPageBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${themeGet('colors.text.default')};

  .top-right-bubble {
    position: absolute;
    top: 0;
    right: 0;
  }
  .bottom-right-bubble {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .bottom-left-bubble {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const LoginPageGrid = styled.div.attrs({
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridColumnGap: '24px',
  px: 'm',
})`
  ${compose(grid, space)}
  display: grid;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  height: 100vh;
`

export const TrusteroLine = styled.div`
  height: 0.3rem;
  margin: 1.7rem 0;
  background: #00c9a7;
  background: linear-gradient(91deg, #00c9a7 0%, #f567a8 100%);
`

export const LineContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.neutral.white};
  font-size: 1rem;
  font-weight: 400;
  margin: 1.7rem 0;
  ::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${palette.neutral.white};
    margin-right: 1rem;
    margin-left: 2.86rem;
  }
  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${palette.neutral.white};
    margin-left: 1rem;
    margin-right: 2.86rem;
  }
`

export const LoginPageContent = styled.div.attrs({
  gridColumn: '5 / span 4',
})`
  ${grid}
  align-self: center;
  text-align: center;
  min-width: 500px;
`

export const TrusteroLogo = styled(TrusteroLogoFullSVG)`
  * {
    fill: white;
  }
`

export const StyledStartFlow = styled(StartFlow)`
  width: 100%;
`

export const StyledLoginButton = styled(StandardButton).attrs({
  variant: StandardButtonVariant.PRIMARY,
  buttonSize: StandardButtonSize.LARGE,
})`
  width: 100%;
  position: relative;
  background-color: white;

  &&,
  &&:visited {
    color: ${themeGet('colors.text.default')};
  }

  &:hover,
  &:active {
    color: white;
  }
`

export const LoginErrors = styled.p.attrs<{ show: boolean }>((props) => {
  return {
    mt: 'l',
    p: 'l',
    bg: props.show ? 'bg.status.error' : 'transparent',
    color: 'fill.status.error.base',
  }
})<{ show: boolean }>`
  ${compose(space, color)}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 6px;
`

export const StyledRedirect = styled.h6`
  margin-bottom: 2em;
  color: ${palette.neutral.white};
`
