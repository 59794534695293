import React from 'react'
import { LargeTitle, SmallTitle } from './NoResults.styles'

type NoResultsTitleProps = {
  large: boolean
  children: string
}

export const NoResultsTitle = ({
  large,
  children,
}: NoResultsTitleProps): JSX.Element => {
  return (
    <>
      {large && <LargeTitle>{children}</LargeTitle>}
      {!large && <SmallTitle>{children}</SmallTitle>}
    </>
  )
}
