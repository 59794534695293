import { RiskFields } from 'src/pages/Risks'

export enum TestIds {
  LOCATION_DISPLAY = 'location-display',
  EDITABLE_TEXT_AREA_COLUMN = 'editable-text-area-column',
  STYLED_TEXT_AREA = 'styled-text-area',
  REQUEST_NAME = 'request-name',
  REQUEST_COLUMN = 'request-column',
  CONTROL_STATUS_WIDGET = 'control-status-widget',
  SPINNER = 'spinner',
  CONTROL_LIST_ITEM_MENU_TOGGLE = 'control-list-item-menu-toggle',
  BUTTON_IN_TEST = 'button-in-test',
  CONTENT_LOADER = 'content-loader',
  CLOSE_MODAL = 'close-modal',
  SVG_ICON = 'svg-icon',
  DATE_RANGE_EDIT_BUTTON = 'date-range-edit-button',
  FILE_INPUT = 'file-input',
  POLICIES_DROPDOWN = 'policies-dropdown',
}

export enum ComponentIds {
  OWNER_DROPDOWN = 'owner-dropdown',
}

/**
 * Cypress E2E Test IDs
 *
 * These IDs should be used across our elements which are being referenced by Cypress.
 * This has 3 benefits:
 *  - Less brittle tests when COPY/text changes or dynamic classes/tags
 *  - All Cypress Test Elements/Id's are centralized to this file
 *  - Makes clear which elements are used in test files
 *
 * We will keep this as a const instead of enum to allow dynamic string creation
 *
 * IMPORTANT: Always use the `[data-testid]` HTML attribute when using these values
 * https://docs.cypress.io/guides/references/best-practices#Selecting-Elements
 *
 * Unforunately we cannot use this file within the cypress directory - please ensure that you carefully use all the same string values!
 */
export const E2E_IDS = Object.freeze({
  // Navbar
  NAV_SECTION: (type: string) =>
    `nav-section-${type?.toLocaleLowerCase().replace(' ', '-')}`,
  NAV_ITEM: (type: string) =>
    `nav-item-${type?.toLowerCase().replace(' ', '-')}`,
  // Controls
  ADD_CONTROL: 'add-control',
  CREATE_CUSTOM_CONTROL: 'create-custom-control',
  // Policies
  ADD_POLICY: 'add-policy',
  CREATE_CUSTOM_POLICY: 'create-custom-policy',
  // Users
  DELETE_USER: 'delete-user-btn', // '-${user.email}' is appended in UsersGridItem
  // Frameworks
  FRAMEWORKS_CARD: (complianceFrameworkModelId: string) =>
    `compliance-framework-card-${complianceFrameworkModelId?.toLowerCase()}`,
  ADD_FRAMEWORK: 'add-framework',
  // Risks
  ADD_RISK: 'add-risk',
  RISK_MATH_FIELD_MENU: (field: RiskFields) => {
    return `risk-math-field-menu-${field?.toLowerCase()}`
  },
  RISK_ROW_MENU: (riskId: string) => {
    return `risk-row-menu-${riskId}`
  },
  RISK_CONTROL_ROW_MENU: (riskId: string) => {
    return `risk-control-row-menu-${riskId}`
  },
  RISK_VENDOR_ROW_MENU: (riskId: string) => {
    return `risk-vendor-row-menu-${riskId}`
  },
  RISK_CATEGORY_MENU: 'risk-category-menu',
  RISK_LINK_ADD_CONTROL: 'risk-link-control',
})
