import React from 'react'
import { Receptor } from 'src/xgenerated'
import { getReceptorTemplates } from 'src/pages/Receptors/receptors.helpers'
import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

export const ReceptorFilterDropdown = (): JSX.Element => {
  const receptors = getReceptorTemplates()
  const noneValue = {
    value: NONE_ID,
    label: NONE_NAME,
  }
  const filterValues = [
    noneValue,
    ...receptors.map((receptor: Receptor) => ({
      value: receptor.modelid,
      label: receptor.name,
    })),
  ]

  return (
    <FilterDropdown
      filterParam={FilterParam.RECEPTOR}
      filterName={FilterName.RECEPTOR}
      filterValues={filterValues}
    />
  )
}
