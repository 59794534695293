import { Department } from '@trustero/trustero-api-web/lib/model/department_pb'
import { useMemo } from 'react'
import { useDepartments } from './useDepartments'

export function useDepartment(modelId: string): Department | undefined {
  const departments = useDepartments()

  const memoMap = useMemo(() => {
    return (
      departments?.reduce(function (
        map: { [key: string]: Department },
        obj: Department,
      ) {
        map[obj.getModelId()] = obj
        map[obj.getId()] = obj
        return map
      },
      {}) ?? {}
    )
  }, [departments])
  return memoMap[modelId]
}
