import React, { useEffect, useState } from 'react'
import log from 'loglevel'
import { EditTextOnClick } from 'src/components/Reusable/Inputs/EditTextOnClick'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { FieldConstants } from 'src/Utils/globalConstants'
import { FlexRow } from 'src/components/Reusable/Flex'
import { InfoTooltip } from 'src/components/Reusable/Tooltip/InfoTooltip'
import { useCreateOrUpdateRisks } from '../risks.hooks'
import {
  FirstLabel,
  RiskMathField,
  RiskOverviewContainer,
} from '../risks.styles'
import { RiskFields } from '../risks.constants'
import { RisksMathMenu } from '../menus'
import { updatedRiskRpcData } from '../risks.helpers'
import { CalculatedRiskMathChip } from '../risks.components'

export const RiskOverview = ({ risk }: { risk: Risk }): JSX.Element => {
  const createOrUpdateRisk = useCreateOrUpdateRisks()
  const [preCondition, setPreCondition] = useState<string>(
    risk.getPredisposingCondition(),
  )
  const [assetAtRisk, setAssetAtRisk] = useState<string>(risk.getAssetAtRisk())
  const [impact, setImpact] = useState<string>(risk.getImpactToCustomer())

  useEffect(() => {
    setPreCondition(risk.getPredisposingCondition())
    setAssetAtRisk(risk.getAssetAtRisk())
    setImpact(risk.getImpactToCustomer())
  }, [risk])

  const riskId = risk.getId()
  const adverseImpact = risk.getAdverseImpact()
  const likelihood = risk.getLikelihood()

  const onSave = async (newValue: string, field: RiskFields): Promise<void> => {
    try {
      const updatedRisk = updatedRiskRpcData(risk, field, newValue)
      await createOrUpdateRisk([updatedRisk])
      switch (field) {
        case RiskFields.predisposingCondition:
          setPreCondition(newValue)
          break
        case RiskFields.assetAtRisk:
          setAssetAtRisk(newValue)
          break
        case RiskFields.impactToCustomer:
          setImpact(newValue)
          break
        default:
          break
      }
    } catch (err) {
      log.error(`Failed to update Risk ${field}: `, err)
    }
  }

  return (
    <RiskOverviewContainer>
      {/* Predisposing Condition */}
      <FlexRow>
        <FirstLabel>Predisposing Condition / Vulnerability</FirstLabel>
        <InfoTooltip
          id="predisposing_condition"
          body="Give context to the risk such as known design flaws or a condition that makes potential impact bigger."
        />
      </FlexRow>
      <EditTextOnClick
        isInline
        initialValue={preCondition}
        onSave={(newValue: string) =>
          onSave(newValue, RiskFields.predisposingCondition)
        }
        onCancel={() => setPreCondition(risk.getPredisposingCondition())}
        isEmpty={!preCondition}
      >
        <span>{preCondition || FieldConstants.NONE}</span>
      </EditTextOnClick>

      {/* Asset at Risk */}
      <FlexRow>
        <label>Asset at Risk</label>
      </FlexRow>
      <EditTextOnClick
        isInline
        initialValue={assetAtRisk}
        onSave={(newValue: string) => onSave(newValue, RiskFields.assetAtRisk)}
        onCancel={() => setAssetAtRisk(risk.getAssetAtRisk())}
        isEmpty={!assetAtRisk}
      >
        <span>{assetAtRisk || FieldConstants.NONE}</span>
      </EditTextOnClick>

      {/* Impact to Customer */}
      <FlexRow>
        <label>Impact to Customer</label>
        <InfoTooltip
          id="impact"
          body="What does this scenario mean for a customer, if anything? Try to be concrete."
        />
      </FlexRow>
      <EditTextOnClick
        isInline
        initialValue={impact}
        onSave={(newValue: string) =>
          onSave(newValue, RiskFields.impactToCustomer)
        }
        onCancel={() => setImpact(risk.getImpactToCustomer())}
        isEmpty={!impact}
      >
        <span>{impact || FieldConstants.NONE}</span>
      </EditTextOnClick>

      {/* Inherent Risk */}
      <FlexRow>
        <label>Inherent Risk Calculation</label>
        <InfoTooltip
          id="risk_calculation"
          body="Use this section to partially quantify the probability and impact of your risks."
        />
      </FlexRow>
      <RiskMathField>
        <span>Likelihood of Occurrence</span>
        <RisksMathMenu riskId={riskId} field={RiskFields.likelihood} />
      </RiskMathField>
      <RiskMathField>
        <span>Adverse Impact</span>
        <RisksMathMenu riskId={riskId} field={RiskFields.adverseImpact} />
      </RiskMathField>
      <RiskMathField>
        <span>
          <FlexRow>
            Inherent Risk
            <InfoTooltip
              id="inherent_risk"
              body="This is calculated based on likelihood and impact, using NIST guidelines."
            />
          </FlexRow>
        </span>
        <CalculatedRiskMathChip
          riskId={riskId}
          impact={adverseImpact}
          likelihood={likelihood}
          field={RiskFields.inherentRisk}
        />
      </RiskMathField>
    </RiskOverviewContainer>
  )
}
