import React, { PropsWithChildren } from 'react'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from './StandardButton'

type MediumCancelButtonProps = {
  onClick: () => void
  isDisabled?: boolean
}

export const MediumCancelButton = ({
  onClick,
  isDisabled,
  children,
}: PropsWithChildren<MediumCancelButtonProps>): JSX.Element => {
  return (
    <StandardButton
      variant={StandardButtonVariant.SECONDARY}
      buttonSize={StandardButtonSize.MEDIUM}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      disabled={isDisabled}
    >
      {children}
    </StandardButton>
  )
}
