import React from 'react'
import log from 'loglevel'
import { Link, useParams } from 'react-router-dom'
import { EvidenceAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { getEvidenceGroupId } from 'src/pages/Evidence/evidence.helpers'
import { useDocument } from 'src/components/async/document/useDocument'
import { SubjectContainer } from './Subject.styles'

export const EvidenceContent = ({
  documentId,
  caption,
}: {
  documentId: string
  caption?: string
}): JSX.Element => {
  const { pageContext } = useParams()
  const { data, isLoading, error } = useDocument({
    documentId,
    shouldFetch: !!documentId,
  })
  if (!documentId) {
    return caption ? (
      <SubjectContainer>
        <span>{`\u2022`}</span>
        {caption}
      </SubjectContainer>
    ) : (
      <></>
    )
  }

  if (!caption || isLoading) {
    return <></>
  } else if (!data || error) {
    log.error(`Error loading log entry for document id ${documentId}`, error)
    return <></>
  }
  const captionString = data.getCaption()
  const contentId = data.getContentid()
  const discoveryId = data.getDiscoveryid()?.getValue() || ''
  const evidenceId = getEvidenceGroupId(captionString, contentId, discoveryId)
  const url = `/${pageContext}/${EvidenceAbsoluteRoutes.SHOW}/${evidenceId}`

  const docExists = !!data.getId()

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {docExists ? <Link to={url}>{caption}</Link> : <>{caption}</>}
    </SubjectContainer>
  )
}
