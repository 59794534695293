import React from 'react'
import styled from 'styled-components/macro'
import {
  compose,
  DisplayProps,
  flexbox,
  FlexboxProps,
  layout,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { InfoSVG } from '../Icons/Basic'

type Attrs = { $size?: number }
type NewAttrs = { $pixelSize: string }
type Props = DisplayProps &
  PositionProps &
  FlexboxProps &
  SpaceProps & {
    $pixelSize: string
  }

export const InfoButton = styled.button.attrs<Attrs, NewAttrs>((props) => {
  const pixelSize = props.$size ? `${props.$size}px` : '16px'
  return {
    $pixelSize: pixelSize,
    children: <InfoSVG width={pixelSize} height={pixelSize} />,
  }
})<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  ${compose(layout, position, flexbox, space)}
  border: none;
  border-radius: 50%;
  width: ${(props) => props.$pixelSize};
  height: ${(props) => props.$pixelSize};

  background-color: transparent;
  svg {
    fill: ${themeGet('colors.fill.neutral.base')};
  }
  &:hover {
    background-color: ${themeGet('colors.fill.neutral.base')};
    svg {
      fill: white;
    }
  }
  &:active {
    background-color: black;
    svg {
      fill: white;
    }
  }
`
