import { ControlSort } from '@trustero/trustero-api-web/lib/model/control_pb'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { CONTROL_CHECK_SORT_COLUMN } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { GridColumnSortControls } from '../../../../components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { ControlListItemConfig } from '../../IndexItem/ControlListItem.constants'

export interface ControlGridRowsProps {
  modelIds: string[]
  audit?: AuditRecord
  config?: ControlListItemConfig
}

export const CONTROL_SORT_COLUMNS = Object.freeze({
  [GridColumnSortControls.NAME]: ControlSort.control_sort_column.NAME,
  [GridColumnSortControls.ID]: ControlSort.control_sort_column.ID,
  [GridColumnSortControls.DEPARTMENT]:
    ControlSort.control_sort_column.DEPARTMENT,
  [GridColumnSortControls.POLICY_MATCH]:
    CONTROL_CHECK_SORT_COLUMN.CONTROL_MATCHES_POLICY,
  [GridColumnSortControls.COMPLETENESS]: CONTROL_CHECK_SORT_COLUMN.COMPLETENESS,
  [GridColumnSortControls.SPOT_CHECK]: CONTROL_CHECK_SORT_COLUMN.SPOT_CHECK,
  [GridColumnSortControls.AUTOMATED_EVIDENCE]:
    ControlSort.control_sort_column.AUTOMATED_EVIDENCE,
  [GridColumnSortControls.MANUAL_EVIDENCE]:
    ControlSort.control_sort_column.MANUAL_EVIDENCE,
  [GridColumnSortControls.ASSIGNEE]: ControlSort.control_sort_column.ASSIGNEE,
})
