import React, { useReducer } from 'react'
import isFunction from 'lodash/isFunction'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  StandardButton,
  StandardButtonVariant,
  StandardButtonSize,
} from '../Buttons'
import { ButtonGroup, EditableOnClickContainer } from './Inputs.styles'
import { EditOnClickProps } from './inputs.constants'

export const EditOnClick = ({
  isInline,
  editMode,
  onSave,
  onSaveControlClose,
  onCancel,
  isEmpty,
  children,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
}: EditOnClickProps): JSX.Element => {
  const [isEditing, toggleEditMode] = useReducer((prev) => !prev, false)
  const hasPermissions = useHasRequiredPermissions(requiredPermissions)

  const onSaveFunc: React.MouseEventHandler = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (isFunction(onSave)) {
      onSave()
      toggleEditMode()
    } else if (isFunction(onSaveControlClose)) {
      const close = await onSaveControlClose()
      close && toggleEditMode()
    }
  }

  const onCancelFunc = () => {
    isFunction(onCancel) && onCancel()
    toggleEditMode()
  }

  return (
    <EditableOnClickContainer
      inline={isInline}
      isEditing={isEditing}
      isEmpty={isEmpty}
      onClick={() => !isEditing && toggleEditMode()}
    >
      {hasPermissions && isEditing ? (
        <>
          {editMode}
          <ButtonGroup inline={isInline}>
            <StandardButton
              variant={StandardButtonVariant.PRIMARY}
              buttonSize={StandardButtonSize.SMALL}
              onClick={onSaveFunc}
            >
              Save
            </StandardButton>
            <StandardButton
              variant={StandardButtonVariant.PRIMARY}
              buttonSize={StandardButtonSize.SMALL}
              onClick={onCancelFunc}
            >
              Cancel
            </StandardButton>
          </ButtonGroup>
        </>
      ) : (
        <>{children}</>
      )}
    </EditableOnClickContainer>
  )
}
