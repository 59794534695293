import React from 'react'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { AutomateDocumentIcon } from 'src/components/Icons/FileType'
import { ModalFormId } from '../../../../components/ModalForms'
import { AddConnectionModal } from '../../../../components/ModalForms/Evidence/AddEvidence/AddConnection'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from '../../../../components/Reusable/Buttons'

type Props = {
  controlId: string
  buttonVariant: StandardButtonVariant
  modalFormId: ModalFormId
  isNotApplicable: boolean
}

export const AddConnectionModalToggle = ({
  controlId,
  buttonVariant,
  modalFormId,
  isNotApplicable,
}: Props): JSX.Element => {
  return (
    <>
      {!isNotApplicable && (
        <AddConnectionModal modalFormId={modalFormId} controlId={controlId} />
      )}
      <StandardOpenModalButton
        modalId={modalFormId}
        variant={buttonVariant}
        size={StandardButtonSize.SMALL}
        text=""
      >
        <AutomateDocumentIcon width="15px" height="15px" />
        Connect Automated Evidence
      </StandardOpenModalButton>
    </>
  )
}
