import React from 'react'
// @ts-ignore
import { LogEntry as LogEntryPb } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import AttachmentService from '../services/attachment.service'
import { AuthAction, AuthRecord } from '../context/authContext'
import { interceptTokenErrors } from './gRpcAdapter'

/*
  message LogEntry {
    string          ID               = 1;  // System generated object id.
    int64           CreatedAt        = 2;  // Time the log entry is created.
    string          AccountID        = 3;  // Trustero account that owns the record.
    MODEL_TYPE      SubjectModelType = 4;  // The model type of model this log entry is attached to.
    string          SubjectModelID   = 5;  // The logical model id of model this log entry is attached to.
    ACTOR_TYPE      ActorType        = 6;  // Actor type that performed the action that resulted in this log entry.
    string          Actor            = 7;  // Actor id that performed the action that resulted in this log entry.  Email for human users and id.XID for receptors.
    string          Message          = 8;  // Log message usually stating the action performed.
    ATTACHMENT_TYPE AttachmentType   = 9;  // Attachment type - either Document or AuditPeriod
    string          AttachmentID     = 10; // Object ID of attachment associated with the subject.
  }
*/
export type ActivityObject = {
  name: string
  type: string
}

type ActivityEvent = {
  actor: {
    displayName: string
    email?: string
  }
  message: string
  object?: ActivityObject
}

export type LogEntry = LogEntryPb.AsObject & {
  activity?: ActivityEvent
  department?: string
}

class ChangelogAdapter {
  static getChangeLog(
    beginTime: number,
    authCtx: AuthRecord | null,
    authDispatch: React.Dispatch<AuthAction> | null,
  ): Promise<LogEntry[]> {
    return interceptTokenErrors(authCtx, authDispatch, (header: unknown) =>
      this.getApiCall(beginTime, header),
    )
  }

  private static async getApiCall(
    beginTime: number,
    header: unknown,
  ): Promise<LogEntry[]> {
    const endTime = 0 // "now"
    const logEntriesMsg = await AttachmentService.getChangeSince(
      beginTime,
      endTime,
      header,
    )
    return logEntriesMsg.getLogentriesList().map((logEntryMsg: LogEntryPb) => {
      return logEntryMsg.toObject() as LogEntry
    })
  }
}

export default ChangelogAdapter
