import React from 'react'
import { ShowPageContainer } from 'src/components/PageLayout/ShowPage'
import { Navigate } from 'react-router-dom'
import { ShowPageLoader } from 'src/components/Reusable/ContentLoaders/ShowPageLoader'
import { useRisk } from '../risks.hooks'
import { RisksShowPageContent } from './RisksShowPageContent'
import { RisksShowPageHeader } from './RisksShowPageHeader'

export const RisksShowPage = ({ riskId }: { riskId: string }): JSX.Element => {
  const risk = useRisk(riskId)

  if (risk === false) {
    return <Navigate to="../../" />
  }

  return risk === null ? (
    <ShowPageLoader />
  ) : (
    <ShowPageContainer>
      <RisksShowPageHeader risk={risk} />
      <RisksShowPageContent risk={risk} />
    </ShowPageContainer>
  )
}
