import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const SectionTitle = styled.div<{
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
  $color?: string
  $size?: number
}>`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${palette.blue[700]};

  ${({ $color }) => $color && `color: ${$color}`};
  ${({ $size }) => $size && `font-size: ${$size}px`};
  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
  ${({ $mr }) => $mr && `margin-right: ${$mr}px`};
`

export const P = styled.p<{
  $fontSize?: number
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
  $isDim?: boolean
  $isUnderline?: boolean
  $isItalicized?: boolean
  $isBold?: boolean
  $pointer?: boolean
  $color?: string
  $fontweight?: number
  $width?: number
  $isCentered?: boolean
  $useCursor?: boolean
}>`
  margin: 0;
  font-weight: 400;
  line-height: 150%;
  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
  ${({ $mr }) => $mr && `margin-right: ${$mr}px`};
  ${({ $fontSize }) => `font-size: ${$fontSize ? $fontSize : 12}px`};
  ${({ $isDim }) =>
    `color: ${$isDim ? palette.neutral[600] : palette.neutral[900]}`};
  ${({ $isUnderline }) => $isUnderline && `text-decoration: underline`};
  ${({ $isItalicized }) => $isItalicized && `font-style: italic`};
  ${({ $isBold }) => $isBold && `font-weight: 500`};
  ${({ $pointer }) => $pointer && `cursor: pointer`};
  ${({ $color }) => $color && `color: ${$color}`};
  ${({ $fontweight }) => $fontweight && `font-weight: ${$fontweight}`};
  ${({ $width }) => $width && `width: ${$width}%`};
  ${({ $isCentered }) => $isCentered && `text-align: center`}
  ${({ $useCursor }) => $useCursor && `cursor: pointer`}
`
