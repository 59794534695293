import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useGetQuestionnaire } from 'src/pages/SecurityQuestionnaire/securityQuestionnaire.hooks'
import { LinkContent } from './LinkedContent'
import { SubjectContainer } from './Subject.styles'

export const QuestionnaireContent = ({
  questionnaireId,
}: {
  questionnaireId: string
}): JSX.Element => {
  const { data, error, isLoading } = useGetQuestionnaire({
    id: questionnaireId,
    includeDeleted: true,
  })

  if (!data || error || isLoading) {
    return <></>
  }
  const name = data.getName()
  const id = data.getId()
  const linkable = !data.getIsDeleted()

  return linkable ? (
    <LinkContent
      name={name}
      modelId={id}
      modelType={MODEL_TYPE.QUESTIONNAIRE}
    />
  ) : (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {name}
    </SubjectContainer>
  )
}
