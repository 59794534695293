import React from 'react'
import isFunction from 'lodash/isFunction'
import { useConfirmationModal } from '../../../components/ModalForms'
import { useRemoveControlFromAudit } from '../../../components/async/model/audit'
import { RemoveControlFromFromAudit } from './RemoveControlFromAudit'

const title = 'Remove control from this audit?'
const confirmText = 'Remove Control'

/**
 * useRemoveFromAuditControl
 * @param id row id, not model id
 */
export const useRemoveControlFromAuditModal = (
  controlId: string,
  afterConfirmFunc?: () => void,
): (() => void) => {
  const removeControlFromAudit = useRemoveControlFromAudit(controlId)
  const onConfirmCB = async () => {
    await removeControlFromAudit()
    isFunction(afterConfirmFunc) && afterConfirmFunc()
  }

  return useConfirmationModal({
    title,
    body: <RemoveControlFromFromAudit controlModelId={controlId} />,
    confirmText,
    onConfirmCB,
    throbber: {
      color: 'primary',
      size: 'xl',
    },
  })
}
