import { useGetURLParams } from './useGetURLParams'

export function useGetURLParam<T>(
  name: string,
  validOptions: Set<T>,
  defaultValue: T,
): T {
  const params = useGetURLParams()
  if (!params[name]) {
    return defaultValue
  }
  const param = Array.isArray(params[name])
    ? (params[name] as Array<unknown>)[0]
    : params[name]
  if (validOptions.has(param as T)) {
    return param as T
  }
  return defaultValue
}
