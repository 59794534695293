import React from 'react'
import styled from 'styled-components/macro'
import palette from '../designSystem/variables/palette'
import { Spinner } from './Spinner'
type Props = {
  show: boolean
}

export const ReceptorSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 24px;

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 150%;

    text-align: center;
    color: ${palette.neutral['900']};
  }
`

export function ReceptorScanSpinner(props: Props): JSX.Element {
  if (!props.show) {
    return <></>
  }
  return (
    <ReceptorSpinnerContainer>
      <Spinner
        title="Test"
        color="primary"
        size="m"
        marginRight={'24px'}
      ></Spinner>
      <p>Checking receptors for evidence</p>
    </ReceptorSpinnerContainer>
  )
}
