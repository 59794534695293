import { useCallback, useEffect, useState } from 'react'
import {
  AnalyticsBrowser,
  Callback,
  EventProperties,
  Options,
  UserTraits,
} from '@segment/analytics-next'
import log from 'loglevel'
import { useLocation } from 'react-router-dom'
import { isProduction } from '../Utils/environment'
import { getPageCategoryAndName } from './analytics.helpers'
import {
  APP_ANALYTICS_EVENTS,
  segmentDevKey,
  segmentProdKey,
  UseAnalytics,
} from './analytics.constants'

// We will use this global map to ensure certain track events are only fired once
// per session across all instances & remounts of the custom hook
const onlyOnceMap: Map<string, boolean | string> = new Map()
const analytics = new AnalyticsBrowser()
const writeKey = isProduction() ? segmentProdKey : segmentDevKey
let isLoaded = false

// TODO: Add handling to toggle ON/OFF for local development
export const useAnalytics = (): UseAnalytics => {
  const [isSegmentLoaded, setIsSegmentLoaded] = useState<boolean>(false)
  const location = useLocation()

  useEffect(() => {
    // Load segment API - this will load twice in development mode
    // This is because react.StrictMode
    if (isLoaded) {
      return
    }
    analytics
      .load({ writeKey })
      .ready(() => {
        isLoaded = true
        setIsSegmentLoaded(true)
      })
      .catch((err) => {
        log.error('Segment API failed to load in App: ', err)
        isLoaded = false
      })
  })

  const hasSegmentLoaded = (): boolean => {
    return !!isLoaded
  }

  /**
   * This method tracks all routes/pages a user enters throughout their session
   *
   * @param category <string> - Parent Route/Page user visited
   * @param name <string> - Child page/view user visited
   * @param properties <dictionary> - <url: string>
   * @returns void
   */
  const pageView = useCallback(
    (location: Location, properties?: EventProperties) => {
      if (!hasSegmentLoaded()) {
        return
      }
      const { category, name } = getPageCategoryAndName(location)
      // Note: In analytics.js, Segment automatically sends the following properties: title, path, url, referrer, and search.
      analytics.page(category, name, properties)
    },
    [],
  )

  // Track page views for users during session
  useEffect(() => {
    // We will take advantage of the location.key to ensure only 1 unique page view is sent
    // even if the useEffect is triggered multiple times on the same page from rerenders
    if (location.key === onlyOnceMap.get('lastLocationKey')) {
      return
    }
    onlyOnceMap.set('lastLocationKey', location.key)
    pageView(location as unknown as Location)
  }, [location, pageView])

  // More information on the methods below can be found here:
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#utility-methods

  /**
   * Identify helps us track users & their session once they login to Trustero
   *
   * @param userId <string> - unqiue primary ID for a given user
   * @param traits <dictionary> - specific user data we want to include/associate with this userId
   * @param options <dictionary> - key:value pair to enable/disbale specific destionations (Not used today)
   * @param callback <func> - executed after a timeout of 300 ms, giving the browser time to make outbound requests first.
   * @returns <void>
   */
  const identify = (
    userId: string,
    traits: UserTraits,
    options?: Options,
    callback?: Callback,
  ) => {
    if (!hasSegmentLoaded()) return
    analytics.identify(userId, traits, options, callback)
  }

  /**
   * This helper ensures a user is only identifed once per user session
   * Its preferred to use identify within a `useEffect` when possible, but if the component rerenders often then
   * this is a great option
   *
   * @param userId <string> - unqiue primary ID for a given user
   * @param traits <dictionary> - specific user data we want to include/associate with this userId
   * @param options <dictionary> - key:value pair to enable/disbale specific destionations (Not used today)
   * @param callback <func> - executed after a timeout of 300 ms, giving the browser time to make outbound requests first.
   * @returns <void>
   */
  const identifyOnce = (
    userId: string,
    traits: UserTraits,
    options?: Options,
    callback?: Callback,
  ) => {
    // Since users can switch accounts, we should only identify each team once through this flow
    const companyName = traits.company?.name
    if (
      !hasSegmentLoaded() ||
      onlyOnceMap.get(`identify-${userId}-${companyName}`)
    ) {
      return
    }
    onlyOnceMap.set(`identify-${userId}-${companyName}`, true)
    identify(userId, traits, options, callback)
  }

  /**
   * Track is one of the primary methods we will use to track any custom event w/ various properties
   * For each new event we created, you need to extend the APP_ANALYTICS_EVENTS constant with a new key/value/properties
   *
   * @param event <string> - must contain a valid key:value in APP_ANALYTICS_EVENTS
   * @param properties <dictionary> - key:value pair of custom field names and values
   * @param options <dictionary> - key:value pair to enable/disbale specific destionations (Not used today)
   * @param callback <func> - executed after a timeout of 300 ms, giving the browser time to make outbound requests first.
   * @returns <void>
   */
  const track = (
    event: string,
    properties?: EventProperties,
    options?: Options,
    callback?: Callback,
  ) => {
    hasSegmentLoaded() && analytics.track(event, properties, options, callback)
  }

  /**
   * This helper ensures a track event only fires 1 time per user session
   * Its preferred to use track within a `useEffect` when possible, but if the component rerenders often then
   * this is a great option
   *
   * @param event <string> - must contain a valid key:value in APP_ANALYTICS_EVENTS
   * @param properties <dictionary> - key:value pair of custom field names and values
   * @param options <dictionary> - key:value pair to enable/disbale specific destionations (Not used today)
   * @param callback <func> - executed after a timeout of 300 ms, giving the browser time to make outbound requests first.
   * @returns <void>
   */
  const trackOnce = (
    event: string,
    properties?: EventProperties,
    options?: Options,
    callback?: Callback,
  ) => {
    if (!hasSegmentLoaded() || onlyOnceMap.get(event)) {
      return
    }
    onlyOnceMap.set(event, true)
    track(event, properties, options, callback)
  }

  /**
   * Calling reset resets the id, including anonymousId, and clears traits for the currently identified user and group.
   * Also clears localStorage for segment related fields
   *
   * This is called upon user Logout once their session is over
   *
   * @returns <void>
   */
  const reset = () => {
    hasSegmentLoaded() && analytics.reset()
  }

  return {
    events: APP_ANALYTICS_EVENTS,
    identify,
    identifyOnce,
    pageView,
    reset,
    track,
    trackOnce,
    isSegmentLoaded,
  }
}
