import { UserRecord } from '@trustero/trustero-api-web/lib/account/account_pb'
import { Overview, Receptor, Service } from '../../xgenerated'

export type Model = Overview | Service | Receptor

export type Content = {
  overviews: Overview[]
  receptors: Receptor[]
  services: Service[]
  users: UserRecord.AsObject[]
  idIndex: { [key: string]: Model }

  loaded: boolean
  lastUpdateStamp: number
  deltaUpdatePeriod: number
  deltaUpdateRunning: boolean
}

export const contentInitialState = (): Content => {
  return {
    overviews: [],
    receptors: [],
    services: [],
    users: [],

    idIndex: {},
    loaded: false,
    lastUpdateStamp: 0,
    deltaUpdatePeriod: 60000,
    deltaUpdateRunning: false,
  }
}
