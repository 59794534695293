import React from 'react'
import styled from 'styled-components/macro'
import {
  compose,
  variant,
  buttonStyle,
  grid,
  position,
  space,
  GridProps,
  PositionProps,
  SpaceProps,
  LayoutProps,
  layout,
  flexbox,
  FlexboxProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../../Tooltip/Tooltip'

const buttonSize = variant({
  prop: 'buttonSize',
  variants: {
    small: {
      height: '32px',
      px: 's',
      fontSize: '1',
      fontWeight: 'semiBold',
    },
    medium: {
      height: '40px',
      px: 'm',
      fontSize: '3',
      fontWeight: 'semiBold',
    },
    large: {
      height: '48px',
      px: 'l',
      fontSize: '4',
      fontWeight: 'semiBold',
    },
  },
})

export enum StandardButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'cancel',
  AI_PRIMARY = 'aiPrimary',
  AI_SECONDARY = 'aiSecondary',
}

export enum StandardButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type StandardButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  LayoutProps &
  FlexboxProps &
  GridProps &
  PositionProps &
  SpaceProps & {
    variant: StandardButtonVariant
    buttonSize: StandardButtonSize
  }

export const StandardButton = styled.button.attrs<StandardButtonProps>(
  ({ display, alignItems, justifyContent }) => ({
    display: display || 'flex',
    alignItems: alignItems || 'center',
    justifyContent: justifyContent || 'center',
  }),
)<StandardButtonProps>`
  ${compose(buttonStyle, buttonSize)}
  ${compose(layout, flexbox, grid, position, space)}
  
  ${(props) => {
    if (Array.isArray(props.children) && props.children.length > 1)
      return `
      > :first-child {
        margin-right: ${themeGet('space.xs')(props)}px;
      }
    `
  }}
  white-space: nowrap;
`

export const PrimaryButton = ({
  text,
  onClick = () => undefined,
  isDisabled,
  disabledReason,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
  size = StandardButtonSize.MEDIUM,
  buttonType = 'button',
  isAi,
}: {
  text: string
  onClick?: () => void
  isDisabled?: boolean
  disabledReason?: string
  requiredPermissions?: PERMISSIONS[]
  size?: StandardButtonSize
  buttonType?: 'button' | 'submit' | 'reset'
  isAi?: boolean
}): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)

  let tooltipText = disabledReason
  let isButtonDisabled = isDisabled
  if (!hasPermission) {
    tooltipText = 'You do not have permission to perform this action.'
    isButtonDisabled = true
  }

  const variant = isAi
    ? StandardButtonVariant.AI_PRIMARY
    : StandardButtonVariant.PRIMARY

  return (
    <>
      <Tooltip
        id={text}
        tooltipBody={tooltipText}
        disabled={!isButtonDisabled || !tooltipText}
        overlayType={TooltipOverlayType.popover}
        placement={TooltipPositions.top}
      >
        <div>
          <StandardButton
            type={buttonType}
            buttonSize={size}
            variant={variant}
            marginBottom={10}
            onClick={onClick}
            disabled={isButtonDisabled}
          >
            {text}
          </StandardButton>
        </div>
      </Tooltip>
    </>
  )
}

export const SecondaryButton = ({
  text,
  onClick,
  isDisabled,
  disabledReason,
  icon,
  size = StandardButtonSize.MEDIUM,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
  isAi,
  content,
}: {
  text?: string
  onClick: () => void
  isDisabled?: boolean
  icon?: JSX.Element
  disabledReason?: string
  size?: StandardButtonSize
  requiredPermissions?: PERMISSIONS[]
  isAi?: boolean
  content?: JSX.Element
}): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)

  let tooltipText = disabledReason
  let isButtonDisabled = isDisabled
  if (!hasPermission) {
    tooltipText = 'You do not have permission to perform this action.'
    isButtonDisabled = true
  }

  const variant = isAi
    ? StandardButtonVariant.AI_SECONDARY
    : StandardButtonVariant.SECONDARY

  return (
    <>
      <Tooltip
        id={text || 'secondary-button'}
        tooltipBody={tooltipText}
        disabled={!isButtonDisabled || !tooltipText}
        overlayType={TooltipOverlayType.popover}
        placement={TooltipPositions.top}
      >
        <StandardButton
          type="button"
          buttonSize={size}
          variant={variant}
          marginBottom={10}
          onClick={onClick}
          disabled={isButtonDisabled}
        >
          {icon}
          {text}
          {content}
        </StandardButton>
      </Tooltip>
    </>
  )
}
