import React, { useContext, useState } from 'react'
import { PickerConfiguration } from '../Linkers.constants'

type GoogleDriveContextType = {
  pickerConfig: PickerConfiguration | null
  setPickerConfig: React.Dispatch<
    React.SetStateAction<PickerConfiguration | null>
  >
}

const GoogleDriveContext = React.createContext<GoogleDriveContextType>({
  pickerConfig: null,
  setPickerConfig: () => null,
})

export const useGoogleDriveContext = (): GoogleDriveContextType =>
  useContext(GoogleDriveContext)

export const GoogleDriveContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [pickerConfig, setPickerConfig] = useState<PickerConfiguration | null>(
    null,
  )

  return (
    <GoogleDriveContext.Provider
      value={{
        pickerConfig,
        setPickerConfig,
      }}
    >
      {children}
    </GoogleDriveContext.Provider>
  )
}
