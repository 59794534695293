import React, { useCallback, useEffect, useState } from 'react'
import { ControlStatusRequest } from '@trustero/trustero-api-web/lib/model/model_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { useLocation } from 'react-router-dom'
import {
  RISKS_STATUS_DROPDOWN,
  RISK_STATUS_LABEL,
  RiskValue,
} from 'src/pages/Risks/risks.constants'
import log from 'loglevel'
import { useCreateOrUpdateRisks } from 'src/pages/Risks/risks.hooks'
import { updatedRiskRpcData } from 'src/pages/Risks/risks.helpers'
import { RiskFields } from 'src/pages/Risks'
import isFunction from 'lodash/isFunction'
import { showInfoToast } from 'src/Utils/helpers/toast'
import {
  QUESTIONNAIRE_ACCEPTANCE_LABEL,
  QUESTIONNAIRE_STATUS_DROPDOWN,
} from 'src/pages/SecurityQuestionnaire/securityquestionnaire.constants'
import { useUpdateQuestionnaire } from 'src/pages/SecurityQuestionnaire/securityQuestionnaire.hooks'
import {
  QUESTIONNAIRE_ACCEPTANCE,
  Questionnaire,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { useModalState } from 'src/Modal/ModalStateContext'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import useClickOutside from '../../Utils/useClickOutside'
import { useAuthorizedGrpcClient } from '../../adapter'
import { useGrpcRevalidateByMethod } from '../async'
import { controlStatusTitle } from '../../lib/common/types'
import {
  ControlsAbsoluteRoutes,
  RisksAbsoluteRoutes,
} from '../Reusable/RootPage/RootPage.constants'
import { ModalFormId } from '../ModalForms'
import {
  StatusDropdownContainer,
  StatusDropdownItem,
  StatusDropdownMenuContainer,
  StatusDropdownToggle,
} from './styles'
import {
  CONTROLS_STATUS_DROPDOWN_ORDERED_LIST,
  StatusDropdownItemsProps,
  StatusDropdownProps,
} from './StatusDropdown.constants'

const StatusDropdownItems = <T,>({
  statusItems,
  status,
  isDismissed,
  modelType,
  selectStatus,
}: StatusDropdownItemsProps<T>) => {
  const statusDropdownClickHandler = async (
    e: React.MouseEvent,
    statusValue: T,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (isDismissed) {
      return
    }
    try {
      await selectStatus(statusValue, modelType)
    } catch (err) {
      log.error(`Error setting status for ${modelType}`, err)
    }
  }
  const StatusDropdownItemComponent = StatusDropdownItem<T>()

  return (
    <>
      {statusItems.map((statusValue: T) => (
        <StatusDropdownItemComponent
          key={`${statusValue}`}
          selected={status}
          modelType={modelType}
          status={statusValue}
          onClick={(e: React.MouseEvent) => {
            statusDropdownClickHandler(e, statusValue)
          }}
        />
      ))}
    </>
  )
}

/**
 * Reusable Status Dropdown component
 *
 * Currently used by Controls & Risks - can be used by any model that has a status in the future as well
 * Default handling is for Controls
 *
 * @deprecated Use DecoratedDropdown instead. This is very tightly coupled and encourages bad patterns.
 * @param status
 * @param isDismissed
 * @param modelId
 * @param modelType
 * @param mutate
 * @returns
 */
export const StatusDropdown = <T extends string | number | symbol>({
  status,
  isDismissed,
  modelId,
  modelType,
  mutate,
  model,
  onToggle,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
}: StatusDropdownProps<T>): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)
  const location = useLocation()
  const grpcRevalidateByMethod = useGrpcRevalidateByMethod()
  const createOrUpdateRisks = useCreateOrUpdateRisks()
  const updateQuestionnaire = useUpdateQuestionnaire()
  const { openModal } = useModalState()
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const [currStatus, setCurrStatus] = useState<T>(status)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (
      modelType === MODEL_TYPE.CONTROL &&
      status === CONTROL_STATUS.NOTAPPLICABLE
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [status, modelType])

  const statusRef = useClickOutside(() =>
    setIsVisible(false),
  ) as React.RefObject<HTMLDivElement>

  const showRoute =
    modelType === MODEL_TYPE.RISK
      ? RisksAbsoluteRoutes.SHOW
      : ControlsAbsoluteRoutes.SHOW
  const isLarge = location.pathname.includes(showRoute)

  const toggleVisibility = () => {
    setIsVisible((isVisible) => !isVisible)
  }

  // We don't want users to be able to set a control's status to "Failed Test"
  let filteredStatusList: T[]
  // For some reason we need to add the number type to the Record bc Risk Status has no FAILED_TEST enum vlaue
  let filterLabels: Record<T | number, string>
  if (modelType === MODEL_TYPE.RISK) {
    filteredStatusList = RISKS_STATUS_DROPDOWN as T[]
    filterLabels = RISK_STATUS_LABEL as Record<T | number, string>
  } else if (modelType === MODEL_TYPE.QUESTIONNAIRE) {
    filteredStatusList = QUESTIONNAIRE_STATUS_DROPDOWN as T[]
    filterLabels = QUESTIONNAIRE_ACCEPTANCE_LABEL as Record<T | number, string>
  } else {
    filteredStatusList = CONTROLS_STATUS_DROPDOWN_ORDERED_LIST.filter(
      (statusValue) => statusValue !== CONTROL_STATUS.NOTAPPLICABLE,
    ) as T[]
    filterLabels = controlStatusTitle as Record<T | number, string>
  }

  useEffect(() => {
    setCurrStatus(status)
  }, [status])

  const selectStatus = useCallback(
    async (newStatus: T): Promise<void> => {
      const isNA = newStatus === (CONTROL_STATUS.NOTAPPLICABLE as T)
      !isNA && setCurrStatus(newStatus)
      try {
        switch (modelType) {
          case MODEL_TYPE.RISK: {
            if (!model) {
              return
            }
            const risk = model as Risk
            const updatedRisk = updatedRiskRpcData(
              risk,
              RiskFields.status,
              newStatus as RiskValue,
            )
            await createOrUpdateRisks([updatedRisk])
            break
          }
          case MODEL_TYPE.QUESTIONNAIRE: {
            if (!model) {
              return
            }
            const questionnaire = model as Questionnaire
            questionnaire.setAcceptance(newStatus as QUESTIONNAIRE_ACCEPTANCE)
            await updateQuestionnaire(questionnaire)
            break
          }
          // Treat Controls as default handling until 3rd model type is added
          case MODEL_TYPE.CONTROL:
          default: {
            const updateControlStatus = async () => {
              const identifierMsg = new Identifier()
                .setModelid(modelId)
                .setModeltype(MODEL_TYPE.CONTROL)
              const controlStatusMsg = new ControlStatusRequest()
                .setId(identifierMsg)
                .setStatus(newStatus as CONTROL_STATUS)
              await modelClient.setControlStatus(controlStatusMsg)
              if (isFunction(mutate)) {
                await mutate()
              }
              await grpcRevalidateByMethod(
                ModelPromiseClient.prototype.getControlStats,
              )
            }
            toggleVisibility()
            if (isNA) {
              openModal(ModalFormId.MARK_CONTROL_NA)
            } else {
              await updateControlStatus()
            }
          }
        }
      } catch (err) {
        log.error(
          `Error updating status in StatusDropdown.tsx ModelType: ${modelType} ModelId: ${modelId}`,
          err,
        )
        showInfoToast('Error updating control status')
      }
      setIsVisible(false)
    },
    [
      modelType,
      model,
      createOrUpdateRisks,
      updateQuestionnaire,
      modelId,
      modelClient,
      mutate,
      grpcRevalidateByMethod,
      openModal,
    ],
  )

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onToggle?.()
    !isDismissed && toggleVisibility()
  }

  const StatusDropdownToggleComponent = StatusDropdownToggle<T>()

  return (
    <StatusDropdownContainer ref={statusRef} isLarge={isLarge}>
      <StatusDropdownToggleComponent
        {...{ isVisible, isDismissed, status: currStatus }}
        onClick={handleToggle}
        isLarge={isLarge}
        modelType={modelType}
        disabled={isDisabled || !hasPermission}
      >
        {filterLabels[currStatus]}
      </StatusDropdownToggleComponent>
      <StatusDropdownMenuContainer isVisible={isVisible}>
        <StatusDropdownItems
          statusItems={filteredStatusList}
          status={currStatus}
          isDismissed={isDismissed}
          modelType={modelType}
          selectStatus={selectStatus}
        />
      </StatusDropdownMenuContainer>
    </StatusDropdownContainer>
  )
}
