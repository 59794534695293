import { MutableRefObject, RefObject, useEffect, useRef } from 'react'

/**
 * Generates a ref to be added onto a target element
 * Calls the callback when a click, or keyboard submit
 * outside of the target element is registered
 *
 * @param {Function} callback - callback function to invoke
 */
export default function useClickOutside<T extends HTMLElement = HTMLElement>(
  callback: () => unknown,
): MutableRefObject<T> | MutableRefObject<T | undefined> | RefObject<T> {
  const ref = useRef<T>()

  const isContained = (e: MouseEvent | KeyboardEvent): boolean | undefined => {
    const target = e.target as Node | null
    return ref.current?.contains(target)
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!isContained(e)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    const handleKeyboardSubmit = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !isContained(e)) callback()
    }
    document.addEventListener('keypress', handleKeyboardSubmit)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keypress', handleKeyboardSubmit)
    }
  })

  return ref
}
