import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import { DerivedTextInput } from '../../components/Reusable/Inputs'

type ModalFormProps = {
  id: string
  onSubmit: React.FormEventHandler
}
export const ModalForm = styled.form<ModalFormProps>`
  display: flex;
  flex-direction: column;
`

export const InputLabel = styled.p`
  margin-bottom: 12px;
  color: #1f2532;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
`

export const FormInput = styled.input.attrs((props) => ({
  type: props.type ? props.type : 'text',
}))`
  height: 45px;
  font-size: 18px;
  :not(:last-child) {
    margin-bottom: 12px;
  }
  padding: 12px;
  background-color: #f3f8fb;
  border: none;
`

export const DerivedTextArea = styled(DerivedTextInput).attrs({
  forwardedAs: 'textarea',
})`
  &[class^='StyledTextInput'],
  > *[class^='StyledTextInput'] {
    display: block;
    resize: none;
    height: 240px;
    padding: ${themeGet('space.s')}px;
  }
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const StatusLabel = styled(InputLabel)`
  margin-right: 12px;
  margin-bottom: 0;
  font-weight: 400;
`
