import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react'

import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { ResponsibilityOptions } from 'src/components/PageLayout/ShowPage/Dropdowns/Dropdowns.constants'
import { useVendors } from 'src/pages/Vendors/vendors.hooks'
import {
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
  MultiSelectDropdownRadio,
} from 'src/components/Reusable/MultiSelectDropdown/MultiSelectDropdown.styles'
import { StyledDropdownToggle } from 'src/components/Reusable/StandardDropdown/StandardDropdown.styles'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  MODEL_TYPE,
  RESPONSIBILITY,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { P } from 'src/components/Reusable/Text/Text.styles'
import { PolicyResponsibilityDescription } from 'src/pages/Policies/Modals/PolicyModals.components'
import { useThrobberContext } from 'src/Throbber'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { VendorsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useHref } from 'react-router-dom'
import { UrlFragments } from 'src/Utils/globalEnums'
import { RadioInput } from 'src/components/Reusable/Inputs/Radio/RadioInput'
import { FlexAlign, FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import palette from 'src/designSystem/variables/palette'
import { LinkIcon } from 'src/components/Icons/FileType'
import { ControlResponsibilityDescription } from 'src/pages/Controls/modals/ControlModals.components'
import { ModalForm, ModalFormId } from '../../../components/ModalForms'
import { DerivedResizableTextArea } from '../../../components/Reusable/Inputs/TextInput'

export const ResponsibilityForm = ({
  id,
  modelType,
  modalId,
  onUpdate,
  currentResponsibility,
  updatedResponsibility,
  vendorId,
  notApplicableReason,
  customElement,
}: {
  id: string
  modelType: MODEL_TYPE.CONTROL | MODEL_TYPE.POLICY
  modalId: ModalFormId
  onUpdate: (
    responsibility: RESPONSIBILITY,
    naReason: string,
    selectedVendor: VendorRecord | null,
  ) => Promise<void>
  currentResponsibility: RESPONSIBILITY
  updatedResponsibility?: RESPONSIBILITY
  vendorId?: string
  notApplicableReason?: string
  customElement?: JSX.Element
}): JSX.Element => {
  const { startThrobber, stopThrobber } = useThrobberContext()
  const [naReason, setNaReason] = useState<string>('')
  const [isValidReason, setIsValidReason] = useState<boolean>(true)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [selectedVendor, setSelectedVendor] = useState<VendorRecord | null>(
    null,
  )
  const [responsibility, setResponsibility] = useState<RESPONSIBILITY>(
    updatedResponsibility !== undefined
      ? updatedResponsibility
      : currentResponsibility,
  )
  const {
    data: vendors,
    isLoading: isLoadingVendors,
    mutate: mutateVendors,
  } = useVendors()

  useEffect(() => {
    if (vendorId) {
      setSelectedVendor(
        vendors?.getVendorsList().find((v) => v.getId() === vendorId) || null,
      )
    }
    if (notApplicableReason) {
      setNaReason(notApplicableReason)
    }
  }, [vendors, vendorId, notApplicableReason])

  useEffect(() => {
    if (updatedResponsibility !== undefined) {
      setResponsibility(updatedResponsibility)
    }
  }, [setResponsibility, updatedResponsibility])

  useEffect(() => {
    if (responsibility === RESPONSIBILITY.TMP_OUTSOURCED) {
      setResponsibility(RESPONSIBILITY.OUTSOURCED_ATTESTATION)
    }
  }, [responsibility])

  const showVendorLinking =
    (responsibility === RESPONSIBILITY.INHERITED ||
      responsibility === RESPONSIBILITY.OUTSOURCED_ATTESTATION ||
      responsibility === RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION ||
      responsibility === RESPONSIBILITY.TMP_OUTSOURCED) &&
    !isLoadingVendors &&
    vendors

  const requireReason = !(
    responsibility === RESPONSIBILITY.DIRECT_RESPONSIBILITY
  )

  useEffect(() => {
    const vendorStatus = showVendorLinking ? selectedVendor !== null : true

    const reasonStatus = requireReason ? naReason.length : true

    if (reasonStatus && vendorStatus) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [naReason, requireReason, selectedVendor, showVendorLinking])

  const show = useIsShowModal(modalId)

  const onHide = () => {
    setNaReason('')
    setIsValidReason(true)
    setSelectedVendor(null)
  }
  const hide = useHideModal({
    modalId,
    onHide,
  })

  const changeNaReason: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newReason = e.currentTarget.value
    if (!isValidReason) {
      setIsValidReason(true)
    }
    setNaReason(newReason)
  }

  const displayResponsibility = ResponsibilityOptions[responsibility]

  const handleRefreshVendors = async (e: React.MouseEvent) => {
    e.preventDefault()
    await mutateVendors()
  }

  const addVendorRoute = useHref({
    pathname: `../../../${VendorsAbsoluteRoutes.INDEX_PAGE}`,
    hash: `${UrlFragments.ADD_VENDOR}`,
  })

  const isOutsourced =
    responsibility === RESPONSIBILITY.OUTSOURCED_ATTESTATION ||
    responsibility === RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION ||
    responsibility === RESPONSIBILITY.TMP_OUTSOURCED

  const isControlNotApplicableStatus =
    responsibility === RESPONSIBILITY.NOT_APPLICABLE ||
    responsibility === RESPONSIBILITY.OUTSOURCED_ATTESTATION ||
    responsibility === RESPONSIBILITY.INHERITED

  const isControl = modelType === MODEL_TYPE.CONTROL
  const modelText = isControl ? 'control' : 'policy'

  const outsourcedGuidance =
    isControl && isOutsourced
      ? responsibility === RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION
        ? 'You will still need to provide evidence for this control.'
        : 'Ensure that the attestation is linked to the vendor.'
      : ''

  const description = isControl ? (
    <ControlResponsibilityDescription
      isNaStatus={isControlNotApplicableStatus}
    />
  ) : (
    <PolicyResponsibilityDescription />
  )

  const updateFunc: FormEventHandler = async (e) => {
    e.preventDefault()
    startThrobber()
    await onUpdate(
      responsibility,
      naReason,
      showVendorLinking ? selectedVendor : null,
    )
    stopThrobber()
    hide()
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      title={`Change ${modelText} responsibility to ${displayResponsibility}?`}
      submitText={`Mark ${displayResponsibility} `}
      description={description}
      formId={modalId}
      showOverflow
      disableSubmitButton={isDisabled}
    >
      <form id={modalId} onSubmit={updateFunc}>
        {showVendorLinking && (
          <FlexColumn align={FlexAlign.FLEX_START} gap={5} $mt={0} $mb={20}>
            <P
              $fontSize={14}
            >{`Which vendor is responsible for this ${modelText}?`}</P>
            <Dropdown id="vendor-dropdown" drop="down" autoClose="outside">
              <Dropdown.Toggle as={StyledDropdownToggle}>
                {selectedVendor ? selectedVendor.getName() : 'Select a vendor'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {vendors && vendors.getVendorsList().length > 0 ? (
                  <>
                    {vendors.getVendorsList().map((vendor) => (
                      <Dropdown.Item
                        key={vendor.getId()}
                        as={MultiSelectDropdownItem}
                        eventKey={vendor.getId()}
                      >
                        <MultiSelectDropdownCheckBoxLabel>
                          <MultiSelectDropdownRadio
                            id={`${id} -${vendor.getId()} `}
                            name={vendor.getId()}
                            checked={selectedVendor?.getId() === vendor.getId()}
                            onChange={() => setSelectedVendor(vendor)}
                          />
                          {vendor.getName()}
                        </MultiSelectDropdownCheckBoxLabel>
                      </Dropdown.Item>
                    ))}
                  </>
                ) : (
                  <Dropdown.Item disabled>No vendors available</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <P $fontSize={14}>
              Don&apos;t see the appropriate vendor?{' '}
              <ol>
                <li>
                  <FlexRow gap={5} justify={FlexAlign.FLEX_START}>
                    <a target="_blank" rel="noreferrer" href={addVendorRoute}>
                      Add a new vendor
                    </a>
                    <LinkIcon />
                  </FlexRow>
                </li>
                <li>
                  <TextButton onClick={handleRefreshVendors}>
                    Refresh vendor list
                  </TextButton>
                </li>
              </ol>
            </P>
          </FlexColumn>
        )}
        {isOutsourced && (
          <>
            <P $fontSize={16}>
              {`Does this vendor have the appropriate attestation for you to fully
              outsource this ${modelText}?`}
            </P>
            <FlexRow gap={5} justify={FlexAlign.FLEX_START}>
              <RadioInput
                id={`${RESPONSIBILITY.OUTSOURCED_ATTESTATION} `}
                checked={
                  responsibility === RESPONSIBILITY.OUTSOURCED_ATTESTATION
                }
                value="OutsourcedAttestation"
                onClick={() =>
                  setResponsibility(RESPONSIBILITY.OUTSOURCED_ATTESTATION)
                }
              />
              <P>Yes, they do</P>
            </FlexRow>
            <FlexRow gap={5} justify={FlexAlign.FLEX_START}>
              <RadioInput
                id={`${RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION} `}
                checked={
                  responsibility === RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION
                }
                value="OutsourcedNoAttestation"
                onClick={() =>
                  setResponsibility(RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION)
                }
              />
              <P>No, they do not</P>
            </FlexRow>
            {!!outsourcedGuidance && (
              <P $color={palette.orange[900]} $mb={20}>
                {outsourcedGuidance}
              </P>
            )}
          </>
        )}
        {requireReason && (
          <DerivedResizableTextArea
            label={`Reason the responsibility for this ${modelText} is ${displayResponsibility}:`}
            name="input"
            initVal={naReason}
            form={modalId}
            customOnChange={changeNaReason}
          />
        )}
        {customElement}
      </form>
    </ModalForm>
  )
}
