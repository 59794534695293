import React from 'react'
import { useLocation } from 'react-router-dom'
import { RISK_STATUS_FILTER } from 'src/pages/Risks/risks.constants'
import { REQUEST_STATUS_FILTER } from 'src/pages/Requests/requests.constants'
import { RECEPTOR_STATUS_FILTER } from 'src/pages/Receptors/receptors.constants'
import { FilterName, FilterParam } from '../FilterBar.types'
import { ControlStatus } from '../../../../../lib/common/types'
import { INDEX_PAGE_WITH_FILTERS } from '../../IndexPage.constants'
import { FilterDropdown } from './FilterDropdown'

const CONTROL_STATUS_FILTER = [
  {
    value: ControlStatus.NEEDS_ATTENTION,
    label: ControlStatus.NEEDS_ATTENTION,
  },
  {
    value: ControlStatus.READY_FOR_REVIEW,
    label: ControlStatus.READY_FOR_REVIEW,
  },
  {
    value: ControlStatus.AUDIT_READY,
    label: ControlStatus.AUDIT_READY,
  },
  {
    value: ControlStatus.ACCEPTED,
    label: ControlStatus.ACCEPTED,
  },
  {
    value: ControlStatus.NOT_APPLICABLE,
    label: ControlStatus.NOT_APPLICABLE,
  },
]

export const StatusFilterDropdown = (): JSX.Element => {
  const loc = useLocation()
  // TODO: Convert to component prop once we add a 3rd status type
  const isControls = loc.pathname.includes(INDEX_PAGE_WITH_FILTERS.CONTROLS)
  const isRisks = loc.pathname.includes(INDEX_PAGE_WITH_FILTERS.RISKS)
  const isRequests = loc.pathname.includes(INDEX_PAGE_WITH_FILTERS.REQUESTS)
  const isReceptors = loc.pathname.includes(INDEX_PAGE_WITH_FILTERS.RECEPTORS)
  const statusValues = new Array<{ value: string; label: string }>()

  if (isControls) {
    statusValues.push(...CONTROL_STATUS_FILTER)
  } else if (isRisks) {
    statusValues.push(...RISK_STATUS_FILTER)
  } else if (isRequests) {
    statusValues.push(...REQUEST_STATUS_FILTER)
  } else if (isReceptors) {
    statusValues.push(...RECEPTOR_STATUS_FILTER)
  }

  return (
    <FilterDropdown
      filterParam={FilterParam.STATUS}
      filterName={FilterName.STATUS}
      filterValues={statusValues}
    />
  )
}
