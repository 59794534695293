import React, { useEffect, useRef } from 'react'
import { ExternalLink } from 'src/components/Reusable/Text/Link'
import { LinkerErrorContainer, StyledLinkerError } from './Linkers.styles'
import { getLinkerKBLink } from './Linkers.helpers'
import { StyledLinkerMarkup } from './modals/ConnectLinkerModal.styles'

export const LinkerError = ({
  errMsg,
  linkerId,
}: {
  errMsg: string
  linkerId: string
}): JSX.Element => (
  <LinkerErrorContainer>
    <StyledLinkerError>{errMsg}</StyledLinkerError>
    <LinkerKBLink linkerId={linkerId} />
  </LinkerErrorContainer>
)

export const LinkerKBLink = ({
  linkerId,
}: {
  linkerId: string
}): JSX.Element => {
  const link = getLinkerKBLink(linkerId)

  return link.length ? (
    <ExternalLink href={getLinkerKBLink(linkerId)} text="Learn more" />
  ) : (
    <></>
  )
}

export const LinkerMarkupWithExternalLinks = ({
  markdown,
}: {
  markdown: string
}): JSX.Element => {
  const markdownRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const links = markdownRef.current?.querySelectorAll('a')
    links?.forEach((link) => {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })
  }, [markdown])

  return (
    <StyledLinkerMarkup
      ref={markdownRef}
      dangerouslySetInnerHTML={{ __html: markdown }}
      height="fit-content"
      my="m"
      bg="white"
      textStyle="body.default"
      overflow="auto"
      display="block"
    />
  )
}
