import log from 'loglevel'
import { EnvConfig } from '../config/config'

const logger = log.getLogger('utils')
/**
 * We don't want to mix data between prod and dev environments, so
 * modify the storage key name to include the environment.
 */
const storage_key_env = (storage_key) => EnvConfig.name + '_' + storage_key

/**
 * Load a data structure from LocalStorage.
 */
export const loadFromStorage = (storage_key, empty_struct) => {
  const env_aware_key = storage_key_env(storage_key)
  const saved_json = localStorage.getItem(env_aware_key)
  if (!saved_json) {
    // Return a *clone* of the empty struct, to make sure it
    // doesn't accidentally get modified
    return JSON.parse(JSON.stringify(empty_struct))
  }
  try {
    return JSON.parse(saved_json)
  } catch (error) {
    logger.error(
      'Error parsing local storage key =',
      env_aware_key,
      '\nsaved_json = ',
      saved_json,
    )
    localStorage.removeItem(env_aware_key)
  }
}

/**
 * Save a data structure to LocalStorage.
 */
export const saveToStorage = (storage_key, data) => {
  const env_aware_key = storage_key_env(storage_key)
  const to_save = JSON.stringify(data)
  localStorage.setItem(env_aware_key, to_save)
}
