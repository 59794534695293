import React from 'react'
import { FlexAlign, FlexRow } from '../Reusable/Flex'
import { AICopilotLogo } from '../PageLayout/PageLayout.components'
import { ModalTitle } from './ModalForm.styles'

export const AIPoweredModalHeader = ({
  title,
}: {
  title: string
}): JSX.Element => {
  return (
    <FlexRow justify={FlexAlign.SPACE_BETWEEN}>
      <ModalTitle>{title}</ModalTitle>
      <AICopilotLogo useDarkText />
    </FlexRow>
  )
}
