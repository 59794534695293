import React from 'react'
import { FlexRow } from '../Reusable/Flex'
import { AICopilotIcon, AICopilotText } from './PageLayout.styles'

export const AICopilotLogo = ({
  useDarkText,
  isLarge,
}: {
  useDarkText?: boolean
  isLarge?: boolean
}): JSX.Element => (
  <FlexRow gap={isLarge ? 16 : 8}>
    <AICopilotIcon $isLarge={isLarge} />
    <AICopilotText useDarkText={useDarkText} isLarge={isLarge}>
      AI Copilot
    </AICopilotText>
  </FlexRow>
)
