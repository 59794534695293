import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  ListEvidenceRequest,
  ListEvidenceResponse,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { useCurrentAudit } from '../model/audit'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'

export const useEvidenceResults = (
  request: ListEvidenceRequest,
  shouldFetch = true,
): GrpcResponse<ListEvidenceResponse> => {
  const audit = useCurrentAudit()
  if (audit.data?.getIsInterval() && !request.hasTimeRange()) {
    request.setTimeRange(
      new TimeRange()
        .setSince(audit.data?.getStartDate())
        .setUntil(audit.data?.getEndDate()),
    )
  }
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.listEvidence,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}
