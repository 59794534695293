import React from 'react'
import { FilterName } from '../FilterBar.types'
import { OwnerFilterDropdown } from './OwnerFilterDropdown'

export const AddedByFilterDropdown = (): JSX.Element => {
  return (
    <OwnerFilterDropdown
      customName={FilterName.ADDED_BY}
      includeUnassigned={false}
    />
  )
}
