import React from 'react'
import { GridColumn, GridRow } from '../../../Reusable/Grid'

type NoRecommendedTemplatesProps = {
  templateType: string
}

export function NoRecommendedTemplates(
  props: NoRecommendedTemplatesProps,
): JSX.Element {
  return (
    <GridRow>
      <GridColumn
        gridColumn="1 / -1"
        justifyContent="center"
        style={{
          fontSize: 'x-large',
          textAlign: 'center',
          padding: '20px',
          marginBottom: '16px',
        }}
      >
        Looks good!
        <br />
        No additional {`${props.templateType}`} recommended.
      </GridColumn>
    </GridRow>
  )
}
