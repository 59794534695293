import styled from 'styled-components/macro'
import {
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  textStyle,
  TextStyleProps,
  position,
  PositionProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import palette from '../../../../designSystem/variables/palette'

type StyledLabelProps = LayoutProps & SpaceProps & PositionProps

export const StyledLabel = styled.label.attrs<StyledLabelProps>((props) => ({
  width: props.width || '100%',
  mb: props.mb || 'l',
  textStyle: 'body.default',
  position: props.position,
}))<StyledLabelProps>`
  ${compose(layout, space, textStyle, position)}
  display: block;
  > :first-child {
    margin-bottom: ${(props) => `${themeGet('space.xxxs')(props)}px`};
  }
`

export const StyledLabelTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

type StyledTextInputProps = LayoutProps &
  SpaceProps &
  TextStyleProps & {
    showError: boolean
    customOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    maxInputLength?: number
  }

export const StyledTextInput = styled.input.attrs<StyledTextInputProps>(
  (props) => ({
    type: 'text',
    width: props.width || '100%',
    px: 's',
    bg: `${palette.neutral.white}`,
    color: 'text.input.default',
    textStyle: 'body.default',
    disabled: props.disabled,
  }),
)<StyledTextInputProps>`
  ${compose(layout, space, color, textStyle)}

  height: 40px;
  border: ${(props) =>
    `1px solid ${themeGet('colors.border.neutral.light')(props)}`};

  ::placeholder {
    color: ${themeGet('colors.text.input.placeholder')};
  }

  :hover {
    background-color: ${themeGet('colors.bg.neutralHover')};
    border-color: ${themeGet('colors.border.neutral.base')};
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }

  ${(props) => {
    return (
      props.showError &&
      `
      border-color: ${themeGet('colors.border.status.error')(props)};
    `
    )
  }}
  ${({ disabled }) => disabled && `color: ${palette.neutral[400]};`}
`

export const StyledTextArea = styled.textarea.attrs<StyledTextInputProps>(
  (props) => ({
    type: 'text',
    width: props.width || '100%',
    px: 's',
    bg: `${palette.neutral.white}`,
    color: 'text.input.default',
    textStyle: 'body.default',
    maxLength: 1000,
  }),
)<StyledTextInputProps>`
  ${compose(layout, space, color, textStyle)}

  resize: none;
  height: 40px;

  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;

  border: ${(props) =>
    `1px solid ${themeGet('colors.border.neutral.light')(props)}`};

  ::placeholder {
    color: ${themeGet('colors.text.input.placeholder')};
  }

  :hover {
    background-color: ${themeGet('colors.bg.neutralHover')};
    border-color: ${themeGet('colors.border.neutral.base')};
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }

  ${(props) => {
    if (props.showError) {
      return `
      border-color: ${themeGet('colors.border.status.error')(props)};
    `
    }
  }}
`

type StyledInputErrorProps = {
  showError: boolean
  children?: string | JSX.Element
  isWarning?: boolean
}

export const StyledInputError = styled.p.attrs<StyledInputErrorProps>(
  (props) => ({
    color: props.isWarning ? palette.orange[900] : 'fill.status.error.base',
    textStyle: 'caption',
  }),
)<StyledInputErrorProps>`
  ${compose(color, textStyle)}
  width: 100%;
  font-size: 14px;
  height: 16px;
  margin-top: 6px;
  ${(props) => {
    if (!props.children || !props.showError) {
      return `visibility: hidden;`
    }
    return ''
  }}
`
