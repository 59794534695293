import { useCallback } from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import { ListControlComplianceStatsRequest } from '@trustero/trustero-api-web/lib/model/stats_pb'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../../components'

/**
 * ! Should be used every time Evidence is added or deleted from a Control
 *
 * Returns a function that invalidates all cache keys affiliated with
 * the fetching of evidence list and evidence count for the given control
 *
 * This then triggers a refetch of a Control's evidence list and evidence count
 * @returns Returns a function to invalidate cache keys
 */
export const useInvalidateEvidence = (): (() => Promise<unknown>) => {
  const grpcRevalidateByMethodRequest = useGrpcRevalidateByMethodRequest()
  const grpcRevalidateByMethod = useGrpcRevalidateByMethod()
  const invalidator = useCallback(() => {
    return Promise.all([
      grpcRevalidateByMethod(
        AttachmentPromiseClient.prototype.getLatestEvidence,
      ),
      grpcRevalidateByMethod(AttachmentPromiseClient.prototype.listEvidence),
      grpcRevalidateByMethodRequest(
        ModelStatsServicePromiseClient.prototype.listControlComplianceStats,
        new ListControlComplianceStatsRequest(),
      ),
    ])
  }, [grpcRevalidateByMethodRequest, grpcRevalidateByMethod])

  return invalidator
}

/**
 * This can be used to invalidate all evidence cache from getLatestEvidence and listEvidence calls.
 * This is not the best long term solution and it would be better to update the cache rather than replace it. However it could be a lot worse and will do for now 12/11/2022.
 *
 * @returns Returns a function to invalidate evidence
 */

export const useHardEvidenceInvalidation = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(AttachmentPromiseClient.prototype.getLatestEvidence),
      mutateFunc(AttachmentPromiseClient.prototype.listEvidence),
      mutateFunc(AttachmentPromiseClient.prototype.getDocumentByOID),
    ])
  }, [mutateFunc])
}
