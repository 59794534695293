import styled from 'styled-components/macro'
import {
  border,
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  space,
  SpaceProps,
  textStyle,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { CheckSVG, DropdownSVG } from '../../Icons/Basic'

export type DropdownLabelProps = LayoutProps & SpaceProps

export const DropdownLabel = styled.label.attrs<DropdownLabelProps>(
  (props) => ({
    mb: props.mb || 0,
    width: props.width || '100%',
    textStyle: 'body.default',
  }),
)<DropdownLabelProps>`
  ${compose(layout, space, textStyle)}
  > :first-child {
    margin-bottom: ${(props) => `${themeGet('space.xxxs')(props)}px`};
  }
`

export type DropdownContainerProps = LayoutProps

export const DropdownContainer = styled.div.attrs<DropdownContainerProps>(
  (props) => {
    return {
      className: 'dropdown-container',
      width: props.width || '100%',
      height: props.height || '40px',
      border: '1px solid',
      borderColor: 'border.neutral.base',
      textStyle: 'body.default',
    }
  },
)`
  ${compose(layout, space, border, color, textStyle)}
  position: relative;
`

type DropdownToggleProps = {
  isToggled: boolean
}

export const DropdownToggle = styled.button.attrs({
  pl: 's',
  pr: 'xs',
  type: 'button',
})<DropdownToggleProps>`
  ${space}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: none;
  padding: none;
  color: ${(props) => (props.isToggled ? 'white' : 'inherit')};

  :hover {
    background-color: ${themeGet('colors.bg.neutral')};
  }

  svg {
    transform: ${(props) =>
      props.isToggled ? 'rotate(180deg)' : 'rotate(0deg)'};
    fill: ${(props) =>
      props.isToggled ? 'white' : themeGet('colors.fill.neutral.base')};
  }

  background-color: ${(props) =>
    props.isToggled ? `#515D75 !important` : themeGet('colors.fill.white')};
`

export const DropdownIcon = styled(DropdownSVG)`
  fill: ${themeGet('colors.fill.tertiary.base')};
`

type DropdownMenuProps = LayoutProps &
  ColorProps &
  PositionProps & {
    isToggled: boolean
  }

export const DropdownMenu = styled.ul.attrs({
  border: '1px solid',
  borderColor: 'border.neutral.light',
  boxShadow: 'outer.base',
})<DropdownMenuProps>`
  ${compose(border, color)}
  display: ${(props) => (props.isToggled ? 'block' : 'none')};
  position: absolute;
  z-index: 2;
  ${position}
  min-width: 150px;
  width: 100%;
  ${layout}
  margin: 0;
  padding: 0;
  ${shadow}
`
DropdownMenu.defaultProps = {
  top: '100%',
}

export const DropdownItem = styled.button.attrs({
  pl: 's',
  pr: 's',
  bg: 'fill.white',
  textStyle: 'body.default',
  type: 'button',
})`
  ${compose(space, border, color, textStyle)}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  border: none;

  :not(:last-child) {
    border-bottom: none;
  }

  :hover {
    background-color: ${themeGet('colors.text.icon.hover')};
  }
`

export const DropdownSelectedIcon = styled(CheckSVG).attrs({
  width: '20px',
  height: '20px',
  fill: 'text.icon.default',
})`
  flex-shrink: 0;
`

export const DropdownItemUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    margin-right: 8px;
  }
`
