import { Users } from '@trustero/trustero-api-web/lib/account/account_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { UserPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'

/**
 * Destructure data for users in account, call .toObject() method on data and users will be in
 * users list array
 * Destructure mutate function to get the cache to update. Do this after changing the users list
 * @returns { data, mutate }
 */

export const useUsers = (): GrpcResponse<Users> => {
  const { response } = useSwrImmutableGrpc(
    UserPromiseClient.prototype.getUsersInAccount,
    new Empty(),
  )
  return NewGrpcResponse(response)
}
