import React, { useMemo } from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  Framework,
  Frameworks,
} from '@trustero/trustero-api-web/lib/model/framework_pb'
import { BaseProps, OnResponseProps, OnUnpackedResponse } from '../types'
import { AsyncImmutableComponent } from '../AsyncImmutableComponent'

type Props<ExternalProps> = BaseProps<
  ExternalProps,
  OnUnpackedResponse<ExternalProps, Frameworks, { frameworks: Framework[] }>
>

export function FrameworksComponent<ExternalProps>(
  props: Props<ExternalProps>,
): JSX.Element {
  const request = useMemo(() => new Empty(), [])
  const child = useMemo(
    () => (p: OnResponseProps<ExternalProps, Frameworks>) =>
      props.child({ ...p, frameworks: p.response.getItemsList() }),
    [props],
  )

  return (
    <AsyncImmutableComponent
      props={props.props}
      asyncCall={ModelPromiseClient.prototype.listFrameworks}
      request={request}
      placeholder={props.placeholder}
      onError={props.onError}
      child={child}
    />
  )
}
