const palette = {
  blue: {
    30: '#EFFBFD',
    50: '#DFF7FB',
    100: '#B0EAF4',
    200: '#7CDDEC',
    300: '#44CEE4',
    400: '#05C3DD',
    500: '#00BAD7',
    600: '#00AAC4',
    700: '#0095A9',
    800: '#008191',
    900: '#005E65',
  },
  green: {
    30: '#EFFCFB',
    50: '#D7F7F1',
    100: '#9AE9D9',
    200: '#3CDBC1',
    300: '#00C9A7',
    400: '#00BA94',
    500: '#00AA81',
    600: '#009C74',
    700: '#008C63',
    800: '#007B55',
    900: '#005E37',
  },
  neutral: {
    30: '#F6F9FC',
    40: '#EBEDEF',
    50: '#EBEDEF',
    100: '#CBD2D8',
    200: '#AAB4BE',
    300: '#8996A4',
    400: '#718191',
    500: '#586C7F',
    600: '#4C5E6E',
    700: '#3D4B59',
    800: '#2E3944',
    900: '#1D252D',
    black: '#000000',
    white: '#ffffff',
  },
  orange: {
    30: '#FFF9EF',
    50: '#FFF8E1',
    100: '#FFECB3',
    200: '#FFE082',
    300: '#FFD54F',
    400: '#FFCA28',
    500: '#FFC107',
    600: '#FFB300',
    700: '#FFA000',
    800: '#FF8F00',
    900: '#FF6F00',
  },
  purple: {
    30: '#F6F3FC',
    50: '#EEE8F9',
    100: '#D2C7F0',
    200: '#B4A1E7',
    300: '#967BDE',
    400: '#7D5ED6',
    500: '#6442CE',
    600: '#593DC8',
    700: '#4A35BE',
    800: '#3B2FB7',
    900: '#1E22AA',
  },
  magenta: {
    30: '#FDF2F7',
    50: '#FDF2F7',
    100: '#F8BEDA',
    200: '#F693C1',
    300: '#F567A8',
    400: '#F44493',
    500: '#F51D7D',
    600: '#E31C79',
    700: '#CC1B72',
    800: '#B6196D',
    900: '#8F1563',
  },
}

export default palette
