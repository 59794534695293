import React from 'react'
import { GridProps, TypographyProps } from 'styled-system'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import BootstrapTab from 'react-bootstrap/Tab'
import { TabContainerProps } from 'react-bootstrap/TabContainer'
import { CountChip } from '../Chips/BasicChip'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../Tooltip/Tooltip'
import { TableListLoader } from '../ContentLoaders/TableListLoader'
import {
  TabContainer,
  TabRow,
  TabContent,
  TabNav,
  TabNote,
  TabTitle,
  TabNavItem,
} from './Tabs.styles'

export type TabType = {
  title: string
  toolTipBody?: string
  count?: number | JSX.Element
  body: JSX.Element
  note?: JSX.Element
  disabled?: boolean
}

type TabsProps = {
  tabs: TabType[]
  defaultActiveKey?: string | number
  onSelect?: TabContainerProps['onSelect']
  tabTitleFontStyles?: TypographyProps
  gridStyles?: GridProps
}

export function Tabs({
  tabs,
  defaultActiveKey = '0',
  onSelect,
  tabTitleFontStyles,
  gridStyles,
}: TabsProps): JSX.Element {
  const innerTabs: JSX.Element[] = []
  const Panes: JSX.Element[] = []

  tabs.forEach(({ title, toolTipBody, count, body, note, disabled }, idx) => {
    innerTabs.push(
      <TabNavItem key={`${title}-${idx}`}>
        <TabTitle
          eventKey={idx}
          {...tabTitleFontStyles}
          data-testid={`TAB_IDX_${idx}`}
          disabled={disabled}
        >
          <span className="tab-title">{title}</span>
          {count !== undefined && <CountChip ml="xs">{count}</CountChip>}
          {toolTipBody && (
            <Tooltip
              id={`tab-tooltip-${title}`}
              placement={TooltipPositions.right}
              overlayType={TooltipOverlayType.popover}
              tooltipBody={toolTipBody}
            >
              <InfoButton as="div" ml="xxs" />
            </Tooltip>
          )}
        </TabTitle>
      </TabNavItem>,
    )

    Panes.push(
      <BootstrapTab.Pane key={idx} eventKey={idx}>
        {note && <TabNote>{note}</TabNote>}
        {body}
      </BootstrapTab.Pane>,
    )
  })

  return (
    <BootstrapTab.Container
      defaultActiveKey={defaultActiveKey}
      onSelect={onSelect}
    >
      <TabContainer {...gridStyles}>
        <TabRow>
          <TabNav>{innerTabs}</TabNav>
        </TabRow>
        <TabRow>
          <TabContent>{Panes}</TabContent>
        </TabRow>
      </TabContainer>
    </BootstrapTab.Container>
  )
}

export const getLoadingTab = ({ title }: { title: string }): TabType => ({
  title,
  count: 0,
  body: <TableListLoader />,
})
