import { useAddOrUpdateDocumentRequest } from '../../components/async/DocumentRequest/useDocumentRequests'
import { useConfirmationModal } from '../../components/ModalForms'
import { useCurrentControl } from '../../context/FormContext'
import { useRequestControlIds } from '../../pages/Requests/RequestShowPage/RequestShowPage.utils'

export const useRemoveControlFromRequestModal = ({
  requestId,
}: {
  requestId: string
}): (() => void) => {
  // get the current control from the context
  const {
    currentControl: { id: controlId, name: controlName },
  } = useCurrentControl()
  // get the current controls for the request
  const controlIdsArray = useRequestControlIds(requestId)
  // get the update CB
  const updateRequest = useAddOrUpdateDocumentRequest()

  const onSubmit = async () => {
    const controlIds = controlIdsArray.filter((elem) => elem !== controlId)
    const unlinkControlId = controlId
    await updateRequest({
      id: requestId,
      controlIds,
      unlinkControlId,
    })
  }

  const confirmationModalProps = {
    title: 'Unlink control and remove evidence?',
    body: `Evidence from this request will be removed for the control ${controlName}`,
    confirmText: 'Unlink and Remove Evidence',
    redirectTo: '.',
    onConfirmCB: onSubmit,
  }

  return useConfirmationModal(confirmationModalProps)
}
