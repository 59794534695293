import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import {
  RESPONSIBILITY,
  ResponsibilityValue,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  CONTROL_STATUS,
  ControlStatusValue,
  CreateUpdateControlRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  Responsibility,
  ResponsibilityToOptions,
} from 'src/components/PageLayout/ShowPage/Dropdowns/Dropdowns.constants'
import { ControlStatus } from 'src/lib/common/types'
import { controlStatusToTitle } from 'src/lib/common/types/control'
import {
  createLowercaseLookupMap,
  CSV_VALIDATIONS,
  HeaderValues,
  ParseColumnType,
} from 'src/Utils/csv/csv.constants'
import {
  ComplianceFrameworkGroup,
  NONE_ID,
  NONE_NAME,
} from 'src/Utils/globalConstants'

export const CUSTOM_FRAMEWORK_INSTRUCTIONS = ` ("True" to add custom framework association)`

export const CONTROL_CSV_TEMPLATE: { [key: string]: string }[] = [
  {
    'Control ID': 'CTRL-0001',
    'Control Name': 'This is a sample control',
    Objective: 'This is a sample objective',
    Notes: 'This is a sample note',
    Procedure: 'This is a sample procedure',
    'Required Evidence': 'sample required evidence',
    'Test Procedures': 'sample test procedures',
  },
  {
    'Control ID': 'CTRL-0002',
    'Control Name': 'This is a sample control',
    Objective: 'This is a sample objective',
    Notes: 'This is a sample note',
    Procedure: 'This is a sample procedure',
  },
]

export interface ImportControlCSVRow {
  [key: string]: string
}
export const CONTROL_CSV_HEADERS: HeaderValues[] = [
  {
    input: 'Control ID',
    output: 'modelId',
  },
  {
    input: 'Control Name',
    output: 'name',
  },
  {
    input: 'Objective',
    output: 'objective',
  },
  {
    input: 'Notes',
    output: 'notes',
    optional: true,
  },
  {
    input: 'Procedure',
    output: 'procedure',
    optional: true,
  },
  {
    input: 'Policy',
    output: 'policy',
    optional: true,
  },
  {
    input: 'Required Evidence',
    output: 'requiredEvidence',
    optional: true,
  },
  {
    input: 'Test Procedures',
    output: 'testProcedures',
    optional: true,
  },
  {
    input: 'Status',
    output: 'status',
    optional: true,
  },
  {
    input: 'Responsibility',
    output: 'responsibility',
    optional: true,
  },
  {
    input: 'Reason',
    output: 'reason',
    optional: true,
  },
  {
    input: 'Vendor',
    output: 'vendor',
    optional: true,
  },
  {
    input: 'Department',
    output: 'department',
    optional: true,
  },
  {
    input: 'Assignee',
    output: 'assignee',
    optional: true,
  },
]

export const getControlParsers = (
  frameworks: { [key: string]: ComplianceFrameworkGroup },
  policies: string[],
  controlModelIds: string[] = [], // pass in control model ids when you want to ensure no duplicates,
  vendors: VendorRecord[] = [],
): ParseColumnType => {
  const validators: ParseColumnType = {
    modelId: {
      validator: (columnName, value, row) => {
        const lengthValidation = CSV_VALIDATIONS.LENGTH(
          columnName,
          value,
          1,
          10,
          row,
        )
        const valueValidation = CSV_VALIDATIONS.VALUE_NOT_IN_ARRAY(
          value,
          row,
          controlModelIds,
        )

        if (lengthValidation) return lengthValidation
        else if (valueValidation) return valueValidation
        else return ''
      },
      mutator: (value: string) => value.slice(0, 10),
    },
    name: {
      validator: (columnName, value, row) =>
        CSV_VALIDATIONS.LENGTH(columnName, value, 1, 255, row),
      mutator: (value: string) => value.slice(0, 255),
    },
    objective: {
      validator: (_c, _v, _r) => null,
      mutator: (value) => value,
    },
    policy: {
      validator: (_c, value, row) =>
        CSV_VALIDATIONS.VALUE_IN_ARRAY(value, row, [
          NONE_ID,
          ...policies.map((policy) => policy.trim().toLowerCase()),
        ]),
      mutator: (value: string) => {
        if (value.trim().toLowerCase() === NONE_ID) return ''
        return value.slice(0, 255)
      },
    },
    procedure: {
      validator: (_c, _v, _r) => null,
      mutator: (value) => value,
    },
    notes: {
      validator: (_c, _v, _r) => null,
      mutator: (value) => value,
    },
    requiredEvidence: {
      validator: (_c, _v, _r) => null,
      mutator: (value) => value,
    },
    testProcedures: {
      validator: (_c, _v, _r) => null,
      mutator: (value) => value,
    },
    status: {
      validator: (_c, value, row) =>
        CSV_VALIDATIONS.VALUE_IN_ARRAY(
          value,
          row,
          Object.values(ControlStatus),
          Object.values(ControlStatus),
        ),
      mutator: (value) => {
        if (!value) return value

        const lowerCaseLookup = createLowercaseLookupMap(controlStatusToTitle)
        const adjustedValue = value.trim().toLowerCase()
        return adjustedValue in lowerCaseLookup
          ? `${lowerCaseLookup[adjustedValue]}`
          : value
      },
    },
    responsibility: {
      validator: (_c, value, row) =>
        CSV_VALIDATIONS.VALUE_IN_ARRAY(
          value,
          row,
          Object.values(Responsibility),
          Object.values(Responsibility),
        ),
      mutator: (value) => {
        if (!value) return value

        const lowerCaseLookup = createLowercaseLookupMap(
          ResponsibilityToOptions,
        )
        const adjustedValue = value.trim().toLowerCase()
        return adjustedValue in lowerCaseLookup
          ? `${lowerCaseLookup[adjustedValue]}`
          : value
      },
    },
    reason: {
      validator: (_c, _v, _r) => null, // anything goes
      mutator: (value) => value,
    },
    vendor: {
      validator: (_c, value, row) => {
        const vendorNames = vendors.map((vendor) => vendor.getName())
        return CSV_VALIDATIONS.VALUE_IN_ARRAY(
          value,
          row,
          vendorNames,
          vendorNames,
        )
      },
      mutator: (value) => {
        if (!value) return value
        if (value.trim().toLowerCase() === NONE_NAME.toLowerCase()) {
          return ''
        }

        const updatedValue = value.trim().toLowerCase()

        return (
          vendors
            .find((vendor) => vendor.getName().toLowerCase() === updatedValue)
            ?.getId() || ''
        )
      },
    },
  }

  Object.entries(frameworks).forEach(([name, objectives]) => {
    const names: string[] = objectives.objectives.map((objective) => {
      return objective.getName()
    })
    validators[name] = {
      validator: (_c, value, row) =>
        CSV_VALIDATIONS.VALUES_IN_ARRAY(
          value,
          row,
          names,
          (value: string, candidate: string) => {
            return candidate.startsWith(value)
          },
        ),
      mutator: (value) => value,
    }
  })

  return validators
}

export const prepareControlsUpload = (
  complianceFrameworks: string[],
  rows: ImportControlCSVRow[],
): CreateUpdateControlRequest[] => {
  return rows.map((row) => {
    const control = new CreateUpdateControlRequest()
      .setModelId(new StringValue().setValue(row.modelId))
      .setName(new StringValue().setValue(row.name))
      .setObjective(new StringValue().setValue(row.objective))
    if (row.policy) {
      control.setPolicyId(new StringValue().setValue(row.policy))
    }
    if (row.procedure) {
      control.setDescription(new StringValue().setValue(row.procedure))
    }
    if (row.notes) {
      control.setNote(new StringValue().setValue(row.notes))
    }
    if (row.requiredEvidence) {
      control.setRequiredEvidence(
        new StringValue().setValue(row.requiredEvidence),
      )
    }
    if (row.testProcedures) {
      control.setTestProcedures(new StringValue().setValue(row.testProcedures))
    }
    if (row.status) {
      control.setStatus(
        new ControlStatusValue().setValue(
          row.status as unknown as CONTROL_STATUS,
        ),
      )
    }
    if (row.responsibility) {
      control.setResponsibility(
        new ResponsibilityValue().setValue(
          row.responsibility as unknown as RESPONSIBILITY,
        ),
      )
    }
    if (row.reason) {
      control.setNotApplicableReason(new StringValue().setValue(row.reason))
    }
    if (row.vendor) {
      control.setVendorId(new StringValue().setValue(row.vendor))
    }

    control.setComplianceFrameworkIds(
      new StringList().setItemList(
        complianceFrameworks.filter((cFramework) => {
          return cFramework in row && row[cFramework] !== ''
        }),
      ),
    )

    control.setFrameworkIds(
      new StringList().setItemList(
        complianceFrameworks.flatMap((cFramework) => {
          if (cFramework in row && row[cFramework] !== '') {
            return row[cFramework]
              .trim()
              .split(',')
              .map((framework) => {
                return `${cFramework}: ${framework}`
              })
          } else {
            return []
          }
        }),
      ),
    )
    return control
  })
}

export const ERROR_MARKING_CONTROL_NA = 'Error marking control not applicable'
