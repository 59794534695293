import { ErrorSVG, PoliciesSVG } from 'src/components/Icons/Basic'
import styled from 'styled-components/macro'
import { color, compose, textStyle } from 'styled-system'

export const PolicyIcon = styled(PoliciesSVG).attrs({
  mr: 'xxxs',
})<{ $isLarge?: boolean }>`
  margin-bottom: 2px;
  width: ${({ $isLarge }) => ($isLarge ? '16px' : '12px')};
  height: ${({ $isLarge }) => ($isLarge ? '16px' : '12px')};
  filter: grayscale(1);
`

export const PolicyErrorIcon = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
  mr: 'xxxs',
})<{ $isLarge?: boolean }>`
  margin-bottom: 2px;
  width: ${({ $isLarge }) => ($isLarge ? '16px' : '12px')};
  height: ${({ $isLarge }) => ($isLarge ? '16px' : '12px')};
`

export const PolicyTitle = styled.span.attrs<{
  $isError?: boolean
  $isLarge?: boolean
}>(({ $isError }) => ({
  color: $isError ? 'fill.status.error.dark' : 'fill.status.info.extraDark',
  textStyle: 'subtitle.caption',
}))<{ $isError?: boolean; $isLarge?: boolean }>`
  ${compose(color, textStyle)}
  font-size: ${({ $isLarge }) => ($isLarge ? '16px' : '12px')};
`
