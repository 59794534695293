import styled from 'styled-components/macro'
import { GridHeader } from '../../../../components/Reusable/Grid'

export const ControlsGridHeader = styled(GridHeader)<{ $color?: string }>`
  min-width: 85px;
  position: sticky;
  top: ${(p) => p.top || 0}px;
  justify-content: ${(p) => p.justifyContent || 'flex-start'};
  z-index: 1;

  ${({ $color }) => $color && `color: ${$color}`}
`

export const FrameworksHeader = styled(ControlsGridHeader)`
  justify-content: center;
`

export const EvidenceHeader = styled(ControlsGridHeader)`
  flex-direction: column;
  justify-content: center;
`

export const EvidenceSubHeaders = styled.div`
  display: flex;
  justify-content: space-evenly;
  > div {
    display: flex;
    justify-content: center;
    width: 120px;
  }
`

export const EvidenceTitle = styled.p`
  width: 70px;
  text-align: center;
`

export const StatusHeader = styled(ControlsGridHeader)`
  min-width: 176px;
  justify-content: center;
`

export const SpinnerContainer = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;
  left: 0;
  right: 0;
  text-align: center;
  div {
    width: ${(p) => (p.isFullWidth ? '100%' : '')};
  }
`
