import React, { ReactChild } from 'react'
import styled from 'styled-components/macro'
import {
  border,
  color,
  compose,
  flex,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textStyle,
} from 'styled-system'

export type BasicChipProps = LayoutProps &
  FlexboxProps &
  SpaceProps & { maxWidth?: string }

export function BasicChip({
  maxWidth,
  children,
  ...restProps
}: BasicChipProps & { children: ReactChild }): JSX.Element {
  return (
    <BasicChipContainer {...{ maxWidth, ...restProps }}>
      <span>{children}</span>
    </BasicChipContainer>
  )
}

const BasicChipContainer = styled.div.attrs<BasicChipProps>({
  px: 'xs',
  border: '1px solid',
  borderRadius: '2px',
  borderColor: 'border.neutral.light',
  bg: 'white',
  color: 'text.default',
  textStyle: 'body.small',
})<BasicChipProps>`
  ${compose(border, color, textStyle)}
  display: flex;
  align-items: center;
  ${flex}
  max-width: 160px;
  height: 24px;
  ${compose(layout, space)}
  &, > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
