import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import { useNavigate } from 'react-router-dom'
import { useDeleteRequestModal } from 'src/pages/Requests/DeleteRequestModal/DeleteRequestModal'
import { DropdownToggleButton } from '../../../../../components/Reusable/Buttons'
import { ReactComponent as VerticalEllipsesIcon } from '../../../../../components/Icons/assets/vertical-ellipses-icon.svg'

export const RequestItemMenu = ({
  request,
  permalink,
}: {
  request: DocumentRequest
  permalink: string
}): JSX.Element => {
  const requestId = request.getId()
  const navigate = useNavigate()

  const deleteRequest = useDeleteRequestModal({ request })

  const onViewDetails = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    navigate(permalink)
  }

  return (
    <Dropdown id={`requests-index-menu-${requestId}`}>
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" as="button" onClick={onViewDetails}>
          View Details
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          as="button"
          onClick={(e) => {
            e.preventDefault()
            deleteRequest()
          }}
        >
          Delete Request
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
