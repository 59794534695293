import React, { forwardRef } from 'react'
import FormCheck, { FormCheckProps } from 'react-bootstrap/FormCheck'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import styled from 'styled-components/macro'

export const StyledCheckInput = styled(FormCheck)`
  &.form-check {
    display: flex;
    align-items: center;
    padding: 0;

    &.form-check-inline {
      display: inline-flex;
    }

    .form-check-input {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      margin: 0;
      margin-right: 8px;
    }

    .form-check-label {
      flex-grow: 1;
    }
  }
`

// Workaround for React 18 compatibility issues https://github.com/styled-components/styled-components/issues/3738
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CheckInputProxy: any = StyledCheckInput

type CheckInputProps = {
  requiredPermissions?: PERMISSIONS[]
} & Omit<FormCheckProps, 'type'>

/**
 * ! IMPORTANT DETAILS FOR THE `onChange` event handler
 * - DO NOT call the event's `preventDefault` method in the handler
 * - Doing so will cause issues when clicking the label
 *   - It may not properly check / uncheck the input
 */
export const CheckBoxInput = forwardRef<HTMLInputElement, CheckInputProps>(
  (props, ref) => {
    const requiredPermissions = props.requiredPermissions || [
      PERMISSIONS.READ,
      PERMISSIONS.EDIT,
    ]
    const hasRequiredPermissions =
      useHasRequiredPermissions(requiredPermissions)

    return (
      <CheckInputProxy
        ref={ref}
        {...props}
        disabled={!hasRequiredPermissions || props.disabled}
      />
    )
  },
)
