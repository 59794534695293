import React, { useReducer, useEffect, useState } from 'react'
import {
  GRID_COL_SORT_CONFIGS_CONTROLS,
  GridColumnSortActionControls,
  GridColumnSortControls,
  GridColumnSortControlsProps,
  GridColumnSortPolicies,
  GridColumnSortType,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { ControlsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useUrlSearchParams } from 'src/Utils/helpers/navigation'
import {
  getColSortIcon,
  getNextColSortType,
  gridColSortReducerControls,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.utils'
import palette from 'src/designSystem/variables/palette'
import { FlexAlign } from 'src/components/Reusable/Flex'
import { useListControlIds } from 'src/components/async/model/control/useListControlIds'
import { GridColumnSort } from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort'
import { CONTROL_TEST_NAMES } from 'src/pages/AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'
import { useControlRequests } from '../ControlsIndexPage.utils'
import {
  ControlsGridHeader,
  EvidenceHeader,
  EvidenceSubHeaders,
  EvidenceTitle,
  FrameworksHeader,
  StatusHeader,
} from './ControlsIndexGrid.styles'

export const ControlsGridColumnSort = ({
  type,
  isActive,
  colName,
  onSort,
}: GridColumnSortControlsProps): JSX.Element | null => {
  const { controls: listCtrlReq } = useControlRequests()
  const { loading } = useListControlIds(listCtrlReq)
  const [showSpinner, setShowSpinner] = useState<boolean>(loading && isActive)
  const currIcon = getColSortIcon(type, isActive)

  useEffect(() => {
    showSpinner && isActive && setShowSpinner(loading)
  }, [showSpinner, isActive, loading])

  const applySorting = (e: React.MouseEvent) => {
    e.preventDefault()
    if (loading) {
      return
    }
    setShowSpinner(true)
    onSort({ colName, type: getNextColSortType(type) })
  }

  return (
    <GridColumnSort {...{ colName, showSpinner, currIcon, applySorting }} />
  )
}

const SortControlsComponent = ({
  column,
}: {
  column: GridColumnSortControls
}): JSX.Element => {
  const { searchParams, updateUrlParams } = useUrlSearchParams()
  const sortBy =
    (searchParams.sort_by && (searchParams.sort_by[0] as GridColumnSortType)) ||
    GridColumnSortType.ASCENDING
  const sortCol: GridColumnSortControls | GridColumnSortPolicies =
    (searchParams.sort_col &&
      (searchParams.sort_col[0] as GridColumnSortControls)) ||
    GridColumnSortControls.ID
  const [columnMapState, dispatch] = useReducer(
    gridColSortReducerControls,
    GRID_COL_SORT_CONFIGS_CONTROLS(sortBy, sortCol),
  )

  const fetchSortedControls = (action: GridColumnSortActionControls) => {
    dispatch(action)
    updateUrlParams({ sort_by: [action.type], sort_col: [action.colName] })
  }

  return (
    <ControlsGridColumnSort
      type={columnMapState[column].type}
      isActive={columnMapState[column].isActive}
      colName={column}
      onSort={fetchSortedControls}
    />
  )
}

export const SortControls = ({
  column,
}: {
  column: GridColumnSortControls
}): JSX.Element => {
  const isControlsPage = location.pathname.includes(
    ControlsAbsoluteRoutes.INDEX,
  )

  return isControlsPage ? <SortControlsComponent column={column} /> : <></>
}

export const ControlsIndexGridDepartmentHeader = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <ControlsGridHeader top={top}>
    Dept
    <SortControls column={GridColumnSortControls.DEPARTMENT} />
  </ControlsGridHeader>
)

export const ControlsIndexGridIdHeader = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <ControlsGridHeader top={top}>
    ID
    <SortControls column={GridColumnSortControls.ID} />
  </ControlsGridHeader>
)

export const ControlsIndexGridNameHeader = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <ControlsGridHeader top={top}>
    Name
    <SortControls column={GridColumnSortControls.NAME} />
  </ControlsGridHeader>
)

export const ControlsIndexGridFrameworksHeader = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <FrameworksHeader top={top}>Frameworks Using This</FrameworksHeader>
)

export const ControlsIndexGridEvidenceHeader = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <EvidenceHeader top={top}>
    <EvidenceSubHeaders>
      <div>
        <EvidenceTitle>Automated Evidence</EvidenceTitle>
        <SortControls column={GridColumnSortControls.AUTOMATED_EVIDENCE} />
      </div>
      <div>
        <EvidenceTitle>Manual Evidence</EvidenceTitle>
        <SortControls column={GridColumnSortControls.MANUAL_EVIDENCE} />
      </div>
    </EvidenceSubHeaders>
  </EvidenceHeader>
)

export const ControlsIndexGridStatusHeader = ({
  top,
}: {
  top: number
}): JSX.Element => <StatusHeader top={top}>Status</StatusHeader>

export const ControlsIndexGridAssigneeHeader = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <ControlsGridHeader top={top} justifyContent="center">
    Assignee
  </ControlsGridHeader>
)

export const ControlIndexGridChecksHeaders = ({
  top,
}: {
  top: number
}): JSX.Element => (
  <>
    <ControlsGridHeader
      $color={palette.purple['600']}
      justifyContent={FlexAlign.CENTER}
      top={top}
    >
      {CONTROL_TEST_NAMES.POLICY_MATCH}
      <SortControls column={GridColumnSortControls.POLICY_MATCH} />
    </ControlsGridHeader>
    <ControlsGridHeader
      $color={palette.purple['600']}
      justifyContent={FlexAlign.CENTER}
      top={top}
    >
      {CONTROL_TEST_NAMES.COMPLETENESS}
      <SortControls column={GridColumnSortControls.COMPLETENESS} />
    </ControlsGridHeader>
    <ControlsGridHeader
      $color={palette.purple['600']}
      justifyContent={FlexAlign.CENTER}
      top={top}
    >
      {CONTROL_TEST_NAMES.SPOT_CHECK}
      <SortControls column={GridColumnSortControls.SPOT_CHECK} />
    </ControlsGridHeader>
  </>
)
