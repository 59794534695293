import styled, { keyframes } from 'styled-components/macro'
import {
  border,
  buttonStyle,
  compose,
  flexbox,
  grid,
  layout,
  margin,
  position,
  space,
  variant,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import palette from '../../../../designSystem/variables/palette'
import { StandardButtonProps } from '../../Buttons'

const fadeIn = keyframes`
0% { opacity: 0 }
100% { opacity: 1 }
`

export const TabPanelHeightDiv = styled.div`
  transition: height 0.3s linear;
  width: 100%;
`

export const TabPanelNoteItem = styled.div`
  margin-bottom: 24px;
  flex-grow: 1;
`

export const TabNoteContent = styled.div.attrs({
  p: 'l',
  borderColor: 'fill.quaternary.base',
})`
  ${compose(space, border)}
  opacity: 0;
  flex-grow: 1;
  border: 1px solid;
  border-radius: 6px;
  animation: ${fadeIn} 0.7s linear 0.3s 1 normal forwards;
  width: 100%;
`

export const TabPanelNoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TabPanelButtonGroup = styled.div.attrs({
  my: 'xs',
})`
  ${margin}
  display: inline-flex;
  align-items: center;

  > * :not(:last-child) {
    margin-right: ${themeGet('space.m')}px;
  }
`

const buttonSize = variant({
  prop: 'buttonSize',
  variants: {
    small: {
      height: '32px',
      px: 's',
      fontSize: '1',
      fontWeight: 'semiBold',
    },
    medium: {
      height: '40px',
      px: 'm',
      fontSize: '3',
      fontWeight: 'semiBold',
    },
    large: {
      height: '48px',
      px: 'l',
      fontSize: '4',
      fontWeight: 'semiBold',
    },
  },
})

export const SuggestionButton = styled.button.attrs<StandardButtonProps>(
  ({ display, alignItems, justifyContent }) => ({
    display: display || 'flex',
    alignItems: alignItems || 'center',
    justifyContent: justifyContent || 'center',
  }),
)<StandardButtonProps>`
  ${compose(buttonStyle, buttonSize)}
  ${compose(layout, flexbox, grid, position, space)}

  ${(props) => {
    if (Array.isArray(props.children) && props.children.length > 1)
      return `
      > :first-child {
        margin-right: ${themeGet('space.xs')(props)}px;
      }
    `
    return ''
  }}
  color: ${palette.purple['200']};
  border: 2px solid ${palette.purple['200']};
  margin-top: 2px;
`
