import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { LINKER_MODEL_IDS } from 'src/lib/Linkers/Linkers.constants'
import { LinkerError } from 'src/lib/Linkers/Linkers.components'
import { FileSelector } from 'src/components/Reusable/Forms/FileSelector/FileSelector'
import { stripFileExtension } from 'src/components/Reusable/Forms/FileSelector/FileSelector.helpers'
import { TextButton } from '../../Reusable/Buttons/TextButton'
import { DocumentFormData, DocumentType } from './AddDocumentForm'
import {
  PolicyDocGoogleDriveButton,
  PolicyDocLinkTypeButton,
} from './AddDocumentForm.components'

type DocumentTypeSelectorProps = {
  setFormData: Dispatch<SetStateAction<DocumentFormData>>
  type: DocumentType
  setType: Dispatch<SetStateAction<DocumentType>>
  hide: () => void
  policyId: string
}

export const DocumentTypeSelector = ({
  setFormData,
  type,
  setType,
  hide,
  policyId,
}: DocumentTypeSelectorProps): JSX.Element => {
  const [gdriveError, setGdriveError] = useState<string>('')

  const clearText = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      setFormData((state) => {
        const newState: DocumentFormData = {
          ...state,
          mime: '',
          body: '',
          caption: '',
        }
        return newState
      })
      if (type !== DocumentType.LINK) setType(DocumentType.NULL)
    },
    [setFormData, type, setType],
  )

  if (type !== DocumentType.NULL) {
    return <TextButton onClick={clearText}>Clear</TextButton>
  }

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) return
    const file = files[0]
    const fileType = file.type

    setFormData((state) => {
      const newState: DocumentFormData = {
        ...state,
        mime: fileType,
        body: file,
        caption: stripFileExtension(file.name),
      }
      return newState
    })
    setType(DocumentType.FILE)
  }

  return (
    <>
      <FileSelector
        alternateTypeOptions={[
          <PolicyDocLinkTypeButton
            setFormData={setFormData}
            setType={setType}
            key={'link'}
          />,
          <PolicyDocGoogleDriveButton
            policyId={policyId}
            hide={hide}
            setGdriveError={setGdriveError}
            key={'gdrive'}
          />,
        ]}
        onChange={handleFileChange}
        isLoading={false}
        isValid={true}
        errorMessage={''}
      />
      {gdriveError && (
        <LinkerError
          errMsg={gdriveError}
          linkerId={LINKER_MODEL_IDS.GOOGLE_DRIVE}
        />
      )}
    </>
  )
}
