import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  ControlsAbsoluteRoutes,
  PoliciesAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { generatePermalinkFromId } from '../../../components/PageLayout/Permalink'
import { useControl } from '../../../components/async/model'
import { UrlFragments } from '../../../Utils/globalEnums'
import { ControlGridRow } from './ControlsListItem.styles'
import {
  ControlsListItemAssigneeColumn,
  ControlsListItemCheckColumns,
  ControlsListItemControlNameColumn,
  ControlsListItemDepartmentColumn,
  ControlsListItemEvidenceColumn,
  ControlsListItemFrameworksColumn,
  ControlsListItemMenuColumn,
  ControlsListItemModelIdColumn,
  ControlsListItemStatusColumn,
} from './ControlsListItem.components'
import { ControlsListItemProps } from './ControlListItem.constants'
import { getControlsListItemConfig } from './ControlsListItem.helpers'

export const ControlsListItem = ({
  controlModelId,
  audit,
  config,
}: ControlsListItemProps): JSX.Element => {
  const params = useParams()
  const { data, isLoading, mutate } = useControl(controlModelId)

  if (!data || isLoading) {
    return <></>
  }

  const control = data.toObject()
  const {
    id: controlId,
    modelId,
    name,
    departmentId,
    complianceFrameworkIdsList,
    objective,
    status,
    policyId: policyModelId,
  } = control

  const isNotApplicable = status === CONTROL_STATUS.NOTAPPLICABLE

  const isFromControlsIndex = location.pathname.includes(
    ControlsAbsoluteRoutes.INDEX,
  )
  const isFromPolicyShow = location.pathname.includes(
    PoliciesAbsoluteRoutes.SHOW,
  )
  const includeSearchParams = isFromControlsIndex || isFromPolicyShow
  const search = isFromPolicyShow
    ? {
        [FilterParam.POLICY]: [policyModelId],
      }
    : undefined

  let toLink = generatePermalinkFromId({
    pageContext: params.pageContext as string,
    modelId,
    isInternalLink: true,
    includeSearchParams,
    search,
  })

  if (isFromPolicyShow) {
    toLink += `#${UrlFragments.POLICIES}`
  }

  const {
    showDepartmentColumn,
    showModelIdColumn,
    showControlNameColumn,
    showFrameworkColumn,
    showCheckColumns,
    showEvidenceColumns,
    showStatusColumn,
    showAssigneeColumn,
    showMenuColumn,
    $isPurple,
    customNameSuffix,
  } = getControlsListItemConfig(config)

  return (
    <ControlGridRow
      id={`controls-index-item-${modelId}`}
      as={Link}
      to={toLink}
      $isNotApplicable={isNotApplicable}
      $isPurple={$isPurple}
    >
      {showDepartmentColumn && (
        <ControlsListItemDepartmentColumn
          show={!isNotApplicable}
          departmentId={departmentId}
        />
      )}
      {showModelIdColumn && <ControlsListItemModelIdColumn modelId={modelId} />}
      {showControlNameColumn && (
        <ControlsListItemControlNameColumn
          controlName={!customNameSuffix ? name : `${name} ${customNameSuffix}`}
          policyModelId={policyModelId}
          objective={objective}
          isControlNa={isNotApplicable}
        />
      )}
      {showFrameworkColumn && (
        <ControlsListItemFrameworksColumn
          audit={audit}
          complianceFrameworkIds={complianceFrameworkIdsList}
        />
      )}
      {showCheckColumns && (
        <ControlsListItemCheckColumns
          controlId={controlId}
          isNotApplicable={isNotApplicable}
        />
      )}
      {showEvidenceColumns && (
        <ControlsListItemEvidenceColumn
          show={!isNotApplicable}
          controlId={controlId}
        />
      )}
      {showStatusColumn && (
        <ControlsListItemStatusColumn control={control} mutate={mutate} />
      )}
      {showAssigneeColumn && (
        <ControlsListItemAssigneeColumn control={control} mutate={mutate} />
      )}
      {showMenuColumn && (
        <ControlsListItemMenuColumn control={control} config={config} />
      )}
    </ControlGridRow>
  )
}
