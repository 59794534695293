import React from 'react'
import { Editor, EditorState, RichUtils } from 'draft-js'
import {
  faBold,
  faItalic,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons'
import { ToolbarGroup } from '../styles'
import StyleButton from './StyleButton'

/**
 * @param {object}      props
 * * `editorState`
 * * `setEditorState`
 * @param {EditorState} props.editorState
 */

type InlineStyleControls = {
  editorRef: React.RefObject<Editor>
  editorState: EditorState
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
}

export default function InlineStyleControls({
  editorRef,
  editorState,
  setEditorState,
}: InlineStyleControls): JSX.Element {
  const INLINE_STYLES = [
    { label: 'bold', inlineStyle: 'BOLD', icon: faBold },
    { label: 'italic', inlineStyle: 'ITALIC', icon: faItalic },
    { label: 'underline', inlineStyle: 'UNDERLINE', icon: faUnderline },
  ]

  const currInlineStyle = editorState.getCurrentInlineStyle()
  const toggleInlineStyle =
    (inlineStyle: string): React.MouseEventHandler =>
    (e) => {
      e.preventDefault()
      editorRef?.current?.focus()
      setEditorState((editorState) =>
        RichUtils.toggleInlineStyle(editorState, inlineStyle),
      )
    }

  return (
    <ToolbarGroup>
      {INLINE_STYLES.map(({ label, inlineStyle, icon }) => (
        <StyleButton
          key={label}
          isActive={currInlineStyle.has(inlineStyle)}
          toggleFn={toggleInlineStyle(inlineStyle)}
          icon={icon}
        />
      ))}
    </ToolbarGroup>
  )
}
