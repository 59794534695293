import styled from 'styled-components/macro'

export const InfoPanelContainer = styled.div<{ isShown: boolean }>`
  display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 360px;
  height: 100vh;
  background-color: white;
  color: #1f2532;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  border-left: 2px solid #cbd4de;
`
