import { RoadmapServicePromiseClient } from '@trustero/trustero-api-web/lib/roadmap/roadmap_grpc_web_pb'
import {
  GetRoadmapWidgetCountsRequest,
  GetRoadmapWidgetCountsResponse,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { ROADMAP_WIDGET_REQUEST_CONFIG } from './RoadmapWidgets.constants'
import { buildRoadmapWidgetRequest } from './RoadmapWidgets.helpers'

/**
 *
 * This hook grabs the counts for the roadmap widgets. It takes in optional auditId and ownerEmail parameters.
 * On the dashboard, the auditId will be undefined in continuous compliance mode. The ownerEmail will be passed in on
 * the dashboard, as well, to reflect the correct counts when a user is viewing work assigned to them.
 *
 * @param {string} auditId - The auditId for the audit, if in audit view or on the roadmap page.
 * @param {string} ownerEmail - The user's email, if on the dashboard and viewing work assigned to them.
 *
 * @returns {GrpcResponse<GetRoadmapWidgetCountsResponse>} A gRPC response object containing the counts for the widgets.
 *
 */
export const useRoadmapWidgetCounts = ({
  auditId,
  ownerEmail,
  requestConfig,
}: {
  auditId?: string
  ownerEmail?: string
  requestConfig: ROADMAP_WIDGET_REQUEST_CONFIG | null
}): GrpcResponse<GetRoadmapWidgetCountsResponse> => {
  const request = new GetRoadmapWidgetCountsRequest()
  requestConfig && buildRoadmapWidgetRequest(requestConfig, request)
  auditId && request.setAuditId(new StringValue().setValue(auditId))
  ownerEmail && request.setOwnerEmail(new StringValue().setValue(ownerEmail))
  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getRoadmapWidgetCounts,
    request,
  )

  return NewGrpcResponse(response)
}
