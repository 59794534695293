import React from 'react'
import { Department } from '@trustero/trustero-api-web/lib/model/department_pb'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '../../../Reusable/StandardDropdown/StandardDropdown.styles'
import { MenuPlaceholder } from '../../../Placeholders/MenuPlaceholder'
import { useDepartments } from '../../../async/Department/useDepartments'

export function DepartmentDropdown({
  selectedItem,
  onDepartmentSelected,
}: {
  selectedItem: string
  onDepartmentSelected: (
    department: Department.AsObject,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element {
  return (
    <Dropdown id="add-model-form-department-dropdown" drop="down">
      <Dropdown.Toggle as={StyledDropdownToggle} width="100%">
        {selectedItem}
      </Dropdown.Toggle>
      <DepartmentDropdownMenu onDepartmentSelected={onDepartmentSelected} />
    </Dropdown>
  )
}

function DepartmentDropdownMenu({
  onDepartmentSelected,
}: {
  onDepartmentSelected: (
    p: Department.AsObject,
  ) => React.MouseEventHandler<HTMLElement>
}): JSX.Element {
  const departments = useDepartments()

  if (departments === undefined) {
    return (
      <Dropdown.Menu as={StyledDropdownMenu}>
        <MenuPlaceholder height={96} />
      </Dropdown.Menu>
    )
  }

  return (
    <Dropdown.Menu as={StyledDropdownMenu}>
      {departments.map((department) => (
        <Dropdown.Item
          key={department.getModelId()}
          id={department.getModelId()}
          eventKey={department.getModelId()}
          onClick={onDepartmentSelected(department.toObject())}
        >
          {department.getName()}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  )
}
