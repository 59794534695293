import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { ServicesShowPage } from './ServicesShowPage'

export const ServicesShowPageRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<ServicesShowPage />} />
      <Route path="*" element={<Navigate replace to="./" />} />
    </Routes>
  )
}
