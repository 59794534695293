import React from 'react'
import { useControls } from 'src/components/async/model/control'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

export const ControlFilterDropdown = (): JSX.Element => {
  const { data, isLoading, error } = useControls()
  if (error) return <></>
  const controls = data?.getItemsList() || []
  const filterValues = controls.map((control) => ({
    value: control.getId(),
    label: `${control.getModelId()} ${control.getName()}`,
  }))

  return (
    <FilterDropdown
      filterParam={FilterParam.CONTROL}
      filterName={FilterName.CONTROL}
      filterValues={filterValues}
      isLoading={!data || isLoading}
    />
  )
}
