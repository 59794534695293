import React, { useEffect } from 'react'
import { useAnalytics } from 'src/analytics/useAnalytics'
import {
  CloseButton,
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../components/Reusable/Buttons'
import {
  ModalBody,
  ModalButtonGroup,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../Modal/styles'
import {
  initialConfirmationState,
  useConfirmationContext,
} from './ConfirmationContext'

export const Confirmation = (): JSX.Element => {
  const { confirmationState, setConfirmationState } = useConfirmationContext()
  const { track } = useAnalytics()

  const close = () => {
    confirmationState.onCancel && confirmationState.onCancel()
    setConfirmationState(initialConfirmationState)
  }

  const confirm = () => {
    confirmationState.onConfirmCB()
    setConfirmationState(initialConfirmationState)
  }

  useEffect(() => {
    if (confirmationState.isShown && confirmationState.analyticsEvent) {
      track(
        confirmationState.analyticsEvent,
        confirmationState.analyticsEventData,
      )
    }
  }, [
    confirmationState.isShown,
    confirmationState.analyticsEvent,
    confirmationState.analyticsEventData,
    track,
  ])

  return (
    <ModalContainer
      show={confirmationState.isShown}
      centered
      size={confirmationState.size || 'sm'}
      backdrop="static"
      onHide={close}
    >
      <CloseButton
        position="absolute"
        top="s"
        right="s"
        onClick={(e) => {
          e.preventDefault()
          close()
        }}
      />

      <ModalHeader>
        <ModalTitle>{confirmationState.title}</ModalTitle>
      </ModalHeader>

      <ModalBody textStyle="body.default">{confirmationState.body}</ModalBody>

      <ModalFooter>
        {confirmationState.footer ?? <></>}
        <ModalButtonGroup>
          {!confirmationState.hideCancel && (
            <StandardButton
              variant={StandardButtonVariant.SECONDARY}
              buttonSize={StandardButtonSize.MEDIUM}
              onClick={(e) => {
                e.preventDefault()
                close()
              }}
            >
              {confirmationState.cancelText || 'Cancel'}
            </StandardButton>
          )}
          <StandardButton
            variant={StandardButtonVariant.PRIMARY}
            buttonSize={StandardButtonSize.MEDIUM}
            onClick={(e) => {
              e.preventDefault()
              confirm()
            }}
          >
            {confirmationState?.confirmText || 'Confirm'}
          </StandardButton>
        </ModalButtonGroup>
      </ModalFooter>
    </ModalContainer>
  )
}
