import React from 'react'
import { useReadonlyAccounts } from 'src/components/async/Account/account.hooks'
import { useAuthContext, useIsReadOnlyUser } from 'src/context/authContext'
import { Banner } from './Banner'

export const AccountPurposeBanner = ({
  noMargin,
}: {
  noMargin?: boolean
}): JSX.Element => {
  const isReadonly = useIsReadOnlyUser()
  const { authCtx } = useAuthContext()
  const { data: readonlyAccounts } = useReadonlyAccounts()

  // if the user is NOT readonly but in a "Trustero Demo" readonly account
  // show a banner so the user knows that anyone can see the data in that account
  const showBanner =
    !isReadonly &&
    readonlyAccounts
      ?.getAccountsList()
      .map((ele) => ele.getId())
      .includes(authCtx.accountId)

  if (!showBanner) return <></>

  const bannerMessage = `You are currently in a Trustero Demo account. Any changes you make will be visible to all users.`

  return <Banner noMargin={noMargin} text={bannerMessage} isWarning />
}
