import { EvidenceGenerator } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import React from 'react'
import { Grid, GridColumn, GridRow } from 'src/components/Reusable/Grid'
import { ReceptorLogo } from 'src/components/async/model/receptor'
import { CUSTOM_RECEPTOR_ID } from 'src/Utils/Receptors/receptors.constants'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { DisableConnectionButton } from './DisableConnectionButton'

export const EvidenceDisplayName = (
  evidenceId: string,
  receptorModelId: string,
): string => {
  if (receptorModelId === CUSTOM_RECEPTOR_ID) {
    return `Custom Receptor: ${evidenceId}`
  }
  return formatEvidenceCaption(evidenceId, true)
}

export const EvidenceGeneratorGrid = ({
  evidenceGenerators,
  controlId,
}: {
  evidenceGenerators: EvidenceGenerator[]
  controlId?: string
}): JSX.Element => {
  return (
    <Grid gridTemplateColumns="min-content 1fr min-content">
      {evidenceGenerators.map((p) => (
        <GridRow key={p.getId()}>
          <GridColumn>
            <ReceptorLogo
              receptorModelId={p.getReceptorModelId()}
              width={'24px'}
              height={'24px'}
            />
          </GridColumn>
          <GridColumn>
            {EvidenceDisplayName(p.getEvidenceId(), p.getReceptorModelId())}
          </GridColumn>
          <GridColumn>
            {controlId && (
              <DisableConnectionButton
                controlId={controlId}
                evidenceGeneratorId={p.getId()}
              />
            )}
          </GridColumn>
        </GridRow>
      ))}
    </Grid>
  )
}
