/**
 * @fileoverview gRPC-Web generated client stub for model
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: model/stats.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.model = require('./stats_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.ModelStatsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.ModelStatsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListControlComplianceStatsRequest,
 *   !proto.model.ListControlComplianceStatsResponse>}
 */
const methodDescriptor_ModelStatsService_ListControlComplianceStats = new grpc.web.MethodDescriptor(
  '/model.ModelStatsService/ListControlComplianceStats',
  grpc.web.MethodType.UNARY,
  proto.model.ListControlComplianceStatsRequest,
  proto.model.ListControlComplianceStatsResponse,
  /**
   * @param {!proto.model.ListControlComplianceStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ListControlComplianceStatsResponse.deserializeBinary
);


/**
 * @param {!proto.model.ListControlComplianceStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ListControlComplianceStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ListControlComplianceStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelStatsServiceClient.prototype.listControlComplianceStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ModelStatsService/ListControlComplianceStats',
      request,
      metadata || {},
      methodDescriptor_ModelStatsService_ListControlComplianceStats,
      callback);
};


/**
 * @param {!proto.model.ListControlComplianceStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ListControlComplianceStatsResponse>}
 *     Promise that resolves to the response
 */
proto.model.ModelStatsServicePromiseClient.prototype.listControlComplianceStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ModelStatsService/ListControlComplianceStats',
      request,
      metadata || {},
      methodDescriptor_ModelStatsService_ListControlComplianceStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetTrusteroScoreRequest,
 *   !proto.model.GetTrusteroScoreResponse>}
 */
const methodDescriptor_ModelStatsService_GetTrusteroScore = new grpc.web.MethodDescriptor(
  '/model.ModelStatsService/GetTrusteroScore',
  grpc.web.MethodType.UNARY,
  proto.model.GetTrusteroScoreRequest,
  proto.model.GetTrusteroScoreResponse,
  /**
   * @param {!proto.model.GetTrusteroScoreRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.GetTrusteroScoreResponse.deserializeBinary
);


/**
 * @param {!proto.model.GetTrusteroScoreRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.GetTrusteroScoreResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.GetTrusteroScoreResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelStatsServiceClient.prototype.getTrusteroScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ModelStatsService/GetTrusteroScore',
      request,
      metadata || {},
      methodDescriptor_ModelStatsService_GetTrusteroScore,
      callback);
};


/**
 * @param {!proto.model.GetTrusteroScoreRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.GetTrusteroScoreResponse>}
 *     Promise that resolves to the response
 */
proto.model.ModelStatsServicePromiseClient.prototype.getTrusteroScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ModelStatsService/GetTrusteroScore',
      request,
      metadata || {},
      methodDescriptor_ModelStatsService_GetTrusteroScore);
};


module.exports = proto.model;

