import React from 'react'
import { FlexRow } from 'src/components/Reusable/Flex'
import {
  TooltipPositions,
  TooltipOverlayType,
  Tooltip,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { AUDIT_RESULT } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import {
  CenteredGridColumn,
  NoPaddingGridColumn,
} from 'src/components/Reusable/Grid/Grid.styles'
import { TitleText } from 'src/components/Reusable/Text/Title'
import { KeyedMutator } from 'swr'
import { ControlPolicyInfo } from 'src/components/Reusable/ListItem/ListItem.components'
import { useGetControlChecks } from 'src/pages/Dashboard/ControlChecksDashboardWidget/ControlChecksWidget.hooks'
import { CONTROL_TEST_NAMES } from 'src/pages/AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'
import { LIST_ITEM_COPY } from 'src/components/Reusable/ListItem/ListItem.constants'
import { ControlStatusDropdownWithUpdate } from 'src/components/PageLayout/ShowPage/Dropdowns/ControlStatusDropdown'
import { GridColumn } from '../../../components/Reusable/Grid'
import { DepartmentChip } from '../DepartmentChip'
import { ModelComplianceFrameWorkInfo } from '../../../components/Reusable/Text/ModelComplianceFrameworkInfo'
import { useComplianceFrameworks } from '../../../components/async/complianceframework/useComplianceFrameworks'
import { MODEL_TYPES } from '../../../Utils/globalEnums'
import { StyledModelId } from '../ControlsShowPage/ControlsShowPage.styles'
import { OwnerAssignmentButton } from './OwnerAssignmentButton'
import { DescriptionMarkup } from './DescriptionMarkup'
import { EvidenceCounts } from './EvidenceCounts'
import { ControlsListItemMenu } from './ControlsListItemMenu/ControlsListItemMenu'
import {
  EvidenceGridColumn,
  PolicyGridColumn,
  StyledIsStaleResult,
} from './ControlsListItem.styles'
import {
  getCheckResultConfig,
  getComplianceFrameworksForControl,
  getControlsListItemConfig,
  getMismatch,
  getNoCheckResultConfig,
} from './ControlsListItem.helpers'
import {
  ControlCheckConfigType,
  ControlListItemConfig,
} from './ControlListItem.constants'

const MAX_MODEL_ID_LENGTH = 10

export const ControlModelId = ({
  modelId,
}: {
  modelId: string
}): JSX.Element => {
  return modelId.length > MAX_MODEL_ID_LENGTH ? (
    <Tooltip
      id={`controls-index-row-modelid-tooltip-${modelId}`}
      placement={TooltipPositions.top}
      overlayType={TooltipOverlayType.popover}
      tooltipBody={modelId}
    >
      <StyledModelId>{modelId}</StyledModelId>
    </Tooltip>
  ) : (
    <>{modelId}</>
  )
}

export const ControlChecksListItemNoTestResult = ({
  controlId,
  isNotApplicable,
}: {
  controlId: string
  isNotApplicable: boolean
}): JSX.Element => {
  const checkConfig = getNoCheckResultConfig(controlId, isNotApplicable)
  return (
    <ControlsChecksListItemTestResultContainer config={checkConfig}>
      {checkConfig.icon}
    </ControlsChecksListItemTestResultContainer>
  )
}

export const ControlChecksListItemTestResult = ({
  controlId,
  result,
  isStale,
}: {
  controlId: string
  result: AUDIT_RESULT
  isStale: boolean
}): JSX.Element => {
  const checkConfig = getCheckResultConfig(controlId, result, isStale)
  return (
    <ControlsChecksListItemTestResultContainer config={checkConfig}>
      {checkConfig.icon}
    </ControlsChecksListItemTestResultContainer>
  )
}

const ControlsChecksListItemTestResultContainer = ({
  config,
  children,
}: {
  config: ControlCheckConfigType
  children: JSX.Element
}) => (
  <Tooltip
    id={config.tooltipId}
    placement={TooltipPositions.left}
    overlayType={TooltipOverlayType.popover}
    tooltipBody={config.tooltipBody}
  >
    {config.isStale ? (
      <StyledIsStaleResult>{children}</StyledIsStaleResult>
    ) : (
      <FlexRow isFullWidth isFullHeight>
        {children}
      </FlexRow>
    )}
  </Tooltip>
)

export const ControlsListItemMenuColumn = ({
  control,
  config,
}: {
  control: Control.AsObject
  config?: ControlListItemConfig
}): JSX.Element => {
  const menuConfig = getControlsListItemConfig(config)
  return (
    <CenteredGridColumn>
      {menuConfig?.customMenu ? (
        <menuConfig.customMenu control={control} />
      ) : (
        <ControlsListItemMenu control={control} />
      )}
    </CenteredGridColumn>
  )
}

export const ControlsListItemAssigneeColumn = ({
  control,
  mutate,
}: {
  control: Control.AsObject
  mutate: KeyedMutator<Control>
}): JSX.Element => (
  <CenteredGridColumn>
    <OwnerAssignmentButton control={control} mutate={mutate} />
  </CenteredGridColumn>
)

export const ControlsListItemStatusColumn = ({
  control,
  mutate,
}: {
  control: Control.AsObject
  mutate: KeyedMutator<Control>
}): JSX.Element => (
  <CenteredGridColumn>
    <ControlStatusDropdownWithUpdate control={control} mutate={mutate} />
  </CenteredGridColumn>
)

export const ControlsListItemEvidenceColumn = ({
  show,
  controlId,
}: {
  show: boolean
  controlId: string
}): JSX.Element => (
  <EvidenceGridColumn>
    {show && <EvidenceCounts controlId={controlId} />}
  </EvidenceGridColumn>
)

export const ControlsListItemDepartmentColumn = ({
  show,
  departmentId,
}: {
  show: boolean
  departmentId: string
}): JSX.Element => (
  <GridColumn>
    {show && <DepartmentChip departmentId={departmentId} />}
  </GridColumn>
)

export const ControlsListItemModelIdColumn = ({
  modelId,
}: {
  modelId: string
}): JSX.Element => (
  <GridColumn>
    <ControlModelId modelId={modelId} />
  </GridColumn>
)

export const ControlsListItemControlNameColumn = ({
  controlName,
  policyModelId,
  objective,
  isControlNa,
}: {
  controlName: string
  objective: string
  policyModelId: string
  isControlNa: boolean
}): JSX.Element => (
  <PolicyGridColumn>
    <ControlPolicyInfo
      policyModelId={policyModelId}
      isControlNa={isControlNa}
    />
    <Tooltip
      id={`controls-index-row-tooltip-${controlName}`}
      placement={TooltipPositions.right}
      overlayType={TooltipOverlayType.popover}
      tooltipBody={<DescriptionMarkup objective={objective} />}
    >
      <TitleText>{controlName}</TitleText>
    </Tooltip>
  </PolicyGridColumn>
)

export const ControlsListItemFrameworksColumn = ({
  audit,
  complianceFrameworkIds,
}: {
  audit?: AuditRecord
  complianceFrameworkIds: string[]
}): JSX.Element => {
  const { data: cfData, isLoading: isCfLoading } = useComplianceFrameworks(true)

  if (!cfData || isCfLoading) {
    return (
      <CenteredGridColumn>
        <></>
      </CenteredGridColumn>
    )
  }

  const complianceFrameworks = cfData.getItemsList() || []

  const complianceFrameworksForControl = getComplianceFrameworksForControl(
    complianceFrameworks,
    complianceFrameworkIds,
  )
  const complianceFrameworksInUse = audit
    ? audit.getComplianceFrameworksList()
    : complianceFrameworks
  const mismatch = getMismatch(
    complianceFrameworksForControl.map((el) => el.getModelId()),
    complianceFrameworksInUse,
    !!audit,
  )
  const frameworkNames = complianceFrameworksForControl
    .map((framework) => framework.getName())
    .join(', ')
  const hasComplianceFrameworks = complianceFrameworksForControl.length > 0
  const cfNames = hasComplianceFrameworks
    ? frameworkNames
    : LIST_ITEM_COPY.NO_FRAMEWORK

  return (
    <CenteredGridColumn>
      <ModelComplianceFrameWorkInfo
        modelComplianceFrameworkNames={cfNames}
        error={!hasComplianceFrameworks}
        mismatch={mismatch}
        modelType={MODEL_TYPES.CONTROL}
      />
    </CenteredGridColumn>
  )
}

export const ControlsListItemCheckColumns = ({
  controlId,
  isNotApplicable,
}: {
  controlId: string
  isNotApplicable: boolean
}): JSX.Element => {
  // use the control id to get the test results
  const { tests } = useGetControlChecks(controlId)

  if (!tests || isNotApplicable) {
    return (
      <>
        <NoPaddingGridColumn>
          <ControlChecksListItemNoTestResult
            controlId={controlId}
            isNotApplicable={isNotApplicable}
          />
        </NoPaddingGridColumn>
        <NoPaddingGridColumn>
          <ControlChecksListItemNoTestResult
            controlId={controlId}
            isNotApplicable={isNotApplicable}
          />
        </NoPaddingGridColumn>
        <NoPaddingGridColumn>
          <ControlChecksListItemNoTestResult
            controlId={controlId}
            isNotApplicable={isNotApplicable}
          />
        </NoPaddingGridColumn>
      </>
    )
  }

  const controlMatchesPolicy = tests.find(
    (test) => test.getTestName() === CONTROL_TEST_NAMES.POLICY_MATCH,
  )

  const completeness = tests.find(
    (test) => test.getTestName() === CONTROL_TEST_NAMES.COMPLETENESS,
  )
  const spotCheck = tests.find(
    (test) => test.getTestName() === CONTROL_TEST_NAMES.SPOT_CHECK,
  )

  return (
    <>
      <NoPaddingGridColumn>
        {controlMatchesPolicy ? (
          <ControlChecksListItemTestResult
            controlId={controlId}
            isStale={!!controlMatchesPolicy.getIsStale()}
            result={controlMatchesPolicy.getResult()}
          />
        ) : (
          <ControlChecksListItemNoTestResult
            controlId={controlId}
            isNotApplicable={isNotApplicable}
          />
        )}
      </NoPaddingGridColumn>
      <NoPaddingGridColumn>
        {completeness ? (
          <ControlChecksListItemTestResult
            controlId={controlId}
            isStale={!!completeness.getIsStale()}
            result={completeness.getResult()}
          />
        ) : (
          <ControlChecksListItemNoTestResult
            controlId={controlId}
            isNotApplicable={isNotApplicable}
          />
        )}
      </NoPaddingGridColumn>
      <NoPaddingGridColumn>
        {spotCheck ? (
          <ControlChecksListItemTestResult
            controlId={controlId}
            isStale={!!spotCheck.getIsStale()}
            result={spotCheck.getResult()}
          />
        ) : (
          <ControlChecksListItemNoTestResult
            controlId={controlId}
            isNotApplicable={isNotApplicable}
          />
        )}
      </NoPaddingGridColumn>
    </>
  )
}
