// source: account/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var provider_provider_pb = require('../provider/provider_pb.js');
goog.object.extend(proto, provider_provider_pb);
var service_service_pb = require('../service/service_pb.js');
goog.object.extend(proto, service_service_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
goog.exportSymbol('proto.account.AccountRecord', null, global);
goog.exportSymbol('proto.account.AccountState', null, global);
goog.exportSymbol('proto.account.AcctID', null, global);
goog.exportSymbol('proto.account.AgentRegistrationToken', null, global);
goog.exportSymbol('proto.account.AttemptEmailSignupRequest', null, global);
goog.exportSymbol('proto.account.AttemptEmailSignupResponse', null, global);
goog.exportSymbol('proto.account.AuthenticatedUser', null, global);
goog.exportSymbol('proto.account.BearerToken', null, global);
goog.exportSymbol('proto.account.BuyerOrigin', null, global);
goog.exportSymbol('proto.account.Cadence', null, global);
goog.exportSymbol('proto.account.Call', null, global);
goog.exportSymbol('proto.account.CallAndPassword', null, global);
goog.exportSymbol('proto.account.CreateComplianceFrameworkRequest', null, global);
goog.exportSymbol('proto.account.CreateComplianceFrameworkResponse', null, global);
goog.exportSymbol('proto.account.Email', null, global);
goog.exportSymbol('proto.account.EnableComplianceFrameworksRequest', null, global);
goog.exportSymbol('proto.account.FEATURE_ACCESS', null, global);
goog.exportSymbol('proto.account.FeatureAccessRequest', null, global);
goog.exportSymbol('proto.account.FeatureAccessResponse', null, global);
goog.exportSymbol('proto.account.GenerateSystemDescriptionDocResponse', null, global);
goog.exportSymbol('proto.account.GetAccountsResponse', null, global);
goog.exportSymbol('proto.account.GetRequest', null, global);
goog.exportSymbol('proto.account.GetRequest.GetbyCase', null, global);
goog.exportSymbol('proto.account.GetScopingConfigResponse', null, global);
goog.exportSymbol('proto.account.GetUsageMetricsRequest', null, global);
goog.exportSymbol('proto.account.GetUsageMetricsResponse', null, global);
goog.exportSymbol('proto.account.Invitation', null, global);
goog.exportSymbol('proto.account.JoinReadOnlyAccountRequest', null, global);
goog.exportSymbol('proto.account.ModelID', null, global);
goog.exportSymbol('proto.account.NewUser', null, global);
goog.exportSymbol('proto.account.ORG_DEVICE_OS', null, global);
goog.exportSymbol('proto.account.ORG_DEVICE_OWNER', null, global);
goog.exportSymbol('proto.account.ORG_INDUSTRY', null, global);
goog.exportSymbol('proto.account.ORG_LOCATION', null, global);
goog.exportSymbol('proto.account.ORG_SIZE', null, global);
goog.exportSymbol('proto.account.PLG_TYPE', null, global);
goog.exportSymbol('proto.account.PURPOSE', null, global);
goog.exportSymbol('proto.account.PlgDetails', null, global);
goog.exportSymbol('proto.account.ReceiveCognitoVerificationCodeRequest', null, global);
goog.exportSymbol('proto.account.ReceiveCognitoVerificationCodeResponse', null, global);
goog.exportSymbol('proto.account.ReceptorToken', null, global);
goog.exportSymbol('proto.account.RefreshTokenRequest', null, global);
goog.exportSymbol('proto.account.ResendVerificationCodeRequest', null, global);
goog.exportSymbol('proto.account.ResendVerificationCodeResponse', null, global);
goog.exportSymbol('proto.account.ResetPasswordReply', null, global);
goog.exportSymbol('proto.account.ResetPasswordRequest', null, global);
goog.exportSymbol('proto.account.SCOPING_FIELDS', null, global);
goog.exportSymbol('proto.account.SCOPING_TOPICS', null, global);
goog.exportSymbol('proto.account.ScopingField', null, global);
goog.exportSymbol('proto.account.ScopingFieldValue', null, global);
goog.exportSymbol('proto.account.ScopingFieldValue.ValueCase', null, global);
goog.exportSymbol('proto.account.ScopingModelValue', null, global);
goog.exportSymbol('proto.account.ScopingProviderValue', null, global);
goog.exportSymbol('proto.account.ScopingServiceValue', null, global);
goog.exportSymbol('proto.account.SendSignInEmailRequest', null, global);
goog.exportSymbol('proto.account.SendWelcomeEmailRequest', null, global);
goog.exportSymbol('proto.account.SignupRequest', null, global);
goog.exportSymbol('proto.account.SystemDescriptionStatus', null, global);
goog.exportSymbol('proto.account.TemplateFilter', null, global);
goog.exportSymbol('proto.account.TemplateSelected', null, global);
goog.exportSymbol('proto.account.USER_ROLE', null, global);
goog.exportSymbol('proto.account.USE_CASE', null, global);
goog.exportSymbol('proto.account.UpdateAccount', null, global);
goog.exportSymbol('proto.account.UpdateScopingConfigRequest', null, global);
goog.exportSymbol('proto.account.UpdateUser', null, global);
goog.exportSymbol('proto.account.UsageMetricUnit', null, global);
goog.exportSymbol('proto.account.UserID', null, global);
goog.exportSymbol('proto.account.UserRecord', null, global);
goog.exportSymbol('proto.account.UserState', null, global);
goog.exportSymbol('proto.account.Users', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.AccountRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.AccountRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.AccountRecord.displayName = 'proto.account.AccountRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.GetAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.account.GetAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetAccountsResponse.displayName = 'proto.account.GetAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.SignupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.SignupRequest.repeatedFields_, null);
};
goog.inherits(proto.account.SignupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.SignupRequest.displayName = 'proto.account.SignupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.Invitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.Invitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.Invitation.displayName = 'proto.account.Invitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.Call = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.Call, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.Call.displayName = 'proto.account.Call';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.Email.displayName = 'proto.account.Email';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.CallAndPassword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.CallAndPassword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.CallAndPassword.displayName = 'proto.account.CallAndPassword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.UpdateAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.UpdateAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.UpdateAccount.displayName = 'proto.account.UpdateAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.AcctID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.AcctID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.AcctID.displayName = 'proto.account.AcctID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.AgentRegistrationToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.AgentRegistrationToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.AgentRegistrationToken.displayName = 'proto.account.AgentRegistrationToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ModelID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ModelID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ModelID.displayName = 'proto.account.ModelID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ReceptorToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ReceptorToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ReceptorToken.displayName = 'proto.account.ReceptorToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.NewUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.NewUser.repeatedFields_, null);
};
goog.inherits(proto.account.NewUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.NewUser.displayName = 'proto.account.NewUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.UserRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.UserRecord.repeatedFields_, null);
};
goog.inherits(proto.account.UserRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.UserRecord.displayName = 'proto.account.UserRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.AuthenticatedUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.AuthenticatedUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.AuthenticatedUser.displayName = 'proto.account.AuthenticatedUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.Users = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.Users.repeatedFields_, null);
};
goog.inherits(proto.account.Users, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.Users.displayName = 'proto.account.Users';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account.GetRequest.oneofGroups_);
};
goog.inherits(proto.account.GetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetRequest.displayName = 'proto.account.GetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.UpdateUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.UpdateUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.UpdateUser.displayName = 'proto.account.UpdateUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.UserID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.UserID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.UserID.displayName = 'proto.account.UserID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ResetPasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ResetPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ResetPasswordRequest.displayName = 'proto.account.ResetPasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ResetPasswordReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ResetPasswordReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ResetPasswordReply.displayName = 'proto.account.ResetPasswordReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.SendWelcomeEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.SendWelcomeEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.SendWelcomeEmailRequest.displayName = 'proto.account.SendWelcomeEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.SendSignInEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.SendSignInEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.SendSignInEmailRequest.displayName = 'proto.account.SendSignInEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.RefreshTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.RefreshTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.RefreshTokenRequest.displayName = 'proto.account.RefreshTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.BearerToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.BearerToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.BearerToken.displayName = 'proto.account.BearerToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.TemplateSelected = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.TemplateSelected, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.TemplateSelected.displayName = 'proto.account.TemplateSelected';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.TemplateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.TemplateFilter.repeatedFields_, null);
};
goog.inherits(proto.account.TemplateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.TemplateFilter.displayName = 'proto.account.TemplateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.EnableComplianceFrameworksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.EnableComplianceFrameworksRequest.repeatedFields_, null);
};
goog.inherits(proto.account.EnableComplianceFrameworksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.EnableComplianceFrameworksRequest.displayName = 'proto.account.EnableComplianceFrameworksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.CreateComplianceFrameworkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.CreateComplianceFrameworkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.CreateComplianceFrameworkRequest.displayName = 'proto.account.CreateComplianceFrameworkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.CreateComplianceFrameworkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.CreateComplianceFrameworkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.CreateComplianceFrameworkResponse.displayName = 'proto.account.CreateComplianceFrameworkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.FeatureAccessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.FeatureAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.FeatureAccessRequest.displayName = 'proto.account.FeatureAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.FeatureAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.FeatureAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.FeatureAccessResponse.displayName = 'proto.account.FeatureAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetScopingConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.GetScopingConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.account.GetScopingConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetScopingConfigResponse.displayName = 'proto.account.GetScopingConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ScopingModelValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ScopingModelValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ScopingModelValue.displayName = 'proto.account.ScopingModelValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ScopingProviderValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.ScopingProviderValue.repeatedFields_, null);
};
goog.inherits(proto.account.ScopingProviderValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ScopingProviderValue.displayName = 'proto.account.ScopingProviderValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ScopingServiceValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.ScopingServiceValue.repeatedFields_, null);
};
goog.inherits(proto.account.ScopingServiceValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ScopingServiceValue.displayName = 'proto.account.ScopingServiceValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ScopingFieldValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account.ScopingFieldValue.oneofGroups_);
};
goog.inherits(proto.account.ScopingFieldValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ScopingFieldValue.displayName = 'proto.account.ScopingFieldValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ScopingField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ScopingField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ScopingField.displayName = 'proto.account.ScopingField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.UpdateScopingConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.UpdateScopingConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.account.UpdateScopingConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.UpdateScopingConfigRequest.displayName = 'proto.account.UpdateScopingConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.PlgDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.PlgDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.PlgDetails.displayName = 'proto.account.PlgDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.AttemptEmailSignupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.AttemptEmailSignupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.AttemptEmailSignupRequest.displayName = 'proto.account.AttemptEmailSignupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.AttemptEmailSignupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.AttemptEmailSignupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.AttemptEmailSignupResponse.displayName = 'proto.account.AttemptEmailSignupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ReceiveCognitoVerificationCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ReceiveCognitoVerificationCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ReceiveCognitoVerificationCodeRequest.displayName = 'proto.account.ReceiveCognitoVerificationCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ReceiveCognitoVerificationCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ReceiveCognitoVerificationCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ReceiveCognitoVerificationCodeResponse.displayName = 'proto.account.ReceiveCognitoVerificationCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ResendVerificationCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ResendVerificationCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ResendVerificationCodeRequest.displayName = 'proto.account.ResendVerificationCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.ResendVerificationCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.ResendVerificationCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.ResendVerificationCodeResponse.displayName = 'proto.account.ResendVerificationCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.JoinReadOnlyAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.JoinReadOnlyAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.JoinReadOnlyAccountRequest.displayName = 'proto.account.JoinReadOnlyAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.SystemDescriptionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.SystemDescriptionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.SystemDescriptionStatus.displayName = 'proto.account.SystemDescriptionStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GenerateSystemDescriptionDocResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.GenerateSystemDescriptionDocResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GenerateSystemDescriptionDocResponse.displayName = 'proto.account.GenerateSystemDescriptionDocResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.UsageMetricUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.UsageMetricUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.UsageMetricUnit.displayName = 'proto.account.UsageMetricUnit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetUsageMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account.GetUsageMetricsResponse.repeatedFields_, null);
};
goog.inherits(proto.account.GetUsageMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetUsageMetricsResponse.displayName = 'proto.account.GetUsageMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account.GetUsageMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account.GetUsageMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account.GetUsageMetricsRequest.displayName = 'proto.account.GetUsageMetricsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.AccountRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.account.AccountRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.AccountRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AccountRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    domainname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orgname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    orgurl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    orglogouri: jspb.Message.getFieldWithDefault(msg, 8, ""),
    auditoraccountid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    useCase: jspb.Message.getFieldWithDefault(msg, 10, 0),
    purpose: jspb.Message.getFieldWithDefault(msg, 11, 0),
    hasCompletedScoping: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.AccountRecord}
 */
proto.account.AccountRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.AccountRecord;
  return proto.account.AccountRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.AccountRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.AccountRecord}
 */
proto.account.AccountRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {!proto.account.AccountState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgurl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrglogouri(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditoraccountid(value);
      break;
    case 10:
      var value = /** @type {!proto.account.USE_CASE} */ (reader.readEnum());
      msg.setUseCase(value);
      break;
    case 11:
      var value = /** @type {!proto.account.PURPOSE} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCompletedScoping(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.AccountRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.AccountRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.AccountRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AccountRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDomainname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrgname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrgurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrglogouri();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAuditoraccountid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUseCase();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getHasCompletedScoping();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.AccountRecord.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.AccountRecord} returns this
*/
proto.account.AccountRecord.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.AccountRecord.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.AccountRecord.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.AccountRecord} returns this
*/
proto.account.AccountRecord.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.AccountRecord.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ID = 3;
 * @return {string}
 */
proto.account.AccountRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AccountState State = 4;
 * @return {!proto.account.AccountState}
 */
proto.account.AccountRecord.prototype.getState = function() {
  return /** @type {!proto.account.AccountState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.account.AccountState} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string DomainName = 5;
 * @return {string}
 */
proto.account.AccountRecord.prototype.getDomainname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setDomainname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string OrgName = 6;
 * @return {string}
 */
proto.account.AccountRecord.prototype.getOrgname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setOrgname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string OrgURL = 7;
 * @return {string}
 */
proto.account.AccountRecord.prototype.getOrgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setOrgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string OrgLogoURI = 8;
 * @return {string}
 */
proto.account.AccountRecord.prototype.getOrglogouri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setOrglogouri = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string AuditorAccountId = 9;
 * @return {string}
 */
proto.account.AccountRecord.prototype.getAuditoraccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setAuditoraccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional USE_CASE use_case = 10;
 * @return {!proto.account.USE_CASE}
 */
proto.account.AccountRecord.prototype.getUseCase = function() {
  return /** @type {!proto.account.USE_CASE} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.account.USE_CASE} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setUseCase = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional PURPOSE purpose = 11;
 * @return {!proto.account.PURPOSE}
 */
proto.account.AccountRecord.prototype.getPurpose = function() {
  return /** @type {!proto.account.PURPOSE} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.account.PURPOSE} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool has_completed_scoping = 12;
 * @return {boolean}
 */
proto.account.AccountRecord.prototype.getHasCompletedScoping = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.AccountRecord} returns this
 */
proto.account.AccountRecord.prototype.setHasCompletedScoping = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.GetAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.account.AccountRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetAccountsResponse}
 */
proto.account.GetAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetAccountsResponse;
  return proto.account.GetAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetAccountsResponse}
 */
proto.account.GetAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.AccountRecord;
      reader.readMessage(value,proto.account.AccountRecord.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.AccountRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountRecord accounts = 1;
 * @return {!Array<!proto.account.AccountRecord>}
 */
proto.account.GetAccountsResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.account.AccountRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.AccountRecord, 1));
};


/**
 * @param {!Array<!proto.account.AccountRecord>} value
 * @return {!proto.account.GetAccountsResponse} returns this
*/
proto.account.GetAccountsResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.AccountRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.AccountRecord}
 */
proto.account.GetAccountsResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.AccountRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.GetAccountsResponse} returns this
 */
proto.account.GetAccountsResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.SignupRequest.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.SignupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.SignupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.SignupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SignupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isAuditor: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    domainName: (f = msg.getDomainName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    userName: (f = msg.getUserName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orgName: (f = msg.getOrgName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orgUrl: (f = msg.getOrgUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orgLogoUri: (f = msg.getOrgLogoUri()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    skipEmail: (f = msg.getSkipEmail()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    auditorAccountEmail: (f = msg.getAuditorAccountEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    allowMultipleAccounts: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    useCase: jspb.Message.getFieldWithDefault(msg, 13, 0),
    purpose: jspb.Message.getFieldWithDefault(msg, 14, 0),
    controlCheckCredits: (f = msg.getControlCheckCredits()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    qcBasicCredits: (f = msg.getQcBasicCredits()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    qcAdvancedCredits: (f = msg.getQcAdvancedCredits()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.SignupRequest}
 */
proto.account.SignupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.SignupRequest;
  return proto.account.SignupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.SignupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.SignupRequest}
 */
proto.account.SignupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuditor(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDomainName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrgName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrgUrl(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrgLogoUri(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSkipEmail(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditorAccountEmail(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleAccounts(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    case 13:
      var value = /** @type {!proto.account.USE_CASE} */ (reader.readEnum());
      msg.setUseCase(value);
      break;
    case 14:
      var value = /** @type {!proto.account.PURPOSE} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setControlCheckCredits(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setQcBasicCredits(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setQcAdvancedCredits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.SignupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.SignupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.SignupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SignupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsAuditor();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDomainName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrgName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrgUrl();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrgLogoUri();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSkipEmail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditorAccountEmail();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAllowMultipleAccounts();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getUseCase();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getControlCheckCredits();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getQcBasicCredits();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getQcAdvancedCredits();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.account.SignupRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.account.SignupRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_auditor = 3;
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.getIsAuditor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setIsAuditor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue domain_name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.SignupRequest.prototype.getDomainName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setDomainName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearDomainName = function() {
  return this.setDomainName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasDomainName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue user_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.SignupRequest.prototype.getUserName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setUserName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearUserName = function() {
  return this.setUserName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasUserName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue org_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.SignupRequest.prototype.getOrgName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setOrgName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearOrgName = function() {
  return this.setOrgName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasOrgName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue org_url = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.SignupRequest.prototype.getOrgUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setOrgUrl = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearOrgUrl = function() {
  return this.setOrgUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasOrgUrl = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue org_logo_uri = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.SignupRequest.prototype.getOrgLogoUri = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setOrgLogoUri = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearOrgLogoUri = function() {
  return this.setOrgLogoUri(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasOrgLogoUri = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue skip_email = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account.SignupRequest.prototype.getSkipEmail = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setSkipEmail = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearSkipEmail = function() {
  return this.setSkipEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasSkipEmail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue auditor_account_email = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.SignupRequest.prototype.getAuditorAccountEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setAuditorAccountEmail = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearAuditorAccountEmail = function() {
  return this.setAuditorAccountEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasAuditorAccountEmail = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool allow_multiple_accounts = 11;
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.getAllowMultipleAccounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setAllowMultipleAccounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated string compliance_framework_ids = 12;
 * @return {!Array<string>}
 */
proto.account.SignupRequest.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};


/**
 * optional USE_CASE use_case = 13;
 * @return {!proto.account.USE_CASE}
 */
proto.account.SignupRequest.prototype.getUseCase = function() {
  return /** @type {!proto.account.USE_CASE} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.account.USE_CASE} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setUseCase = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional PURPOSE purpose = 14;
 * @return {!proto.account.PURPOSE}
 */
proto.account.SignupRequest.prototype.getPurpose = function() {
  return /** @type {!proto.account.PURPOSE} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.account.PURPOSE} value
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional google.protobuf.Int64Value control_check_credits = 15;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.account.SignupRequest.prototype.getControlCheckCredits = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 15));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setControlCheckCredits = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearControlCheckCredits = function() {
  return this.setControlCheckCredits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasControlCheckCredits = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Int64Value qc_basic_credits = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.account.SignupRequest.prototype.getQcBasicCredits = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setQcBasicCredits = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearQcBasicCredits = function() {
  return this.setQcBasicCredits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasQcBasicCredits = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Int64Value qc_advanced_credits = 17;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.account.SignupRequest.prototype.getQcAdvancedCredits = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 17));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.account.SignupRequest} returns this
*/
proto.account.SignupRequest.prototype.setQcAdvancedCredits = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.SignupRequest} returns this
 */
proto.account.SignupRequest.prototype.clearQcAdvancedCredits = function() {
  return this.setQcAdvancedCredits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SignupRequest.prototype.hasQcAdvancedCredits = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.Invitation.prototype.toObject = function(opt_includeInstance) {
  return proto.account.Invitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.Invitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Invitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invitername: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.Invitation}
 */
proto.account.Invitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.Invitation;
  return proto.account.Invitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.Invitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.Invitation}
 */
proto.account.Invitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.Invitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.Invitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.Invitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Invitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvitername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Email = 1;
 * @return {string}
 */
proto.account.Invitation.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.Invitation} returns this
 */
proto.account.Invitation.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string InviterName = 2;
 * @return {string}
 */
proto.account.Invitation.prototype.getInvitername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.Invitation} returns this
 */
proto.account.Invitation.prototype.setInvitername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.Call.prototype.toObject = function(opt_includeInstance) {
  return proto.account.Call.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.Call} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Call.toObject = function(includeInstance, msg) {
  var f, obj = {
    callid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiry: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.Call}
 */
proto.account.Call.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.Call;
  return proto.account.Call.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.Call} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.Call}
 */
proto.account.Call.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.Call.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.Call.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.Call} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Call.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiry();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string CallID = 1;
 * @return {string}
 */
proto.account.Call.prototype.getCallid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.Call} returns this
 */
proto.account.Call.prototype.setCallid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Expiry = 2;
 * @return {number}
 */
proto.account.Call.prototype.getExpiry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.Call} returns this
 */
proto.account.Call.prototype.setExpiry = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.account.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.Email}
 */
proto.account.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.Email;
  return proto.account.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.Email}
 */
proto.account.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Email = 1;
 * @return {string}
 */
proto.account.Email.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.Email} returns this
 */
proto.account.Email.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.CallAndPassword.prototype.toObject = function(opt_includeInstance) {
  return proto.account.CallAndPassword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.CallAndPassword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.CallAndPassword.toObject = function(includeInstance, msg) {
  var f, obj = {
    callid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.CallAndPassword}
 */
proto.account.CallAndPassword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.CallAndPassword;
  return proto.account.CallAndPassword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.CallAndPassword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.CallAndPassword}
 */
proto.account.CallAndPassword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.CallAndPassword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.CallAndPassword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.CallAndPassword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.CallAndPassword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string CallID = 1;
 * @return {string}
 */
proto.account.CallAndPassword.prototype.getCallid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.CallAndPassword} returns this
 */
proto.account.CallAndPassword.prototype.setCallid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Password = 2;
 * @return {string}
 */
proto.account.CallAndPassword.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.CallAndPassword} returns this
 */
proto.account.CallAndPassword.prototype.setPassword = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.CallAndPassword} returns this
 */
proto.account.CallAndPassword.prototype.clearPassword = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.CallAndPassword.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.account.CallAndPassword.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.CallAndPassword} returns this
 */
proto.account.CallAndPassword.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.CallAndPassword} returns this
 */
proto.account.CallAndPassword.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.CallAndPassword.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.UpdateAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.account.UpdateAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.UpdateAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UpdateAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainName: (f = msg.getDomainName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orgName: (f = msg.getOrgName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orgUrl: (f = msg.getOrgUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    orgLogoUri: (f = msg.getOrgLogoUri()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.UpdateAccount}
 */
proto.account.UpdateAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.UpdateAccount;
  return proto.account.UpdateAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.UpdateAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.UpdateAccount}
 */
proto.account.UpdateAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDomainName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrgName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrgUrl(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrgLogoUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.UpdateAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.UpdateAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.UpdateAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UpdateAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrgName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrgUrl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrgLogoUri();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue domain_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.UpdateAccount.prototype.getDomainName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.UpdateAccount} returns this
*/
proto.account.UpdateAccount.prototype.setDomainName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UpdateAccount} returns this
 */
proto.account.UpdateAccount.prototype.clearDomainName = function() {
  return this.setDomainName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateAccount.prototype.hasDomainName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue org_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.UpdateAccount.prototype.getOrgName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.UpdateAccount} returns this
*/
proto.account.UpdateAccount.prototype.setOrgName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UpdateAccount} returns this
 */
proto.account.UpdateAccount.prototype.clearOrgName = function() {
  return this.setOrgName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateAccount.prototype.hasOrgName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue org_url = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.UpdateAccount.prototype.getOrgUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.UpdateAccount} returns this
*/
proto.account.UpdateAccount.prototype.setOrgUrl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UpdateAccount} returns this
 */
proto.account.UpdateAccount.prototype.clearOrgUrl = function() {
  return this.setOrgUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateAccount.prototype.hasOrgUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue org_logo_uri = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account.UpdateAccount.prototype.getOrgLogoUri = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account.UpdateAccount} returns this
*/
proto.account.UpdateAccount.prototype.setOrgLogoUri = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UpdateAccount} returns this
 */
proto.account.UpdateAccount.prototype.clearOrgLogoUri = function() {
  return this.setOrgLogoUri(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateAccount.prototype.hasOrgLogoUri = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.AcctID.prototype.toObject = function(opt_includeInstance) {
  return proto.account.AcctID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.AcctID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AcctID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.AcctID}
 */
proto.account.AcctID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.AcctID;
  return proto.account.AcctID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.AcctID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.AcctID}
 */
proto.account.AcctID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.AcctID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.AcctID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.AcctID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AcctID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.account.AcctID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AcctID} returns this
 */
proto.account.AcctID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.AgentRegistrationToken.prototype.toObject = function(opt_includeInstance) {
  return proto.account.AgentRegistrationToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.AgentRegistrationToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AgentRegistrationToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.AgentRegistrationToken}
 */
proto.account.AgentRegistrationToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.AgentRegistrationToken;
  return proto.account.AgentRegistrationToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.AgentRegistrationToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.AgentRegistrationToken}
 */
proto.account.AgentRegistrationToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.AgentRegistrationToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.AgentRegistrationToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.AgentRegistrationToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AgentRegistrationToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Token = 1;
 * @return {string}
 */
proto.account.AgentRegistrationToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AgentRegistrationToken} returns this
 */
proto.account.AgentRegistrationToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ModelID.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ModelID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ModelID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ModelID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ModelID}
 */
proto.account.ModelID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ModelID;
  return proto.account.ModelID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ModelID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ModelID}
 */
proto.account.ModelID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ModelID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ModelID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ModelID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ModelID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.account.ModelID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ModelID} returns this
 */
proto.account.ModelID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ReceptorToken.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ReceptorToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ReceptorToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ReceptorToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ReceptorToken}
 */
proto.account.ReceptorToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ReceptorToken;
  return proto.account.ReceptorToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ReceptorToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ReceptorToken}
 */
proto.account.ReceptorToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ReceptorToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ReceptorToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ReceptorToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ReceptorToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Token = 1;
 * @return {string}
 */
proto.account.ReceptorToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ReceptorToken} returns this
 */
proto.account.ReceptorToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.NewUser.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.NewUser.prototype.toObject = function(opt_includeInstance) {
  return proto.account.NewUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.NewUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.NewUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    grantoremail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newuseremail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    role: jspb.Message.getFieldWithDefault(msg, 4, 0),
    auditIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.NewUser}
 */
proto.account.NewUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.NewUser;
  return proto.account.NewUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.NewUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.NewUser}
 */
proto.account.NewUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrantoremail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewuseremail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 4:
      var value = /** @type {!proto.account.USER_ROLE} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.NewUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.NewUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.NewUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.NewUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGrantoremail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewuseremail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAuditIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string GrantorEmail = 1;
 * @return {string}
 */
proto.account.NewUser.prototype.getGrantoremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.setGrantoremail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string NewUserEmail = 2;
 * @return {string}
 */
proto.account.NewUser.prototype.getNewuseremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.setNewuseremail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AccountId = 3;
 * @return {string}
 */
proto.account.NewUser.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional USER_ROLE Role = 4;
 * @return {!proto.account.USER_ROLE}
 */
proto.account.NewUser.prototype.getRole = function() {
  return /** @type {!proto.account.USER_ROLE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.account.USER_ROLE} value
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string audit_ids = 5;
 * @return {!Array<string>}
 */
proto.account.NewUser.prototype.getAuditIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.setAuditIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.addAuditIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.NewUser} returns this
 */
proto.account.NewUser.prototype.clearAuditIdsList = function() {
  return this.setAuditIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.UserRecord.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.UserRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.account.UserRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.UserRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UserRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isaccountcontact: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    avataruri: jspb.Message.getFieldWithDefault(msg, 10, ""),
    department: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lastseen: (f = msg.getLastseen()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    donotify: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lastScopingPageSeen: jspb.Message.getFieldWithDefault(msg, 14, 0),
    auditIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    allowQc: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.UserRecord}
 */
proto.account.UserRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.UserRecord;
  return proto.account.UserRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.UserRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.UserRecord}
 */
proto.account.UserRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {!proto.account.UserState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 7:
      var value = /** @type {!proto.account.USER_ROLE} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountcontact(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvataruri(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastseen(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDonotify(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastScopingPageSeen(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditIds(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowQc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.UserRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.UserRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.UserRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UserRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getIsaccountcontact();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAvataruri();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLastseen();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDonotify();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLastScopingPageSeen();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getAuditIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getAllowQc();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.UserRecord.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.UserRecord} returns this
*/
proto.account.UserRecord.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UserRecord.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.UserRecord.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.UserRecord} returns this
*/
proto.account.UserRecord.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UserRecord.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ID = 3;
 * @return {string}
 */
proto.account.UserRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Email = 4;
 * @return {string}
 */
proto.account.UserRecord.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserState State = 5;
 * @return {!proto.account.UserState}
 */
proto.account.UserRecord.prototype.getState = function() {
  return /** @type {!proto.account.UserState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.account.UserState} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string AccountID = 6;
 * @return {string}
 */
proto.account.UserRecord.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional USER_ROLE Role = 7;
 * @return {!proto.account.USER_ROLE}
 */
proto.account.UserRecord.prototype.getRole = function() {
  return /** @type {!proto.account.USER_ROLE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.account.USER_ROLE} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool IsAccountContact = 8;
 * @return {boolean}
 */
proto.account.UserRecord.prototype.getIsaccountcontact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setIsaccountcontact = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string Name = 9;
 * @return {string}
 */
proto.account.UserRecord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string AvatarURI = 10;
 * @return {string}
 */
proto.account.UserRecord.prototype.getAvataruri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setAvataruri = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Department = 11;
 * @return {string}
 */
proto.account.UserRecord.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp LastSeen = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.UserRecord.prototype.getLastseen = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.UserRecord} returns this
*/
proto.account.UserRecord.prototype.setLastseen = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.clearLastseen = function() {
  return this.setLastseen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UserRecord.prototype.hasLastseen = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool DoNotify = 13;
 * @return {boolean}
 */
proto.account.UserRecord.prototype.getDonotify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setDonotify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 last_scoping_page_seen = 14;
 * @return {number}
 */
proto.account.UserRecord.prototype.getLastScopingPageSeen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setLastScopingPageSeen = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated string audit_ids = 15;
 * @return {!Array<string>}
 */
proto.account.UserRecord.prototype.getAuditIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setAuditIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.addAuditIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.clearAuditIdsList = function() {
  return this.setAuditIdsList([]);
};


/**
 * optional bool allow_qc = 16;
 * @return {boolean}
 */
proto.account.UserRecord.prototype.getAllowQc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UserRecord} returns this
 */
proto.account.UserRecord.prototype.setAllowQc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.AuthenticatedUser.prototype.toObject = function(opt_includeInstance) {
  return proto.account.AuthenticatedUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.AuthenticatedUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AuthenticatedUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.account.UserRecord.toObject(includeInstance, f),
    accesstoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.AuthenticatedUser}
 */
proto.account.AuthenticatedUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.AuthenticatedUser;
  return proto.account.AuthenticatedUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.AuthenticatedUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.AuthenticatedUser}
 */
proto.account.AuthenticatedUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.UserRecord;
      reader.readMessage(value,proto.account.UserRecord.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.AuthenticatedUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.AuthenticatedUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.AuthenticatedUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AuthenticatedUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account.UserRecord.serializeBinaryToWriter
    );
  }
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional UserRecord User = 1;
 * @return {?proto.account.UserRecord}
 */
proto.account.AuthenticatedUser.prototype.getUser = function() {
  return /** @type{?proto.account.UserRecord} */ (
    jspb.Message.getWrapperField(this, proto.account.UserRecord, 1));
};


/**
 * @param {?proto.account.UserRecord|undefined} value
 * @return {!proto.account.AuthenticatedUser} returns this
*/
proto.account.AuthenticatedUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.AuthenticatedUser} returns this
 */
proto.account.AuthenticatedUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.AuthenticatedUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string AccessToken = 2;
 * @return {string}
 */
proto.account.AuthenticatedUser.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AuthenticatedUser} returns this
 */
proto.account.AuthenticatedUser.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.Users.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.Users.prototype.toObject = function(opt_includeInstance) {
  return proto.account.Users.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.Users} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Users.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.account.UserRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.Users}
 */
proto.account.Users.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.Users;
  return proto.account.Users.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.Users} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.Users}
 */
proto.account.Users.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.UserRecord;
      reader.readMessage(value,proto.account.UserRecord.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.Users.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.Users.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.Users} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.Users.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.UserRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserRecord Users = 1;
 * @return {!Array<!proto.account.UserRecord>}
 */
proto.account.Users.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.account.UserRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.UserRecord, 1));
};


/**
 * @param {!Array<!proto.account.UserRecord>} value
 * @return {!proto.account.Users} returns this
*/
proto.account.Users.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.UserRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.UserRecord}
 */
proto.account.Users.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.UserRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.Users} returns this
 */
proto.account.Users.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account.GetRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.account.GetRequest.GetbyCase = {
  GETBY_NOT_SET: 0,
  USERID: 1,
  EMAIL: 2
};

/**
 * @return {proto.account.GetRequest.GetbyCase}
 */
proto.account.GetRequest.prototype.getGetbyCase = function() {
  return /** @type {proto.account.GetRequest.GetbyCase} */(jspb.Message.computeOneofCase(this, proto.account.GetRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetRequest}
 */
proto.account.GetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetRequest;
  return proto.account.GetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetRequest}
 */
proto.account.GetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.account.GetRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetRequest} returns this
 */
proto.account.GetRequest.prototype.setUserid = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.account.GetRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.GetRequest} returns this
 */
proto.account.GetRequest.prototype.clearUserid = function() {
  return jspb.Message.setOneofField(this, 1, proto.account.GetRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.GetRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Email = 2;
 * @return {string}
 */
proto.account.GetRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetRequest} returns this
 */
proto.account.GetRequest.prototype.setEmail = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.account.GetRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.GetRequest} returns this
 */
proto.account.GetRequest.prototype.clearEmail = function() {
  return jspb.Message.setOneofField(this, 2, proto.account.GetRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.GetRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.UpdateUser.prototype.toObject = function(opt_includeInstance) {
  return proto.account.UpdateUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.UpdateUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UpdateUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isaccountcontact: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avataruri: jspb.Message.getFieldWithDefault(msg, 5, ""),
    department: jspb.Message.getFieldWithDefault(msg, 6, ""),
    donotify: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    auditIds: (f = msg.getAuditIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    allowQc: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.UpdateUser}
 */
proto.account.UpdateUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.UpdateUser;
  return proto.account.UpdateUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.UpdateUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.UpdateUser}
 */
proto.account.UpdateUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.account.USER_ROLE} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountcontact(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvataruri(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDonotify(value);
      break;
    case 8:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setAuditIds(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowQc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.UpdateUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.UpdateUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.UpdateUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UpdateUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.account.USER_ROLE} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAuditIds();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.account.UpdateUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional USER_ROLE Role = 2;
 * @return {!proto.account.USER_ROLE}
 */
proto.account.UpdateUser.prototype.getRole = function() {
  return /** @type {!proto.account.USER_ROLE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.account.USER_ROLE} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearRole = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool isAccountContact = 3;
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.getIsaccountcontact = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setIsaccountcontact = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearIsaccountcontact = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasIsaccountcontact = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.account.UpdateUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string AvatarURI = 5;
 * @return {string}
 */
proto.account.UpdateUser.prototype.getAvataruri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setAvataruri = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearAvataruri = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasAvataruri = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Department = 6;
 * @return {string}
 */
proto.account.UpdateUser.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setDepartment = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearDepartment = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool DoNotify = 7;
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.getDonotify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setDonotify = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearDonotify = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasDonotify = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.StringList audit_ids = 8;
 * @return {?proto.common.StringList}
 */
proto.account.UpdateUser.prototype.getAuditIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 8));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.account.UpdateUser} returns this
*/
proto.account.UpdateUser.prototype.setAuditIds = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearAuditIds = function() {
  return this.setAuditIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasAuditIds = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool allow_qc = 9;
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.getAllowQc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.setAllowQc = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.UpdateUser} returns this
 */
proto.account.UpdateUser.prototype.clearAllowQc = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UpdateUser.prototype.hasAllowQc = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.UserID.prototype.toObject = function(opt_includeInstance) {
  return proto.account.UserID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.UserID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UserID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.UserID}
 */
proto.account.UserID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.UserID;
  return proto.account.UserID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.UserID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.UserID}
 */
proto.account.UserID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.UserID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.UserID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.UserID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UserID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.account.UserID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.UserID} returns this
 */
proto.account.UserID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ResetPasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ResetPasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ResetPasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResetPasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    callid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newpassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ResetPasswordRequest}
 */
proto.account.ResetPasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ResetPasswordRequest;
  return proto.account.ResetPasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ResetPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ResetPasswordRequest}
 */
proto.account.ResetPasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ResetPasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ResetPasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ResetPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResetPasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string CallID = 1;
 * @return {string}
 */
proto.account.ResetPasswordRequest.prototype.getCallid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ResetPasswordRequest} returns this
 */
proto.account.ResetPasswordRequest.prototype.setCallid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string NewPassword = 2;
 * @return {string}
 */
proto.account.ResetPasswordRequest.prototype.getNewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ResetPasswordRequest} returns this
 */
proto.account.ResetPasswordRequest.prototype.setNewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ResetPasswordReply.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ResetPasswordReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ResetPasswordReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResetPasswordReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ResetPasswordReply}
 */
proto.account.ResetPasswordReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ResetPasswordReply;
  return proto.account.ResetPasswordReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ResetPasswordReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ResetPasswordReply}
 */
proto.account.ResetPasswordReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ResetPasswordReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ResetPasswordReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ResetPasswordReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResetPasswordReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Message = 1;
 * @return {string}
 */
proto.account.ResetPasswordReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ResetPasswordReply} returns this
 */
proto.account.ResetPasswordReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.SendWelcomeEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.SendWelcomeEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.SendWelcomeEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SendWelcomeEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    buyerOrigin: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.SendWelcomeEmailRequest}
 */
proto.account.SendWelcomeEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.SendWelcomeEmailRequest;
  return proto.account.SendWelcomeEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.SendWelcomeEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.SendWelcomeEmailRequest}
 */
proto.account.SendWelcomeEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.account.BuyerOrigin} */ (reader.readEnum());
      msg.setBuyerOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.SendWelcomeEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.SendWelcomeEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.SendWelcomeEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SendWelcomeEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBuyerOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.account.SendWelcomeEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.SendWelcomeEmailRequest} returns this
 */
proto.account.SendWelcomeEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_URL = 2;
 * @return {string}
 */
proto.account.SendWelcomeEmailRequest.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.SendWelcomeEmailRequest} returns this
 */
proto.account.SendWelcomeEmailRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.SendWelcomeEmailRequest} returns this
 */
proto.account.SendWelcomeEmailRequest.prototype.clearRedirectUrl = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SendWelcomeEmailRequest.prototype.hasRedirectUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BuyerOrigin buyer_origin = 3;
 * @return {!proto.account.BuyerOrigin}
 */
proto.account.SendWelcomeEmailRequest.prototype.getBuyerOrigin = function() {
  return /** @type {!proto.account.BuyerOrigin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.account.BuyerOrigin} value
 * @return {!proto.account.SendWelcomeEmailRequest} returns this
 */
proto.account.SendWelcomeEmailRequest.prototype.setBuyerOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.SendSignInEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.SendSignInEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.SendSignInEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SendSignInEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.SendSignInEmailRequest}
 */
proto.account.SendSignInEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.SendSignInEmailRequest;
  return proto.account.SendSignInEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.SendSignInEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.SendSignInEmailRequest}
 */
proto.account.SendSignInEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.SendSignInEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.SendSignInEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.SendSignInEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SendSignInEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.account.SendSignInEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.SendSignInEmailRequest} returns this
 */
proto.account.SendSignInEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_URL = 2;
 * @return {string}
 */
proto.account.SendSignInEmailRequest.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.SendSignInEmailRequest} returns this
 */
proto.account.SendSignInEmailRequest.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.SendSignInEmailRequest} returns this
 */
proto.account.SendSignInEmailRequest.prototype.clearRedirectUrl = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.SendSignInEmailRequest.prototype.hasRedirectUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.RefreshTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.RefreshTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.RefreshTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.RefreshTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customExpiration: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.RefreshTokenRequest}
 */
proto.account.RefreshTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.RefreshTokenRequest;
  return proto.account.RefreshTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.RefreshTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.RefreshTokenRequest}
 */
proto.account.RefreshTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.RefreshTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.RefreshTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.RefreshTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.RefreshTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomExpiration();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.account.RefreshTokenRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.RefreshTokenRequest} returns this
 */
proto.account.RefreshTokenRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool custom_expiration = 2;
 * @return {boolean}
 */
proto.account.RefreshTokenRequest.prototype.getCustomExpiration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.RefreshTokenRequest} returns this
 */
proto.account.RefreshTokenRequest.prototype.setCustomExpiration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.BearerToken.prototype.toObject = function(opt_includeInstance) {
  return proto.account.BearerToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.BearerToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.BearerToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwsToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.BearerToken}
 */
proto.account.BearerToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.BearerToken;
  return proto.account.BearerToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.BearerToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.BearerToken}
 */
proto.account.BearerToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwsToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.BearerToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.BearerToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.BearerToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.BearerToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwsToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string jws_token = 1;
 * @return {string}
 */
proto.account.BearerToken.prototype.getJwsToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.BearerToken} returns this
 */
proto.account.BearerToken.prototype.setJwsToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.TemplateSelected.prototype.toObject = function(opt_includeInstance) {
  return proto.account.TemplateSelected.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.TemplateSelected} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.TemplateSelected.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    objective: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.TemplateSelected}
 */
proto.account.TemplateSelected.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.TemplateSelected;
  return proto.account.TemplateSelected.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.TemplateSelected} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.TemplateSelected}
 */
proto.account.TemplateSelected.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.TemplateSelected.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.TemplateSelected.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.TemplateSelected} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.TemplateSelected.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.account.TemplateSelected.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.TemplateSelected} returns this
 */
proto.account.TemplateSelected.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool selected = 2;
 * @return {boolean}
 */
proto.account.TemplateSelected.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.TemplateSelected} returns this
 */
proto.account.TemplateSelected.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string objective = 3;
 * @return {string}
 */
proto.account.TemplateSelected.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.TemplateSelected} returns this
 */
proto.account.TemplateSelected.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.TemplateFilter.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.TemplateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.account.TemplateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.TemplateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.TemplateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlFilterList: jspb.Message.toObjectList(msg.getControlFilterList(),
    proto.account.TemplateSelected.toObject, includeInstance),
    policyFilterList: jspb.Message.toObjectList(msg.getPolicyFilterList(),
    proto.account.TemplateSelected.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.TemplateFilter}
 */
proto.account.TemplateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.TemplateFilter;
  return proto.account.TemplateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.TemplateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.TemplateFilter}
 */
proto.account.TemplateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.account.TemplateSelected;
      reader.readMessage(value,proto.account.TemplateSelected.deserializeBinaryFromReader);
      msg.addControlFilter(value);
      break;
    case 3:
      var value = new proto.account.TemplateSelected;
      reader.readMessage(value,proto.account.TemplateSelected.deserializeBinaryFromReader);
      msg.addPolicyFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.TemplateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.TemplateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.TemplateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.TemplateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.account.TemplateSelected.serializeBinaryToWriter
    );
  }
  f = message.getPolicyFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.account.TemplateSelected.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TemplateSelected control_filter = 2;
 * @return {!Array<!proto.account.TemplateSelected>}
 */
proto.account.TemplateFilter.prototype.getControlFilterList = function() {
  return /** @type{!Array<!proto.account.TemplateSelected>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.TemplateSelected, 2));
};


/**
 * @param {!Array<!proto.account.TemplateSelected>} value
 * @return {!proto.account.TemplateFilter} returns this
*/
proto.account.TemplateFilter.prototype.setControlFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.account.TemplateSelected=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.TemplateSelected}
 */
proto.account.TemplateFilter.prototype.addControlFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.account.TemplateSelected, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.TemplateFilter} returns this
 */
proto.account.TemplateFilter.prototype.clearControlFilterList = function() {
  return this.setControlFilterList([]);
};


/**
 * repeated TemplateSelected policy_filter = 3;
 * @return {!Array<!proto.account.TemplateSelected>}
 */
proto.account.TemplateFilter.prototype.getPolicyFilterList = function() {
  return /** @type{!Array<!proto.account.TemplateSelected>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.TemplateSelected, 3));
};


/**
 * @param {!Array<!proto.account.TemplateSelected>} value
 * @return {!proto.account.TemplateFilter} returns this
*/
proto.account.TemplateFilter.prototype.setPolicyFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.account.TemplateSelected=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.TemplateSelected}
 */
proto.account.TemplateFilter.prototype.addPolicyFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.account.TemplateSelected, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.TemplateFilter} returns this
 */
proto.account.TemplateFilter.prototype.clearPolicyFilterList = function() {
  return this.setPolicyFilterList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.EnableComplianceFrameworksRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.EnableComplianceFrameworksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.EnableComplianceFrameworksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.EnableComplianceFrameworksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.EnableComplianceFrameworksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    templateFilter: (f = msg.getTemplateFilter()) && proto.account.TemplateFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.EnableComplianceFrameworksRequest}
 */
proto.account.EnableComplianceFrameworksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.EnableComplianceFrameworksRequest;
  return proto.account.EnableComplianceFrameworksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.EnableComplianceFrameworksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.EnableComplianceFrameworksRequest}
 */
proto.account.EnableComplianceFrameworksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    case 2:
      var value = new proto.account.TemplateFilter;
      reader.readMessage(value,proto.account.TemplateFilter.deserializeBinaryFromReader);
      msg.setTemplateFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.EnableComplianceFrameworksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.EnableComplianceFrameworksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.EnableComplianceFrameworksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.EnableComplianceFrameworksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTemplateFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.account.TemplateFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string compliance_framework_ids = 1;
 * @return {!Array<string>}
 */
proto.account.EnableComplianceFrameworksRequest.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.account.EnableComplianceFrameworksRequest} returns this
 */
proto.account.EnableComplianceFrameworksRequest.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.account.EnableComplianceFrameworksRequest} returns this
 */
proto.account.EnableComplianceFrameworksRequest.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.EnableComplianceFrameworksRequest} returns this
 */
proto.account.EnableComplianceFrameworksRequest.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};


/**
 * optional TemplateFilter template_filter = 2;
 * @return {?proto.account.TemplateFilter}
 */
proto.account.EnableComplianceFrameworksRequest.prototype.getTemplateFilter = function() {
  return /** @type{?proto.account.TemplateFilter} */ (
    jspb.Message.getWrapperField(this, proto.account.TemplateFilter, 2));
};


/**
 * @param {?proto.account.TemplateFilter|undefined} value
 * @return {!proto.account.EnableComplianceFrameworksRequest} returns this
*/
proto.account.EnableComplianceFrameworksRequest.prototype.setTemplateFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.EnableComplianceFrameworksRequest} returns this
 */
proto.account.EnableComplianceFrameworksRequest.prototype.clearTemplateFilter = function() {
  return this.setTemplateFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.EnableComplianceFrameworksRequest.prototype.hasTemplateFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.CreateComplianceFrameworkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.CreateComplianceFrameworkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.CreateComplianceFrameworkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.CreateComplianceFrameworkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.CreateComplianceFrameworkRequest}
 */
proto.account.CreateComplianceFrameworkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.CreateComplianceFrameworkRequest;
  return proto.account.CreateComplianceFrameworkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.CreateComplianceFrameworkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.CreateComplianceFrameworkRequest}
 */
proto.account.CreateComplianceFrameworkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.CreateComplianceFrameworkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.CreateComplianceFrameworkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.CreateComplianceFrameworkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.CreateComplianceFrameworkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.account.CreateComplianceFrameworkRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.CreateComplianceFrameworkRequest} returns this
 */
proto.account.CreateComplianceFrameworkRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.CreateComplianceFrameworkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.CreateComplianceFrameworkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.CreateComplianceFrameworkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.CreateComplianceFrameworkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.CreateComplianceFrameworkResponse}
 */
proto.account.CreateComplianceFrameworkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.CreateComplianceFrameworkResponse;
  return proto.account.CreateComplianceFrameworkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.CreateComplianceFrameworkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.CreateComplianceFrameworkResponse}
 */
proto.account.CreateComplianceFrameworkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.CreateComplianceFrameworkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.CreateComplianceFrameworkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.CreateComplianceFrameworkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.CreateComplianceFrameworkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.account.CreateComplianceFrameworkResponse.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.CreateComplianceFrameworkResponse} returns this
 */
proto.account.CreateComplianceFrameworkResponse.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.FeatureAccessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.FeatureAccessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.FeatureAccessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.FeatureAccessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.FeatureAccessRequest}
 */
proto.account.FeatureAccessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.FeatureAccessRequest;
  return proto.account.FeatureAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.FeatureAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.FeatureAccessRequest}
 */
proto.account.FeatureAccessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.account.FEATURE_ACCESS} */ (reader.readEnum());
      msg.setFeatureType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.FeatureAccessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.FeatureAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.FeatureAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.FeatureAccessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional FEATURE_ACCESS feature_type = 1;
 * @return {!proto.account.FEATURE_ACCESS}
 */
proto.account.FeatureAccessRequest.prototype.getFeatureType = function() {
  return /** @type {!proto.account.FEATURE_ACCESS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.account.FEATURE_ACCESS} value
 * @return {!proto.account.FeatureAccessRequest} returns this
 */
proto.account.FeatureAccessRequest.prototype.setFeatureType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.FeatureAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.FeatureAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.FeatureAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.FeatureAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowAccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.FeatureAccessResponse}
 */
proto.account.FeatureAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.FeatureAccessResponse;
  return proto.account.FeatureAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.FeatureAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.FeatureAccessResponse}
 */
proto.account.FeatureAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.FeatureAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.FeatureAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.FeatureAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.FeatureAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowAccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool allow_access = 1;
 * @return {boolean}
 */
proto.account.FeatureAccessResponse.prototype.getAllowAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.FeatureAccessResponse} returns this
 */
proto.account.FeatureAccessResponse.prototype.setAllowAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.GetScopingConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetScopingConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetScopingConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetScopingConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetScopingConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.account.ScopingField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetScopingConfigResponse}
 */
proto.account.GetScopingConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetScopingConfigResponse;
  return proto.account.GetScopingConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetScopingConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetScopingConfigResponse}
 */
proto.account.GetScopingConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.ScopingField;
      reader.readMessage(value,proto.account.ScopingField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetScopingConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetScopingConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetScopingConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetScopingConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.ScopingField.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScopingField fields = 1;
 * @return {!Array<!proto.account.ScopingField>}
 */
proto.account.GetScopingConfigResponse.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.account.ScopingField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.ScopingField, 1));
};


/**
 * @param {!Array<!proto.account.ScopingField>} value
 * @return {!proto.account.GetScopingConfigResponse} returns this
*/
proto.account.GetScopingConfigResponse.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.ScopingField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.ScopingField}
 */
proto.account.GetScopingConfigResponse.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.ScopingField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.GetScopingConfigResponse} returns this
 */
proto.account.GetScopingConfigResponse.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ScopingModelValue.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ScopingModelValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ScopingModelValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingModelValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ScopingModelValue}
 */
proto.account.ScopingModelValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ScopingModelValue;
  return proto.account.ScopingModelValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ScopingModelValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ScopingModelValue}
 */
proto.account.ScopingModelValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 3:
      var value = /** @type {!proto.provider.PROVIDERS} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ScopingModelValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ScopingModelValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ScopingModelValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingModelValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.provider.PROVIDERS} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.account.ScopingModelValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ScopingModelValue} returns this
 */
proto.account.ScopingModelValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_id = 2;
 * @return {string}
 */
proto.account.ScopingModelValue.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ScopingModelValue} returns this
 */
proto.account.ScopingModelValue.prototype.setModelId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.ScopingModelValue} returns this
 */
proto.account.ScopingModelValue.prototype.clearModelId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingModelValue.prototype.hasModelId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional provider.PROVIDERS provider = 3;
 * @return {!proto.provider.PROVIDERS}
 */
proto.account.ScopingModelValue.prototype.getProvider = function() {
  return /** @type {!proto.provider.PROVIDERS} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.provider.PROVIDERS} value
 * @return {!proto.account.ScopingModelValue} returns this
 */
proto.account.ScopingModelValue.prototype.setProvider = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.ScopingModelValue} returns this
 */
proto.account.ScopingModelValue.prototype.clearProvider = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingModelValue.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.ScopingProviderValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ScopingProviderValue.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ScopingProviderValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ScopingProviderValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingProviderValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerRoleInstancesList: jspb.Message.toObjectList(msg.getProviderRoleInstancesList(),
    proto.account.ScopingModelValue.toObject, includeInstance),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ScopingProviderValue}
 */
proto.account.ScopingProviderValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ScopingProviderValue;
  return proto.account.ScopingProviderValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ScopingProviderValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ScopingProviderValue}
 */
proto.account.ScopingProviderValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.ScopingModelValue;
      reader.readMessage(value,proto.account.ScopingModelValue.deserializeBinaryFromReader);
      msg.addProviderRoleInstances(value);
      break;
    case 2:
      var value = /** @type {!proto.provider.PROVIDER_ROLE} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ScopingProviderValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ScopingProviderValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ScopingProviderValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingProviderValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderRoleInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.ScopingModelValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.provider.PROVIDER_ROLE} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated ScopingModelValue provider_role_instances = 1;
 * @return {!Array<!proto.account.ScopingModelValue>}
 */
proto.account.ScopingProviderValue.prototype.getProviderRoleInstancesList = function() {
  return /** @type{!Array<!proto.account.ScopingModelValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.ScopingModelValue, 1));
};


/**
 * @param {!Array<!proto.account.ScopingModelValue>} value
 * @return {!proto.account.ScopingProviderValue} returns this
*/
proto.account.ScopingProviderValue.prototype.setProviderRoleInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.ScopingModelValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.ScopingModelValue}
 */
proto.account.ScopingProviderValue.prototype.addProviderRoleInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.ScopingModelValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.ScopingProviderValue} returns this
 */
proto.account.ScopingProviderValue.prototype.clearProviderRoleInstancesList = function() {
  return this.setProviderRoleInstancesList([]);
};


/**
 * optional provider.PROVIDER_ROLE role = 2;
 * @return {!proto.provider.PROVIDER_ROLE}
 */
proto.account.ScopingProviderValue.prototype.getRole = function() {
  return /** @type {!proto.provider.PROVIDER_ROLE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.provider.PROVIDER_ROLE} value
 * @return {!proto.account.ScopingProviderValue} returns this
 */
proto.account.ScopingProviderValue.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.ScopingProviderValue} returns this
 */
proto.account.ScopingProviderValue.prototype.clearRole = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingProviderValue.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.ScopingServiceValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ScopingServiceValue.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ScopingServiceValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ScopingServiceValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingServiceValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceRoleInstancesList: jspb.Message.toObjectList(msg.getServiceRoleInstancesList(),
    proto.account.ScopingModelValue.toObject, includeInstance),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ScopingServiceValue}
 */
proto.account.ScopingServiceValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ScopingServiceValue;
  return proto.account.ScopingServiceValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ScopingServiceValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ScopingServiceValue}
 */
proto.account.ScopingServiceValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.ScopingModelValue;
      reader.readMessage(value,proto.account.ScopingModelValue.deserializeBinaryFromReader);
      msg.addServiceRoleInstances(value);
      break;
    case 2:
      var value = /** @type {!proto.service.SERVICE_ROLE} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ScopingServiceValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ScopingServiceValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ScopingServiceValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingServiceValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceRoleInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.ScopingModelValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.service.SERVICE_ROLE} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated ScopingModelValue service_role_instances = 1;
 * @return {!Array<!proto.account.ScopingModelValue>}
 */
proto.account.ScopingServiceValue.prototype.getServiceRoleInstancesList = function() {
  return /** @type{!Array<!proto.account.ScopingModelValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.ScopingModelValue, 1));
};


/**
 * @param {!Array<!proto.account.ScopingModelValue>} value
 * @return {!proto.account.ScopingServiceValue} returns this
*/
proto.account.ScopingServiceValue.prototype.setServiceRoleInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.ScopingModelValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.ScopingModelValue}
 */
proto.account.ScopingServiceValue.prototype.addServiceRoleInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.ScopingModelValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.ScopingServiceValue} returns this
 */
proto.account.ScopingServiceValue.prototype.clearServiceRoleInstancesList = function() {
  return this.setServiceRoleInstancesList([]);
};


/**
 * optional service.SERVICE_ROLE role = 2;
 * @return {!proto.service.SERVICE_ROLE}
 */
proto.account.ScopingServiceValue.prototype.getRole = function() {
  return /** @type {!proto.service.SERVICE_ROLE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.service.SERVICE_ROLE} value
 * @return {!proto.account.ScopingServiceValue} returns this
 */
proto.account.ScopingServiceValue.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.ScopingServiceValue} returns this
 */
proto.account.ScopingServiceValue.prototype.clearRole = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingServiceValue.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account.ScopingFieldValue.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.account.ScopingFieldValue.ValueCase = {
  VALUE_NOT_SET: 0,
  TEXT_VAL: 1,
  ENUM_VAL: 2
};

/**
 * @return {proto.account.ScopingFieldValue.ValueCase}
 */
proto.account.ScopingFieldValue.prototype.getValueCase = function() {
  return /** @type {proto.account.ScopingFieldValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.account.ScopingFieldValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ScopingFieldValue.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ScopingFieldValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ScopingFieldValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingFieldValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    textVal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enumVal: jspb.Message.getFieldWithDefault(msg, 2, 0),
    services: (f = msg.getServices()) && proto.account.ScopingServiceValue.toObject(includeInstance, f),
    providerRoleInstances: (f = msg.getProviderRoleInstances()) && proto.account.ScopingProviderValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ScopingFieldValue}
 */
proto.account.ScopingFieldValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ScopingFieldValue;
  return proto.account.ScopingFieldValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ScopingFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ScopingFieldValue}
 */
proto.account.ScopingFieldValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextVal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnumVal(value);
      break;
    case 3:
      var value = new proto.account.ScopingServiceValue;
      reader.readMessage(value,proto.account.ScopingServiceValue.deserializeBinaryFromReader);
      msg.setServices(value);
      break;
    case 4:
      var value = new proto.account.ScopingProviderValue;
      reader.readMessage(value,proto.account.ScopingProviderValue.deserializeBinaryFromReader);
      msg.setProviderRoleInstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ScopingFieldValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ScopingFieldValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ScopingFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingFieldValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getServices();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.account.ScopingServiceValue.serializeBinaryToWriter
    );
  }
  f = message.getProviderRoleInstances();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.account.ScopingProviderValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text_val = 1;
 * @return {string}
 */
proto.account.ScopingFieldValue.prototype.getTextVal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ScopingFieldValue} returns this
 */
proto.account.ScopingFieldValue.prototype.setTextVal = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.account.ScopingFieldValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.ScopingFieldValue} returns this
 */
proto.account.ScopingFieldValue.prototype.clearTextVal = function() {
  return jspb.Message.setOneofField(this, 1, proto.account.ScopingFieldValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingFieldValue.prototype.hasTextVal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 enum_val = 2;
 * @return {number}
 */
proto.account.ScopingFieldValue.prototype.getEnumVal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.ScopingFieldValue} returns this
 */
proto.account.ScopingFieldValue.prototype.setEnumVal = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.account.ScopingFieldValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.account.ScopingFieldValue} returns this
 */
proto.account.ScopingFieldValue.prototype.clearEnumVal = function() {
  return jspb.Message.setOneofField(this, 2, proto.account.ScopingFieldValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingFieldValue.prototype.hasEnumVal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScopingServiceValue services = 3;
 * @return {?proto.account.ScopingServiceValue}
 */
proto.account.ScopingFieldValue.prototype.getServices = function() {
  return /** @type{?proto.account.ScopingServiceValue} */ (
    jspb.Message.getWrapperField(this, proto.account.ScopingServiceValue, 3));
};


/**
 * @param {?proto.account.ScopingServiceValue|undefined} value
 * @return {!proto.account.ScopingFieldValue} returns this
*/
proto.account.ScopingFieldValue.prototype.setServices = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.ScopingFieldValue} returns this
 */
proto.account.ScopingFieldValue.prototype.clearServices = function() {
  return this.setServices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingFieldValue.prototype.hasServices = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScopingProviderValue provider_role_instances = 4;
 * @return {?proto.account.ScopingProviderValue}
 */
proto.account.ScopingFieldValue.prototype.getProviderRoleInstances = function() {
  return /** @type{?proto.account.ScopingProviderValue} */ (
    jspb.Message.getWrapperField(this, proto.account.ScopingProviderValue, 4));
};


/**
 * @param {?proto.account.ScopingProviderValue|undefined} value
 * @return {!proto.account.ScopingFieldValue} returns this
*/
proto.account.ScopingFieldValue.prototype.setProviderRoleInstances = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.ScopingFieldValue} returns this
 */
proto.account.ScopingFieldValue.prototype.clearProviderRoleInstances = function() {
  return this.setProviderRoleInstances(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingFieldValue.prototype.hasProviderRoleInstances = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ScopingField.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ScopingField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ScopingField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingField.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: (f = msg.getValue()) && proto.account.ScopingFieldValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ScopingField}
 */
proto.account.ScopingField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ScopingField;
  return proto.account.ScopingField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ScopingField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ScopingField}
 */
proto.account.ScopingField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.account.SCOPING_FIELDS} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.account.ScopingFieldValue;
      reader.readMessage(value,proto.account.ScopingFieldValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ScopingField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ScopingField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ScopingField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ScopingField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.account.ScopingFieldValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional SCOPING_FIELDS field = 1;
 * @return {!proto.account.SCOPING_FIELDS}
 */
proto.account.ScopingField.prototype.getField = function() {
  return /** @type {!proto.account.SCOPING_FIELDS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.account.SCOPING_FIELDS} value
 * @return {!proto.account.ScopingField} returns this
 */
proto.account.ScopingField.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ScopingFieldValue value = 2;
 * @return {?proto.account.ScopingFieldValue}
 */
proto.account.ScopingField.prototype.getValue = function() {
  return /** @type{?proto.account.ScopingFieldValue} */ (
    jspb.Message.getWrapperField(this, proto.account.ScopingFieldValue, 2));
};


/**
 * @param {?proto.account.ScopingFieldValue|undefined} value
 * @return {!proto.account.ScopingField} returns this
*/
proto.account.ScopingField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.ScopingField} returns this
 */
proto.account.ScopingField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.ScopingField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.UpdateScopingConfigRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.UpdateScopingConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.UpdateScopingConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.UpdateScopingConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UpdateScopingConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.account.ScopingField.toObject, includeInstance),
    hasCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    scopingTopic: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastPageSeen: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.UpdateScopingConfigRequest}
 */
proto.account.UpdateScopingConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.UpdateScopingConfigRequest;
  return proto.account.UpdateScopingConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.UpdateScopingConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.UpdateScopingConfigRequest}
 */
proto.account.UpdateScopingConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.ScopingField;
      reader.readMessage(value,proto.account.ScopingField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCompleted(value);
      break;
    case 3:
      var value = /** @type {!proto.account.SCOPING_TOPICS} */ (reader.readEnum());
      msg.setScopingTopic(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastPageSeen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.UpdateScopingConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.UpdateScopingConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.UpdateScopingConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UpdateScopingConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.ScopingField.serializeBinaryToWriter
    );
  }
  f = message.getHasCompleted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getScopingTopic();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLastPageSeen();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * repeated ScopingField fields = 1;
 * @return {!Array<!proto.account.ScopingField>}
 */
proto.account.UpdateScopingConfigRequest.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.account.ScopingField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.ScopingField, 1));
};


/**
 * @param {!Array<!proto.account.ScopingField>} value
 * @return {!proto.account.UpdateScopingConfigRequest} returns this
*/
proto.account.UpdateScopingConfigRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.ScopingField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.ScopingField}
 */
proto.account.UpdateScopingConfigRequest.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.ScopingField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.UpdateScopingConfigRequest} returns this
 */
proto.account.UpdateScopingConfigRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional bool has_completed = 2;
 * @return {boolean}
 */
proto.account.UpdateScopingConfigRequest.prototype.getHasCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.UpdateScopingConfigRequest} returns this
 */
proto.account.UpdateScopingConfigRequest.prototype.setHasCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional SCOPING_TOPICS scoping_topic = 3;
 * @return {!proto.account.SCOPING_TOPICS}
 */
proto.account.UpdateScopingConfigRequest.prototype.getScopingTopic = function() {
  return /** @type {!proto.account.SCOPING_TOPICS} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.account.SCOPING_TOPICS} value
 * @return {!proto.account.UpdateScopingConfigRequest} returns this
 */
proto.account.UpdateScopingConfigRequest.prototype.setScopingTopic = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 last_page_seen = 4;
 * @return {number}
 */
proto.account.UpdateScopingConfigRequest.prototype.getLastPageSeen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.UpdateScopingConfigRequest} returns this
 */
proto.account.UpdateScopingConfigRequest.prototype.setLastPageSeen = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.PlgDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.account.PlgDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.PlgDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.PlgDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    plgType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.PlgDetails}
 */
proto.account.PlgDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.PlgDetails;
  return proto.account.PlgDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.PlgDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.PlgDetails}
 */
proto.account.PlgDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.account.PLG_TYPE} */ (reader.readEnum());
      msg.setPlgType(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.PlgDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.PlgDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.PlgDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.PlgDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlgType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional PLG_TYPE plg_type = 1;
 * @return {!proto.account.PLG_TYPE}
 */
proto.account.PlgDetails.prototype.getPlgType = function() {
  return /** @type {!proto.account.PLG_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.account.PLG_TYPE} value
 * @return {!proto.account.PlgDetails} returns this
 */
proto.account.PlgDetails.prototype.setPlgType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp completion_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.PlgDetails.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.PlgDetails} returns this
*/
proto.account.PlgDetails.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.PlgDetails} returns this
 */
proto.account.PlgDetails.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.PlgDetails.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.AttemptEmailSignupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.AttemptEmailSignupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.AttemptEmailSignupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AttemptEmailSignupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.AttemptEmailSignupRequest}
 */
proto.account.AttemptEmailSignupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.AttemptEmailSignupRequest;
  return proto.account.AttemptEmailSignupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.AttemptEmailSignupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.AttemptEmailSignupRequest}
 */
proto.account.AttemptEmailSignupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.AttemptEmailSignupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.AttemptEmailSignupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.AttemptEmailSignupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AttemptEmailSignupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.account.AttemptEmailSignupRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AttemptEmailSignupRequest} returns this
 */
proto.account.AttemptEmailSignupRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.AttemptEmailSignupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.AttemptEmailSignupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.AttemptEmailSignupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AttemptEmailSignupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isVerificationNeeded: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isResponseOk: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.AttemptEmailSignupResponse}
 */
proto.account.AttemptEmailSignupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.AttemptEmailSignupResponse;
  return proto.account.AttemptEmailSignupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.AttemptEmailSignupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.AttemptEmailSignupResponse}
 */
proto.account.AttemptEmailSignupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVerificationNeeded(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResponseOk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.AttemptEmailSignupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.AttemptEmailSignupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.AttemptEmailSignupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.AttemptEmailSignupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsVerificationNeeded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsResponseOk();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool is_verification_needed = 1;
 * @return {boolean}
 */
proto.account.AttemptEmailSignupResponse.prototype.getIsVerificationNeeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.AttemptEmailSignupResponse} returns this
 */
proto.account.AttemptEmailSignupResponse.prototype.setIsVerificationNeeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.account.AttemptEmailSignupResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.AttemptEmailSignupResponse} returns this
 */
proto.account.AttemptEmailSignupResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_response_ok = 3;
 * @return {boolean}
 */
proto.account.AttemptEmailSignupResponse.prototype.getIsResponseOk = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.AttemptEmailSignupResponse} returns this
 */
proto.account.AttemptEmailSignupResponse.prototype.setIsResponseOk = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ReceiveCognitoVerificationCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ReceiveCognitoVerificationCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ReceiveCognitoVerificationCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ReceiveCognitoVerificationCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    verificationCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ReceiveCognitoVerificationCodeRequest}
 */
proto.account.ReceiveCognitoVerificationCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ReceiveCognitoVerificationCodeRequest;
  return proto.account.ReceiveCognitoVerificationCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ReceiveCognitoVerificationCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ReceiveCognitoVerificationCodeRequest}
 */
proto.account.ReceiveCognitoVerificationCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ReceiveCognitoVerificationCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ReceiveCognitoVerificationCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ReceiveCognitoVerificationCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ReceiveCognitoVerificationCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVerificationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.account.ReceiveCognitoVerificationCodeRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ReceiveCognitoVerificationCodeRequest} returns this
 */
proto.account.ReceiveCognitoVerificationCodeRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string verification_code = 2;
 * @return {string}
 */
proto.account.ReceiveCognitoVerificationCodeRequest.prototype.getVerificationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ReceiveCognitoVerificationCodeRequest} returns this
 */
proto.account.ReceiveCognitoVerificationCodeRequest.prototype.setVerificationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ReceiveCognitoVerificationCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ReceiveCognitoVerificationCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ReceiveCognitoVerificationCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isLoginIssued: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isCodeAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    token: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ReceiveCognitoVerificationCodeResponse}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ReceiveCognitoVerificationCodeResponse;
  return proto.account.ReceiveCognitoVerificationCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ReceiveCognitoVerificationCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ReceiveCognitoVerificationCodeResponse}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLoginIssued(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCodeAccepted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ReceiveCognitoVerificationCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ReceiveCognitoVerificationCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ReceiveCognitoVerificationCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsLoginIssued();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsCodeAccepted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool is_login_issued = 1;
 * @return {boolean}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.getIsLoginIssued = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.ReceiveCognitoVerificationCodeResponse} returns this
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.setIsLoginIssued = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_code_accepted = 2;
 * @return {boolean}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.getIsCodeAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.ReceiveCognitoVerificationCodeResponse} returns this
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.setIsCodeAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ReceiveCognitoVerificationCodeResponse} returns this
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string token = 4;
 * @return {string}
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ReceiveCognitoVerificationCodeResponse} returns this
 */
proto.account.ReceiveCognitoVerificationCodeResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ResendVerificationCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ResendVerificationCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ResendVerificationCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResendVerificationCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ResendVerificationCodeRequest}
 */
proto.account.ResendVerificationCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ResendVerificationCodeRequest;
  return proto.account.ResendVerificationCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ResendVerificationCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ResendVerificationCodeRequest}
 */
proto.account.ResendVerificationCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ResendVerificationCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ResendVerificationCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ResendVerificationCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResendVerificationCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.account.ResendVerificationCodeRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ResendVerificationCodeRequest} returns this
 */
proto.account.ResendVerificationCodeRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.ResendVerificationCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.ResendVerificationCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.ResendVerificationCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResendVerificationCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isCodeIssued: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.ResendVerificationCodeResponse}
 */
proto.account.ResendVerificationCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.ResendVerificationCodeResponse;
  return proto.account.ResendVerificationCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.ResendVerificationCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.ResendVerificationCodeResponse}
 */
proto.account.ResendVerificationCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCodeIssued(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.ResendVerificationCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.ResendVerificationCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.ResendVerificationCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.ResendVerificationCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsCodeIssued();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_code_issued = 1;
 * @return {boolean}
 */
proto.account.ResendVerificationCodeResponse.prototype.getIsCodeIssued = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.ResendVerificationCodeResponse} returns this
 */
proto.account.ResendVerificationCodeResponse.prototype.setIsCodeIssued = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.account.ResendVerificationCodeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.ResendVerificationCodeResponse} returns this
 */
proto.account.ResendVerificationCodeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.JoinReadOnlyAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.JoinReadOnlyAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.JoinReadOnlyAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.JoinReadOnlyAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.JoinReadOnlyAccountRequest}
 */
proto.account.JoinReadOnlyAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.JoinReadOnlyAccountRequest;
  return proto.account.JoinReadOnlyAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.JoinReadOnlyAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.JoinReadOnlyAccountRequest}
 */
proto.account.JoinReadOnlyAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.JoinReadOnlyAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.JoinReadOnlyAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.JoinReadOnlyAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.JoinReadOnlyAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.account.JoinReadOnlyAccountRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.JoinReadOnlyAccountRequest} returns this
 */
proto.account.JoinReadOnlyAccountRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.SystemDescriptionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.account.SystemDescriptionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.SystemDescriptionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SystemDescriptionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasCompanyName: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    hasCompanyCountry: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hasCity: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasStateProvince: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasCloudProvider: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasSourceCode: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hasHrisSystem: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    hasPerformanceEvaluations: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    hasAts: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hasBackgroundChecks: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    hasSecurityTraining: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    hasDeviceManagement: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    hasIam: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hasProductivitySuite: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasFormalDocuments: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    hasSupportRequests: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    hasIncidentManagement: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    hasInternalIncidentManagement: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    hasMonitoring: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    hasVulnerabilityScanning: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hasThreatPrevention: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    hasConfigurationManagement: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    hasManagementMonitoring: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    hasCodeScanning: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    hasArchDiagram: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    hasSoftwareDevelopment: jspb.Message.getBooleanFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.SystemDescriptionStatus}
 */
proto.account.SystemDescriptionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.SystemDescriptionStatus;
  return proto.account.SystemDescriptionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.SystemDescriptionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.SystemDescriptionStatus}
 */
proto.account.SystemDescriptionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCompanyName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCompanyCountry(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCity(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStateProvince(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCloudProvider(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSourceCode(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasHrisSystem(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPerformanceEvaluations(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAts(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackgroundChecks(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSecurityTraining(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeviceManagement(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasIam(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasProductivitySuite(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFormalDocuments(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSupportRequests(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasIncidentManagement(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInternalIncidentManagement(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMonitoring(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasVulnerabilityScanning(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasThreatPrevention(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasConfigurationManagement(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasManagementMonitoring(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCodeScanning(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasArchDiagram(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSoftwareDevelopment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.SystemDescriptionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.SystemDescriptionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.SystemDescriptionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.SystemDescriptionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasCompanyName();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHasCompanyCountry();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHasCity();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasStateProvince();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasCloudProvider();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasSourceCode();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasHrisSystem();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getHasPerformanceEvaluations();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getHasAts();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHasBackgroundChecks();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getHasSecurityTraining();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getHasDeviceManagement();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getHasIam();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHasProductivitySuite();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasFormalDocuments();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHasSupportRequests();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getHasIncidentManagement();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getHasInternalIncidentManagement();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getHasMonitoring();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getHasVulnerabilityScanning();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHasThreatPrevention();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getHasConfigurationManagement();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getHasManagementMonitoring();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getHasCodeScanning();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getHasArchDiagram();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getHasSoftwareDevelopment();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional bool has_company_name = 1;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasCompanyName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasCompanyName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool has_company_country = 2;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasCompanyCountry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasCompanyCountry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool has_city = 3;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasCity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasCity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_state_province = 4;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasStateProvince = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasStateProvince = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_cloud_provider = 5;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasCloudProvider = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasCloudProvider = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_source_code = 6;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasSourceCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasSourceCode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool has_hris_system = 7;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasHrisSystem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasHrisSystem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool has_performance_evaluations = 8;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasPerformanceEvaluations = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasPerformanceEvaluations = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool has_ats = 9;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasAts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasAts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool has_background_checks = 10;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasBackgroundChecks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasBackgroundChecks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool has_security_training = 11;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasSecurityTraining = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasSecurityTraining = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool has_device_management = 12;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasDeviceManagement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasDeviceManagement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool has_iam = 13;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasIam = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasIam = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool has_productivity_suite = 14;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasProductivitySuite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasProductivitySuite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool has_formal_documents = 15;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasFormalDocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasFormalDocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool has_support_requests = 16;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasSupportRequests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasSupportRequests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool has_incident_management = 17;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasIncidentManagement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasIncidentManagement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool has_internal_incident_management = 18;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasInternalIncidentManagement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasInternalIncidentManagement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool has_monitoring = 19;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasMonitoring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasMonitoring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool has_vulnerability_scanning = 20;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasVulnerabilityScanning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasVulnerabilityScanning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool has_threat_prevention = 21;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasThreatPrevention = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasThreatPrevention = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool has_configuration_management = 22;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasConfigurationManagement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasConfigurationManagement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool has_management_monitoring = 23;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasManagementMonitoring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasManagementMonitoring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool has_code_scanning = 24;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasCodeScanning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasCodeScanning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool has_arch_diagram = 25;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasArchDiagram = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasArchDiagram = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool has_software_development = 26;
 * @return {boolean}
 */
proto.account.SystemDescriptionStatus.prototype.getHasSoftwareDevelopment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account.SystemDescriptionStatus} returns this
 */
proto.account.SystemDescriptionStatus.prototype.setHasSoftwareDevelopment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GenerateSystemDescriptionDocResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GenerateSystemDescriptionDocResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GenerateSystemDescriptionDocResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: msg.getDocument_asB64(),
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GenerateSystemDescriptionDocResponse}
 */
proto.account.GenerateSystemDescriptionDocResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GenerateSystemDescriptionDocResponse;
  return proto.account.GenerateSystemDescriptionDocResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GenerateSystemDescriptionDocResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GenerateSystemDescriptionDocResponse}
 */
proto.account.GenerateSystemDescriptionDocResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GenerateSystemDescriptionDocResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GenerateSystemDescriptionDocResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GenerateSystemDescriptionDocResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes document = 1;
 * @return {!(string|Uint8Array)}
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.getDocument = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes document = 1;
 * This is a type-conversion wrapper around `getDocument()`
 * @return {string}
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.getDocument_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDocument()));
};


/**
 * optional bytes document = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDocument()`
 * @return {!Uint8Array}
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.getDocument_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDocument()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.account.GenerateSystemDescriptionDocResponse} returns this
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.setDocument = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GenerateSystemDescriptionDocResponse} returns this
 */
proto.account.GenerateSystemDescriptionDocResponse.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.UsageMetricUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.account.UsageMetricUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.UsageMetricUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UsageMetricUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    controlTests: jspb.Message.getFieldWithDefault(msg, 3, 0),
    qcAdvanced: jspb.Message.getFieldWithDefault(msg, 4, 0),
    qcBasic: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.UsageMetricUnit}
 */
proto.account.UsageMetricUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.UsageMetricUnit;
  return proto.account.UsageMetricUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.UsageMetricUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.UsageMetricUnit}
 */
proto.account.UsageMetricUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlTests(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQcAdvanced(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQcBasic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.UsageMetricUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.UsageMetricUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.UsageMetricUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.UsageMetricUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getControlTests();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getQcAdvanced();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getQcBasic();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.UsageMetricUnit.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.UsageMetricUnit} returns this
*/
proto.account.UsageMetricUnit.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UsageMetricUnit} returns this
 */
proto.account.UsageMetricUnit.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UsageMetricUnit.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account.UsageMetricUnit.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account.UsageMetricUnit} returns this
*/
proto.account.UsageMetricUnit.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account.UsageMetricUnit} returns this
 */
proto.account.UsageMetricUnit.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account.UsageMetricUnit.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 control_tests = 3;
 * @return {number}
 */
proto.account.UsageMetricUnit.prototype.getControlTests = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.UsageMetricUnit} returns this
 */
proto.account.UsageMetricUnit.prototype.setControlTests = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 qc_advanced = 4;
 * @return {number}
 */
proto.account.UsageMetricUnit.prototype.getQcAdvanced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.UsageMetricUnit} returns this
 */
proto.account.UsageMetricUnit.prototype.setQcAdvanced = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 qc_basic = 5;
 * @return {number}
 */
proto.account.UsageMetricUnit.prototype.getQcBasic = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.account.UsageMetricUnit} returns this
 */
proto.account.UsageMetricUnit.prototype.setQcBasic = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account.GetUsageMetricsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetUsageMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetUsageMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetUsageMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetUsageMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usageList: jspb.Message.toObjectList(msg.getUsageList(),
    proto.account.UsageMetricUnit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetUsageMetricsResponse}
 */
proto.account.GetUsageMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetUsageMetricsResponse;
  return proto.account.GetUsageMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetUsageMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetUsageMetricsResponse}
 */
proto.account.GetUsageMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account.UsageMetricUnit;
      reader.readMessage(value,proto.account.UsageMetricUnit.deserializeBinaryFromReader);
      msg.addUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetUsageMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetUsageMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetUsageMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetUsageMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account.UsageMetricUnit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UsageMetricUnit usage = 1;
 * @return {!Array<!proto.account.UsageMetricUnit>}
 */
proto.account.GetUsageMetricsResponse.prototype.getUsageList = function() {
  return /** @type{!Array<!proto.account.UsageMetricUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account.UsageMetricUnit, 1));
};


/**
 * @param {!Array<!proto.account.UsageMetricUnit>} value
 * @return {!proto.account.GetUsageMetricsResponse} returns this
*/
proto.account.GetUsageMetricsResponse.prototype.setUsageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account.UsageMetricUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account.UsageMetricUnit}
 */
proto.account.GetUsageMetricsResponse.prototype.addUsage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account.UsageMetricUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account.GetUsageMetricsResponse} returns this
 */
proto.account.GetUsageMetricsResponse.prototype.clearUsageList = function() {
  return this.setUsageList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account.GetUsageMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account.GetUsageMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account.GetUsageMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetUsageMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cadence: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account.GetUsageMetricsRequest}
 */
proto.account.GetUsageMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account.GetUsageMetricsRequest;
  return proto.account.GetUsageMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account.GetUsageMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account.GetUsageMetricsRequest}
 */
proto.account.GetUsageMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {!proto.account.Cadence} */ (reader.readEnum());
      msg.setCadence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account.GetUsageMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account.GetUsageMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account.GetUsageMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account.GetUsageMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCadence();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.account.GetUsageMetricsRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account.GetUsageMetricsRequest} returns this
 */
proto.account.GetUsageMetricsRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Cadence cadence = 2;
 * @return {!proto.account.Cadence}
 */
proto.account.GetUsageMetricsRequest.prototype.getCadence = function() {
  return /** @type {!proto.account.Cadence} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.account.Cadence} value
 * @return {!proto.account.GetUsageMetricsRequest} returns this
 */
proto.account.GetUsageMetricsRequest.prototype.setCadence = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.account.AccountState = {
  ACCT_ACTIVE: 0,
  ACCT_CLOSED: 1
};

/**
 * @enum {number}
 */
proto.account.USE_CASE = {
  SAAS_BUYER: 0,
  SAAS_PROVIDER: 1
};

/**
 * @enum {number}
 */
proto.account.USER_ROLE = {
  ADMIN: 0,
  USER: 1,
  AUDITOR: 2,
  READONLY: 3
};

/**
 * @enum {number}
 */
proto.account.PURPOSE = {
  CUSTOMER: 0,
  DEMO: 1,
  TEST: 2,
  READONLY_ACCOUNT: 3
};

/**
 * @enum {number}
 */
proto.account.ORG_SIZE = {
  ORG_SIZE_1_10: 0,
  ORG_SIZE_11_50: 1,
  ORG_SIZE_51_100: 2,
  ORG_SIZE_101_500: 3,
  ORG_SIZE_501_1000: 4,
  ORG_SIZE_1001_5000: 5,
  ORG_SIZE_5001_PLUS: 6
};

/**
 * @enum {number}
 */
proto.account.ORG_INDUSTRY = {
  ACCOUNTING: 0,
  AUTOMOTIVE: 1,
  BIOTECHNOLOGY: 2,
  COMPUTER_GAMES: 3,
  COMPUTER_HARDWARE: 4,
  COMPUTER_SOFTWARE: 5,
  CONSUMER_GOODS: 6,
  CONSUMER_SERVICES: 7,
  EDUCATION: 8,
  ENTERTAINMENT: 9,
  FINANCIAL_SERVICES: 10,
  FOOD_AND_BEVERAGE: 11,
  GOVERNMENT_RELATIONS: 12,
  HEALTHCARE: 13,
  INSURANCE: 14,
  LEGAL_SERVICES: 15,
  LEISURE_TRAVEL_TOURISM: 16,
  LOGISTICS_AND_SUPPLY_CHAIN: 17,
  MANAGEMENT_CONSULTING: 18,
  MARKETING_AND_ADVERTISING: 19,
  PHARMACEUTICALS: 20,
  REAL_ESTATE: 21,
  RETAIL: 22
};

/**
 * @enum {number}
 */
proto.account.ORG_LOCATION = {
  ALL_REMOTE: 0,
  HYBRID: 1,
  ONSITE: 2
};

/**
 * @enum {number}
 */
proto.account.ORG_DEVICE_OWNER = {
  EMPLOYEE: 0,
  COMPANY: 1,
  BOTH: 2
};

/**
 * @enum {number}
 */
proto.account.ORG_DEVICE_OS = {
  OSX: 0,
  WINDOWS: 1,
  LINUX: 2,
  OTHER: 3
};

/**
 * @enum {number}
 */
proto.account.SCOPING_TOPICS = {
  WELCOME: 0,
  BUSINESS_FACTS: 1,
  LOCATION: 2,
  PRIMARY_INFRASTRUCTURE_SYSTEMS_IN_SCOPE: 3,
  LAPTOPS_AND_DEVICES: 4,
  EVERYDAY_SYSTEMS: 5,
  DOING_GREAT: 6,
  PEOPLE: 7,
  SECURITY_AND_MONITORING_FOR_ENGINEERING: 8,
  FINISH: 9
};

/**
 * @enum {number}
 */
proto.account.UserState = {
  USER_ACTIVE: 0,
  USER_INACTIVE: 1
};

/**
 * @enum {number}
 */
proto.account.BuyerOrigin = {
  REPORT_SCAN: 0,
  SECURITY_QUESTIONNAIRE: 1
};

/**
 * @enum {number}
 */
proto.account.FEATURE_ACCESS = {
  AUDITBOT_BETA: 0,
  SECURITY_QUESTIONNAIRE_BETA: 1,
  CONTINUOUS_MONITORING: 2,
  REMOVE_CONTROL_TESTS: 3,
  ANECDOTES_EVIDENCE_INDEX: 4
};

/**
 * @enum {number}
 */
proto.account.SCOPING_FIELDS = {
  COMPANY_NAME: 0,
  COMPANY_WEBSITE: 1,
  COMPANY_SIZE: 2,
  COMPANY_INDUSTRY: 3,
  COMPANY_COUNTRY: 4,
  WORK_LOCATION: 5,
  COMPANY_ADDRESS: 6,
  CLOUD_PROVIDER: 7,
  CLOUD_PROVIDER_NAME: 8,
  SOURCE_CODE: 9,
  SOURCE_CODE_NAME: 10,
  PRODUCTIVITY_SUITE: 11,
  PRODUCTIVITY_SUITE_NAME: 12,
  DEVICE_OWNER: 13,
  DEVICE_OS: 14,
  DEVICE_MANAGEMENT: 15,
  IAM: 16,
  FORMAL_DOCUMENTS: 17,
  SUPPORT_REQUESTS: 18,
  IT_REQUESTS: 19,
  SOFTWARE_DEVELOPMENT: 20,
  TECHNICAL_OPS: 21,
  HRIS_SYSTEM: 22,
  PERFORMANCE_EVALUATIONS: 23,
  ATS: 24,
  BACKGROUND_CHECKS: 25,
  SECURITY_TRAINING: 26,
  VULNERABILITY_SCANNING: 27,
  MONITORING: 28,
  CONFIGURATION_MANAGEMENT: 29,
  THREAT_PREVENTION: 30,
  MANAGEMENT_MONITORING: 31,
  CODE_SCANNING: 32,
  INCIDENT_MANAGEMENT: 33,
  STREET_ADDRESS: 34,
  SECONDARY_ADDRESS: 35,
  CITY: 36,
  STATE_PROVINCE: 37,
  POSTAL_CODE: 38,
  INTERNAL_INCIDENT_MANAGEMENT: 39
};

/**
 * @enum {number}
 */
proto.account.PLG_TYPE = {
  UNKNOWN: 0,
  REPORT_SCAN_PLG_TYPE: 1,
  SECURITY_QUESTIONNAIRE_PLG_TYPE: 2
};

/**
 * @enum {number}
 */
proto.account.Cadence = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2
};

goog.object.extend(exports, proto.account);
