import React from 'react'
import { RootPage } from 'src/components/Reusable/RootPage'
import {
  ROOT_ID_PARAM_TYPE,
  SubRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { ServicesIndexPage } from './index/ServicesIndexPage'
import { ServicesShowPageRouter } from './show/ServicesShowPageRouter'

export enum ServiceAbsoluteRoutes {
  ROOT = 'services',
  RECEPTORS = 'services/receptors',
  SERVICES = 'services/services',
}

export enum ServiceSubRoutes {
  RECEPTORS = 'receptors',
  SERVICES = 'services',
}

/**
 * Models a scope change request performed when selecting one of the checkboxes
 * i.e expect only one of the fields to be set per request
 */
export type ServiceScopeStateUpdate = {
  productDelivery?: boolean
  hasCustomerData?: boolean
  supportingTool?: boolean
  included?: boolean
}

//[AP-6644] TODO: Updated xbuild to use camel case for Service and Receptor objects

export const ServiceRootPage = (): JSX.Element => (
  <RootPage
    idParamType={ROOT_ID_PARAM_TYPE.SERVICE_ID}
    indexRoute={SubRoutes.INDEX}
    indexPage={<ServicesIndexPage />}
    showRoute={SubRoutes.SHOW}
    showPage={<ServicesShowPageRouter />}
  />
)
