import React from 'react'
import { SOURCE_TYPE } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

export enum SourceFilterValues {
  RECEPTOR = 'receptor',
  MANUAL = 'manual',
  DOCUMENT_MANAGEMENT_SYSTEM = 'document_management_system',
}

enum SourceFilterNames {
  RECEPTOR = 'Receptor',
  MANUAL = 'Manual',
  DOCUMENT_MANAGEMENT_SYSTEM = 'Document Management System',
}

const sourceFilterNameToValue = Object.freeze({
  [SourceFilterNames.RECEPTOR]: SourceFilterValues.RECEPTOR,
  [SourceFilterNames.MANUAL]: SourceFilterValues.MANUAL,
  [SourceFilterNames.DOCUMENT_MANAGEMENT_SYSTEM]:
    SourceFilterValues.DOCUMENT_MANAGEMENT_SYSTEM,
})

export const sourceFilterValueToSourceType = Object.freeze({
  [SourceFilterValues.RECEPTOR]: SOURCE_TYPE.AUTOMATED,
  [SourceFilterValues.MANUAL]: SOURCE_TYPE.MANUAL,
  [SourceFilterValues.DOCUMENT_MANAGEMENT_SYSTEM]:
    SOURCE_TYPE.DOCUMENT_MANAGEMENT_SYSTEM,
})

export const SourceFilterDropdown = (): JSX.Element => {
  const filterValues = Object.values(SourceFilterNames).map(
    (filterName: SourceFilterNames) => ({
      value: sourceFilterNameToValue[filterName],
      label: filterName,
    }),
  )

  return (
    <FilterDropdown
      filterParam={FilterParam.SOURCE}
      filterName={FilterName.SOURCE}
      filterValues={filterValues}
    />
  )
}
