import { RISK_LEVEL } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import styled from 'styled-components/macro'
import palette from 'src/designSystem/variables/palette'
import { CircleCheckFillSVG, ErrorSVG } from 'src/components/Icons/Basic'
import { StickyGridHeader } from 'src/components/Reusable/Grid/Grid.styles'
import { Dropdown } from 'react-bootstrap'
import { RISK_LEVEL_TO_COLOR } from './vendors.constants'

export const RiskLevelChip = styled.div<{ $riskLevel: RISK_LEVEL }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.75em;
  border-radius: 0.5em;
  font-size: 0.95em;
  text-wrap: nowrap;
  background-color: ${({ $riskLevel }) => RISK_LEVEL_TO_COLOR[$riskLevel]};
`

export const AttestationStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 0.95em;
`

export const AttestationFreshIcon = styled(CircleCheckFillSVG)`
  height: 16px;
  width: 16px;
`

export const AttestationStaleIcon = styled(ErrorSVG)`
  height: 18px;
  width: 18px;
  position: relative;
  left: -1px;
  path {
    fill: ${palette.magenta[400]};
  }
`

export const StyledVendorsGridHeader = styled(StickyGridHeader)<{
  isCentered?: boolean
}>`
  ${({ isCentered }) => isCentered && `justify-content: center;`}
`
export const SectionDivider = styled.hr`
  margin: 0 0 1em 0;
`

export const RiskTierTitle = styled.div`
  font-size: 14px;
`

export const RiskTierInfoContainer = styled.div`
  font-size: 14px;
  margin: 0.25em 0 1em 0;
`

export const RiskTierOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 14px;
  margin-left: 0.5em;
`

export const RiskTierRadioButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`

export const RiskTierError = styled.div`
  color: ${palette.magenta[500]};
  font-size: 0.9em;
  margin-top: 0.5em;
  height: 1.5em;
`

export const VendorDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1em;

  label {
    font-weight: 500;
    margin-bottom: 0.5em;

    &:nth-of-type(2) {
      margin-top: 1em;
    }
  }

  p {
    margin-bottom: 1em;

    a {
      margin-left: 0.5em;
      text-decoration: none;
      font-weight: 500;
    }
  }
`

export const VendorTab = styled.div`
  padding-bottom: 0.5em;

  .vendor-tab-btn {
    margin-top: 1em;
  }
`

export const TierSummary = styled.p`
  margin-top: 1em;
`

export const VendorToggle = styled(Dropdown.Toggle)<{ $bgColor: string }>`
  border: none;
  color: ${palette.neutral.black};
  background-color: ${({ $bgColor }) => $bgColor};

  &::after {
    margin-left: 0.5em;
  }

  &.btn-primary.dropdown-toggle {
    border: none;
    color: ${palette.neutral.black};
    background-color: ${({ $bgColor }) => $bgColor};
  }
`
