import { Message } from 'google-protobuf'
import { SWRResponse } from 'swr'
import { SWRInfiniteResponse } from 'swr/infinite'

/**
 * ItemList is a generic message that follows the GRPC `list` API convention
 * of returning a message with an attribute `repeated Item items`. This
 * convention is followed by Controls, Policies, Departments, Frameworks.
 */
export type ItemsList<Response extends Message, Item> = Response & {
  setItemsList: (value: Array<Item>) => Response
  getItemsList: () => Array<Item>
}

export type MutateFunc<Item, Response extends Message> = () => Promise<
  ItemsList<Response, Item> | undefined
>

export type MutateLocalFunc<Item> = () => Promise<Item | undefined>

/**
 * Helper type that augments the SWRResponse with a loading field.
 */
export type GrpcResponse<Response> = SWRResponse<Response> & {
  loading: boolean
}

export function NewGrpcResponse<Response extends Message>(
  response: SWRResponse,
): GrpcResponse<Response> {
  return {
    ...response,
    loading: !response.data && !response.error,
  }
}

export type GrpcInfiniteResponse<Response> = SWRInfiniteResponse<Response> & {
  loading: boolean
}

export const NewGrpcInfiniteResponse = <Response extends Message>(
  response: SWRInfiniteResponse,
): GrpcInfiniteResponse<Response> => ({
  ...response,
  loading: !response.data && !response.error,
})
