import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  GetPoliciesForComplianceFrameworkRequest,
  PolicyRecords,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const usePoliciesForComplianceFramework = (
  complianceFrameworkId: string,
  shouldFetch = true,
): GrpcResponse<PolicyRecords> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getPoliciesForComplianceFramework,
    new GetPoliciesForComplianceFrameworkRequest().setComplianceFrameworkId(
      complianceFrameworkId,
    ),
    shouldFetch,
  )
  return NewGrpcResponse(response)
}
