import React, { FormEventHandler, useEffect, useState } from 'react'
import {
  AddOrUpdateEvidenceGroupRequest,
  EVIDENCE_LINKAGE_TYPE,
  EvidenceLinkage,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { MultiSelectForm } from 'src/components/Reusable/Forms/MultiSelectForm'
import { getPluralization } from 'src/Utils/globalHelpers'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useGrpcRevalidateByMethod } from 'src/components'
import { SelectControlsRowItem } from 'src/components/Reusable/Forms/MultiSelectFromComponents/SelectControlsRowItem'
import { useLocation } from 'react-router'
import { EvidenceAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useControls } from 'src/components/async/model/control'
import { NoRecommendedTemplates } from 'src/components/ModalForms/Frameworks/AddFramework/NoRecommendedTemplates'
import { AuditsSVG } from 'src/components/Icons/Basic'
import { useInAudit } from 'src/context/AuditContext'
import { showInfoToast } from 'src/Utils/helpers/toast'
import log from 'loglevel'
import isFunction from 'lodash/isFunction'
import {
  PinkInfoBox,
  PinkInfoBoxBody,
  PinkInfoBoxHeader,
} from 'src/components/Reusable/Text/PinkInfoBox.styles'
import {
  useAddOrUpdateEvidenceGroups,
  useInvalidateEvidenceGroups,
} from '../evidence.hooks'

export const LinkEvidenceToControlsModal = ({
  requests,
  onHideFunc,
  existingControlIds,
}: {
  requests: AddOrUpdateEvidenceGroupRequest[]
  onHideFunc?: () => void
  existingControlIds?: string[]
}): JSX.Element => {
  const location = useLocation()
  const { auditId } = useInAudit()
  const indexMutate = useInvalidateEvidenceGroups()
  const showMutate = useGrpcRevalidateByMethod()
  const isIndex = location.pathname.includes(EvidenceAbsoluteRoutes.INDEX)
  const addOrUpdateEvidenceGroups = useAddOrUpdateEvidenceGroups()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedControls, setSelectedControls] = useState<string[]>([])
  const [controlsMap, setControlsMap] = useState<Record<string, string>>({})
  const show = useIsShowModal(ModalFormId.LINK_EVIDENCE_TO_CONTROLS)
  const onHide = () => {
    isFunction(onHideFunc) && onHideFunc()
    setSelectedControls([])
  }
  const hide = useHideModal({
    modalId: ModalFormId.LINK_EVIDENCE_TO_CONTROLS,
    onHide,
  })
  const { data, isLoading, error } = useControls()

  useEffect(() => {
    if (!data) return
    const controlsMap = data.getItemsList().reduce((acc, control) => {
      acc[control.getId()] = control.getModelId()
      return acc
    }, {} as Record<string, string>)
    setControlsMap(controlsMap)
  }, [data])

  if (!data) {
    if (error) {
      log.error(
        'Error fetching controls in update control statuses modal',
        error,
      )
    }
    return <></>
  }

  const controls = data.getItemsList().filter((control) => {
    return !existingControlIds?.includes(control.getId())
  })

  const onSubmit: FormEventHandler = async (
    e: React.MouseEvent<HTMLFormElement>,
  ) => {
    try {
      e.preventDefault()
      setIsSubmitting(true)
      const linkages = selectedControls.map((controlId) =>
        new EvidenceLinkage()
          .setLinkageType(EVIDENCE_LINKAGE_TYPE.CONTROL_LINK)
          .setLinkageId(controlId)
          .setLinkageModelId(controlsMap[controlId]),
      )
      requests.forEach((request) => {
        request.setLinkagesList(linkages)
      })
      await addOrUpdateEvidenceGroups(requests)
    } catch (error) {
      log.error('Error linking evidenceGroups to Controls', error)
      if (!selectedControls.length) {
        showInfoToast('Error adding evidence')
      } else {
        showInfoToast('Error linking evidence to controls')
      }
    } finally {
      if (isIndex) {
        indexMutate()
      } else {
        showMutate(AttachmentPromiseClient.prototype.getEvidenceGroupControlIds)
        showMutate(
          AttachmentPromiseClient.prototype.getEvidenceGroupDocumentIds,
        )
      }
      setIsSubmitting(false)
      hide()
    }
  }

  const multiSelectFormProps = {
    formId: ModalFormId.LINK_EVIDENCE_TO_CONTROLS,
    loading: isLoading,
    gridTemplateColumnStyling:
      'min-content minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 1fr)',
    gridItems: controls.length ? (
      <>
        {controls.map((control) => (
          <SelectControlsRowItem
            key={control.getId()}
            control={control}
            setSelectedControlIds={setSelectedControls}
            selectedControlIds={selectedControls}
          />
        ))}
      </>
    ) : (
      <NoRecommendedTemplates templateType={'controls'} />
    ),
    numberSelectedText: `${selectedControls.length} ${getPluralization(
      'control',
      selectedControls.length,
    )} selected`,
    onSubmit,
  }

  const showWarning = !!auditId && isIndex && selectedControls.length === 0

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.LINK_EVIDENCE_TO_CONTROLS}
      size="xl"
      title="Select Relevant Controls"
      description="The evidence will be linked to each of these controls"
      submitText={`Select ${selectedControls.length} ${getPluralization(
        'control',
        selectedControls.length,
      )}`}
      showOverflow
      disableSubmitButton={isSubmitting}
    >
      {showWarning && (
        <PinkInfoBox>
          <PinkInfoBoxHeader>
            <AuditsSVG width="16px" height="16px" />
            Link Controls to Include Evidence in Audit
          </PinkInfoBoxHeader>
          <PinkInfoBoxBody>
            Warning: this evidence won&apos;t be visible here. Link to a control
            in audit to make it visible. Evidence is only shown in an audit if a
            control in that audit is using it.
          </PinkInfoBoxBody>
        </PinkInfoBox>
      )}
      <MultiSelectForm {...multiSelectFormProps} />
    </ModalForm>
  )
}
