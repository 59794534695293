import log from 'loglevel'
import {
  Call,
  SignupRequest,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { EmailPassword } from '@trustero/trustero-api-web/lib/auth/auth_pb'
import { AuthPromiseClient } from '@trustero/trustero-api-web/lib/auth/auth_grpc_web_pb'
import { NTRCE_API_URL } from '../adapter/gRpcAdapter'

const logger = log.getLogger('services')

class AuthService {
  login(email, password) {
    const emailPassword = new EmailPassword()
    emailPassword.setEmail(email)
    emailPassword.setPassword(password)

    const client = new AuthPromiseClient(NTRCE_API_URL, '', '')
    return client.usingPassword(emailPassword, {})
  }

  register(domain, email, password) {
    logger.debug('NTRCE_API_URL = ', NTRCE_API_URL)
    const signupRequest = new SignupRequest()
    signupRequest.setEmail(email)
    signupRequest.setPassword(password)

    const client = new AccountPromiseClient(NTRCE_API_URL, '', '')
    return client.signup(signupRequest, {})
  }

  registerConfirm(callid) {
    const call = new Call()
    call.setCallid(callid)

    const client = new AccountPromiseClient(NTRCE_API_URL, '', '')
    return client.confirmSignup(call, {})
  }
}

export default new AuthService()
