/**
 * @fileoverview gRPC-Web generated client stub for evidence
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: evidence/testing.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var common_time_pb = require('../common/time_pb.js')

var common_model_pb = require('../common/model_pb.js')

var attachment_attachment_pb = require('../attachment/attachment_pb.js')
const proto = {};
proto.evidence = require('./testing_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.evidence.TestingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.evidence.TestingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.CreateUpdateTestDefinitionRequest,
 *   !proto.evidence.TestDefinition>}
 */
const methodDescriptor_Testing_CreateUpdateTestDefinition = new grpc.web.MethodDescriptor(
  '/evidence.Testing/CreateUpdateTestDefinition',
  grpc.web.MethodType.UNARY,
  proto.evidence.CreateUpdateTestDefinitionRequest,
  proto.evidence.TestDefinition,
  /**
   * @param {!proto.evidence.CreateUpdateTestDefinitionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.TestDefinition.deserializeBinary
);


/**
 * @param {!proto.evidence.CreateUpdateTestDefinitionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.TestDefinition)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.TestDefinition>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.createUpdateTestDefinition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/CreateUpdateTestDefinition',
      request,
      metadata || {},
      methodDescriptor_Testing_CreateUpdateTestDefinition,
      callback);
};


/**
 * @param {!proto.evidence.CreateUpdateTestDefinitionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.TestDefinition>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.createUpdateTestDefinition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/CreateUpdateTestDefinition',
      request,
      metadata || {},
      methodDescriptor_Testing_CreateUpdateTestDefinition);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.GetTestResultsRequest,
 *   !proto.evidence.GetTestResultsResponse>}
 */
const methodDescriptor_Testing_GetTestResults = new grpc.web.MethodDescriptor(
  '/evidence.Testing/GetTestResults',
  grpc.web.MethodType.UNARY,
  proto.evidence.GetTestResultsRequest,
  proto.evidence.GetTestResultsResponse,
  /**
   * @param {!proto.evidence.GetTestResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.GetTestResultsResponse.deserializeBinary
);


/**
 * @param {!proto.evidence.GetTestResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.GetTestResultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.GetTestResultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.getTestResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/GetTestResults',
      request,
      metadata || {},
      methodDescriptor_Testing_GetTestResults,
      callback);
};


/**
 * @param {!proto.evidence.GetTestResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.GetTestResultsResponse>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.getTestResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/GetTestResults',
      request,
      metadata || {},
      methodDescriptor_Testing_GetTestResults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.GetLatestTestResultRequest,
 *   !proto.evidence.GetLatestTestResultsResponse>}
 */
const methodDescriptor_Testing_GetLatestTestResults = new grpc.web.MethodDescriptor(
  '/evidence.Testing/GetLatestTestResults',
  grpc.web.MethodType.UNARY,
  proto.evidence.GetLatestTestResultRequest,
  proto.evidence.GetLatestTestResultsResponse,
  /**
   * @param {!proto.evidence.GetLatestTestResultRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.GetLatestTestResultsResponse.deserializeBinary
);


/**
 * @param {!proto.evidence.GetLatestTestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.GetLatestTestResultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.GetLatestTestResultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.getLatestTestResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/GetLatestTestResults',
      request,
      metadata || {},
      methodDescriptor_Testing_GetLatestTestResults,
      callback);
};


/**
 * @param {!proto.evidence.GetLatestTestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.GetLatestTestResultsResponse>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.getLatestTestResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/GetLatestTestResults',
      request,
      metadata || {},
      methodDescriptor_Testing_GetLatestTestResults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.GetTestResultRequest,
 *   !proto.evidence.GetTestResultResponse>}
 */
const methodDescriptor_Testing_GetTestResult = new grpc.web.MethodDescriptor(
  '/evidence.Testing/GetTestResult',
  grpc.web.MethodType.UNARY,
  proto.evidence.GetTestResultRequest,
  proto.evidence.GetTestResultResponse,
  /**
   * @param {!proto.evidence.GetTestResultRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.GetTestResultResponse.deserializeBinary
);


/**
 * @param {!proto.evidence.GetTestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.GetTestResultResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.GetTestResultResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.getTestResult =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/GetTestResult',
      request,
      metadata || {},
      methodDescriptor_Testing_GetTestResult,
      callback);
};


/**
 * @param {!proto.evidence.GetTestResultRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.GetTestResultResponse>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.getTestResult =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/GetTestResult',
      request,
      metadata || {},
      methodDescriptor_Testing_GetTestResult);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Testing_RunAccountTests = new grpc.web.MethodDescriptor(
  '/evidence.Testing/RunAccountTests',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.runAccountTests =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/RunAccountTests',
      request,
      metadata || {},
      methodDescriptor_Testing_RunAccountTests,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.runAccountTests =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/RunAccountTests',
      request,
      metadata || {},
      methodDescriptor_Testing_RunAccountTests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.TestResult,
 *   !proto.evidence.TestResult>}
 */
const methodDescriptor_Testing_AddTest = new grpc.web.MethodDescriptor(
  '/evidence.Testing/AddTest',
  grpc.web.MethodType.UNARY,
  proto.evidence.TestResult,
  proto.evidence.TestResult,
  /**
   * @param {!proto.evidence.TestResult} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.TestResult.deserializeBinary
);


/**
 * @param {!proto.evidence.TestResult} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.TestResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.TestResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.addTest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/AddTest',
      request,
      metadata || {},
      methodDescriptor_Testing_AddTest,
      callback);
};


/**
 * @param {!proto.evidence.TestResult} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.TestResult>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.addTest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/AddTest',
      request,
      metadata || {},
      methodDescriptor_Testing_AddTest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.TestResult,
 *   !proto.evidence.StatusResponse>}
 */
const methodDescriptor_Testing_DeleteTest = new grpc.web.MethodDescriptor(
  '/evidence.Testing/DeleteTest',
  grpc.web.MethodType.UNARY,
  proto.evidence.TestResult,
  proto.evidence.StatusResponse,
  /**
   * @param {!proto.evidence.TestResult} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.evidence.TestResult} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.deleteTest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/DeleteTest',
      request,
      metadata || {},
      methodDescriptor_Testing_DeleteTest,
      callback);
};


/**
 * @param {!proto.evidence.TestResult} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.deleteTest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/DeleteTest',
      request,
      metadata || {},
      methodDescriptor_Testing_DeleteTest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.RunAccountTestsByReceptorModelIDRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Testing_RunAccountTestsByReceptorModelID = new grpc.web.MethodDescriptor(
  '/evidence.Testing/RunAccountTestsByReceptorModelID',
  grpc.web.MethodType.UNARY,
  proto.evidence.RunAccountTestsByReceptorModelIDRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.runAccountTestsByReceptorModelID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/RunAccountTestsByReceptorModelID',
      request,
      metadata || {},
      methodDescriptor_Testing_RunAccountTestsByReceptorModelID,
      callback);
};


/**
 * @param {!proto.evidence.RunAccountTestsByReceptorModelIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.runAccountTestsByReceptorModelID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/RunAccountTestsByReceptorModelID',
      request,
      metadata || {},
      methodDescriptor_Testing_RunAccountTestsByReceptorModelID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.evidence.GetFailedTestsBreakdownResponse>}
 */
const methodDescriptor_Testing_GetFailedTestsBreakdown = new grpc.web.MethodDescriptor(
  '/evidence.Testing/GetFailedTestsBreakdown',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.evidence.GetFailedTestsBreakdownResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.GetFailedTestsBreakdownResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.GetFailedTestsBreakdownResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.GetFailedTestsBreakdownResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.getFailedTestsBreakdown =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/GetFailedTestsBreakdown',
      request,
      metadata || {},
      methodDescriptor_Testing_GetFailedTestsBreakdown,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.GetFailedTestsBreakdownResponse>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.getFailedTestsBreakdown =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/GetFailedTestsBreakdown',
      request,
      metadata || {},
      methodDescriptor_Testing_GetFailedTestsBreakdown);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.evidence.GetEvidenceIssuesRequest,
 *   !proto.evidence.GetEvidenceIssuesResponse>}
 */
const methodDescriptor_Testing_GetEvidenceIssues = new grpc.web.MethodDescriptor(
  '/evidence.Testing/GetEvidenceIssues',
  grpc.web.MethodType.UNARY,
  proto.evidence.GetEvidenceIssuesRequest,
  proto.evidence.GetEvidenceIssuesResponse,
  /**
   * @param {!proto.evidence.GetEvidenceIssuesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.evidence.GetEvidenceIssuesResponse.deserializeBinary
);


/**
 * @param {!proto.evidence.GetEvidenceIssuesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.evidence.GetEvidenceIssuesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.evidence.GetEvidenceIssuesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.evidence.TestingClient.prototype.getEvidenceIssues =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/evidence.Testing/GetEvidenceIssues',
      request,
      metadata || {},
      methodDescriptor_Testing_GetEvidenceIssues,
      callback);
};


/**
 * @param {!proto.evidence.GetEvidenceIssuesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.evidence.GetEvidenceIssuesResponse>}
 *     Promise that resolves to the response
 */
proto.evidence.TestingPromiseClient.prototype.getEvidenceIssues =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/evidence.Testing/GetEvidenceIssues',
      request,
      metadata || {},
      methodDescriptor_Testing_GetEvidenceIssues);
};


module.exports = proto.evidence;

