import React from 'react'
import { NoResultsTitle } from './NoResults.components'
import { NoResultsContainer, Note } from './NoResults.styles'

type NoResultsProps = {
  icons?: JSX.Element
  title: string
  largeTitle?: boolean
  note?: string
  buttons?: JSX.Element
}

export const NoResults = ({
  icons,
  title,
  note,
  buttons,
  largeTitle,
}: NoResultsProps): JSX.Element => {
  return (
    <NoResultsContainer>
      {icons && icons}
      <NoResultsTitle large={largeTitle ?? false}>{title}</NoResultsTitle>
      {note && <Note>{note}</Note>}
      {buttons}
    </NoResultsContainer>
  )
}
