import React, { FormEventHandler, useState } from 'react'
import {
  AddOrUpdateEvidenceGroupRequest,
  EVIDENCE_LINKAGE_TYPE,
  EvidenceLinkage,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { MultiSelectForm } from 'src/components/Reusable/Forms/MultiSelectForm'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { getPluralization } from 'src/Utils/globalHelpers'
import { RequestPromiseClient } from '@trustero/trustero-api-web/lib/request/request_grpc_web_pb'
import { useGrpcRevalidateByMethod } from 'src/components'
import { useLocation } from 'react-router'
import { EvidenceAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { SelectRequestsRowItem } from 'src/components/Reusable/Forms/MultiSelectFromComponents/SelectRequestsRowItem'
import { NoRecommendedTemplates } from 'src/components/ModalForms/Frameworks/AddFramework/NoRecommendedTemplates'
import log from 'loglevel'
import isFunction from 'lodash/isFunction'
import { useDocumentRequests } from 'src/components/async/DocumentRequest'
import { ListDocumentRequestsRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import {
  useAddOrUpdateEvidenceGroups,
  useInvalidateEvidenceGroups,
} from '../evidence.hooks'

export const LinkEvidenceToRequestsModal = ({
  reqs,

  onHideFunc,
  existingRequestIds,
}: {
  reqs: AddOrUpdateEvidenceGroupRequest[]
  onHideFunc?: () => void
  existingRequestIds?: string[]
}): JSX.Element => {
  const location = useLocation()
  const indexMutate = useInvalidateEvidenceGroups()
  const showMutate = useGrpcRevalidateByMethod()
  const isIndex = location.pathname.includes(EvidenceAbsoluteRoutes.INDEX)
  const addOrUpdateEvidenceGroups = useAddOrUpdateEvidenceGroups()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedRequests, setSelectedRequests] = useState<string[]>([])
  const show = useIsShowModal(ModalFormId.LINK_EVIDENCE_TO_REQUESTS)
  const onHide = () => {
    isFunction(onHideFunc) && onHideFunc()
    setSelectedRequests([])
  }
  const hide = useHideModal({
    modalId: ModalFormId.LINK_EVIDENCE_TO_REQUESTS,
    onHide,
  })
  const drlRequest = new ListDocumentRequestsRequest()
  const { data, isLoading, error } = useDocumentRequests({
    request: drlRequest,
  })

  if (!data) {
    if (error) {
      log.error(
        'Error fetching controls in update control statuses modal',
        error,
      )
    }
    return <></>
  }

  const requests = data.getItemsList().filter((request) => {
    return !existingRequestIds?.includes(request.getId())
  })

  const onSubmit: FormEventHandler = async (
    e: React.MouseEvent<HTMLFormElement>,
  ) => {
    try {
      e.preventDefault()
      setIsSubmitting(true)
      const linkages = selectedRequests.map((requestId) =>
        new EvidenceLinkage()
          .setLinkageType(EVIDENCE_LINKAGE_TYPE.REQUEST_LINK)
          .setLinkageId(requestId),
      )
      reqs.forEach((request) => {
        request.setLinkagesList(linkages)
      })
      await addOrUpdateEvidenceGroups(reqs)
    } catch (error) {
      log.error('Error adding evidence groups', error)
    } finally {
      if (isIndex) {
        indexMutate()
      } else {
        showMutate(RequestPromiseClient.prototype.listDocumentRequests)
        showMutate(AttachmentPromiseClient.prototype.getEvidenceGroupControlIds)
      }
      setIsSubmitting(false)
      hide()
    }
  }

  const multiSelectFormProps = {
    formId: ModalFormId.LINK_EVIDENCE_TO_REQUESTS,
    loading: isLoading,
    gridTemplateColumnStyling:
      'repeat(2, max-content) 1fr repeat(2, max-content)',
    gridItems: requests.length ? (
      <>
        {requests.map((request) => (
          <SelectRequestsRowItem
            key={request.getId()}
            request={request}
            setSelectedRequestIds={setSelectedRequests}
            selectedRequestIds={selectedRequests}
          />
        ))}
      </>
    ) : (
      <NoRecommendedTemplates templateType={'controls'} />
    ),
    numberSelectedText: `${selectedRequests.length} ${getPluralization(
      'request',
      selectedRequests.length,
    )} selected`,
    onSubmit,
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.LINK_EVIDENCE_TO_REQUESTS}
      size="xl"
      title="Link this evidence to requests"
      description="Select requests this evidence can help satisfy"
      submitText={`Select ${selectedRequests.length} ${getPluralization(
        'request',
        selectedRequests.length,
      )}`}
      showOverflow
      disableSubmitButton={isSubmitting || !selectedRequests.length}
    >
      <MultiSelectForm {...multiSelectFormProps} />
    </ModalForm>
  )
}
