import React, { SetStateAction } from 'react'
import { P } from 'src/components/Reusable/Text/Text.styles'
import { Spinner } from 'src/Throbber'
import { FlexColumn } from 'src/components/Reusable/Flex'
import {
  SignupButton,
  SignupInput,
  SignupInputContainer,
} from './SignUpPage.styles'

export const SignupPageInput = ({
  handleSubmit,
  value,
  setValue,
  placeholder,
  isLoading,
}: {
  handleSubmit: () => Promise<void>
  value: string
  setValue: React.Dispatch<SetStateAction<string>>
  placeholder: string
  isLoading: boolean
}): JSX.Element => (
  <SignupInputContainer
    onSubmit={(e) => {
      e.preventDefault()
      handleSubmit()
    }}
  >
    <SignupInput
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
    />
    <SignupButton disabled={isLoading} type="submit">
      {isLoading ? (
        <FlexColumn isFullHeight isFullWidth>
          <Spinner size="s" color="primary" />
        </FlexColumn>
      ) : (
        <P $fontSize={14} $isBold>
          Submit
        </P>
      )}
    </SignupButton>
  </SignupInputContainer>
)
