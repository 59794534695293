import { useCallback } from 'react'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { AddControlsRequest } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import log from 'loglevel'
import { useAuthorizedGrpcClient } from '../../../../../adapter'
import { useCurrentAudit } from '../../../../../components/async/model/audit'

export function useBringControlsIntoAuditCallback(): (
  selected: string[],
) => Promise<void> {
  const auditClient = useAuthorizedGrpcClient(AuditPromiseClient)
  const { data: audit, mutate } = useCurrentAudit()
  const auditId = audit?.getId()

  return useCallback(
    async (controlIds: string[]) => {
      if (!controlIds.length || !auditId) {
        return
      }

      try {
        await auditClient.addControls(
          new AddControlsRequest()
            .setAuditIdsList([auditId])
            .setControlIdsList(controlIds),
        )
        await mutate()
      } catch (e) {
        log.error('Error in useBringControlsIntoAuditCallback', e)
      }
    },
    [auditClient, auditId, mutate],
  )
}
