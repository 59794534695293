import React, { useReducer } from 'react'
import { GridRow } from 'src/components/Reusable/Grid'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import {
  GRID_COL_SORT_CONFIGS_REQUESTS,
  GridColumnSortActionRequests,
  GridColumnSortRequests,
  GridColumnSortType,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { useUrlSearchParams } from 'src/Utils/helpers/navigation'
import { NoFilterResults } from 'src/components/PageLayout/NoFilterResults'
import { gridColSortReducerRequests } from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.utils'
import { RequestsGridColumnSort } from 'src/components/Reusable/Grid/GridColumnSort/RequestsGridColumnSort'
import { RequestGridHeader } from '../requests.styles'
import { REQUEST_GRID_LOCATIONS } from './RequestsIndexPage.constants'

export const RequestsIndexGridHeader = ({
  requests,
  location,
  stickyHeader = false,
}: {
  requests: DocumentRequest[]
  location: REQUEST_GRID_LOCATIONS
  stickyHeader?: boolean
}): JSX.Element => {
  const fromControlShowPage = location === REQUEST_GRID_LOCATIONS.CONTROLS_SHOW
  const fromRequestIndexPage =
    location === REQUEST_GRID_LOCATIONS.REQUESTS_INDEX
  const { searchParams, updateUrlParams } = useUrlSearchParams()
  const sortBy =
    (searchParams.sort_by && (searchParams.sort_by[0] as GridColumnSortType)) ||
    undefined
  const sortCol: GridColumnSortRequests | undefined =
    (searchParams.sort_col &&
      (searchParams.sort_col[0] as GridColumnSortRequests)) ||
    undefined
  const [columnMapState, dispatch] = useReducer(
    gridColSortReducerRequests,
    GRID_COL_SORT_CONFIGS_REQUESTS(sortBy, sortCol),
  )

  const noFilterResults = requests.length === 0

  const fetchSortedRequests = (action: GridColumnSortActionRequests) => {
    dispatch(action)
    updateUrlParams({ sort_by: [action.type], sort_col: [action.colName] })
  }

  return noFilterResults ? (
    <NoFilterResults />
  ) : (
    <GridRow>
      <RequestGridHeader sticky={stickyHeader} />
      <RequestGridHeader sticky={stickyHeader} />
      <RequestGridHeader sticky={stickyHeader}>
        Evidence
        {fromRequestIndexPage && (
          <RequestsGridColumnSort
            type={columnMapState[GridColumnSortRequests.EVIDENCE].type}
            isActive={columnMapState[GridColumnSortRequests.EVIDENCE].isActive}
            colName={GridColumnSortRequests.EVIDENCE}
            onSort={fetchSortedRequests}
          />
        )}
      </RequestGridHeader>
      <RequestGridHeader sticky={stickyHeader}>
        Controls
        {fromRequestIndexPage && (
          <RequestsGridColumnSort
            type={columnMapState[GridColumnSortRequests.CONTROLS].type}
            isActive={columnMapState[GridColumnSortRequests.CONTROLS].isActive}
            colName={GridColumnSortRequests.CONTROLS}
            onSort={fetchSortedRequests}
          />
        )}
      </RequestGridHeader>
      <RequestGridHeader sticky={stickyHeader}>Status</RequestGridHeader>
      <RequestGridHeader sticky={stickyHeader}>Assignee</RequestGridHeader>
      <RequestGridHeader sticky={stickyHeader}>Due Date</RequestGridHeader>
      {!fromControlShowPage && <RequestGridHeader sticky={stickyHeader} />}
    </GridRow>
  )
}
