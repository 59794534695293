import React from 'react'
import {
  PercentageCompleteContainer,
  ProgressCircle,
  ProgressContainer,
  ProgressRing,
  ProgressRingCircle,
} from './ProgressCircle.styles'

export const PercentageComplete = ({
  percentage,
  innerRadius,
  outerRadius,
  fontSize,
  icon,
  strokeWidth,
}: {
  percentage: number
  innerRadius: number
  outerRadius: number
  fontSize?: number
  icon?: JSX.Element
  strokeWidth?: number
}): JSX.Element => {
  const showPercent = percentage === -1 ? '--' : `${percentage}%`
  const progress = percentage === -1 ? 0 : (percentage || 0) / 100

  return (
    <PercentageCompleteContainer>
      <ProgressContainer radius={outerRadius}>
        <ProgressCircle radius={innerRadius} fontSize={fontSize}>
          {icon ? icon : showPercent}
        </ProgressCircle>
        <ProgressRing radius={outerRadius}>
          <ProgressRingCircle
            radius={outerRadius}
            progress={progress}
            strokeWidth={strokeWidth}
          />
        </ProgressRing>
      </ProgressContainer>
    </PercentageCompleteContainer>
  )
}
