import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import includes from 'lodash/includes'
import { getModelTypeLabel } from 'src/adapter'

export type HeaderValues = {
  input: string
  output: string
  optional?: boolean
}

export type ParseColumnType = {
  [key: string]: {
    validator: (
      columnName: string,
      value: string,
      row: number,
    ) => string | string[] | null
    mutator: (value: string) => string | string[]
  }
}

export type CsvTemplateType = {
  [key: string]: string
}

export type FileStats = {
  headersLen: number
  currCell: number
  row(): number
}

export const createLowercaseLookupMap = <T>(
  obj: Record<string, T>,
): Record<string, T> => {
  const lowercaseMap: Record<string, T> = {}
  Object.keys(obj).forEach((key) => {
    lowercaseMap[key.toLowerCase()] = obj[key]
  })
  return lowercaseMap
}

export const CSV_VALIDATIONS = Object.freeze({
  HEADER: (expected?: string, actual?: string): string | null => {
    if (!expected || !actual) {
      return `Header name issue: '${actual}' does not match expected headers.`
    }
    const newExpected = expected.toLowerCase().trim()
    const newActual = actual.toLowerCase().trim()
    return includes(newActual, newExpected)
      ? null
      : `Header name wrong: '${actual}' should be '${expected}'.`
  },
  LENGTH: (
    header: string,
    value: string,
    min: number,
    max: number,
    row: number,
  ): string | null => {
    if (value.length < min || value.length > max) {
      return `Row ${row}: ${header} length must be between ${min} and ${max} characters.`
    }
    return null
  },
  DATE: (header: string, value: string, row: number): string | null => {
    if (value === '') return null
    const dateFromString = new Date(value)
    return dateFromString.getTime()
      ? null
      : `Row ${row}: ${header} is not formatted correctly. Recommended format is MM/DD/YYYY.`
  },
  ID_EXISTS: (
    value: string,
    ids: Set<string>,
    row: number,
    modelType: MODEL_TYPE = MODEL_TYPE.CONTROL,
  ): string[] | null => {
    if (value === '') return null
    const valueArray = value.split(',')
    const result = valueArray.reduce(
      (ac: string[], elem: string, idx: number) => {
        const id: string = elem.trim()
        if (!ids.has(id)) {
          ac.push(
            `Row ${row}: Item ${
              idx + 1
            } '${id}' - ID of linked ${getModelTypeLabel(
              modelType,
            )} not recognized. Double check the ID is correct.`,
          )
        }
        return ac
      },
      [],
    )
    return result.length ? result : null
  },
  USER_EMAIL: (
    value: string,
    userEmails: Set<string>,
    row: number,
  ): string | null => {
    if (value === '') return null
    const valueArray = value.split(',')
    const result = valueArray.reduce((ac: string | null, elem: string) => {
      const email: string = elem.trim()
      if (!userEmails.has(email)) {
        return `Row ${row}: User email '${email}' not recognized. Double check the email is correct.`
      }
      return ac
    }, null)
    return result
  },
  VALUE_IN_ARRAY: (
    value: string,
    row: number,
    items: string[],
    expectedValues?: string[],
  ): string | null => {
    if (value === '') return null
    const item: string = value.trim().toLowerCase()
    if (!items.map((ele) => ele.toLowerCase()).includes(item)) {
      if (expectedValues && expectedValues.length) {
        return `Row ${row}: '${value}' not one of the expected values. Double check the item is correct. Expected values: ${expectedValues
          .slice(0, 5)
          .join(', ')}${expectedValues.length > 5 ? '...' : ''}.`
      }
      return `Row ${row}: '${value}' not one of the expected values. Double check the item is correct.`
    }
    return null
  },
  VALUE_NOT_IN_ARRAY: (
    value: string,
    row: number,
    items: string[],
    ignoreCase?: boolean,
  ): string | null => {
    if (value === '') return null
    let exists = false
    if (ignoreCase) {
      const lowerItems = items.map((item) => item.toLowerCase())
      if (lowerItems.includes(value.toLowerCase())) {
        exists = true
      }
    }
    if (items.includes(value)) {
      exists = true
    }
    return exists
      ? `Row ${row}: '${value}' already exists in your account. Double check the item is correct.`
      : null
  },
  VALUES_IN_ARRAY: (
    value: string,
    row: number,
    items: string[],
    matcher: (v: string, candidate: string) => boolean,
  ): string | null => {
    const values = value.split(',')
    if (values.length === 0) return null
    for (const value of values) {
      if (!items.some((item) => matcher(value, item))) {
        return `Row ${row}: '${value}' not one of the expected values. Double check the item is correct.`
      }
    }
    return null
  },
  ID_EXISTS_ONCE: (
    row: number,
    value: string,
    modelType: MODEL_TYPE = MODEL_TYPE.CONTROL,
  ): string[] | null => {
    if (value === '') return null
    const valueArray = value.split(',')
    const valueSet = new Set()
    const result = valueArray.reduce(
      (ac: string[], elem: string, idx: number) => {
        const valueItem: string = elem.trim()
        if (valueSet.has(valueItem)) {
          ac.push(
            `Row ${row}: Item ${
              idx + 1
            } '${valueItem}' ID of linked ${getModelTypeLabel(
              modelType,
            )} is duplicated. Double check the ID is correct.`,
          )
        } else {
          valueSet.add(valueItem)
        }
        return ac
      },
      [],
    )
    return result.length ? result : null
  },
})
