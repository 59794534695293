import { MIME_TYPE } from 'src/Utils/globalEnums'
import { MAX_UPLOAD_BYTES, MAX_UPLOAD_MB } from './FileSelector.constants'

// Function to correct Apple-specific MIME types based on file extensions
export const correctAppleFiles = (
  fileRejections: File[],
  allowedMimeTypes: string[],
): File[] => {
  const allowedAppleMimeTypes = [
    MIME_TYPE.APPLE_KEYNOTE,
    MIME_TYPE.APPLE_NUMBERS,
    MIME_TYPE.APPLE_PAGES,
  ]

  return fileRejections
    .map((file) => {
      const extension = file.name.split('.').pop()?.toLowerCase()

      // Only correct the MIME type if the Apple-specific MIME type is allowed
      if (
        allowedAppleMimeTypes.includes(MIME_TYPE.APPLE_KEYNOTE) &&
        extension === 'key'
      ) {
        Object.defineProperty(file, 'type', {
          value: MIME_TYPE.APPLE_KEYNOTE,
          writable: true,
        })
      }
      if (
        allowedAppleMimeTypes.includes(MIME_TYPE.APPLE_NUMBERS) &&
        extension === 'numbers'
      ) {
        Object.defineProperty(file, 'type', {
          value: MIME_TYPE.APPLE_NUMBERS,
          writable: true,
        })
      }
      if (
        allowedAppleMimeTypes.includes(MIME_TYPE.APPLE_PAGES) &&
        extension === 'pages'
      ) {
        Object.defineProperty(file, 'type', {
          value: MIME_TYPE.APPLE_PAGES,
          writable: true,
        })
      }

      // After correcting the MIME type, check if it's in the allowed list
      if (allowedMimeTypes.includes(file.type)) {
        return file
      }

      return null // Return null for files that still don’t match after correction
    })
    .filter((file): file is File => file !== null) // Remove null entries
}

export const stripFileExtension = (filename: string): string =>
  filename.replace(/\.[^/.]+$/, '')

export const validateFiles = (
  files: File[],
  accept: string[] = [],
  maxUploadBytes: number = MAX_UPLOAD_BYTES,
): { validFiles: File[]; errors: string | null } => {
  let errors: string | null = null

  // Filter files by size
  const oversizedFiles = files.filter((file) => file.size > maxUploadBytes)
  if (oversizedFiles.length > 0) {
    errors = `One or more files exceed the ${MAX_UPLOAD_MB}MB limit.`
  }

  // Filter files by MIME type
  const validFiles = files.filter((file) => accept.includes(file.type))

  if (validFiles.length === 0) {
    errors = 'Invalid file type. Please upload supported files.'
  }

  return { validFiles, errors }
}
