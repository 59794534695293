import React, { MouseEventHandler, useEffect, useState } from 'react'
import log from 'loglevel'
import {
  useHideModal,
  useIsShowModal,
  useModalState,
} from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { useThrobberContext } from 'src/Throbber'
import { ServicesMapData, useServicesContext } from '../context/ServicesContext'
import { AddServiceRolesBody } from '../services.styles'
import {
  AddServiceRolesItem,
  AddServiceRolesSubmitButton,
} from '../services.components'
import { useBulkAddOrUpdateServices } from '../Services.hooks'

export const AddServiceRolesModal = (): JSX.Element => {
  const { openModal } = useModalState()
  const [modalBody, setModalBody] = useState<HTMLDivElement | null>(null)
  const show = useIsShowModal(ModalFormId.ADD_SERVICE_ROLES)
  const { startThrobber, stopThrobber } = useThrobberContext()
  const { servicesMap, clearState } = useServicesContext()
  const addOrUpdateServices = useBulkAddOrUpdateServices()
  const hide = useHideModal({
    modalId: ModalFormId.ADD_SERVICE_ROLES,
    onHide: () => clearState(),
  })
  const maxHeight = 400

  useEffect(() => {
    if (!modalBody) return
    const contentHeight = modalBody.offsetHeight || 0
    if (contentHeight >= maxHeight) {
      modalBody?.classList.add('scroll')
    } else {
      modalBody.classList.remove('scroll')
    }
  }, [modalBody, servicesMap])

  const selectedServices = Object.values(servicesMap).filter(
    (servicesData) => servicesData.isSelected,
  )

  const onSubmit: MouseEventHandler<HTMLElement> = async (e) => {
    e.preventDefault()
    try {
      hide()
      startThrobber()
      const services = Object.values(servicesMap)
        .filter((serviceData) => serviceData.isSelected)
        .map((serviceData) => serviceData.service)
      await addOrUpdateServices(services)
    } catch (error) {
      log.error('Error when adding services', error)
    } finally {
      stopThrobber()
    }
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      title="How Do You Use These Services?"
      description="For each service, indicate how it fits into your Primary Infrastructure."
      submitText="Confirm"
      formId={ModalFormId.ADD_SERVICE_ROLES}
      onBack={() => openModal(ModalFormId.ADD_SERVICES)}
      customSubmitButton={<AddServiceRolesSubmitButton onSubmit={onSubmit} />}
      showOverflow
    >
      <AddServiceRolesBody ref={(ref) => setModalBody(ref)}>
        {selectedServices.map((serviceData: ServicesMapData) => {
          const service = serviceData.service
          return <AddServiceRolesItem key={service.id} service={service} />
        })}
      </AddServiceRolesBody>
    </ModalForm>
  )
}
