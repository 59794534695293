import themeGet from '@styled-system/theme-get'
import styled from 'styled-components/macro'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import palette from 'src/designSystem/variables/palette'
import { Tabs } from 'react-bootstrap'
import { ReactComponent as PermalinkIcon } from '../../../Icons/assets/permalink-icon.svg'

export const StyledEvidenceP = styled.p`
  display: flex;
  align-items: flex-end;
  height: 48px;
`

export const EvidenceTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  flex: 1 1 max-content;
  overflow-y: auto;
  .tab-content,
  .tab-pane.active.show {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .row:nth-child(2) {
    flex: 1 1 max-content;
    overflow-y: auto;
  }

  .markup-section {
    padding: 12px;
  }
`

export const EvidenceDataGridContainer = styled.div`
  flex: 1 1 min-content;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .MuiDataGrid-root {
    height: 100%;
  }

  .MuiDataGrid-row.even {
    background-color: ${palette.neutral['30']};

    &:hover {
      background-color: ${palette.neutral['50']};
    }
  }

  .MuiDataGrid-row.odd:hover {
    background-color: ${palette.neutral['50']};
  }

  .evidence-grid-cell.MuiDataGrid-cell {
    border-bottom: none;
  }

  .MuiDataGrid-columnHeaders {
    border-bottom: none;
  }

  .MuiDataGrid-iconButtonContainer {
    width: auto;
    visibility: visible;
  }

  .MuiDataGrid-columnHeader.evidence-grid-column {
    font-family: 'Poppins', sans-serif;
    border-bottom: none;
    font-weight: 900;

    &:not(.MuiDataGrid-columnHeader--sorted) {
      .MuiDataGrid-sortIcon {
        opacity: 1;
      }
    }

    .MuiDataGrid-menuIcon {
      width: auto;
      right: 0;
      align-self: center;
      position: absolute;
    }
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 900;
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }

  .evidence-grid-row {
    font-family: 'Poppins', sans-serif;
  }
`

export const CustomTabs = styled(Tabs)<{ $isNewEvidence: boolean }>`
  ${({ $isNewEvidence }) =>
    $isNewEvidence
      ? `
    .nav-link {
      text-decoration: none;
      color: ${palette.neutral['900']};
    }
    width: fit-content;
    position: absolute;
  `
      : `
  position: absolute;
  top: 0;
  right: 0;`}
  border: 1px solid ${themeGet('colors.border.neutral.light')};
  border-radius: 6px;
  overflow: clip;
  background-color: white;

  .nav-item {
    :not(:last-child) {
      .nav-link:not(.active) {
        border-right: 1px solid ${themeGet('colors.border.neutral.light')};
      }
    }

    .nav-link {
      height: 100%;
      border-radius: 0;

      & {
        svg {
          path,
          polygon {
            fill: ${themeGet('colors.text.icon.default')};
          }
        }
      }

      &.active {
        background-color: ${themeGet('colors.fill.neutral.extraDark')};

        svg {
          path,
          polygon {
            fill: white;
          }
        }
      }
    }
  }
`

export const EvidenceHeader = styled.div`
  padding: 0px 0px 24px;
  overflow-y: auto;
  max-height: 200px;
`

export const StyledPermalinkIcon = styled(PermalinkIcon)`
  width: 24px;
  height: 24px;
`

export const FileDownloadBtn = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledImagePreview = styled.img`
  width: 600px;
  height: auto;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const StyledIframePreview = styled.iframe`
  width: 600px;
  height: auto;
  cursor: pointer;
`

export const StyledPreviewModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StyledPreviewModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`

export const StyledPreviewModalIframe = styled.iframe`
  width: 90%;
  height: 90%;
  max-width: 90%;
  max-height: 90%;
`

export const PreviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const StyledCaptionContainer = styled.div<{ allowEdit: boolean }>`
  ${({ allowEdit }) =>
    allowEdit &&
    `
    :hover {
      background-color: ${palette.neutral['30']};
    };
      
    cursor: text;
  `}
`
