import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  ItemTitle,
  ShowPageBackArrow,
  ShowPageHeaderButtons,
  ShowPageHeaderContainer,
  ShowPageTitleWithIcon,
} from 'src/components/PageLayout/ShowPage'
import { ServicesAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import {
  ChangelogButton,
  PermalinkButton,
} from 'src/components/Reusable/Buttons'
import {
  generatePermalink,
  linkWithSearch,
} from 'src/components/PageLayout/Permalink'
import {
  ShowPageHeaderDiv,
  ShowPageHeaderNavLinks,
  ShowPageHeaderTitleRow,
  ShowPageRouteTitle,
} from 'src/components/PageLayout/ShowPage/showpage.styles'
import { ShowPageHeader } from 'src/components/PageLayout/ShowPage/showpage.components'
import { ServicesPrevNext } from '../services.components'
import { useServicesContext } from '../context/ServicesContext'

export const ServicesShowPageHeader = (): JSX.Element => {
  const params = useParams()
  const { service } = useServicesContext()

  if (!service) {
    return <></>
  }

  const { name, id, logo: Logo } = service
  const permalinkUrl = generatePermalink({
    pageContext: params.pageContext as string,
    modelId: id,
    modelType: MODEL_TYPE.SERVICE,
    isInternalLink: false,
  })
  const breadcrumb = linkWithSearch(`../../../${ServicesAbsoluteRoutes.INDEX}`)

  return (
    <ShowPageHeaderContainer>
      <ShowPageHeader>
        <ShowPageHeaderDiv>
          <ShowPageHeaderNavLinks>
            <ShowPageRouteTitle>
              <Link to={breadcrumb}>
                <ShowPageBackArrow icon={faArrowLeft} />
                Services
              </Link>
            </ShowPageRouteTitle>
            <ServicesPrevNext />
          </ShowPageHeaderNavLinks>
          <ShowPageHeaderTitleRow>
            <ShowPageTitleWithIcon>
              <Logo height="40px" width="40px" />
              <ItemTitle>{name}</ItemTitle>
            </ShowPageTitleWithIcon>
            <ShowPageHeaderButtons>
              <ChangelogButton subjectIds={[id]} />
              <PermalinkButton isPermalink permalinkUrl={permalinkUrl} />
            </ShowPageHeaderButtons>
          </ShowPageHeaderTitleRow>
        </ShowPageHeaderDiv>
      </ShowPageHeader>
    </ShowPageHeaderContainer>
  )
}
