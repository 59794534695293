import { openInNewTab } from '../globalHelpers'

const HS_DOMAIN = 'https://support.trustero.com'

export const HUBSPOT = Object.freeze({
  DOMAIN: HS_DOMAIN,
  KNOWLEDGE_BASE: '/knowledge',
  TICKETS_VIEW: '/tickets-view',
  AUTH_URL: (token: string, redirectUrl: string) =>
    `${HS_DOMAIN}/_hcms/mem/jwt?jwt=${token}&redirect_url=${redirectUrl}`,
  RELEVANT_DATE_DOCUMENTATION:
    'https://support.trustero.com/knowledge/what-is-evidences-relevant-date-and-how-should-i-use-it',
  AUDIT_PERIOD_DOCUMENTATION:
    'https://support.trustero.com/knowledge/audit-window-dates-and-states',
  REQUIRED_EVIDENCE_AND_TEST_PROCEDURES:
    'https://support.trustero.com/knowledge/required-evidence-and-test-procedures',
  QC_BEST_PRACTICES:
    'https://support.trustero.com/knowledge/questionnaire-copilot-faqs-and-best-practices',
  DATE_LIMIT:
    'https://support.trustero.com/knowledge/what-is-the-date-limit-and-how-should-i-use-it',
  GOOD_MANUAL_EVIDENCE:
    'https://support.trustero.com/knowledge/manual-evidence-best-practices',
  AUDIT_SCAN_FAQ:
    'https://support.trustero.com/knowledge/audit-scan-and-compliance-roadmap-faqs',
  VENDOR_MANAGEMENT_FAQ:
    'https://support.trustero.com/knowledge/vendor-management-faqs',
  RISK_REGISTER_OVERVIEW:
    'https://support.trustero.com/knowledge/risk-register-overview-and-guidance',
  CONTROL_RESPONSIBILITY:
    'https://support.trustero.com/knowledge/scoping-determining-control-responsibility',
  CONNECT_GOOGLE_DRIVE:
    'https://support.trustero.com/knowledge/enable-google-drive-integration',
  POLICY_RESPONSIBILITY:
    'https://support.trustero.com/knowledge/how-to-determine-policy-responsibility',
})

export const hasHubspotRedirect = (redirectUrl: string): boolean =>
  redirectUrl.includes(HUBSPOT.KNOWLEDGE_BASE) ||
  redirectUrl.includes(HUBSPOT.TICKETS_VIEW)

export const goToHubspotSupport = (
  redirectUrl: string,
  token: string,
  inNewTab?: boolean,
): void => {
  if (!hasHubspotRedirect(redirectUrl)) return
  const url = HUBSPOT.AUTH_URL(token, redirectUrl)
  if (inNewTab) {
    openInNewTab(url)
  } else {
    window.location.href = url
  }
}
