import styled from 'styled-components/macro'
import { compose, padding } from 'styled-system'
import palette from 'src/designSystem/variables/palette'
import { InfoSVG } from 'src/components/Icons/Basic'
import {
  filterBarHeight,
  headerContentHeight,
  headerHeight,
} from '../../PageLayout/dimensionUtils'
import { TextButton } from '../Buttons/TextButton'

export const IndexContent = styled.article.attrs({
  px: 'l',
})`
  ${padding}
  position: relative;
  background-color: white;
`

export const IndexNav = styled.nav.attrs({ pt: 'xs' })<{
  isLargeHeader?: boolean
  isNotSticky?: boolean
  noSubPageNav?: boolean
}>`
  ${compose(padding)}
  display: flex;
  top: ${({ noSubPageNav }) =>
    noSubPageNav ? headerContentHeight : headerHeight}px;
  height: ${({ isLargeHeader }) => (isLargeHeader ? 80 : filterBarHeight)}px;
  align-items: center;
  position: ${({ isNotSticky }) => (isNotSticky ? 'auto' : 'sticky')};
  z-index: 2;
  background-color: white;
`
export const IndexPageContentLoaderContainer = styled.div`
  padding: 24px;
`

export const IndexAdditionalInfoSection = styled.div`
  font-size: 14px;
  font-style: italic;
  color: ${palette.neutral[900]};
  display: flex;
  align-items: center;

  a,
  a:visited {
    color: ${palette.neutral[900]};
    font-style: normal;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: ${palette.blue[700]};
    }
  }
`

export const IndexAdditionalInfoTextButton = styled(TextButton)`
  text-decoration: none;
  font-weight: 500;
  color: ${palette.neutral[900]};
`

export const IndexAdditionalInfoSVG = styled(InfoSVG)`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  margin-bottom: 2px;
  path:not(:first-child) {
    fill: ${palette.neutral[900]};
  }
`

export const LargeIndexAdditionalInfoSVG = styled(InfoSVG)`
  height: 32px;
  width: 32px;
  margin-right: 6px;
  margin-bottom: 0px;
  path:not(:first-child) {
    fill: ${palette.neutral[900]};
  }
`
