import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { ModalProps } from 'react-bootstrap/Modal'
import { APP_ANALYTICS_EVENTS } from 'src/analytics/analytics.constants'

export type ConfirmationState = {
  isShown: boolean
  size?: ModalProps['size']
  title: string
  body: string | JSX.Element
  confirmText?: string
  onConfirmCB: () => void
  hideCancel?: boolean
  onCancel?: () => void
  cancelText?: string
  footer?: JSX.Element
  analyticsEvent?: keyof typeof APP_ANALYTICS_EVENTS
  analyticsEventData?: Record<string, unknown>
}

export const initialConfirmationState: ConfirmationState = {
  isShown: false,
  size: 'sm',
  title: '',
  body: '',
  onConfirmCB: () => null,
  onCancel: () => null,
}

export type ConfirmationContextType = {
  confirmationState: ConfirmationState
  setConfirmationState: Dispatch<SetStateAction<ConfirmationState>>
}

export const ConfirmationContext = createContext<ConfirmationContextType>({
  confirmationState: initialConfirmationState,
  setConfirmationState: () => null,
})

export const useConfirmationContext = (): ConfirmationContextType =>
  useContext(ConfirmationContext)

export type ConfirmationProviderType = {
  children: NonNullable<ReactNode> | Array<NonNullable<ReactNode>>
}

export const ConfirmationProvider = ({
  children,
}: ConfirmationProviderType): JSX.Element => {
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>(
    initialConfirmationState,
  )

  return (
    <ConfirmationContext.Provider
      value={{ confirmationState, setConfirmationState }}
    >
      {children}
    </ConfirmationContext.Provider>
  )
}
