import { ParsedQuery } from 'query-string'
import { FilterParam } from '../../components/Reusable/IndexPage/FilterBar/FilterBar.types'

export enum DashboardQueryParam {
  COMPLIANCE_CHECKLIST_TAB = 'compliance_checklist_tab',
  STATUS_OF_CONTROLS_TAB = 'status_of_controls_tab',
}

export type DashboardQuery = ParsedQuery & {
  [DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB]?: ChecklistFilterEnum
  [DashboardQueryParam.STATUS_OF_CONTROLS_TAB]?:
    | FilterParam.DEPARTMENT
    | FilterParam.OWNER
    | FilterParam.POLICY
}

export enum ChecklistFilterEnum {
  TEAM_WORK = 'team_work',
  YOUR_WORK = 'your_work',
}
