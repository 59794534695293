import { useCallback } from 'react'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { ListControlsRequest } from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { ControlServicePromiseClient } from '@trustero/trustero-api-web/lib/model/control_grpc_web_pb'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { useGrpcRevalidateByMethod } from '../../useGrpcMutate'
import { useSwrImmutableGrpc } from '../../useSwrImmutableGrpc'

export const useListControlIds = (
  request: ListControlsRequest = new ListControlsRequest(),
  ignoreAuditContext = false,
): GrpcResponse<StringList> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listControlIds,
    request,
    true,
    {
      ignoreAuditContext,
    },
  )
  return NewGrpcResponse(response)
}

export const useInvalidateListControlIdsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(ModelPromiseClient.prototype.listControlIds),
      mutateFunc(ControlServicePromiseClient.prototype.getHasNoControls),
    ])
  }, [mutateFunc])
}
