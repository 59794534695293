import React from 'react'
import { getReceptorTemplate, Receptor } from 'src/xgenerated/receptor'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { LINKER_MODEL_IDS } from './Linkers.constants'

export const getLinkerTemplate = (linkerId: string): Receptor => {
  return getReceptorTemplate(linkerId)
}

export const getLinkerKBLink = (linkerId: string): string => {
  switch (linkerId) {
    case LINKER_MODEL_IDS.GOOGLE_DRIVE:
      return HUBSPOT.CONNECT_GOOGLE_DRIVE
    default:
      return ''
  }
}

export const getLinkerTooltipMessage = (
  linkerName: string,
  hasInvalidCreds: boolean,
  exceptions: string,
  link?: JSX.Element,
): string | JSX.Element => {
  if (hasInvalidCreds) {
    return (
      <>
        {`${linkerName} has invalid credentials`}
        {link}
      </>
    )
  }
  if (exceptions) {
    return (
      <>
        {exceptions}
        {link}
      </>
    )
  }
  return `${linkerName} is not connected`
}
