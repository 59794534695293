import React from 'react'
import ContentLoader from 'react-content-loader'

export function ListItemPlaceholder({
  display,
  width,
  height,
}: Pick<
  React.SVGAttributes<SVGElement>,
  'display' | 'width' | 'height'
>): JSX.Element {
  return (
    <ContentLoader
      speed={0.5}
      viewBox="0 0 385 32"
      display={display}
      width={width}
      height={height}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="16" y="9" rx="0.25em" width="14" height="14" />
      <rect x="38" y="11.375" rx="0.25em" width="331" height="9.25" />
    </ContentLoader>
  )
}
