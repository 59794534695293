import { useContext } from 'react'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { AuditContext } from '../../../../context/AuditContext'
import { GrpcResponse } from '../../hooks/types'
import { useAudit } from './useAudit'

export const useCurrentAudit = (): GrpcResponse<AuditRecord> => {
  const { auditId } = useContext(AuditContext)
  return useAudit(auditId ?? '', !!auditId)
}
