import React from 'react'
import { FilterName, FilterParam } from '../FilterBar.types'
import { RiskFilterValues } from '../FilterBar.constants'
import { FilterDropdown } from './FilterDropdown'

export const RiskFilterDropdown = ({
  param,
  name,
}: {
  param: FilterParam.INHERENT_RISK | FilterParam.RESIDUAL_RISK
  name: FilterName.INHERENT_RISK | FilterName.RESIDUAL_RISK
}): JSX.Element => {
  const filterValues = [
    {
      value: RiskFilterValues.NOT_SET,
      label: 'Not Set',
    },
    {
      value: RiskFilterValues.SET,
      label: 'Set',
    },
  ]

  return (
    <FilterDropdown
      filterParam={param}
      filterName={name}
      filterValues={filterValues}
    />
  )
}
