import React from 'react'
import {
  Framework,
  Frameworks,
} from '@trustero/trustero-api-web/lib/model/framework_pb'
import { BaseProps, OnUnpackedResponse } from '../types'
import { FrameworkComponentResponse } from './FrameworkComponentResponse'
import { FrameworksComponent } from './FrameworksComponent'

type Props<ExternalProps> = {
  modelId: string
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<ExternalProps, Frameworks, { framework: Framework }>
>

export function FrameworkComponent<ExternalProps>(
  props: Props<ExternalProps>,
): JSX.Element {
  return (
    <FrameworksComponent
      {...props}
      child={({ frameworks, mutate }) => (
        <FrameworkComponentResponse
          {...props}
          mutate={mutate}
          frameworks={frameworks}
        />
      )}
    />
  )
}
