import { EVIDENCE_SORT_COLUMN } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { FlexAlign } from 'src/components/Reusable/Flex'
import { GridColumnSortEvidence } from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'

export type EvidenceConfigType = {
  body: Uint8Array | string
  mimeType: string
  sourcesMarkdown: string
  isAutomated: boolean
  file: File | null
}

export const EVIDENCE_INDEX_HEADERS = {
  TYPE: {
    name: 'Type',
    gridAlign: FlexAlign.FLEX_END,
  },
  NAME: {
    name: 'Name',
    gridAlign: FlexAlign.FLEX_START,
  },
  LINKED_REQUESTS: {
    name: 'Linked Requests',
    gridAlign: FlexAlign.CENTER,
  },
  LINKED_CONTROLS: {
    name: 'Linked Controls',
    gridAlign: FlexAlign.CENTER,
  },
  ADDED_BY: {
    name: 'By',
    gridAlign: FlexAlign.CENTER,
  },
  RELEVANT_DATE: {
    name: 'Relevant Date',
    gridAlign: FlexAlign.FLEX_START,
  },
  CREATED: {
    name: 'Created',
    gridAlign: FlexAlign.FLEX_START,
  },
  DELETE: {
    name: '', // Empty column for delete button
    gridAlign: FlexAlign.CENTER,
  },
  EXPAND: {
    name: '', // Empty column for expand button
    gridAlign: FlexAlign.CENTER,
  },
}

export const EVIDENCE_HEADERS_TO_SORT_COL: Record<
  string,
  GridColumnSortEvidence
> = {
  [EVIDENCE_INDEX_HEADERS.NAME.name]: GridColumnSortEvidence.NAME,
  [EVIDENCE_INDEX_HEADERS.RELEVANT_DATE.name]:
    GridColumnSortEvidence.RELEVANT_DATE,
  [EVIDENCE_INDEX_HEADERS.CREATED.name]: GridColumnSortEvidence.CREATED_AT,
}

export const EVIDENCE_SORT_COLUMNS = Object.freeze({
  [GridColumnSortEvidence.NAME]: EVIDENCE_SORT_COLUMN.NAME,
  [GridColumnSortEvidence.RELEVANT_DATE]: EVIDENCE_SORT_COLUMN.RELEVANT_DATE,
  [GridColumnSortEvidence.CREATED_AT]: EVIDENCE_SORT_COLUMN.CREATED_AT,
})

export const EVIDENCE_INDEX_PAGE_SIZE = 20

export enum EVIDENCE_TABS {
  EVIDENCE = 'Evidence',
  EXCLUDED = 'Excluded',
  SOURCE = 'Source',
  PROPERTIES = 'Properties',
  CONTROLS = 'Linked Controls',
  REQUESTS = 'Requests',
}
