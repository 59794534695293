import {
  Controls,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { useCallback } from 'react'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useGrpcRevalidateByMethod } from '../../../../../components'
import {
  GrpcResponse,
  NewGrpcResponse,
} from '../../../../../components/async/hooks/types'
import { useSwrImmutableGrpc } from '../../../../../components/async/useSwrImmutableGrpc'
import { boolValue } from '../../../../../Utils/primitives'

export const useListControls = ({
  excludeFromAudit,
  isDismissed,
  complianceFrameworkIds,
  auditId,
  excludeNotApplicable,
}: {
  excludeFromAudit: boolean
  isDismissed: boolean
  complianceFrameworkIds: string[]
  auditId?: string
  excludeNotApplicable?: boolean
}): GrpcResponse<Controls> => {
  const request = new ListControlsRequest()
    .setExcludedFromAudit(boolValue(excludeFromAudit))
    .setIsDismissed(boolValue(isDismissed))
    .setComplianceFrameworkIdsList(complianceFrameworkIds)
    .setExcludeNotApplicable(boolValue(excludeNotApplicable))

  auditId && request.setAuditId(new StringValue().setValue(auditId))

  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listControls,
    request,
    true,
  )
  return NewGrpcResponse(response)
}

export const useInvalidateListControlsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([mutateFunc(ModelPromiseClient.prototype.listControls)])
  }, [mutateFunc])
}
