import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { RisksAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useRisk } from 'src/pages/Risks/risks.hooks'
import { useInAudit } from 'src/context/AuditContext'
import AuthContext from 'src/context/authContext'
import { SubjectContainer } from './Subject.styles'

export const RiskContent = ({ riskId }: { riskId: string }): JSX.Element => {
  const risk = useRisk(riskId)
  const { auditId } = useInAudit()
  const { authCtx } = useContext(AuthContext)
  if (!risk) {
    return <></>
  }

  const url = auditId
    ? `/${authCtx.accountId}:${auditId}/${RisksAbsoluteRoutes.SHOW}/${riskId}`
    : `/${authCtx.accountId}/${RisksAbsoluteRoutes.SHOW}/${riskId}`

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      <Link to={url}>{risk.getName()}</Link>
    </SubjectContainer>
  )
}
