import { ErrorSVG } from 'src/components/Icons/Basic'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const RelevantDateFormLabel = styled.label`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
`

export const NoAuditsLabel = styled.label`
  font-size: 14px;
`

export const EvidenceAuditGridColumn = styled.div<{
  isCentered?: boolean
}>`
  ${({ isCentered }) =>
    isCentered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

export const StyledEvidenceAuditGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(3, max-content);
  font-size: 14px;
  width: 100%;
`

export const StyledEvidenceAuditGridRow = styled.div`
  &:nth-child(even) {
    background-color: ${palette.neutral[30]};
  }
  padding: 8px;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;
  align-items: center;
`

export const RelevantDateErrorIcon = styled(ErrorSVG)`
  width: 20px;
  height: 20px;
  path {
    fill: ${palette.magenta[500]};
  }
`
