import React, { useRef, useState } from 'react'
import { Tooltip } from '../../Tooltip'
import { ReactComponent as PermalinkIcon } from '../../../Icons/assets/permalink-icon.svg'
import { IconButton, IconButtonVariant } from '../IconButton'

export const PermalinkButton = ({
  permalinkUrl,
  isPermalink,
}: {
  permalinkUrl: string
  isPermalink: boolean
}): JSX.Element => {
  const [isClicked, setIsClicked] = useState(false)
  const [show, setShow] = useState(false)
  const onClickTimeoutRef = useRef(0)

  const id = Math.random() * 1000000
  const linkText = isPermalink ? 'Copy Permalink' : 'Copy Link'

  return (
    <Tooltip
      id={`permalink-tooltip-${id}`}
      trigger={['hover', 'click']}
      show={show}
      onToggle={(nextShow) => {
        setIsClicked(false)
        setShow(nextShow)
      }}
      placement="top"
      tooltipBody={isClicked ? 'Copied!' : linkText}
    >
      <IconButton
        variant={IconButtonVariant.icon}
        onClick={async (e) => {
          e.preventDefault()
          window.clearTimeout(onClickTimeoutRef.current)
          setIsClicked(true)
          setShow(true)
          onClickTimeoutRef.current = window.setTimeout(() => {
            setShow(false)
          }, 300)
          await navigator.clipboard.writeText(permalinkUrl)
        }}
      >
        <PermalinkIcon width="24px" height="24px" />
      </IconButton>
    </Tooltip>
  )
}

PermalinkButton.defaultProps = {
  isPermalink: false,
}
