import React, { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import { RequestStatusDropdown } from 'src/pages/Requests/components/RequestItemComponents/RequestStatus'
import { RequestOwnerButton } from 'src/pages/Requests/components/RequestItemComponents/RequestOwnerButton'
import { PERMISSIONS } from 'src/config/roleConfig'
import { GridColumn } from '../../Grid'
import { CheckBoxInput } from '../../Inputs/CheckBox/CheckBoxInput'
import { STATUS_TYPES } from '../../StatusIndicator/StatusIndicator.constants'
import { StatusIndicator } from '../../StatusIndicator'
import { Markup } from '../../Text/Markup'
import { SelectGridRow } from './MultiSelectFormComponents.styles'

export interface SelectControlsRowItemBaseProps {
  control: DocumentRequest
  selectedControlIds: string[]
  setSelectedControlIds: Dispatch<SetStateAction<string[]>>
}

export const SelectRequestsRowItem = ({
  request,
  selectedRequestIds,
  setSelectedRequestIds,
}: {
  request: DocumentRequest
  selectedRequestIds: string[]
  setSelectedRequestIds: Dispatch<SetStateAction<string[]>>
}): JSX.Element => {
  const selectedRequests = new Set(selectedRequestIds)
  const requestId = request.getId()
  const status = request.getStatus()
  const requestString = request.getRequest()
  const ownerId = request.getOwnerId()

  const onChangeHandler = () => {
    selectedRequests.has(requestId)
      ? selectedRequests.delete(requestId)
      : selectedRequests.add(requestId)
    setSelectedRequestIds(Array.from(selectedRequests))
  }

  const onClickHandler: MouseEventHandler = (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.stopPropagation()
    onChangeHandler()
  }

  return (
    <SelectGridRow
      id={`select-requests-item-${requestId}`}
      onClick={onClickHandler}
    >
      <GridColumn justifyContent="center" overflow="hidden">
        <StatusIndicator statusType={STATUS_TYPES.REQUEST} status={status} />
      </GridColumn>
      <GridColumn>
        <CheckBoxInput
          id={requestId}
          checked={selectedRequests.has(requestId)}
          onClick={(e) => e.stopPropagation()}
          onChange={onChangeHandler}
          requiredPermissions={[PERMISSIONS.AUDITOR_ACTION]}
        />
      </GridColumn>
      <GridColumn>
        <Markup markdown={requestString} truncate={2} />
      </GridColumn>
      <GridColumn>
        <RequestStatusDropdown request={request} isDisabled />
      </GridColumn>
      <GridColumn>
        <RequestOwnerButton id={requestId} ownerId={ownerId} isDisabled />
      </GridColumn>
    </SelectGridRow>
  )
}
