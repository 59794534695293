import React from 'react'
import { ActionRow } from '../../PageLayout/PageLayout.styles'
import { IndexContent, IndexNav } from './IndexPage.styles'

type IndexPageProps = {
  pageHeader: JSX.Element
  filterBar?: JSX.Element
  indexButtons?: JSX.Element
  infoSection: JSX.Element
  indexGrid: JSX.Element
  modals?: JSX.Element[]
  isLargeHeader?: boolean
  isNotSticky?: boolean
  noSubPageNav?: boolean
}

export const IndexPage = ({
  pageHeader,
  filterBar,
  indexButtons,
  infoSection,
  indexGrid,
  modals,
  isLargeHeader,
  isNotSticky,
  noSubPageNav,
}: IndexPageProps): JSX.Element => {
  return (
    <>
      {pageHeader}
      <IndexContent>
        <IndexNav
          isLargeHeader={isLargeHeader}
          isNotSticky={isNotSticky}
          noSubPageNav={noSubPageNav}
        >
          <ActionRow>
            {filterBar || infoSection}
            {indexButtons}
          </ActionRow>
        </IndexNav>
        {!!filterBar && infoSection}
        {indexGrid}
      </IndexContent>
      {modals?.map((modal) => modal)}
    </>
  )
}
