import React, { ReactNode, useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import AccordionContext from 'react-bootstrap/AccordionContext'
import { ShowPageAccordionIcon } from '../ShowPageAccordions.components'

export const LinkagesAccordion = styled(Accordion)`
  width: max-content;
  > .accordion-item,
  > .accordion-item:not(:first-of-type) {
    border: 1px solid ${palette.neutral[100]};
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`

export const LinkagesAccordionItem = styled(Accordion.Item)`git `

export const LinkagesAccordionHeader = styled(Accordion.Header).attrs(
  ({ $currentEventKey, children }) => {
    const { activeEventKey } = useContext(AccordionContext)
    const isActive = Number(activeEventKey) === Number($currentEventKey)
    return {
      forwardedAs: 'header',
      children: (
        <>
          {children}
          <ShowPageAccordionIcon isActive={isActive} />
        </>
      ),
    }
  },
)<{ $currentEventKey: string }>`
  .accordion-button {
    color: ${palette.blue[700]};
    font-size: 16px;
    font-weight: 500;
    display: flex;
    gap: 12px;
    padding: 10px;
    
    :focus,
    :hover,
    :not(.collapsed) {
      outline: none;
      background-color: unset;
      box-shadow: none;
    }
    
    ::after {
      display: none;
    }
    `

export const LinkagesAccordionBody = styled(Accordion.Body)`
  padding: 0 16px 16px;
`

export const LinkagesAccordionBodyContent = styled.article.attrs<{
  children: ReactNode
}>(({ children }) => ({
  mb: 'm',
  children: <div className="linkages-grid">{children}</div>,
}))`
  > .linkages-grid {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-auto-rows: 1fr;
    gap: 16px 20px;
    align-items: center;
  }
`
