import styled from 'styled-components/macro'
import { getStatusColor } from './StatusIndicator.helpers'
import { STATUS_TYPES } from './StatusIndicator.constants'

export const StatusIndicatorBar = styled.div<{
  statusType: STATUS_TYPES
  status: number
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: .5rem;
  height: 100%;
  background-color: ${({ statusType, status }) =>
    getStatusColor(statusType, status)};
  }};
`
