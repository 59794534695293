import cloneDeep from 'lodash/cloneDeep'
import {
  IsRiskSetFilter,
  RISK_CATEGORY,
  RISK_MATH,
  RISK_STATUS,
  RISK_TREATMENT,
  RISK_TYPE,
  Risk,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  GetRisksRequest,
  RiskFilter,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import queryString, { ParsedQuery } from 'query-string'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { NONE_ID } from 'src/Utils/globalConstants'
import { useListControls } from '../Controls/ControlsIndexPage/AddControl/BringIntoAudit'
import {
  RISK_LABEL_TO_STATUS,
  RISK_MATH_MAP,
  RiskFields,
  RiskStatusLabels,
  RiskValue,
} from './risks.constants'

/**
 * Returns a Risk object ready to pass in RPC request
 *
 * @param risk:
 * @returns Risk: Risk Data in RPC format
 */
export const updatedRiskRpcData = (
  risk: Risk,
  field: RiskFields,
  value?: RiskValue,
): Risk => {
  const updatedRisk = cloneDeep(risk)
  if (field === RiskFields.id) {
    updatedRisk.setId(value as string)
  } else if (field === RiskFields.accountId) {
    updatedRisk.setAccountId(value as string)
  } else if (field === RiskFields.name) {
    updatedRisk.setName(value as string)
  } else if (field === RiskFields.description) {
    updatedRisk.setDescription(value as string)
  } else if (field === RiskFields.departmentId) {
    updatedRisk.setDepartmentId(value as string)
  } else if (field === RiskFields.customId) {
    updatedRisk.setCustomId(value as string)
  } else if (field === RiskFields.status) {
    updatedRisk.setStatus(value as RISK_STATUS)
  } else if (field === RiskFields.ownerEmail) {
    updatedRisk.setOwnerEmail(value as string)
  } else if (field === RiskFields.predisposingCondition) {
    updatedRisk.setPredisposingCondition(value as string)
  } else if (field === RiskFields.assetAtRisk) {
    updatedRisk.setAssetAtRisk(value as string)
  } else if (field === RiskFields.impactToCustomer) {
    updatedRisk.setImpactToCustomer(value as string)
  } else if (field === RiskFields.likelihood) {
    updatedRisk.setLikelihood(value as RISK_MATH)
  } else if (field === RiskFields.adverseImpact) {
    updatedRisk.setAdverseImpact(value as RISK_MATH)
  } else if (field === RiskFields.treatment) {
    updatedRisk.setTreatment(value as RISK_TREATMENT)
  } else if (field === RiskFields.riskAfterTreatment) {
    updatedRisk.setRiskAfterTreatment(value as RISK_MATH)
  } else if (field === RiskFields.category) {
    updatedRisk.setCategory(value as RISK_CATEGORY)
  }
  return updatedRisk
}

export const getRiskMathField = (risk: Risk, field: RiskFields): RISK_MATH => {
  if (field === RiskFields.likelihood) {
    return risk.getLikelihood()
  } else if (field === RiskFields.adverseImpact) {
    return risk.getAdverseImpact()
  } else if (field === RiskFields.riskAfterTreatment) {
    return risk.getRiskAfterTreatment()
  } else {
    return RISK_MATH.NOT_SET
  }
}

export const getRiskMathCalculation = (
  impact: RISK_MATH,
  likelihood: RISK_MATH,
): RISK_MATH => {
  return RISK_MATH_MAP[impact][likelihood]
}

/**
 * Returns a single Risk object ready to pass in RPC request - will need to be used in an array
 *
 * @param id <string>
 * @param name <string>
 * @param condition  <string>
 * @param likelihood <RISK_MATH>
 * @param adverseImpact <RISK_MATH>
 * @returns
 */
export const getAddRisksRequest = (
  customId: string,
  name: string, // Aliased as threat in UI
  condition = '',
  likelihood = RISK_MATH.NOT_SET,
  adverseImpact = RISK_MATH.NOT_SET,
): Risk => {
  return new Risk()
    .setCustomId(customId)
    .setName(name)
    .setPredisposingCondition(condition || '')
    .setLikelihood(likelihood || RISK_MATH.NOT_SET)
    .setAdverseImpact(adverseImpact || RISK_MATH.NOT_SET)
}

const NO_EMAIL_OWNER = 'unassigned'

export const applyRiskFilters = (
  filter: RiskFilter,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.DEPARTMENT: {
      const departmentIds = new Set(filterParams)
      if (departmentIds.has(NONE_ID)) {
        departmentIds.delete(NONE_ID)
        filter.setIncludeNoDepartment(new BoolValue().setValue(true))
      }
      filter.setDepartmentIdsList(Array.from(departmentIds))
      break
    }
    case FilterParam.OWNER: {
      const emails = [...filterParams]
      const idx = emails.findIndex(
        (email) => email?.toLowerCase() === NO_EMAIL_OWNER,
      )
      if (idx > -1) {
        emails.splice(idx, 1)
        filter.setOwnerEmailsList(emails)
        filter.setIncludeUnassigned(new BoolValue().setValue(true))
      } else {
        filter.setOwnerEmailsList(emails)
      }
      break
    }
    case FilterParam.STATUS: {
      const statuses = (filterParams as RiskStatusLabels[])?.map(
        (label) => RISK_LABEL_TO_STATUS[label],
      )
      filter.setStatusesList(statuses)
      break
    }
    case FilterParam.INHERENT_RISK: {
      const isRiskSet = new Set(filterParams)
      const includeBoth = isRiskSet.has('not_set') && isRiskSet.has('set')
      if (!includeBoth) {
        const riskFilter = new IsRiskSetFilter().setType(RISK_TYPE.INHERENT)
        if (isRiskSet.has('not_set')) {
          riskFilter.setIsSet(false)
        } else if (isRiskSet.has('set')) {
          riskFilter.setIsSet(true)
        }
        filter.addRiskMathFilters(riskFilter)
      }
      break
    }
    case FilterParam.RESIDUAL_RISK: {
      const isRiskSet = new Set(filterParams)
      const includeBoth = isRiskSet.has('not_set') && isRiskSet.has('set')
      if (!includeBoth) {
        const riskFilter = new IsRiskSetFilter().setType(RISK_TYPE.RESIDUAL)
        if (isRiskSet.has('not_set')) {
          riskFilter.setIsSet(false)
        } else if (isRiskSet.has('set')) {
          riskFilter.setIsSet(true)
        }
        filter.addRiskMathFilters(riskFilter)
      }
      break
    }
    default:
      break
  }
}

export const getRisksFilterRequest = (locSearch: string): GetRisksRequest => {
  const req = new GetRisksRequest()
  const filter = new RiskFilter()
  const queryParams: ParsedQuery<string> = queryString.parse(locSearch, {
    arrayFormat: 'bracket',
  })
  // Apply all filters for each parameter in URL
  Object.values(FilterParam).forEach((filterType: FilterParam) =>
    applyRiskFilters(filter, queryParams, filterType),
  )
  req.setFilterBy(filter)
  return req
}

export const useFilterControlIdsByAudit = (risks?: Risk[]): void => {
  const { data: controls, isLoading } = useListControls({
    excludeFromAudit: false,
    isDismissed: false,
    complianceFrameworkIds: [],
  })

  if (!risks || isLoading) {
    return
  } else if (controls) {
    // make a set of audit control ids
    const auditControlIdsSet = new Set<string>()
    controls.getItemsList().forEach((control: Control) => {
      auditControlIdsSet.add(control.getId())
    })

    risks.forEach((riskResponse: Risk) => {
      const riskControlIds = riskResponse.getControlIds().split(',')
      const riskAuditControlIds = riskControlIds.filter((controlId: string) =>
        auditControlIdsSet.has(controlId),
      )
      riskResponse.setControlIds(riskAuditControlIds.join(','))
    })
  }
}
