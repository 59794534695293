import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PropTypes } from 'prop-types'

import { AuthContext } from '../context/authContext'
import AuthAdapter from '../adapter/AuthAdapter'

const SignupConfirm = (props) => {
  const navigate = useNavigate()
  const { authDispatch } = useContext(AuthContext)
  const [message, setMessage] = useState('')

  useEffect(() => {
    AuthAdapter.registerConfirm(authDispatch, props.callid)
      .then(({ success, errors }) => {
        if (success) {
          navigate('/')
        } else {
          // find all errors mentioned
          setMessage(
            Object.values(errors)
              .map((msg) => msg)
              .join(' '),
          )
        }
      })
      .catch((error) => {
        setMessage(error.message)
      })
  }, [navigate, authDispatch, props.callid])

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {message ? (
          <div className="alert alert-danger">{message}</div>
        ) : (
          <div>
            Hang on while we confirm your registration with our service...
          </div>
        )}
      </div>
    </div>
  )
}

SignupConfirm.propTypes = {
  callid: PropTypes.string,
}

export default SignupConfirm
