import React, { useContext, useMemo, useState } from 'react'

type CurrentEvidence = {
  evidenceId: string
  setEvidenceId: React.Dispatch<React.SetStateAction<string>>
}

const CurrentEvidenceContext = React.createContext<CurrentEvidence>({
  evidenceId: '',
  setEvidenceId: () => '',
})

export const useCurrentEvidenceId = (): CurrentEvidence =>
  useContext(CurrentEvidenceContext)

export const CurrentEvidenceContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [evidenceId, setEvidenceId] = useState<string>('')

  const evidenceData: CurrentEvidence = useMemo(
    () => ({
      evidenceId,
      setEvidenceId,
    }),
    [evidenceId, setEvidenceId],
  )
  return (
    <CurrentEvidenceContext.Provider value={evidenceData}>
      {children}
    </CurrentEvidenceContext.Provider>
  )
}
