import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { InfoLinkContainer, InfoLinkSection } from '../../Text/InfoLink.styles'
import { ExternalLink } from '../../Text/Link'

type InfoSectionProps = {
  text: string
  link: string
  linkText?: string
  additionalInfo?: JSX.Element
  isExternalLink?: boolean
}

export const InfoSection = ({
  text,
  link,
  linkText = 'Learn More',
  additionalInfo,
  isExternalLink,
}: InfoSectionProps): JSX.Element => {
  const newText = link ? text.concat(' ') : text
  return (
    <InfoLinkContainer>
      <InfoLinkSection>
        {newText}
        {link && (
          <>
            {isExternalLink ? (
              <ExternalLink href={link} text={linkText} />
            ) : (
              <HashLink to={link}>{linkText}</HashLink>
            )}
          </>
        )}
      </InfoLinkSection>
      {additionalInfo}
    </InfoLinkContainer>
  )
}

InfoSection.defaultProps = {
  link: '',
}
