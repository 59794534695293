import React from 'react'
import { ModalFormId } from '../../../../components/ModalForms'
import { StandardButtonVariant } from '../../../../components/Reusable/Buttons'
import {
  TabPanelButtonGroup,
  TabPanelNoteItem,
} from '../../../../components/Reusable/Tabs/TabNotes/styles'
import { AddConnectionModalToggle } from './AddConnectionModalToggle'

export const TabNote = ({
  controlId,
  isNotApplicable,
}: {
  controlId: string
  isNotApplicable: boolean
}): JSX.Element => {
  const addConnectionModalId = isNotApplicable
    ? ModalFormId.NA_INFO
    : ModalFormId.ADD_EVIDENCE_GENERATOR_CONNECTED_TAB
  return (
    <TabPanelNoteItem>
      <TabPanelButtonGroup>
        <AddConnectionModalToggle
          buttonVariant={StandardButtonVariant.PRIMARY}
          modalFormId={addConnectionModalId}
          controlId={controlId}
          isNotApplicable={isNotApplicable}
        />
      </TabPanelButtonGroup>
    </TabPanelNoteItem>
  )
}
