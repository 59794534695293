export enum SignupTypes {
  GENERIC_SIGNUP = 'signup',
  REPORT_SCANNER = 'report-scanner',
  SECURITY_QUESTIONNAIRE = 'security-questionnaire',
}

export type SignUpProps = {
  header: string
  subHeader: string
  description: string
  signupType: SignupTypes
  redirectUrl: string
  isAuthed?: boolean
}

export const SIGN_UP_PROPS: Record<string, SignUpProps> = {
  genericSignup: {
    header: 'Welcome to Trustero',
    subHeader: 'Create your free account now!',
    description:
      'Create your free account to analyze SOC 2 reports and answer security questionnaires using our AI Copilot.',
    signupType: SignupTypes.GENERIC_SIGNUP,
    redirectUrl: '/dashboard',
  },
  reportScan: {
    header: 'SOC 2 Report Scan',
    subHeader: 'Upload a SOC 2 Report for Quick, Automated Analysis',
    description:
      'Report Scan recognizes key features of the report using AI so you can get clarity about what the report really means.',
    signupType: SignupTypes.REPORT_SCANNER,
    redirectUrl: '/report_scan',
  },
  securityQuestionnaire: {
    // below \u2011 is a non-breaking dash for styling
    header: 'AI Questionnaire Co\u2011Pilot Free',
    subHeader:
      'Quick, concise, accurate answers to your security questionnaires - Free for everyone!',
    description:
      'The AI Copilot answers nuanced security questions faster and more accurately than a human based on your knowledge base.',
    signupType: SignupTypes.SECURITY_QUESTIONNAIRE,
    redirectUrl: '/security_questionnaire',
  },
}

export const SIGNUP_REDIRECT_ROUTES: Record<SignupTypes, string> = {
  // These routes cannot be imported from the main app because of when this file is initialized
  [SignupTypes.GENERIC_SIGNUP]: '/dashboard',
  [SignupTypes.REPORT_SCANNER]: '/scan_report',
  [SignupTypes.SECURITY_QUESTIONNAIRE]: '/answer_questionnaire',
}
