import React, { useEffect, useRef, useState } from 'react'
import useClickOutside from '../../../Utils/useClickOutside'
import * as keyDown from './keyboardUtils'
import {
  DropdownLabel,
  DropdownLabelProps,
  DropdownContainer,
  DropdownContainerProps,
  DropdownToggle,
  DropdownIcon,
  DropdownMenu,
  DropdownItem,
  DropdownSelectedIcon,
} from './styles'

type Props = {
  label?: string
  items: {
    id: string
    value: string
  }[]
  initVal: string
  selectCB?: (info: string) => void
  labelProps?: DropdownLabelProps
  containerProps?: DropdownContainerProps
}

/**
 * @deprecated - please use the <SelectDropdown /> Component moving forward
 */
export function Dropdown({
  label,
  items,
  initVal,
  selectCB,
  labelProps,
  containerProps,
}: Props): JSX.Element {
  const [selected, setSelected] = useState(initVal)
  useEffect(() => setSelected(initVal), [initVal])

  const select: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    const button = e.target as HTMLButtonElement
    setIsToggled(false)
    setSelected(button.textContent as string)
    if (selectCB) selectCB(button.dataset.info as string)
  }

  const [isToggled, setIsToggled] = useState(false)
  const toggle: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    setIsToggled(!isToggled)
  }

  const dropdownRef = useClickOutside(() => {
    if (isToggled) setIsToggled(false)
  }) as React.RefObject<HTMLDivElement>

  // List of nodes
  const nodesRef = useRef<HTMLButtonElement[]>([])
  // If the list of users change, update the length of nodesRef
  useEffect(() => {
    nodesRef.current = nodesRef.current.slice(0, items.length)
  }, [items])

  return (
    <DropdownLabel id={`dropdown-label-${label}`} {...labelProps}>
      <span>{label}</span>
      <DropdownContainer ref={dropdownRef} {...containerProps}>
        <DropdownToggle
          isToggled={isToggled}
          id={`dropdown-toggle-${label}-${new Date().getTime()}`}
          aria-labelledby={`dropdown-label-${label}`}
          onClick={toggle}
          onKeyDown={keyDown.toggle(nodesRef, setIsToggled)}
        >
          {selected}
          <DropdownIcon />
        </DropdownToggle>
        <DropdownMenu
          aria-expanded={isToggled}
          role="list"
          isToggled={isToggled}
        >
          {items.map((item, idx) => (
            <DropdownItem
              id={item.id}
              key={item.id}
              ref={(node) => {
                if (node && node !== nodesRef.current[idx]) {
                  nodesRef.current[idx] = node
                }
              }}
              data-info={item.id}
              onClick={select}
              onKeyDown={keyDown.item(idx, nodesRef, setIsToggled)}
            >
              {item.value}
              {item.value === selected ? <DropdownSelectedIcon /> : null}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </DropdownContainer>
    </DropdownLabel>
  )
}
