import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const StyledShowPageAccordionIcon = styled(FontAwesomeIcon)<{
  disabled?: boolean
}>`
  color: ${palette.neutral[600]};
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
`
