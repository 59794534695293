import { useCallback, useMemo } from 'react'
import log from 'loglevel'
import { MutatorOptions } from 'swr'
import {
  AuditRecord,
  AuditRecords,
  GetAuditRequest,
  GetAuditsRequest,
} from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { useListMember } from '../../hooks/useListMember'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { useSwrImmutableGrpc } from '../../useSwrImmutableGrpc'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { mutateControlDependencies } from '../index'
import { useGrpcRevalidateByMethod } from '../../useGrpcMutate'

export const useInvalidateAudits = (): (() => Promise<void>) => {
    const mutateFunc = useGrpcRevalidateByMethod()
  
    return useCallback(async () => {
      try {
        mutateFunc(AuditPromiseClient.prototype.getAudits)
      } catch (error) {
        log.error('Error when invalidating audits cache', error)
      }
    }, [mutateFunc])
  }


export const useAudit = (
  id: string,
  shouldFetch = true,
  includeDeleted = false,
): GrpcResponse<AuditRecord> => {
  const client = useAuthorizedGrpcClient(AuditPromiseClient)
  const methodMutator = useGrpcRevalidateByMethod()
  const response = useListMember(
    {
      id,
      asyncCall: AuditPromiseClient.prototype.getAudits,
      request: new GetAuditsRequest().setIncludeDeleted(
        new BoolValue().setValue(includeDeleted),
      ),
      newCollection: () => new AuditRecords(),
    },
    shouldFetch && !!id,
  )
  const mutate = useCallback(
    async (
      auditRecord: Promise<AuditRecord>,
      opts: MutatorOptions | boolean | undefined,
    ) => {
      const api = !auditRecord
        ? client.getAudit(new GetAuditRequest().setAuditId(id))
        : auditRecord
      const result: AuditRecord | undefined = await response.mutate(api, opts)
      await Promise.all([
        methodMutator(ModelPromiseClient.prototype.listControls),
        mutateControlDependencies(methodMutator),
      ])
      return result
    },
    [client, id, methodMutator, response],
  )

  return NewGrpcResponse({
    ...response,
    mutate,
  })
}

export const useAudits = (
  request?: GetAuditsRequest,
  shouldFetch?: boolean,
): GrpcResponse<AuditRecords> => {
  const { response } = useSwrImmutableGrpc(
    AuditPromiseClient.prototype.getAudits,
    request || new GetAuditsRequest(),
    shouldFetch
  )
  return NewGrpcResponse(response)
}

export const useHasOpenAudits = (): boolean => {
  const audits = useAudits()
  const hasOpenAudits = useMemo<boolean>(() => {
    return (audits.data?.getItemsList() || []).some(
      (auditItem: AuditRecord) => !auditItem.getIsClosed(),
    )
  }, [audits])

  return hasOpenAudits
}