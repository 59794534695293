import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useReceptorTemplate } from './useReceptor'

type Props = {
  receptorModelId: string
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

export function ReceptorLogo({
  receptorModelId,
  ...props
}: Props): JSX.Element {
  const receptor = useReceptorTemplate({ receptorModelId })
  if (receptor) {
    const Logo = receptor.logo
    return <Logo {...props} />
  }

  return <Spinner size={'sm'} animation={'border'} />
}
