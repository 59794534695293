import React from 'react'
import { SERVICE_ROLE } from '@trustero/trustero-api-web/lib/service/service_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { DirectRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { SERVICE_ROLE_TO_LABEL } from 'src/pages/Services/Services.constants'
import { NotCoveredContainer, NotCoveredNames } from './NotCoveredModal.styles'
import { NotCoveredButton } from './NotCoveredModal.components'

export const ServiceRolesNotFulfilledModal = ({
  roles,
}: {
  roles: SERVICE_ROLE[]
}): JSX.Element => {
  const show = useIsShowModal(ModalFormId.ROLES_NOT_FULFILLED)
  const onHide = useHideModal({ modalId: ModalFormId.ROLES_NOT_FULFILLED })
  const roleLabels = roles
    .map((role) => SERVICE_ROLE_TO_LABEL[role])
    .sort((a, b) => a.localeCompare(b))

  return (
    <ModalForm
      size="lg"
      formId={ModalFormId.ROLES_NOT_FULFILLED}
      title="These Service Roles Are Not Fulfilled"
      description="You haven't selected which services you use for the following purposes."
      show={show}
      hide={onHide}
      customFooter={
        <NotCoveredButton
          onHide={onHide}
          route={DirectRoutes.SCOPE}
          text="Finish Scoping"
        />
      }
      hideCancel
    >
      <form id={ModalFormId.ROLES_NOT_FULFILLED}>
        <NotCoveredContainer>
          <NotCoveredNames>
            {roleLabels.map((role) => (
              <div key={role}>{role}</div>
            ))}
          </NotCoveredNames>
        </NotCoveredContainer>
      </form>
    </ModalForm>
  )
}
