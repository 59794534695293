import React from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import utf8 from 'utf8'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import {
  AddDocumentRequest,
  Document as DocumentMsg,
  DOCUMENT_TYPE,
  DeleteDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import log from 'loglevel'
import {
  RichTextEditor,
  RteModal,
  RteModalQueryParams,
} from '../../../../../components/RichTextEditor'

type DocumentMarkdownEditorProps = {
  id?: string
  modelId?: string
  title: string
  markdown: string
  onUpdate: () => void
}

export const DocumentItemMarkdownEditor = (
  props: DocumentMarkdownEditorProps,
): JSX.Element => {
  const location = useLocation()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  if (!props.modelId && props.id) {
    return <></>
  }

  const query = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  })

  const objectId = props.id ?? props.modelId ?? ''
  const show =
    query[RteModalQueryParams.SHOW_RTE_MODAL] === 'true' &&
    query[RteModalQueryParams.UNIQUE_ID] === objectId

  return (
    <RteModal show={show}>
      <RichTextEditor
        objectId={objectId}
        title={props.title}
        markdown={props.markdown}
        isEditableTitle
        saveCB={async (title, body) => {
          const regex = new RegExp(/(\[.*?\])(?!\(http)/gm)
          const isCustomized = !regex.test(body)
          const documentMsg = new DocumentMsg()
            .setId(props.id ?? '')
            .setSubjectmodelid(props.modelId ?? '')
            .setSubjectmodeltype(MODEL_TYPE.POLICY)
            .setDoctype(DOCUMENT_TYPE.POLICYDOC)
            .setMime('text/markdown')
            .setBody(
              new Uint8Array(
                Array.from(utf8.encode(body)).map((s) => s.charCodeAt(0)),
              ),
            )
            .setCaption(title)
            .setIsCustomized(new BoolValue().setValue(isCustomized))
          try {
            const addedDocument = await attachmentClient.addDocument(
              new AddDocumentRequest().setDocument(documentMsg),
            )
            if (props.id)
              await attachmentClient.deleteDocument(
                new DeleteDocumentRequest().setDocumentId(objectId),
              )
            props.onUpdate()
            return addedDocument
          } catch (err) {
            log.error(
              `Error when saving policy documentId: ${objectId} in policyId: ${props.modelId}`,
              err,
            )
          }
        }}
      />
    </RteModal>
  )
}
