import React from 'react'
import styled from 'styled-components/macro'
import { textStyle, compose, space, MarginProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import DropdownItem from 'react-bootstrap/DropdownItem'
import { Link, LinkProps } from 'react-router-dom'
import { DropdownSVG } from '../../../Icons/Basic'
import palette from '../../../../designSystem/variables/palette'

export const FilterBarContainer = styled.ul.attrs({})<MarginProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 32px;
  ${compose(space)}
`

export const FilterBarWrapper = styled.ul.attrs({})<MarginProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 32px;
  ${compose(space)}
`

export const FilterDropdownToggle = styled.button.attrs((props) => {
  return {
    textStyle: 'body.default',
    children: (
      <>
        {props.children}
        <DropdownSVG />
      </>
    ),
  }
})<{ $isAi?: boolean }>`
  ${textStyle}

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin: 0;
  padding: 0 8px 0 8px;
  border: none;
  border-radius: 2px;
  background-color: white;

  &[aria-expanded='false'] {
    p {
      ${({ $isAi }) => $isAi && `color: ${palette.purple['600']}`}
    }
  }

  :hover {
    background-color: ${themeGet('colors.bg.neutral')};
  }

  &[aria-expanded='true'] {
    background-color: #515d75;
    color: white;

    svg {
      transform: scaleY(-1);
    }
  }

  svg {
    transform: scaleY(1);
    transition: transform 0.35s;
    fill: ${themeGet('colors.text.icon.low')};
  }

  :after {
    display: none;
  }
`

export const FilterDropdownItem = styled(DropdownItem).attrs({
  as: Link,
})<LinkProps>`
  display: flex;
  align-items: center;
  height: 36px;
  color: unset !important;
  text-decoration: none;

  :hover,
  :focus {
    background-color: ${themeGet('colors.bg.neutral')};

    .form-check-input {
      border-color: ${themeGet('colors.fill.status.success.light')};
      box-shadow: 0 0 0 0.25rem ${palette.green['100']};
    }
  }

  .form-check {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 6px 16px !important;
  }
`
