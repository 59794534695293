import React from 'react'
import log from 'loglevel'
import { Dropdown } from 'react-bootstrap'
import { E2E_IDS } from 'src/Utils/testIds'
import { RISK_CATEGORY } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from 'src/components/Reusable/StandardDropdown/StandardDropdown.styles'
import { useHasEditPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { useCreateOrUpdateRisks, useRisk } from '../risks.hooks'
import { RISK_CATEGORY_DROPDOWN, RISK_CATEGORY_LABEL } from '../risks.constants'
import { RiskLinkageExclamationIcon } from '../risks.styles'

export const RisksCategoryMenu = ({
  riskId,
}: {
  riskId: string
}): JSX.Element => {
  const hasEditPermission = useHasEditPermissions()
  const createOrUpdateRisk = useCreateOrUpdateRisks()
  const risk = useRisk(riskId)

  if (!risk) {
    return <></>
  }

  const isNoCategory = risk.getCategory() === RISK_CATEGORY.NO_CATEGORY
  const category = risk.getCategory()
  const updateRisk = async (newCategory: RISK_CATEGORY): Promise<void> => {
    try {
      risk.setCategory(newCategory)
      await createOrUpdateRisk([risk])
    } catch (err) {
      log.error(`Failed to update risk category for riskId: ${riskId}:`, err)
    }
  }

  return (
    <Dropdown
      id={E2E_IDS.RISK_CATEGORY_MENU}
      data-testid={E2E_IDS.RISK_CATEGORY_MENU}
      className="risk-category-menu"
    >
      <Dropdown.Toggle
        as={StyledDropdownToggle}
        width="100%"
        disabled={!hasEditPermission}
      >
        {isNoCategory && <RiskLinkageExclamationIcon />}
        {RISK_CATEGORY_LABEL[category]}
      </Dropdown.Toggle>
      <Dropdown.Menu as={StyledDropdownMenu} width="100%">
        {RISK_CATEGORY_DROPDOWN.map((item) => (
          <Dropdown.Item
            key={item.name}
            eventKey={item.value}
            onClick={() => updateRisk(item.value)}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
