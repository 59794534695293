import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ListDocumentRequestsRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import { getDRLFilterRequest } from './requests.helpers'

export const useDRLRequest = (): ListDocumentRequestsRequest => {
  const location = useLocation()

  return useMemo(() => getDRLFilterRequest(location.search), [location.search])
}
