import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import jwtDecode from 'jwt-decode'
import log from 'loglevel'
import queryString from 'query-string'
import isArray from 'lodash/isArray'
import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import {
  goToHubspotSupport,
  hasHubspotRedirect,
} from 'src/Utils/hubspot/hubspot.utils'
import AuthContext, {
  AuthActionType,
  JwtNtrcePayload,
  tokenDecodeToMinutes,
} from '../../context/authContext'
import { translateAuthError } from '../../adapter/gRpcAdapter'
import { isDev } from '../../Utils/environment'
import { LoginErrors } from './LoginPage.styles'
import { AuthorizationComponentProps } from './LoginPage.constants'

const logger = log.getLogger('login')

const AttemptUrlLogin = ({
  login,
  setIsRedirecting,
}: AuthorizationComponentProps): null => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    if (Object.keys(searchParams).length)
      logger.debug('Attempting url login', searchParams)
    if (!searchParams.token) return
    const accessToken = searchParams.token as string
    logger.debug('Found access token search param: ', accessToken)

    // if token exists, start spinner to attempt redirect
    setIsRedirecting?.(true)

    // Ensure token has not expired
    const tokenMinutes = tokenDecodeToMinutes(accessToken, false)
    if (tokenMinutes.expiration <= 0) {
      // stop spinner
      setIsRedirecting?.(false)
      logger.debug('Token was expired')
      navigate('/login')
      return
    }

    const {
      aid: accountId,
      sub: email,
      auc: useCase,
      rol: role,
    } = jwtDecode<JwtNtrcePayload>(accessToken)

    // Handle Hubspot KB/Tickets SSO for Email users
    const redirect = searchParams.redirect_url || searchParams.redirect
    const redirectUrl = redirect && isArray(redirect) ? redirect[0] : redirect
    if (redirectUrl && hasHubspotRedirect(redirectUrl)) {
      goToHubspotSupport(redirectUrl, accessToken)
      return
    }

    // stop spinner if no redirect
    setIsRedirecting?.(false)

    // token is valid, attempt login
    login({
      accessToken,
      accountId,
      email,
      useCase: Number(useCase) || USE_CASE.SAAS_BUYER,
      role: Number(role),
      redirectUrl: searchParams.redirect as string,
    })
  })

  return null
}

const AttemptOAuthLogin = ({
  login,
  setErrorMsg,
}: AuthorizationComponentProps): null => {
  const [cookies, , removeCookie] = useCookies([
    'error',
    'access_token',
    'redirect',
  ])

  useEffect(() => {
    const cookieOptions = isDev() ? {} : { path: '/', domain: 'trustero.com' }

    if (Object.keys(cookies).length) {
      logger.debug('Attempting oauth login', cookies)
    }

    if (cookies?.error) {
      logger.debug('found error cookie: ', document.cookie)
      removeCookie('error', cookieOptions)
      const err = { code: 3, message: atob(cookies.error) }
      const errors = translateAuthError(err)
      const finalErrorMsg = Object.values(errors)[0]
      setErrorMsg?.(finalErrorMsg)
    }

    if (!cookies?.access_token) return

    const accessToken = cookies.access_token
    logger.debug('Found access token cookie: ', accessToken)

    removeCookie('access_token', cookieOptions)
    removeCookie('redirect', cookieOptions)

    const {
      aid: accountId,
      sub: email,
      auc: useCase,
      rol: role,
    } = jwtDecode<JwtNtrcePayload>(accessToken)

    login({
      accessToken,
      accountId,
      email,
      useCase: Number(useCase) || USE_CASE.SAAS_BUYER,
      role: Number(role),
      redirectUrl: cookies.redirect,
    })
  })

  return null
}

/**
 * Attempt to log the user in with the provided provided access token
 *
 * ! Note
 * - Once the user has been authenticated
 * - `App.tsx` will
 *   - Unmount `<AppUnauth>`
 *   - Then immediately mount `<AppAuth>`
 * - `authDispatch` asynchronously updates the state of `AuthContext`
 * - So we must wait for the update to resolve
 * - BEFORE we navigate to the `redirectUrl`
 * - If we don't follow the strict order mentioned above, then
 *   - `authDispatch` will fire asynchronously
 *   - While `navigate` will fire synchronously
 * - Meaning
 *   - We will navigate to an undefined route in `<AppUnauth>`
 *     - Which will redirect to `/login`
 *   - Then authenticate the user some time afterwards
 *     - Unmount `<AppUnauth>`
 *     - Mount `<AppAuth>`
 *   - Thus, landing on `/login` in `<AppAuth>`
 *     - Causing `<MainApp>` to interpret the `accountId` param as `login`
 *     - Which will ultimately redirect to the `<AccessDeniedPage>`
 *     - Because we are unable to validate access
 */
export const AttemptLogin = ({
  setIsRedirecting,
}: {
  setIsRedirecting?: React.Dispatch<React.SetStateAction<boolean>>
}): ReactElement | null => {
  const navigate = useNavigate()
  const { authDispatch } = useContext(AuthContext)
  const [errorMsg, setErrorMsg] = useState('')

  const login = ({
    accessToken,
    accountId,
    email,
    redirectUrl,
    useCase,
    role,
  }: {
    accessToken: string
    accountId?: string
    email?: string
    redirectUrl: string
    useCase: USE_CASE
    role: USER_ROLE
  }) => {
    if (!accountId || !email) {
      setErrorMsg('Malformed token received')
      return
    } else if (redirectUrl && !redirectUrl.length) {
      setErrorMsg('Malformed token received')
      logger.error(
        'Did not receive a redirect URL from ntrced while logging in',
      )
    }
    logger.debug(
      'Login dispatch: ',
      email,
      ': to account: ',
      accountId,
      ': with token:',
      accessToken,
    )
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          authDispatch({
            type: AuthActionType.LOGIN,
            accessToken,
            accountId,
            email,
            useCase,
            role,
          }),
        )
      })
    }).then(() => {
      logger.debug('Redirecting to:', redirectUrl)
      navigate(redirectUrl)
    })
  }

  return (
    <>
      <AttemptUrlLogin login={login} setIsRedirecting={setIsRedirecting} />
      <AttemptOAuthLogin login={login} setErrorMsg={setErrorMsg} />
      <LoginErrors show={Boolean(errorMsg)}>{errorMsg}</LoginErrors>
    </>
  )
}
