import React from 'react'
import { GridRow } from '../Grid'
import { EmptyStateTextColumn } from './EmptyStateTextOnly.styles'
import { EmptyStateTextOnlySize } from './EmptyStateTextOnly.constants'

export const EmptyStateTextOnly = ({
  text,
  size,
}: {
  text: string
  size?: EmptyStateTextOnlySize
}): JSX.Element => {
  return (
    <GridRow>
      <EmptyStateTextColumn $size={size}>{text}</EmptyStateTextColumn>
    </GridRow>
  )
}
