import React from 'react'
import { EvidenceGenerator } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { GridColumn, GridRow } from '../../../../components/Reusable/Grid'
import { EvidenceGeneratorGrid } from './EvidenceGeneratorsGrid'

type Props = {
  controlId: string
  evidenceGenerators: EvidenceGenerator[]
}

export const TabBody = ({
  controlId,
  evidenceGenerators,
}: Props): JSX.Element => {
  if (evidenceGenerators.length === 0) {
    return (
      <GridRow>
        <GridColumn gridColumn="1 / -1" justifyContent="center">
          {'No New Connections Available'}
        </GridColumn>
      </GridRow>
    )
  }
  return (
    <EvidenceGeneratorGrid
      controlId={controlId}
      evidenceGenerators={evidenceGenerators}
    />
  )
}
