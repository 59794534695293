import React, { useState } from 'react'
import ReactMde from 'react-mde'
import showdown from 'showdown'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { FullWidthDiv } from '../Flex'
import { EditableOnClick, EditableOnClickProps } from './EditableOnClick'

const converter = new showdown.Converter()
converter.setFlavor('github')
converter.setOption('simpleLineBreaks', false)

export type EditableMarkupProps = Omit<EditableOnClickProps, 'editMode'>

export const EditableMarkupOnClick = (
  props: EditableMarkupProps,
): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')
  return (
    <EditableOnClick
      {...props}
      editMode={({ value, onChange }) => (
        <ReactMde
          value={value}
          onChange={onChange}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
      )}
    />
  )
}

export const EditableMarkup = ({
  value,
  onChange,
}: {
  value: string
  onChange: (v: string) => void
}): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')
  return (
    <FullWidthDiv>
      <ReactMde
        value={value}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
    </FullWidthDiv>
  )
}
