import React, { useState } from 'react'
import { Framework } from '@trustero/trustero-api-web/lib/model/framework_pb'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '../../../Reusable/StandardDropdown/StandardDropdown.styles'
import {
  MultiSelectDropdownCheckBox,
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
} from '../../../Reusable/MultiSelectDropdown'
import { MenuPlaceholder } from '../../../Placeholders/MenuPlaceholder'
import { ListItemPlaceholder } from '../../../Placeholders/ListItemPlaceholder'
import {
  FrameworkComponent,
  useFrameworkIdsByComplianceFrameworkId,
} from '../../../async/Framework'
import { CountChip } from '../../../Reusable/Chips/BasicChip'

const FrameworkDropdownMenu = ({
  frameworkIds,
  onFrameworkSelected,
}: {
  frameworkIds: string[]
  onFrameworkSelected: (f: Framework.AsObject) => () => void
}): JSX.Element => {
  return (
    <Dropdown.Menu as={StyledDropdownMenu}>
      {frameworkIds.map((modelId) => (
        <FrameworkDropdownItem
          key={modelId}
          modelId={modelId}
          onFrameworkSelected={onFrameworkSelected}
        />
      ))}
    </Dropdown.Menu>
  )
}

const FrameworkDropdownItem = ({
  modelId,
  onFrameworkSelected,
}: {
  modelId: string
  onFrameworkSelected: (f: Framework.AsObject) => () => void
}): JSX.Element => {
  return (
    <Dropdown.Item eventKey={modelId} as={MultiSelectDropdownItem}>
      <MultiSelectDropdownCheckBoxLabel>
        <FrameworkComponent
          props={{ onFrameworkSelected }}
          modelId={modelId}
          placeholder={<ListItemPlaceholder height={32} />}
          child={({ framework, props }) => (
            <>
              <MultiSelectDropdownCheckBox
                id={modelId}
                onChange={() =>
                  props?.onFrameworkSelected(framework.toObject())()
                }
              />
              {framework.getName()}
            </>
          )}
        />
      </MultiSelectDropdownCheckBoxLabel>
    </Dropdown.Item>
  )
}

export const FrameworkDropdown = ({
  selectedFrameworks,
  onFrameworkSelected,
  placeholder,
  complianceFrameworkId = '',
  showCount = true,
}: {
  selectedFrameworks: Framework.AsObject[]
  onFrameworkSelected: (f: Framework.AsObject) => () => void
  placeholder?: string
  complianceFrameworkId?: string
  showCount?: boolean
}): JSX.Element => {
  const frameworks = useFrameworkIdsByComplianceFrameworkId(
    complianceFrameworkId,
  )

  if (frameworks.loading) {
    return (
      <Dropdown.Menu as={StyledDropdownMenu}>
        <MenuPlaceholder height={96} />
      </Dropdown.Menu>
    )
  }
  const frameworkIds = frameworks?.data?.getItemList() || []

  return (
    <Dropdown
      id="add-model-form-framework-dropdown"
      drop="down"
      autoClose="outside"
    >
      <Dropdown.Toggle as={StyledDropdownToggle} width="100%">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {selectedFrameworks.map((framework) => framework.name).join(', ') ||
            (placeholder ?? 'Select Frameworks')}
          {showCount && (
            <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
              {selectedFrameworks.length}
            </CountChip>
          )}
        </div>
      </Dropdown.Toggle>
      <FrameworkDropdownMenu
        frameworkIds={frameworkIds}
        onFrameworkSelected={onFrameworkSelected}
      />
    </Dropdown>
  )
}

export const MultipleFrameworkDropdown = ({
  onFrameworkSelected,
  placeholder,
  complianceFrameworkId,
  showCount = false,
}: {
  placeholder: string
  complianceFrameworkId: string
  showCount?: boolean
  onFrameworkSelected: (f: Framework.AsObject) => () => void
}): JSX.Element => {
  const [selectedFrameworks, setSelectedFrameworks] = useState<
    Framework.AsObject[]
  >([])
  const newOnFrameworkSelected = (selectedFramework: Framework.AsObject) => {
    onFrameworkSelected(selectedFramework)()
    return () => {
      setSelectedFrameworks((state) => {
        const newState = [...state]
        const idx = newState.findIndex(
          (framework) => framework.modelId === selectedFramework.modelId,
        )
        idx !== -1 ? newState.splice(idx, 1) : newState.push(selectedFramework)
        return newState
      })
    }
  }
  return (
    <FrameworkDropdown
      selectedFrameworks={selectedFrameworks}
      onFrameworkSelected={newOnFrameworkSelected}
      placeholder={placeholder}
      complianceFrameworkId={complianceFrameworkId}
      showCount={showCount && !!selectedFrameworks.length}
    />
  )
}
