import palette from './variables/palette'

export const baseTheme = {
  breakpoints: ['600px', '960px', '1280px'],
  space: {
    xxxs: 2,
    xxs: 4,
    xs: 8,
    s: 12,
    m: 16,
    l: 24,
    xl: 32,
    xxl: 48,
  },
  colors: {
    bg: {
      primary: palette.neutral['30'],
      primaryDark: palette.neutral['50'],
      secondary: palette.magenta['30'],
      secondaryDark: palette.magenta['50'],
      tertiary: palette.green['30'],
      tertiaryDark: palette.green['50'],
      quaternary: palette.purple['30'],
      quaternaryDark: palette.purple['50'],
      neutral: palette.neutral['30'],
      neutralHover: palette.neutral['40'],
      neutralDark: palette.neutral['50'],
      status: {
        error: palette.magenta['30'],
        info: palette.neutral['30'],
        success: palette.green['30'],
        warning: palette.orange['30'],
      },
      button: {
        secondary: {
          hover: {
            magenta: palette.magenta['30'],
            purple: palette.purple['30'],
          },
        },
      },
    },
    fill: {
      black: palette.neutral.black,
      white: palette.neutral.white,
      primary: {
        light: palette.neutral['300'],
        base: palette.neutral['400'],
        dark: palette.neutral['600'],
        extraDark: palette.neutral['900'],
      },
      secondary: {
        light: palette.magenta['300'],
        base: palette.magenta['400'],
        dark: palette.magenta['600'],
        extraDark: palette.magenta['800'],
      },
      tertiary: {
        light: palette.green['300'],
        base: palette.green['400'],
        dark: palette.green['600'],
        extraDark: palette.green['800'],
      },
      quaternary: {
        light: palette.purple['300'],
        base: palette.purple['400'],
        dark: palette.purple['600'],
        extraDark: palette.purple['800'],
      },
      neutral: {
        light: palette.neutral['300'],
        base: palette.neutral['400'],
        dark: palette.neutral['500'],
        extraDark: palette.neutral['800'],
      },
      status: {
        error: {
          light: palette.magenta['300'],
          base: palette.magenta['500'],
          dark: palette.magenta['600'],
          extraDark: palette.magenta['900'],
        },
        warning: {
          light: palette.orange['300'],
          base: palette.orange['400'],
          dark: palette.orange['500'],
          extraDark: palette.orange['600'],
        },
        info: {
          light: palette.neutral['200'],
          base: palette.neutral['300'],
          dark: palette.neutral['400'],
          extraDark: palette.neutral['500'],
        },
        success: {
          light: palette.green['300'],
          base: palette.green['400'],
          dark: palette.green['500'],
          extraDark: palette.green['800'],
        },
      },
    },
    border: {
      primary: {
        light: palette.neutral['200'],
        base: palette.neutral['400'],
        dark: palette.neutral['800'],
      },
      secondary: {
        light: palette.magenta['200'],
        base: palette.magenta['400'],
        dark: palette.magenta['600'],
      },
      tertiary: {
        light: palette.green['200'],
        base: palette.green['400'],
        dark: palette.green['600'],
      },
      quarternary: {
        light: palette.purple['200'],
        base: palette.purple['400'],
        dark: palette.purple['600'],
      },
      neutral: {
        superlight: palette.neutral['50'],
        light: palette.neutral['100'],
        base: palette.neutral['200'],
        dark: palette.neutral['600'],
      },
      status: {
        error: palette.magenta['400'],
        info: palette.neutral['300'],
        success: palette.green['400'],
        warning: palette.orange['700'],
      },
    },
    text: {
      default: palette.neutral['900'],
      low: palette.neutral['400'],
      med: palette.neutral['600'],
      disabled: palette.neutral['200'],
      icon: {
        default: palette.neutral['600'],
        low: palette.neutral['300'],
        disabled: palette.neutral['100'],
        hover: palette.neutral['50'],
      },
      button: {
        primary: {
          inverse: palette.neutral.white,
        },
        secondary: {
          magenta: palette.magenta['600'],
          purple: palette.purple['600'],
          neutral: palette.neutral['800'],
        },
        disabled: palette.neutral['200'],
      },
      input: {
        placeholder: palette.neutral['400'],
        default: palette.neutral['900'],
      },
    },
  },
  fontSizes: {
    0: 10, // 0.625rem
    1: 12, // 0.75rem
    2: 13, // 0.8125rem
    3: 14, // 0.875rem
    4: 16, // 1rem
    5: 18, // 1.125rem
    6: 20, // 1.25rem
    7: 24, // 1.5rem
    8: 28, // 1.75rem
    9: 36, // 2rem
    10: 40, // 2.125rem
  },
  fontWeights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  lineHeights: {
    body: 1.5,
    default: 1,
    header: 1.25,
  },
  letterSpacings: {
    condensed: {
      xs: -0.2,
      s: -0.3,
      m: -0.5,
      l: -0.7,
      xl: -1.4,
    },
    none: 0,
    open: {
      s: 0.3,
      m: 0.5,
      l: 0.7,
      xl: 1.4,
    },
  },
  shadows: {
    outer: {
      high: '0 3px 10px 10px rgba(0,0,0,0.15)',
      base: '0 2px 5px 0 rgba(0,0,0,0.20)',
      low: '0 1px 1px 1px rgba(0,0,0,0.20)',
    },
    inner: {
      base: 'inset 0 1px 2px 0 rgba(0,0,0,0.20)',
    },
  },
}

export const variantTheme = {
  buttons: {
    primary: {
      border: 'none',
      borderRadius: '6px',
      backgroundColor: baseTheme.colors.fill.primary.extraDark,
      textDecoration: 'none',
      '&&, &&:visited': {
        color: baseTheme.colors.text.button.primary.inverse,
      },
      ':not(:disabled)': {
        ':hover': {
          backgroundColor: baseTheme.colors.fill.primary.dark,
          textDecoration: 'none',
        },
        '&.active, :active': {
          backgroundColor: 'black',
          textDecoration: 'none',
        },
      },
      ':disabled': {
        backgroundColor: baseTheme.colors.fill.primary.light,
        color: `${baseTheme.colors.text.disabled} !important`,
        textDecoration: 'none',
      },
    },
    cancel: {
      border: '1px solid',
      borderRadius: '6px',
      borderColor: 'black',
      backgroundColor: baseTheme.colors.fill.white,
      textDecoration: 'none',
      '&, &:visited': {
        color: baseTheme.colors.text.default,
      },
      ':not(:disabled)': {
        ':hover': {
          backgroundColor: palette.neutral['50'],
          textDecoration: 'none',
        },
        '&.active, :active': {
          backgroundColor: palette.neutral['100'],
          textDecoration: 'none',
        },
      },
      ':disabled': {
        borderColor: baseTheme.colors.fill.primary.light,
        color: baseTheme.colors.text.disabled,
        textDecoration: 'none',
      },
    },
    aiPrimary: {
      border: 'none',
      borderRadius: '6px',
      backgroundColor: baseTheme.colors.fill.quaternary.dark,
      textDecoration: 'none',
      '&&, &&:visited': {
        color: baseTheme.colors.text.button.primary.inverse,
      },
      ':not(:disabled)': {
        ':hover': {
          backgroundColor: baseTheme.colors.fill.quaternary.base,
          textDecoration: 'none',
        },
        '&.active, :active': {
          backgroundColor: baseTheme.colors.fill.quaternary.extraDark,
          textDecoration: 'none',
        },
      },
      ':disabled': {
        backgroundColor: baseTheme.colors.fill.primary.light,
        color: `${baseTheme.colors.text.disabled} !important`,
        textDecoration: 'none',
      },
    },
    aiSecondary: {
      border: '1px solid',
      borderRadius: '6px',
      borderColor: baseTheme.colors.fill.quaternary.extraDark,
      backgroundColor: palette.purple[30],
      textDecoration: 'none',
      '&, &:visited': {
        color: baseTheme.colors.fill.quaternary.extraDark,
      },
      ':not(:disabled)': {
        ':hover': {
          backgroundColor: palette.purple[50],
          textDecoration: 'none',
        },
        '&.active, :active': {
          backgroundColor: palette.purple[100],
          textDecoration: 'none',
        },
      },
      ':disabled': {
        borderColor: baseTheme.colors.fill.primary.light,
        color: baseTheme.colors.text.disabled,
        textDecoration: 'none',
      },
    },
  },
  colorStyles: {
    link: {
      primary: {
        color: palette.neutral['900'],
        ':visited': {
          color: palette.neutral['900'],
        },
        ':hover': {
          color: palette.blue['700'],
        },
      },
      secondary: {
        color: palette.neutral['900'],
        ':visited': {
          color: palette.neutral['900'],
        },
        ':not(:hover)': {
          textDecoration: 'none',
        },
        ':hover': {
          color: baseTheme.colors.fill.primary.light,
        },
      },
    },
  },
  textStyles: {
    body: {
      big: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[4],
        lineHeight: baseTheme.lineHeights.body,
        letterSpacing: baseTheme.letterSpacings.none,
      },
      default: {
        fontWeight: baseTheme.fontWeights.regular,
        fontSize: baseTheme.fontSizes[3],
        lineHeight: baseTheme.lineHeights.body,
        letterSpacing: baseTheme.letterSpacings.none,
      },
      small: {
        fontWeight: baseTheme.fontWeights.regular,
        fontSize: baseTheme.fontSizes[2],
        lineHeight: baseTheme.lineHeights.body,
        letterSpacing: baseTheme.letterSpacings.none,
      },
    },
    button: {
      large: {
        fontWeight: baseTheme.fontWeights.semiBold,
        fontSize: baseTheme.fontSizes[4],
        letterSpacing: baseTheme.letterSpacings.none,
      },
      medium: {
        fontWeight: baseTheme.fontWeights.semiBold,
        fontSize: baseTheme.fontSizes[3],
        letterSpacing: baseTheme.letterSpacings.none,
      },
      small: {
        fontWeight: baseTheme.fontWeights.semiBold,
        fontSize: baseTheme.fontSizes[3],
        letterSpacing: baseTheme.letterSpacings.none,
      },
    },
    caption: {
      fontWeight: baseTheme.fontWeights.regular,
      fontSize: baseTheme.fontSizes[1],
      lineHeight: baseTheme.lineHeights.header,
      letterSpacing: baseTheme.letterSpacings.condensed.l,
    },
    form: {
      disabled: {
        fontFamily: 'Poppins',
        fontWeight: baseTheme.fontWeights.regular,
        fontSize: baseTheme.fontSizes[3],
        letterSpacing: '-0.03px',
      },
    },
    header: {
      1: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[9],
        lineHeight: baseTheme.lineHeights.header,
        letterSpacing: baseTheme.letterSpacings.condensed.xl,
      },
      2: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[7],
        lineHeight: baseTheme.lineHeights.header,
        letterSpacing: baseTheme.letterSpacings.condensed.l,
      },
      3: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[6],
        lineHeight: baseTheme.lineHeights.header,
        letterSpacing: baseTheme.letterSpacings.condensed.m,
      },
      4: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[4],
        lineHeight: baseTheme.lineHeights.header,
        letterSpacing: baseTheme.letterSpacings.condensed.s,
      },
      5: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[4],
        lineHeight: baseTheme.lineHeights.header,
        letterSpacing: baseTheme.letterSpacings.condensed.xs,
      },
    },
    link: {
      1: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[3],
        letterSpacing: baseTheme.letterSpacings.condensed.s,
      },
      2: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[1],
        letterSpacing: baseTheme.letterSpacings.none,
      },
    },
    display: {
      fontWeight: baseTheme.fontWeights.light,
      fontSize: baseTheme.fontSizes[10],
      letterSpacing: baseTheme.letterSpacings.condensed.l,
    },
    subtitle: {
      1: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[3],
        letterSpacing: baseTheme.letterSpacings.condensed.s,
      },
      2: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[2],
        letterSpacing: baseTheme.letterSpacings.none,
      },
      3: {
        fontWeight: baseTheme.fontWeights.medium,
        fontSize: baseTheme.fontSizes[1],
        letterSpacing: baseTheme.letterSpacings.none,
      },
      caption: {
        fontWeight: baseTheme.fontWeights.regular,
        fontSize: baseTheme.fontSizes[1],
        letterSpacing: baseTheme.letterSpacings.none,
      },
    },
  },
}

const theme = { ...baseTheme, ...variantTheme }
export default theme
