// source: task/task.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.taskmanager.GetTasksRequest', null, global);
goog.exportSymbol('proto.taskmanager.ListTasksRequest', null, global);
goog.exportSymbol('proto.taskmanager.Progress', null, global);
goog.exportSymbol('proto.taskmanager.TASK_STATUS', null, global);
goog.exportSymbol('proto.taskmanager.TASK_TYPE', null, global);
goog.exportSymbol('proto.taskmanager.Task', null, global);
goog.exportSymbol('proto.taskmanager.TaskID', null, global);
goog.exportSymbol('proto.taskmanager.TaskList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taskmanager.TaskID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taskmanager.TaskID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taskmanager.TaskID.displayName = 'proto.taskmanager.TaskID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taskmanager.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taskmanager.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taskmanager.Task.displayName = 'proto.taskmanager.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taskmanager.ListTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taskmanager.ListTasksRequest.repeatedFields_, null);
};
goog.inherits(proto.taskmanager.ListTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taskmanager.ListTasksRequest.displayName = 'proto.taskmanager.ListTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taskmanager.GetTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taskmanager.GetTasksRequest.repeatedFields_, null);
};
goog.inherits(proto.taskmanager.GetTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taskmanager.GetTasksRequest.displayName = 'proto.taskmanager.GetTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taskmanager.TaskList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taskmanager.TaskList.repeatedFields_, null);
};
goog.inherits(proto.taskmanager.TaskList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taskmanager.TaskList.displayName = 'proto.taskmanager.TaskList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taskmanager.Progress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taskmanager.Progress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taskmanager.Progress.displayName = 'proto.taskmanager.Progress';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taskmanager.TaskID.prototype.toObject = function(opt_includeInstance) {
  return proto.taskmanager.TaskID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taskmanager.TaskID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.TaskID.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taskmanager.TaskID}
 */
proto.taskmanager.TaskID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taskmanager.TaskID;
  return proto.taskmanager.TaskID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taskmanager.TaskID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taskmanager.TaskID}
 */
proto.taskmanager.TaskID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taskmanager.TaskID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taskmanager.TaskID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taskmanager.TaskID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.TaskID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.taskmanager.TaskID.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.TaskID} returns this
 */
proto.taskmanager.TaskID.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taskmanager.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.taskmanager.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taskmanager.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ownerEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serverId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    expiryAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    commandLine: jspb.Message.getFieldWithDefault(msg, 10, ""),
    exitCode: jspb.Message.getFieldWithDefault(msg, 11, 0),
    stderr: jspb.Message.getFieldWithDefault(msg, 12, ""),
    subjectId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    parentTaskId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    progress: jspb.Message.getFieldWithDefault(msg, 15, 0),
    progressMax: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taskmanager.Task}
 */
proto.taskmanager.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taskmanager.Task;
  return proto.taskmanager.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taskmanager.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taskmanager.Task}
 */
proto.taskmanager.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setServerId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiryAt(value);
      break;
    case 8:
      var value = /** @type {!proto.taskmanager.TASK_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {!proto.taskmanager.TASK_TYPE} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommandLine(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStderr(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentTaskId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgressMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taskmanager.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taskmanager.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taskmanager.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwnerEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExpiryAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCommandLine();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExitCode();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStderr();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getParentTaskId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getProgressMax();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.taskmanager.Task.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.taskmanager.Task} returns this
*/
proto.taskmanager.Task.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taskmanager.Task.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.taskmanager.Task.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.taskmanager.Task} returns this
*/
proto.taskmanager.Task.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taskmanager.Task.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string owner_email = 5;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getOwnerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string server_id = 6;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getServerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setServerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string expiry_at = 7;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getExpiryAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setExpiryAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TASK_STATUS status = 8;
 * @return {!proto.taskmanager.TASK_STATUS}
 */
proto.taskmanager.Task.prototype.getStatus = function() {
  return /** @type {!proto.taskmanager.TASK_STATUS} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.taskmanager.TASK_STATUS} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional TASK_TYPE type = 9;
 * @return {!proto.taskmanager.TASK_TYPE}
 */
proto.taskmanager.Task.prototype.getType = function() {
  return /** @type {!proto.taskmanager.TASK_TYPE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.taskmanager.TASK_TYPE} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string command_line = 10;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getCommandLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setCommandLine = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 exit_code = 11;
 * @return {number}
 */
proto.taskmanager.Task.prototype.getExitCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setExitCode = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string stderr = 12;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getStderr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setStderr = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string subject_id = 13;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string parent_task_id = 14;
 * @return {string}
 */
proto.taskmanager.Task.prototype.getParentTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setParentTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 progress = 15;
 * @return {number}
 */
proto.taskmanager.Task.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 progress_max = 16;
 * @return {number}
 */
proto.taskmanager.Task.prototype.getProgressMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.taskmanager.Task} returns this
 */
proto.taskmanager.Task.prototype.setProgressMax = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taskmanager.ListTasksRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taskmanager.ListTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taskmanager.ListTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taskmanager.ListTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.ListTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusListList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    startDateTime: (f = msg.getStartDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDateTime: (f = msg.getEndDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taskmanager.ListTasksRequest}
 */
proto.taskmanager.ListTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taskmanager.ListTasksRequest;
  return proto.taskmanager.ListTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taskmanager.ListTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taskmanager.ListTasksRequest}
 */
proto.taskmanager.ListTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.taskmanager.TASK_STATUS>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusList(values[i]);
      }
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDateTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taskmanager.ListTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taskmanager.ListTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taskmanager.ListTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.ListTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusListList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getStartDateTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TASK_STATUS status_list = 1;
 * @return {!Array<!proto.taskmanager.TASK_STATUS>}
 */
proto.taskmanager.ListTasksRequest.prototype.getStatusListList = function() {
  return /** @type {!Array<!proto.taskmanager.TASK_STATUS>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.taskmanager.TASK_STATUS>} value
 * @return {!proto.taskmanager.ListTasksRequest} returns this
 */
proto.taskmanager.ListTasksRequest.prototype.setStatusListList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.taskmanager.TASK_STATUS} value
 * @param {number=} opt_index
 * @return {!proto.taskmanager.ListTasksRequest} returns this
 */
proto.taskmanager.ListTasksRequest.prototype.addStatusList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taskmanager.ListTasksRequest} returns this
 */
proto.taskmanager.ListTasksRequest.prototype.clearStatusListList = function() {
  return this.setStatusListList([]);
};


/**
 * optional google.protobuf.Timestamp start_date_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.taskmanager.ListTasksRequest.prototype.getStartDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.taskmanager.ListTasksRequest} returns this
*/
proto.taskmanager.ListTasksRequest.prototype.setStartDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taskmanager.ListTasksRequest} returns this
 */
proto.taskmanager.ListTasksRequest.prototype.clearStartDateTime = function() {
  return this.setStartDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taskmanager.ListTasksRequest.prototype.hasStartDateTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_date_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.taskmanager.ListTasksRequest.prototype.getEndDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.taskmanager.ListTasksRequest} returns this
*/
proto.taskmanager.ListTasksRequest.prototype.setEndDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taskmanager.ListTasksRequest} returns this
 */
proto.taskmanager.ListTasksRequest.prototype.clearEndDateTime = function() {
  return this.setEndDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taskmanager.ListTasksRequest.prototype.hasEndDateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taskmanager.GetTasksRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taskmanager.GetTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taskmanager.GetTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taskmanager.GetTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.GetTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskIdListList: jspb.Message.toObjectList(msg.getTaskIdListList(),
    proto.taskmanager.TaskID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taskmanager.GetTasksRequest}
 */
proto.taskmanager.GetTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taskmanager.GetTasksRequest;
  return proto.taskmanager.GetTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taskmanager.GetTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taskmanager.GetTasksRequest}
 */
proto.taskmanager.GetTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taskmanager.TaskID;
      reader.readMessage(value,proto.taskmanager.TaskID.deserializeBinaryFromReader);
      msg.addTaskIdList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taskmanager.GetTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taskmanager.GetTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taskmanager.GetTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.GetTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskIdListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taskmanager.TaskID.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaskID task_id_list = 1;
 * @return {!Array<!proto.taskmanager.TaskID>}
 */
proto.taskmanager.GetTasksRequest.prototype.getTaskIdListList = function() {
  return /** @type{!Array<!proto.taskmanager.TaskID>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taskmanager.TaskID, 1));
};


/**
 * @param {!Array<!proto.taskmanager.TaskID>} value
 * @return {!proto.taskmanager.GetTasksRequest} returns this
*/
proto.taskmanager.GetTasksRequest.prototype.setTaskIdListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taskmanager.TaskID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taskmanager.TaskID}
 */
proto.taskmanager.GetTasksRequest.prototype.addTaskIdList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taskmanager.TaskID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taskmanager.GetTasksRequest} returns this
 */
proto.taskmanager.GetTasksRequest.prototype.clearTaskIdListList = function() {
  return this.setTaskIdListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taskmanager.TaskList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taskmanager.TaskList.prototype.toObject = function(opt_includeInstance) {
  return proto.taskmanager.TaskList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taskmanager.TaskList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.TaskList.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskListList: jspb.Message.toObjectList(msg.getTaskListList(),
    proto.taskmanager.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taskmanager.TaskList}
 */
proto.taskmanager.TaskList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taskmanager.TaskList;
  return proto.taskmanager.TaskList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taskmanager.TaskList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taskmanager.TaskList}
 */
proto.taskmanager.TaskList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taskmanager.Task;
      reader.readMessage(value,proto.taskmanager.Task.deserializeBinaryFromReader);
      msg.addTaskList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taskmanager.TaskList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taskmanager.TaskList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taskmanager.TaskList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.TaskList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taskmanager.Task.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Task task_list = 1;
 * @return {!Array<!proto.taskmanager.Task>}
 */
proto.taskmanager.TaskList.prototype.getTaskListList = function() {
  return /** @type{!Array<!proto.taskmanager.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taskmanager.Task, 1));
};


/**
 * @param {!Array<!proto.taskmanager.Task>} value
 * @return {!proto.taskmanager.TaskList} returns this
*/
proto.taskmanager.TaskList.prototype.setTaskListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taskmanager.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taskmanager.Task}
 */
proto.taskmanager.TaskList.prototype.addTaskList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taskmanager.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taskmanager.TaskList} returns this
 */
proto.taskmanager.TaskList.prototype.clearTaskListList = function() {
  return this.setTaskListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taskmanager.Progress.prototype.toObject = function(opt_includeInstance) {
  return proto.taskmanager.Progress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taskmanager.Progress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.Progress.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    progress: jspb.Message.getFieldWithDefault(msg, 5, 0),
    progressMax: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taskmanager.Progress}
 */
proto.taskmanager.Progress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taskmanager.Progress;
  return proto.taskmanager.Progress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taskmanager.Progress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taskmanager.Progress}
 */
proto.taskmanager.Progress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {!proto.taskmanager.TASK_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgressMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taskmanager.Progress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taskmanager.Progress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taskmanager.Progress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taskmanager.Progress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getProgressMax();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.taskmanager.Progress.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Progress} returns this
 */
proto.taskmanager.Progress.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.taskmanager.Progress.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taskmanager.Progress} returns this
 */
proto.taskmanager.Progress.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.taskmanager.Progress.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.taskmanager.Progress} returns this
*/
proto.taskmanager.Progress.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taskmanager.Progress} returns this
 */
proto.taskmanager.Progress.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taskmanager.Progress.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TASK_STATUS status = 4;
 * @return {!proto.taskmanager.TASK_STATUS}
 */
proto.taskmanager.Progress.prototype.getStatus = function() {
  return /** @type {!proto.taskmanager.TASK_STATUS} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.taskmanager.TASK_STATUS} value
 * @return {!proto.taskmanager.Progress} returns this
 */
proto.taskmanager.Progress.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 progress = 5;
 * @return {number}
 */
proto.taskmanager.Progress.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.taskmanager.Progress} returns this
 */
proto.taskmanager.Progress.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 progress_max = 6;
 * @return {number}
 */
proto.taskmanager.Progress.prototype.getProgressMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.taskmanager.Progress} returns this
 */
proto.taskmanager.Progress.prototype.setProgressMax = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.taskmanager.TASK_STATUS = {
  RUNNING: 0,
  COMPLETED: 1,
  STOPPED: 2,
  MARKED: 3,
  QUEUED: 4
};

/**
 * @enum {number}
 */
proto.taskmanager.TASK_TYPE = {
  GPT_POOL: 0,
  RECEPTOR_SCAN_AND_TEST: 1,
  COMMAND_EXEC: 2,
  RECEPTOR_WEEKLY_SCAN: 3,
  AUDITBOT_CHECK: 4
};

goog.object.extend(exports, proto.taskmanager);
