import React from 'react'
import { STATUS_TYPES } from './StatusIndicator.constants'
import { StatusIndicatorBar } from './StatusIndicator.styles'

type StatusIndicatorProps = {
  statusType: STATUS_TYPES
  status: number
}

export const StatusIndicator = ({
  statusType,
  status,
}: StatusIndicatorProps): JSX.Element => (
  <StatusIndicatorBar statusType={statusType} status={status} />
)
