import React, {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useState,
  ChangeEventHandler,
} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import log from 'loglevel'
import debounce from 'lodash/debounce'
import { Service } from 'src/xgenerated'
import { ServicesAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import {
  ListServicesRequest,
  SERVICE_ROLE,
} from '@trustero/trustero-api-web/lib/service/service_pb'
import {
  SERVICE_ROLE_LABEL_TO_ROLE,
  SERVICE_ROLE_TO_LABEL,
  ServiceRoleLabels,
  UPDATE_SERVICE_DELAY,
} from '../Services.constants'
import {
  useCreateOrUpdateService,
  useService,
  useServices,
} from '../Services.hooks'
import { getInactiveServices, getService } from '../services.helpers'

export type ServicesMapData = {
  service: Service
  isSelected: boolean
}

type ServiceRoleData = {
  isDefault: boolean
  isSelected: boolean
}

export type ServiceRoleMap = Record<ServiceRoleLabels, ServiceRoleData>

export type ServicesContextType = {
  service: Service | null
  getCurrentService: (modelId: string) => Service | null
  servicesMap: Record<string, ServicesMapData>
  toggleSelectService: (modelId: string) => void
  clearState: () => void
  getNoneSelected: () => boolean
  getOnChange: (modelId: string) => ChangeEventHandler<HTMLInputElement>
  getSelectedRoles: (
    modelId: string,
    isDefault: boolean,
  ) => Set<ServiceRoleLabels>
  getDropdownRoles: (modelId: string) => ServiceRoleLabels[]
}

export enum ServicesActionType {
  INITIALIZE = 'INITIALIZE',
  UPDATE = 'UPDATE',
}

const initialServicesMap: Record<string, ServicesMapData> = {}

const initialServiceRoleState: ServiceRoleMap = Object.values(
  ServiceRoleLabels,
).reduce((acc: ServiceRoleMap, role: ServiceRoleLabels) => {
  acc[role] = {
    isDefault: false,
    isSelected: false,
  }
  return acc
}, {} as ServiceRoleMap)

export const ServicesContext = createContext<ServicesContextType>({
  service: null,
  getCurrentService: () => null,
  servicesMap: {},
  toggleSelectService: () => null,
  clearState: () => null,
  getNoneSelected: () => true,
  getOnChange: () => () => null,
  getSelectedRoles: () => new Set(),
  getDropdownRoles: () => [],
})

const servicesModalReducer = (
  state: Record<string, ServicesMapData>,
  action: {
    type: string
    data: Record<string, ServicesMapData>
  },
): Record<string, ServicesMapData> => {
  switch (action.type) {
    case ServicesActionType.INITIALIZE:
      return {
        ...action.data,
      }
    case ServicesActionType.UPDATE:
      return {
        ...state,
        ...action.data,
      }
    default:
      return {}
  }
}

const servicesRolesReducer = (
  state: ServiceRoleMap,
  action: {
    type: string
    data: ServiceRoleMap
  },
): ServiceRoleMap => {
  switch (action.type) {
    case ServicesActionType.UPDATE:
      return {
        ...state,
        ...action.data,
      }
    default:
      return initialServiceRoleState
  }
}

const getServiceRoles = (
  service: Service,
  isGetDefault: boolean,
): Set<ServiceRoleLabels> => {
  const serviceRoles = isGetDefault
    ? service.default_roles || []
    : service.service_roles || []
  return new Set(
    serviceRoles.map(
      (role) => SERVICE_ROLE_TO_LABEL[role] as ServiceRoleLabels,
    ),
  )
}

const buildServiceRoleMap = (service: Service): ServiceRoleMap => {
  const serviceRoles = getServiceRoles(service, false)
  const defaultRoles = getServiceRoles(service, true)
  return Object.values(ServiceRoleLabels).reduce(
    (acc: ServiceRoleMap, role: ServiceRoleLabels) => {
      acc[role] = {
        isDefault: defaultRoles.has(role),
        isSelected: serviceRoles.has(role),
      }
      return acc
    },
    initialServiceRoleState,
  )
}

/**
 * ServicesProvider is a context provider that provides services data to its children.
 * It also provides helper functions to update the services data.
 * It is used in the AddServices modal flow and the Services show page.
 */
export const ServicesProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const navigate = useNavigate()
  const { pageContext } = useParams()
  const addOrUpdateService = useCreateOrUpdateService()
  const req = new ListServicesRequest()
  const { data, isLoading, error } = useServices(req)
  const { serviceId } = useParams() as { serviceId: string }
  const location = useLocation()
  const {
    data: serviceData,
    isLoading: isServiceLoading,
    error: serviceError,
  } = useService({ serviceId })
  const [servicesMap, dispatch] = useReducer(
    servicesModalReducer,
    initialServicesMap,
  )
  const [initialMap, setInitialMap] = React.useState<
    Record<string, ServicesMapData>
  >({})
  const [service, setService] = useState<Service | null>(null)
  const [serviceRoleState, setServiceRoleState] = useReducer(
    servicesRolesReducer,
    initialServiceRoleState,
  )
  const isShowPage = location.pathname.includes(ServicesAbsoluteRoutes.SHOW)
  const isExcludeEnabled = IsGatekeeperEnabled(GatekeeperFlag.EXCLUDE_SERVICES)

  useEffect(() => {
    if (error) {
      log.error('Error fetching services for modal in services context', error)
      return
    } else if (!data || isLoading) return
    // initialize modal services
    const services = data.getServicesList()
    const inactiveServices = getInactiveServices(services)
    const map = inactiveServices.reduce((acc, service) => {
      acc[service.modelid] = {
        service,
        isSelected: false,
      }
      return acc
    }, {} as Record<string, ServicesMapData>)
    dispatch({
      type: ServicesActionType.INITIALIZE,
      data: map,
    })
    setInitialMap(map)
  }, [data, isLoading, error])

  useEffect(() => {
    if (serviceError) {
      log.error(
        'Error fetching service for show page in services context',
        serviceError,
      )
      return
    } else if (!serviceData || isServiceLoading) return
    if (!serviceData.getId() && isShowPage && !isServiceLoading) {
      navigate(`/${pageContext}/${ServicesAbsoluteRoutes.INDEX}`)
      return
    }
    // initialize show page service roles
    const service = getService(serviceData)
    // redirect to services index page if service is excluded or dismissed and user is on the show page
    if (
      ((isExcludeEnabled && service.is_excluded) || service.dismissed) &&
      isShowPage
    ) {
      navigate(`/${pageContext}/${ServicesAbsoluteRoutes.INDEX}`)
    }
    setService(service)
    const serviceRoleState = buildServiceRoleMap(service)
    setServiceRoleState({
      type: ServicesActionType.UPDATE,
      data: serviceRoleState,
    })
  }, [
    isExcludeEnabled,
    serviceData,
    isServiceLoading,
    serviceError,
    isShowPage,
    navigate,
    pageContext,
  ])

  /**
   * toggleSelectService toggles the isSelected property of a service in the servicesMap for the AddServices modal
   * @param modelId The id of the service to toggle
   */
  const toggleSelectService = (modelId: string): void => {
    const serviceData = servicesMap[modelId]
    const updatedServiceData = {
      ...serviceData,
      isSelected: !serviceData.isSelected,
    }
    dispatch({
      type: ServicesActionType.UPDATE,
      data: { [modelId]: updatedServiceData },
    })
  }

  const updateServiceRolesInModal = (
    modelId: string,
    serviceRole: ServiceRoleLabels,
  ): void => {
    const serviceData = servicesMap[modelId]
    const currentRoles = new Set(serviceData.service.service_roles)
    const newRole = SERVICE_ROLE_LABEL_TO_ROLE[serviceRole]
    const isChecked = currentRoles.has(newRole)
    isChecked ? currentRoles.delete(newRole) : currentRoles.add(newRole)
    const updatedRoles = Array.from(currentRoles)
    const updatedServiceData = {
      ...serviceData,
      service: {
        ...serviceData.service,
        service_roles: updatedRoles,
      },
    }
    dispatch({
      type: ServicesActionType.UPDATE,
      data: { [modelId]: updatedServiceData },
    })
  }

  /**
   * clearState sets the servicesMap to its initial state after the services data has been fetched
   */
  const clearState = (): void => {
    dispatch({
      type: ServicesActionType.INITIALIZE,
      data: initialMap,
    })
  }

  /**
   * getNoneSelected returns true if none of the services in the servicesMap are selected in the AddServices modal
   */
  const getNoneSelected = (): boolean => {
    return Object.values(servicesMap)
      .map((serviceData) => serviceData.isSelected)
      .every((selected) => !selected)
  }

  /**
   * getService returns the appropriate service for the component.
   *
   * @param modelId The model id of the service
   * @returns service
   */
  const getCurrentService = (modelId: string): Service | null => {
    return isShowPage ? service : servicesMap[modelId].service
  }

  /**
   * getSelectedRoles returns a set of selected service roles that are either default or non-default for a given service.
   *
   * @param modelId The id of the service to get the roles for
   * @param isDefault Whether to get the default roles or non-default roles
   * @returns set of service roles for a service
   */
  const getSelectedRoles = (
    modelId: string,
    isDefault: boolean,
  ): Set<ServiceRoleLabels> => {
    const currentState = isShowPage
      ? serviceRoleState
      : buildServiceRoleMap(servicesMap[modelId].service)
    const currentRoles = Object.values(ServiceRoleLabels).filter(
      (role) =>
        currentState[role].isDefault === isDefault &&
        currentState[role].isSelected,
    )
    return new Set(currentRoles)
  }

  /**
   * getAllNonDefaultRoles returns a list of all of the service roles, excluding the default roles for a given service.
   * This is used to populate the dropdown of service roles in the AddServiceRoles modal and on the show page.
   *
   * @param modelId The id of the service to get the roles for
   * @returns list of service roles for a service
   */
  const getDropdownRoles = (modelId: string): ServiceRoleLabels[] => {
    const currentState = isShowPage
      ? serviceRoleState
      : buildServiceRoleMap(servicesMap[modelId].service)
    return Object.values(ServiceRoleLabels)
      .filter((role) => {
        return (
          currentState[role].isDefault === false &&
          currentState[role].isSelected === false
        )
      })
      .sort((a, b) => a.localeCompare(b))
  }

  const updateService = useMemo(() => {
    return debounce(
      async ({
        modelId,
        serviceRoles,
      }: {
        modelId: string
        serviceRoles: SERVICE_ROLE[]
      }) => {
        try {
          await addOrUpdateService({
            modelId,
            serviceRoles,
          })
        } catch (e) {
          log.error(
            `Error updating service's roles for service with model id ${modelId}`,
            e,
          )
        }
      },
      UPDATE_SERVICE_DELAY,
    )
  }, [addOrUpdateService])

  const updateServiceRolesOnShowPage = useCallback(
    (modelId: string, serviceRole: ServiceRoleLabels) => {
      if (!service) return
      try {
        const currentRoleState = {
          ...serviceRoleState,
          [serviceRole]: {
            ...serviceRoleState[serviceRole],
            isSelected: !serviceRoleState[serviceRole].isSelected,
          },
        }
        setServiceRoleState({
          type: ServicesActionType.UPDATE,
          data: currentRoleState,
        })
        const currentRoles = Object.values(ServiceRoleLabels).filter(
          (role) => currentRoleState[role].isSelected,
        )
        updateService({
          modelId: modelId,
          serviceRoles: currentRoles.map(
            (role) => SERVICE_ROLE_LABEL_TO_ROLE[role],
          ),
        })
      } catch (e) {
        log.error(
          `Error updating service's roles for service with model id ${modelId}`,
          e,
        )
      }
    },
    [updateService, serviceRoleState, service],
  )

  /**
   * getOnChange returns a function that updates the service roles for a service (in the servicesMap for the AddServices modal flow or in the ServiceRoleState on the Services show page)
   * @param modelId
   * @returns ChangeEventHandler<HTMLInputElement> function that updates the service roles for a service
   */
  const getOnChange = (
    modelId: string,
  ): ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      isShowPage
        ? updateServiceRolesOnShowPage(
            modelId,
            e.target.value as ServiceRoleLabels,
          )
        : updateServiceRolesInModal(
            modelId,
            e.target.value as ServiceRoleLabels,
          )
    }
  }

  return (
    <ServicesContext.Provider
      value={{
        service,
        getCurrentService,
        servicesMap,
        toggleSelectService,
        getOnChange,
        clearState,
        getNoneSelected,
        getSelectedRoles,
        getDropdownRoles,
      }}
    >
      {children}
    </ServicesContext.Provider>
  )
}

export const useServicesContext = (): ServicesContextType => {
  return useContext(ServicesContext)
}
