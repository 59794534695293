import React from 'react'
import { PropTypes } from 'prop-types'

function SignupSuccess(props) {
  const url = new URL(`/register/${props.callid}`, document.location)

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <div className="alert alert-success">Success!</div>
        <div>
          An email has been sent to your account which includes a link to
          complete the signup process.
        </div>
        <div>Or use this one: {url.toString()}</div>
      </div>
    </div>
  )
}

SignupSuccess.propTypes = {
  callid: PropTypes.string.isRequired,
}

export default SignupSuccess
