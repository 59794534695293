export enum APP_ROUTES {
  CONTROLS = '/controls',
}

export enum COOKIES {
  FROM_SIGNUP = 'fromSignup',
}

export enum MODEL_TYPES {
  CONTROL = 'control',
  POLICY = 'policy',
}

export enum FRAMEWORK_MODEL_IDS {
  SOC2 = 'trcf-soc2',
  SOC1 = 'trcf-soc1',
  ISO27001 = 'trcf-iso27001',
  ISO27701 = 'trcf-iso27701',
  TEST = 'trcf-test',
  HIPAA = 'trcf-hipaa',
  PCIDSS = 'trcf-pci-dss',
  PCISAQD = 'trcf-pci-saq-d',
  PCISAQA = 'trcf-pci-saq-a',
  GDPR = 'trcf-gdpr',
  CCPA = 'trcf-ccpa',
  NISTCSF = 'trcf-nist-csf',
  NIST800171 = 'trcf-nist-800-171',
  DODCMMC = 'trcf-dod-cmmc',
  HITRUST = 'trcf-hitrust-e1',
  NYDFS = 'trcf-nydfs',
  ISO27701_DC = 'trcf-iso27701-dc',
  ISO27701_DP = 'trcf-iso27701-dp',
  GDPR_DC = 'trcf-gdpr-dc',
  GDPR_DP = 'trcf-gdpr-dp',
  PIPEDA_DC = 'trcf-pipeda-dc',
  PIPEDA_DP = 'trcf-pipeda-dp',
  CUSTOM = 'trcf-custom',
}

export enum FRAMEWORK_OPTION_NAMES {
  SOC2Type2 = 'SOC 2 Type 2',
  SOC2Type1 = 'SOC 2 Type 1',
  SOC1 = 'SOC 1',
  ISO27001 = 'ISO 27001',
  ISO27701 = 'ISO 27001',
  TEST = 'Test Framework',
  HIPAA = 'HIPAA',
  PCIDSS = 'PCI DSS',
  PCISAQD = 'PCI DSS SAQ D',
  PCISAQA = 'PCI DSS SAQ A',
  GDPR = 'GDPR',
  CCPA = 'CCPA',
  NISTCSF = 'NIST CSF',
  NIST800171 = 'NIST 800-171',
  DODCMMC = 'DOD CMMC',
  HITRUST = 'HITRUST e1',
  ISO27701_DC = 'ISO 27701 - Data Controller',
  ISO27701_DP = 'ISO 27701 - Data Processor',
  GDPR_DC = 'GDPR - Data Controller',
  GDPR_DP = 'GDPR - Data Processor',
  PIPEDA_DC = 'PIPEDA - Data Controller',
  PIPEDA_DP = 'PIPEDA - Data Processor',
  NYDFS = 'NYDFS',
}

export enum ERROR_CODES {
  DUPLICATE_ENTRY = '1062',
  TOO_LONG = '1406',
}

export enum APPLE_FILE_EXTENSIONS {
  KEY = 'key',
  NUMBERS = 'numbers',
  PAGES = 'pages',
}

export const ERROR_MESSAGES = Object.freeze({
  [ERROR_CODES.DUPLICATE_ENTRY]: 'A duplicate entry already exists.',
  [ERROR_CODES.TOO_LONG]: 'The value is too long.',
  UNKNOWN: 'An unknown error occurred.',
  EMPTY: 'Please enter a value.',
})

export enum SOC2_CRITERIA_CODES {
  A1_1 = 'A1.1',
  A1_2 = 'A1.2',
  A1_3 = 'A1.3',
  C1_1 = 'C1.1',
  C1_2 = 'C1.2',
  CC1_1 = 'CC1.1',
  CC1_2 = 'CC1.2',
  CC1_3 = 'CC1.3',
  CC1_4 = 'CC1.4',
  CC1_5 = 'CC1.5',
  CC2_1 = 'CC2.1',
  CC2_2 = 'CC2.2',
  CC2_3 = 'CC2.3',
  CC3_1 = 'CC3.1',
  CC3_2 = 'CC3.2',
  CC3_3 = 'CC3.3',
  CC3_4 = 'CC3.4',
  CC4_1 = 'CC4.1',
  CC4_2 = 'CC4.2',
  CC5_1 = 'CC5.1',
  CC5_2 = 'CC5.2',
  CC5_3 = 'CC5.3',
  CC6_1 = 'CC6.1',
  CC6_2 = 'CC6.2',
  CC6_3 = 'CC6.3',
  CC6_4 = 'CC6.4',
  CC6_5 = 'CC6.5',
  CC6_6 = 'CC6.6',
  CC6_7 = 'CC6.7',
  CC6_8 = 'CC6.8',
  CC7_1 = 'CC7.1',
  CC7_2 = 'CC7.2',
  CC7_3 = 'CC7.3',
  CC7_4 = 'CC7.4',
  CC7_5 = 'CC7.5',
  CC8_1 = 'CC8.1',
  CC9_1 = 'CC9.1',
  CC9_2 = 'CC9.2',
  P1_1 = 'P1.1',
  P2_1 = 'P2.1',
  P3_1 = 'P3.1',
  P3_2 = 'P3.2',
  P4_1 = 'P4.1',
  P4_2 = 'P4.2',
  P4_3 = 'P4.3',
  P5_1 = 'P5.1',
  P5_2 = 'P5.2',
  P6_1 = 'P6.1',
  P6_2 = 'P6.2',
  P6_3 = 'P6.3',
  P6_4 = 'P6.4',
  P6_5 = 'P6.5',
  P6_6 = 'P6.6',
  P6_7 = 'P6.7',
  P7_1 = 'P7.1',
  P8_1 = 'P8.1',
  PI1_1 = 'PI1.1',
  PI1_2 = 'PI1.2',
  PI1_3 = 'PI1.3',
  PI1_4 = 'PI1.4',
  PI1_5 = 'PI1.5',
}

export const SOC2_CRITERIA_CODES_TO_NAMES = Object.freeze({
  [SOC2_CRITERIA_CODES.A1_1]: 'A1.1 Capacity Management',
  [SOC2_CRITERIA_CODES.A1_2]: 'A1.2 Data Backup/Recovery',
  [SOC2_CRITERIA_CODES.A1_3]: 'A1.3 Data Recovery Tests',
  [SOC2_CRITERIA_CODES.C1_1]: 'C1.1 Identify Confidential Info',
  [SOC2_CRITERIA_CODES.C1_2]: 'C1.2 Dispose of Confidential Info',
  [SOC2_CRITERIA_CODES.CC1_1]: 'CC1.1 COSO 1: Integrity & Ethical Values',
  [SOC2_CRITERIA_CODES.CC1_2]: 'CC1.2 COSO 2: Board Independence',
  [SOC2_CRITERIA_CODES.CC1_3]: 'CC1.3 COSO 3: Authority & Responsibility',
  [SOC2_CRITERIA_CODES.CC1_4]: 'CC1.4 COSO 4: Competence',
  [SOC2_CRITERIA_CODES.CC1_5]: 'CC1.5 COSO 5: Accountability',
  [SOC2_CRITERIA_CODES.CC2_1]: 'CC2.1 COSO 13: Quality Information',
  [SOC2_CRITERIA_CODES.CC2_2]: 'CC2.2 COSO 14: Internal Communications',
  [SOC2_CRITERIA_CODES.CC2_3]: 'CC2.3 COSO 15: External Communications',
  [SOC2_CRITERIA_CODES.CC3_1]: 'CC3.1 COSO 6: Clear Objectives',
  [SOC2_CRITERIA_CODES.CC3_2]: 'CC3.2 COSO 7: Risk Analysis',
  [SOC2_CRITERIA_CODES.CC3_3]: 'CC3.3 COSO 8: Fraud Potential',
  [SOC2_CRITERIA_CODES.CC3_4]: 'CC3.4 COSO 9: Change Assessment',
  [SOC2_CRITERIA_CODES.CC4_1]: 'CC4.1 COSO 16: Control Evaluation',
  [SOC2_CRITERIA_CODES.CC4_2]: 'CC4.2 COSO 17: Deficiency Correction',
  [SOC2_CRITERIA_CODES.CC5_1]: 'CC5.1 COSO 10: Control Activities',
  [SOC2_CRITERIA_CODES.CC5_2]: 'CC5.2 COSO 11: Technology Controls',
  [SOC2_CRITERIA_CODES.CC5_3]: 'CC5.3 COSO 12: Policies & Expectations',
  [SOC2_CRITERIA_CODES.CC6_1]: 'CC6.1 Logical Access Security',
  [SOC2_CRITERIA_CODES.CC6_2]: 'CC6.2 User System Credentials',
  [SOC2_CRITERIA_CODES.CC6_3]: 'CC6.3 Role Based Access',
  [SOC2_CRITERIA_CODES.CC6_4]: 'CC6.4 Physical Access Restrictions',
  [SOC2_CRITERIA_CODES.CC6_5]: 'CC6.5 Secure Device Disposal',
  [SOC2_CRITERIA_CODES.CC6_6]: 'CC6.6 Mitigate Outside Threats',
  [SOC2_CRITERIA_CODES.CC6_7]: 'CC6.7 Data Transmission',
  [SOC2_CRITERIA_CODES.CC6_8]: 'CC6.8 Prevent Malicious Software',
  [SOC2_CRITERIA_CODES.CC7_1]: 'CC7.1 Detect Configuration Changes',
  [SOC2_CRITERIA_CODES.CC7_2]: 'CC7.2 Monitor and Analyze Anomalies',
  [SOC2_CRITERIA_CODES.CC7_3]: 'CC7.3 Evaluate Security Events',
  [SOC2_CRITERIA_CODES.CC7_4]: 'CC7.4 Defined Incident Response',
  [SOC2_CRITERIA_CODES.CC7_5]: 'CC7.5 Recovery from Incidents',
  [SOC2_CRITERIA_CODES.CC8_1]: 'CC8.1 Infrastructure Changes',
  [SOC2_CRITERIA_CODES.CC9_1]: 'CC9.1 Business Disruption Risk',
  [SOC2_CRITERIA_CODES.CC9_2]: 'CC9.2 Vendor and Partner Risk',
  [SOC2_CRITERIA_CODES.P1_1]: 'P1.1 Notice to Data Subjects',
  [SOC2_CRITERIA_CODES.P2_1]: 'P2.1 Data Subject Choices',
  [SOC2_CRITERIA_CODES.P3_1]: 'P3.1 Collection and Objectives',
  [SOC2_CRITERIA_CODES.P3_2]: 'P3.2 Need for Explicit Consent',
  [SOC2_CRITERIA_CODES.P4_1]: 'P4.1 Limited Use of PII',
  [SOC2_CRITERIA_CODES.P4_2]: 'P4.2 PPI Retention',
  [SOC2_CRITERIA_CODES.P4_3]: 'P4.3 PII Secure Disposal',
  [SOC2_CRITERIA_CODES.P5_1]: 'P5.1 Data Subject PII Access',
  [SOC2_CRITERIA_CODES.P5_2]: 'P5.2 PII Correction',
  [SOC2_CRITERIA_CODES.P6_1]: 'P6.1 Consent of Data Subjects',
  [SOC2_CRITERIA_CODES.P6_2]: 'P6.2 Authorized Disclosures',
  [SOC2_CRITERIA_CODES.P6_3]: 'P6.3 Unauthorized Disclosures',
  [SOC2_CRITERIA_CODES.P6_4]: 'P6.4 Compliance of Vendors',
  [SOC2_CRITERIA_CODES.P6_5]: 'P6.5 Notification by Vendors',
  [SOC2_CRITERIA_CODES.P6_6]: 'P6.6 Breach & Incident Notification',
  [SOC2_CRITERIA_CODES.P6_7]: 'P6.7 Data Subject Requests',
  [SOC2_CRITERIA_CODES.P7_1]: 'P7.1 Accuracy',
  [SOC2_CRITERIA_CODES.P8_1]: 'P8.1 Issue Resolution and Corrections',
  [SOC2_CRITERIA_CODES.PI1_1]: 'PI1.1 Processing Objectives',
  [SOC2_CRITERIA_CODES.PI1_2]: 'PI1.2 Input Validation',
  [SOC2_CRITERIA_CODES.PI1_3]: 'PI1.3 Processing Policies',
  [SOC2_CRITERIA_CODES.PI1_4]: 'PI1.4 Output Validation',
  [SOC2_CRITERIA_CODES.PI1_5]: 'PI1.5 Storage',
})

// MIME types constants
export enum MIME_TYPE {
  NULL = '',
  TEXT_MARKDOWN = 'text/markdown',
  TEXT_URI_LIST = 'text/uri-list',
  TEXT_CSV = 'text/csv',
  APPLE_KEYNOTE = 'application/vnd.apple.keynote',
  APPLE_KEYNOTE_2 = 'application/x-iwork-keynote-sffkey',
  APPLE_NUMBERS = 'application/vnd.apple.numbers',
  APPLE_NUMBERS_2 = 'application/x-iwork-numbers-sffnumbers',
  APPLE_PAGES = 'application/vnd.apple.pages',
  APPLE_PAGES_2 = 'application/x-iwork-pages-sffpages',
  IMAGE_PNG = 'image/png',
  IMAGE_JPEG = 'image/jpeg',
  APPLICATION_WORD = 'application/msword',
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  APPLICATION_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_RTF = 'application/rtf', // Missing MIME type for RTF
  APPLICATION_VND_MS_EXCEL = 'application/vnd.ms-excel', // Existing enum for MS Excel
  APPLICATION_VND_OPENXML_SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // OpenXML spreadsheet MIME type
  DOC_X = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MULTIPART_MIXED = 'multipart/tr-mixed',
  POWERPOINT = 'application/vnd.ms-powerpoint',
}

export enum EXTENSION {
  PDF = '.pdf',
  CSV = '.csv',
}

type FrameworkNamesType = {
  [key in FRAMEWORK_MODEL_IDS]: string
}

export const FRAMEWORK_MODEL_ID_TO_NAME: FrameworkNamesType = Object.freeze({
  [FRAMEWORK_MODEL_IDS.SOC2]: 'SOC 2',
  [FRAMEWORK_MODEL_IDS.SOC1]: 'SOC 1',
  [FRAMEWORK_MODEL_IDS.ISO27001]: 'ISO 27001',
  [FRAMEWORK_MODEL_IDS.ISO27701]: 'ISO 27701',
  [FRAMEWORK_MODEL_IDS.TEST]: 'Test',
  [FRAMEWORK_MODEL_IDS.HIPAA]: 'HIPAA',
  [FRAMEWORK_MODEL_IDS.PCIDSS]: 'PCI DSS',
  [FRAMEWORK_MODEL_IDS.PCISAQD]: 'PCI/DSS SAQ D',
  [FRAMEWORK_MODEL_IDS.PCISAQA]: 'PCI/DSS SAQ A',
  [FRAMEWORK_MODEL_IDS.HITRUST]: 'HITRUST e1',
  [FRAMEWORK_MODEL_IDS.GDPR]: 'GDPR',
  [FRAMEWORK_MODEL_IDS.CCPA]: 'CCPA',
  [FRAMEWORK_MODEL_IDS.NISTCSF]: 'NIST CSF',
  [FRAMEWORK_MODEL_IDS.NIST800171]: 'NIST 800-171',
  [FRAMEWORK_MODEL_IDS.DODCMMC]: 'DOD CMMC',
  [FRAMEWORK_MODEL_IDS.NYDFS]: 'NYDFS',
  [FRAMEWORK_MODEL_IDS.ISO27701_DC]: 'ISO 27701 - Data Controller',
  [FRAMEWORK_MODEL_IDS.ISO27701_DP]: 'ISO 27701 - Data Processor',
  [FRAMEWORK_MODEL_IDS.GDPR_DC]: 'GDPR - Data Controller',
  [FRAMEWORK_MODEL_IDS.GDPR_DP]: 'GDPR - Data Processor',
  [FRAMEWORK_MODEL_IDS.PIPEDA_DC]: 'PIPEDA - Data Controller',
  [FRAMEWORK_MODEL_IDS.PIPEDA_DP]: 'PIPEDA - Data Processor',
  [FRAMEWORK_MODEL_IDS.CUSTOM]: 'Custom',
})

export enum Assignee {
  UNASSIGNED = 'Unassigned',
  UNASSIGNED_BE = 'unassigned',
}

export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum UrlFragments {
  CONTROLS = 'controls',
  POLICIES = 'policies',
  ADD_VENDOR = 'add-vendor',
  ADD_AUDIT = 'add-audit',
  REQUESTS = 'requests',
}
