import log from 'loglevel'
import {
  GetInitialScopingResponse,
  GetOrCreateComplianceRoadmapResponse,
  GetRoadmapWidgetCountsResponse,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import {
  AUDIT_RESULT,
  AuditReadinessRecord,
  AuditReadinessTestRecord,
  GetAuditReadinessResponse,
  GetSmartChecksTestInformationResponse,
  SmartCheckRecord,
  SmartControlTestRecord,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { getCheckResult } from '../AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.helpers'
import {
  FRACTION_ELEMENTS,
  OPERATIONALIZE_CHECKS,
  PREPARE_CONTENT_CHECKS,
  RoadmapToggles,
  RoadmapIndexRowType,
  RoadmapSteps,
  SmartCheckCounts,
  RoadmapIndexConfig,
  ROADMAP_STEPS,
} from './roadmap.constants'

// Returns the current step in the roadmap or true if the roadmap journey is complete
export const getCurrentStep = (
  config: RoadmapIndexConfig,
): RoadmapSteps | true => {
  if (config[RoadmapSteps.InitialScoping].percentage < 100) {
    return RoadmapSteps.InitialScoping
  } else if (config[RoadmapSteps.PrepareContent].percentage < 100) {
    return RoadmapSteps.PrepareContent
  } else if (config[RoadmapSteps.Operationalize].percentage < 100) {
    return RoadmapSteps.Operationalize
  } else if (config[RoadmapSteps.HandleRequests].percentage < 100) {
    return RoadmapSteps.HandleRequests
  } else if (config[RoadmapSteps.AuditReadiness].percentage < 100) {
    return RoadmapSteps.AuditReadiness
  } else {
    return true
  }
}

export const getInitialScopingPercentage = (
  data?: GetInitialScopingResponse,
): number => {
  if (!data) return -1
  let numerator = 0
  const denominator = Object.keys(data.toObject()).length
  data.getCompanyNameEntered() && numerator++
  data.getFrameworksSelectedList().length > 0 && numerator++
  data.getAuditSetup() && numerator++
  data.getServicesSelected() && numerator++
  data.getReceptorsSetup() && numerator++
  data.getServiceRolesFulfilled() && numerator++
  data.getPoliciesStarted() && numerator++
  data.getControlsStarted() && numerator++
  data.getRisksStarted() && numerator++
  if (denominator === 0) return 0
  return Math.round((numerator / denominator) * 100)
}

export const getRoadmapWidgetFractionElements = (
  data: GetRoadmapWidgetCountsResponse,
): FRACTION_ELEMENTS => {
  let numerator = 0
  let denominator = 0
  const response = data.toObject()
  const values = Object.values(response)
  values.forEach((value) => {
    if (!value) return
    numerator += value.finished
    denominator += value.total
  })

  return {
    numerator,
    denominator,
  }
}

export const handleRoadmapErrors = ({
  auditId,
  widgetError,
  prepareContentError,
  operationalizeError,
  handleRequestsError,
  roadmapToggleError,
  smartCheckError,
  auditReadinessError,
  auditError,
}: {
  auditId?: string
  widgetError?: Error
  prepareContentError?: Error
  operationalizeError?: Error
  handleRequestsError?: Error
  roadmapToggleError?: Error
  smartCheckError?: Error
  auditReadinessError?: Error
  auditError?: Error
}): void => {
  if (!auditId) return
  widgetError &&
    log.error(
      `Error getting roadmap widget data for audit id: ${auditId}`,
      widgetError,
    )
  prepareContentError &&
    log.error(
      `Error getting prepare content data for audit id: ${auditId}`,
      prepareContentError,
    )
  operationalizeError &&
    log.error(
      `Error getting operationalize data for audit id: ${auditId}`,
      operationalizeError,
    )
  handleRequestsError &&
    log.error(
      `Error getting handle requests data for audit id: ${auditId}`,
      handleRequestsError,
    )
  roadmapToggleError &&
    log.error(
      `Error getting roadmap toggle data for audit id: ${auditId}`,
      roadmapToggleError,
    )
  smartCheckError &&
    log.error(
      `Error getting smart check data for audit id: ${auditId}`,
      smartCheckError,
    )
  auditReadinessError &&
    log.error(
      `Error getting audit readiness data for audit id: ${auditId}`,
      auditReadinessError,
    )
  auditError &&
    log.error(`Error getting audit data for audit id: ${auditId}`, auditError)
}

export const getRoadmapStepPercentage = ({
  checks,
  widgetData,
  roadmapToggleData,
  smartChecksData,
  includeSmartChecks = false,
}: {
  checks: RoadmapToggles[]
  widgetData?: GetRoadmapWidgetCountsResponse
  roadmapToggleData?: GetOrCreateComplianceRoadmapResponse
  smartChecksData?: GetSmartChecksTestInformationResponse
  includeSmartChecks?: boolean
}): number => {
  if (
    !widgetData ||
    !roadmapToggleData ||
    (includeSmartChecks && !smartChecksData)
  )
    return -1
  let numerator = 0
  let denominator = 0
  const { numerator: widgetNumerator, denominator: widgetDenominator } =
    getRoadmapWidgetFractionElements(widgetData)
  numerator += widgetNumerator
  denominator += widgetDenominator
  const checkboxInfo = roadmapToggleData.getComplianceRoadmap()
  if (checkboxInfo) {
    denominator += checks.length
    for (const check of checks) {
      switch (check) {
        case RoadmapToggles.PoliciesApproved:
          checkboxInfo.getPoliciesApproved() && numerator++
          break
        case RoadmapToggles.ApprovedSupportingDocuments:
          checkboxInfo.getApprovedSupportDocuments() && numerator++
          break
        case RoadmapToggles.HasSupportingDocuments:
          checkboxInfo.getHasSupportingDocuments() && numerator++
          break
      }
    }
  }
  if (includeSmartChecks && smartChecksData) {
    const smartChecks = smartChecksData.getSmartChecksList()
    smartChecks.forEach((smartCheck: SmartCheckRecord) => {
      const tests = smartCheck.getTestControlsList()
      denominator += tests.length
      tests.forEach((test: SmartControlTestRecord) => {
        if (test.getResult() === AUDIT_RESULT.PASS || !!test.getIsControlNa()) {
          numerator++
        }
      })
    })
  }
  if (denominator === 0) return 0
  return Math.round((numerator / denominator) * 100)
}

export const getHandleRequestsPercentage = (
  data?: GetRoadmapWidgetCountsResponse,
): number => {
  if (!data) return -1
  const { numerator, denominator } = getRoadmapWidgetFractionElements(data)
  if (denominator === 0) return 0
  return Math.round((numerator / denominator) * 100)
}

export const getAuditReadinessPercentage = (
  data?: GetAuditReadinessResponse,
): number => {
  if (!data) return -1
  const numerator = data.getControlsPassed() + data.getControlsNotApplicable()
  const denominator = data.getControlsTotal()
  if (denominator === 0) return 0
  return Math.round((numerator / denominator) * 100)
}

export const getRoadMapIndexRowsConfig = ({
  initialScopingData,
  prepareContentWidgetData,
  operationalizeWidgetData,
  handleRequestsWidgetData,
  roadmapToggleData,
  smartChecksData,
  auditReadinessData,
}: {
  initialScopingData?: GetInitialScopingResponse
  prepareContentWidgetData?: GetRoadmapWidgetCountsResponse
  operationalizeWidgetData?: GetRoadmapWidgetCountsResponse
  handleRequestsWidgetData?: GetRoadmapWidgetCountsResponse
  roadmapToggleData?: GetOrCreateComplianceRoadmapResponse
  smartChecksData?: GetSmartChecksTestInformationResponse
  auditReadinessData?: GetAuditReadinessResponse
}): Record<RoadmapSteps, RoadmapIndexRowType> => ({
  [RoadmapSteps.InitialScoping]: {
    title: 'Initial Scoping',
    description:
      'Your first challenge. Get started on the basics, allowing everyone involved to understand what the basic structures will be and what systems are in scope.',
    link: RoadmapSteps.InitialScoping,
    percentage: getInitialScopingPercentage(initialScopingData),
  },
  [RoadmapSteps.PrepareContent]: {
    title: 'Prepare Content',
    description:
      'Finalize your policies and control language so you know exactly what compliance should look like for your company.',
    link: RoadmapSteps.PrepareContent,
    percentage: getRoadmapStepPercentage({
      checks: PREPARE_CONTENT_CHECKS,
      widgetData: prepareContentWidgetData,
      roadmapToggleData,
      smartChecksData,
      includeSmartChecks: true,
    }),
  },
  [RoadmapSteps.Operationalize]: {
    title: 'Operationalize',
    description: 'Define and delegate the work of proving compliance.',
    link: RoadmapSteps.Operationalize,
    percentage: getRoadmapStepPercentage({
      checks: OPERATIONALIZE_CHECKS,
      widgetData: operationalizeWidgetData,
      roadmapToggleData,
    }),
  },
  [RoadmapSteps.HandleRequests]: {
    title: 'Handle Document Requests',
    description: `Use your auditor's document requests to link evidence to the relevant controls.`,
    link: RoadmapSteps.HandleRequests,
    percentage: getHandleRequestsPercentage(handleRequestsWidgetData),
  },
  [RoadmapSteps.AuditReadiness]: {
    title: 'Audit Readiness',
    description:
      'Use the AI Copilot to check that all controls should pass your audit. This involves many tests of your evidence on each control, ensuring that it proves you are doing what your controls require.',
    link: RoadmapSteps.AuditReadiness,
    percentage: getAuditReadinessPercentage(auditReadinessData),
  },
})

export const getControlReadinessResults = (
  smartChecks?: AuditReadinessRecord[],
  auditControlIds?: string[],
): SmartCheckCounts => {
  const resultCounts: SmartCheckCounts = {
    passing: [],
    issues: [],
    notScanned: auditControlIds || [],
  }

  if (!smartChecks || !auditControlIds) {
    return resultCounts
  }

  const tempMap = {
    passing: new Set<string>(),
    issues: new Set<string>(),
  }

  smartChecks.reduce((acc, sc) => {
    const res = getCheckResult(
      sc
        .getControlTestsList()
        .map((ct: AuditReadinessTestRecord) => ct.getResult()),
    )
    res === AUDIT_RESULT.PASS && acc.passing.add(sc.getControlId())
    res === AUDIT_RESULT.FAIL && acc.issues.add(sc.getControlId())
    return acc
  }, tempMap as Record<string, Set<string>>)

  resultCounts.notScanned = resultCounts.notScanned.filter(
    (id) => !tempMap.passing.has(id) && !tempMap.issues.has(id),
  )

  resultCounts.passing = Array.from(tempMap.passing)
  resultCounts.issues = Array.from(tempMap.issues)

  return resultCounts
}

export const getControlPluralization = (count: number): string => {
  const showVal = count > -1 ? `${count}` : '--'
  return `${showVal} ${count !== 1 ? 'controls' : 'control'}`
}

export const getRoadmapSteps = (auditId?: string): RoadmapSteps[] => {
  if (!auditId)
    return ROADMAP_STEPS.filter((step) => step !== RoadmapSteps.HandleRequests)
  return ROADMAP_STEPS
}

export const getTotalRoadmapPercentage = (
  config: RoadmapIndexConfig,
  steps: RoadmapSteps[],
): number => {
  const total = steps.reduce((acc, step) => {
    return acc + config[step].percentage
  }, 0)
  return Math.round(total / steps.length)
}
