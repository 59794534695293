/**
 * @fileoverview gRPC-Web generated client stub for notification
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: notification/notification.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.notification = require('./notification_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notification.NotificationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notification.NotificationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.GetNotificationsRequest,
 *   !proto.notification.GetNotificationsResponse>}
 */
const methodDescriptor_Notification_GetNotifications = new grpc.web.MethodDescriptor(
  '/notification.Notification/GetNotifications',
  grpc.web.MethodType.UNARY,
  proto.notification.GetNotificationsRequest,
  proto.notification.GetNotificationsResponse,
  /**
   * @param {!proto.notification.GetNotificationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notification.GetNotificationsResponse.deserializeBinary
);


/**
 * @param {!proto.notification.GetNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notification.GetNotificationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification.GetNotificationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.NotificationClient.prototype.getNotifications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.Notification/GetNotifications',
      request,
      metadata || {},
      methodDescriptor_Notification_GetNotifications,
      callback);
};


/**
 * @param {!proto.notification.GetNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification.GetNotificationsResponse>}
 *     Promise that resolves to the response
 */
proto.notification.NotificationPromiseClient.prototype.getNotifications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.Notification/GetNotifications',
      request,
      metadata || {},
      methodDescriptor_Notification_GetNotifications);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.LatestNotificationRequest,
 *   !proto.notification.LatestNotificationResponse>}
 */
const methodDescriptor_Notification_GetLatestNotification = new grpc.web.MethodDescriptor(
  '/notification.Notification/GetLatestNotification',
  grpc.web.MethodType.UNARY,
  proto.notification.LatestNotificationRequest,
  proto.notification.LatestNotificationResponse,
  /**
   * @param {!proto.notification.LatestNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notification.LatestNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.notification.LatestNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notification.LatestNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification.LatestNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.NotificationClient.prototype.getLatestNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.Notification/GetLatestNotification',
      request,
      metadata || {},
      methodDescriptor_Notification_GetLatestNotification,
      callback);
};


/**
 * @param {!proto.notification.LatestNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification.LatestNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.notification.NotificationPromiseClient.prototype.getLatestNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.Notification/GetLatestNotification',
      request,
      metadata || {},
      methodDescriptor_Notification_GetLatestNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.DismissNotificationRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Notification_DismissNotification = new grpc.web.MethodDescriptor(
  '/notification.Notification/DismissNotification',
  grpc.web.MethodType.UNARY,
  proto.notification.DismissNotificationRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.notification.DismissNotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.notification.DismissNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.NotificationClient.prototype.dismissNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.Notification/DismissNotification',
      request,
      metadata || {},
      methodDescriptor_Notification_DismissNotification,
      callback);
};


/**
 * @param {!proto.notification.DismissNotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.notification.NotificationPromiseClient.prototype.dismissNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.Notification/DismissNotification',
      request,
      metadata || {},
      methodDescriptor_Notification_DismissNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.AddSubscriptionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Notification_AddSubscription = new grpc.web.MethodDescriptor(
  '/notification.Notification/AddSubscription',
  grpc.web.MethodType.UNARY,
  proto.notification.AddSubscriptionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.notification.AddSubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.notification.AddSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.NotificationClient.prototype.addSubscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.Notification/AddSubscription',
      request,
      metadata || {},
      methodDescriptor_Notification_AddSubscription,
      callback);
};


/**
 * @param {!proto.notification.AddSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.notification.NotificationPromiseClient.prototype.addSubscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.Notification/AddSubscription',
      request,
      metadata || {},
      methodDescriptor_Notification_AddSubscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.RemoveSubscriptionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Notification_RemoveSubscription = new grpc.web.MethodDescriptor(
  '/notification.Notification/RemoveSubscription',
  grpc.web.MethodType.UNARY,
  proto.notification.RemoveSubscriptionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.notification.RemoveSubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.notification.RemoveSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.NotificationClient.prototype.removeSubscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.Notification/RemoveSubscription',
      request,
      metadata || {},
      methodDescriptor_Notification_RemoveSubscription,
      callback);
};


/**
 * @param {!proto.notification.RemoveSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.notification.NotificationPromiseClient.prototype.removeSubscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.Notification/RemoveSubscription',
      request,
      metadata || {},
      methodDescriptor_Notification_RemoveSubscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.GetSubscriptionRequest,
 *   !proto.notification.Subscription>}
 */
const methodDescriptor_Notification_GetSubscriptionBySubjectId = new grpc.web.MethodDescriptor(
  '/notification.Notification/GetSubscriptionBySubjectId',
  grpc.web.MethodType.UNARY,
  proto.notification.GetSubscriptionRequest,
  proto.notification.Subscription,
  /**
   * @param {!proto.notification.GetSubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notification.Subscription.deserializeBinary
);


/**
 * @param {!proto.notification.GetSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notification.Subscription)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification.Subscription>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.NotificationClient.prototype.getSubscriptionBySubjectId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.Notification/GetSubscriptionBySubjectId',
      request,
      metadata || {},
      methodDescriptor_Notification_GetSubscriptionBySubjectId,
      callback);
};


/**
 * @param {!proto.notification.GetSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification.Subscription>}
 *     Promise that resolves to the response
 */
proto.notification.NotificationPromiseClient.prototype.getSubscriptionBySubjectId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.Notification/GetSubscriptionBySubjectId',
      request,
      metadata || {},
      methodDescriptor_Notification_GetSubscriptionBySubjectId);
};


module.exports = proto.notification;

