import { useContext } from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { ListPoliciesRequest } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { ListSuggestionsRequest, SUGGESTION_TYPES } from '@trustero/trustero-api-web/lib/recommend/recommend_pb'
import { ListControlsRequest } from '@trustero/trustero-api-web/lib/model/control_pb'
import { useVendors } from 'src/pages/Vendors/vendors.hooks'
import { useDepartments } from '../../Department/useDepartments'
import { useSwrImmutableGrpc } from '../../useSwrImmutableGrpc'
import { DownloadControlsReport } from '../../../../Utils/reports/ControlsReport'
import { AuditContext } from '../../../../context/AuditContext'
import { useAudit } from '../audit'
import { boolValue, stringValue } from '../../../../Utils/primitives'
import { useComplianceFrameworks } from '../../complianceframework'
import { useComplianceFrameworkObjectives } from '../../Framework/useComplianceFrameworkObjectives'
import { useSuggestionsForAccount } from '../../suggestions/useSuggestionsForAccount'

export const useDownloadControlsReport = (
  auditId?: string,
): (() => Promise<void>) => {
  const departments = useDepartments()
  const { auditId: currentAuditId } = useContext(AuditContext)
  const { data: audit } = useAudit(auditId ?? '', !!auditId)
  const { data: complianceFrameworks } = useComplianceFrameworks(true)
  const { data: cfObjectives } = useComplianceFrameworkObjectives(complianceFrameworks?.getItemsList()?.map((cf) => cf.getId()) ?? [])
  const { data: suggestions } = useSuggestionsForAccount(new ListSuggestionsRequest().setTypesList([SUGGESTION_TYPES.EVIDENCE, SUGGESTION_TYPES.TESTS]))
  const { data: vendors } = useVendors()


  const invalidAuditId = auditId && !audit

  const {
    response: { data: controls },
  } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listControls,
    new ListControlsRequest()
      .setIsDismissed(boolValue(false))
      .setAuditId(stringValue(auditId ?? currentAuditId)),
    !invalidAuditId,
  )

  const {
    response: { data: policies },
  } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.list,
    new ListPoliciesRequest(),
    !invalidAuditId,
  )
  if (invalidAuditId) {
    return () => Promise.resolve()
  }

  return () => DownloadControlsReport(controls, policies, departments, vendors?.getVendorsList(), complianceFrameworks, cfObjectives, suggestions?.getSuggestedEvidence()?.getRecordsList(), suggestions?.getSuggestedTests()?.getRecordsList())
}
