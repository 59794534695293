import React from 'react'
import { ControlComplianceStatsComponent } from '../../../components/async/model/stats'

type Props = {
  controlId: string
}

export const EvidenceCounts = (props: Props): JSX.Element => {
  return (
    <ControlComplianceStatsComponent
      {...props}
      child={({ stats }) => (
        <>
          <p>{stats.getAutomatedEvidenceCount()}</p>
          <p>{stats.getManualEvidenceCount()}</p>
        </>
      )}
      onError={() => <p>-</p>}
    />
  )
}
