import React from 'react'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { faList, faListOl } from '@fortawesome/free-solid-svg-icons'
import { ToolbarGroup } from '../styles'
import StyleButton from './StyleButton'

type BlockStyleControlsProps = {
  editorRef: React.RefObject<Editor>
  editorState: EditorState
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>
}

export default function BlockStyleControls({
  editorRef,
  editorState,
  setEditorState,
}: BlockStyleControlsProps): JSX.Element {
  const BLOCK_TYPES = [
    { label: 'bulletList', blockType: 'unordered-list-item', icon: faList },
    { label: 'numberList', blockType: 'ordered-list-item', icon: faListOl },
  ]

  const selection = editorState.getSelection()
  const currBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  const toggleBlockType =
    (blockType: string): React.MouseEventHandler =>
    (e) => {
      e.preventDefault()
      editorRef?.current?.focus()
      setEditorState((editorState) =>
        RichUtils.toggleBlockType(editorState, blockType),
      )
    }

  return (
    <ToolbarGroup>
      {BLOCK_TYPES.map(({ label, blockType, icon }) => (
        <StyleButton
          key={label}
          isActive={blockType === currBlockType}
          toggleFn={toggleBlockType(blockType)}
          icon={icon}
        />
      ))}
    </ToolbarGroup>
  )
}
