export enum NON_LINKABLE_VERBS {
  REMOVED = 'removed',
  DELETED = 'deleted',
  UNLINKED = 'unlinked',
}

export const NON_LINKABLE_CONFIG = Object.freeze({
  [NON_LINKABLE_VERBS.REMOVED]: true,
  [NON_LINKABLE_VERBS.DELETED]: true,
  [NON_LINKABLE_VERBS.UNLINKED]: true,
})
