// source: vendormanagement/vendormanagement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_sorting_pb = require('../common/sorting_pb.js');
goog.object.extend(proto, common_sorting_pb);
goog.exportSymbol('proto.vendormanagement.ATTESTATION_STATUS', null, global);
goog.exportSymbol('proto.vendormanagement.AttestationStatusFilter', null, global);
goog.exportSymbol('proto.vendormanagement.CreateVendorRequest', null, global);
goog.exportSymbol('proto.vendormanagement.CreateVendorsRequest', null, global);
goog.exportSymbol('proto.vendormanagement.DeleteVendorRequest', null, global);
goog.exportSymbol('proto.vendormanagement.GetVendorRequest', null, global);
goog.exportSymbol('proto.vendormanagement.ListVendorsRequest', null, global);
goog.exportSymbol('proto.vendormanagement.ListVendorsResponse', null, global);
goog.exportSymbol('proto.vendormanagement.RISK_LEVEL', null, global);
goog.exportSymbol('proto.vendormanagement.RiskFilter', null, global);
goog.exportSymbol('proto.vendormanagement.RiskLevelFilter', null, global);
goog.exportSymbol('proto.vendormanagement.RiskLevelUpdate', null, global);
goog.exportSymbol('proto.vendormanagement.UpdateVendorRequest', null, global);
goog.exportSymbol('proto.vendormanagement.UpdateVendorsRequest', null, global);
goog.exportSymbol('proto.vendormanagement.VENDOR_SORT_COLUMN', null, global);
goog.exportSymbol('proto.vendormanagement.VendorRecord', null, global);
goog.exportSymbol('proto.vendormanagement.VendorSort', null, global);
goog.exportSymbol('proto.vendormanagement.Vendors', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.VendorRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.VendorRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.VendorRecord.displayName = 'proto.vendormanagement.VendorRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.GetVendorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.GetVendorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.GetVendorRequest.displayName = 'proto.vendormanagement.GetVendorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.RiskLevelFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.RiskLevelFilter.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.RiskLevelFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.RiskLevelFilter.displayName = 'proto.vendormanagement.RiskLevelFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.AttestationStatusFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.AttestationStatusFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.AttestationStatusFilter.displayName = 'proto.vendormanagement.AttestationStatusFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.RiskFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.RiskFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.RiskFilter.displayName = 'proto.vendormanagement.RiskFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.VendorSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.VendorSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.VendorSort.displayName = 'proto.vendormanagement.VendorSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.ListVendorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.ListVendorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.ListVendorsRequest.displayName = 'proto.vendormanagement.ListVendorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.Vendors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.Vendors.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.Vendors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.Vendors.displayName = 'proto.vendormanagement.Vendors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.CreateVendorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.CreateVendorRequest.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.CreateVendorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.CreateVendorRequest.displayName = 'proto.vendormanagement.CreateVendorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.CreateVendorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.CreateVendorsRequest.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.CreateVendorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.CreateVendorsRequest.displayName = 'proto.vendormanagement.CreateVendorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.ListVendorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.ListVendorsResponse.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.ListVendorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.ListVendorsResponse.displayName = 'proto.vendormanagement.ListVendorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.RiskLevelUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.RiskLevelUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.RiskLevelUpdate.displayName = 'proto.vendormanagement.RiskLevelUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.UpdateVendorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.UpdateVendorsRequest.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.UpdateVendorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.UpdateVendorsRequest.displayName = 'proto.vendormanagement.UpdateVendorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.UpdateVendorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vendormanagement.UpdateVendorRequest.repeatedFields_, null);
};
goog.inherits(proto.vendormanagement.UpdateVendorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.UpdateVendorRequest.displayName = 'proto.vendormanagement.UpdateVendorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vendormanagement.DeleteVendorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vendormanagement.DeleteVendorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vendormanagement.DeleteVendorRequest.displayName = 'proto.vendormanagement.DeleteVendorRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.VendorRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.VendorRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.VendorRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.VendorRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    riskLevel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    attestationStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    risksCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.VendorRecord}
 */
proto.vendormanagement.VendorRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.VendorRecord;
  return proto.vendormanagement.VendorRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.VendorRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.VendorRecord}
 */
proto.vendormanagement.VendorRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {!proto.vendormanagement.RISK_LEVEL} */ (reader.readEnum());
      msg.setRiskLevel(value);
      break;
    case 7:
      var value = /** @type {!proto.vendormanagement.ATTESTATION_STATUS} */ (reader.readEnum());
      msg.setAttestationStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRisksCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.VendorRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.VendorRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.VendorRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.VendorRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRiskLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAttestationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRisksCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vendormanagement.VendorRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vendormanagement.VendorRecord.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
*/
proto.vendormanagement.VendorRecord.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.VendorRecord.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.vendormanagement.VendorRecord.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
*/
proto.vendormanagement.VendorRecord.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.VendorRecord.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.vendormanagement.VendorRecord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RISK_LEVEL risk_level = 6;
 * @return {!proto.vendormanagement.RISK_LEVEL}
 */
proto.vendormanagement.VendorRecord.prototype.getRiskLevel = function() {
  return /** @type {!proto.vendormanagement.RISK_LEVEL} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.vendormanagement.RISK_LEVEL} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.setRiskLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ATTESTATION_STATUS attestation_status = 7;
 * @return {!proto.vendormanagement.ATTESTATION_STATUS}
 */
proto.vendormanagement.VendorRecord.prototype.getAttestationStatus = function() {
  return /** @type {!proto.vendormanagement.ATTESTATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.vendormanagement.ATTESTATION_STATUS} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.setAttestationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 risks_count = 8;
 * @return {number}
 */
proto.vendormanagement.VendorRecord.prototype.getRisksCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.setRisksCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string notes = 9;
 * @return {string}
 */
proto.vendormanagement.VendorRecord.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.VendorRecord} returns this
 */
proto.vendormanagement.VendorRecord.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.GetVendorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.GetVendorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.GetVendorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.GetVendorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.GetVendorRequest}
 */
proto.vendormanagement.GetVendorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.GetVendorRequest;
  return proto.vendormanagement.GetVendorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.GetVendorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.GetVendorRequest}
 */
proto.vendormanagement.GetVendorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.GetVendorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.GetVendorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.GetVendorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.GetVendorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vendormanagement.GetVendorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.GetVendorRequest} returns this
 */
proto.vendormanagement.GetVendorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.RiskLevelFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.RiskLevelFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.RiskLevelFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.RiskLevelFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.RiskLevelFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskLevelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.RiskLevelFilter}
 */
proto.vendormanagement.RiskLevelFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.RiskLevelFilter;
  return proto.vendormanagement.RiskLevelFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.RiskLevelFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.RiskLevelFilter}
 */
proto.vendormanagement.RiskLevelFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.vendormanagement.RISK_LEVEL>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRiskLevels(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.RiskLevelFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.RiskLevelFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.RiskLevelFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.RiskLevelFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskLevelsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated RISK_LEVEL risk_levels = 1;
 * @return {!Array<!proto.vendormanagement.RISK_LEVEL>}
 */
proto.vendormanagement.RiskLevelFilter.prototype.getRiskLevelsList = function() {
  return /** @type {!Array<!proto.vendormanagement.RISK_LEVEL>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.vendormanagement.RISK_LEVEL>} value
 * @return {!proto.vendormanagement.RiskLevelFilter} returns this
 */
proto.vendormanagement.RiskLevelFilter.prototype.setRiskLevelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.vendormanagement.RISK_LEVEL} value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.RiskLevelFilter} returns this
 */
proto.vendormanagement.RiskLevelFilter.prototype.addRiskLevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.RiskLevelFilter} returns this
 */
proto.vendormanagement.RiskLevelFilter.prototype.clearRiskLevelsList = function() {
  return this.setRiskLevelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.AttestationStatusFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.AttestationStatusFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.AttestationStatusFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.AttestationStatusFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeNone: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeStale: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeFresh: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.AttestationStatusFilter}
 */
proto.vendormanagement.AttestationStatusFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.AttestationStatusFilter;
  return proto.vendormanagement.AttestationStatusFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.AttestationStatusFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.AttestationStatusFilter}
 */
proto.vendormanagement.AttestationStatusFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeNone(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeStale(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeFresh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.AttestationStatusFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.AttestationStatusFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.AttestationStatusFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.AttestationStatusFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeNone();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeStale();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeFresh();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool include_none = 1;
 * @return {boolean}
 */
proto.vendormanagement.AttestationStatusFilter.prototype.getIncludeNone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vendormanagement.AttestationStatusFilter} returns this
 */
proto.vendormanagement.AttestationStatusFilter.prototype.setIncludeNone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_stale = 2;
 * @return {boolean}
 */
proto.vendormanagement.AttestationStatusFilter.prototype.getIncludeStale = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vendormanagement.AttestationStatusFilter} returns this
 */
proto.vendormanagement.AttestationStatusFilter.prototype.setIncludeStale = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_fresh = 3;
 * @return {boolean}
 */
proto.vendormanagement.AttestationStatusFilter.prototype.getIncludeFresh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vendormanagement.AttestationStatusFilter} returns this
 */
proto.vendormanagement.AttestationStatusFilter.prototype.setIncludeFresh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.RiskFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.RiskFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.RiskFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.RiskFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.RiskFilter}
 */
proto.vendormanagement.RiskFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.RiskFilter;
  return proto.vendormanagement.RiskFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.RiskFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.RiskFilter}
 */
proto.vendormanagement.RiskFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.RiskFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.RiskFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.RiskFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.RiskFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string risk_id = 1;
 * @return {string}
 */
proto.vendormanagement.RiskFilter.prototype.getRiskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.RiskFilter} returns this
 */
proto.vendormanagement.RiskFilter.prototype.setRiskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.VendorSort.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.VendorSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.VendorSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.VendorSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOrder: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.VendorSort}
 */
proto.vendormanagement.VendorSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.VendorSort;
  return proto.vendormanagement.VendorSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.VendorSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.VendorSort}
 */
proto.vendormanagement.VendorSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.SortOrder} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.vendormanagement.VENDOR_SORT_COLUMN} */ (reader.readEnum());
      msg.setSortColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.VendorSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.VendorSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.VendorSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.VendorSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortColumn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional common.SortOrder sort_order = 1;
 * @return {!proto.common.SortOrder}
 */
proto.vendormanagement.VendorSort.prototype.getSortOrder = function() {
  return /** @type {!proto.common.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.SortOrder} value
 * @return {!proto.vendormanagement.VendorSort} returns this
 */
proto.vendormanagement.VendorSort.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional VENDOR_SORT_COLUMN sort_column = 2;
 * @return {!proto.vendormanagement.VENDOR_SORT_COLUMN}
 */
proto.vendormanagement.VendorSort.prototype.getSortColumn = function() {
  return /** @type {!proto.vendormanagement.VENDOR_SORT_COLUMN} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vendormanagement.VENDOR_SORT_COLUMN} value
 * @return {!proto.vendormanagement.VendorSort} returns this
 */
proto.vendormanagement.VendorSort.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.ListVendorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.ListVendorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.ListVendorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.ListVendorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskLevelFilter: (f = msg.getRiskLevelFilter()) && proto.vendormanagement.RiskLevelFilter.toObject(includeInstance, f),
    riskFilter: (f = msg.getRiskFilter()) && proto.vendormanagement.RiskFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.vendormanagement.VendorSort.toObject(includeInstance, f),
    attestationStatusFilter: (f = msg.getAttestationStatusFilter()) && proto.vendormanagement.AttestationStatusFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.ListVendorsRequest}
 */
proto.vendormanagement.ListVendorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.ListVendorsRequest;
  return proto.vendormanagement.ListVendorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.ListVendorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.ListVendorsRequest}
 */
proto.vendormanagement.ListVendorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vendormanagement.RiskLevelFilter;
      reader.readMessage(value,proto.vendormanagement.RiskLevelFilter.deserializeBinaryFromReader);
      msg.setRiskLevelFilter(value);
      break;
    case 2:
      var value = new proto.vendormanagement.RiskFilter;
      reader.readMessage(value,proto.vendormanagement.RiskFilter.deserializeBinaryFromReader);
      msg.setRiskFilter(value);
      break;
    case 3:
      var value = new proto.vendormanagement.VendorSort;
      reader.readMessage(value,proto.vendormanagement.VendorSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 4:
      var value = new proto.vendormanagement.AttestationStatusFilter;
      reader.readMessage(value,proto.vendormanagement.AttestationStatusFilter.deserializeBinaryFromReader);
      msg.setAttestationStatusFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.ListVendorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.ListVendorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.ListVendorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.ListVendorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskLevelFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vendormanagement.RiskLevelFilter.serializeBinaryToWriter
    );
  }
  f = message.getRiskFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vendormanagement.RiskFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vendormanagement.VendorSort.serializeBinaryToWriter
    );
  }
  f = message.getAttestationStatusFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vendormanagement.AttestationStatusFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional RiskLevelFilter risk_level_filter = 1;
 * @return {?proto.vendormanagement.RiskLevelFilter}
 */
proto.vendormanagement.ListVendorsRequest.prototype.getRiskLevelFilter = function() {
  return /** @type{?proto.vendormanagement.RiskLevelFilter} */ (
    jspb.Message.getWrapperField(this, proto.vendormanagement.RiskLevelFilter, 1));
};


/**
 * @param {?proto.vendormanagement.RiskLevelFilter|undefined} value
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
*/
proto.vendormanagement.ListVendorsRequest.prototype.setRiskLevelFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
 */
proto.vendormanagement.ListVendorsRequest.prototype.clearRiskLevelFilter = function() {
  return this.setRiskLevelFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.ListVendorsRequest.prototype.hasRiskLevelFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RiskFilter risk_filter = 2;
 * @return {?proto.vendormanagement.RiskFilter}
 */
proto.vendormanagement.ListVendorsRequest.prototype.getRiskFilter = function() {
  return /** @type{?proto.vendormanagement.RiskFilter} */ (
    jspb.Message.getWrapperField(this, proto.vendormanagement.RiskFilter, 2));
};


/**
 * @param {?proto.vendormanagement.RiskFilter|undefined} value
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
*/
proto.vendormanagement.ListVendorsRequest.prototype.setRiskFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
 */
proto.vendormanagement.ListVendorsRequest.prototype.clearRiskFilter = function() {
  return this.setRiskFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.ListVendorsRequest.prototype.hasRiskFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VendorSort sort = 3;
 * @return {?proto.vendormanagement.VendorSort}
 */
proto.vendormanagement.ListVendorsRequest.prototype.getSort = function() {
  return /** @type{?proto.vendormanagement.VendorSort} */ (
    jspb.Message.getWrapperField(this, proto.vendormanagement.VendorSort, 3));
};


/**
 * @param {?proto.vendormanagement.VendorSort|undefined} value
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
*/
proto.vendormanagement.ListVendorsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
 */
proto.vendormanagement.ListVendorsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.ListVendorsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AttestationStatusFilter attestation_status_filter = 4;
 * @return {?proto.vendormanagement.AttestationStatusFilter}
 */
proto.vendormanagement.ListVendorsRequest.prototype.getAttestationStatusFilter = function() {
  return /** @type{?proto.vendormanagement.AttestationStatusFilter} */ (
    jspb.Message.getWrapperField(this, proto.vendormanagement.AttestationStatusFilter, 4));
};


/**
 * @param {?proto.vendormanagement.AttestationStatusFilter|undefined} value
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
*/
proto.vendormanagement.ListVendorsRequest.prototype.setAttestationStatusFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.ListVendorsRequest} returns this
 */
proto.vendormanagement.ListVendorsRequest.prototype.clearAttestationStatusFilter = function() {
  return this.setAttestationStatusFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.ListVendorsRequest.prototype.hasAttestationStatusFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.Vendors.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.Vendors.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.Vendors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.Vendors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.Vendors.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorsList: jspb.Message.toObjectList(msg.getVendorsList(),
    proto.vendormanagement.VendorRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.Vendors}
 */
proto.vendormanagement.Vendors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.Vendors;
  return proto.vendormanagement.Vendors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.Vendors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.Vendors}
 */
proto.vendormanagement.Vendors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vendormanagement.VendorRecord;
      reader.readMessage(value,proto.vendormanagement.VendorRecord.deserializeBinaryFromReader);
      msg.addVendors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.Vendors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.Vendors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.Vendors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.Vendors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vendormanagement.VendorRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorRecord vendors = 1;
 * @return {!Array<!proto.vendormanagement.VendorRecord>}
 */
proto.vendormanagement.Vendors.prototype.getVendorsList = function() {
  return /** @type{!Array<!proto.vendormanagement.VendorRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vendormanagement.VendorRecord, 1));
};


/**
 * @param {!Array<!proto.vendormanagement.VendorRecord>} value
 * @return {!proto.vendormanagement.Vendors} returns this
*/
proto.vendormanagement.Vendors.prototype.setVendorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vendormanagement.VendorRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.VendorRecord}
 */
proto.vendormanagement.Vendors.prototype.addVendors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vendormanagement.VendorRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.Vendors} returns this
 */
proto.vendormanagement.Vendors.prototype.clearVendorsList = function() {
  return this.setVendorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.CreateVendorRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.CreateVendorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.CreateVendorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.CreateVendorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.CreateVendorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    riskLevel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    riskIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    notes: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.CreateVendorRequest}
 */
proto.vendormanagement.CreateVendorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.CreateVendorRequest;
  return proto.vendormanagement.CreateVendorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.CreateVendorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.CreateVendorRequest}
 */
proto.vendormanagement.CreateVendorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.vendormanagement.RISK_LEVEL} */ (reader.readEnum());
      msg.setRiskLevel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRiskIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.CreateVendorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.CreateVendorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.CreateVendorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.CreateVendorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRiskLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vendormanagement.CreateVendorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.CreateVendorRequest} returns this
 */
proto.vendormanagement.CreateVendorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RISK_LEVEL risk_level = 3;
 * @return {!proto.vendormanagement.RISK_LEVEL}
 */
proto.vendormanagement.CreateVendorRequest.prototype.getRiskLevel = function() {
  return /** @type {!proto.vendormanagement.RISK_LEVEL} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vendormanagement.RISK_LEVEL} value
 * @return {!proto.vendormanagement.CreateVendorRequest} returns this
 */
proto.vendormanagement.CreateVendorRequest.prototype.setRiskLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string risk_ids = 4;
 * @return {!Array<string>}
 */
proto.vendormanagement.CreateVendorRequest.prototype.getRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vendormanagement.CreateVendorRequest} returns this
 */
proto.vendormanagement.CreateVendorRequest.prototype.setRiskIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.CreateVendorRequest} returns this
 */
proto.vendormanagement.CreateVendorRequest.prototype.addRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.CreateVendorRequest} returns this
 */
proto.vendormanagement.CreateVendorRequest.prototype.clearRiskIdsList = function() {
  return this.setRiskIdsList([]);
};


/**
 * optional string notes = 5;
 * @return {string}
 */
proto.vendormanagement.CreateVendorRequest.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.CreateVendorRequest} returns this
 */
proto.vendormanagement.CreateVendorRequest.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.CreateVendorsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.CreateVendorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.CreateVendorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.CreateVendorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.CreateVendorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.vendormanagement.CreateVendorRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.CreateVendorsRequest}
 */
proto.vendormanagement.CreateVendorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.CreateVendorsRequest;
  return proto.vendormanagement.CreateVendorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.CreateVendorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.CreateVendorsRequest}
 */
proto.vendormanagement.CreateVendorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vendormanagement.CreateVendorRequest;
      reader.readMessage(value,proto.vendormanagement.CreateVendorRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.CreateVendorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.CreateVendorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.CreateVendorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.CreateVendorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vendormanagement.CreateVendorRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateVendorRequest requests = 1;
 * @return {!Array<!proto.vendormanagement.CreateVendorRequest>}
 */
proto.vendormanagement.CreateVendorsRequest.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.vendormanagement.CreateVendorRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vendormanagement.CreateVendorRequest, 1));
};


/**
 * @param {!Array<!proto.vendormanagement.CreateVendorRequest>} value
 * @return {!proto.vendormanagement.CreateVendorsRequest} returns this
*/
proto.vendormanagement.CreateVendorsRequest.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vendormanagement.CreateVendorRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.CreateVendorRequest}
 */
proto.vendormanagement.CreateVendorsRequest.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vendormanagement.CreateVendorRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.CreateVendorsRequest} returns this
 */
proto.vendormanagement.CreateVendorsRequest.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.ListVendorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.ListVendorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.ListVendorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.ListVendorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.ListVendorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorsList: jspb.Message.toObjectList(msg.getVendorsList(),
    proto.vendormanagement.VendorRecord.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.ListVendorsResponse}
 */
proto.vendormanagement.ListVendorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.ListVendorsResponse;
  return proto.vendormanagement.ListVendorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.ListVendorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.ListVendorsResponse}
 */
proto.vendormanagement.ListVendorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vendormanagement.VendorRecord;
      reader.readMessage(value,proto.vendormanagement.VendorRecord.deserializeBinaryFromReader);
      msg.addVendors(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.ListVendorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.ListVendorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.ListVendorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.ListVendorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vendormanagement.VendorRecord.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated VendorRecord vendors = 1;
 * @return {!Array<!proto.vendormanagement.VendorRecord>}
 */
proto.vendormanagement.ListVendorsResponse.prototype.getVendorsList = function() {
  return /** @type{!Array<!proto.vendormanagement.VendorRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vendormanagement.VendorRecord, 1));
};


/**
 * @param {!Array<!proto.vendormanagement.VendorRecord>} value
 * @return {!proto.vendormanagement.ListVendorsResponse} returns this
*/
proto.vendormanagement.ListVendorsResponse.prototype.setVendorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vendormanagement.VendorRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.VendorRecord}
 */
proto.vendormanagement.ListVendorsResponse.prototype.addVendors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vendormanagement.VendorRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.ListVendorsResponse} returns this
 */
proto.vendormanagement.ListVendorsResponse.prototype.clearVendorsList = function() {
  return this.setVendorsList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.vendormanagement.ListVendorsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vendormanagement.ListVendorsResponse} returns this
 */
proto.vendormanagement.ListVendorsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.RiskLevelUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.RiskLevelUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.RiskLevelUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.RiskLevelUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskLevel: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.RiskLevelUpdate}
 */
proto.vendormanagement.RiskLevelUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.RiskLevelUpdate;
  return proto.vendormanagement.RiskLevelUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.RiskLevelUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.RiskLevelUpdate}
 */
proto.vendormanagement.RiskLevelUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vendormanagement.RISK_LEVEL} */ (reader.readEnum());
      msg.setRiskLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.RiskLevelUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.RiskLevelUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.RiskLevelUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.RiskLevelUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional RISK_LEVEL risk_level = 1;
 * @return {!proto.vendormanagement.RISK_LEVEL}
 */
proto.vendormanagement.RiskLevelUpdate.prototype.getRiskLevel = function() {
  return /** @type {!proto.vendormanagement.RISK_LEVEL} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vendormanagement.RISK_LEVEL} value
 * @return {!proto.vendormanagement.RiskLevelUpdate} returns this
 */
proto.vendormanagement.RiskLevelUpdate.prototype.setRiskLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.UpdateVendorsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.UpdateVendorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.UpdateVendorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.UpdateVendorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.UpdateVendorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.vendormanagement.UpdateVendorRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.UpdateVendorsRequest}
 */
proto.vendormanagement.UpdateVendorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.UpdateVendorsRequest;
  return proto.vendormanagement.UpdateVendorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.UpdateVendorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.UpdateVendorsRequest}
 */
proto.vendormanagement.UpdateVendorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vendormanagement.UpdateVendorRequest;
      reader.readMessage(value,proto.vendormanagement.UpdateVendorRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.UpdateVendorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.UpdateVendorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.UpdateVendorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.UpdateVendorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vendormanagement.UpdateVendorRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UpdateVendorRequest requests = 1;
 * @return {!Array<!proto.vendormanagement.UpdateVendorRequest>}
 */
proto.vendormanagement.UpdateVendorsRequest.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.vendormanagement.UpdateVendorRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vendormanagement.UpdateVendorRequest, 1));
};


/**
 * @param {!Array<!proto.vendormanagement.UpdateVendorRequest>} value
 * @return {!proto.vendormanagement.UpdateVendorsRequest} returns this
*/
proto.vendormanagement.UpdateVendorsRequest.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vendormanagement.UpdateVendorRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.UpdateVendorRequest}
 */
proto.vendormanagement.UpdateVendorsRequest.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vendormanagement.UpdateVendorRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.UpdateVendorsRequest} returns this
 */
proto.vendormanagement.UpdateVendorsRequest.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vendormanagement.UpdateVendorRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.UpdateVendorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.UpdateVendorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.UpdateVendorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    riskLevel: (f = msg.getRiskLevel()) && proto.vendormanagement.RiskLevelUpdate.toObject(includeInstance, f),
    riskIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    notes: (f = msg.getNotes()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.UpdateVendorRequest}
 */
proto.vendormanagement.UpdateVendorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.UpdateVendorRequest;
  return proto.vendormanagement.UpdateVendorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.UpdateVendorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.UpdateVendorRequest}
 */
proto.vendormanagement.UpdateVendorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new proto.vendormanagement.RiskLevelUpdate;
      reader.readMessage(value,proto.vendormanagement.RiskLevelUpdate.deserializeBinaryFromReader);
      msg.setRiskLevel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addRiskIds(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.UpdateVendorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.UpdateVendorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.UpdateVendorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRiskLevel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vendormanagement.RiskLevelUpdate.serializeBinaryToWriter
    );
  }
  f = message.getRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
*/
proto.vendormanagement.UpdateVendorRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RiskLevelUpdate risk_level = 4;
 * @return {?proto.vendormanagement.RiskLevelUpdate}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.getRiskLevel = function() {
  return /** @type{?proto.vendormanagement.RiskLevelUpdate} */ (
    jspb.Message.getWrapperField(this, proto.vendormanagement.RiskLevelUpdate, 4));
};


/**
 * @param {?proto.vendormanagement.RiskLevelUpdate|undefined} value
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
*/
proto.vendormanagement.UpdateVendorRequest.prototype.setRiskLevel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.clearRiskLevel = function() {
  return this.setRiskLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.hasRiskLevel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string risk_ids = 5;
 * @return {!Array<string>}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.getRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.setRiskIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.addRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.clearRiskIdsList = function() {
  return this.setRiskIdsList([]);
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
*/
proto.vendormanagement.UpdateVendorRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue notes = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.getNotes = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
*/
proto.vendormanagement.UpdateVendorRequest.prototype.setNotes = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vendormanagement.UpdateVendorRequest} returns this
 */
proto.vendormanagement.UpdateVendorRequest.prototype.clearNotes = function() {
  return this.setNotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vendormanagement.UpdateVendorRequest.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vendormanagement.DeleteVendorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vendormanagement.DeleteVendorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vendormanagement.DeleteVendorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.DeleteVendorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vendormanagement.DeleteVendorRequest}
 */
proto.vendormanagement.DeleteVendorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vendormanagement.DeleteVendorRequest;
  return proto.vendormanagement.DeleteVendorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vendormanagement.DeleteVendorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vendormanagement.DeleteVendorRequest}
 */
proto.vendormanagement.DeleteVendorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vendormanagement.DeleteVendorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vendormanagement.DeleteVendorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vendormanagement.DeleteVendorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vendormanagement.DeleteVendorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.vendormanagement.DeleteVendorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vendormanagement.DeleteVendorRequest} returns this
 */
proto.vendormanagement.DeleteVendorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.vendormanagement.RISK_LEVEL = {
  VERY_LOW: 0,
  LOW: 1,
  MODERATE: 2,
  HIGH: 3
};

/**
 * @enum {number}
 */
proto.vendormanagement.VENDOR_SORT_COLUMN = {
  NAME: 0,
  RISK_TIER: 1,
  ATTESTATION: 2,
  RISKS_COUNT: 3
};

/**
 * @enum {number}
 */
proto.vendormanagement.ATTESTATION_STATUS = {
  NONE: 0,
  STALE: 1,
  FRESH: 2
};

goog.object.extend(exports, proto.vendormanagement);
