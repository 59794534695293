import React, { useContext, useMemo, useState } from 'react'

type CurrentFormAudit = {
  auditId: string
  setAuditId: React.Dispatch<React.SetStateAction<string>>
}

const CurrentFormAuditContext = React.createContext<CurrentFormAudit>({
  auditId: '',
  setAuditId: () => '',
})

/**
 * *IMPORTANT*
 * This hook is _NOT_ used to get the current audit scope. It is used to get the currently selected audit on the audit index page. This is to allow the correct audit id to get passed into the edit audit form.
 * @returns {CurrentFormAudit} CurrentFormAudit
 */
export const useCurrentFormAuditId = (): CurrentFormAudit =>
  useContext(CurrentFormAuditContext)

export const CurrentFormAuditContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [auditId, setAuditId] = useState<string>('')

  const auditData: CurrentFormAudit = useMemo(
    () => ({
      auditId,
      setAuditId,
    }),
    [auditId, setAuditId],
  )
  return (
    <CurrentFormAuditContext.Provider value={auditData}>
      {children}
    </CurrentFormAuditContext.Provider>
  )
}
