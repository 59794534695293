import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import {
  CreateOrUpdateServiceRequest,
  BulkCreateOrUpdateServicesRequest,
  CreateCustomServiceRequest,
  GetServiceRequest,
  ListServicesRequest,
  ListServicesResponse,
  SERVICE_ROLE,
  ServiceRecord,
  ServiceRoles,
  ReceptorFilter,
  CreateCustomServicesRequest,
} from '@trustero/trustero-api-web/lib/service/service_pb'
import log from 'loglevel'
import { ServicePromiseClient } from '@trustero/trustero-api-web/lib/service/service_grpc_web_pb'
import {
  BulkSetInstancesDismissRequest,
  ListInstancesByServiceIdRequest,
  ListInstancesResponse,
  SetInstanceDismissRequest,
} from '@trustero/trustero-api-web/lib/model/service_instance_pb'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { ServiceInstancePromiseClient } from '@trustero/trustero-api-web/lib/model/service_instance_grpc_web_pb'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { useConfirmationModal } from 'src/components/ModalForms'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { ServicesAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useGrpcRevalidateByMethod } from 'src/components'
import { Service } from 'src/xgenerated'
import { useThrobberContext } from 'src/Throbber'
import { useAuthorizedGrpcClient } from 'src/adapter'
import queryString, { ParsedQuery } from 'query-string'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  applyServicesFilters,
  downloadPrimaryInfrastuctureMappings,
} from './services.helpers'
import {
  ConfirmExcludeServiceModalBody,
  ConfirmIncludeServiceModalBody,
} from './services.components'

export const useInvalidateServicesCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await Promise.all([
        mutateFunc(ServicePromiseClient.prototype.listServices),
        mutateFunc(ServicePromiseClient.prototype.getServiceById),
        mutateFunc(AccountPromiseClient.prototype.getScopingConfig),
      ])
    } catch (error) {
      log.error('Error when invalidating services cache', error)
    }
  }, [mutateFunc])
}

export const useInvalidateServiceInstancesCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await mutateFunc(
        ServiceInstancePromiseClient.prototype.listInstancesByServiceId,
      )
    } catch (error) {
      log.error('Error when invalidating service instances cache', error)
    }
  }, [mutateFunc])
}

export const useServices = (
  req: ListServicesRequest,
  shouldFetch = true,
): GrpcResponse<ListServicesResponse> => {
  const { response } = useSwrImmutableGrpc(
    ServicePromiseClient.prototype.listServices,
    req,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useServicesRequest = (
  includeCustomScopingRoles = false,
): ListServicesRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListServicesRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyServicesFilters(req, queryParams, filterType),
    )
    // Excluded services should be displayed on the Services Index page
    if (location.pathname.includes(ServicesAbsoluteRoutes.INDEX)) {
      req.setIncludeExcluded(new BoolValue().setValue(true))
    }
    if (includeCustomScopingRoles) {
      // this ensures that previously added custom services still appear in the appropriate dropdowns on the scoping page
      req.setIncludeCustomScopingRoles(new BoolValue().setValue(true))
    }
    return req
  }, [location.search, location.pathname, includeCustomScopingRoles])
}

export const useServicesForReceptor = (
  receptorId: string,
  receptorModelId: string,
): GrpcResponse<ListServicesResponse> => {
  const req = new ListServicesRequest()
    .setReceptorFilter(
      new ReceptorFilter()
        .setReceptorModelId(new StringValue().setValue(receptorModelId))
        .setReceptorId(new StringValue().setValue(receptorId)),
    )
    .setIncludeExcluded(new BoolValue().setValue(true))
  const { response } = useSwrImmutableGrpc(
    ServicePromiseClient.prototype.listServices,
    req,
    !!receptorId && !!receptorModelId,
  )
  return NewGrpcResponse(response)
}

export const useBulkAddOrUpdateServices = (): ((
  services: Service[],
) => Promise<void>) => {
  const mutate = useInvalidateServicesCache()
  const serviceClient = useAuthorizedGrpcClient(ServicePromiseClient)

  return async (services: Service[]) => {
    const request = new BulkCreateOrUpdateServicesRequest()
    const requestList: CreateOrUpdateServiceRequest[] = []
    services.forEach((service) => {
      const individualReq = new CreateOrUpdateServiceRequest()
      individualReq.setModelId(service.modelid)
      service.service_roles &&
        individualReq.setServiceRoles(
          new ServiceRoles().setItemsList(service.service_roles),
        )
      requestList.push(individualReq)
    })
    request.setRequestsList(requestList)
    await serviceClient.bulkCreateOrUpdateServices(request)
    await mutate()
  }
}

export const useCreateCustomServices = (): (({
  customServices,
}: {
  customServices: CreateCustomServiceRequest[]
}) => Promise<void>) => {
  const mutate = useInvalidateServicesCache()
  const serviceClient = useAuthorizedGrpcClient(ServicePromiseClient)

  return async ({
    customServices,
  }: {
    customServices: CreateCustomServiceRequest[]
  }) => {
    const request = new CreateCustomServicesRequest().setServicesList(
      customServices,
    )
    await serviceClient.createCustomServices(request)
    await mutate()
  }
}

export const useService = ({
  serviceId,
  modelId,
}: {
  serviceId?: string
  modelId?: string
}): GrpcResponse<ServiceRecord> => {
  const request = new GetServiceRequest()
  const shouldFetch = !!serviceId || !!modelId
  serviceId && request.setId(serviceId)
  modelId && request.setModelId(modelId)
  const { response } = useSwrImmutableGrpc(
    ServicePromiseClient.prototype.getServiceById,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useCreateOrUpdateService = (): (({
  modelId,
  isDismissed,
  serviceRoles,
  isExcluded,
}: {
  modelId: string
  isDismissed?: boolean
  serviceRoles?: SERVICE_ROLE[]
  isExcluded?: boolean
}) => Promise<void>) => {
  const mutate = useInvalidateServicesCache()
  const serviceClient = useAuthorizedGrpcClient(ServicePromiseClient)

  const createOrUpdateService = useCallback(
    async ({
      modelId,
      isDismissed,
      serviceRoles,
      isExcluded,
    }: {
      modelId: string
      isDismissed?: boolean
      serviceRoles?: SERVICE_ROLE[]
      isExcluded?: boolean
    }) => {
      const request = new CreateOrUpdateServiceRequest().setModelId(modelId)
      isDismissed &&
        request.setIsDismissed(new BoolValue().setValue(isDismissed))
      serviceRoles &&
        request.setServiceRoles(new ServiceRoles().setItemsList(serviceRoles))
      isExcluded != undefined &&
        request.setIsExcluded(new BoolValue().setValue(isExcluded))

      await serviceClient.createOrUpdateService(request)
      await mutate()
    },
    [mutate, serviceClient],
  )

  return createOrUpdateService
}

export const useDismissServiceModal = ({
  service,
}: {
  service: Service
}): (() => void) => {
  const setDismissed = useCreateOrUpdateService()
  const mutate = useInvalidateServicesCache()

  const onDismiss = async () => {
    try {
      await setDismissed({
        modelId: service.modelid,
        isDismissed: true,
      })
      await mutate()
    } catch (error) {
      log.error(
        `Error when dismissing service with model id ${service.modelid}`,
        error,
      )
    }
  }

  const confirmationModalProps = {
    title: 'Remove service?',
    body: `${service.name} will be removed from your list of services. You can add it back later.`,
    confirmText: 'Remove Service',
    redirectTo: '.',
    onConfirmCB: onDismiss,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useExcludeServiceModal = ({
  service,
}: {
  service: Service
}): (() => void) => {
  const mutate = useInvalidateServicesCache()
  const setExcluded = useCreateOrUpdateService()
  const { startThrobber, stopThrobber } = useThrobberContext()

  const onExclude = async () => {
    try {
      startThrobber()
      await setExcluded({
        modelId: service.modelid,
        isExcluded: true,
      })
      await mutate()
    } catch (error) {
      log.error(
        `Error when excluding service with model id ${service.modelid}`,
        error,
      )
    } finally {
      stopThrobber()
    }
  }

  const confirmationModalProps = {
    title: `Exclude ${service.name}?`,
    body: <ConfirmExcludeServiceModalBody service={service} />,
    confirmText: 'Exclude Service',
    redirectTo: '.',
    onConfirmCB: onExclude,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useIncludeServiceModal = ({
  service,
}: {
  service: Service
}): (() => void) => {
  const mutate = useInvalidateServicesCache()
  const setIncluded = useCreateOrUpdateService()
  const { startThrobber, stopThrobber } = useThrobberContext()

  const onInclude = async () => {
    try {
      startThrobber()
      await setIncluded({
        modelId: service.modelid,
        isExcluded: false,
      })
      await mutate()
    } catch (error) {
      log.error(
        `Error when including service with model id ${service.modelid}`,
        error,
      )
    } finally {
      stopThrobber()
    }
  }

  const confirmationModalProps = {
    title: `Include ${service.name}?`,
    body: <ConfirmIncludeServiceModalBody service={service} />,
    confirmText: 'Include Service',
    redirectTo: '.',
    onConfirmCB: onInclude,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useInstancesForService = ({
  serviceId,
}: {
  serviceId: string
}): GrpcResponse<ListInstancesResponse> => {
  const request = new ListInstancesByServiceIdRequest().setServiceId(serviceId)
  const { response } = useSwrImmutableGrpc(
    ServiceInstancePromiseClient.prototype.listInstancesByServiceId,
    request,
    !!serviceId,
  )
  return NewGrpcResponse(response)
}

export const useSetDismissedInstance = (): (({
  instanceId,
  isDismissed,
}: {
  instanceId: string
  isDismissed: boolean
}) => Promise<void>) => {
  const mutate = useInvalidateServiceInstancesCache()
  const serviceInstanceClient = useAuthorizedGrpcClient(
    ServiceInstancePromiseClient,
  )
  return useCallback(
    async ({
      instanceId,
      isDismissed,
    }: {
      instanceId: string
      isDismissed: boolean
    }) => {
      const request = new SetInstanceDismissRequest()
      request.setId(instanceId)
      request.setDismissed(isDismissed)
      await serviceInstanceClient.setDismiss(request)
      await mutate()
    },
    [mutate, serviceInstanceClient],
  )
}

export const useSetDismissedInstanceByService = (): (({
  serviceId,
  isDismissed,
}: {
  serviceId: string
  isDismissed: boolean
}) => Promise<void>) => {
  const mutate = useInvalidateServiceInstancesCache()
  const serviceInstanceClient = useAuthorizedGrpcClient(
    ServiceInstancePromiseClient,
  )
  return useCallback(
    async ({
      serviceId,
      isDismissed,
    }: {
      serviceId: string
      isDismissed: boolean
    }) => {
      const request = new BulkSetInstancesDismissRequest()
      request.setServiceId(serviceId)
      request.setDismissed(isDismissed)
      await serviceInstanceClient.bulkSetDismiss(request)
      await mutate()
    },
    [mutate, serviceInstanceClient],
  )
}

export const useDownloadPrimaryInfrastructure = (): (() => Promise<void>) => {
  const client = useAuthorizedGrpcClient(ServicePromiseClient)
  return async () => {
    try {
      const response = await client.getPrimaryInfrastructureMappings(
        new Empty(),
      )
      const primaryInfrastructureMappings = response.getMappingsList()
      downloadPrimaryInfrastuctureMappings(primaryInfrastructureMappings)
    } catch (error) {
      log.error('Error when downloading primary infrastructure mappings', error)
      showInfoToast(
        'Sorry, there was a problem downloading the primary infrastructure service mappings. Please try again.',
      )
    }
  }
}
