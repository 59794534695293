import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { ComponentIds } from 'src/Utils/testIds'
import {
  ShowPageItemStatsRow,
  ShowPageItemStatsLabel,
  AlignedShowPageItemStatsValue,
} from 'src/components/PageLayout/ShowPage'
import {
  LinkagesAccordion,
  LinkagesAccordionBody,
  LinkagesAccordionBodyContent,
  LinkagesAccordionHeader,
  LinkagesAccordionItem,
} from 'src/components/Reusable/Accordions/ShowPage/Linkages/LinkagesAccordion.styles'
import { OwnerAssignmentButton } from 'src/components/Reusable/Buttons/OwnerAssignmentButton'
import { LinkagesDepartmentChip } from 'src/pages/Controls/ControlsShowPage/ControlsShowItem/ControlsShowItemAccordions/ControlsShowItemLinkages/ControlsShowItemLinkages.styles'
import formatDate from 'src/Utils/formatDate'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import log from 'loglevel'
import { RiskOwnerLabel, RiskTimestamps } from '../risks.styles'
import { RisksCategoryMenu } from '../menus/RisksCategoryMenu'
import { useCreateOrUpdateRisks } from '../risks.hooks'
import { updatedRiskRpcData } from '../risks.helpers'
import { RiskFields } from '../risks.constants'

export const RiskLinkages = ({ risk }: { risk: Risk }): JSX.Element => {
  const createOrUpdateRisks = useCreateOrUpdateRisks()

  if (!risk) {
    return <></>
  }

  const riskId = risk.getId()
  const createdAt = formatDate(
    new Date(risk.getCreatedAt()).toLocaleString(),
    true,
    false,
  )
  const updatedAt = formatDate(
    new Date(risk.getUpdatedAt()).toLocaleString(),
    true,
    false,
  )
  const ownerEmail = risk.getOwnerEmail()
  const deptId = risk.getDepartmentId()

  const updateDept = async (newDeptId: string) => {
    // TODO: Update Dept API handling
    try {
      const updatedRisk = updatedRiskRpcData(
        risk,
        RiskFields.departmentId,
        newDeptId,
      )
      await createOrUpdateRisks([updatedRisk])
    } catch (err) {
      log.error(`Error updating risk ${riskId} department to ${newDeptId}`, err)
    }
  }

  return (
    <LinkagesAccordion>
      <LinkagesAccordionItem eventKey="1">
        <LinkagesAccordionHeader $currentEventKey="1">
          LINKAGES
        </LinkagesAccordionHeader>
        <LinkagesAccordionBody>
          <LinkagesAccordionBodyContent>
            <ShowPageItemStatsRow>
              <ShowPageItemStatsLabel>Assignee</ShowPageItemStatsLabel>
              <AlignedShowPageItemStatsValue>
                <OwnerAssignmentButton
                  id={ComponentIds.OWNER_DROPDOWN}
                  model={risk}
                  modelType={MODEL_TYPE.RISK}
                  modelId={risk.getId()}
                  email={ownerEmail}
                />
                <RiskOwnerLabel htmlFor={ComponentIds.OWNER_DROPDOWN}>
                  {ownerEmail}
                </RiskOwnerLabel>
              </AlignedShowPageItemStatsValue>
            </ShowPageItemStatsRow>
            <ShowPageItemStatsRow>
              <ShowPageItemStatsLabel>Department</ShowPageItemStatsLabel>
              <AlignedShowPageItemStatsValue>
                <LinkagesDepartmentChip
                  departmentId={deptId}
                  onChanged={updateDept}
                  allowEmpty
                />
              </AlignedShowPageItemStatsValue>
            </ShowPageItemStatsRow>
            <ShowPageItemStatsRow>
              <ShowPageItemStatsLabel>Category</ShowPageItemStatsLabel>
              <AlignedShowPageItemStatsValue>
                <RisksCategoryMenu riskId={riskId} />
              </AlignedShowPageItemStatsValue>
            </ShowPageItemStatsRow>
          </LinkagesAccordionBodyContent>
        </LinkagesAccordionBody>
      </LinkagesAccordionItem>
      <RiskTimestamps>
        <p>Created: {createdAt}</p>
        <p>Last Modified: {updatedAt}</p>
      </RiskTimestamps>
    </LinkagesAccordion>
  )
}
