import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { Dropdown } from 'react-bootstrap'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import { Range } from 'react-date-range'
import { formatTimestamp } from 'src/Utils/formatDate'
import { CountChip } from '../../Chips/BasicChip'
import { DateRangeModal } from '../../TrusteroDateRange/TrusteroDateRange'
import { FilterParam } from './FilterBar.types'
import { FilterDropdownToggle } from './FilterBar.styles'

export const DateRangeFilter = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: undefined,
  } as Range)
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedDateRange = useMemo(() => {
    let appliedFilters: string[] = []
    const preprocessed = searchParams[FilterParam.DATE_RANGE]
    if (!preprocessed) return { startDate: undefined, endDate: undefined }
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed[0].split('-')
    } else {
      appliedFilters = preprocessed ? preprocessed.split('-') : []
    }
    return {
      startDate: new Date(parseInt(appliedFilters[0], 10)),
      endDate: new Date(parseInt(appliedFilters[1], 10)),
    } as Range
  }, [searchParams])

  useEffect(() => {
    if (!range.startDate || !range.endDate) return
    const updatedHref = queryString.stringifyUrl(
      {
        url: location.pathname,
        query: {
          ...searchParams,
          [FilterParam.DATE_RANGE]: [
            `${range.startDate.getTime()}-${range.endDate.getTime()}`,
          ],
        },
      },
      { arrayFormat: 'bracket' },
    )
    const currentHref = queryString.stringifyUrl(
      {
        url: location.pathname,
        query: {
          ...searchParams,
        },
      },
      { arrayFormat: 'bracket' },
    )
    if (currentHref !== updatedHref) {
      navigate(updatedHref, { replace: true })
      setRange({ startDate: undefined, endDate: undefined })
    }
  }, [range, searchParams, location.pathname, navigate, appliedDateRange])

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        id="date-range-filter"
        onClick={() => setShowDatePicker(true)}
      >
        <p>Date Range</p>
        {!!appliedDateRange.startDate && !!appliedDateRange.endDate && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {`${formatTimestamp(
              Timestamp.fromDate(appliedDateRange.startDate),
              DATE_FORMATS.ISO,
            )} to ${formatTimestamp(
              Timestamp.fromDate(appliedDateRange.endDate),
              DATE_FORMATS.ISO,
            )}`}
          </CountChip>
        )}
      </Dropdown.Toggle>
      {showDatePicker && (
        <DateRangeModal
          range={appliedDateRange}
          rangeSet={setRange}
          editingSet={setShowDatePicker}
        />
      )}
    </Dropdown>
  )
}
