import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import OverlayTrigger, {
  OverlayDelay,
  OverlayTriggerProps,
} from 'react-bootstrap/OverlayTrigger'
import BootstrapTooltip from 'react-bootstrap/Tooltip'
import Popover from 'react-bootstrap/Popover'

const StyledPopover = styled(Popover)`
  max-width: 550px;
`

const defaultTooltipDelay: OverlayDelay = { show: 150, hide: 150 }

// More options: https://mui.com/material-ui/api/tooltip/#props
export enum TooltipPositions {
  default = 'auto',
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export enum TooltipOverlayType {
  tooltip = 'tooltip',
  popover = 'popover',
}

export enum TOOLTIP_TRIGGERS {
  HOVER = 'hover',
  FOCUS = 'focus',
}

export const Tooltip = ({
  id,
  trigger,
  defaultShow,
  show,
  onToggle,
  delay = defaultTooltipDelay,
  placement = TooltipPositions.default,
  overlayType = TooltipOverlayType.tooltip,
  tooltipBody,
  children,
  disabled = false,
}: Omit<OverlayTriggerProps, 'overlay'> & {
  id: string
  overlayType?: TooltipOverlayType
  tooltipBody: ReactNode
  disabled?: boolean
}): JSX.Element => {
  const overlay = disabled ? (
    <></>
  ) : overlayType === TooltipOverlayType.tooltip ? (
    <BootstrapTooltip id={id}>{tooltipBody}</BootstrapTooltip>
  ) : (
    <StyledPopover id={id}>
      <Popover.Body>{tooltipBody}</Popover.Body>
    </StyledPopover>
  )

  return (
    <OverlayTrigger
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
      trigger={trigger}
      defaultShow={defaultShow}
      show={show}
      onToggle={onToggle}
      delay={delay}
      placement={placement}
      overlay={overlay}
    >
      {children}
    </OverlayTrigger>
  )
}
