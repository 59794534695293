import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useAuditRun } from 'src/pages/AuditBot/AuditBot.hooks'
import { useAudit } from 'src/components/async/model'
import { LinkContent } from './LinkedContent'
import { SubjectContainer } from './Subject.styles'

export const AuditBotContent = ({
  auditRunId,
  linkable,
}: {
  auditRunId: string
  linkable: boolean
}): JSX.Element => {
  const { data, isLoading } = useAuditRun(auditRunId)
  const auditId = data?.getSummary()?.getAuditId()?.getValue() || ''
  const { data: audit, isLoading: isAuditLoading } = useAudit(auditId)
  if (!data || !audit || isLoading || isAuditLoading) {
    return <></>
  }
  const { summary } = data.toObject()
  const { name } = audit.toObject()
  const id = summary?.auditRunId || ''

  if (linkable) {
    return (
      <LinkContent
        name={name}
        modelId={id}
        modelType={MODEL_TYPE.AUDITBOT_RUN}
      />
    )
  }

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {name}
    </SubjectContainer>
  )
}
