import React from 'react'
import Form from 'react-bootstrap/Form'
import { RISK_MATH } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  RiskMathRadioButtonRow,
  RiskMathRadioContainer,
  RiskRadioContainer,
} from '../risks.styles'
import { RiskMathLabels } from '../risks.constants'

export const RisksMathRadioMenu = ({
  labelText,
  onSelectCb,
}: {
  labelText: string
  onSelectCb: (risk: RISK_MATH) => void
}): JSX.Element => (
  <RiskMathRadioButtonRow>
    <label>{labelText}</label>
    <RiskMathRadioContainer>
      <RiskRadioContainer>
        <Form.Check
          inline
          label={RiskMathLabels.VERY_LOW}
          name={labelText}
          type="radio"
          id={`risk-radio-item-${labelText}-${RiskMathLabels.VERY_LOW}`}
          onClick={() => onSelectCb(RISK_MATH.VERY_LOW)}
        />
      </RiskRadioContainer>
      <RiskRadioContainer>
        <Form.Check
          inline
          label={RiskMathLabels.LOW}
          name={labelText}
          type="radio"
          id={`risk-radio-item-${labelText}-${RiskMathLabels.LOW}`}
          onClick={() => onSelectCb(RISK_MATH.LOW)}
        />
      </RiskRadioContainer>
      <RiskRadioContainer>
        <Form.Check
          inline
          label={RiskMathLabels.MODERATE}
          name={labelText}
          type="radio"
          id={`risk-radio-item-${labelText}-${RiskMathLabels.MODERATE}`}
          onClick={() => onSelectCb(RISK_MATH.MODERATE)}
        />
      </RiskRadioContainer>
      <RiskRadioContainer>
        <Form.Check
          inline
          label={RiskMathLabels.HIGH}
          name={labelText}
          type="radio"
          id={`risk-radio-item-${labelText}-${RiskMathLabels.HIGH}`}
          onClick={() => onSelectCb(RISK_MATH.HIGH)}
        />
      </RiskRadioContainer>
      <RiskRadioContainer>
        <Form.Check
          inline
          label={RiskMathLabels.VERY_HIGH}
          name={labelText}
          type="radio"
          id={`risk-radio-item-${labelText}-${RiskMathLabels.VERY_HIGH}`}
          onClick={() => onSelectCb(RISK_MATH.VERY_HIGH)}
        />
      </RiskRadioContainer>
    </RiskMathRadioContainer>
  </RiskMathRadioButtonRow>
)
