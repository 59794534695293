import React, { CSSProperties, useReducer } from 'react'
import DropdownItem from 'react-bootstrap/DropdownItem'
import { Department } from '@trustero/trustero-api-web/lib/model/department_pb'
import { useHasEditPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  BasicChip,
  BasicChipProps,
} from '../../components/Reusable/Chips/BasicChip/BasicChip'
import { Spinner } from '../../Throbber'
import { DropdownChip } from '../../components/Reusable/Chips/DropdownChip'
import { useDepartmentIds } from '../../components/async/Department/useDepartmentIds'
import { useDepartment } from '../../components/async/Department/useDepartment'
import { GrpcResponse } from '../../components/async/hooks/types'

type Props = BasicChipProps & {
  departmentId?: string
  onChanged?: (id: string) => void
  style?: CSSProperties | undefined
  allowEmpty?: boolean
}

const Empty = () => <BasicChip>-</BasicChip>

export const DepartmentChip = ({
  departmentId,
  onChanged,
  style,
  allowEmpty,
  ...basicChipProps
}: Props): JSX.Element => {
  const hasEditPermissions = useHasEditPermissions()
  const [isLoading, toggleLoading] = useReducer((p) => !p, false)
  const department = useDepartment(departmentId ?? '')
  if (departmentId === undefined || isLoading) {
    return <Spinner size="s" color="primary" />
  }
  if ((departmentId.length === 0 || department === undefined) && !allowEmpty) {
    return <Empty />
  }
  const title = department?.getShortName() || '-'
  if (!onChanged) {
    return <BasicChip {...basicChipProps}>{title}</BasicChip>
  }

  return (
    <DropdownChip
      title={title}
      tooltipId={`dropdown-chip-tooltip-${title}`}
      style={style}
      tooltipText={
        hasEditPermissions
          ? 'Edit Department'
          : 'You do not have permission to edit department'
      }
      disabled={!hasEditPermissions}
    >
      <MenuOptions
        onChanged={async (id) => {
          toggleLoading()
          await onChanged(id)
          toggleLoading()
        }}
      />
    </DropdownChip>
  )
}

type MenuOptionsProps = {
  onChanged: (id: string) => void
}

const MenuOptions = (props: MenuOptionsProps): JSX.Element => {
  const departmentIds = useDepartmentIds()

  if (departmentIds === undefined) {
    return <Spinner size="s" color="primary" />
  }

  return (
    <>
      {departmentIds.data?.map((p, idx) => (
        <MenuOption
          key={p}
          id={p}
          idx={idx}
          onChanged={() => props.onChanged(p)}
        />
      ))}
    </>
  )
}

type MenuOptionProps = {
  id: string
  idx: number
  onChanged: () => void
}

const MenuOption = (props: MenuOptionProps): JSX.Element => {
  const department = useDepartment(props.id)
  if (department === undefined) {
    return <Spinner size="s" color="primary" />
  }

  return (
    <DropdownItem
      as="li"
      tabIndex={props.idx}
      onClick={async (e) => {
        e.preventDefault()
        await props.onChanged()
      }}
    >
      {department.getName()}
    </DropdownItem>
  )
}

export const DepartmentChipWithDepartment = ({
  department,
}: {
  department: GrpcResponse<Department>
}): JSX.Element =>
  department.data ? (
    <BasicChip>{department.data.getShortName()}</BasicChip>
  ) : (
    <>
      {department.loading ? (
        <Spinner color={'primary'} size={'m'} />
      ) : (
        <Empty />
      )}
    </>
  )
