import React, { useContext, useState } from 'react'
import { SendSignInEmailRequest } from '@trustero/trustero-api-web/lib/account/account_pb'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import log from 'loglevel'
import queryString from 'query-string'
import isArray from 'lodash/isArray'
import AuthContext from '../../../context/authContext'
import { NTRCE_API_URL } from '../../../adapter/gRpcAdapter'
import { ChevronSVG, KeySVG } from '../../../components/Icons/Basic'
import {
  BackButton,
  ConfirmButton,
  EmailAuthForm,
  EmailFieldSet,
  EmailInput,
  ResponseMessage,
  StartFlow,
} from './EmailLogin.styles'

export enum FormStage {
  START,
  INPUT,
  ERROR,
  SUCCESS,
}

export const EmailLogin = ({
  originalURL = '',
}: {
  originalURL?: string
}): JSX.Element => {
  const { authCtx } = useContext(AuthContext)
  const [email, setEmail] = useState(authCtx?.email || '')
  const [formStage, formStageSet] = useState<FormStage>(FormStage.START)
  const AccountClient = new AccountPromiseClient(NTRCE_API_URL)

  const sendLoginEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    switch (formStage) {
      case FormStage.INPUT: {
        try {
          const searchParams = queryString.parse(window.location.search)
          const redirect = searchParams.redirect_url || searchParams.redirect
          const redirectUrl = isArray(redirect)
            ? redirect[0]
            : redirect || originalURL
          const request = new SendSignInEmailRequest()
            .setEmail(email)
            .setRedirectUrl(redirectUrl)
          await AccountClient.sendSignInEmail(request)
          setEmail('')
          formStageSet(FormStage.SUCCESS)
        } catch (e) {
          log.error('Error in EmailLogin', e)
          formStageSet(FormStage.ERROR)
        }
        break
      }
      case FormStage.SUCCESS: {
        setEmail('')
        formStageSet(FormStage.START)
        break
      }
      case FormStage.START:
      case FormStage.ERROR:
      default: {
        formStageSet(FormStage.INPUT)
        break
      }
    }
  }

  return (
    <EmailAuthForm onSubmit={sendLoginEmail}>
      {formStage === FormStage.START && (
        <StartFlow type="submit">
          <KeySVG />
          Send me an Access Link
        </StartFlow>
      )}

      {formStage !== FormStage.START && (
        <EmailFieldSet formStage={formStage}>
          {formStage === FormStage.INPUT ? (
            <>
              <BackButton
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  formStageSet(FormStage.START)
                }}
              >
                <ChevronSVG />
              </BackButton>
              <EmailInput
                type="text"
                placeholder="Enter Email Address"
                value={email}
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </>
          ) : (
            <ResponseMessage formStage={formStage} />
          )}
          <ConfirmButton type="submit" formStage={formStage} />
        </EmailFieldSet>
      )}
    </EmailAuthForm>
  )
}
