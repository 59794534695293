import React from 'react'
import styled from 'styled-components/macro'
import {
  compose,
  space,
  border,
  color,
  textStyle,
  TextStyleProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import { ChevronSVG } from '../components/Icons/Basic'

const StyledModal = styled(ModalDialog)`
  font-family: Poppins, sans-serif;

  .modal-content {
    padding: ${themeGet('space.xxl')}px ${themeGet('space.xxl')}px
      ${themeGet('space.xl')}px;
    box-shadow: ${themeGet('shadows.outer.high')};
    border: none;
    border-radius: 4px;
  }

  &.modal-dialog {
    @media (min-width: 576px) {
      &.modal-sm {
        max-width: 500px;
      }
    }
  }
`

export function ModalContainer({
  children,
  ...props
}: ModalProps): JSX.Element {
  return (
    <Modal {...props} dialogAs={StyledModal}>
      {children}
    </Modal>
  )
}

export const ModalHeader = styled.header.attrs({
  mb: 'l',
})`
  ${space}
`

export const ModalTitle = styled.h2.attrs({
  m: 0,
  textStyle: 'header.2',
})`
  ${compose(space, textStyle)}
  color: black;
`

export const ModalDescription = styled.article.attrs({
  mt: 'm',
  textStyle: 'body.default',
})`
  ${compose(space, textStyle)}
`

export const ModalBody = styled.article.attrs({
  className: 'modal-body',
  mb: 'm',
})<TextStyleProps>`
  padding: 0;
  ${compose(space, border, textStyle)}
`

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`

export const ModalButtonGroup = styled.div.attrs({
  mr: 'm',
})`
  display: flex;

  &:last-child {
    margin-left: auto;
  }

  > *:not(:only-child):not(:last-child) {
    ${space}
  }
`

export const ModalBack = styled.a.attrs({
  p: 0,
  mb: 'xs',
  color: 'text.default',
  textStyle: 'subtitle.1',
})`
  ${compose(space, color, textStyle)}
  width: fit-content;
  height: 24px;
  border: none;
  background-color: white;

  ::after {
    content: 'Back';
    margin-left: ${(props) => `${themeGet('space.xxs')(props)}px`};
  }
`

export const BackIcon = styled(ChevronSVG).attrs({
  fill: 'fill.tertiary.dark',
})`
  transform: rotate(180deg);
`
