import styled from 'styled-components/macro'
import palette from '../../../designSystem/variables/palette'

export const PinkInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${palette.magenta[400]};
  padding: 12px;
  font-size: 14px;
  margin-bottom: 20px;
`
export const PinkInfoBoxHeader = styled.div`
  display: flex;
  color: ${palette.magenta[400]};
  font-weight: 500;
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
    path {
      fill: ${palette.magenta[400]};
    }
  }
`
export const PinkInfoBoxBody = styled.div`
  display: flex;
  font-weight: 400;
  font-style: italic;
`

export const PinkInfoBoxBodyColumn = styled.div`
  display: flex;
  font-weight: 400;
  font-style: italic;
  flex-direction: column;
  gap: 8px;
`
