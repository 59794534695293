import { EroDarkEastSVG } from 'src/components/Icons/Brand'
import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { GridHeader } from 'src/components/Reusable/Grid'
import styled, { css } from 'styled-components/macro'

export const RequestGridHeader = styled(GridHeader)<{ sticky: boolean }>`
  ${({ sticky }) => {
    return (
      sticky &&
      css`
        position: sticky;
        top: ${headerHeight + filterBarHeight};
        z-index: 1;
      `
    )
  }}
`

export const SuggestedEvidenceContainer = styled.div`
  display: flex;
  gap: 1em;
`

export const SuggestedEvidenceButtons = styled.div`
  display: flex;
  flex-direction: column;
`

export const SuggestedEvidenceEroIcon = styled(EroDarkEastSVG)`
  height: 32px;
`
