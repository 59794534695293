import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, GridColumn, GridHeader } from 'src/components/Reusable/Grid'
import { headerContentHeight } from 'src/components/PageLayout/dimensionUtils'
import palette from 'src/designSystem/variables/palette'
import {
  AuditsSVG,
  CheckSVG,
  CircleCheckFillSVG,
  ControlsSVG,
  ErrorSVG,
  PoliciesSVG,
  XSVG,
  QuestionMarkSVG,
} from 'src/components/Icons/Basic'
import { Spinner } from 'src/Throbber'
import { SectionAccordion } from 'src/components/Reusable/Accordions/ShowPage/Sections/SectionAccordion.styles'
import { EroDarkWordmarkSVG } from 'src/components/Icons/Brand'
import { ReactComponent as NoAuditBotRunsSVG } from '../../components/Icons/EmptyStateIcons/no_auditbot_runs_icon.svg'

export const NoAuditBotRunsIcon = styled(NoAuditBotRunsSVG)`
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AuditBotGrid = styled(Grid).attrs({
  pb: 'xs',
  gridTemplateColumns:
    'min-content repeat(2, 1fr) 2fr repeat(2, 1fr) repeat(3, max-content)',
})``

export const AUDIT_BOT_HEADERS = Object.freeze({
  INDICATOR: '', // Empty column for status indicator
  AUDIT: 'Context',
  CONTROL_COUNT: 'Control Count',
  CONTROL: 'Control',
  DOCUMENTS: 'Documents',
  CONTROLS: 'Controls Check',
  OWNER: '', // Empty column for gravatar
  TIMESTAMP: 'Date',
  DELETE: '', // Empty column for delete icon
})

export const StyledAuditBotGridHeader = styled(GridHeader).attrs({
  top: headerContentHeight + 80,
})`
  position: sticky;
  z-index: 1;
`

export const AuditbotSectionHeader = styled.h6`
  color: ${palette.blue[700]};
  margin-bottom: 1em;
`

export const StatusGridColumn = styled(GridColumn)`
  justify-content: center;
  overflow: hidden;
`

export const AuditBotPassIcon = styled(CircleCheckFillSVG)`
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
`

export const AuditBotFailIcon = styled(ErrorSVG)`
  height: 18px;
  width: 18px;
  position: relative;
  left: -1px;
  path {
    fill: ${palette.magenta[500]};
  }
`

export const AuditBotNAControlCheckIcon = styled(CircleCheckFillSVG)`
  height: 16px;
  width: 16px;
  path {
    fill: ${palette.neutral[200]};
  }
`

export const AuditBotMissingGuidanceIcon = styled(ErrorSVG)`
  height: 18px;
  width: 18px;
  path {
    fill: ${palette.neutral[200]};
  }
`

export const AuditBotNotScannedIcon = styled(ErrorSVG)`
  height: 18px;
  width: 18px;
  position: relative;
  left: -1px;
  path {
    fill: ${palette.neutral[500]};
  }
`

export const AuditBotUnknownIcon = styled(ErrorSVG)`
  height: 18px;
  width: 18px;
  position: relative;
  left: -1px;
  path {
    fill: ${palette.orange[600]};
  }
`

export const AuditBotCheckIcon = styled(CheckSVG)`
  width: 16px;
  height: 16px;
  padding-bottom: 1px;
`

export const AuditBotNAIcon = styled(CheckSVG)`
  width: 16px;
  height: 16px;
  padding-bottom: 1px;
  path {
    fill: ${palette.neutral[300]};
  }
`

export const AuditBotXIcon = styled(XSVG)`
  height: 12px;
  padding-bottom: 1px;
  width: 16px;
  align-items: center;
  justify-content: center;
  path {
    fill: ${palette.magenta[500]};
  }
`

export const AuditBotUnknownXIcon = styled(XSVG)`
  height: 12px;
  padding-bottom: 1px;
  width: 16px;
  align-items: center;
  justify-content: center;
  path {
    fill: ${palette.orange[600]};
  }
`

export const AuditBotMissingGuidanceControlIcon = styled(AuditBotXIcon)`
  path {
    fill: ${palette.neutral[200]};
  }
`

export const AuditBotQuestionIcon = styled(QuestionMarkSVG)`
  width: 16px;
  height: 16px;
  padding-bottom: 1px;
  path {
    fill: ${palette.orange[600]};
  }
`

export const AuditBotGridReadySection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const AuditBotStyledSectionAccordion = styled(SectionAccordion)`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

export const AuditBotGridSpinner = styled(Spinner)`
  margin-left: 0.125rem;
`

export const EroDarkWordmarkIcon = styled(EroDarkWordmarkSVG)`
  height: 12.5rem;
`

export const AuditBotProgressBarContainer = styled.div`
  align-self: center;
  width: 120px;
`

export const ScanTimestamp = styled.p`
  font-size: 14px;
  line-height: 150%;
  color: ${palette.neutral[600]};
`

export const EmptyTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  background-color: ${palette.neutral[30]};

  p {
    margin: 1em 0;
  }

  button {
    margin-bottom: 1em;
  }
`

export const AuditRunHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  span {
    margin-right: 0.5em;

    &:nth-child(2) {
      margin-left: 0.5em;
      font-weight: 600;
    }
  }
`

export const AuditScanHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  [class^='DateFilterContainer-'] {
    position: relative;
  }
`

export const AuditBotCancellingIcon = styled(FontAwesomeIcon)`
  margin: 0 0.25em;
  path {
    fill: ${palette.magenta[400]};
  }
`

export const AuditBotCancelledIcon = styled(FontAwesomeIcon)`
  margin: 0 0.1em;
  height: 15px;
  width: 15px;
  path {
    fill: ${palette.neutral[200]};
  }
`
export const AuditBotCancellingIconLarge = styled(FontAwesomeIcon)`
  width: 24px;
  height: 48px;
  path {
    fill: ${palette.magenta[400]};
  }
`

export const AuditBotCancelledIconLarge = styled(FontAwesomeIcon)`
  width: 24px;
  height: 48px;
  path {
    fill: ${palette.neutral[200]};
  }
`

export const AuditPrecheckReadyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 0 0.5em 0.25em;

  h5 {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 1.3em;
    padding-top: 0.25em;
    font-size: 20px;
  }
`

export const AuditSummaryReadyIcon = styled(AuditsSVG)`
  path {
    fill: ${palette.neutral[600]};
  }
`

export const AuditPrecheckReadyIcon = styled(PoliciesSVG)`
  path {
    fill: ${palette.neutral[600]};
  }
`

export const AuditControlsReadyIcon = styled(ControlsSVG)`
  path {
    fill: ${palette.neutral[600]};
  }
`

export const AuditScanInfoContainer = styled.div`
  margin: 16px 0 5px;
`
