import React from 'react'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { AuditBotNAIcon } from 'src/pages/AuditBot/AuditBot.styles'
import { AUDIT_RESULT } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import {
  CHECK_RESULT_TYPE,
  getCheckResultIcon,
} from 'src/pages/AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.helpers'
import { FlexAlign, FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import {
  CONTROL_CHECK_TOOLTIP_TEXT,
  ControlCheckConfigType,
  ControlListItemConfig,
  ControlListItemConfigMap,
  ControlListItemConfigurations,
} from './ControlListItem.constants'

export const getComplianceFrameworksForControl = (
  complianceFrameworks: ComplianceFramework[],
  currentIdList: string[],
): ComplianceFramework[] =>
  complianceFrameworks.filter((framework: ComplianceFramework) =>
    currentIdList?.includes(framework.getId()),
  ) || []

export const getMismatch = (
  complianceFrameworkIdsList: string[],
  complianceFrameworksInUse: ComplianceFramework[],
  hasAudit: boolean,
): boolean => {
  if (!hasAudit || !complianceFrameworkIdsList.length) {
    return false
  }
  return !complianceFrameworksInUse.some((framework) =>
    complianceFrameworkIdsList.includes(framework.getModelId()),
  )
}

export const getControlsListItemConfig = (
  config?: ControlListItemConfig,
): ControlListItemConfigMap =>
  config
    ? ControlListItemConfigurations[config]
    : ControlListItemConfigurations[ControlListItemConfig.DEFAULT]

export const getNoCheckResultConfig = (
  controlId: string,
  isNotApplicable: boolean,
): ControlCheckConfigType => {
  const icon = isNotApplicable ? <AuditBotNAIcon /> : <>-</>
  const tooltipBody = isNotApplicable ? 'Not Applicable' : 'Not Scanned'
  return {
    tooltipId: `controls-index-row-checkresult-tooltip-${controlId}`,
    icon,
    tooltipBody,
    isStale: false,
  }
}

export const getCheckResultConfig = (
  controlId: string,
  result: AUDIT_RESULT,
  isStale: boolean,
): ControlCheckConfigType => {
  const icon = getCheckResultIcon(result, CHECK_RESULT_TYPE.INDIVIDUAL)
  const isStaleText = isStale ? 'Outdated Result' : ''
  const tooltipBody = (
    <FlexColumn align={FlexAlign.FLEX_START}>
      <FlexRow>{CONTROL_CHECK_TOOLTIP_TEXT[result]}</FlexRow>
      <FlexRow>{isStaleText}</FlexRow>
    </FlexColumn>
  )
  return {
    tooltipId: `controls-index-row-checkresult-tooltip-${controlId}`,
    icon,
    tooltipBody,
    isStale,
  }
}
