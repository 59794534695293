import { useCallback } from 'react'
import log from 'loglevel'
import {
  CountDocumentRequestsResponse,
  CreateOrUpdateDocumentRequestRequest,
  DeleteDocumentRequestRequest,
  DocumentRequest,
  DocumentRequests,
  ListDocumentRequestsRequest,
} from '@trustero/trustero-api-web/lib/request/request_pb'
import { RequestPromiseClient } from '@trustero/trustero-api-web/lib/request/request_grpc_web_pb'
import {
  Int32Value,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { toast } from 'react-toastify'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { EvidenceGroupId } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { NONE_ID } from 'src/Utils/globalConstants'
import { useInvalidateSubscriptionCache } from 'src/global.hooks/notification.hooks'
import { useGrpcRevalidateByMethod } from '../useGrpcMutate'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { dateToTimestamp } from '../../../Utils/formatDate'
import { useAuthorizedGrpcClient } from '../../../adapter'
import { useInAudit } from '../../../context/AuditContext'
import { ImportRiskCSVRow } from '../../ModalForms/Requests/AddRequestBulkUpload/AddRequestBulkUpload'
import { useListMember } from '../hooks/useListMember'
import { ToastPrompts } from '../../../Utils/helpers/toast'

export const useInvalidateDocumentRequestsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(RequestPromiseClient.prototype.listDocumentRequests),
      mutateFunc(RequestPromiseClient.prototype.countDocumentRequests),
      mutateFunc(ModelPromiseClient.prototype.listControls),
    ])
  }, [mutateFunc])
}

type UseAddOrUpdateDocumentRequestParams = {
  id?: string
  request?: string
  controlIds?: string[]
  dueDate?: Date
  status?: number
  ownerId?: string
  unlinkControlId?: string
}

export const useAddOrUpdateDocumentRequest = (): (({
  id,
  request,
  controlIds,
  dueDate,
  status,
  ownerId,
  unlinkControlId,
}: UseAddOrUpdateDocumentRequestParams) => Promise<DocumentRequest>) => {
  const mutator = useInvalidateDocumentRequestsCache()
  const subscriptionMutator = useInvalidateSubscriptionCache()
  const requestClient = useAuthorizedGrpcClient(RequestPromiseClient)
  const { auditId } = useInAudit()

  return async ({
    id,
    request,
    controlIds,
    dueDate,
    status,
    ownerId,
    unlinkControlId,
  }: UseAddOrUpdateDocumentRequestParams): Promise<DocumentRequest> => {
    const createOrUpdateRequest = new CreateOrUpdateDocumentRequestRequest()

    if (id) {
      createOrUpdateRequest.setId(new StringValue().setValue(id))
    }
    if (request) {
      createOrUpdateRequest.setRequest(new StringValue().setValue(request))
    }
    if (auditId) {
      createOrUpdateRequest.setAuditId(new StringValue().setValue(auditId))
    }
    if (controlIds) {
      createOrUpdateRequest.setControlIds(
        new StringList().setItemList(controlIds),
      )
    }
    if (dueDate) {
      const dueDateTimestamp = dateToTimestamp(dueDate)
      createOrUpdateRequest.setDueDate(dueDateTimestamp)
    }
    // check if status is undefined, because 0 is falsey
    if (status !== undefined) {
      createOrUpdateRequest.setStatus(new Int32Value().setValue(status))
    }
    if (ownerId) {
      if (ownerId === NONE_ID) {
        createOrUpdateRequest.setOwnerId(new StringValue().setValue(''))
      } else {
        createOrUpdateRequest.setOwnerId(new StringValue().setValue(ownerId))
      }
    }
    if (unlinkControlId) {
      createOrUpdateRequest.setUnlinkControlId(
        new StringValue().setValue(unlinkControlId),
      )
    }

    let response = new DocumentRequest()
    try {
      response = await requestClient.createOrUpdateDocumentRequest(
        createOrUpdateRequest,
      )
      await mutator()
      await subscriptionMutator()
    } catch (e) {
      log.error(
        'error creating document request in useAddDocumentRequest hook',
        e,
      )
      if (controlIds || unlinkControlId) {
        toast(ToastPrompts.LINK_CONTROL_TO_REQUEST)
      }
    }
    return response
  }
}

export const useBulkRequestUpload = (): ((
  requestsData: ImportRiskCSVRow[],
) => void) => {
  const mutator = useInvalidateDocumentRequestsCache()
  const requestClient = useAuthorizedGrpcClient(RequestPromiseClient)
  const { auditId } = useInAudit()

  return useCallback(
    async (requestsData: ImportRiskCSVRow[]): Promise<void> => {
      if (!auditId) {
        log.error('missing auditId in useBulkRequestUpload')
        toast('Request can only be added from within an audit')
        return
      }
      try {
        await Promise.all(
          requestsData.map(
            ({ request, dueDate, controlIds }: ImportRiskCSVRow) => {
              const uploadRequest = new CreateOrUpdateDocumentRequestRequest()

              uploadRequest.setAuditId(new StringValue().setValue(auditId))
              uploadRequest.setRequest(new StringValue().setValue(request))
              dueDate && uploadRequest.setDueDate(dateToTimestamp(dueDate))
              uploadRequest.setControlIds(
                new StringList().setItemList(controlIds),
              )
              uploadRequest.setControlModelIds(true)
              return requestClient.createOrUpdateDocumentRequest(uploadRequest)
            },
          ),
        )
      } catch (error) {
        log.error('error when making bulk request upload via CSV', error)
        toast('Upload failed. Please check formatting and try again.')
      }

      mutator()
      return
    },
    [auditId, mutator, requestClient],
  )
}

export const useDocumentRequest = (
  id: string,
  shouldFetch = true,
): GrpcResponse<DocumentRequest> => {
  const getRequest = new ListDocumentRequestsRequest()
  const response = useListMember(
    {
      id,
      asyncCall: RequestPromiseClient.prototype.listDocumentRequests,
      request: getRequest,
      newCollection: () => new DocumentRequests(),
    },
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useDocumentRequests = ({
  request,
  controlId,
  evidenceGroupId,
  shouldFetch = true,
}: {
  request: ListDocumentRequestsRequest
  controlId?: string
  evidenceGroupId?: EvidenceGroupId
  shouldFetch?: boolean
}): GrpcResponse<DocumentRequests> => {
  const { auditId } = useInAudit()
  controlId && request.setControlId(new StringValue().setValue(controlId))
  auditId && request.setAuditId(new StringValue().setValue(auditId))
  evidenceGroupId && request.setEvidenceGroupId(evidenceGroupId)
  const { response } = useSwrImmutableGrpc(
    RequestPromiseClient.prototype.listDocumentRequests,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useDeleteDocumentRequest = (id: string): (() => Promise<void>) => {
  const mutator = useInvalidateDocumentRequestsCache()
  const { auditId } = useInAudit()
  const deleteRequest = new DeleteDocumentRequestRequest().setId(id)
  if (auditId) {
    deleteRequest.setAuditId(auditId)
  }
  const requestClient = useAuthorizedGrpcClient(RequestPromiseClient)
  return async () => {
    try {
      await requestClient.deleteDocumentRequest(deleteRequest)
      await mutator()
    } catch (e) {
      log.error(
        'error deleting document request in useDeleteDocumentRequest hook',
        e,
      )
    }
  }
}

export const useDocumentRequestCount = (
  auditId: string,
  shouldFetch = true,
): GrpcResponse<CountDocumentRequestsResponse> => {
  const countRequest = new ListDocumentRequestsRequest().setAuditId(
    new StringValue().setValue(auditId),
  )
  const { response } = useSwrImmutableGrpc(
    RequestPromiseClient.prototype.countDocumentRequests,
    countRequest,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}
