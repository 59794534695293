import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import queryString, { ParsedQuery } from 'query-string'
import { NONE_ID } from 'src/Utils/globalConstants'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  ControlSort,
  DepartmentFilter,
  ListControlsRequest,
  PolicyFilter,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { FRAMEWORK_MODEL_IDS } from 'src/Utils/globalEnums'
import { applyFrameworkFilter } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.utils'
import {
  CONTROL_CHECK_SORT_COLUMN,
  ControlCheckSort,
  GetAuditReadinessRequest,
  TestResultFilter,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { SmartCheckFilterValues } from 'src/components/Reusable/IndexPage/FilterBar/FilterDropdowns/SmartChecksFilterDropdown'
import { ControlStatus, titleToControlStatus } from '../../../lib/common/types'
import {
  ControlsSortPrecedence,
  GridColumnSortControls,
  GridColumnSortType,
  SORT_ORDER,
} from '../../../components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { FilterParam } from '../../../components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { CONTROL_SORT_COLUMNS } from './ControlsIndexGrid/ControlsIndexPage.constants'
import { NO_EMAIL_OWNER } from './ControlsIndexPage.constants'

const applyFilter = (
  listCtrlsReq: ListControlsRequest,
  auditbotRequest: GetAuditReadinessRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
) => {
  const filterParams = queryParams[filterType] as string[]
  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.DEPARTMENT: {
      const departmentFilter = new DepartmentFilter()
      const departmentIds = new Set(filterParams)
      if (departmentIds.has(NONE_ID)) {
        departmentIds.delete(NONE_ID)
        departmentFilter.setIncludeNoDepartment(new BoolValue().setValue(true))
      }
      departmentFilter.setDepartmentIdsList(Array.from(departmentIds))
      listCtrlsReq.setDepartmentFilter(departmentFilter)
      break
    }
    case FilterParam.POLICY: {
      const policyFilter = new PolicyFilter()
      const policyIds = new Set(filterParams)
      if (policyIds.has(NONE_ID)) {
        policyIds.delete(NONE_ID)
        policyFilter.setIncludeNoPolicy(new BoolValue().setValue(true))
      }
      policyFilter.setPolicyModelIdsList(Array.from(policyIds))
      listCtrlsReq.setPolicyFilter(policyFilter)
      break
    }
    case FilterParam.OWNER: {
      const emails = [...filterParams]
      const idx = emails.findIndex(
        (email) => email?.toLowerCase() === NO_EMAIL_OWNER,
      )
      if (idx > -1) {
        emails.splice(idx, 1)
        listCtrlsReq.setOwnersList(emails)
        listCtrlsReq.setIncludeUnassigned(new BoolValue().setValue(true))
      } else {
        listCtrlsReq.setOwnersList(emails)
      }
      break
    }
    case FilterParam.SOC2: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.SOC2,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.SOC1: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.SOC1,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.ISO: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.ISO27001,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.NISTCSF: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.NISTCSF,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.PCISAQA: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.PCISAQA,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.PCISAQD: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.PCISAQD,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.HITRUST: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.HITRUST,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.HIPAA: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.HIPAA,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.NYDFS: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.NYDFS,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.ISO27701_DC: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.ISO27701_DC,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.ISO27701_DP: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.ISO27701_DP,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.GDPR_DC: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.GDPR_DC,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.GDPR_DP: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.GDPR_DP,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.PIPEDA_DC: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.PIPEDA_DC,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.PIPEDA_DP: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.PIPEDA_DP,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.TEST: {
      applyFrameworkFilter(
        listCtrlsReq,
        FRAMEWORK_MODEL_IDS.TEST,
        filterParams,
        MODEL_TYPE.CONTROL,
      )
      break
    }
    case FilterParam.AUDIT:
      listCtrlsReq.setAuditIdsList(filterParams)
      break
    case FilterParam.COMPLIANCE_FRAMEWORK:
      listCtrlsReq.setComplianceFrameworkIdsList(filterParams)
      break
    case FilterParam.STATUS:
      listCtrlsReq.setStatusesList(
        (filterParams as ControlStatus[])?.map(
          (param) => titleToControlStatus[param],
        ),
      )
      break
    case FilterParam.SMART_CHECKS: {
      const aiFilter = new TestResultFilter()
      const filters = new Set(filterParams)
      aiFilter.setIncludeFailing(filters.has(SmartCheckFilterValues.FAILING))
      aiFilter.setIncludePassing(filters.has(SmartCheckFilterValues.PASSING))
      aiFilter.setIncludeStale(filters.has(SmartCheckFilterValues.STALE))
      auditbotRequest.setTestResultFilter(aiFilter)
      break
    }
    default:
      break
  }
}

const isControlChecksSort = (sortCol: GridColumnSortControls) =>
  sortCol === GridColumnSortControls.POLICY_MATCH ||
  sortCol === GridColumnSortControls.COMPLETENESS ||
  sortCol === GridColumnSortControls.SPOT_CHECK

/**
 * This will help pull the URL search params for sortControls with the sort_by and sort_col properties
 * If for any reason no matching values are found, we will default to using a DESC A-Z, 0-9 sort by the Name
 * Column.
 *
 * @param listCtrlsReq: gRPC request we will append the sortControls to
 * @param queryParams: object of URL search params, contains key for each column name
 */
const applySort = (
  listCtrlsReq: ListControlsRequest,
  auditbotRequest: GetAuditReadinessRequest,
  queryParams: ParsedQuery<string>,
): ControlsSortPrecedence => {
  let sortPrecedence = ControlsSortPrecedence.CONTROLS
  // This will only handle a single parameter supplied to this field in the URL
  // Might be a bit brittle if user starts manipulating URLs as is - long term we may want to move to a solution where
  // filter and sort information is passed along into the request itself to avoid using URLs
  const sortBy =
    ((queryParams.sort_by && queryParams.sort_by[0]) as GridColumnSortType) ||
    GridColumnSortType.ASCENDING
  const sortCol =
    ((queryParams.sort_col &&
      queryParams.sort_col[0]) as GridColumnSortControls) ||
    GridColumnSortControls.ID

  if (isControlChecksSort(sortCol)) {
    const req = new ControlCheckSort()
      .setSortOrder(SORT_ORDER[sortBy])
      .setSortColumn(CONTROL_SORT_COLUMNS[sortCol] as CONTROL_CHECK_SORT_COLUMN)
    auditbotRequest.setSort(req)
    sortPrecedence = ControlsSortPrecedence.CONTROL_CHECKS
  } else {
    const req = new ControlSort()
      .setSortOrder(SORT_ORDER[sortBy])
      .setSortColumn(
        CONTROL_SORT_COLUMNS[sortCol] as ControlSort.control_sort_column,
      )
    listCtrlsReq.setSortControls(req)
    sortPrecedence = ControlsSortPrecedence.CONTROLS
  }

  return sortPrecedence
}

/**
 * Adds Filter & Sorting information for Controls to pass in a gRPC request
 *
 * @param locSearch
 * @returns ListControlsRequest
 */
export const getControlsGridFilterRequest = (
  locSearch: string,
): ControlsRequests => {
  const queryParams: ParsedQuery<string> = queryString.parse(locSearch, {
    arrayFormat: 'bracket',
  })
  const listCtrlsReq: ListControlsRequest =
    new ListControlsRequest().setIsDismissed(
      new BoolValue().setValue(
        JSON.parse(
          queryParams.dismissed?.[0] &&
            (queryParams.dismisssed?.[0] === 'false' ||
              queryParams.dismissed?.[0] === 'true')
            ? queryParams.dismissed[0]
            : 'false',
        ),
      ),
    )
  const auditbotRequest = new GetAuditReadinessRequest()
  Object.values(FilterParam).forEach((filterType) =>
    applyFilter(listCtrlsReq, auditbotRequest, queryParams, filterType),
  )
  const precedence = applySort(listCtrlsReq, auditbotRequest, queryParams)
  return { controls: listCtrlsReq, auditbot: auditbotRequest, precedence }
}

type ControlsRequests = {
  controls: ListControlsRequest
  auditbot: GetAuditReadinessRequest
  precedence: ControlsSortPrecedence
}

export const useControlRequests = (): ControlsRequests => {
  const location = useLocation()
  return useMemo(
    () => getControlsGridFilterRequest(location.search),
    [location.search],
  )
}
