import React, { useCallback, useEffect, useState } from 'react'
import isFunction from 'lodash/isFunction'
import { useDropzone } from 'react-dropzone'
import { ModalFormId } from 'src/components/ModalForms'
import { useModalState } from 'src/Modal/ModalStateContext'
import { useDragAndDrop } from 'src/context/DragAndDrop/DragAndDrop.context'
import { TypeSelectorContainer } from 'src/components/ModalForms/FileType/TypeSelector.styles'
import { FileContainer, FileSection } from './FileSelector.styles'
import {
  DEFAULT_ACCEPTED_MIME_TYPES,
  MAX_UPLOAD_BYTES,
} from './FileSelector.constants'
import { correctAppleFiles, validateFiles } from './FileSelector.helpers'
import {
  FileClearButton,
  FileSelectorError,
  FileUploadDisplay,
} from './FileSelector.components'

type FileSelectorProps = {
  onChange: (files: File[]) => void
  isLoading: boolean
  isValid: boolean
  errorMessage: string
  modalId?: ModalFormId
  accept?: string[]
  files?: File[]
  text?: string
  onClear?: () => void
  allowMultiple?: boolean
  alternateTypeOptions?: JSX.Element[]
}

export const FileSelector = ({
  onChange,
  isLoading,
  isValid,
  accept = DEFAULT_ACCEPTED_MIME_TYPES,
  errorMessage,
  modalId,
  files,
  text,
  onClear,
  allowMultiple = false,
  alternateTypeOptions = [],
}: FileSelectorProps): JSX.Element => {
  const [fileError, setFileError] = useState<string | null>(null)
  const { files: contextFiles, clearFiles } = useDragAndDrop()
  const { openModal } = useModalState()

  const onDrop = useCallback(
    (droppedFiles: File[]) => {
      if (modalId) {
        openModal(modalId)
      }

      const allFiles = correctAppleFiles(droppedFiles, accept)

      // Run custom validation
      const { validFiles, errors } = validateFiles(
        allFiles,
        accept,
        MAX_UPLOAD_BYTES,
      )

      if (errors) {
        setFileError(errors)
      } else {
        setFileError(null)
        isFunction(onChange) && onChange(validFiles)
      }
    },
    [modalId, accept, openModal, onChange],
  )

  // Process files from the context when they change
  useEffect(() => {
    if (contextFiles.length > 0) {
      onDrop(contextFiles) // Handle the files from the context
      clearFiles() // Clear files from the context after processing
    }
  }, [contextFiles, onDrop, clearFiles])

  const acceptObject = accept.reduce((acc, mimeType) => {
    acc[mimeType] = []
    return acc
  }, {} as Record<string, string[]>)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptObject,
    multiple: allowMultiple,
  })

  const fileText = allowMultiple ? 'files' : 'file'
  const dropText = isDragActive
    ? `Drop the ${fileText} here...`
    : text || 'Select File'
  const showClear = !!(files && files.length > 0 && isFunction(onClear))
  const showErrorMessage = fileError ? fileError : isValid ? '' : errorMessage

  return (
    <FileSection>
      {alternateTypeOptions.length ? (
        <TypeSelectorContainer {...getRootProps()} isDragOver={isDragActive}>
          <>
            {isDragActive ? (
              <div>{dropText}</div>
            ) : (
              <FileUploadDisplay
                isLoading={isLoading}
                files={files}
                dropText={dropText}
                getInputProps={getInputProps}
                options={alternateTypeOptions}
              />
            )}
          </>
        </TypeSelectorContainer>
      ) : (
        <FileContainer
          {...getRootProps()}
          isDragOver={isDragActive}
          disableHover={!!alternateTypeOptions.length}
        >
          <FileUploadDisplay
            isLoading={isLoading}
            files={files}
            dropText={dropText}
            getInputProps={getInputProps}
            options={alternateTypeOptions}
          />
        </FileContainer>
      )}
      <FileClearButton onClick={onClear} showClear={showClear} />
      <FileSelectorError errorMessage={showErrorMessage} />
    </FileSection>
  )
}
