import React from 'react'
import { PageHeader } from 'src/components/PageLayout/PageHeader'
import { ModalFormId } from 'src/components/ModalForms'
import { IndexPage } from 'src/components/Reusable/IndexPage'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { InfoSection } from 'src/components/Reusable/IndexPage/InfoSection'
import { SubpageNav } from 'src/components/PageLayout/PageLayout.styles'
import { FilterBar } from 'src/components/Reusable/IndexPage/FilterBar'
import { INDEX_PAGE_WITH_FILTERS } from 'src/components/Reusable/IndexPage/IndexPage.constants'
import { FilterBarConfigs } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.constants'
import { ServicesIndexGrid } from './ServicesIndexGrid'

export const ServicesIndexPage = (): JSX.Element => (
  <IndexPage
    pageHeader={<PageHeader title="Services" subHeader={<SubpageNav />} />}
    indexButtons={
      <StandardOpenModalButton
        modalId={ModalFormId.ADD_SERVICES}
        variant={StandardButtonVariant.PRIMARY}
        size={StandardButtonSize.SMALL}
        text="Add Services"
      />
    }
    filterBar={
      <FilterBar config={FilterBarConfigs[INDEX_PAGE_WITH_FILTERS.SERVICES]} />
    }
    infoSection={<InfoSection text="Track which services your company uses." />}
    indexGrid={<ServicesIndexGrid />}
  />
)
