import { useCallback, useContext } from 'react'
import { RemoveControlsRequest } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import log from 'loglevel'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { AuditContext } from '../../../../context/AuditContext'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useInvalidateControlsCache } from '../control'

/**
 * TODO: Logic should be  moved to the backend once the multi-audit flag is
 * @param controlId
 */
export function useRemoveControlFromAudit(
  controlId: string | string[],
): () => Promise<void> {
  const { auditId } = useContext(AuditContext)
  const mutateControls = useInvalidateControlsCache()
  const auditClient = useAuthorizedGrpcClient(AuditPromiseClient)

  return useCallback(async () => {
    if (!auditId) {
      return
    }
    try {
      await auditClient.removeControls(
        new RemoveControlsRequest()
          .setAuditId(auditId)
          .setControlIdsList(
            Array.isArray(controlId) ? controlId : [controlId],
          ),
      )
      await mutateControls()
    } catch (e) {
      log.error('Caught an error while attempting to remove the control', e)
    }
  }, [auditClient, auditId, controlId, mutateControls])
}
