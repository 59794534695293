import React, { useState } from 'react'
import isFunction from 'lodash/isFunction'
import useClickOutside from 'src/Utils/useClickOutside'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  DecoratedDropdownContainer,
  DecoratedDropdownItem,
  DecoratedDropdownMenuContainer,
  DecoratedDropdownToggle,
} from './DecoratedDropdown.styles'
import { DecoratedDropdownProps } from './DecoratedDropdown.constants'

export const DecoratedDropdown = <T,>({
  selected,
  setSelected,
  isDisabled,
  options,
  filteredDisplayOptions, // if you only want the user to be able to select from a subset of the options
  dropdownSelectedOverride, // if you need to override the dropdown selected item based on the filtered options
  onToggle,
  $isLarge,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
}: DecoratedDropdownProps<T>): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)
  const [isVisible, setIsVisible] = useState(false)

  const isDisabledOrNoPermission = isDisabled || !hasPermission

  const statusRef = useClickOutside(() =>
    setIsVisible(false),
  ) as React.RefObject<HTMLDivElement>

  const toggleVisibility = () => {
    setIsVisible((isVisible) => !isVisible)
  }

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (isFunction(onToggle)) {
      onToggle()
    }

    if (!isDisabledOrNoPermission) {
      toggleVisibility()
    }
  }

  const handleChange = async (key: T) => {
    await setSelected(key)
    setIsVisible(false)
  }

  const choices = filteredDisplayOptions || options
  const currOption =
    options.find((option) => option.key === selected) || options[0]
  const dropdownSelected =
    dropdownSelectedOverride !== undefined ? dropdownSelectedOverride : selected

  return (
    <DecoratedDropdownContainer ref={statusRef}>
      <DecoratedDropdownToggle
        colors={currOption.colors}
        isDisabled={isDisabledOrNoPermission}
        isVisible={isVisible}
        $isLarge={$isLarge}
        onClick={handleToggle}
      >
        {currOption.label}
      </DecoratedDropdownToggle>
      <DecoratedDropdownMenuContainer isVisible={isVisible}>
        {choices.map((option) => (
          <DecoratedDropdownItem
            isSelected={option.key === dropdownSelected}
            backgroundColor={option.colors.background}
            textColor={option.colors.text}
            label={option.label}
            key={`${option.key + option.label}`}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleChange(option.key)
            }}
          />
        ))}
      </DecoratedDropdownMenuContainer>
    </DecoratedDropdownContainer>
  )
}
