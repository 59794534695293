import React from 'react'
import { usePastAudit } from 'src/context/AuditContext'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { Banner } from './Banner'

export const InPastAuditBanner = ({
  noMargin,
}: {
  noMargin?: boolean
}): JSX.Element => {
  const { isPastAudit, endDate } = usePastAudit()

  if (!isPastAudit) {
    return <></>
  }

  return (
    <Banner
      text={`The end date of this audit was ${endDate}. Continuing to edit it may be
      confusing as new evidence won't appear, etc.`}
      link={HUBSPOT.AUDIT_PERIOD_DOCUMENTATION}
      noMargin={noMargin}
    />
  )
}
