import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { ListFrameworkIdsByComplianceFrameworkIdRequest } from '@trustero/trustero-api-web/lib/model/framework_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import log from 'loglevel'

import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

/***
 * if an id is not passed in it will return all of the frameworks associated with that account
 * if an id is passed in it will filter by that compliance framework (base) id
 */

export const useFrameworkIdsByComplianceFrameworkId = (
  id = '',
): GrpcResponse<StringList> => {
  const call = id
      ? ModelPromiseClient.prototype.listFrameworkIdsByComplianceFrameworkId
      : ModelPromiseClient.prototype.listFrameworkIds,
    request =
      new ListFrameworkIdsByComplianceFrameworkIdRequest().setComplianceFrameworkId(
        id,
      )
  const { response } = useSwrImmutableGrpc(call, request, true)
  if (response.error)
    log.error('error fetching framework ids by compliance framework id')

  return NewGrpcResponse(response)
}
