import React from 'react'
import styled from 'styled-components/macro'
import { Spinner } from 'src/Throbber/Spinner'
import { ReactComponent as GoogleSVG } from '../../components/Icons/Dependencies/google/google.svg'
import { ReactComponent as OktaSVG } from '../../components/Icons/Dependencies/okta/okta.svg'
import { ReactComponent as OneLoginSVG } from '../../components/Icons/Dependencies/onelogin/oneLogin.svg'
import { ReactComponent as MicrosoftSVG } from '../../components/Icons/Dependencies/microsoft/microsoft.svg'
import { ReactComponent as EntraIdSVG } from '../../components/Icons/Dependencies/microsoft/entra.svg'
import { ReactComponent as Auth0SVG } from '../../components/Icons/Dependencies/auth0/auth0.svg'
import { StyledLoginButton, StyledRedirect } from './LoginPage.styles'

const LoginIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  margin: 4px 0 4px 4px;
  padding: 8px;
  border-radius: inherit;
  background-color: white;
  width: 40px;
  height: 40px;
`

export const GoogleIcon = (): JSX.Element => (
  <LoginIcon>
    <GoogleSVG width={'1.5rem'} />
  </LoginIcon>
)

export const OktaIcon = (): JSX.Element => (
  <LoginIcon>
    <OktaSVG />
  </LoginIcon>
)

export const Auth0Icon = (): JSX.Element => (
  <LoginIcon>
    <Auth0SVG />
  </LoginIcon>
)

export const OneLoginIcon = (): JSX.Element => (
  <LoginIcon>
    <OneLoginSVG />
  </LoginIcon>
)
export const MicrosoftIcon = (): JSX.Element => (
  <LoginIcon>
    <MicrosoftSVG />
  </LoginIcon>
)
export const EntraIdIcon = (): JSX.Element => (
  <LoginIcon>
    <EntraIdSVG />
  </LoginIcon>
)

export const LoginButton = ({
  leftIcon,
  onClickFunc,
  providerName,
  isSignUp,
}: {
  leftIcon?: JSX.Element
  onClickFunc: () => void
  providerName: string
  isSignUp?: boolean
}): JSX.Element => {
  return (
    <StyledLoginButton onClick={onClickFunc}>
      {leftIcon && leftIcon}
      {isSignUp ? 'Sign Up with ' : 'Login with '}
      {providerName}
    </StyledLoginButton>
  )
}

export const RedirectToHubspot = (): JSX.Element => (
  <>
    <StyledRedirect>Redirecting to the Trustero Support Page...</StyledRedirect>
    <Spinner color="primary" size="xl" />
  </>
)
