import React, { FormEventHandler, useMemo, useState } from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { showInfoToast } from 'src/Utils/helpers/toast'
import {
  RISK_LINK_TYPE,
  RiskLink,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  ListVendorsRequest,
  VendorRecord,
} from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import {
  useInvalidateVendorsCache,
  useVendors,
} from 'src/pages/Vendors/vendors.hooks'
import { MultiSelectForm } from 'src/components/Reusable/Forms/MultiSelectForm'
import log from 'loglevel'
import { useToggleLinks } from '../risks.hooks'
import { RiskVendorsMultiSelectGridItems } from './RiskModal.components'

export const AddVendorsToRiskModal = ({
  existingVendorIds,
  riskId,
}: {
  existingVendorIds: string[]
  riskId: string
}): JSX.Element => {
  const [selectedVendors, setSelectedVendors] = useState<string[]>([])
  const toggleLinks = useToggleLinks()
  const onHide = useHideModal({})
  const { data, isLoading, error } = useVendors(new ListVendorsRequest())
  const mutate = useInvalidateVendorsCache()
  const show = useIsShowModal(ModalFormId.LINK_VENDOR_TO_RISK)

  const availableVendors = useMemo(() => {
    if (isLoading || error || !data) return []

    return data
      .getVendorsList()
      .filter((elem: VendorRecord) => !existingVendorIds.includes(elem.getId()))
  }, [data, isLoading, error, existingVendorIds])

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    const links = selectedVendors.map((vendorId: string) =>
      new RiskLink()
        .setId(vendorId)
        .setRiskId(riskId)
        .setModelType(MODEL_TYPE.VENDOR),
    )
    try {
      await toggleLinks({
        type: RISK_LINK_TYPE.LINK,
        links,
      })
      mutate()
      onHide()
      setSelectedVendors([])
    } catch (err) {
      showInfoToast(
        'Sorry, we were unable to link the vendor(s) to the risk. Please try again later.',
      )
      log.error(`Error adding vendor(s) to risk id ${riskId}:`, err)
    }
  }

  return (
    <ModalForm
      show={show}
      hide={onHide}
      formId={ModalFormId.LINK_VENDOR_TO_RISK}
      size="xl"
      title="Link Risk to Vendors"
      submitText="Link Vendors"
    >
      <MultiSelectForm
        formId={ModalFormId.LINK_VENDOR_TO_RISK}
        title="Associate vendors with this risk."
        loading={isLoading}
        gridTemplateColumnStyling="min-content minmax(min-content, auto)"
        gridItems={
          <RiskVendorsMultiSelectGridItems
            vendors={availableVendors}
            selectedVendors={selectedVendors}
            setSelectedVendors={setSelectedVendors}
          />
        }
        onSubmit={onSubmit}
      />
    </ModalForm>
  )
}
