import React from 'react'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import { useDeleteDocumentRequest } from 'src/components/async/DocumentRequest/useDocumentRequests'
import { FlexAlign, FlexColumn } from 'src/components/Reusable/Flex'
import { getPluralization } from 'src/Utils/globalHelpers'
import { useConfirmationModal } from '../../../components/ModalForms'

const title = 'Delete This Request Entirely?'
const confirmText = 'Delete Document Request'

type DeleteRequestProps = {
  request: DocumentRequest
}

export const useDeleteRequestModal = ({
  request,
}: DeleteRequestProps): (() => void) => {
  const deleteDocumentRequest = useDeleteDocumentRequest(request.getId())

  const onConfirmCB = async () => {
    await deleteDocumentRequest()
  }

  return useConfirmationModal({
    title,
    body: (
      <FlexColumn gap={10} align={FlexAlign.FLEX_START}>
        <p>
          <b>
            This request will be deleted from your organization. This action
            cannot be undone.
          </b>
        </p>
        <p>{request.getRequest()}</p>
        {!!request.getEvidenceCount() && (
          <p>{`${request.getEvidenceCount()} ${getPluralization(
            'piece',
            request.getEvidenceCount(),
          )} of evidence will be deleted and will no longer appear on the ${request.getControlsCount()} ${getPluralization(
            'control',
            request.getControlsCount(),
          )} linked to this request.`}</p>
        )}
      </FlexColumn>
    ),
    confirmText,
    onConfirmCB,
    throbber: {
      color: 'primary',
      size: 'xl',
    },
  })
}
