import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import Dropdown from 'react-bootstrap/Dropdown'
import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { PERMISSIONS } from 'src/config/roleConfig'
import { FilterName, FilterParam } from '../FilterBar.types'
import { Spinner } from '../../../../../Throbber'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Chips/BasicChip'
import { ListItemPlaceholder } from '../../../../Placeholders/ListItemPlaceholder'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { useDepartment } from '../../../../async/Department/useDepartment'
import { useDepartmentIds } from '../../../../async/Department/useDepartmentIds'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'

const DepartmentItem = ({
  modelId,
  searchParams,
  appliedFilters,
}: {
  modelId: string
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element => {
  const navigate = useNavigate()
  const department = useDepartment(modelId)

  if (!department && modelId !== NONE_ID) {
    return <ListItemPlaceholder height={36} />
  }
  const departmentModelId = department?.getModelId() || NONE_ID
  const departmentName = department?.getName() || NONE_NAME

  const newAppliedFilters = new Set(appliedFilters)
  const isChecked = newAppliedFilters.has(modelId)

  isChecked ? newAppliedFilters.delete(modelId) : newAppliedFilters.add(modelId)

  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...searchParams,
        [FilterParam.DEPARTMENT]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <FilterDropdownItem replace to={href}>
      <CheckBoxInput
        id={departmentModelId}
        label={departmentName}
        checked={isChecked}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={() => {
          navigate(href, { replace: true })
        }}
        tabIndex={-1}
        requiredPermissions={[PERMISSIONS.READ]}
      />
    </FilterDropdownItem>
  )
}

const DepartmentDropdownMenu = ({
  searchParams,
  appliedFilters,
  deptIds,
}: {
  searchParams: ParsedQuery
  appliedFilters: string[]
  deptIds: string[]
}): JSX.Element => {
  return (
    <Dropdown.Menu>
      <DepartmentItem
        modelId={NONE_ID}
        searchParams={searchParams}
        appliedFilters={appliedFilters}
      />
      {deptIds.map((modelId: string) => (
        <DepartmentItem
          key={modelId}
          modelId={modelId}
          searchParams={searchParams}
          appliedFilters={appliedFilters}
        />
      ))}
    </Dropdown.Menu>
  )
}

const DepartmentDropdownToggle = ({
  appliedFilters,
  searchParams,
}: {
  appliedFilters: string[]
  searchParams: queryString.ParsedQuery<string>
}): JSX.Element => {
  const { data: deptIds, isLoading } = useDepartmentIds()
  if (!deptIds || isLoading) {
    return (
      <>
        <Dropdown.Toggle
          as={FilterDropdownToggle}
          id={`${FilterName.DEPARTMENT}-filter-dropdown`}
        >
          <p>{FilterName.DEPARTMENT}</p>
          <Spinner size="s" color="primary" mx="xxs" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      </>
    )
  }
  return (
    <>
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        id={`${FilterName.DEPARTMENT}-filter-dropdown`}
      >
        <p>{FilterName.DEPARTMENT}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      <DepartmentDropdownMenu
        searchParams={searchParams}
        appliedFilters={appliedFilters}
        deptIds={deptIds}
      />
    </>
  )
}

export const DepartmentFilterDropdown = (): JSX.Element => {
  const location = useLocation()

  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[FilterParam.DEPARTMENT]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams])

  return (
    <Dropdown autoClose="outside">
      <DepartmentDropdownToggle
        appliedFilters={appliedFilters}
        searchParams={searchParams}
      />
    </Dropdown>
  )
}
