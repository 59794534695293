import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { MemoryRouter } from 'react-router'

export const printComponentAsPDF = (
  component: JSX.Element,
  title: string,
): void => {
  const componentString = ReactDOMServer.renderToString(
    <MemoryRouter initialEntries={['/']}>{component}</MemoryRouter>,
  )
  const styles = Array.from(document.querySelectorAll('style'))
    .map((style) => style.outerHTML)
    .join('')

  let iframe = document.getElementById('printFrame') as HTMLIFrameElement

  if (!iframe) {
    iframe = document.createElement('iframe')
    iframe.id = 'printFrame'
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
  }

  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document

  if (iframeDoc) {
    iframeDoc.open()
    iframeDoc.write(`<html><head><title>${title}</title>`)
    iframeDoc.write(styles) // inject the styles
    iframeDoc.write('</head><body>')
    iframeDoc.write(componentString)
    iframeDoc.write('</body></html>')
    iframeDoc.close()

    const printFrameWindow = iframe.contentWindow
    printFrameWindow?.print()
  }
}

export const blobToUint8Array = async (blob: Blob): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const arrayBuffer = reader.result as ArrayBuffer
      const uint8Array = new Uint8Array(arrayBuffer)
      resolve(uint8Array)
    }
    reader.onerror = () => reject(reader.error)
    reader.readAsArrayBuffer(blob)
  })
}
