// source: provider/provider.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.provider.CreateProviderInstancesRequest', null, global);
goog.exportSymbol('proto.provider.GetProviderInstancesResponse', null, global);
goog.exportSymbol('proto.provider.PROVIDERS', null, global);
goog.exportSymbol('proto.provider.PROVIDER_ROLE', null, global);
goog.exportSymbol('proto.provider.ProviderRoleInstance', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.provider.ProviderRoleInstance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.provider.ProviderRoleInstance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.provider.ProviderRoleInstance.displayName = 'proto.provider.ProviderRoleInstance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.provider.GetProviderInstancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.provider.GetProviderInstancesResponse.repeatedFields_, null);
};
goog.inherits(proto.provider.GetProviderInstancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.provider.GetProviderInstancesResponse.displayName = 'proto.provider.GetProviderInstancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.provider.CreateProviderInstancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.provider.CreateProviderInstancesRequest.repeatedFields_, null);
};
goog.inherits(proto.provider.CreateProviderInstancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.provider.CreateProviderInstancesRequest.displayName = 'proto.provider.CreateProviderInstancesRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.provider.ProviderRoleInstance.prototype.toObject = function(opt_includeInstance) {
  return proto.provider.ProviderRoleInstance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.provider.ProviderRoleInstance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.provider.ProviderRoleInstance.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, 0),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isDismissed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.provider.ProviderRoleInstance}
 */
proto.provider.ProviderRoleInstance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.provider.ProviderRoleInstance;
  return proto.provider.ProviderRoleInstance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.provider.ProviderRoleInstance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.provider.ProviderRoleInstance}
 */
proto.provider.ProviderRoleInstance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.provider.PROVIDERS} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 3:
      var value = /** @type {!proto.provider.PROVIDER_ROLE} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDismissed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.provider.ProviderRoleInstance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.provider.ProviderRoleInstance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.provider.ProviderRoleInstance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.provider.ProviderRoleInstance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsDismissed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.provider.ProviderRoleInstance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.provider.ProviderRoleInstance} returns this
 */
proto.provider.ProviderRoleInstance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PROVIDERS provider = 2;
 * @return {!proto.provider.PROVIDERS}
 */
proto.provider.ProviderRoleInstance.prototype.getProvider = function() {
  return /** @type {!proto.provider.PROVIDERS} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.provider.PROVIDERS} value
 * @return {!proto.provider.ProviderRoleInstance} returns this
 */
proto.provider.ProviderRoleInstance.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PROVIDER_ROLE role = 3;
 * @return {!proto.provider.PROVIDER_ROLE}
 */
proto.provider.ProviderRoleInstance.prototype.getRole = function() {
  return /** @type {!proto.provider.PROVIDER_ROLE} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.provider.PROVIDER_ROLE} value
 * @return {!proto.provider.ProviderRoleInstance} returns this
 */
proto.provider.ProviderRoleInstance.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_dismissed = 4;
 * @return {boolean}
 */
proto.provider.ProviderRoleInstance.prototype.getIsDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.provider.ProviderRoleInstance} returns this
 */
proto.provider.ProviderRoleInstance.prototype.setIsDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_custom = 5;
 * @return {boolean}
 */
proto.provider.ProviderRoleInstance.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.provider.ProviderRoleInstance} returns this
 */
proto.provider.ProviderRoleInstance.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.provider.GetProviderInstancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.provider.GetProviderInstancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.provider.GetProviderInstancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.provider.GetProviderInstancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.provider.GetProviderInstancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.provider.ProviderRoleInstance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.provider.GetProviderInstancesResponse}
 */
proto.provider.GetProviderInstancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.provider.GetProviderInstancesResponse;
  return proto.provider.GetProviderInstancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.provider.GetProviderInstancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.provider.GetProviderInstancesResponse}
 */
proto.provider.GetProviderInstancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.provider.ProviderRoleInstance;
      reader.readMessage(value,proto.provider.ProviderRoleInstance.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.provider.GetProviderInstancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.provider.GetProviderInstancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.provider.GetProviderInstancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.provider.GetProviderInstancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.provider.ProviderRoleInstance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProviderRoleInstance instances = 1;
 * @return {!Array<!proto.provider.ProviderRoleInstance>}
 */
proto.provider.GetProviderInstancesResponse.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.provider.ProviderRoleInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.provider.ProviderRoleInstance, 1));
};


/**
 * @param {!Array<!proto.provider.ProviderRoleInstance>} value
 * @return {!proto.provider.GetProviderInstancesResponse} returns this
*/
proto.provider.GetProviderInstancesResponse.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.provider.ProviderRoleInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.provider.ProviderRoleInstance}
 */
proto.provider.GetProviderInstancesResponse.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.provider.ProviderRoleInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.provider.GetProviderInstancesResponse} returns this
 */
proto.provider.GetProviderInstancesResponse.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.provider.CreateProviderInstancesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.provider.CreateProviderInstancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.provider.CreateProviderInstancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.provider.CreateProviderInstancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.provider.CreateProviderInstancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    instancesList: jspb.Message.toObjectList(msg.getInstancesList(),
    proto.provider.ProviderRoleInstance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.provider.CreateProviderInstancesRequest}
 */
proto.provider.CreateProviderInstancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.provider.CreateProviderInstancesRequest;
  return proto.provider.CreateProviderInstancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.provider.CreateProviderInstancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.provider.CreateProviderInstancesRequest}
 */
proto.provider.CreateProviderInstancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.provider.ProviderRoleInstance;
      reader.readMessage(value,proto.provider.ProviderRoleInstance.deserializeBinaryFromReader);
      msg.addInstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.provider.CreateProviderInstancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.provider.CreateProviderInstancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.provider.CreateProviderInstancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.provider.CreateProviderInstancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.provider.ProviderRoleInstance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProviderRoleInstance instances = 1;
 * @return {!Array<!proto.provider.ProviderRoleInstance>}
 */
proto.provider.CreateProviderInstancesRequest.prototype.getInstancesList = function() {
  return /** @type{!Array<!proto.provider.ProviderRoleInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.provider.ProviderRoleInstance, 1));
};


/**
 * @param {!Array<!proto.provider.ProviderRoleInstance>} value
 * @return {!proto.provider.CreateProviderInstancesRequest} returns this
*/
proto.provider.CreateProviderInstancesRequest.prototype.setInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.provider.ProviderRoleInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.provider.ProviderRoleInstance}
 */
proto.provider.CreateProviderInstancesRequest.prototype.addInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.provider.ProviderRoleInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.provider.CreateProviderInstancesRequest} returns this
 */
proto.provider.CreateProviderInstancesRequest.prototype.clearInstancesList = function() {
  return this.setInstancesList([]);
};


/**
 * @enum {number}
 */
proto.provider.PROVIDER_ROLE = {
  CLOUD_HOSTING: 0,
  SOURCE_CODE_MANAGEMENT: 1
};

/**
 * @enum {number}
 */
proto.provider.PROVIDERS = {
  CUSTOM: 0,
  AMAZON_WEB_SERVICES: 1,
  GOOGLE_CLOUD_PLATFORM: 2,
  MICROSOFT_AZURE: 3,
  GITHUB: 4,
  SOURCEFORGE: 5,
  BITBUCKET: 6,
  GITLAB: 7,
  HEROKU: 8,
  MICROSOFT_AZURE_DEVOPS: 9,
  AWS_CODECOMMIT: 10
};

goog.object.extend(exports, proto.provider);
