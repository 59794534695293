/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, ReactNode, ReactNodeArray } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownToggleButton, IconButtonVariant } from '../Buttons'
import { Tooltip } from '../Tooltip'

type Props = {
  title?: string | JSX.Element
  tooltipId: string
  tooltipText: string
  style?: CSSProperties | undefined
  children: ReactNode | ReactNodeArray
  disabled?: boolean
}

export function DropdownChip(props: Props): JSX.Element {
  return (
    <Dropdown>
      <Tooltip
        id={props.tooltipId}
        placement="top"
        tooltipBody={props.tooltipText}
      >
        <Dropdown.Toggle
          as={DropdownToggleButton}
          variant={IconButtonVariant.chip}
          width={'auto'}
          maxWidth={'160px'}
          style={props.style}
          disabled={props.disabled}
        >
          {props.title}
        </Dropdown.Toggle>
      </Tooltip>
      <Dropdown.Menu as="ul">{props.children}</Dropdown.Menu>
    </Dropdown>
  )
}
