import { GoogleDrive } from 'src/components/Icons/Dependencies'
import styled from 'styled-components/macro'
import { compose, margin, textStyle } from 'styled-system'
import { TypeButton } from '../FileType/TypeSelector.styles'

export const DocumentDetailsContainer = styled.div.attrs({
  mb: 'l',
})`
  ${margin}
  position: relative;
`

export const Label = styled.label.attrs({
  mb: 'xxxs',
  textStyle: 'body.default',
})`
  ${compose(margin, textStyle)}
  display: flex;
  justify-content: space-between;
`

export const GoogleDriveIcon = styled(GoogleDrive)`
  width: 48px;
  height: 48px;
`

export const StyledTypeButton = styled(TypeButton)`
  margin: 0;
  cursor: pointer;
`
