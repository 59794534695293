import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import styled from 'styled-components/macro'
import { TableListLoader } from './TableListLoader'

// Styled components for layout
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 60px;
`

const LeftHeader = styled.div`
  flex: 1;
  margin-left: 15px;
`

const RightHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  margin-right: 15px;
`

// Content loader for the top left section (header and objective)
const LeftHeaderLoader = (props: IContentLoaderProps): JSX.Element => (
  <ContentLoader viewBox="0 0 600 200" height={200} width={600} {...props}>
    <rect x="0" y="0" rx="6" ry="6" width="400" height="35" />{' '}
    {/* Large Title */}
    <rect x="0" y="50" rx="5" ry="5" width="550" height="25" />{' '}
    {/* Objective Text */}
    <rect x="0" y="90" rx="5" ry="5" width="500" height="25" />{' '}
    {/* Additional line */}
    <rect x="0" y="130" rx="5" ry="5" width="450" height="25" />{' '}
    {/* Additional line */}
    <rect x="0" y="170" rx="5" ry="5" width="400" height="25" />{' '}
    {/* Additional line */}
  </ContentLoader>
)

// Content loader for the top right section (status, responsibility, linkages)
const RightHeaderLoader = (props: IContentLoaderProps): JSX.Element => (
  <ContentLoader viewBox="0 0 400 200" height={200} width={400} {...props}>
    <rect x="0" y="0" rx="6" ry="6" width="380" height="35" />{' '}
    {/* Large Status */}
    <rect x="0" y="60" rx="6" ry="6" width="380" height="35" />{' '}
    {/* Responsibility */}
    <rect x="0" y="120" rx="6" ry="6" width="380" height="35" />{' '}
    {/* Linkages */}
    <rect x="0" y="180" rx="6" ry="6" width="380" height="35" />{' '}
    {/* Extra Field */}
  </ContentLoader>
)

const TableSection = styled.div`
  margin-top: 30px;
`
export const ShowPageHeaderLoader = (
  props: IContentLoaderProps,
): JSX.Element => (
  <Container>
    <HeaderSection>
      <LeftHeader>
        <LeftHeaderLoader {...props} />
      </LeftHeader>
      <RightHeader>
        <RightHeaderLoader {...props} />
      </RightHeader>
    </HeaderSection>
  </Container>
)

export const ShowPageContentLoader = (
  props: IContentLoaderProps,
): JSX.Element => (
  <Container>
    <TableSection>
      <TableListLoader {...props} />
    </TableSection>
  </Container>
)

// Main loader component for the dashboard page
export const ShowPageLoader = (props: IContentLoaderProps): JSX.Element => (
  <Container>
    <HeaderSection>
      <LeftHeader>
        <LeftHeaderLoader {...props} />
      </LeftHeader>
      <RightHeader>
        <RightHeaderLoader {...props} />
      </RightHeader>
    </HeaderSection>
    {/* Table section (not modified) */}
    <TableSection>
      <TableListLoader {...props} />
    </TableSection>
  </Container>
)
