import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'
import { RISK_STATUS } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { QUESTIONNAIRE_ACCEPTANCE } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import {
  CONTROL_STATUS_TO_KEY_MAP,
  QUESTIONNAIRE_ACCEPTANCE_TO_KEY_MAP,
  RISK_STATUS_TO_KEY_MAP,
  STATUS_KEYS,
} from './StatusDropdown.constants'

export const getStatusKey = <T,>(
  status: T,
  modelType: MODEL_TYPE,
): STATUS_KEYS => {
  switch (modelType) {
    case MODEL_TYPE.CONTROL:
      return CONTROL_STATUS_TO_KEY_MAP[status as unknown as CONTROL_STATUS]
    case MODEL_TYPE.RISK:
      return RISK_STATUS_TO_KEY_MAP[status as unknown as RISK_STATUS]
    case MODEL_TYPE.QUESTIONNAIRE:
      return QUESTIONNAIRE_ACCEPTANCE_TO_KEY_MAP[
        status as unknown as QUESTIONNAIRE_ACCEPTANCE
      ]
    default:
      return STATUS_KEYS.CONTROL_NEEDS_ATTENTION
  }
}
