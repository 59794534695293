import { useCallback } from 'react'
import { Dismissed } from '@trustero/trustero-api-web/lib/model/model_pb'
import log from 'loglevel'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useInvalidatePolicyCache } from '../../policy/usePolicy'
import { useRemoveControlFromAllAudits } from '../audit'
import { useInvalidateControlsCache } from './useControls'

/**
 * TODO: Logic should be  moved to the backend once the multi-audit flag is
 * @param controlId
 */
export const useDeleteControl = ({
  control: { id, modelId },
}: {
  control: Control.AsObject
}): (() => Promise<void>) => {
  const invalidatePolicyCache = useInvalidatePolicyCache()
  const invalidateControlsCache = useInvalidateControlsCache()
  const client = useAuthorizedGrpcClient(ModelPromiseClient)
  const policyClient = useAuthorizedGrpcClient(PolicyPromiseClient)
  const removeFromAllAudits = useRemoveControlFromAllAudits(id ?? '')

  return useCallback(async () => {
    const dismissRequest = new Dismissed()
      .setId(
        new Identifier().setModelid(modelId).setModeltype(MODEL_TYPE.CONTROL),
      )
      .setDismissed(true)

    try {
      const dismissPromise = client.setDismissed(dismissRequest).then(() => {
        return
      })
      const removeFromAuditPromise = removeFromAllAudits()
      const removeFromPolicyPromise = policyClient
        .disassociateControlFromPolicy(new StringList().setItemList([id]))
        .then(() => {
          return
        })

      await Promise.all([
        dismissPromise,
        removeFromAuditPromise,
        removeFromPolicyPromise,
      ])

      await invalidatePolicyCache()
      await invalidateControlsCache()
    } catch (e) {
      log.error('Caught an error while attempting to remove the control', e)
    }
  }, [
    modelId,
    client,
    removeFromAllAudits,
    policyClient,
    id,
    invalidatePolicyCache,
    invalidateControlsCache,
  ])
}
