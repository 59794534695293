import {
  GetPolicyForComplianceFrameworkRequest,
  PolicyRecord,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const usePolicyForComplianceFramework = (
  modelId: string,
  complianceFrameworkId: string,
): GrpcResponse<PolicyRecord> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getPolicyForComplianceFramework,
    new GetPolicyForComplianceFrameworkRequest()
      .setModelId(modelId)
      .setComplianceFrameworkId(complianceFrameworkId),
  )
  return NewGrpcResponse(response)
}
