import React, { ChangeEventHandler, useState } from 'react'
import {
  useHideModal,
  useIsShowModal,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import {
  ModalForm,
  ModalFormId,
  ModalFormProps,
} from '../../../../../components/ModalForms/ModalForm'
import { Spinner } from '../../../../../Throbber'
import { ListItemPlaceholder } from '../../../../../components/Placeholders/ListItemPlaceholder'
import { ControlsIndexGridNoHeaders } from './ControlsIndexGridNoHeaders'

type MultiSelectListModalProps = Omit<
  ModalFormProps,
  'show' | 'formId' | 'onBack' | 'hide' | 'footerContent'
> & {
  options: string[] | undefined
  modalFormId: ModalFormId
  onSubmit: (selected: string[]) => Promise<void>
  formFallback: JSX.Element
}

export const MultiSelectListModal = (
  props: MultiSelectListModalProps,
): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<string[]>([])
  const hide = useHideModal({
    onHide: () => setSelected([]),
    modalId: props.modalFormId,
  })
  const show = useIsShowModal(props.modalFormId)
  const onBack = useSetActiveModal(ModalFormId.ADD_CONTROL_OPTIONS)
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { checked, value },
  }) =>
    setSelected((prevState) =>
      checked ? [...prevState, value] : prevState.filter((p) => p !== value),
    )
  const showPlaceholder = props.options === undefined
  const showFallback =
    props.options !== undefined && props.options.length === 0 && !loading

  return (
    <ModalForm
      {...props}
      hide={hide}
      show={show}
      formId={props.modalFormId}
      customSubmitButton={showFallback ? <></> : undefined}
      size="lg"
      onBack={onBack}
    >
      <form
        id={props.modalFormId}
        onSubmit={async (e) => {
          e.preventDefault()
          setLoading(true)
          await props.onSubmit(selected)
          setLoading(false)
          hide()
        }}
      >
        {loading && <Spinner size="l" color="primary" />}
        {showPlaceholder &&
          Array.from({ length: 5 }).map((_, i) => (
            <ListItemPlaceholder key={`${i}`} height={'36'} />
          ))}
        {showFallback && props.formFallback}
        {!loading &&
          props.options !== undefined &&
          props.options.length > 0 && (
            <ControlsIndexGridNoHeaders
              controlIds={props.options}
              selected={selected}
              onChange={onChange}
            />
          )}
      </form>
    </ModalForm>
  )
}
