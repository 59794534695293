import React from 'react'
import { Markup } from '../../../components/Reusable/Text/Markup'

type Props = {
  objective: string
}

export const DescriptionMarkup = ({ objective }: Props): JSX.Element => {
  return <Markup markdown={objective} />
}
