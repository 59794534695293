import React from 'react'
import log from 'loglevel'
import { Dropdown } from 'react-bootstrap'
import { E2E_IDS } from 'src/Utils/testIds'
import { RISK_TREATMENT } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from 'src/components/Reusable/StandardDropdown/StandardDropdown.styles'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useCreateOrUpdateRisks, useRisk } from '../risks.hooks'
import {
  RISK_TREATMENT_DROPDOWN,
  RISK_TREATMENT_LABEL,
} from '../risks.constants'

export const RisksTreatmentMenu = ({
  riskId,
}: {
  riskId: string
}): JSX.Element => {
  const hasEditPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])
  const createOrUpdateRisk = useCreateOrUpdateRisks()
  const risk = useRisk(riskId)

  if (!risk) {
    return <></>
  }

  const treatment = risk.getTreatment()
  const updateRisk = async (newTreatment: RISK_TREATMENT): Promise<void> => {
    try {
      risk.setTreatment(newTreatment)
      await createOrUpdateRisk([risk])
    } catch (err) {
      log.error(`Failed to update risk category for riskId: ${riskId}:`, err)
    }
  }

  return (
    <Dropdown
      id={E2E_IDS.RISK_CATEGORY_MENU}
      data-testid={E2E_IDS.RISK_CATEGORY_MENU}
    >
      <Dropdown.Toggle
        as={StyledDropdownToggle}
        disabled={!hasEditPermissions}
        width="100%"
      >
        {RISK_TREATMENT_LABEL[treatment]}
      </Dropdown.Toggle>
      <Dropdown.Menu as={StyledDropdownMenu}>
        {RISK_TREATMENT_DROPDOWN.map((item) => (
          <Dropdown.Item
            key={item.name}
            eventKey={item.value}
            onClick={() => updateRisk(item.value)}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
