import {
  GetLatestTestResultRequest,
  GetLatestTestResultsResponse,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { SWRResponse } from 'swr'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { ListControlsRequest } from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { boolValue } from '../../../Utils/primitives'

/**
 * useLatestTestResults
 *
 *
 * @param request If the hook is invoked in a Continuous Compliance view, the
 *   request is passed as is.
 *   If it is invoked in an Audit view, the request will be updated to reflect
 *   the context of the current audit:
 *
 *   - If the model ids list has not been set, it will be updated with control
 *   ids in the audit
 * @param shouldFetch if false, useSWR will not fire
 */
export const useLatestTestResults = (
  request: GetLatestTestResultRequest,
  shouldFetch = true,
): SWRResponse<GetLatestTestResultsResponse> => {
  const shouldListAll =
    request.getModelIdsList().length === 0 &&
    request.getControlIdsList().length === 0
  const { response: controlsResponse } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listControls,
    new ListControlsRequest().setIsDismissed(boolValue(false)),
    shouldListAll && shouldFetch,
  )
  if (shouldListAll) {
    request.setModelIdsList(
      controlsResponse.data
        ?.getItemsList()
        .map((p) =>
          new Identifier()
            .setModelid(p.getModelId())
            .setModeltype(MODEL_TYPE.CONTROL),
        ) ?? [],
    )
  }
  const { response } = useSwrImmutableGrpc(
    TestingPromiseClient.prototype.getLatestTestResults,
    request,
    shouldFetch,
  )
  return response
}
