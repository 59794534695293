import { useContext, useState, useEffect } from 'react'
import {
  GetRequest,
  UpdateUser,
  UserRecord,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { UserPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import AuthContext from '../../../context/authContext'

// Hook for fetching the logged-in user record
export const useUser = (shouldFetch = true): GrpcResponse<UserRecord> => {
  // n.b. account.User/Get is permission restricted to only allow you to look up
  // your own account.  (Admins can look up others, which might mislead you into
  // thinking your UI is working.)
  const { authCtx } = useContext(AuthContext)
  const { response } = useSwrImmutableGrpc(
    UserPromiseClient.prototype.get,
    new GetRequest().setEmail(authCtx.email),
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useIsUserFirstLogin = (): boolean | null => {
  const [isNewUser, setIsNewUser] = useState<boolean | null>(null)
  const { data: user } = useUser()

  useEffect(() => {
    if (user) {
      if (Number(user?.getLastseen()?.getSeconds()) === 0) {
        setIsNewUser(true)
      } else {
        setIsNewUser(false)
      }
    }
  }, [user])

  return isNewUser
}

export const useUpdateUser = (): (({
  userId,
  userName,
}: {
  userId: string
  userName: string
}) => Promise<void>) => {
  const userService = useAuthorizedGrpcClient(UserPromiseClient)
  const { mutate: mutateUser } = useUser()
  return async ({ userId, userName }: { userId: string; userName: string }) => {
    if (userName.length) {
      const updatedUser = await userService.update(
        new UpdateUser().setId(userId).setName(userName),
      )
      // Update the local cache with user returned from the GRPC and do not revalidate
      await mutateUser(updatedUser, false)
    }
  }
}
