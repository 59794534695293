import { useMemo } from 'react'
import {
  ControlComplianceStats,
  ListControlComplianceStatsResponse,
} from '@trustero/trustero-api-web/lib/model/stats_pb'
import { BaseProps, OnUnpackedResponse } from '../../types'

type Props<ExternalProps> = {
  controlId: string
  stats: ControlComplianceStats[]
  mutate: () => Promise<ListControlComplianceStatsResponse | undefined>
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<
    ExternalProps,
    ListControlComplianceStatsResponse,
    { stats: ControlComplianceStats }
  >
>

export function ControlComplianceStatsResponse<ExternalProps>(
  props: Props<ExternalProps>,
): JSX.Element {
  const stats = useMemo(() => {
    return props.stats.reduce(function (
      map: { [key: string]: ControlComplianceStats },
      obj: ControlComplianceStats,
    ) {
      map[obj.getId()] = obj
      return map
    },
    {})
  }, [props.stats])

  const finalStats =
    stats[props.controlId] ??
    new ControlComplianceStats()
      .setAutomatedEvidenceCount(0)
      .setManualEvidenceCount(0)
      .setAutomatedTestCount(0)
      .setManualTestCount(0)
  return props.child({ ...props, stats: finalStats })
}
