import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  CONTROL_STATUS,
  Control,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  QUESTIONNAIRE_ACCEPTANCE,
  Questionnaire,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { RISK_STATUS, Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { PERMISSIONS } from 'src/config/roleConfig'
import { KeyedMutator } from 'swr'

export enum STATUS_KEYS {
  // Control
  CONTROL_NEEDS_ATTENTION = 'Control Needs Attention',
  CONTROL_READY_FOR_REVIEW = 'Control Ready for Review',
  CONTROL_AUDIT_READY = 'Control Audit Ready',
  CONTROL_ACCEPTED = 'Control Accepted',
  CONTROL_FAILED_TEST = 'Control Failed Test',
  CONTROL_NOT_APPLICABLE = 'Control Not Applicable',

  // Risk
  RISK_TODO = 'Risk To Do',
  RISK_IN_PROGRESS = 'Risk In Progress',
  RISK_PENDING = 'Risk Pending',
  RISK_COMPLETED = 'Risk Completed',

  // Questionnaire
  QUESTIONNAIRE_IN_PROGRESS = 'In Progress',
  QUESTIONNAIRE_ACCEPTED = 'Accepted',
}

export const CONTROL_STATUS_TO_KEY_MAP = {
  [CONTROL_STATUS.NEEDSATTENTION]: STATUS_KEYS.CONTROL_NEEDS_ATTENTION,
  [CONTROL_STATUS.READYFORREVIEW]: STATUS_KEYS.CONTROL_READY_FOR_REVIEW,
  [CONTROL_STATUS.AUDITREADY]: STATUS_KEYS.CONTROL_AUDIT_READY,
  [CONTROL_STATUS.ACCEPTED]: STATUS_KEYS.CONTROL_ACCEPTED,
  [CONTROL_STATUS.FAILEDTEST]: STATUS_KEYS.CONTROL_FAILED_TEST,
  [CONTROL_STATUS.NOTAPPLICABLE]: STATUS_KEYS.CONTROL_NOT_APPLICABLE,
}

export const RISK_STATUS_TO_KEY_MAP = {
  [RISK_STATUS.TODO]: STATUS_KEYS.RISK_TODO,
  [RISK_STATUS.IN_PROGRESS]: STATUS_KEYS.RISK_IN_PROGRESS,
  [RISK_STATUS.PENDING]: STATUS_KEYS.RISK_PENDING,
  [RISK_STATUS.COMPLETED]: STATUS_KEYS.RISK_COMPLETED,
}

export const QUESTIONNAIRE_ACCEPTANCE_TO_KEY_MAP = {
  [QUESTIONNAIRE_ACCEPTANCE.IN_PROGRESS]: STATUS_KEYS.QUESTIONNAIRE_IN_PROGRESS,
  [QUESTIONNAIRE_ACCEPTANCE.ACCEPTED_QUESTIONNAIRE]:
    STATUS_KEYS.QUESTIONNAIRE_ACCEPTED,
}

export const CONTROLS_STATUS_DROPDOWN_ORDERED_LIST = [
  CONTROL_STATUS.NEEDSATTENTION,
  CONTROL_STATUS.READYFORREVIEW,
  CONTROL_STATUS.AUDITREADY,
  CONTROL_STATUS.ACCEPTED,
  CONTROL_STATUS.NOTAPPLICABLE,
]

export type StatusDropdownItemsProps<T> = {
  statusItems: T[]
  status: T
  isDismissed: boolean
  modelType: MODEL_TYPE
  selectStatus: (newStatus: T, type: MODEL_TYPE) => Promise<void>
}

export type StatusDropdownProps<T> = {
  modelId: string // Can be XID as well
  modelType: MODEL_TYPE
  status: T
  isDismissed: boolean
  mutate?: KeyedMutator<Control>
  model?: Risk | Questionnaire | null
  onToggle?: () => void
  requiredPermissions?: PERMISSIONS[]
}
