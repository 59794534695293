import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import log from 'loglevel'
import { RISK_MATH } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { E2E_IDS } from 'src/Utils/testIds'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { getRiskMathField, updatedRiskRpcData } from '../risks.helpers'
import {
  RiskFields,
  RISK_MATH_DROPDOWN,
  RISK_MATH_LABEL,
} from '../risks.constants'
import {
  RiskExclamationIcon,
  RiskMathContainer,
  RiskMathToggle,
  ToggleIcon,
} from '../risks.styles'
import { useCreateOrUpdateRisks, useRisk } from '../risks.hooks'

export const RisksMathMenu = ({
  riskId,
  field,
  initValue,
  maxValue,
  isReadOnly,
  hasNoBorder,
}: {
  riskId: string
  field: RiskFields
  maxValue?: RISK_MATH
  initValue?: RISK_MATH
  isReadOnly?: boolean
  hasNoBorder?: boolean
}): JSX.Element => {
  const hasEditPermission = useHasRequiredPermissions([
    PERMISSIONS.EDIT,
    PERMISSIONS.READ,
  ])
  const risk = useRisk(riskId)
  const updateRisk = useCreateOrUpdateRisks()
  const [riskMath, setRiskMath] = useState<RISK_MATH>(
    initValue || RISK_MATH.NOT_SET,
  )
  const isNotSet = riskMath === RISK_MATH.NOT_SET
  // Residual Risk can only be set to a value less than or equal to the Inherent Risk
  const values = maxValue
    ? RISK_MATH_DROPDOWN.filter((item) => item.value <= maxValue)
    : RISK_MATH_DROPDOWN

  useEffect(() => {
    // Acts as a chip in read-only mode by using these props
    if ((initValue || initValue === RISK_MATH.NOT_SET) && isReadOnly) {
      setRiskMath(initValue)
      return
    }
    if (!risk) {
      return
    }
    setRiskMath(getRiskMathField(risk, field))
  }, [field, initValue, isReadOnly, risk, riskMath])

  const disableToggle = !hasEditPermission || isReadOnly

  const selectRiskMath = async (newRiskMath: RISK_MATH) => {
    try {
      if (disableToggle || !risk) {
        return
      }
      await updateRisk([updatedRiskRpcData(risk, field, newRiskMath)])
    } catch (err) {
      log.error(`Failed to update risk ${field} for riskId: ${riskId}:`, err)
    }
    return
  }

  return (
    <RiskMathContainer className={`risk-math-${riskMath}`}>
      <Dropdown
        id={E2E_IDS.RISK_MATH_FIELD_MENU(field)}
        data-testid={E2E_IDS.RISK_MATH_FIELD_MENU(field)}
      >
        <RiskMathToggle disabled={disableToggle} $hasNoBorder={hasNoBorder}>
          {isNotSet && <RiskExclamationIcon />}
          {RISK_MATH_LABEL[riskMath]}
          {!disableToggle && <ToggleIcon />}
        </RiskMathToggle>
        <Dropdown.Menu>
          {values.map((item) => (
            <Dropdown.Item
              className={`risk-math-item-${item.value}`}
              key={item.name}
              eventKey={item.value}
              onClick={() => selectRiskMath(item.value)}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </RiskMathContainer>
  )
}
