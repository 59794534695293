// source: risk/risk.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
goog.exportSymbol('proto.risk.CreateOrUpdateRisksRequest', null, global);
goog.exportSymbol('proto.risk.DeleteRisksRequest', null, global);
goog.exportSymbol('proto.risk.DownloadRiskCSVRequest', null, global);
goog.exportSymbol('proto.risk.DownloadRiskCSVResponse', null, global);
goog.exportSymbol('proto.risk.GetRisksByIdsRequest', null, global);
goog.exportSymbol('proto.risk.GetRisksRequest', null, global);
goog.exportSymbol('proto.risk.GetRisksResponse', null, global);
goog.exportSymbol('proto.risk.IsRiskSetFilter', null, global);
goog.exportSymbol('proto.risk.RISK_CATEGORY', null, global);
goog.exportSymbol('proto.risk.RISK_LINK_TYPE', null, global);
goog.exportSymbol('proto.risk.RISK_MATH', null, global);
goog.exportSymbol('proto.risk.RISK_STATUS', null, global);
goog.exportSymbol('proto.risk.RISK_TREATMENT', null, global);
goog.exportSymbol('proto.risk.RISK_TYPE', null, global);
goog.exportSymbol('proto.risk.Risk', null, global);
goog.exportSymbol('proto.risk.RiskFilter', null, global);
goog.exportSymbol('proto.risk.RiskLink', null, global);
goog.exportSymbol('proto.risk.RiskSort', null, global);
goog.exportSymbol('proto.risk.RiskSort.RiskSortTuple', null, global);
goog.exportSymbol('proto.risk.ToggleRiskLinksRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.Risk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.Risk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.Risk.displayName = 'proto.risk.Risk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.IsRiskSetFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.IsRiskSetFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.IsRiskSetFilter.displayName = 'proto.risk.IsRiskSetFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.RiskFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.RiskFilter.repeatedFields_, null);
};
goog.inherits(proto.risk.RiskFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.RiskFilter.displayName = 'proto.risk.RiskFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.RiskSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.RiskSort.repeatedFields_, null);
};
goog.inherits(proto.risk.RiskSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.RiskSort.displayName = 'proto.risk.RiskSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.RiskSort.RiskSortTuple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.RiskSort.RiskSortTuple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.RiskSort.RiskSortTuple.displayName = 'proto.risk.RiskSort.RiskSortTuple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.GetRisksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.GetRisksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.GetRisksRequest.displayName = 'proto.risk.GetRisksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.GetRisksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.GetRisksResponse.repeatedFields_, null);
};
goog.inherits(proto.risk.GetRisksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.GetRisksResponse.displayName = 'proto.risk.GetRisksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.GetRisksByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.GetRisksByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.risk.GetRisksByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.GetRisksByIdsRequest.displayName = 'proto.risk.GetRisksByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.CreateOrUpdateRisksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.CreateOrUpdateRisksRequest.repeatedFields_, null);
};
goog.inherits(proto.risk.CreateOrUpdateRisksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.CreateOrUpdateRisksRequest.displayName = 'proto.risk.CreateOrUpdateRisksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.DeleteRisksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.DeleteRisksRequest.repeatedFields_, null);
};
goog.inherits(proto.risk.DeleteRisksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.DeleteRisksRequest.displayName = 'proto.risk.DeleteRisksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.RiskLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.RiskLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.RiskLink.displayName = 'proto.risk.RiskLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.ToggleRiskLinksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.risk.ToggleRiskLinksRequest.repeatedFields_, null);
};
goog.inherits(proto.risk.ToggleRiskLinksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.ToggleRiskLinksRequest.displayName = 'proto.risk.ToggleRiskLinksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.DownloadRiskCSVRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.DownloadRiskCSVRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.DownloadRiskCSVRequest.displayName = 'proto.risk.DownloadRiskCSVRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.risk.DownloadRiskCSVResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.risk.DownloadRiskCSVResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.risk.DownloadRiskCSVResponse.displayName = 'proto.risk.DownloadRiskCSVResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.Risk.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.Risk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.Risk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.Risk.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    ownerEmail: jspb.Message.getFieldWithDefault(msg, 10, ""),
    predisposingCondition: jspb.Message.getFieldWithDefault(msg, 11, ""),
    assetAtRisk: jspb.Message.getFieldWithDefault(msg, 12, ""),
    impactToCustomer: jspb.Message.getFieldWithDefault(msg, 13, ""),
    likelihood: jspb.Message.getFieldWithDefault(msg, 14, 0),
    adverseImpact: jspb.Message.getFieldWithDefault(msg, 15, 0),
    treatment: jspb.Message.getFieldWithDefault(msg, 16, 0),
    riskAfterTreatment: jspb.Message.getFieldWithDefault(msg, 17, 0),
    category: jspb.Message.getFieldWithDefault(msg, 18, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 19, ""),
    controlIds: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.Risk}
 */
proto.risk.Risk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.Risk;
  return proto.risk.Risk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.Risk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.Risk}
 */
proto.risk.Risk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomId(value);
      break;
    case 9:
      var value = /** @type {!proto.risk.RISK_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerEmail(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPredisposingCondition(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetAtRisk(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpactToCustomer(value);
      break;
    case 14:
      var value = /** @type {!proto.risk.RISK_MATH} */ (reader.readEnum());
      msg.setLikelihood(value);
      break;
    case 15:
      var value = /** @type {!proto.risk.RISK_MATH} */ (reader.readEnum());
      msg.setAdverseImpact(value);
      break;
    case 16:
      var value = /** @type {!proto.risk.RISK_TREATMENT} */ (reader.readEnum());
      msg.setTreatment(value);
      break;
    case 17:
      var value = /** @type {!proto.risk.RISK_MATH} */ (reader.readEnum());
      msg.setRiskAfterTreatment(value);
      break;
    case 18:
      var value = /** @type {!proto.risk.RISK_CATEGORY} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.Risk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.Risk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.Risk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.Risk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getOwnerEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPredisposingCondition();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAssetAtRisk();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getImpactToCustomer();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLikelihood();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getAdverseImpact();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getTreatment();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getRiskAfterTreatment();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getControlIds();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.risk.Risk.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.risk.Risk.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.risk.Risk.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.risk.Risk.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.risk.Risk.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.risk.Risk.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string department_id = 7;
 * @return {string}
 */
proto.risk.Risk.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string custom_id = 8;
 * @return {string}
 */
proto.risk.Risk.prototype.getCustomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setCustomId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional RISK_STATUS status = 9;
 * @return {!proto.risk.RISK_STATUS}
 */
proto.risk.Risk.prototype.getStatus = function() {
  return /** @type {!proto.risk.RISK_STATUS} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.risk.RISK_STATUS} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string owner_email = 10;
 * @return {string}
 */
proto.risk.Risk.prototype.getOwnerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string predisposing_condition = 11;
 * @return {string}
 */
proto.risk.Risk.prototype.getPredisposingCondition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setPredisposingCondition = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string asset_at_risk = 12;
 * @return {string}
 */
proto.risk.Risk.prototype.getAssetAtRisk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setAssetAtRisk = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string impact_to_customer = 13;
 * @return {string}
 */
proto.risk.Risk.prototype.getImpactToCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setImpactToCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional RISK_MATH likelihood = 14;
 * @return {!proto.risk.RISK_MATH}
 */
proto.risk.Risk.prototype.getLikelihood = function() {
  return /** @type {!proto.risk.RISK_MATH} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.risk.RISK_MATH} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setLikelihood = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional RISK_MATH adverse_impact = 15;
 * @return {!proto.risk.RISK_MATH}
 */
proto.risk.Risk.prototype.getAdverseImpact = function() {
  return /** @type {!proto.risk.RISK_MATH} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.risk.RISK_MATH} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setAdverseImpact = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional RISK_TREATMENT treatment = 16;
 * @return {!proto.risk.RISK_TREATMENT}
 */
proto.risk.Risk.prototype.getTreatment = function() {
  return /** @type {!proto.risk.RISK_TREATMENT} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.risk.RISK_TREATMENT} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setTreatment = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional RISK_MATH risk_after_treatment = 17;
 * @return {!proto.risk.RISK_MATH}
 */
proto.risk.Risk.prototype.getRiskAfterTreatment = function() {
  return /** @type {!proto.risk.RISK_MATH} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.risk.RISK_MATH} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setRiskAfterTreatment = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional RISK_CATEGORY category = 18;
 * @return {!proto.risk.RISK_CATEGORY}
 */
proto.risk.Risk.prototype.getCategory = function() {
  return /** @type {!proto.risk.RISK_CATEGORY} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.risk.RISK_CATEGORY} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string notes = 19;
 * @return {string}
 */
proto.risk.Risk.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string control_ids = 20;
 * @return {string}
 */
proto.risk.Risk.prototype.getControlIds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.Risk} returns this
 */
proto.risk.Risk.prototype.setControlIds = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.IsRiskSetFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.IsRiskSetFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.IsRiskSetFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.IsRiskSetFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isSet: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.IsRiskSetFilter}
 */
proto.risk.IsRiskSetFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.IsRiskSetFilter;
  return proto.risk.IsRiskSetFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.IsRiskSetFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.IsRiskSetFilter}
 */
proto.risk.IsRiskSetFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.risk.RISK_TYPE} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.IsRiskSetFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.IsRiskSetFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.IsRiskSetFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.IsRiskSetFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsSet();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional RISK_TYPE type = 1;
 * @return {!proto.risk.RISK_TYPE}
 */
proto.risk.IsRiskSetFilter.prototype.getType = function() {
  return /** @type {!proto.risk.RISK_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.risk.RISK_TYPE} value
 * @return {!proto.risk.IsRiskSetFilter} returns this
 */
proto.risk.IsRiskSetFilter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_set = 2;
 * @return {boolean}
 */
proto.risk.IsRiskSetFilter.prototype.getIsSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.risk.IsRiskSetFilter} returns this
 */
proto.risk.IsRiskSetFilter.prototype.setIsSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.RiskFilter.repeatedFields_ = [1,2,3,5,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.RiskFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.RiskFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.RiskFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    departmentIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    includeNoDepartment: (f = msg.getIncludeNoDepartment()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    ownerEmailsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    includeUnassigned: (f = msg.getIncludeUnassigned()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    riskMathFiltersList: jspb.Message.toObjectList(msg.getRiskMathFiltersList(),
    proto.risk.IsRiskSetFilter.toObject, includeInstance),
    vendorIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.RiskFilter}
 */
proto.risk.RiskFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.RiskFilter;
  return proto.risk.RiskFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.RiskFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.RiskFilter}
 */
proto.risk.RiskFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRiskIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartmentIds(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNoDepartment(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOwnerEmails(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeUnassigned(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.risk.RISK_STATUS>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 8:
      var value = new proto.risk.IsRiskSetFilter;
      reader.readMessage(value,proto.risk.IsRiskSetFilter.deserializeBinaryFromReader);
      msg.addRiskMathFilters(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addVendorIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.RiskFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.RiskFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.RiskFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDepartmentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getIncludeNoDepartment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOwnerEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getIncludeUnassigned();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getRiskMathFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.risk.IsRiskSetFilter.serializeBinaryToWriter
    );
  }
  f = message.getVendorIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * repeated string risk_ids = 1;
 * @return {!Array<string>}
 */
proto.risk.RiskFilter.prototype.getRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.setRiskIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.addRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearRiskIdsList = function() {
  return this.setRiskIdsList([]);
};


/**
 * repeated string control_ids = 2;
 * @return {!Array<string>}
 */
proto.risk.RiskFilter.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * repeated string department_ids = 3;
 * @return {!Array<string>}
 */
proto.risk.RiskFilter.prototype.getDepartmentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.setDepartmentIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.addDepartmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearDepartmentIdsList = function() {
  return this.setDepartmentIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_no_department = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.risk.RiskFilter.prototype.getIncludeNoDepartment = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.risk.RiskFilter} returns this
*/
proto.risk.RiskFilter.prototype.setIncludeNoDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearIncludeNoDepartment = function() {
  return this.setIncludeNoDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.risk.RiskFilter.prototype.hasIncludeNoDepartment = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string owner_emails = 5;
 * @return {!Array<string>}
 */
proto.risk.RiskFilter.prototype.getOwnerEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.setOwnerEmailsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.addOwnerEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearOwnerEmailsList = function() {
  return this.setOwnerEmailsList([]);
};


/**
 * optional google.protobuf.BoolValue include_unassigned = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.risk.RiskFilter.prototype.getIncludeUnassigned = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.risk.RiskFilter} returns this
*/
proto.risk.RiskFilter.prototype.setIncludeUnassigned = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearIncludeUnassigned = function() {
  return this.setIncludeUnassigned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.risk.RiskFilter.prototype.hasIncludeUnassigned = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated RISK_STATUS statuses = 7;
 * @return {!Array<!proto.risk.RISK_STATUS>}
 */
proto.risk.RiskFilter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.risk.RISK_STATUS>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.risk.RISK_STATUS>} value
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.risk.RISK_STATUS} value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * repeated IsRiskSetFilter risk_math_filters = 8;
 * @return {!Array<!proto.risk.IsRiskSetFilter>}
 */
proto.risk.RiskFilter.prototype.getRiskMathFiltersList = function() {
  return /** @type{!Array<!proto.risk.IsRiskSetFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.risk.IsRiskSetFilter, 8));
};


/**
 * @param {!Array<!proto.risk.IsRiskSetFilter>} value
 * @return {!proto.risk.RiskFilter} returns this
*/
proto.risk.RiskFilter.prototype.setRiskMathFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.risk.IsRiskSetFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.risk.IsRiskSetFilter}
 */
proto.risk.RiskFilter.prototype.addRiskMathFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.risk.IsRiskSetFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearRiskMathFiltersList = function() {
  return this.setRiskMathFiltersList([]);
};


/**
 * repeated string vendor_ids = 9;
 * @return {!Array<string>}
 */
proto.risk.RiskFilter.prototype.getVendorIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.setVendorIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.addVendorIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskFilter} returns this
 */
proto.risk.RiskFilter.prototype.clearVendorIdsList = function() {
  return this.setVendorIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.RiskSort.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.RiskSort.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.RiskSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.RiskSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortListList: jspb.Message.toObjectList(msg.getSortListList(),
    proto.risk.RiskSort.RiskSortTuple.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.RiskSort}
 */
proto.risk.RiskSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.RiskSort;
  return proto.risk.RiskSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.RiskSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.RiskSort}
 */
proto.risk.RiskSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.risk.RiskSort.RiskSortTuple;
      reader.readMessage(value,proto.risk.RiskSort.RiskSortTuple.deserializeBinaryFromReader);
      msg.addSortList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.RiskSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.RiskSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.RiskSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.risk.RiskSort.RiskSortTuple.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.RiskSort.RiskSortTuple.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.RiskSort.RiskSortTuple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.RiskSort.RiskSortTuple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskSort.RiskSortTuple.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.RiskSort.RiskSortTuple}
 */
proto.risk.RiskSort.RiskSortTuple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.RiskSort.RiskSortTuple;
  return proto.risk.RiskSort.RiskSortTuple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.RiskSort.RiskSortTuple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.RiskSort.RiskSortTuple}
 */
proto.risk.RiskSort.RiskSortTuple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.RiskSort.RiskSortTuple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.RiskSort.RiskSortTuple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.RiskSort.RiskSortTuple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskSort.RiskSortTuple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.risk.RiskSort.RiskSortTuple.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.RiskSort.RiskSortTuple} returns this
 */
proto.risk.RiskSort.RiskSortTuple.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string direction = 2;
 * @return {string}
 */
proto.risk.RiskSort.RiskSortTuple.prototype.getDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.RiskSort.RiskSortTuple} returns this
 */
proto.risk.RiskSort.RiskSortTuple.prototype.setDirection = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RiskSortTuple sort_list = 1;
 * @return {!Array<!proto.risk.RiskSort.RiskSortTuple>}
 */
proto.risk.RiskSort.prototype.getSortListList = function() {
  return /** @type{!Array<!proto.risk.RiskSort.RiskSortTuple>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.risk.RiskSort.RiskSortTuple, 1));
};


/**
 * @param {!Array<!proto.risk.RiskSort.RiskSortTuple>} value
 * @return {!proto.risk.RiskSort} returns this
*/
proto.risk.RiskSort.prototype.setSortListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.risk.RiskSort.RiskSortTuple=} opt_value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskSort.RiskSortTuple}
 */
proto.risk.RiskSort.prototype.addSortList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.risk.RiskSort.RiskSortTuple, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.RiskSort} returns this
 */
proto.risk.RiskSort.prototype.clearSortListList = function() {
  return this.setSortListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.GetRisksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.GetRisksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.GetRisksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.GetRisksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterBy: (f = msg.getFilterBy()) && proto.risk.RiskFilter.toObject(includeInstance, f),
    sortBy: (f = msg.getSortBy()) && proto.risk.RiskSort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.GetRisksRequest}
 */
proto.risk.GetRisksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.GetRisksRequest;
  return proto.risk.GetRisksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.GetRisksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.GetRisksRequest}
 */
proto.risk.GetRisksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.risk.RiskFilter;
      reader.readMessage(value,proto.risk.RiskFilter.deserializeBinaryFromReader);
      msg.setFilterBy(value);
      break;
    case 2:
      var value = new proto.risk.RiskSort;
      reader.readMessage(value,proto.risk.RiskSort.deserializeBinaryFromReader);
      msg.setSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.GetRisksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.GetRisksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.GetRisksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.GetRisksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterBy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.risk.RiskFilter.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.risk.RiskSort.serializeBinaryToWriter
    );
  }
};


/**
 * optional RiskFilter filter_by = 1;
 * @return {?proto.risk.RiskFilter}
 */
proto.risk.GetRisksRequest.prototype.getFilterBy = function() {
  return /** @type{?proto.risk.RiskFilter} */ (
    jspb.Message.getWrapperField(this, proto.risk.RiskFilter, 1));
};


/**
 * @param {?proto.risk.RiskFilter|undefined} value
 * @return {!proto.risk.GetRisksRequest} returns this
*/
proto.risk.GetRisksRequest.prototype.setFilterBy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.risk.GetRisksRequest} returns this
 */
proto.risk.GetRisksRequest.prototype.clearFilterBy = function() {
  return this.setFilterBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.risk.GetRisksRequest.prototype.hasFilterBy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RiskSort sort_by = 2;
 * @return {?proto.risk.RiskSort}
 */
proto.risk.GetRisksRequest.prototype.getSortBy = function() {
  return /** @type{?proto.risk.RiskSort} */ (
    jspb.Message.getWrapperField(this, proto.risk.RiskSort, 2));
};


/**
 * @param {?proto.risk.RiskSort|undefined} value
 * @return {!proto.risk.GetRisksRequest} returns this
*/
proto.risk.GetRisksRequest.prototype.setSortBy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.risk.GetRisksRequest} returns this
 */
proto.risk.GetRisksRequest.prototype.clearSortBy = function() {
  return this.setSortBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.risk.GetRisksRequest.prototype.hasSortBy = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.GetRisksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.GetRisksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.GetRisksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.GetRisksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.GetRisksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    risksList: jspb.Message.toObjectList(msg.getRisksList(),
    proto.risk.Risk.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.GetRisksResponse}
 */
proto.risk.GetRisksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.GetRisksResponse;
  return proto.risk.GetRisksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.GetRisksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.GetRisksResponse}
 */
proto.risk.GetRisksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.risk.Risk;
      reader.readMessage(value,proto.risk.Risk.deserializeBinaryFromReader);
      msg.addRisks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.GetRisksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.GetRisksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.GetRisksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.GetRisksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.risk.Risk.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Risk risks = 1;
 * @return {!Array<!proto.risk.Risk>}
 */
proto.risk.GetRisksResponse.prototype.getRisksList = function() {
  return /** @type{!Array<!proto.risk.Risk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.risk.Risk, 1));
};


/**
 * @param {!Array<!proto.risk.Risk>} value
 * @return {!proto.risk.GetRisksResponse} returns this
*/
proto.risk.GetRisksResponse.prototype.setRisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.risk.Risk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.risk.Risk}
 */
proto.risk.GetRisksResponse.prototype.addRisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.risk.Risk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.GetRisksResponse} returns this
 */
proto.risk.GetRisksResponse.prototype.clearRisksList = function() {
  return this.setRisksList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.risk.GetRisksResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.risk.GetRisksResponse} returns this
 */
proto.risk.GetRisksResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.GetRisksByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.GetRisksByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.GetRisksByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.GetRisksByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.GetRisksByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.GetRisksByIdsRequest}
 */
proto.risk.GetRisksByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.GetRisksByIdsRequest;
  return proto.risk.GetRisksByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.GetRisksByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.GetRisksByIdsRequest}
 */
proto.risk.GetRisksByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRiskIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.GetRisksByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.GetRisksByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.GetRisksByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.GetRisksByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string risk_ids = 1;
 * @return {!Array<string>}
 */
proto.risk.GetRisksByIdsRequest.prototype.getRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.GetRisksByIdsRequest} returns this
 */
proto.risk.GetRisksByIdsRequest.prototype.setRiskIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.GetRisksByIdsRequest} returns this
 */
proto.risk.GetRisksByIdsRequest.prototype.addRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.GetRisksByIdsRequest} returns this
 */
proto.risk.GetRisksByIdsRequest.prototype.clearRiskIdsList = function() {
  return this.setRiskIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.CreateOrUpdateRisksRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.CreateOrUpdateRisksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.CreateOrUpdateRisksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.CreateOrUpdateRisksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.CreateOrUpdateRisksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    risksList: jspb.Message.toObjectList(msg.getRisksList(),
    proto.risk.Risk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.CreateOrUpdateRisksRequest}
 */
proto.risk.CreateOrUpdateRisksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.CreateOrUpdateRisksRequest;
  return proto.risk.CreateOrUpdateRisksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.CreateOrUpdateRisksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.CreateOrUpdateRisksRequest}
 */
proto.risk.CreateOrUpdateRisksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.risk.Risk;
      reader.readMessage(value,proto.risk.Risk.deserializeBinaryFromReader);
      msg.addRisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.CreateOrUpdateRisksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.CreateOrUpdateRisksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.CreateOrUpdateRisksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.CreateOrUpdateRisksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.risk.Risk.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Risk risks = 1;
 * @return {!Array<!proto.risk.Risk>}
 */
proto.risk.CreateOrUpdateRisksRequest.prototype.getRisksList = function() {
  return /** @type{!Array<!proto.risk.Risk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.risk.Risk, 1));
};


/**
 * @param {!Array<!proto.risk.Risk>} value
 * @return {!proto.risk.CreateOrUpdateRisksRequest} returns this
*/
proto.risk.CreateOrUpdateRisksRequest.prototype.setRisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.risk.Risk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.risk.Risk}
 */
proto.risk.CreateOrUpdateRisksRequest.prototype.addRisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.risk.Risk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.CreateOrUpdateRisksRequest} returns this
 */
proto.risk.CreateOrUpdateRisksRequest.prototype.clearRisksList = function() {
  return this.setRisksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.DeleteRisksRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.DeleteRisksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.DeleteRisksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.DeleteRisksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.DeleteRisksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.DeleteRisksRequest}
 */
proto.risk.DeleteRisksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.DeleteRisksRequest;
  return proto.risk.DeleteRisksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.DeleteRisksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.DeleteRisksRequest}
 */
proto.risk.DeleteRisksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRiskIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.DeleteRisksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.DeleteRisksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.DeleteRisksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.DeleteRisksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string risk_ids = 1;
 * @return {!Array<string>}
 */
proto.risk.DeleteRisksRequest.prototype.getRiskIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.risk.DeleteRisksRequest} returns this
 */
proto.risk.DeleteRisksRequest.prototype.setRiskIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.risk.DeleteRisksRequest} returns this
 */
proto.risk.DeleteRisksRequest.prototype.addRiskIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.DeleteRisksRequest} returns this
 */
proto.risk.DeleteRisksRequest.prototype.clearRiskIdsList = function() {
  return this.setRiskIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.RiskLink.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.RiskLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.RiskLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    modelType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.RiskLink}
 */
proto.risk.RiskLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.RiskLink;
  return proto.risk.RiskLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.RiskLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.RiskLink}
 */
proto.risk.RiskLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModelType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.RiskLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.RiskLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.RiskLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.RiskLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string risk_id = 1;
 * @return {string}
 */
proto.risk.RiskLink.prototype.getRiskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.RiskLink} returns this
 */
proto.risk.RiskLink.prototype.setRiskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.risk.RiskLink.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.risk.RiskLink} returns this
 */
proto.risk.RiskLink.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.MODEL_TYPE model_type = 3;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.risk.RiskLink.prototype.getModelType = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.risk.RiskLink} returns this
 */
proto.risk.RiskLink.prototype.setModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.risk.ToggleRiskLinksRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.ToggleRiskLinksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.ToggleRiskLinksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.ToggleRiskLinksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.ToggleRiskLinksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.risk.RiskLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.ToggleRiskLinksRequest}
 */
proto.risk.ToggleRiskLinksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.ToggleRiskLinksRequest;
  return proto.risk.ToggleRiskLinksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.ToggleRiskLinksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.ToggleRiskLinksRequest}
 */
proto.risk.ToggleRiskLinksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.risk.RISK_LINK_TYPE} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.risk.RiskLink;
      reader.readMessage(value,proto.risk.RiskLink.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.ToggleRiskLinksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.ToggleRiskLinksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.ToggleRiskLinksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.ToggleRiskLinksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.risk.RiskLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional RISK_LINK_TYPE type = 1;
 * @return {!proto.risk.RISK_LINK_TYPE}
 */
proto.risk.ToggleRiskLinksRequest.prototype.getType = function() {
  return /** @type {!proto.risk.RISK_LINK_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.risk.RISK_LINK_TYPE} value
 * @return {!proto.risk.ToggleRiskLinksRequest} returns this
 */
proto.risk.ToggleRiskLinksRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated RiskLink links = 2;
 * @return {!Array<!proto.risk.RiskLink>}
 */
proto.risk.ToggleRiskLinksRequest.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.risk.RiskLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.risk.RiskLink, 2));
};


/**
 * @param {!Array<!proto.risk.RiskLink>} value
 * @return {!proto.risk.ToggleRiskLinksRequest} returns this
*/
proto.risk.ToggleRiskLinksRequest.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.risk.RiskLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.risk.RiskLink}
 */
proto.risk.ToggleRiskLinksRequest.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.risk.RiskLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.risk.ToggleRiskLinksRequest} returns this
 */
proto.risk.ToggleRiskLinksRequest.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.DownloadRiskCSVRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.DownloadRiskCSVRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.DownloadRiskCSVRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.DownloadRiskCSVRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterBy: (f = msg.getFilterBy()) && proto.risk.RiskFilter.toObject(includeInstance, f),
    sortBy: (f = msg.getSortBy()) && proto.risk.RiskSort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.DownloadRiskCSVRequest}
 */
proto.risk.DownloadRiskCSVRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.DownloadRiskCSVRequest;
  return proto.risk.DownloadRiskCSVRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.DownloadRiskCSVRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.DownloadRiskCSVRequest}
 */
proto.risk.DownloadRiskCSVRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.risk.RiskFilter;
      reader.readMessage(value,proto.risk.RiskFilter.deserializeBinaryFromReader);
      msg.setFilterBy(value);
      break;
    case 2:
      var value = new proto.risk.RiskSort;
      reader.readMessage(value,proto.risk.RiskSort.deserializeBinaryFromReader);
      msg.setSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.DownloadRiskCSVRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.DownloadRiskCSVRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.DownloadRiskCSVRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.DownloadRiskCSVRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterBy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.risk.RiskFilter.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.risk.RiskSort.serializeBinaryToWriter
    );
  }
};


/**
 * optional RiskFilter filter_by = 1;
 * @return {?proto.risk.RiskFilter}
 */
proto.risk.DownloadRiskCSVRequest.prototype.getFilterBy = function() {
  return /** @type{?proto.risk.RiskFilter} */ (
    jspb.Message.getWrapperField(this, proto.risk.RiskFilter, 1));
};


/**
 * @param {?proto.risk.RiskFilter|undefined} value
 * @return {!proto.risk.DownloadRiskCSVRequest} returns this
*/
proto.risk.DownloadRiskCSVRequest.prototype.setFilterBy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.risk.DownloadRiskCSVRequest} returns this
 */
proto.risk.DownloadRiskCSVRequest.prototype.clearFilterBy = function() {
  return this.setFilterBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.risk.DownloadRiskCSVRequest.prototype.hasFilterBy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RiskSort sort_by = 2;
 * @return {?proto.risk.RiskSort}
 */
proto.risk.DownloadRiskCSVRequest.prototype.getSortBy = function() {
  return /** @type{?proto.risk.RiskSort} */ (
    jspb.Message.getWrapperField(this, proto.risk.RiskSort, 2));
};


/**
 * @param {?proto.risk.RiskSort|undefined} value
 * @return {!proto.risk.DownloadRiskCSVRequest} returns this
*/
proto.risk.DownloadRiskCSVRequest.prototype.setSortBy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.risk.DownloadRiskCSVRequest} returns this
 */
proto.risk.DownloadRiskCSVRequest.prototype.clearSortBy = function() {
  return this.setSortBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.risk.DownloadRiskCSVRequest.prototype.hasSortBy = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.risk.DownloadRiskCSVResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.risk.DownloadRiskCSVResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.risk.DownloadRiskCSVResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.DownloadRiskCSVResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.risk.DownloadRiskCSVResponse}
 */
proto.risk.DownloadRiskCSVResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.risk.DownloadRiskCSVResponse;
  return proto.risk.DownloadRiskCSVResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.risk.DownloadRiskCSVResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.risk.DownloadRiskCSVResponse}
 */
proto.risk.DownloadRiskCSVResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.risk.DownloadRiskCSVResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.risk.DownloadRiskCSVResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.risk.DownloadRiskCSVResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.risk.DownloadRiskCSVResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.risk.RISK_TYPE = {
  INHERENT: 0,
  RESIDUAL: 1
};

/**
 * @enum {number}
 */
proto.risk.RISK_STATUS = {
  TODO: 0,
  IN_PROGRESS: 1,
  PENDING: 2,
  COMPLETED: 3
};

/**
 * @enum {number}
 */
proto.risk.RISK_MATH = {
  NOT_SET: 0,
  VERY_LOW: 1,
  LOW: 2,
  MODERATE: 3,
  HIGH: 4,
  VERY_HIGH: 5
};

/**
 * @enum {number}
 */
proto.risk.RISK_TREATMENT = {
  ACCEPT: 0,
  AVOID: 1,
  MITIGATE: 2,
  SHARE: 3,
  TRANSFER: 4
};

/**
 * @enum {number}
 */
proto.risk.RISK_CATEGORY = {
  NO_CATEGORY: 0,
  CUSTOMER: 1,
  VENDOR: 2,
  LEADERSHIP: 3,
  SYSTEMS: 4,
  LAWS: 5,
  IP: 6
};

/**
 * @enum {number}
 */
proto.risk.RISK_LINK_TYPE = {
  LINK: 0,
  UNLINK: 1
};

goog.object.extend(exports, proto.risk);
