import React, { MouseEventHandler, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import log from 'loglevel'
import FileSaver from 'file-saver'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { editDocumentMarkdown } from 'src/pages/Policies/Policies.helpers'
import AttachmentAdapter from '../../../../../adapter/AttachmentAdapter'
import AuthContext from '../../../../../context/authContext'
import { Spinner, ThrobberContext } from '../../../../../Throbber'

export function useDocumentOnClick({
  documentId,
  setMarkdownBody,
}: {
  documentId: string
  setMarkdownBody: React.Dispatch<React.SetStateAction<string>>
}): MouseEventHandler<HTMLButtonElement> | undefined {
  const { authCtx, authDispatch } = useContext(AuthContext)
  const { setThrobberState } = useContext(ThrobberContext)

  const navigate = useNavigate()
  const location = useLocation()

  const downloadDocumentBody = (documentId: string) => {
    return async (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      setThrobberState({
        isShown: true,
        Display: <Spinner color="primary" size="xl" />,
      })

      const document = await AttachmentAdapter.getDocumentById(
        documentId,
        authCtx,
        authDispatch,
      ).catch((e) => log.error('Error in useDocumentOnClick', e))

      setThrobberState({
        isShown: false,
        Display: <></>,
      })

      if (
        !document ||
        !document.material ||
        !(
          document.material instanceof Blob || document.mime === 'text/markdown'
        )
      ) {
        showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        return
      }

      if (document.material instanceof Blob) {
        FileSaver.saveAs(document.material)
      } else if (document.mime === 'text/markdown') {
        setMarkdownBody(document.material)
        editDocumentMarkdown(
          documentId,
          document.material,
          setMarkdownBody,
          navigate,
          location,
        )
      }
    }
  }

  return downloadDocumentBody(documentId)
}
