import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { SpaceProps } from 'styled-system'
import {
  UserRecord,
  UserState,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { NONE_ID } from 'src/Utils/globalConstants'
import useClickOutside from '../../../../../Utils/useClickOutside'
import {
  DropdownItem,
  DropdownItemUser,
  DropdownMenu,
  DropdownSelectedIcon,
} from '../../../../../components/Reusable/Dropdown/styles'
import { Tooltip } from '../../../../../components/Reusable/Tooltip'
import { Gravatar } from '../../../../../components/Gravatar'
import { useUsers } from '../../../../../components/async/Users/useUsers'
import { useAddOrUpdateDocumentRequest } from '../../../../../components/async/DocumentRequest/useDocumentRequests'
import * as keyDown from '../../../../../components/Reusable/Dropdown/keyboardUtils'
import {
  IconButton,
  IconButtonVariant,
} from '../../../../../components/Reusable/Buttons/IconButton'
import { Assignee } from '../../../../../Utils/globalEnums'

const OwnerAssignmentContainer = styled.div`
  position: relative;
`

type RequestOwnerButtonProps = SpaceProps & {
  id: string
  ownerId: string
  isDisabled: boolean
  onUpdate?: () => void
}

export const RequestOwnerButton = ({
  id,
  ownerId = Assignee.UNASSIGNED,
  isDisabled = false,
  onUpdate,
  ...spaceProps
}: RequestOwnerButtonProps): JSX.Element => {
  const [menuIsToggled, setMenuIsToggled] = useState(false)
  const containerRef = useClickOutside(() => {
    setMenuIsToggled(false)
  }) as RefObject<HTMLDivElement>

  const [showTooltip, setShowTooltip] = useState(false)

  const updateDocumentRequest = useAddOrUpdateDocumentRequest()

  const response = useUsers()

  const activeUsers = useMemo(() => {
    const getUsers: UserRecord.AsObject[] = [
      {
        id: NONE_ID,
        name: Assignee.UNASSIGNED,
        email: Assignee.UNASSIGNED,
      } as UserRecord.AsObject,
    ]

    if (response.data) {
      getUsers.push(
        ...response.data
          .getUsersList()
          .map((userMsg) => {
            return userMsg.toObject()
          })
          .filter((user) => user.state === UserState.USER_ACTIVE),
      )
    }

    return getUsers
  }, [response.data])

  const initialUser = useMemo(() => {
    const getUser =
      response.data
        ?.getUsersList()
        .map((userMsg) => {
          return userMsg.toObject()
        })
        .find((user) => user.id === ownerId) ??
      ({
        id: NONE_ID,
        name: Assignee.UNASSIGNED,
        email: Assignee.UNASSIGNED,
      } as UserRecord.AsObject)
    return getUser
  }, [response.data, ownerId])

  // List of nodes
  const nodesRef = useRef<HTMLButtonElement[]>([])
  // If the list of users change, update the length of nodesRef
  useEffect(() => {
    nodesRef.current = nodesRef.current.slice(0, activeUsers.length)
  }, [activeUsers])

  return (
    <>
      <Tooltip
        id={`owner-assignment-tooltip-${id}`}
        show={showTooltip && !menuIsToggled}
        onToggle={(nextShow) => {
          !isDisabled && setShowTooltip(menuIsToggled ? false : nextShow)
        }}
        placement="top"
        tooltipBody="Change Owner"
      >
        <OwnerAssignmentContainer ref={containerRef}>
          <IconButton
            disabled={isDisabled}
            variant={IconButtonVariant.icon}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowTooltip(false)
              setMenuIsToggled((state) => !state)
            }}
            onKeyDown={keyDown.toggle(nodesRef, setMenuIsToggled)}
            {...spaceProps}
          >
            <Gravatar email={initialUser.email} />
          </IconButton>
          <DropdownMenu
            aria-expanded={menuIsToggled}
            role="list"
            isToggled={menuIsToggled}
            right={0}
            width="fit-content"
            maxWidth="350px"
          >
            {activeUsers.map((user, idx) => (
              <DropdownItem
                key={user.id}
                ref={(node) => {
                  if (node && node !== nodesRef.current[idx]) {
                    nodesRef.current[idx] = node
                  }
                }}
                data-email={user.email}
                onClick={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setMenuIsToggled(false)

                  await updateDocumentRequest({
                    id,
                    ownerId: user.id,
                  })
                  onUpdate && onUpdate()
                }}
                onKeyDown={keyDown.item(idx, nodesRef, setMenuIsToggled)}
              >
                <DropdownItemUser>
                  <Gravatar email={user.email} />
                  <p>{user.name || user.email}</p>
                </DropdownItemUser>
                {user.id === ownerId ? <DropdownSelectedIcon /> : <></>}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </OwnerAssignmentContainer>
      </Tooltip>
    </>
  )
}
