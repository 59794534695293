import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import { Navigate } from 'react-router-dom'
import { Gatekeeper, GatekeeperFlag } from '../context/Gatekeeper'
import Register from './register.component'
import SignupSuccess from './SignupSuccess'
import SignupConfirm from './SignupConfirm'

const SignupFlowGated = (props) => {
  return (
    <Gatekeeper
      renderOff={() => <Navigate to="/" />}
      authorizedFlags={[GatekeeperFlag.REGISTER_PAGES]}
    >
      <SignupFlow {...props} />
    </Gatekeeper>
  )
}

const SignupFlow = (props) => {
  const [callid, setCallid] = useState(
    (props.match && props.match.params.callid) || undefined,
  )
  const [state, setState] = useState(callid ? 'confirm' : 'initial')

  function signupCallback(state, callid) {
    setCallid(callid)
    setState(state)
  }

  switch (state) {
    case 'initial':
      return <Register callback={signupCallback} />
    case 'success':
      return <SignupSuccess callid={callid} />
    case 'confirm':
      return <SignupConfirm callid={callid} />
    default:
      throw new Error(`Unknown Signup state: [${state}]`)
  }
}

SignupFlow.propTypes = {
  match: PropTypes.object,
}

export default SignupFlowGated
