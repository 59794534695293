import {
  PROVIDERS,
  PROVIDER_ROLE,
  ProviderRoleInstance,
} from '@trustero/trustero-api-web/lib/provider/provider_pb'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'

export type UniqueProviderRow = {
  id: string
  provider: ProviderRoleInstance
}

export enum ProviderLabels {
  CUSTOM = 'Custom', // This option should not be shown in the dropdown
  AMAZON_WEB_SERVICES = 'Amazon Web Services',
  BITBUCKET = 'Bitbucket',
  GITHUB = 'GitHub',
  GITLAB = 'GitLab',
  GOOGLE_CLOUD_PLATFORM = 'Google Cloud Platform',
  MICROSOFT_AZURE = 'Microsoft Azure',
  SOURCEFORGE = 'SourceForge',
  HEROKU = 'Heroku',
  MICROSOFT_AZURE_DEVOPS = 'Microsoft Azure DevOps',
  AWS_CODECOMMIT = 'AWS CodeCommit',
}

export const PROVIDER_TO_LABEL: Record<number, ProviderLabels> = {
  [PROVIDERS.CUSTOM]: ProviderLabels.CUSTOM, // This option should not be shown in the dropdown
  [PROVIDERS.AMAZON_WEB_SERVICES]: ProviderLabels.AMAZON_WEB_SERVICES,
  [PROVIDERS.BITBUCKET]: ProviderLabels.BITBUCKET,
  [PROVIDERS.GITHUB]: ProviderLabels.GITHUB,
  [PROVIDERS.GITLAB]: ProviderLabels.GITLAB,
  [PROVIDERS.GOOGLE_CLOUD_PLATFORM]: ProviderLabels.GOOGLE_CLOUD_PLATFORM,
  [PROVIDERS.MICROSOFT_AZURE]: ProviderLabels.MICROSOFT_AZURE,
  [PROVIDERS.SOURCEFORGE]: ProviderLabels.SOURCEFORGE,
  [PROVIDERS.HEROKU]: ProviderLabels.HEROKU,
  [PROVIDERS.MICROSOFT_AZURE_DEVOPS]: ProviderLabels.MICROSOFT_AZURE_DEVOPS,
  [PROVIDERS.AWS_CODECOMMIT]: ProviderLabels.AWS_CODECOMMIT,
}

export const PROVIDER_LABEL_TO_PROVIDER: Record<ProviderLabels, PROVIDERS> = {
  [ProviderLabels.CUSTOM]: PROVIDERS.CUSTOM, // This option should not be shown in the dropdown
  [ProviderLabels.AMAZON_WEB_SERVICES]: PROVIDERS.AMAZON_WEB_SERVICES,
  [ProviderLabels.BITBUCKET]: PROVIDERS.BITBUCKET,
  [ProviderLabels.GITHUB]: PROVIDERS.GITHUB,
  [ProviderLabels.GITLAB]: PROVIDERS.GITLAB,
  [ProviderLabels.GOOGLE_CLOUD_PLATFORM]: PROVIDERS.GOOGLE_CLOUD_PLATFORM,
  [ProviderLabels.MICROSOFT_AZURE]: PROVIDERS.MICROSOFT_AZURE,
  [ProviderLabels.SOURCEFORGE]: PROVIDERS.SOURCEFORGE,
  [ProviderLabels.HEROKU]: PROVIDERS.HEROKU,
  [ProviderLabels.MICROSOFT_AZURE_DEVOPS]: PROVIDERS.MICROSOFT_AZURE_DEVOPS,
  [ProviderLabels.AWS_CODECOMMIT]: PROVIDERS.AWS_CODECOMMIT,
}

// We keep the provider-role mapping in the FE due to golang only allowing for int32 enums
export const CLOUD_HOSTING_PROVIDERS: SelectItem[] = [
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.AMAZON_WEB_SERVICES],
    value: PROVIDERS.AMAZON_WEB_SERVICES,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.GOOGLE_CLOUD_PLATFORM],
    value: PROVIDERS.GOOGLE_CLOUD_PLATFORM,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.MICROSOFT_AZURE],
    value: PROVIDERS.MICROSOFT_AZURE,
  },
]

export const SOURCE_CODE_MANAGEMENT_PROVIDERS: SelectItem[] = [
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.GITHUB],
    value: PROVIDERS.GITHUB,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.SOURCEFORGE],
    value: PROVIDERS.SOURCEFORGE,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.BITBUCKET],
    value: PROVIDERS.BITBUCKET,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.GITLAB],
    value: PROVIDERS.GITLAB,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.HEROKU],
    value: PROVIDERS.HEROKU,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.MICROSOFT_AZURE_DEVOPS],
    value: PROVIDERS.MICROSOFT_AZURE_DEVOPS,
  },
  {
    name: PROVIDER_TO_LABEL[PROVIDERS.AWS_CODECOMMIT],
    value: PROVIDERS.AWS_CODECOMMIT,
  },
]

export const PROVIDER_ROLE_ITEMS: SelectItem[] = [
  {
    name: 'Cloud Hosting',
    value: PROVIDER_ROLE.CLOUD_HOSTING,
  },
  {
    name: 'Source Code Management',
    value: PROVIDER_ROLE.SOURCE_CODE_MANAGEMENT,
  },
]
