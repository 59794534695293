import styled from 'styled-components/macro'

export const NotCoveredContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 350px;
  overflow-y: auto;
`

export const NotCoveredNames = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
`

export const NotCoveredButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
`
