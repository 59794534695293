import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ATTACHMENT_TYPE } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import AuthContext from 'src/context/authContext'
import { useAudit } from '../../../components/async/model/audit'
import { ControlContent } from './ControlContent'
import { PolicyContent } from './PolicyContent'
import { NON_LINKABLE_VERBS } from './Subject.constants'
import { SubjectContainer } from './Subject.styles'

type Props = {
  auditId: string
  attachmentId?: string
  attachmentType?: ATTACHMENT_TYPE
  verb: string
}

export const AuditContent = (props: Props): JSX.Element => {
  const { authCtx } = useContext(AuthContext)
  const { data: audit } = useAudit(props.auditId, true, true)

  if (
    props.attachmentId &&
    props.attachmentType &&
    props.attachmentType === ATTACHMENT_TYPE.CONTROL
  ) {
    return (
      <ControlContent
        modelId={props.attachmentId}
        linkable={props.verb !== NON_LINKABLE_VERBS.REMOVED}
      />
    )
  } else if (
    props.attachmentId &&
    props.attachmentType &&
    props.attachmentType === ATTACHMENT_TYPE.POLICY
  ) {
    return (
      <PolicyContent
        modelId={props.attachmentId}
        linkable={props.verb !== NON_LINKABLE_VERBS.REMOVED}
      />
    )
  }

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      <Link to={`/${authCtx.accountId}:${audit?.getId()}`}>
        {audit?.getName()}
      </Link>
    </SubjectContainer>
  )
}
