import React from 'react'
import { Receptor } from 'src/xgenerated'
import { ReceptorsGrid } from '../receptors.styles'
import {
  ReceptorsGridHeader,
  ReceptorsGridRow,
} from './ReceptorsGrid.components'

export const ReceptorsGridRows = ({
  receptors,
}: {
  receptors: Receptor[]
}): JSX.Element => (
  <ReceptorsGrid>
    <ReceptorsGridHeader />
    {receptors.map((receptor: Receptor) => (
      <ReceptorsGridRow key={receptor.id} receptor={receptor} />
    ))}
  </ReceptorsGrid>
)
