export class ReceptorStaticHelper {
  static isAccredited(receptor) {
    return (
      receptor && receptor.credentials !== null && receptor.credentials !== ''
    )
  }
}

export class ContentStaticHelper {
  /**
   * Given a list of Content Object IDs, return back a list of
   * all the matching objects.
   */
  static getObjsByIds(content, ids) {
    return ids
      .map(
        (id) =>
          // There is a special case for email lookup!  Users are not indexed
          // by email (because the email changes when deactivated), so we have
          // to manually find the record
          id && id.includes('@')
            ? content.users.find((p) => p.email === id)
            : content.idIndex[id], // use the index
      )
      .filter((obj) => obj) // no nulls
  }

  static getObjById(content, id) {
    let objsByIds = this.getObjsByIds(content, [id])
    return objsByIds.length === 0 ? null : objsByIds[0]
  }
}
