import {
  CircleCheckFillSVG,
  CircleQuestionMarkSVG,
  DropdownDarkSVG,
} from 'src/components/Icons/Basic'
import { EroDarkEastImg, UserEditedImg } from 'src/components/Icons/Brand'
import palette from 'src/designSystem/variables/palette'
import styled, { css, keyframes } from 'styled-components/macro'

export const StyledEroContainer = styled.div`
  width: 250px;
  min-width: 250px;
  background-color: ${palette.neutral[30]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
`

export const EroIcon = styled(EroDarkEastImg)<{
  $isSmall?: boolean
}>`
  flex-direction: column;

  ${({ $isSmall }) => ($isSmall ? `margin-bottom: 0` : `margin-bottom: 1em`)};
  ${({ $isSmall }) => ($isSmall ? `height: 28px` : `height: 40px`)};
  ${({ $isSmall }) => ($isSmall ? `width: 28px` : `width: 40px`)};
`

export const UserEditedIcon = styled(UserEditedImg)<{
  $isSmall?: boolean
}>`
  flex-direction: column;

  ${({ $isSmall }) => ($isSmall ? `margin-bottom: 0` : `margin-bottom: 1em`)};
  ${({ $isSmall }) => ($isSmall ? `height: 28px` : `height: 40px`)};
  ${({ $isSmall }) => ($isSmall ? `width: 28px` : `width: 40px`)};
`

export const SecurityQuestionnaireSection = styled.div`
  width: 100%;
`

export const QuestionnaireH3 = styled.h3<{
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
}>`
  margin: 0;
  color: ${palette.neutral[900]};
  font-size: 22px;
  font-weight: 500;
  line-height: 150%;

  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
  ${({ $mr }) => $mr && `margin-right: ${$mr}px`};
`

export const QuestionnaireP = styled.p<{
  $fontSize?: number
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
  $isDim?: boolean
  $isUnderline?: boolean
  $isItalicized?: boolean
  $isBold?: boolean
  $pointer?: boolean
}>`
  margin: 0;
  font-weight: 400;
  line-height: 150%;

  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
  ${({ $mr }) => $mr && `margin-right: ${$mr}px`};

  ${({ $fontSize }) => `font-size: ${$fontSize ? $fontSize : 18}px`};
  ${({ $isDim }) =>
    `color: ${$isDim ? palette.neutral[600] : palette.neutral[900]}`};
  ${({ $isUnderline }) => $isUnderline && `text-decoration: underline`};
  ${({ $isItalicized }) => $isItalicized && `font-style: italic`};
  ${({ $isBold }) => $isBold && `font-weight: 500`};
  ${({ $pointer }) => $pointer && `cursor: pointer`};
`

export const AccordionTitle = styled.p`
  color: ${palette.neutral[900]}
  font-size: 18px;
  font-weight: 400;
  user-select: text !important;
  cursor: text !important;
`

export const AccordionTitleBold = styled(AccordionTitle)`
  font-weight: 500;
`

export const QuestionnaireAnswerTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${palette.neutral[900]};
  line-height: 150%;
`

export const QuestionnaireCheckIcon = styled(CircleCheckFillSVG)`
  width: 16px;
  height: 16px;
  padding-bottom: 1px;
`

export const QuestionnaireQuestionMarkIcon = styled(CircleQuestionMarkSVG)`
  width: 16px;
  height: 16px;
  padding-bottom: 1px;
`

export const ContactSalesLink = styled.a`
  margin-left: 0.25em;
`

export const StyledScanAgainContainer = styled.div`
  align-self: flex-end;
  justify-self: flex-end;
`

export const StyledScanAgainP = styled.p`
  font-size: 16px;
  font-weight: 400;
`

export const StyledQuestionnaireItalicP = styled.p`
  font-style: italic;
  font-size: 13px;
  font-weight: 300;
`

const flipForwards = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
`

const flipBackwards = keyframes`
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
`

export const StyledDropdownSvg = styled(DropdownDarkSVG)<{
  $isFlip: boolean | null
}>`
  width: 20px;

  ${({ $isFlip }) =>
    $isFlip === false
      ? css`
          animation: ${flipBackwards} 0.3s linear forwards;
        `
      : $isFlip === true
      ? css`
          animation: ${flipForwards} 0.3s linear forwards;
        `
      : undefined};
`

const expand = keyframes`
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 300px;
    height: 100%;
  }
  `

const collapse = keyframes`
  0% {
    max-height: 200px;
  }
  100% {
    height: 0%;
    max-height: 0px;
  }
`

export const SourcesButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 120px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

export const SourcesContainer = styled.div<{ $expand: boolean | null }>`
  margin-top: 10px;
  max-height: 0px;
  height: 0px;
  overflow: hidden;
  display: flex;
  gap: 3px;
  flex-direction: column;
  ${({ $expand }) =>
    $expand === true
      ? css`
          max-height: 0px;
          height: 0px;
          animation: ${expand} 0.8s ease-in-out forwards;
          overflow: auto;
        `
      : $expand === false
      ? css`
          animation: ${collapse} 0.5s ease-in-out forwards;
        `
      : undefined}
`

export const SourceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
