import {
  Control,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useControls } from '../../../components/async/model/control/useControls'

export const useRequestControlIds = (requestId: string): string[] => {
  const controlRes = useControls(
    new ListControlsRequest().setDocumentRequestId(
      new StringValue().setValue(requestId),
    ),
  )

  const controlModelIds = controlRes.data?.getItemsList() || []
  return controlModelIds.map((elem: Control) => elem.getId())
}
