import React from 'react'
import { useGetRoleAccessConfig } from 'src/app/AppAuth/AppAuth.hooks'
import { Banner } from './Banner'

export const UserRoleBanner = ({
  noMargin,
}: {
  noMargin?: boolean
}): JSX.Element => {
  const {
    bannerConfig: { showBanner, bannerMessage, linkText, link, internalLink },
  } = useGetRoleAccessConfig()

  if (!showBanner) return <></>

  return (
    <Banner
      linkText={linkText}
      link={link}
      internalLink={internalLink}
      noMargin={noMargin}
      text={bannerMessage}
      isWarning
    />
  )
}
