import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { useCallback } from 'react'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { useGrpcRevalidateByMethod } from '../../../../components'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../../adapter/grpcClient'

export const useInvalidateTestResultsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(TestingPromiseClient.prototype.getTestResults),
      mutateFunc(TestingPromiseClient.prototype.getLatestTestResults),
      mutateFunc(ModelPromiseClient.prototype.listControls),
    ])
  }, [mutateFunc])
}

// TODO [AP-7391] Rename to useScanAllReceptors and rename the method in the API
// once old control tests have been retired
export const useRunAccountTests = (): (() => Promise<void>) => {
  const testingClient =
    useAuthorizedGrpcClientWithContentUpdate(TestingPromiseClient)
  const invalidateTestResultsCache = useInvalidateTestResultsCache()
  return useCallback(async () => {
    await testingClient.runAccountTests(new Empty())
    invalidateTestResultsCache()
  }, [invalidateTestResultsCache, testingClient])
}
