import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { SWRResponse } from 'swr'
import { useFetchStringList } from '../hooks/useFetchStringList'

export function useDepartmentIds(): SWRResponse<string[]> {
  return useFetchStringList(
    ModelPromiseClient.prototype.listDepartmentIds,
    new Empty(),
  )
}
