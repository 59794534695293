import includes from 'lodash/includes'
import {
  APP_ANALYTICS_ROUTE_CATEGORIES,
  APP_ANALYTICS_ROUTE_PAGE_NAMES,
} from './analytics.constants'

const getMatchingRouteKey = (
  pageConstant: Record<string, string>,
  path: string,
): string => {
  const keys = Object.keys(pageConstant)
  const keyMatched = keys.find((key: string) =>
    includes(path, pageConstant[key]),
  )
  return keyMatched || ''
}

export const getPageCategoryAndName = (
  location: Location,
): { category: string; name: string } => ({
  name: getMatchingRouteKey(APP_ANALYTICS_ROUTE_CATEGORIES, location.pathname),
  category: getMatchingRouteKey(
    APP_ANALYTICS_ROUTE_PAGE_NAMES,
    location.pathname,
  ),
})
