import { v4 as uuidv4 } from 'uuid'
import { Questionnaire } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { ParseColumnType } from 'src/Utils/csv/csv.constants'
import { CsvTemplateType } from 'src/Utils/csv/csv.constants'
import { getDownloadCSVFunc } from 'src/components/ModalForms/Reusable/UploadCSVModal.helpers'
import { getCSVBlob } from 'src/Utils/csv/csv.helpers'
import { blobToUint8Array } from 'src/Utils/download.helpers'
import { SecurityQuestionnaireQuestionField } from './securityQuestionnaireModals.constants'

export const downloadSecurityQuestionnaire = (data?: Questionnaire): void => {
  const questions =
    data?.getQuestionsList().map((ele) => ({
      Question: ele.getQuestion() || '',
      Answer: ele.getAnswer() || '',
    })) || ([] as CsvTemplateType[])

  const downloadFunc = getDownloadCSVFunc(
    questions,
    data?.getName() || 'SecurityQuestionnaire',
  )
  downloadFunc()
}

export const getSecurityQuestionnaireParsers = (): ParseColumnType => ({
  question: {
    validator: (_c, _v, _r) => null,
    mutator: (value) => value,
  },
})

export const getEmptyQuestionField =
  (): SecurityQuestionnaireQuestionField => ({
    id: uuidv4(),
    item: {
      question: '',
    },
    isValid: true,
  })

export const getQuestionnaireAsFile = async (
  data?: Questionnaire,
): Promise<Uint8Array> => {
  const questions =
    data?.getQuestionsList().map((ele) => ({
      Question: ele.getQuestion() || '',
      Answer: ele.getAnswer() || '',
    })) || ([] as CsvTemplateType[])

  const blob = getCSVBlob(questions)
  return await blobToUint8Array(blob)
}
