import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isWebUri } from 'valid-url'
import { ExternalLink } from 'src/components/Reusable/Text/Link'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { FileSelector } from 'src/components/Reusable/Forms/FileSelector/FileSelector'
import { Spinner } from 'src/Throbber'
import { TestIds } from 'src/Utils/testIds'
import { P } from 'src/components/Reusable/Text/Text.styles'
import palette from 'src/designSystem/variables/palette'
import formatDate from '../../../../../Utils/formatDate'
import { ReactComponent as TextIcon } from '../../../../../components/Icons/assets/evidence-text-icon.svg'
import { ReactComponent as LinkIcon } from '../../../../../components/Icons/assets/permalink-icon.svg'
import { TextButton } from '../../../../Reusable/Buttons/TextButton'
import { DerivedTextArea, TextInputProps } from '../../../../Reusable/Inputs'
import { StyledTextInput } from '../../../../Reusable/Inputs/TextInput/styles'
import { TypeButton } from '../../../FileType/TypeSelector.styles'
import { MIME_TYPE } from '../../../../../Utils/globalEnums'
import { useFileTypeIcon } from '../../../FileType/useFileTypeIcon'
import { ModalFormId } from '../../../ModalForm'
import {
  AddEvidenceError,
  CaptionLabel,
  EvidenceDetailsContainer,
  FilesInfoContainer,
  FilesInfoContainerRow,
  FilesLoadingContainer,
  Label,
  UploadInfo,
  UploadInfoSection,
} from './AddEvidenceForm.styles'
import {
  AddEvidenceErrorType,
  EvidenceFormData,
  EvidenceType,
} from './AddEvidenceForm.constants'

type CaptionTextInputProps = TextInputProps & {
  createCaption: (captionString: string) => void
  isError: (field: string, error: boolean) => void
  captionError: string
}

export const CaptionTextInput = ({
  as,
  createCaption,
  name,
  initVal = '',
  placeholder,
  form,
  label,
  captionError,
  isError,
  ...styleProps
}: CaptionTextInputProps): JSX.Element => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!captionError) {
      return
    }
    setShowError(true)
  }, [captionError])

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const newVal = e.target.value
    createCaption(newVal)
    if (!newVal.length) {
      setShowError(true)
      isError('caption', true)
      return
    }
    isError(AddEvidenceErrorType.CAPTION, false)
    setShowError(false)
  }

  const onKeyDown: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (e.key !== 'Enter') {
      return
    }
    e.preventDefault()
  }

  const inputProps = {
    as,
    name,
    value: initVal,
    placeholder,
    onChange,
    onKeyDown,
    showError,
  }

  return (
    <>
      {label ? (
        <CaptionLabel form={form} {...styleProps}>
          <p>{label}</p>
          <StyledTextInput {...inputProps} />
        </CaptionLabel>
      ) : (
        <StyledTextInput {...{ ...inputProps, ...styleProps }} />
      )}
    </>
  )
}

type EvidenceFileProps = {
  formData: EvidenceFormData
}

export const EvidenceFile = ({ formData }: EvidenceFileProps): JSX.Element => {
  const { mime, body } = formData
  const FileTypeIcon = useFileTypeIcon({ mime })

  if (!(body instanceof Blob)) return <></>

  return (
    <>
      <FilesInfoContainerRow>
        <FileTypeIcon width="24px" height="24px" />
        <span className="filename">{body.name}</span>
        <span className="last-modified">
          Modified on {formatDate(body.lastModified)}
        </span>
      </FilesInfoContainerRow>
    </>
  )
}

type EvidenceFilesProps = {
  formDataList: EvidenceFormData[]
  type: EvidenceType
}

export const EvidenceFiles = ({
  formDataList,
  type,
}: EvidenceFilesProps): JSX.Element => {
  const noEvidenceFiles = type !== EvidenceType.FILE || formDataList.length < 1
  return (
    <>
      {noEvidenceFiles ? (
        <></>
      ) : (
        <FilesInfoContainer>
          {formDataList.map((formData, i) => (
            <EvidenceFile key={`evidence-file-${i}`} formData={formData} />
          ))}
        </FilesInfoContainer>
      )}
    </>
  )
}

type EvidenceTextProps = {
  formData: EvidenceFormData
  setFormData: Dispatch<SetStateAction<EvidenceFormData>>
  formId: string
  type: EvidenceType
}

export const EvidenceText = ({
  formData,
  setFormData,
  formId,
  type,
}: EvidenceTextProps): JSX.Element => (
  <>
    {(type === EvidenceType.TEXT || type === EvidenceType.LINK) && (
      <DerivedTextArea
        required
        name="body"
        initVal={formData.body as string}
        placeholder={`Add ${type} Content`}
        form={formId}
        setFormData={
          setFormData as Dispatch<SetStateAction<{ [key: string]: string }>>
        }
        isValid={
          type === EvidenceType.LINK ? (value) => !!isWebUri(value) : undefined
        }
        errorMessage={
          type === EvidenceType.LINK
            ? 'Please enter a valid url that begins with ' +
              `"https://" ` +
              'or ' +
              `"http://"`
            : undefined
        }
      />
    )}
  </>
)

type EvidenceTypeSelectorProps = {
  updateEvidence: (mime: MIME_TYPE, type: EvidenceType) => void
  updateEvidenceFiles: (files: EvidenceFormData[], type: EvidenceType) => void
  type: EvidenceType
  updateUploadSize: (totalSizeInMB: number) => void
  modalId: ModalFormId
}

export function EvidenceTypeSelector({
  updateEvidence,
  updateEvidenceFiles,
  type,
  updateUploadSize,
  modalId,
}: EvidenceTypeSelectorProps): JSX.Element {
  const showClear = type !== EvidenceType.NULL

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) return
    const totalSize = files
      ? Array.from(files).reduce((size, file) => {
          size += file.size
          return size
        }, 0)
      : 0
    const totalSizeInMB = Math.round(totalSize / 1000000)
    updateUploadSize(totalSizeInMB)
    const evidenceFormData = files.map((file) => ({
      mime: file.type,
      body: file,
    }))
    updateEvidenceFiles(evidenceFormData, EvidenceType.FILE)
  }

  const handleClear = () => {
    updateEvidence(MIME_TYPE.NULL, EvidenceType.NULL)
    updateEvidenceFiles([], EvidenceType.NULL)
  }

  return (
    <>
      {showClear ? (
        <TextButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleClear()
          }}
        >
          Clear
        </TextButton>
      ) : (
        <FileSelector
          alternateTypeOptions={[
            <TypeButton
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                updateEvidence(MIME_TYPE.TEXT_MARKDOWN, EvidenceType.TEXT)
              }}
              key="text"
            >
              <TextIcon />
              <span>Display Text</span>
            </TypeButton>,
            <TypeButton
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                updateEvidence(MIME_TYPE.TEXT_URI_LIST, EvidenceType.LINK)
              }}
              key="link"
            >
              <LinkIcon width="48px" height="48px" />
              <span>Paste Link</span>
            </TypeButton>,
          ]}
          onChange={handleFileChange}
          isLoading={false}
          onClear={handleClear}
          isValid={true}
          errorMessage={''}
          allowMultiple
          modalId={modalId}
        />
      )}
    </>
  )
}

export const AddEvidenceFormDescription = (): JSX.Element => (
  <span>
    Make sure your evidence will work:&nbsp;
    <ExternalLink
      href={HUBSPOT.GOOD_MANUAL_EVIDENCE}
      text="Good Manual Evidence"
    />
  </span>
)

export const AddEvidenceCaption = ({
  numFiles,
  caption,
  error,
  isError,
  createCaption,
}: {
  numFiles: number
  caption: string
  error: string
  isError: () => boolean
  createCaption: (caption: string) => void
}): JSX.Element => {
  return (
    <>
      {numFiles > 1 ? (
        <P $fontSize={14} $mb={30}>
          Captions will automatically be set to the file names.
        </P>
      ) : (
        <CaptionTextInput
          label="Caption"
          name="caption"
          initVal={caption}
          captionError={error}
          isError={isError}
          placeholder="Add Caption for Evidence"
          form={ModalFormId.ADD_EVIDENCE}
          createCaption={createCaption}
        />
      )}
      <AddEvidenceError showError={!!error}>{error}</AddEvidenceError>
    </>
  )
}

export const FilesLoading = ({
  type,
  numFiles,
  uploadSize,
}: {
  type: EvidenceType
  numFiles: number
  uploadSize: number
}): JSX.Element => {
  return (
    <FilesLoadingContainer>
      <Spinner data-testid={TestIds.SPINNER} color={'primary'} size={'xl'} />
      Processing upload
      {type === EvidenceType.FILE && (
        <UploadInfoSection>
          <UploadInfo>
            {`${numFiles} file${numFiles > 1 ? 's' : ''}`}
          </UploadInfo>
          <UploadInfo>{`${uploadSize} MB`}</UploadInfo>
        </UploadInfoSection>
      )}
    </FilesLoadingContainer>
  )
}

export const AddEvidenceDetails = ({
  formData,
  formDataSet,
  formDataList,
  updateEvidence,
  updateEvidenceFiles,
  type,
  errors,
  updateUploadSize,
  modalId,
}: {
  formData: EvidenceFormData
  formDataSet: Dispatch<SetStateAction<EvidenceFormData>>
  formDataList: EvidenceFormData[]
  updateEvidence: (mime: MIME_TYPE, type: EvidenceType) => void
  updateEvidenceFiles: (files: EvidenceFormData[], type: EvidenceType) => void
  type: EvidenceType
  errors: { [key: string]: string }
  updateUploadSize: (totalSizeInMB: number) => void
  modalId: ModalFormId
}): JSX.Element => {
  const [list, setList] = useState<EvidenceFormData[]>(formDataList)
  useEffect(() => {
    setList(formDataList)
  }, [formDataList])
  return (
    <>
      <EvidenceDetailsContainer>
        <Label>Document Details</Label>
        {type === EvidenceType.LINK && (
          <P $color={palette.orange['900']} $isBold>
            Currently Audit Scan can only evaluate evidence that is stored in
            the platform. If you store your evidence somewhere else that you
            link to, audit scan will not be able to evaluate it.
          </P>
        )}
        <EvidenceTypeSelector
          updateEvidence={updateEvidence}
          updateEvidenceFiles={updateEvidenceFiles}
          type={type}
          updateUploadSize={updateUploadSize}
          modalId={modalId}
        />
        <EvidenceFiles formDataList={list} type={type} />
        <EvidenceText
          {...{
            formData,
            setFormData: formDataSet,
            formId: modalId,
            type,
          }}
        />
      </EvidenceDetailsContainer>
      <AddEvidenceError showError={!!errors.evidenceError}>
        {errors.evidenceError}
      </AddEvidenceError>
    </>
  )
}
