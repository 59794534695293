import showdown from 'showdown'

export const converter = new showdown.Converter()
converter.setFlavor('github')
converter.setOption('simpleLineBreaks', false)

// This converter is used for evidence markup. We don't want to render links for evidence markup because it causes an error from showdown.js if we pass in more than 10 links.
export const noLinkConverter = new showdown.Converter()
noLinkConverter.setFlavor('github')
noLinkConverter.setOption('simpleLineBreaks', false)
noLinkConverter.setOption('simplifiedAutoLink', false)
