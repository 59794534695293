import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from 'src/context/authContext'
import { useDocumentRequest } from '../../../components/async/DocumentRequest/useDocumentRequests'
import { SubjectContainer } from './Subject.styles'

type Props = {
  requestId: string
}

export const RequestContent = (props: Props): JSX.Element => {
  const { data: request } = useDocumentRequest(props.requestId)
  const { authCtx } = useContext(AuthContext)
  if (!request) {
    return <></>
  }
  const requestString = request?.getRequest() ?? ''
  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      <Link
        to={`/${authCtx.accountId}:${request?.getAuditId()}/requests/show/${
          props.requestId
        }`}
      >
        {requestString.length <= 20
          ? requestString
          : `${requestString.slice(0, 20)}...`}
      </Link>
    </SubjectContainer>
  )
}
