import React from 'react'
import { useIsShowModal } from 'src/Modal/ModalStateContext'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModalFormId } from '../../../../../components/ModalForms'
import { MultiSelectListModal } from '../MultiSelectListModal'
import { useCurrentAudit } from '../../../../../components/async/model/audit'
import { useBringControlsIntoAuditCallback } from './useBringControlsIntoAuditCallback'
import { useListControls } from './useListControls'

/**
 * Modal for displaying a list of controls _not_ in the current audit that a
 * user can select to bring into the current audit.
 */
export function BringControlsIntoAuditModal(): JSX.Element {
  const complianceFrameworks =
    useCurrentAudit()
      .data?.toObject()
      ?.complianceFrameworksList.map((framework) => framework.id) || []

  const controls = useListControls({
    excludeFromAudit: true,
    isDismissed: false,
    complianceFrameworkIds: complianceFrameworks,
  })

  const onSubmit = useBringControlsIntoAuditCallback()
  if (
    !useIsShowModal(ModalFormId.BRING_CONTROL_INTO_AUDIT || controls.loading)
  ) {
    return <></>
  }
  const controlList = controls.data
    ?.getItemsList()
    .map((control: Control) => control.getId())

  return (
    <MultiSelectListModal
      title={'Bring Controls Into This Audit'}
      submitText={'Bring Into Audit'}
      options={controlList}
      modalFormId={ModalFormId.BRING_CONTROL_INTO_AUDIT}
      onSubmit={onSubmit}
      formFallback={
        <p>{"You've already added all company controls to your audit."}</p>
      }
    />
  )
}
