import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router'
import { RisksShowPage } from './RisksShowPage'

export const RisksShowPageRouter = (): JSX.Element => {
  const { riskId } = useParams() as { riskId: string }

  return (
    <Routes>
      <Route path="/" element={<RisksShowPage riskId={riskId} />} />
      <Route path="*" element={<Navigate replace to="./" />} />
    </Routes>
  )
}
