import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { GetPolicyControlsRequest } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { SWRResponse } from 'swr'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useControlsOfPolicy = (
  id: string,
  shouldFetch = true,
): SWRResponse<StringList> => {
  const { response } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.getControls,
    new GetPolicyControlsRequest()
      .setId(id)
      .setInScope(new BoolValue().setValue(true)),
    shouldFetch,
  )
  return response
}
