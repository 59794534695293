import styled from 'styled-components/macro'
import palette from 'src/designSystem/variables/palette'
import { GridColumn, GridRow } from 'src/components/Reusable/Grid'

export const StyledIsStaleResult = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  border: 1px solid ${palette.orange['600']};
  background: ${palette.orange['50']};
`

export const EvidenceGridColumn = styled(GridColumn)`
  justify-content: space-evenly;
  p {
    display: flex;
    justify-content: center;
    width: 70px;
  }
`

export const PolicyGridColumn = styled(GridColumn)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const RightAlignGridColumn = styled(GridColumn)`
  justify-content: right;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  padding-left: 1.5em;
`

export const ControlGridRow = styled(GridRow)<{
  $isNotApplicable?: boolean
  $isPurple?: boolean
}>`
  ${({ $isPurple }) =>
    $isPurple &&
    `
    > .grid-column {
      background-color: ${palette.purple['30']};
    }
    &:hover {
      > .grid-column {
        background-color: ${palette.purple['50']};
      }
    }
  `}
  ${({ $isNotApplicable }) =>
    $isNotApplicable &&
    `
    > .grid-column {
      background-color: ${palette.neutral['50']};
    }
    &:hover {
      > .grid-column {
        background-color: ${palette.neutral['100']};
      }
    }
  `}
`
