export const UPDATE_CONTROL_DELAY = 1000

export const OBJECTIVE_WARNING =
  'Caution: Editing the objective changes this control materially. Policy, procedure, and evidence may need to change.'

export enum ControlQueryParams {
  DEFAULT_TAB = 'defaultTab',
}

export enum ControlTabParams {
  REQUESTS = 'requests',
  EVIDENCE = 'evidence',
  AUDIT_SCANS = 'audit-scans',
  AUTOMATED_EVIDENCE = 'automated-evidence',
  RISKS = 'risks',
}

export enum ControlTabTitles {
  REQUESTS = 'Requests',
  EVIDENCE = 'Evidence',
  AUDIT_SCANS = 'Audit Scans',
  AUTOMATED_EVIDENCE = 'Automated Evidence',
  RISKS = 'Risks',
}

export const CONTROL_TAB_PARAM_TO_TITLE: Record<
  ControlTabParams,
  ControlTabTitles
> = {
  [ControlTabParams.REQUESTS]: ControlTabTitles.REQUESTS,
  [ControlTabParams.EVIDENCE]: ControlTabTitles.EVIDENCE,
  [ControlTabParams.AUDIT_SCANS]: ControlTabTitles.AUDIT_SCANS,
  [ControlTabParams.AUTOMATED_EVIDENCE]: ControlTabTitles.AUTOMATED_EVIDENCE,
  [ControlTabParams.RISKS]: ControlTabTitles.RISKS,
}
