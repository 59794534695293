import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  GetEvidenceBodyByOIDResponse,
  GetEvidenceBodyByOIDRequest,
  GetEvidenceDiscoveryResponse,
  GetEvidenceDiscoveryRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrGrpc } from '../useSwrGrpc'
import FORMAT = GetEvidenceBodyByOIDRequest.FORMAT

export const useGetEvidenceBody = (
  documentId: string,
): GrpcResponse<GetEvidenceBodyByOIDResponse> => {
  const { response } = useSwrGrpc(
    AttachmentPromiseClient.prototype.getEvidenceBodyByOID,
    new GetEvidenceBodyByOIDRequest()
      .setId(documentId)
      .setFormat(FORMAT.MARKDOWN),
  )
  return NewGrpcResponse(response)
}

export const useGetEvidenceDiscovery = (
  documentId: string,
): GrpcResponse<GetEvidenceDiscoveryResponse> => {
  const { response } = useSwrGrpc(
    AttachmentPromiseClient.prototype.getEvidenceDiscovery,
    new GetEvidenceDiscoveryRequest().setEvidenceId(documentId),
  )
  return NewGrpcResponse(response)
}
