import React, {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEventHandler,
} from 'react'
import { isFunction } from 'lodash'
import styled from 'styled-components/macro'
import { LayoutProps } from 'styled-system'
import { IconButton, IconButtonVariant } from '../IconButton'

const StyledDropdownToggleButton = styled(IconButton)`
  ::after {
    display: none;
  }
`

export const DropdownToggleButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> &
    LayoutProps & {
      variant: IconButtonVariant
      onClick: MouseEventHandler<HTMLButtonElement>
      onClickFunc?: () => void
    }
>(({ children, variant, onClick, onClickFunc, ...attrs }, ref) => {
  const clickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.preventDefault()
    if (!attrs.disabled) onClick(e)
    isFunction(onClickFunc) && onClickFunc()
  }

  return (
    <StyledDropdownToggleButton
      {...attrs}
      ref={ref}
      variant={variant || IconButtonVariant.icon}
      onClick={clickHandler}
    >
      {children}
    </StyledDropdownToggleButton>
  )
})
