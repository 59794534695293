import { isWebUri } from 'valid-url'

const URL_PROTOCOL = {
  HTTP: 'http://',
  HTTPS: 'https://',
  MIN_LENGTH: 7,
}

export const isValidUrl = (url: string): boolean => {
  return (
    url.length >= URL_PROTOCOL.MIN_LENGTH &&
    (url.startsWith(URL_PROTOCOL.HTTP) || url.startsWith(URL_PROTOCOL.HTTPS)) &&
    !!isWebUri(url)
  )
}
