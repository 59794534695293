import Papa from 'papaparse'
import {
  HeaderValues,
  FileStats,
  CSV_VALIDATIONS,
  ParseColumnType,
  CsvTemplateType,
} from './csv.constants'

export const transformHeader = (
  header: string,
  headers: HeaderValues[],
  fileStats: FileStats,
  seenHeaders: Set<string>,
  headerErrors: string[],
): string => {
  fileStats.headersLen++
  if (seenHeaders.has(header)) {
    headerErrors.push(`Header ${header} appears multiple times.`)
    return ''
  } else {
    seenHeaders.add(header)
  }
  const expectedHeader = headers.find((candidate) => {
    return candidate.input === header
  })
  if (!expectedHeader) {
    headerErrors.push(
      `Header name issue: '${header}' does not match expected headers.`,
    )
    return ''
  }
  const validation = CSV_VALIDATIONS.HEADER(expectedHeader.input, header)
  if (validation) {
    headerErrors.push(validation)
    return ''
  }
  return expectedHeader.output
}

export const transformValue = (
  value: string,
  columnName: string,
  fileStats: FileStats,
  columnParsers: ParseColumnType,
  bodyErrors: string[],
): string[] | string => {
  fileStats.currCell++

  if (!(columnName in columnParsers)) {
    // This a dynamically generated column if there are no header errors
    return value
  }
  const validation = columnParsers[columnName].validator(
    columnName,
    value,
    fileStats.row(),
  )
  if (validation !== null && validation.length) {
    if (Array.isArray(validation)) {
      bodyErrors.push(...validation)
      return ''
    } else {
      bodyErrors.push(validation)
      return ''
    }
  }
  return columnParsers[columnName].mutator(value)
}

export const getCSVBlob = (data: CsvTemplateType[]): Blob => {
  const csv = Papa.unparse(data)
  return new Blob([csv], { type: 'text/csv' })
}
