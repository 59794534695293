import React from 'react'
import { ReactComponent as DeleteIcon } from '../../Icons/assets/delete-icon.svg'
import { IconButton, IconButtonVariant } from './index'

type Props = {
  onClick: () => void
}

export function DeleteButton(props: Props): JSX.Element {
  return (
    <IconButton variant={IconButtonVariant.icon} onClick={props.onClick}>
      <DeleteIcon />
    </IconButton>
  )
}
