import { PolicySort } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { GridColumnSortPolicies } from '../../../components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'

export const POLICY_SORT_COLUMNS = Object.freeze({
  [GridColumnSortPolicies.NAME]: PolicySort.policy_sort_column.NAME,
  [GridColumnSortPolicies.DEPARTMENT]: PolicySort.policy_sort_column.DEPARTMENT,
  [GridColumnSortPolicies.DOCUMENTATION]:
    PolicySort.policy_sort_column.DOCUMENTATION,
  [GridColumnSortPolicies.CONTROLS]: PolicySort.policy_sort_column.CONTROLS,
  [GridColumnSortPolicies.ASSIGNEE]: PolicySort.policy_sort_column.ASSIGNEE,
})
