import React from 'react'
import { SWRConfiguration } from 'swr'
import { GrpcCall } from './utils'
import { AsyncComponentResponse } from './AsyncComponentResponse'
import { BaseProps, OnResponse } from './types'
import { useSwrGrpc } from './useSwrGrpc'

export type Props<ElementProps, Request, Response> = BaseProps<
  ElementProps,
  OnResponse<ElementProps, Response>
> & {
  asyncCall: GrpcCall<Request, Response>
  request: Request
  config?: SWRConfiguration
}

/**
 * @deprecated use useSwrGrpc hook instead
 * @param props
 * @constructor
 */
export function AsyncComponent<ExternalProps, Request, Response>(
  props: Props<ExternalProps, Request, Response>,
): JSX.Element {
  const { response, cacheKey } = useSwrGrpc(props.asyncCall, props.request)
  return (
    <AsyncComponentResponse
      props={props.props}
      placeholder={props.placeholder ?? <></>}
      child={props.child}
      onValidationStarted={props.onValidationStarted}
      onValidationCompleted={props.onValidationCompleted}
      onError={props.onError}
      data={response.data}
      error={response.error}
      mutate={response.mutate}
      isLoading={response.isLoading}
      isValidating={response.isValidating}
      cacheKey={cacheKey}
    />
  )
}
