// source: recommend/recommend.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var model_control_pb = require('../model/control_pb.js');
goog.object.extend(proto, model_control_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
goog.exportSymbol('proto.agent.AddReportResponse', null, global);
goog.exportSymbol('proto.agent.AutomatedEvidence', null, global);
goog.exportSymbol('proto.agent.CSOCS', null, global);
goog.exportSymbol('proto.agent.ControlFinding', null, global);
goog.exportSymbol('proto.agent.DeleteReportRequest', null, global);
goog.exportSymbol('proto.agent.GetOperationalizationRequest', null, global);
goog.exportSymbol('proto.agent.GetReportRequest', null, global);
goog.exportSymbol('proto.agent.GetSuggestedRequest', null, global);
goog.exportSymbol('proto.agent.HowToHandleItList', null, global);
goog.exportSymbol('proto.agent.HowToHandleItRecord', null, global);
goog.exportSymbol('proto.agent.HowToHandleItRequest', null, global);
goog.exportSymbol('proto.agent.HowToHandleItResponse', null, global);
goog.exportSymbol('proto.agent.ImportControlsRequest', null, global);
goog.exportSymbol('proto.agent.ListReportsReply', null, global);
goog.exportSymbol('proto.agent.ListSuggestionsRequest', null, global);
goog.exportSymbol('proto.agent.ListSuggestionsResponse', null, global);
goog.exportSymbol('proto.agent.OperationalizationRecord', null, global);
goog.exportSymbol('proto.agent.OperationalizationResponse', null, global);
goog.exportSymbol('proto.agent.PDFDocument', null, global);
goog.exportSymbol('proto.agent.RECOMMENDATION_STATUS', null, global);
goog.exportSymbol('proto.agent.ReportStatus', null, global);
goog.exportSymbol('proto.agent.ReportSummary', null, global);
goog.exportSymbol('proto.agent.ReportValue', null, global);
goog.exportSymbol('proto.agent.ResetSuggestedEvidenceRequest', null, global);
goog.exportSymbol('proto.agent.ResetSuggestedTestsRequest', null, global);
goog.exportSymbol('proto.agent.RestoreOriginalReportValueRequest', null, global);
goog.exportSymbol('proto.agent.SUGGESTION_TYPES', null, global);
goog.exportSymbol('proto.agent.SuggestedAutomatedEvidence', null, global);
goog.exportSymbol('proto.agent.SuggestedDLRReceptorRequest', null, global);
goog.exportSymbol('proto.agent.SuggestedEvidence', null, global);
goog.exportSymbol('proto.agent.SuggestedEvidenceList', null, global);
goog.exportSymbol('proto.agent.SuggestedEvidenceRecord', null, global);
goog.exportSymbol('proto.agent.SuggestedEvidenceRequest', null, global);
goog.exportSymbol('proto.agent.SuggestedEvidenceResponse', null, global);
goog.exportSymbol('proto.agent.SuggestedReceptor', null, global);
goog.exportSymbol('proto.agent.SuggestedReceptors', null, global);
goog.exportSymbol('proto.agent.SuggestedTestList', null, global);
goog.exportSymbol('proto.agent.SuggestedTestRecord', null, global);
goog.exportSymbol('proto.agent.SuggestedTestRequest', null, global);
goog.exportSymbol('proto.agent.SuggestedTestResponse', null, global);
goog.exportSymbol('proto.agent.TestPreview', null, global);
goog.exportSymbol('proto.agent.ToggleSuggestionsRequest', null, global);
goog.exportSymbol('proto.agent.UpdateReportRequest', null, global);
goog.exportSymbol('proto.agent.UpdateSuggestedEvidenceRequest', null, global);
goog.exportSymbol('proto.agent.UpdateSuggestedTestsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.AddReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.AddReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.AddReportResponse.displayName = 'proto.agent.AddReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.GetReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.GetReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.GetReportRequest.displayName = 'proto.agent.GetReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.DeleteReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.DeleteReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.DeleteReportRequest.displayName = 'proto.agent.DeleteReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.PDFDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.PDFDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.PDFDocument.displayName = 'proto.agent.PDFDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ControlFinding = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ControlFinding, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ControlFinding.displayName = 'proto.agent.ControlFinding';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ReportSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ReportSummary.repeatedFields_, null);
};
goog.inherits(proto.agent.ReportSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ReportSummary.displayName = 'proto.agent.ReportSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.UpdateReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.UpdateReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.UpdateReportRequest.displayName = 'proto.agent.UpdateReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.RestoreOriginalReportValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.RestoreOriginalReportValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.RestoreOriginalReportValueRequest.displayName = 'proto.agent.RestoreOriginalReportValueRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ListReportsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ListReportsReply.repeatedFields_, null);
};
goog.inherits(proto.agent.ListReportsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ListReportsReply.displayName = 'proto.agent.ListReportsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.TestPreview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.TestPreview.repeatedFields_, null);
};
goog.inherits(proto.agent.TestPreview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.TestPreview.displayName = 'proto.agent.TestPreview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedEvidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedEvidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedEvidence.displayName = 'proto.agent.SuggestedEvidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.GetOperationalizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.GetOperationalizationRequest.repeatedFields_, null);
};
goog.inherits(proto.agent.GetOperationalizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.GetOperationalizationRequest.displayName = 'proto.agent.GetOperationalizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.OperationalizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.OperationalizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.OperationalizationResponse.displayName = 'proto.agent.OperationalizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.OperationalizationRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.OperationalizationRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.OperationalizationRecord.displayName = 'proto.agent.OperationalizationRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedReceptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedReceptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedReceptor.displayName = 'proto.agent.SuggestedReceptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedReceptors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.SuggestedReceptors.repeatedFields_, null);
};
goog.inherits(proto.agent.SuggestedReceptors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedReceptors.displayName = 'proto.agent.SuggestedReceptors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.AutomatedEvidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.AutomatedEvidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.AutomatedEvidence.displayName = 'proto.agent.AutomatedEvidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedAutomatedEvidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.SuggestedAutomatedEvidence.repeatedFields_, null);
};
goog.inherits(proto.agent.SuggestedAutomatedEvidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedAutomatedEvidence.displayName = 'proto.agent.SuggestedAutomatedEvidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.CSOCS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.CSOCS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.CSOCS.displayName = 'proto.agent.CSOCS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ImportControlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ImportControlsRequest.repeatedFields_, null);
};
goog.inherits(proto.agent.ImportControlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ImportControlsRequest.displayName = 'proto.agent.ImportControlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.GetSuggestedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.GetSuggestedRequest.repeatedFields_, null);
};
goog.inherits(proto.agent.GetSuggestedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.GetSuggestedRequest.displayName = 'proto.agent.GetSuggestedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedEvidenceRequest.displayName = 'proto.agent.SuggestedEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedTestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedTestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedTestRequest.displayName = 'proto.agent.SuggestedTestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.HowToHandleItRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.HowToHandleItRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.HowToHandleItRequest.displayName = 'proto.agent.HowToHandleItRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedTestRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedTestRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedTestRecord.displayName = 'proto.agent.SuggestedTestRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.HowToHandleItRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.HowToHandleItRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.HowToHandleItRecord.displayName = 'proto.agent.HowToHandleItRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedEvidenceRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedEvidenceRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedEvidenceRecord.displayName = 'proto.agent.SuggestedEvidenceRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedEvidenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedEvidenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedEvidenceResponse.displayName = 'proto.agent.SuggestedEvidenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedTestResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedTestResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedTestResponse.displayName = 'proto.agent.SuggestedTestResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.HowToHandleItResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.HowToHandleItResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.HowToHandleItResponse.displayName = 'proto.agent.HowToHandleItResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedDLRReceptorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.SuggestedDLRReceptorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedDLRReceptorRequest.displayName = 'proto.agent.SuggestedDLRReceptorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ToggleSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ToggleSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ToggleSuggestionsRequest.displayName = 'proto.agent.ToggleSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.UpdateSuggestedTestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.UpdateSuggestedTestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.UpdateSuggestedTestsRequest.displayName = 'proto.agent.UpdateSuggestedTestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ResetSuggestedTestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ResetSuggestedTestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ResetSuggestedTestsRequest.displayName = 'proto.agent.ResetSuggestedTestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.UpdateSuggestedEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.UpdateSuggestedEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.UpdateSuggestedEvidenceRequest.displayName = 'proto.agent.UpdateSuggestedEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ResetSuggestedEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ResetSuggestedEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ResetSuggestedEvidenceRequest.displayName = 'proto.agent.ResetSuggestedEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ListSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ListSuggestionsRequest.repeatedFields_, null);
};
goog.inherits(proto.agent.ListSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ListSuggestionsRequest.displayName = 'proto.agent.ListSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedEvidenceList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.SuggestedEvidenceList.repeatedFields_, null);
};
goog.inherits(proto.agent.SuggestedEvidenceList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedEvidenceList.displayName = 'proto.agent.SuggestedEvidenceList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.SuggestedTestList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.SuggestedTestList.repeatedFields_, null);
};
goog.inherits(proto.agent.SuggestedTestList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.SuggestedTestList.displayName = 'proto.agent.SuggestedTestList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.HowToHandleItList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.HowToHandleItList.repeatedFields_, null);
};
goog.inherits(proto.agent.HowToHandleItList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.HowToHandleItList.displayName = 'proto.agent.HowToHandleItList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ListSuggestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ListSuggestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ListSuggestionsResponse.displayName = 'proto.agent.ListSuggestionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.AddReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.AddReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.AddReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.AddReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.AddReportResponse}
 */
proto.agent.AddReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.AddReportResponse;
  return proto.agent.AddReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.AddReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.AddReportResponse}
 */
proto.agent.AddReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.AddReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.AddReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.AddReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.AddReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string report_id = 1;
 * @return {string}
 */
proto.agent.AddReportResponse.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.AddReportResponse} returns this
 */
proto.agent.AddReportResponse.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.agent.AddReportResponse.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.AddReportResponse} returns this
 */
proto.agent.AddReportResponse.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.GetReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.GetReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.GetReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.GetReportRequest}
 */
proto.agent.GetReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.GetReportRequest;
  return proto.agent.GetReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.GetReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.GetReportRequest}
 */
proto.agent.GetReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.GetReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.GetReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.GetReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.agent.GetReportRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetReportRequest} returns this
 */
proto.agent.GetReportRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.DeleteReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.DeleteReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.DeleteReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.DeleteReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.DeleteReportRequest}
 */
proto.agent.DeleteReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.DeleteReportRequest;
  return proto.agent.DeleteReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.DeleteReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.DeleteReportRequest}
 */
proto.agent.DeleteReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.DeleteReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.DeleteReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.DeleteReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.DeleteReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.agent.DeleteReportRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.DeleteReportRequest} returns this
 */
proto.agent.DeleteReportRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.PDFDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.PDFDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.PDFDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.PDFDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    body: msg.getBody_asB64(),
    bodysize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.PDFDocument}
 */
proto.agent.PDFDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.PDFDocument;
  return proto.agent.PDFDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.PDFDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.PDFDocument}
 */
proto.agent.PDFDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBodysize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.PDFDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.PDFDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.PDFDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.PDFDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getBodysize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string AccountID = 1;
 * @return {string}
 */
proto.agent.PDFDocument.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.PDFDocument} returns this
 */
proto.agent.PDFDocument.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FileName = 3;
 * @return {string}
 */
proto.agent.PDFDocument.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.PDFDocument} returns this
 */
proto.agent.PDFDocument.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes Body = 4;
 * @return {!(string|Uint8Array)}
 */
proto.agent.PDFDocument.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes Body = 4;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.agent.PDFDocument.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes Body = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.agent.PDFDocument.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.agent.PDFDocument} returns this
 */
proto.agent.PDFDocument.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional int64 BodySize = 5;
 * @return {number}
 */
proto.agent.PDFDocument.prototype.getBodysize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.agent.PDFDocument} returns this
 */
proto.agent.PDFDocument.prototype.setBodysize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ControlFinding.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ControlFinding.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ControlFinding} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ControlFinding.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ControlFinding}
 */
proto.agent.ControlFinding.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ControlFinding;
  return proto.agent.ControlFinding.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ControlFinding} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ControlFinding}
 */
proto.agent.ControlFinding.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ControlFinding.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ControlFinding.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ControlFinding} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ControlFinding.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string Body = 1;
 * @return {string}
 */
proto.agent.ControlFinding.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ControlFinding} returns this
 */
proto.agent.ControlFinding.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 Page = 2;
 * @return {number}
 */
proto.agent.ControlFinding.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ControlFinding} returns this
 */
proto.agent.ControlFinding.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ReportSummary.repeatedFields_ = [20,22,24,26,28,31,32];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ReportSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ReportSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ReportSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReportSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 4, ""),
    owneremail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    auditor: (f = msg.getAuditor()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    auditorpageattribution: (f = msg.getAuditorpageattribution()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    auditorranking: (f = msg.getAuditorranking()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    auditorcriticisms: (f = msg.getAuditorcriticisms()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    firm: (f = msg.getFirm()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firmpageattribution: (f = msg.getFirmpageattribution()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    reporttype: (f = msg.getReporttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    reporttypepageattribution: (f = msg.getReporttypepageattribution()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    periodpageattribution: (f = msg.getPeriodpageattribution()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    opinion: (f = msg.getOpinion()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    opinionpageattribution: (f = msg.getOpinionpageattribution()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    securitycoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    securitymissingList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    availabilitycoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    availabilitymissingList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    confidentialitycoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    confidentialitymissingList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    privacycoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    privacymissingList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    processingintegritycoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    processingintegritymissingList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
    controlnonoccurances: (f = msg.getControlnonoccurances()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    controldeviations: (f = msg.getControldeviations()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    controlnonoccurancedetailsList: jspb.Message.toObjectList(msg.getControlnonoccurancedetailsList(),
    proto.agent.ControlFinding.toObject, includeInstance),
    controldeviationsdetailsList: jspb.Message.toObjectList(msg.getControldeviationsdetailsList(),
    proto.agent.ControlFinding.toObject, includeInstance),
    nonOccurrenceExplanation: jspb.Message.getFieldWithDefault(msg, 33, ""),
    deviationExplanation: jspb.Message.getFieldWithDefault(msg, 34, ""),
    uploadFileName: jspb.Message.getFieldWithDefault(msg, 35, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    reportStatus: jspb.Message.getFieldWithDefault(msg, 37, 0),
    auditSectionUpdate: jspb.Message.getFieldWithDefault(msg, 38, ""),
    auditorSectionUpdate: jspb.Message.getFieldWithDefault(msg, 39, ""),
    criteriaSectionUpdate: jspb.Message.getFieldWithDefault(msg, 40, ""),
    nonOccurrenceSectionUpdate: jspb.Message.getFieldWithDefault(msg, 41, ""),
    exceptionSectionUpdate: jspb.Message.getFieldWithDefault(msg, 42, ""),
    firmSectionUpdate: jspb.Message.getFieldWithDefault(msg, 43, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ReportSummary}
 */
proto.agent.ReportSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ReportSummary;
  return proto.agent.ReportSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ReportSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ReportSummary}
 */
proto.agent.ReportSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwneremail(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditor(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setAuditorpageattribution(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setAuditorranking(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setAuditorcriticisms(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirm(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setFirmpageattribution(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReporttype(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setReporttypepageattribution(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPeriodpageattribution(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpinion(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOpinionpageattribution(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSecuritycoverage(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addSecuritymissing(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvailabilitycoverage(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailabilitymissing(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfidentialitycoverage(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addConfidentialitymissing(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrivacycoverage(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addPrivacymissing(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProcessingintegritycoverage(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.addProcessingintegritymissing(value);
      break;
    case 29:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setControlnonoccurances(value);
      break;
    case 30:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setControldeviations(value);
      break;
    case 31:
      var value = new proto.agent.ControlFinding;
      reader.readMessage(value,proto.agent.ControlFinding.deserializeBinaryFromReader);
      msg.addControlnonoccurancedetails(value);
      break;
    case 32:
      var value = new proto.agent.ControlFinding;
      reader.readMessage(value,proto.agent.ControlFinding.deserializeBinaryFromReader);
      msg.addControldeviationsdetails(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonOccurrenceExplanation(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviationExplanation(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadFileName(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 37:
      var value = /** @type {!proto.agent.ReportStatus} */ (reader.readEnum());
      msg.setReportStatus(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditSectionUpdate(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditorSectionUpdate(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setCriteriaSectionUpdate(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonOccurrenceSectionUpdate(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptionSectionUpdate(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmSectionUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ReportSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ReportSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ReportSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReportSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwneremail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAuditor();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditorpageattribution();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAuditorranking();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAuditorcriticisms();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFirm();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirmpageattribution();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getReporttype();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReporttypepageattribution();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPeriodpageattribution();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOpinion();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpinionpageattribution();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSecuritycoverage();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getSecuritymissingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getAvailabilitycoverage();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getAvailabilitymissingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getConfidentialitycoverage();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getConfidentialitymissingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getPrivacycoverage();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getPrivacymissingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getProcessingintegritycoverage();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getProcessingintegritymissingList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      28,
      f
    );
  }
  f = message.getControlnonoccurances();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getControldeviations();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getControlnonoccurancedetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.agent.ControlFinding.serializeBinaryToWriter
    );
  }
  f = message.getControldeviationsdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.agent.ControlFinding.serializeBinaryToWriter
    );
  }
  f = message.getNonOccurrenceExplanation();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getDeviationExplanation();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getUploadFileName();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getReportStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      37,
      f
    );
  }
  f = message.getAuditSectionUpdate();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getAuditorSectionUpdate();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getCriteriaSectionUpdate();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getNonOccurrenceSectionUpdate();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getExceptionSectionUpdate();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getFirmSectionUpdate();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.agent.ReportSummary.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string AccountID = 3;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Caption = 4;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string OwnerEmail = 5;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getOwneremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setOwneremail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue Auditor = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.ReportSummary.prototype.getAuditor = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setAuditor = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearAuditor = function() {
  return this.setAuditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasAuditor = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value AuditorPageAttribution = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getAuditorpageattribution = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setAuditorpageattribution = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearAuditorpageattribution = function() {
  return this.setAuditorpageattribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasAuditorpageattribution = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value AuditorRanking = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getAuditorranking = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setAuditorranking = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearAuditorranking = function() {
  return this.setAuditorranking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasAuditorranking = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value AuditorCriticisms = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getAuditorcriticisms = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setAuditorcriticisms = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearAuditorcriticisms = function() {
  return this.setAuditorcriticisms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasAuditorcriticisms = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue Firm = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.ReportSummary.prototype.getFirm = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setFirm = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearFirm = function() {
  return this.setFirm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasFirm = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value FirmPageAttribution = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getFirmpageattribution = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setFirmpageattribution = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearFirmpageattribution = function() {
  return this.setFirmpageattribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasFirmpageattribution = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue ReportType = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.ReportSummary.prototype.getReporttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setReporttype = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearReporttype = function() {
  return this.setReporttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasReporttype = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Int32Value ReportTypePageAttribution = 13;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getReporttypepageattribution = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 13));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setReporttypepageattribution = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearReporttypepageattribution = function() {
  return this.setReporttypepageattribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasReporttypepageattribution = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp StartDate = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.agent.ReportSummary.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp EndDate = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.agent.ReportSummary.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Int32Value PeriodPageAttribution = 16;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getPeriodpageattribution = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 16));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setPeriodpageattribution = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearPeriodpageattribution = function() {
  return this.setPeriodpageattribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasPeriodpageattribution = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue Opinion = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.ReportSummary.prototype.getOpinion = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setOpinion = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearOpinion = function() {
  return this.setOpinion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasOpinion = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Int32Value OpinionPageAttribution = 18;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getOpinionpageattribution = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 18));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setOpinionpageattribution = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearOpinionpageattribution = function() {
  return this.setOpinionpageattribution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasOpinionpageattribution = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional double SecurityCoverage = 19;
 * @return {number}
 */
proto.agent.ReportSummary.prototype.getSecuritycoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setSecuritycoverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * repeated string SecurityMissing = 20;
 * @return {!Array<string>}
 */
proto.agent.ReportSummary.prototype.getSecuritymissingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setSecuritymissingList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.addSecuritymissing = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearSecuritymissingList = function() {
  return this.setSecuritymissingList([]);
};


/**
 * optional double AvailabilityCoverage = 21;
 * @return {number}
 */
proto.agent.ReportSummary.prototype.getAvailabilitycoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setAvailabilitycoverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * repeated string AvailabilityMissing = 22;
 * @return {!Array<string>}
 */
proto.agent.ReportSummary.prototype.getAvailabilitymissingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setAvailabilitymissingList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.addAvailabilitymissing = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearAvailabilitymissingList = function() {
  return this.setAvailabilitymissingList([]);
};


/**
 * optional double ConfidentialityCoverage = 23;
 * @return {number}
 */
proto.agent.ReportSummary.prototype.getConfidentialitycoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setConfidentialitycoverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * repeated string ConfidentialityMissing = 24;
 * @return {!Array<string>}
 */
proto.agent.ReportSummary.prototype.getConfidentialitymissingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setConfidentialitymissingList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.addConfidentialitymissing = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearConfidentialitymissingList = function() {
  return this.setConfidentialitymissingList([]);
};


/**
 * optional double PrivacyCoverage = 25;
 * @return {number}
 */
proto.agent.ReportSummary.prototype.getPrivacycoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setPrivacycoverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * repeated string PrivacyMissing = 26;
 * @return {!Array<string>}
 */
proto.agent.ReportSummary.prototype.getPrivacymissingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setPrivacymissingList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.addPrivacymissing = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearPrivacymissingList = function() {
  return this.setPrivacymissingList([]);
};


/**
 * optional double ProcessingIntegrityCoverage = 27;
 * @return {number}
 */
proto.agent.ReportSummary.prototype.getProcessingintegritycoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setProcessingintegritycoverage = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * repeated string ProcessingIntegrityMissing = 28;
 * @return {!Array<string>}
 */
proto.agent.ReportSummary.prototype.getProcessingintegritymissingList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setProcessingintegritymissingList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.addProcessingintegritymissing = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearProcessingintegritymissingList = function() {
  return this.setProcessingintegritymissingList([]);
};


/**
 * optional google.protobuf.Int32Value ControlNonOccurances = 29;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getControlnonoccurances = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 29));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setControlnonoccurances = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearControlnonoccurances = function() {
  return this.setControlnonoccurances(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasControlnonoccurances = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.Int32Value ControlDeviations = 30;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.ReportSummary.prototype.getControldeviations = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 30));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setControldeviations = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearControldeviations = function() {
  return this.setControldeviations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ReportSummary.prototype.hasControldeviations = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated ControlFinding ControlNonOccuranceDetails = 31;
 * @return {!Array<!proto.agent.ControlFinding>}
 */
proto.agent.ReportSummary.prototype.getControlnonoccurancedetailsList = function() {
  return /** @type{!Array<!proto.agent.ControlFinding>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.ControlFinding, 31));
};


/**
 * @param {!Array<!proto.agent.ControlFinding>} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setControlnonoccurancedetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.agent.ControlFinding=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.ControlFinding}
 */
proto.agent.ReportSummary.prototype.addControlnonoccurancedetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.agent.ControlFinding, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearControlnonoccurancedetailsList = function() {
  return this.setControlnonoccurancedetailsList([]);
};


/**
 * repeated ControlFinding ControlDeviationsDetails = 32;
 * @return {!Array<!proto.agent.ControlFinding>}
 */
proto.agent.ReportSummary.prototype.getControldeviationsdetailsList = function() {
  return /** @type{!Array<!proto.agent.ControlFinding>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.ControlFinding, 32));
};


/**
 * @param {!Array<!proto.agent.ControlFinding>} value
 * @return {!proto.agent.ReportSummary} returns this
*/
proto.agent.ReportSummary.prototype.setControldeviationsdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.agent.ControlFinding=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.ControlFinding}
 */
proto.agent.ReportSummary.prototype.addControldeviationsdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.agent.ControlFinding, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.clearControldeviationsdetailsList = function() {
  return this.setControldeviationsdetailsList([]);
};


/**
 * optional string non_occurrence_explanation = 33;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getNonOccurrenceExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setNonOccurrenceExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string deviation_explanation = 34;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getDeviationExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setDeviationExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string upload_file_name = 35;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getUploadFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setUploadFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string content_id = 36;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional ReportStatus report_status = 37;
 * @return {!proto.agent.ReportStatus}
 */
proto.agent.ReportSummary.prototype.getReportStatus = function() {
  return /** @type {!proto.agent.ReportStatus} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {!proto.agent.ReportStatus} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setReportStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 37, value);
};


/**
 * optional string audit_section_update = 38;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getAuditSectionUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setAuditSectionUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string auditor_section_update = 39;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getAuditorSectionUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setAuditorSectionUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string criteria_section_update = 40;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getCriteriaSectionUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setCriteriaSectionUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string non_occurrence_section_update = 41;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getNonOccurrenceSectionUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setNonOccurrenceSectionUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string exception_section_update = 42;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getExceptionSectionUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setExceptionSectionUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string firm_section_update = 43;
 * @return {string}
 */
proto.agent.ReportSummary.prototype.getFirmSectionUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReportSummary} returns this
 */
proto.agent.ReportSummary.prototype.setFirmSectionUpdate = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.UpdateReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.UpdateReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.UpdateReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auditor: (f = msg.getAuditor()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    reportType: (f = msg.getReportType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    opinion: (f = msg.getOpinion()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    securityCoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    securityMissing: (f = msg.getSecurityMissing()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    availabilityCoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    availabilityMissing: (f = msg.getAvailabilityMissing()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    confidentialityCoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    confidentialityMissing: (f = msg.getConfidentialityMissing()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    privacyCoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    privacyMissing: (f = msg.getPrivacyMissing()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    processingIntegrityCoverage: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    processingIntegrityMissing: (f = msg.getProcessingIntegrityMissing()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlNonOccurrences: (f = msg.getControlNonOccurrences()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    controlNonOccurrenceDetails: (f = msg.getControlNonOccurrenceDetails()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlExceptions: (f = msg.getControlExceptions()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    controlExceptionDetails: (f = msg.getControlExceptionDetails()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    auditSectionUpdate: (f = msg.getAuditSectionUpdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    auditorSectionUpdate: (f = msg.getAuditorSectionUpdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    criteriaSectionUpdate: (f = msg.getCriteriaSectionUpdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nonOccurrenceSectionUpdate: (f = msg.getNonOccurrenceSectionUpdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    exceptionSectionUpdate: (f = msg.getExceptionSectionUpdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firm: (f = msg.getFirm()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firmSectionUpdate: (f = msg.getFirmSectionUpdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.UpdateReportRequest}
 */
proto.agent.UpdateReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.UpdateReportRequest;
  return proto.agent.UpdateReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.UpdateReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.UpdateReportRequest}
 */
proto.agent.UpdateReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditor(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReportType(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpinion(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSecurityCoverage(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSecurityMissing(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvailabilityCoverage(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAvailabilityMissing(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfidentialityCoverage(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setConfidentialityMissing(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrivacyCoverage(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrivacyMissing(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProcessingIntegrityCoverage(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProcessingIntegrityMissing(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setControlNonOccurrences(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setControlNonOccurrenceDetails(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setControlExceptions(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setControlExceptionDetails(value);
      break;
    case 23:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditSectionUpdate(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditorSectionUpdate(value);
      break;
    case 25:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCriteriaSectionUpdate(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNonOccurrenceSectionUpdate(value);
      break;
    case 27:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExceptionSectionUpdate(value);
      break;
    case 28:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirm(value);
      break;
    case 29:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirmSectionUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.UpdateReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.UpdateReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.UpdateReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuditor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReportType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOpinion();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSecurityMissing();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAvailabilityMissing();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getConfidentialityMissing();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getPrivacyMissing();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getProcessingIntegrityMissing();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlNonOccurrences();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getControlNonOccurrenceDetails();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlExceptions();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getControlExceptionDetails();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditSectionUpdate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditorSectionUpdate();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaSectionUpdate();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNonOccurrenceSectionUpdate();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExceptionSectionUpdate();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirm();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirmSectionUpdate();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string report_id = 1;
 * @return {string}
 */
proto.agent.UpdateReportRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.agent.UpdateReportRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue auditor = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getAuditor = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setAuditor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearAuditor = function() {
  return this.setAuditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasAuditor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue report_type = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getReportType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setReportType = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearReportType = function() {
  return this.setReportType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasReportType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp start_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.agent.UpdateReportRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.agent.UpdateReportRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue opinion = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getOpinion = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setOpinion = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearOpinion = function() {
  return this.setOpinion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasOpinion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double security_coverage = 8;
 * @return {number}
 */
proto.agent.UpdateReportRequest.prototype.getSecurityCoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setSecurityCoverage = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearSecurityCoverage = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasSecurityCoverage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue security_missing = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getSecurityMissing = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setSecurityMissing = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearSecurityMissing = function() {
  return this.setSecurityMissing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasSecurityMissing = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional double availability_coverage = 11;
 * @return {number}
 */
proto.agent.UpdateReportRequest.prototype.getAvailabilityCoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setAvailabilityCoverage = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearAvailabilityCoverage = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasAvailabilityCoverage = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue availability_missing = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getAvailabilityMissing = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setAvailabilityMissing = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearAvailabilityMissing = function() {
  return this.setAvailabilityMissing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasAvailabilityMissing = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional double confidentiality_coverage = 13;
 * @return {number}
 */
proto.agent.UpdateReportRequest.prototype.getConfidentialityCoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setConfidentialityCoverage = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearConfidentialityCoverage = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasConfidentialityCoverage = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue confidentiality_missing = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getConfidentialityMissing = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setConfidentialityMissing = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearConfidentialityMissing = function() {
  return this.setConfidentialityMissing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasConfidentialityMissing = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional double privacy_coverage = 15;
 * @return {number}
 */
proto.agent.UpdateReportRequest.prototype.getPrivacyCoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setPrivacyCoverage = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearPrivacyCoverage = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasPrivacyCoverage = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue privacy_missing = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getPrivacyMissing = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setPrivacyMissing = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearPrivacyMissing = function() {
  return this.setPrivacyMissing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasPrivacyMissing = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional double processing_integrity_coverage = 17;
 * @return {number}
 */
proto.agent.UpdateReportRequest.prototype.getProcessingIntegrityCoverage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.setProcessingIntegrityCoverage = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearProcessingIntegrityCoverage = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasProcessingIntegrityCoverage = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.StringValue processing_integrity_missing = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getProcessingIntegrityMissing = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setProcessingIntegrityMissing = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearProcessingIntegrityMissing = function() {
  return this.setProcessingIntegrityMissing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasProcessingIntegrityMissing = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.Int32Value control_non_occurrences = 19;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.UpdateReportRequest.prototype.getControlNonOccurrences = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 19));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setControlNonOccurrences = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearControlNonOccurrences = function() {
  return this.setControlNonOccurrences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasControlNonOccurrences = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue control_non_occurrence_details = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getControlNonOccurrenceDetails = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setControlNonOccurrenceDetails = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearControlNonOccurrenceDetails = function() {
  return this.setControlNonOccurrenceDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasControlNonOccurrenceDetails = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Int32Value control_exceptions = 21;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.agent.UpdateReportRequest.prototype.getControlExceptions = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 21));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setControlExceptions = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearControlExceptions = function() {
  return this.setControlExceptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasControlExceptions = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.StringValue control_exception_details = 22;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getControlExceptionDetails = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 22));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setControlExceptionDetails = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearControlExceptionDetails = function() {
  return this.setControlExceptionDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasControlExceptionDetails = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.StringValue audit_section_update = 23;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getAuditSectionUpdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 23));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setAuditSectionUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearAuditSectionUpdate = function() {
  return this.setAuditSectionUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasAuditSectionUpdate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.StringValue auditor_section_update = 24;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getAuditorSectionUpdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 24));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setAuditorSectionUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearAuditorSectionUpdate = function() {
  return this.setAuditorSectionUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasAuditorSectionUpdate = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.StringValue criteria_section_update = 25;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getCriteriaSectionUpdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 25));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setCriteriaSectionUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearCriteriaSectionUpdate = function() {
  return this.setCriteriaSectionUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasCriteriaSectionUpdate = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.StringValue non_occurrence_section_update = 26;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getNonOccurrenceSectionUpdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 26));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setNonOccurrenceSectionUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearNonOccurrenceSectionUpdate = function() {
  return this.setNonOccurrenceSectionUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasNonOccurrenceSectionUpdate = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.StringValue exception_section_update = 27;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getExceptionSectionUpdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 27));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setExceptionSectionUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearExceptionSectionUpdate = function() {
  return this.setExceptionSectionUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasExceptionSectionUpdate = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.StringValue firm = 28;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getFirm = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 28));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setFirm = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearFirm = function() {
  return this.setFirm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasFirm = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.StringValue firm_section_update = 29;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.agent.UpdateReportRequest.prototype.getFirmSectionUpdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 29));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.agent.UpdateReportRequest} returns this
*/
proto.agent.UpdateReportRequest.prototype.setFirmSectionUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.UpdateReportRequest} returns this
 */
proto.agent.UpdateReportRequest.prototype.clearFirmSectionUpdate = function() {
  return this.setFirmSectionUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.UpdateReportRequest.prototype.hasFirmSectionUpdate = function() {
  return jspb.Message.getField(this, 29) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.RestoreOriginalReportValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.RestoreOriginalReportValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.RestoreOriginalReportValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.RestoreOriginalReportValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.RestoreOriginalReportValueRequest}
 */
proto.agent.RestoreOriginalReportValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.RestoreOriginalReportValueRequest;
  return proto.agent.RestoreOriginalReportValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.RestoreOriginalReportValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.RestoreOriginalReportValueRequest}
 */
proto.agent.RestoreOriginalReportValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 2:
      var value = /** @type {!proto.agent.ReportValue} */ (reader.readEnum());
      msg.setReportValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.RestoreOriginalReportValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.RestoreOriginalReportValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.RestoreOriginalReportValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.RestoreOriginalReportValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportValue();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string report_id = 1;
 * @return {string}
 */
proto.agent.RestoreOriginalReportValueRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.RestoreOriginalReportValueRequest} returns this
 */
proto.agent.RestoreOriginalReportValueRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ReportValue report_value = 2;
 * @return {!proto.agent.ReportValue}
 */
proto.agent.RestoreOriginalReportValueRequest.prototype.getReportValue = function() {
  return /** @type {!proto.agent.ReportValue} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.agent.ReportValue} value
 * @return {!proto.agent.RestoreOriginalReportValueRequest} returns this
 */
proto.agent.RestoreOriginalReportValueRequest.prototype.setReportValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ListReportsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ListReportsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ListReportsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ListReportsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListReportsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.agent.ReportSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ListReportsReply}
 */
proto.agent.ListReportsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ListReportsReply;
  return proto.agent.ListReportsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ListReportsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ListReportsReply}
 */
proto.agent.ListReportsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReportSummary;
      reader.readMessage(value,proto.agent.ReportSummary.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ListReportsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ListReportsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ListReportsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListReportsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.ReportSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReportSummary Reports = 1;
 * @return {!Array<!proto.agent.ReportSummary>}
 */
proto.agent.ListReportsReply.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.agent.ReportSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.ReportSummary, 1));
};


/**
 * @param {!Array<!proto.agent.ReportSummary>} value
 * @return {!proto.agent.ListReportsReply} returns this
*/
proto.agent.ListReportsReply.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.ReportSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.ReportSummary}
 */
proto.agent.ListReportsReply.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.ReportSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ListReportsReply} returns this
 */
proto.agent.ListReportsReply.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.TestPreview.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.TestPreview.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.TestPreview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.TestPreview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.TestPreview.toObject = function(includeInstance, msg) {
  var f, obj = {
    testList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.TestPreview}
 */
proto.agent.TestPreview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.TestPreview;
  return proto.agent.TestPreview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.TestPreview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.TestPreview}
 */
proto.agent.TestPreview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.TestPreview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.TestPreview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.TestPreview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.TestPreview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string Test = 1;
 * @return {!Array<string>}
 */
proto.agent.TestPreview.prototype.getTestList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.TestPreview} returns this
 */
proto.agent.TestPreview.prototype.setTestList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.TestPreview} returns this
 */
proto.agent.TestPreview.prototype.addTest = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.TestPreview} returns this
 */
proto.agent.TestPreview.prototype.clearTestList = function() {
  return this.setTestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedEvidence.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedEvidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedEvidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidence: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedEvidence}
 */
proto.agent.SuggestedEvidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedEvidence;
  return proto.agent.SuggestedEvidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedEvidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedEvidence}
 */
proto.agent.SuggestedEvidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedEvidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedEvidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedEvidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidence();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Evidence = 1;
 * @return {string}
 */
proto.agent.SuggestedEvidence.prototype.getEvidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidence} returns this
 */
proto.agent.SuggestedEvidence.prototype.setEvidence = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.GetOperationalizationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.GetOperationalizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.GetOperationalizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.GetOperationalizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetOperationalizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    complianceFrameworkBaseModelIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    refresh: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.GetOperationalizationRequest}
 */
proto.agent.GetOperationalizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.GetOperationalizationRequest;
  return proto.agent.GetOperationalizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.GetOperationalizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.GetOperationalizationRequest}
 */
proto.agent.GetOperationalizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkBaseModelIds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefresh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.GetOperationalizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.GetOperationalizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.GetOperationalizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetOperationalizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getRefresh();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.GetOperationalizationRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetOperationalizationRequest} returns this
 */
proto.agent.GetOperationalizationRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string compliance_framework_base_model_ids = 2;
 * @return {!Array<string>}
 */
proto.agent.GetOperationalizationRequest.prototype.getComplianceFrameworkBaseModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.GetOperationalizationRequest} returns this
 */
proto.agent.GetOperationalizationRequest.prototype.setComplianceFrameworkBaseModelIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.GetOperationalizationRequest} returns this
 */
proto.agent.GetOperationalizationRequest.prototype.addComplianceFrameworkBaseModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.GetOperationalizationRequest} returns this
 */
proto.agent.GetOperationalizationRequest.prototype.clearComplianceFrameworkBaseModelIdsList = function() {
  return this.setComplianceFrameworkBaseModelIdsList([]);
};


/**
 * optional bool refresh = 3;
 * @return {boolean}
 */
proto.agent.GetOperationalizationRequest.prototype.getRefresh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.GetOperationalizationRequest} returns this
 */
proto.agent.GetOperationalizationRequest.prototype.setRefresh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.OperationalizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.OperationalizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.OperationalizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.OperationalizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationalization: (f = msg.getOperationalization()) && proto.agent.OperationalizationRecord.toObject(includeInstance, f),
    isNewResponse: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.OperationalizationResponse}
 */
proto.agent.OperationalizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.OperationalizationResponse;
  return proto.agent.OperationalizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.OperationalizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.OperationalizationResponse}
 */
proto.agent.OperationalizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.OperationalizationRecord;
      reader.readMessage(value,proto.agent.OperationalizationRecord.deserializeBinaryFromReader);
      msg.setOperationalization(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNewResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.OperationalizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.OperationalizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.OperationalizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.OperationalizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationalization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.OperationalizationRecord.serializeBinaryToWriter
    );
  }
  f = message.getIsNewResponse();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional OperationalizationRecord operationalization = 1;
 * @return {?proto.agent.OperationalizationRecord}
 */
proto.agent.OperationalizationResponse.prototype.getOperationalization = function() {
  return /** @type{?proto.agent.OperationalizationRecord} */ (
    jspb.Message.getWrapperField(this, proto.agent.OperationalizationRecord, 1));
};


/**
 * @param {?proto.agent.OperationalizationRecord|undefined} value
 * @return {!proto.agent.OperationalizationResponse} returns this
*/
proto.agent.OperationalizationResponse.prototype.setOperationalization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.OperationalizationResponse} returns this
 */
proto.agent.OperationalizationResponse.prototype.clearOperationalization = function() {
  return this.setOperationalization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.OperationalizationResponse.prototype.hasOperationalization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_new_response = 2;
 * @return {boolean}
 */
proto.agent.OperationalizationResponse.prototype.getIsNewResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.OperationalizationResponse} returns this
 */
proto.agent.OperationalizationResponse.prototype.setIsNewResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.OperationalizationRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.OperationalizationRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.OperationalizationRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.OperationalizationRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    body: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dismissed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    controlId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.OperationalizationRecord}
 */
proto.agent.OperationalizationRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.OperationalizationRecord;
  return proto.agent.OperationalizationRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.OperationalizationRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.OperationalizationRecord}
 */
proto.agent.OperationalizationRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 5:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismissed(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.OperationalizationRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.OperationalizationRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.OperationalizationRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.OperationalizationRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDismissed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.agent.OperationalizationRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.agent.OperationalizationRecord.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string compliance_framework_base_model_id = 4;
 * @return {string}
 */
proto.agent.OperationalizationRecord.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 5;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.OperationalizationRecord.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string body = 6;
 * @return {string}
 */
proto.agent.OperationalizationRecord.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool dismissed = 7;
 * @return {boolean}
 */
proto.agent.OperationalizationRecord.prototype.getDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string control_id = 8;
 * @return {string}
 */
proto.agent.OperationalizationRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.OperationalizationRecord} returns this
 */
proto.agent.OperationalizationRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedReceptor.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedReceptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedReceptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedReceptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGeneratorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    evidenceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedReceptor}
 */
proto.agent.SuggestedReceptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedReceptor;
  return proto.agent.SuggestedReceptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedReceptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedReceptor}
 */
proto.agent.SuggestedReceptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceGeneratorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedReceptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedReceptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedReceptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedReceptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGeneratorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEvidenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string evidence_generator_id = 1;
 * @return {string}
 */
proto.agent.SuggestedReceptor.prototype.getEvidenceGeneratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedReceptor} returns this
 */
proto.agent.SuggestedReceptor.prototype.setEvidenceGeneratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string evidence_id = 2;
 * @return {string}
 */
proto.agent.SuggestedReceptor.prototype.getEvidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedReceptor} returns this
 */
proto.agent.SuggestedReceptor.prototype.setEvidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.SuggestedReceptors.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedReceptors.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedReceptors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedReceptors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedReceptors.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestionsList: jspb.Message.toObjectList(msg.getSuggestionsList(),
    proto.agent.SuggestedReceptor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedReceptors}
 */
proto.agent.SuggestedReceptors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedReceptors;
  return proto.agent.SuggestedReceptors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedReceptors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedReceptors}
 */
proto.agent.SuggestedReceptors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.agent.SuggestedReceptor;
      reader.readMessage(value,proto.agent.SuggestedReceptor.deserializeBinaryFromReader);
      msg.addSuggestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedReceptors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedReceptors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedReceptors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedReceptors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.agent.SuggestedReceptor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SuggestedReceptor suggestions = 2;
 * @return {!Array<!proto.agent.SuggestedReceptor>}
 */
proto.agent.SuggestedReceptors.prototype.getSuggestionsList = function() {
  return /** @type{!Array<!proto.agent.SuggestedReceptor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.SuggestedReceptor, 2));
};


/**
 * @param {!Array<!proto.agent.SuggestedReceptor>} value
 * @return {!proto.agent.SuggestedReceptors} returns this
*/
proto.agent.SuggestedReceptors.prototype.setSuggestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.agent.SuggestedReceptor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.SuggestedReceptor}
 */
proto.agent.SuggestedReceptors.prototype.addSuggestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.agent.SuggestedReceptor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.SuggestedReceptors} returns this
 */
proto.agent.SuggestedReceptors.prototype.clearSuggestionsList = function() {
  return this.setSuggestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.AutomatedEvidence.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.AutomatedEvidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.AutomatedEvidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.AutomatedEvidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidencename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.AutomatedEvidence}
 */
proto.agent.AutomatedEvidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.AutomatedEvidence;
  return proto.agent.AutomatedEvidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.AutomatedEvidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.AutomatedEvidence}
 */
proto.agent.AutomatedEvidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidencename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.AutomatedEvidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.AutomatedEvidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.AutomatedEvidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.AutomatedEvidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidencename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string EvidenceName = 1;
 * @return {string}
 */
proto.agent.AutomatedEvidence.prototype.getEvidencename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.AutomatedEvidence} returns this
 */
proto.agent.AutomatedEvidence.prototype.setEvidencename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DocumentId = 2;
 * @return {string}
 */
proto.agent.AutomatedEvidence.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.AutomatedEvidence} returns this
 */
proto.agent.AutomatedEvidence.prototype.setDocumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ContentID = 3;
 * @return {string}
 */
proto.agent.AutomatedEvidence.prototype.getContentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.AutomatedEvidence} returns this
 */
proto.agent.AutomatedEvidence.prototype.setContentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Mime = 4;
 * @return {string}
 */
proto.agent.AutomatedEvidence.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.AutomatedEvidence} returns this
 */
proto.agent.AutomatedEvidence.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.SuggestedAutomatedEvidence.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedAutomatedEvidence.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedAutomatedEvidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedAutomatedEvidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedAutomatedEvidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestionsList: jspb.Message.toObjectList(msg.getSuggestionsList(),
    proto.agent.AutomatedEvidence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedAutomatedEvidence}
 */
proto.agent.SuggestedAutomatedEvidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedAutomatedEvidence;
  return proto.agent.SuggestedAutomatedEvidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedAutomatedEvidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedAutomatedEvidence}
 */
proto.agent.SuggestedAutomatedEvidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.AutomatedEvidence;
      reader.readMessage(value,proto.agent.AutomatedEvidence.deserializeBinaryFromReader);
      msg.addSuggestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedAutomatedEvidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedAutomatedEvidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedAutomatedEvidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedAutomatedEvidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.AutomatedEvidence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutomatedEvidence Suggestions = 1;
 * @return {!Array<!proto.agent.AutomatedEvidence>}
 */
proto.agent.SuggestedAutomatedEvidence.prototype.getSuggestionsList = function() {
  return /** @type{!Array<!proto.agent.AutomatedEvidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.AutomatedEvidence, 1));
};


/**
 * @param {!Array<!proto.agent.AutomatedEvidence>} value
 * @return {!proto.agent.SuggestedAutomatedEvidence} returns this
*/
proto.agent.SuggestedAutomatedEvidence.prototype.setSuggestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.AutomatedEvidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.AutomatedEvidence}
 */
proto.agent.SuggestedAutomatedEvidence.prototype.addSuggestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.AutomatedEvidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.SuggestedAutomatedEvidence} returns this
 */
proto.agent.SuggestedAutomatedEvidence.prototype.clearSuggestionsList = function() {
  return this.setSuggestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.CSOCS.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.CSOCS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.CSOCS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.CSOCS.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.CSOCS}
 */
proto.agent.CSOCS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.CSOCS;
  return proto.agent.CSOCS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.CSOCS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.CSOCS}
 */
proto.agent.CSOCS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.CSOCS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.CSOCS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.CSOCS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.CSOCS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Body = 1;
 * @return {string}
 */
proto.agent.CSOCS.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.CSOCS} returns this
 */
proto.agent.CSOCS.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ImportControlsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ImportControlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ImportControlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ImportControlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ImportControlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlsList: jspb.Message.toObjectList(msg.getControlsList(),
    model_control_pb.CreateUpdateControlRequest.toObject, includeInstance),
    destructiveUpload: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ImportControlsRequest}
 */
proto.agent.ImportControlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ImportControlsRequest;
  return proto.agent.ImportControlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ImportControlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ImportControlsRequest}
 */
proto.agent.ImportControlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_control_pb.CreateUpdateControlRequest;
      reader.readMessage(value,model_control_pb.CreateUpdateControlRequest.deserializeBinaryFromReader);
      msg.addControls(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDestructiveUpload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ImportControlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ImportControlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ImportControlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ImportControlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      model_control_pb.CreateUpdateControlRequest.serializeBinaryToWriter
    );
  }
  f = message.getDestructiveUpload();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated model.CreateUpdateControlRequest controls = 1;
 * @return {!Array<!proto.model.CreateUpdateControlRequest>}
 */
proto.agent.ImportControlsRequest.prototype.getControlsList = function() {
  return /** @type{!Array<!proto.model.CreateUpdateControlRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, model_control_pb.CreateUpdateControlRequest, 1));
};


/**
 * @param {!Array<!proto.model.CreateUpdateControlRequest>} value
 * @return {!proto.agent.ImportControlsRequest} returns this
*/
proto.agent.ImportControlsRequest.prototype.setControlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.CreateUpdateControlRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.CreateUpdateControlRequest}
 */
proto.agent.ImportControlsRequest.prototype.addControls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.CreateUpdateControlRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ImportControlsRequest} returns this
 */
proto.agent.ImportControlsRequest.prototype.clearControlsList = function() {
  return this.setControlsList([]);
};


/**
 * optional bool destructive_upload = 2;
 * @return {boolean}
 */
proto.agent.ImportControlsRequest.prototype.getDestructiveUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.ImportControlsRequest} returns this
 */
proto.agent.ImportControlsRequest.prototype.setDestructiveUpload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.GetSuggestedRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.GetSuggestedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.GetSuggestedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.GetSuggestedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetSuggestedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    complianceFrameworkBaseModelIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.GetSuggestedRequest}
 */
proto.agent.GetSuggestedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.GetSuggestedRequest;
  return proto.agent.GetSuggestedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.GetSuggestedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.GetSuggestedRequest}
 */
proto.agent.GetSuggestedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkBaseModelIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.GetSuggestedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.GetSuggestedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.GetSuggestedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetSuggestedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.GetSuggestedRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetSuggestedRequest} returns this
 */
proto.agent.GetSuggestedRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string compliance_framework_base_model_ids = 2;
 * @return {!Array<string>}
 */
proto.agent.GetSuggestedRequest.prototype.getComplianceFrameworkBaseModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.GetSuggestedRequest} returns this
 */
proto.agent.GetSuggestedRequest.prototype.setComplianceFrameworkBaseModelIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.GetSuggestedRequest} returns this
 */
proto.agent.GetSuggestedRequest.prototype.addComplianceFrameworkBaseModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.GetSuggestedRequest} returns this
 */
proto.agent.GetSuggestedRequest.prototype.clearComplianceFrameworkBaseModelIdsList = function() {
  return this.setComplianceFrameworkBaseModelIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    controlId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedEvidenceRequest}
 */
proto.agent.SuggestedEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedEvidenceRequest;
  return proto.agent.SuggestedEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedEvidenceRequest}
 */
proto.agent.SuggestedEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 6:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string body = 1;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRequest} returns this
 */
proto.agent.SuggestedEvidenceRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string compliance_framework_base_model_id = 3;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRequest.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRequest} returns this
 */
proto.agent.SuggestedEvidenceRequest.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 6;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.SuggestedEvidenceRequest.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.SuggestedEvidenceRequest} returns this
 */
proto.agent.SuggestedEvidenceRequest.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string control_id = 7;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRequest} returns this
 */
proto.agent.SuggestedEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedTestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedTestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedTestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    controlId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedTestRequest}
 */
proto.agent.SuggestedTestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedTestRequest;
  return proto.agent.SuggestedTestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedTestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedTestRequest}
 */
proto.agent.SuggestedTestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 6:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedTestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedTestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedTestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string body = 1;
 * @return {string}
 */
proto.agent.SuggestedTestRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRequest} returns this
 */
proto.agent.SuggestedTestRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string compliance_framework_base_model_id = 3;
 * @return {string}
 */
proto.agent.SuggestedTestRequest.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRequest} returns this
 */
proto.agent.SuggestedTestRequest.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 6;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.SuggestedTestRequest.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.SuggestedTestRequest} returns this
 */
proto.agent.SuggestedTestRequest.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string control_id = 7;
 * @return {string}
 */
proto.agent.SuggestedTestRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRequest} returns this
 */
proto.agent.SuggestedTestRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.HowToHandleItRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.HowToHandleItRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.HowToHandleItRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    body: jspb.Message.getFieldWithDefault(msg, 1, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    controlId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.HowToHandleItRequest}
 */
proto.agent.HowToHandleItRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.HowToHandleItRequest;
  return proto.agent.HowToHandleItRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.HowToHandleItRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.HowToHandleItRequest}
 */
proto.agent.HowToHandleItRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 6:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.HowToHandleItRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.HowToHandleItRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.HowToHandleItRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string body = 1;
 * @return {string}
 */
proto.agent.HowToHandleItRequest.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRequest} returns this
 */
proto.agent.HowToHandleItRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string compliance_framework_base_model_id = 3;
 * @return {string}
 */
proto.agent.HowToHandleItRequest.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRequest} returns this
 */
proto.agent.HowToHandleItRequest.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 6;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.HowToHandleItRequest.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.HowToHandleItRequest} returns this
 */
proto.agent.HowToHandleItRequest.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string control_id = 7;
 * @return {string}
 */
proto.agent.HowToHandleItRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRequest} returns this
 */
proto.agent.HowToHandleItRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedTestRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedTestRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedTestRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dismissed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedTestRecord}
 */
proto.agent.SuggestedTestRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedTestRecord;
  return proto.agent.SuggestedTestRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedTestRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedTestRecord}
 */
proto.agent.SuggestedTestRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismissed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedTestRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedTestRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedTestRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDismissed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.agent.SuggestedTestRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.agent.SuggestedTestRecord.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_id = 3;
 * @return {string}
 */
proto.agent.SuggestedTestRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string compliance_framework_base_model_id = 4;
 * @return {string}
 */
proto.agent.SuggestedTestRecord.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.agent.SuggestedTestRecord.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 6;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.SuggestedTestRecord.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool dismissed = 7;
 * @return {boolean}
 */
proto.agent.SuggestedTestRecord.prototype.getDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_custom = 8;
 * @return {boolean}
 */
proto.agent.SuggestedTestRecord.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.SuggestedTestRecord} returns this
 */
proto.agent.SuggestedTestRecord.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.HowToHandleItRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.HowToHandleItRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.HowToHandleItRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dismissed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.HowToHandleItRecord}
 */
proto.agent.HowToHandleItRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.HowToHandleItRecord;
  return proto.agent.HowToHandleItRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.HowToHandleItRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.HowToHandleItRecord}
 */
proto.agent.HowToHandleItRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismissed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.HowToHandleItRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.HowToHandleItRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.HowToHandleItRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDismissed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.agent.HowToHandleItRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.agent.HowToHandleItRecord.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_id = 3;
 * @return {string}
 */
proto.agent.HowToHandleItRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string compliance_framework_base_model_id = 4;
 * @return {string}
 */
proto.agent.HowToHandleItRecord.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.agent.HowToHandleItRecord.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 6;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.HowToHandleItRecord.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool dismissed = 7;
 * @return {boolean}
 */
proto.agent.HowToHandleItRecord.prototype.getDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_custom = 8;
 * @return {boolean}
 */
proto.agent.HowToHandleItRecord.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.HowToHandleItRecord} returns this
 */
proto.agent.HowToHandleItRecord.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedEvidenceRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedEvidenceRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedEvidenceRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    body: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    complianceFrameworkBaseModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    recommendStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dismissed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedEvidenceRecord}
 */
proto.agent.SuggestedEvidenceRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedEvidenceRecord;
  return proto.agent.SuggestedEvidenceRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedEvidenceRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedEvidenceRecord}
 */
proto.agent.SuggestedEvidenceRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkBaseModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (reader.readEnum());
      msg.setRecommendStatus(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismissed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedEvidenceRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedEvidenceRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedEvidenceRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComplianceFrameworkBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRecommendStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDismissed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string body = 2;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_id = 3;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string compliance_framework_base_model_id = 4;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getComplianceFrameworkBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setComplianceFrameworkBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional RECOMMENDATION_STATUS recommend_status = 6;
 * @return {!proto.agent.RECOMMENDATION_STATUS}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getRecommendStatus = function() {
  return /** @type {!proto.agent.RECOMMENDATION_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.agent.RECOMMENDATION_STATUS} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setRecommendStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool dismissed = 7;
 * @return {boolean}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_custom = 8;
 * @return {boolean}
 */
proto.agent.SuggestedEvidenceRecord.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.SuggestedEvidenceRecord} returns this
 */
proto.agent.SuggestedEvidenceRecord.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedEvidenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedEvidenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedEvidenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestedEvidence: (f = msg.getSuggestedEvidence()) && proto.agent.SuggestedEvidenceRecord.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedEvidenceResponse}
 */
proto.agent.SuggestedEvidenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedEvidenceResponse;
  return proto.agent.SuggestedEvidenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedEvidenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedEvidenceResponse}
 */
proto.agent.SuggestedEvidenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.SuggestedEvidenceRecord;
      reader.readMessage(value,proto.agent.SuggestedEvidenceRecord.deserializeBinaryFromReader);
      msg.setSuggestedEvidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedEvidenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedEvidenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedEvidenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestedEvidence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.SuggestedEvidenceRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional SuggestedEvidenceRecord suggested_evidence = 1;
 * @return {?proto.agent.SuggestedEvidenceRecord}
 */
proto.agent.SuggestedEvidenceResponse.prototype.getSuggestedEvidence = function() {
  return /** @type{?proto.agent.SuggestedEvidenceRecord} */ (
    jspb.Message.getWrapperField(this, proto.agent.SuggestedEvidenceRecord, 1));
};


/**
 * @param {?proto.agent.SuggestedEvidenceRecord|undefined} value
 * @return {!proto.agent.SuggestedEvidenceResponse} returns this
*/
proto.agent.SuggestedEvidenceResponse.prototype.setSuggestedEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.SuggestedEvidenceResponse} returns this
 */
proto.agent.SuggestedEvidenceResponse.prototype.clearSuggestedEvidence = function() {
  return this.setSuggestedEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.SuggestedEvidenceResponse.prototype.hasSuggestedEvidence = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedTestResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedTestResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedTestResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestedTest: (f = msg.getSuggestedTest()) && proto.agent.SuggestedTestRecord.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedTestResponse}
 */
proto.agent.SuggestedTestResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedTestResponse;
  return proto.agent.SuggestedTestResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedTestResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedTestResponse}
 */
proto.agent.SuggestedTestResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.SuggestedTestRecord;
      reader.readMessage(value,proto.agent.SuggestedTestRecord.deserializeBinaryFromReader);
      msg.setSuggestedTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedTestResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedTestResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedTestResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestedTest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.SuggestedTestRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional SuggestedTestRecord suggested_test = 1;
 * @return {?proto.agent.SuggestedTestRecord}
 */
proto.agent.SuggestedTestResponse.prototype.getSuggestedTest = function() {
  return /** @type{?proto.agent.SuggestedTestRecord} */ (
    jspb.Message.getWrapperField(this, proto.agent.SuggestedTestRecord, 1));
};


/**
 * @param {?proto.agent.SuggestedTestRecord|undefined} value
 * @return {!proto.agent.SuggestedTestResponse} returns this
*/
proto.agent.SuggestedTestResponse.prototype.setSuggestedTest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.SuggestedTestResponse} returns this
 */
proto.agent.SuggestedTestResponse.prototype.clearSuggestedTest = function() {
  return this.setSuggestedTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.SuggestedTestResponse.prototype.hasSuggestedTest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.HowToHandleItResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.HowToHandleItResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.HowToHandleItResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    howToHandleIt: (f = msg.getHowToHandleIt()) && proto.agent.HowToHandleItRecord.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.HowToHandleItResponse}
 */
proto.agent.HowToHandleItResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.HowToHandleItResponse;
  return proto.agent.HowToHandleItResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.HowToHandleItResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.HowToHandleItResponse}
 */
proto.agent.HowToHandleItResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.HowToHandleItRecord;
      reader.readMessage(value,proto.agent.HowToHandleItRecord.deserializeBinaryFromReader);
      msg.setHowToHandleIt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.HowToHandleItResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.HowToHandleItResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.HowToHandleItResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHowToHandleIt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.HowToHandleItRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional HowToHandleItRecord how_to_handle_it = 1;
 * @return {?proto.agent.HowToHandleItRecord}
 */
proto.agent.HowToHandleItResponse.prototype.getHowToHandleIt = function() {
  return /** @type{?proto.agent.HowToHandleItRecord} */ (
    jspb.Message.getWrapperField(this, proto.agent.HowToHandleItRecord, 1));
};


/**
 * @param {?proto.agent.HowToHandleItRecord|undefined} value
 * @return {!proto.agent.HowToHandleItResponse} returns this
*/
proto.agent.HowToHandleItResponse.prototype.setHowToHandleIt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.HowToHandleItResponse} returns this
 */
proto.agent.HowToHandleItResponse.prototype.clearHowToHandleIt = function() {
  return this.setHowToHandleIt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.HowToHandleItResponse.prototype.hasHowToHandleIt = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedDLRReceptorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedDLRReceptorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedDLRReceptorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedDLRReceptorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedDLRReceptorRequest}
 */
proto.agent.SuggestedDLRReceptorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedDLRReceptorRequest;
  return proto.agent.SuggestedDLRReceptorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedDLRReceptorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedDLRReceptorRequest}
 */
proto.agent.SuggestedDLRReceptorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedDLRReceptorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedDLRReceptorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedDLRReceptorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedDLRReceptorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.agent.SuggestedDLRReceptorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.SuggestedDLRReceptorRequest} returns this
 */
proto.agent.SuggestedDLRReceptorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ToggleSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ToggleSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ToggleSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ToggleSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    restoreAll: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ToggleSuggestionsRequest}
 */
proto.agent.ToggleSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ToggleSuggestionsRequest;
  return proto.agent.ToggleSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ToggleSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ToggleSuggestionsRequest}
 */
proto.agent.ToggleSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {!proto.agent.SUGGESTION_TYPES} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRestoreAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ToggleSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ToggleSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ToggleSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ToggleSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRestoreAll();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.ToggleSuggestionsRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ToggleSuggestionsRequest} returns this
 */
proto.agent.ToggleSuggestionsRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SUGGESTION_TYPES type = 2;
 * @return {!proto.agent.SUGGESTION_TYPES}
 */
proto.agent.ToggleSuggestionsRequest.prototype.getType = function() {
  return /** @type {!proto.agent.SUGGESTION_TYPES} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.agent.SUGGESTION_TYPES} value
 * @return {!proto.agent.ToggleSuggestionsRequest} returns this
 */
proto.agent.ToggleSuggestionsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool restore_all = 3;
 * @return {boolean}
 */
proto.agent.ToggleSuggestionsRequest.prototype.getRestoreAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.ToggleSuggestionsRequest} returns this
 */
proto.agent.ToggleSuggestionsRequest.prototype.setRestoreAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.UpdateSuggestedTestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.UpdateSuggestedTestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.UpdateSuggestedTestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateSuggestedTestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestedTests: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.UpdateSuggestedTestsRequest}
 */
proto.agent.UpdateSuggestedTestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.UpdateSuggestedTestsRequest;
  return proto.agent.UpdateSuggestedTestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.UpdateSuggestedTestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.UpdateSuggestedTestsRequest}
 */
proto.agent.UpdateSuggestedTestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestedTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.UpdateSuggestedTestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.UpdateSuggestedTestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.UpdateSuggestedTestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateSuggestedTestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestedTests();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.UpdateSuggestedTestsRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateSuggestedTestsRequest} returns this
 */
proto.agent.UpdateSuggestedTestsRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string suggested_tests = 2;
 * @return {string}
 */
proto.agent.UpdateSuggestedTestsRequest.prototype.getSuggestedTests = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateSuggestedTestsRequest} returns this
 */
proto.agent.UpdateSuggestedTestsRequest.prototype.setSuggestedTests = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ResetSuggestedTestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ResetSuggestedTestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ResetSuggestedTestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ResetSuggestedTestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ResetSuggestedTestsRequest}
 */
proto.agent.ResetSuggestedTestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ResetSuggestedTestsRequest;
  return proto.agent.ResetSuggestedTestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ResetSuggestedTestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ResetSuggestedTestsRequest}
 */
proto.agent.ResetSuggestedTestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ResetSuggestedTestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ResetSuggestedTestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ResetSuggestedTestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ResetSuggestedTestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.ResetSuggestedTestsRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ResetSuggestedTestsRequest} returns this
 */
proto.agent.ResetSuggestedTestsRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.UpdateSuggestedEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.UpdateSuggestedEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.UpdateSuggestedEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateSuggestedEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestedEvidence: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.UpdateSuggestedEvidenceRequest}
 */
proto.agent.UpdateSuggestedEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.UpdateSuggestedEvidenceRequest;
  return proto.agent.UpdateSuggestedEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.UpdateSuggestedEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.UpdateSuggestedEvidenceRequest}
 */
proto.agent.UpdateSuggestedEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestedEvidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.UpdateSuggestedEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.UpdateSuggestedEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.UpdateSuggestedEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateSuggestedEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestedEvidence();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.UpdateSuggestedEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateSuggestedEvidenceRequest} returns this
 */
proto.agent.UpdateSuggestedEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string suggested_evidence = 2;
 * @return {string}
 */
proto.agent.UpdateSuggestedEvidenceRequest.prototype.getSuggestedEvidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateSuggestedEvidenceRequest} returns this
 */
proto.agent.UpdateSuggestedEvidenceRequest.prototype.setSuggestedEvidence = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ResetSuggestedEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ResetSuggestedEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ResetSuggestedEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ResetSuggestedEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ResetSuggestedEvidenceRequest}
 */
proto.agent.ResetSuggestedEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ResetSuggestedEvidenceRequest;
  return proto.agent.ResetSuggestedEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ResetSuggestedEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ResetSuggestedEvidenceRequest}
 */
proto.agent.ResetSuggestedEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ResetSuggestedEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ResetSuggestedEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ResetSuggestedEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ResetSuggestedEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.agent.ResetSuggestedEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ResetSuggestedEvidenceRequest} returns this
 */
proto.agent.ResetSuggestedEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ListSuggestionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ListSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ListSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ListSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    typesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ListSuggestionsRequest}
 */
proto.agent.ListSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ListSuggestionsRequest;
  return proto.agent.ListSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ListSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ListSuggestionsRequest}
 */
proto.agent.ListSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.agent.SUGGESTION_TYPES>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ListSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ListSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ListSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated SUGGESTION_TYPES types = 1;
 * @return {!Array<!proto.agent.SUGGESTION_TYPES>}
 */
proto.agent.ListSuggestionsRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.agent.SUGGESTION_TYPES>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.agent.SUGGESTION_TYPES>} value
 * @return {!proto.agent.ListSuggestionsRequest} returns this
 */
proto.agent.ListSuggestionsRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.agent.SUGGESTION_TYPES} value
 * @param {number=} opt_index
 * @return {!proto.agent.ListSuggestionsRequest} returns this
 */
proto.agent.ListSuggestionsRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ListSuggestionsRequest} returns this
 */
proto.agent.ListSuggestionsRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.SuggestedEvidenceList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedEvidenceList.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedEvidenceList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedEvidenceList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceList.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.agent.SuggestedEvidenceRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedEvidenceList}
 */
proto.agent.SuggestedEvidenceList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedEvidenceList;
  return proto.agent.SuggestedEvidenceList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedEvidenceList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedEvidenceList}
 */
proto.agent.SuggestedEvidenceList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.SuggestedEvidenceRecord;
      reader.readMessage(value,proto.agent.SuggestedEvidenceRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedEvidenceList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedEvidenceList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedEvidenceList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedEvidenceList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.SuggestedEvidenceRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SuggestedEvidenceRecord records = 1;
 * @return {!Array<!proto.agent.SuggestedEvidenceRecord>}
 */
proto.agent.SuggestedEvidenceList.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.agent.SuggestedEvidenceRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.SuggestedEvidenceRecord, 1));
};


/**
 * @param {!Array<!proto.agent.SuggestedEvidenceRecord>} value
 * @return {!proto.agent.SuggestedEvidenceList} returns this
*/
proto.agent.SuggestedEvidenceList.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.SuggestedEvidenceRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.SuggestedEvidenceRecord}
 */
proto.agent.SuggestedEvidenceList.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.SuggestedEvidenceRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.SuggestedEvidenceList} returns this
 */
proto.agent.SuggestedEvidenceList.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.SuggestedTestList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.SuggestedTestList.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.SuggestedTestList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.SuggestedTestList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestList.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.agent.SuggestedTestRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.SuggestedTestList}
 */
proto.agent.SuggestedTestList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.SuggestedTestList;
  return proto.agent.SuggestedTestList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.SuggestedTestList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.SuggestedTestList}
 */
proto.agent.SuggestedTestList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.SuggestedTestRecord;
      reader.readMessage(value,proto.agent.SuggestedTestRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.SuggestedTestList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.SuggestedTestList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.SuggestedTestList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.SuggestedTestList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.SuggestedTestRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SuggestedTestRecord records = 1;
 * @return {!Array<!proto.agent.SuggestedTestRecord>}
 */
proto.agent.SuggestedTestList.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.agent.SuggestedTestRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.SuggestedTestRecord, 1));
};


/**
 * @param {!Array<!proto.agent.SuggestedTestRecord>} value
 * @return {!proto.agent.SuggestedTestList} returns this
*/
proto.agent.SuggestedTestList.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.SuggestedTestRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.SuggestedTestRecord}
 */
proto.agent.SuggestedTestList.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.SuggestedTestRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.SuggestedTestList} returns this
 */
proto.agent.SuggestedTestList.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.HowToHandleItList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.HowToHandleItList.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.HowToHandleItList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.HowToHandleItList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItList.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.agent.HowToHandleItRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.HowToHandleItList}
 */
proto.agent.HowToHandleItList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.HowToHandleItList;
  return proto.agent.HowToHandleItList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.HowToHandleItList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.HowToHandleItList}
 */
proto.agent.HowToHandleItList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.HowToHandleItRecord;
      reader.readMessage(value,proto.agent.HowToHandleItRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.HowToHandleItList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.HowToHandleItList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.HowToHandleItList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.HowToHandleItList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.HowToHandleItRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HowToHandleItRecord records = 1;
 * @return {!Array<!proto.agent.HowToHandleItRecord>}
 */
proto.agent.HowToHandleItList.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.agent.HowToHandleItRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.HowToHandleItRecord, 1));
};


/**
 * @param {!Array<!proto.agent.HowToHandleItRecord>} value
 * @return {!proto.agent.HowToHandleItList} returns this
*/
proto.agent.HowToHandleItList.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.HowToHandleItRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.HowToHandleItRecord}
 */
proto.agent.HowToHandleItList.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.HowToHandleItRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.HowToHandleItList} returns this
 */
proto.agent.HowToHandleItList.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ListSuggestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ListSuggestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ListSuggestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListSuggestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestedEvidence: (f = msg.getSuggestedEvidence()) && proto.agent.SuggestedEvidenceList.toObject(includeInstance, f),
    suggestedTests: (f = msg.getSuggestedTests()) && proto.agent.SuggestedTestList.toObject(includeInstance, f),
    howToHandleIts: (f = msg.getHowToHandleIts()) && proto.agent.HowToHandleItList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ListSuggestionsResponse}
 */
proto.agent.ListSuggestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ListSuggestionsResponse;
  return proto.agent.ListSuggestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ListSuggestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ListSuggestionsResponse}
 */
proto.agent.ListSuggestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.SuggestedEvidenceList;
      reader.readMessage(value,proto.agent.SuggestedEvidenceList.deserializeBinaryFromReader);
      msg.setSuggestedEvidence(value);
      break;
    case 2:
      var value = new proto.agent.SuggestedTestList;
      reader.readMessage(value,proto.agent.SuggestedTestList.deserializeBinaryFromReader);
      msg.setSuggestedTests(value);
      break;
    case 3:
      var value = new proto.agent.HowToHandleItList;
      reader.readMessage(value,proto.agent.HowToHandleItList.deserializeBinaryFromReader);
      msg.setHowToHandleIts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ListSuggestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ListSuggestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ListSuggestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListSuggestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestedEvidence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.SuggestedEvidenceList.serializeBinaryToWriter
    );
  }
  f = message.getSuggestedTests();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.agent.SuggestedTestList.serializeBinaryToWriter
    );
  }
  f = message.getHowToHandleIts();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.agent.HowToHandleItList.serializeBinaryToWriter
    );
  }
};


/**
 * optional SuggestedEvidenceList suggested_evidence = 1;
 * @return {?proto.agent.SuggestedEvidenceList}
 */
proto.agent.ListSuggestionsResponse.prototype.getSuggestedEvidence = function() {
  return /** @type{?proto.agent.SuggestedEvidenceList} */ (
    jspb.Message.getWrapperField(this, proto.agent.SuggestedEvidenceList, 1));
};


/**
 * @param {?proto.agent.SuggestedEvidenceList|undefined} value
 * @return {!proto.agent.ListSuggestionsResponse} returns this
*/
proto.agent.ListSuggestionsResponse.prototype.setSuggestedEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ListSuggestionsResponse} returns this
 */
proto.agent.ListSuggestionsResponse.prototype.clearSuggestedEvidence = function() {
  return this.setSuggestedEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ListSuggestionsResponse.prototype.hasSuggestedEvidence = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SuggestedTestList suggested_tests = 2;
 * @return {?proto.agent.SuggestedTestList}
 */
proto.agent.ListSuggestionsResponse.prototype.getSuggestedTests = function() {
  return /** @type{?proto.agent.SuggestedTestList} */ (
    jspb.Message.getWrapperField(this, proto.agent.SuggestedTestList, 2));
};


/**
 * @param {?proto.agent.SuggestedTestList|undefined} value
 * @return {!proto.agent.ListSuggestionsResponse} returns this
*/
proto.agent.ListSuggestionsResponse.prototype.setSuggestedTests = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ListSuggestionsResponse} returns this
 */
proto.agent.ListSuggestionsResponse.prototype.clearSuggestedTests = function() {
  return this.setSuggestedTests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ListSuggestionsResponse.prototype.hasSuggestedTests = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HowToHandleItList how_to_handle_its = 3;
 * @return {?proto.agent.HowToHandleItList}
 */
proto.agent.ListSuggestionsResponse.prototype.getHowToHandleIts = function() {
  return /** @type{?proto.agent.HowToHandleItList} */ (
    jspb.Message.getWrapperField(this, proto.agent.HowToHandleItList, 3));
};


/**
 * @param {?proto.agent.HowToHandleItList|undefined} value
 * @return {!proto.agent.ListSuggestionsResponse} returns this
*/
proto.agent.ListSuggestionsResponse.prototype.setHowToHandleIts = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ListSuggestionsResponse} returns this
 */
proto.agent.ListSuggestionsResponse.prototype.clearHowToHandleIts = function() {
  return this.setHowToHandleIts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ListSuggestionsResponse.prototype.hasHowToHandleIts = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.agent.ReportValue = {
  FIRM: 0,
  AUDIT: 1,
  AUDITOR: 2,
  CRITERIA: 3,
  NON_OCCURRENCE: 4,
  EXCEPTION: 5
};

/**
 * @enum {number}
 */
proto.agent.ReportStatus = {
  ACCEPTED: 0,
  RUNNING: 1,
  FAILED: 2
};

/**
 * @enum {number}
 */
proto.agent.RECOMMENDATION_STATUS = {
  DEFAULT: 0,
  SUCCESS: 1,
  NONE: 2,
  FAILURE: 3
};

/**
 * @enum {number}
 */
proto.agent.SUGGESTION_TYPES = {
  UNKNOWN: 0,
  EVIDENCE: 1,
  TESTS: 2,
  GUIDANCE: 3,
  HOWTOHANDLEIT: 4,
  MARKETPLACE: 5,
  AUTOMATED_EVIDENCE: 6
};

goog.object.extend(exports, proto.agent);
