import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { usePolicy } from '../../../components/async/model'
import { LinkContent } from './LinkedContent'
import { SubjectContainer } from './Subject.styles'

type Props = {
  modelId: string
  linkable: boolean
}

export function PolicyContent(props: Props): JSX.Element {
  const { data: policy } = usePolicy(props.modelId)
  if (!policy) {
    return <></>
  }

  if (props.linkable) {
    return (
      <LinkContent
        name={policy?.getName()}
        modelId={policy?.getModelId()}
        modelType={MODEL_TYPE.POLICY}
      />
    )
  }

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {policy?.getName()}
    </SubjectContainer>
  )
}
