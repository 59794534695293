import React from 'react'
import { ParsedQuery } from 'query-string'
import {
  ATTESTATION_STATUS,
  AttestationStatusFilter,
  ListVendorsRequest,
  RiskLevelFilter,
  VendorSort,
} from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import {
  GridColumnSortType,
  GridColumnSortVendors,
  SORT_ORDER,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  ATTESTATION_LABEL_TO_STATUS,
  AttestationStatusLabels,
  RISK_TIER_LABEL_TO_LEVEL,
  RiskTierLabels,
  VENDOR_SORT_COLUMNS,
} from './vendors.constants'
import { AttestationFreshIcon, AttestationStaleIcon } from './vendors.styles'

export const applyVendorsFilters = (
  req: ListVendorsRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.RISK_TIER: {
      const riskLevelFilter = new RiskLevelFilter()
      const tiers = (filterParams as RiskTierLabels[]).map(
        (label) => RISK_TIER_LABEL_TO_LEVEL[label],
      )
      tiers.length > 0 && riskLevelFilter.setRiskLevelsList(tiers)
      req.setRiskLevelFilter(riskLevelFilter)
      break
    }
    case FilterParam.ATTESTATION: {
      const attestationFilter = new AttestationStatusFilter()
      const statuses = new Set(
        (filterParams as AttestationStatusLabels[]).map(
          (label) => ATTESTATION_LABEL_TO_STATUS[label],
        ),
      )
      statuses.has(ATTESTATION_STATUS.NONE) &&
        attestationFilter.setIncludeNone(true)
      statuses.has(ATTESTATION_STATUS.STALE) &&
        attestationFilter.setIncludeStale(true)
      statuses.has(ATTESTATION_STATUS.FRESH) &&
        attestationFilter.setIncludeFresh(true)
      statuses.size > 0 && req.setAttestationStatusFilter(attestationFilter)
      break
    }
    default:
      break
  }
}

export const applyVendorsSort = (
  req: ListVendorsRequest,
  queryParams: ParsedQuery<string>,
): void => {
  const sortOrder =
    (queryParams.sort_by && (queryParams.sort_by[0] as GridColumnSortType)) ||
    undefined
  const sortCol =
    (queryParams.sort_col &&
      (queryParams.sort_col[0] as GridColumnSortVendors)) ||
    undefined
  const shouldApplySort = sortOrder && sortCol
  if (!shouldApplySort) {
    return
  }
  const sort = new VendorSort()
  sort.setSortOrder(SORT_ORDER[sortOrder])
  sort.setSortColumn(VENDOR_SORT_COLUMNS[sortCol])
  req.setSort(sort)
}

export const getFreshnessIcon = (isFresh: boolean): JSX.Element => {
  return isFresh ? <AttestationFreshIcon /> : <AttestationStaleIcon />
}

export const getVendorFreshTooltipBody = (
  status: ATTESTATION_STATUS,
): string => {
  switch (status) {
    case ATTESTATION_STATUS.STALE:
      return 'Most recent attestation is out of date'
    case ATTESTATION_STATUS.NONE:
      return 'No attestation for this vendor'
    default:
      return ''
  }
}
