import React from 'react'
import { ShowPageContainer } from 'src/components/PageLayout/ShowPage'
import { ServicesProvider } from '../context/ServicesContext'
import { ServicesShowPageHeader } from './ServicesShowPageHeader'
import { ServicesShowPageContent } from './ServicesShowPageContent'

export const ServicesShowPage = (): JSX.Element => (
  <ServicesProvider>
    <ShowPageContainer>
      <ServicesShowPageHeader />
      <ServicesShowPageContent />
    </ShowPageContainer>
  </ServicesProvider>
)
