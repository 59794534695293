import React from 'react'
import utf8 from 'utf8'
import isString from 'lodash/isString'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId, ModalFormIdQueryParam } from '../../ModalForm'
import { useCreateEvidence } from '../../../async/suggestions/useCreateEvidence'
import { useBulkCreateEvidence } from '../../../async/evidence/useBulkCreateEvidence'
import {
  EvidenceFormData,
  EvidenceType,
} from './forms/AddEvidenceForm.constants'
import { AddEvidenceForm } from './forms/AddEvidenceForm'
import { AddEvidenceFormDescription } from './forms/AddEvidenceForm.components'

export const AddEvidenceQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  CONTROL_ID: 'controlId',
  REQUEST_ID: 'requestId',
}

type AddEvidenceModalProps = {
  modelId?: string
  controlId?: string
  requestId?: string
  name: string
  openOnDrag?: boolean
} & (
  | { requestId: string; controlId?: never; modelId?: never }
  | { controlId: string; modelId: string; requestId?: never }
)
/**
  Modal form to add evidence
 ** MUST HAVE Either a requestId or a modelId
 * Update 5/15/23 - We need BOTH the model id and the control id when adding evidence to a control. Otherwise, use the request id.
 */
export const AddEvidenceModal = ({
  modelId,
  controlId,
  requestId,
  name,
  openOnDrag = false,
}: AddEvidenceModalProps): JSX.Element => {
  const show = useIsShowModal(ModalFormId.ADD_EVIDENCE)
  const hide = useHideModal({ modalId: ModalFormId.ADD_EVIDENCE })
  const createEvidence = useCreateEvidence({
    controlId,
    requestId,
  })
  const bulkCreateEvidence = useBulkCreateEvidence({
    controlId,
    modelId,
    requestId,
  })

  // TODO: Check everywhere this form is used and make sure it is working for the correct control

  const onSubmitFunc = async (
    caption: string,
    formData: EvidenceFormData,
    formDataList: EvidenceFormData[],
    date: Timestamp,
    type: EvidenceType,
    uploadSize = 0,
  ) => {
    if (type === EvidenceType.FILE) {
      await bulkCreateEvidence(
        formDataList.map((f) => f.body as File),
        date,
        uploadSize,
        caption,
      )
    } else {
      const { mime, body } = formData
      // Convert string into utf8 encoded codes
      const bodyBytes = new Uint8Array(
        isString(body)
          ? Array.from(utf8.encode(body.trim())).map((s) => s.charCodeAt(0))
          : [],
      )
      // If this fails the user will get a toast
      await createEvidence({
        mime,
        bodyBytes,
        caption: `${caption.trim()}`,
        relevantDate: date,
      })
    }
    hide()
  }

  return (
    <ModalForm
      formId={ModalFormId.ADD_EVIDENCE}
      title={`Add Evidence for ${name}`}
      description={<AddEvidenceFormDescription />}
      openOnDrag={openOnDrag}
      submitText="Add Evidence"
      show={show}
      hide={hide}
      enforceFocus={false}
    >
      <AddEvidenceForm
        onSubmitFunc={onSubmitFunc}
        controlId={controlId}
        modalId={ModalFormId.ADD_EVIDENCE}
        isShown={show}
      />
    </ModalForm>
  )
}
