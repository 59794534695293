import React from 'react'
import {
  BannerContainer,
  BannerDismiss,
  BannerInfo,
  StyledInternalLink,
} from './banners.styles'

export const Banner = ({
  text,
  link,
  internalLink,
  isWarning,
  noMargin,
  linkText = 'Learn More',
  onDismiss,
}: {
  text: string
  link?: string
  internalLink?: string
  isWarning?: boolean
  noMargin?: boolean
  linkText?: string
  onDismiss?: () => void
}): JSX.Element => (
  <BannerContainer $isWarning={isWarning} $noMargin={noMargin}>
    <BannerInfo>
      <p>{text}</p>
      {link && (
        <a href={link} target="_blank" rel="noreferrer">
          {linkText}
        </a>
      )}
      {internalLink && (
        <StyledInternalLink to={internalLink}>{linkText}</StyledInternalLink>
      )}
    </BannerInfo>
    {onDismiss && (
      <BannerDismiss onClick={onDismiss} color="white">
        Dismiss
      </BannerDismiss>
    )}
  </BannerContainer>
)
