import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const P = styled.p<{ $mt?: number; $mb?: number; $ml?: number }>`
  font-size: 14px;
  ${({ $mt }) => $mt && `margin-top: ${$mt}px`};
  ${({ $mb }) => $mb && `margin-bottom: ${$mb}px`};
  ${({ $ml }) => $ml && `margin-left: ${$ml}px`};
`

export const QuestionnaireH3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
`

export const QuestionnaireAcceptanceContainer = styled.div<{
  $isAccepted: boolean
}>`
  cursor: default;
  display: flex;
  flex-direction: row;
  margin: 0.5em;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  min-width: 120px;
  width: fit-content;
  display: inline-block;
  text-align: center;

  background-color: ${({ $isAccepted }) =>
    $isAccepted ? palette.green[500] : palette.blue[50]};

  color: ${({ $isAccepted }) =>
    $isAccepted ? palette.neutral['white'] : palette.blue[800]};
`
