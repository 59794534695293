import { ChecklistFilterEnum, DashboardQueryParam } from '../types'
import { useGetURLParam } from '.'

const options = new Set(Object.values(ChecklistFilterEnum))

export function useActiveComplianceChecklistFilter(): ChecklistFilterEnum {
  return useGetURLParam(
    DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB,
    options,
    ChecklistFilterEnum.TEAM_WORK,
  )
}
