import React from 'react'
import styled from 'styled-components/macro'
import { LayoutProps, PaddingProps } from 'styled-system'
import { IconButton, IconButtonProps, IconButtonVariant } from '../IconButton'
import { ReactComponent as CloseIcon } from '../../../../components/Icons/assets/close-icon.svg'
import { TestIds } from '../../../../Utils/testIds'

type CloseButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  Omit<IconButtonProps, 'variant' | keyof LayoutProps | keyof PaddingProps> & {
    iconWidth?: string
    iconHeight?: string
  }

const StyledCloseIcon = styled(IconButton)`
  width: 24px;
  height: 24px;

  &:hover,
  &:active {
    color: white;
    svg * {
      fill: white;
    }
  }
`

export const CloseButton = (props: CloseButtonProps): JSX.Element => {
  return (
    <StyledCloseIcon
      data-testid={TestIds.CLOSE_MODAL}
      variant={IconButtonVariant.icon}
      type="button"
      margin={props.margin}
      {...props}
    >
      <CloseIcon />
    </StyledCloseIcon>
  )
}
