import styled from 'styled-components/macro'
import {
  border,
  color,
  compose,
  margin,
  padding,
  space,
  textStyle,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const TypeSelectorContainer = styled.div.attrs<{ isDragOver?: boolean }>(
  {
    p: 'l',
    border: '1px solid',
    borderColor: 'border.neutral.light',
    bg: 'white',
    color: 'text.low',
    textStyle: 'form.disabled',
    role: 'listbox',
  },
)`
  ${compose(space, border, color, textStyle)}
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 240px;
  line-height: 24px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
  }

  ${({ isDragOver }: { isDragOver?: boolean }) =>
    isDragOver ? `border: 2px dashed black !important;` : undefined};

  span:first-child {
    margin-bottom: ${themeGet('space.s')}px;
  }
`

export const TypeRow = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`

export const TypeButtonBase = styled.button.attrs({
  mr: 'l',
  p: 0,
  border: '1px solid',
  borderColor: 'border.neutral.light',
  borderRadius: '2px',
  type: 'button',
  role: 'button',
  tabIndex: 0,
})`
  ${compose(padding, border, color, textStyle)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  appearance: none !important;
  background-color: white;
  color: inherit;

  &:not(:last-child) {
    ${margin}
  }

  :hover {
    background-color: ${(props) => props.theme.colors?.bg?.neutralHover};
    border-color: ${(props) => props.theme.colors?.border?.neutral?.base};
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }
`

export const TypeButton = styled(TypeButtonBase)`
  > svg path {
    fill: ${themeGet('colors.text.icon.low')};
  }
`

export const StyledTypeButton = styled(TypeButton)`
  margin: 0;
  cursor: pointer;
`
