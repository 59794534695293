import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  Department,
  Departments,
} from '@trustero/trustero-api-web/lib/model/department_pb'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'

export const useDepartments = (): Department[] | undefined => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listDepartments,
    new Empty(),
  )
  if (response.data) {
    return response.data?.getItemsList()
  } else if (response.error) {
    return []
  } else {
    return undefined
  }
}

/**
 * Fork of above that returns a GrpcResponse object instead of an object
 * Leveraged to use new isLoading check pattern in components
 *
 * @returns
 */
export const useSwrDepartments = (): GrpcResponse<Departments> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listDepartments,
    new Empty(),
  )
  return NewGrpcResponse(response)
}
