import React from 'react'
import { DraftDecoratorComponentProps } from '../DecoratorType'

export function LinkEntity({
  contentState,
  entityKey,
  children,
}: DraftDecoratorComponentProps): JSX.Element | void {
  if (entityKey) {
    const { href } = contentState.getEntity(entityKey).getData()
    return <a href={href}>{children}</a>
  }
}
