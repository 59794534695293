export const toggle = (nodesRef, setIsToggled) => (e) => {
  if (
    e.key === 'ArrowDown' &&
    document.activeElement.id === e.currentTarget.id
  ) {
    nodesRef.current[0].focus()
  } else if (
    e.key === 'ArrowUp' &&
    document.activeElement.id === e.currentTarget.id
  ) {
    nodesRef.current[nodesRef.current.length - 1].focus()
  } else if (
    e.key === 'Escape' &&
    document.activeElement.id === e.currentTarget.id
  ) {
    e.currentTarget.blur()
    setIsToggled(false)
  }
}

export const item = (idx, nodesRef, setIsToggled) => (e) => {
  if (
    e.key === 'ArrowDown' &&
    document.activeElement.id === e.currentTarget.id
  ) {
    const nextSibling = nodesRef.current[(idx + 1) % nodesRef.current.length]
    nextSibling.focus()
  } else if (
    e.key === 'ArrowUp' &&
    document.activeElement.id === e.currentTarget.id
  ) {
    nodesRef.current[
      (idx - 1 + nodesRef.current.length) % nodesRef.current.length
    ].focus()
  } else if (
    e.key === 'Escape' &&
    document.activeElement.id === e.currentTarget.id
  ) {
    e.currentTarget.blur()
    setIsToggled(false)
  }
}
