import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router'
import {
  AuthActionType,
  JwtNtrcePayload,
  tokenDecodeToMinutes,
  useAuthContext,
} from 'src/context/authContext'

export const useAttemptEmailLogin = ({
  token,
  redirectUrl,
}: {
  token: string
  redirectUrl: string
}): void => {
  const navigate = useNavigate()
  const { authDispatch } = useAuthContext()

  if (token.length) {
    const tokenMinutes = tokenDecodeToMinutes(token, false)

    if (tokenMinutes.expiration > 0) {
      const { aid: accountId, sub: email } = jwtDecode<JwtNtrcePayload>(token)
      if (!email || !accountId) return // do an error

      const login = ({
        accessToken,
        accountId,
        email,
        redirectUrl,
        useCase,
        role,
      }: {
        accessToken: string
        accountId: string
        email: string
        redirectUrl: string
        useCase: USE_CASE
        role: USER_ROLE
      }) => {
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              authDispatch({
                type: AuthActionType.LOGIN,
                accessToken,
                accountId,
                email,
                useCase,
                role,
              }),
            )
          })
        }).then(() => {
          navigate(redirectUrl)
        })
      }

      login({
        accessToken: token,
        accountId,
        email,
        redirectUrl,
        useCase: USE_CASE.SAAS_BUYER,
        role: USER_ROLE.ADMIN,
      })
    }
  }
}
