import styled from 'styled-components/macro'
import { StyledInputError } from '../../Inputs/TextInput/styles'

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  overflow: visible;
`
export const StyledNameInputContainer = styled(StyledInputContainer)`
  width: 100%;
`

export const ShowPageTitleError = styled(StyledInputError)`
  position: absolute;
  bottom: -18px;
  right: 0;
  z-index: 1;
  overflow: visible;
  font-size: 14px;
`

export const StyledEditRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  overflow: visible;
`
