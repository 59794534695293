import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { generatePermalink } from '../../../components/PageLayout/Permalink'
import { SubjectContainer } from './Subject.styles'

type Props = {
  modelId: string
  name: string
  modelType: MODEL_TYPE
  unlinked?: boolean
}

export const LinkContent = ({
  modelId,
  name,
  modelType,
  unlinked,
}: Props): JSX.Element => {
  const { pageContext } = useParams()
  const showLink = pageContext && !unlinked
  const isControl = modelType === MODEL_TYPE.CONTROL
  const displayName = isControl ? `${modelId} ${name}` : name

  return (
    <SubjectContainer>
      <span>{`\u2022`}</span>
      {showLink ? (
        <Link
          to={generatePermalink({
            pageContext: pageContext,
            modelId,
            modelType,
            isInternalLink: true,
          })}
        >
          {displayName}
        </Link>
      ) : (
        <>{displayName}</>
      )}
    </SubjectContainer>
  )
}
