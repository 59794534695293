import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { NotCoveredButtonContainer } from './NotCoveredModal.styles'

export const NotCoveredButton = ({
  onHide,
  route,
  text,
}: {
  onHide: () => void
  route: string
  text: string
}): JSX.Element => {
  const { pageContext } = useParams()
  const navigate = useNavigate()
  return (
    <NotCoveredButtonContainer>
      <StandardButton
        buttonSize={StandardButtonSize.MEDIUM}
        variant={StandardButtonVariant.PRIMARY}
        onClick={(e) => {
          e.preventDefault()
          onHide()
          navigate(`/${pageContext}/${route}`)
        }}
      >
        {text}
      </StandardButton>
    </NotCoveredButtonContainer>
  )
}
