import React, { useCallback, useContext } from 'react'
import log from 'loglevel'
import { Dropdown } from 'react-bootstrap'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { generatePermalink } from 'src/components/PageLayout/Permalink'
import { useParams } from 'react-router-dom'
import { Changelog } from 'src/InfoPanel/Changelog'
import { InfoPanelContext } from 'src/InfoPanel'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { E2E_IDS } from 'src/Utils/testIds'
import { useCurrentControl } from 'src/context/FormContext'
import {
  useUnlinkVendorRiskModal,
  useVendor,
} from 'src/pages/Vendors/vendors.hooks'
import { ReactComponent as VerticalEllipsesIcon } from '../../../components/Icons/assets/vertical-ellipses-icon.svg'
import { DropdownToggleButton } from '../../../components/Reusable/Buttons'
import {
  useDeleteRiskModal,
  useUnlinkControlFromRiskModal,
} from '../modals/RiskModals.hooks'

// TODO: Can consolidate with CopyLink in Controls
const CopyLink = ({ riskId }: { riskId: string }) => {
  const params = useParams()

  const onCopyLink = useCallback(
    async (e: React.MouseEvent) => {
      try {
        e.preventDefault()
        await navigator.clipboard.writeText(
          generatePermalink({
            modelType: MODEL_TYPE.RISK,
            pageContext: params.pageContext as string,
            modelId: riskId,
            isInternalLink: false,
          }),
        )
      } catch (err) {
        log.error('Failed to copy link to clipboard', err)
      }
    },
    [riskId, params.pageContext],
  )

  return (
    <Dropdown.Item eventKey="1" as="button" onClick={onCopyLink}>
      Copy Permalink
    </Dropdown.Item>
  )
}

const DeleteRisk = ({ risk }: { risk: Risk }): JSX.Element => {
  const showModal = useDeleteRiskModal(risk)

  return (
    <Dropdown.Item
      eventKey="2"
      as="button"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault()
        showModal()
      }}
    >
      Delete Risk
    </Dropdown.Item>
  )
}

const ViewActivities = ({ riskId }: { riskId: string }): JSX.Element => {
  const { setInfoPanelState } = useContext(InfoPanelContext)

  const onViewActivities = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setInfoPanelState({
        isShown: true,
        render: <Changelog subjectIds={[riskId]} />,
      })
    },
    [riskId, setInfoPanelState],
  )

  return (
    <Dropdown.Item eventKey="3" as="button" onClick={onViewActivities}>
      View Activities
    </Dropdown.Item>
  )
}

export const RiskRowMenu = ({ risk }: { risk: Risk }): JSX.Element => {
  const { controlModelId } = useParams()
  const { vendorId } = useParams()
  const isControlPage = controlModelId && controlModelId.length
  const isVendorPage = vendorId && vendorId.length

  return isControlPage ? (
    <ControlRiskRowMenu riskId={risk.getId()} riskName={risk.getName()} />
  ) : isVendorPage ? (
    <RiskVendorRowMenu risk={risk} vendorId={vendorId} />
  ) : (
    <RiskPageRiskRowMenu risk={risk} />
  )
}

const RiskPageRiskRowMenu = ({ risk }: { risk: Risk }): JSX.Element => {
  const riskId = risk.getId()

  return (
    <Dropdown
      id={E2E_IDS.RISK_ROW_MENU(riskId)}
      data-testid={E2E_IDS.RISK_ROW_MENU(riskId)}
    >
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <CopyLink riskId={riskId} />
        <DeleteRisk risk={risk} />
        <ViewActivities riskId={riskId} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const RiskControlRowMenu = ({
  riskId,
  controlId,
  name,
  isRiskItem,
}: {
  riskId: string
  controlId: string
  name: string
  isRiskItem?: boolean
}): JSX.Element => {
  const unlinkControl = useUnlinkControlFromRiskModal({
    controlId,
    riskId,
    name,
  })

  const text = isRiskItem ? 'Unlink Risk' : 'Unlink Control'

  return (
    <Dropdown
      id={E2E_IDS.RISK_CONTROL_ROW_MENU(riskId)}
      data-testid={E2E_IDS.RISK_CONTROL_ROW_MENU(riskId)}
    >
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={unlinkControl}>
          <span>{text}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const RiskVendorRowMenu = ({
  risk,
  vendorId,
}: {
  risk: Risk
  vendorId: string
}): JSX.Element => {
  const { data, isLoading, error } = useVendor(vendorId)
  const unlinkRisk = useUnlinkVendorRiskModal(
    data?.getId() || '',
    data?.getName() || '',
    risk.getId(),
    risk.getCustomId(),
    risk.getName(),
    MODEL_TYPE.VENDOR,
  )

  if (!data || isLoading) {
    return <></>
  } else if (error) {
    log.error(`Error loading vendor id ${vendorId}:`, error)
    return <></>
  }

  return (
    <Dropdown
      id={E2E_IDS.RISK_VENDOR_ROW_MENU(risk.getId())}
      data-testid={E2E_IDS.RISK_VENDOR_ROW_MENU(risk.getId())}
    >
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={unlinkRisk}>Unlink Risk</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const ControlRiskRowMenu = ({
  riskId,
  riskName,
}: {
  riskId: string
  riskName: string
}): JSX.Element => {
  const { currentControl: control } = useCurrentControl()

  return (
    <RiskControlRowMenu
      riskId={riskId}
      controlId={control.id}
      name={riskName}
      isRiskItem
    />
  )
}
