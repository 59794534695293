import React, { useEffect, useState } from 'react'
import { Key, SWRResponse } from 'swr'
import log from 'loglevel'
import { BaseProps, OnResponse } from './types'

const logger = log.getLogger('async')

type Props<Response, ElementProps> = BaseProps<
  ElementProps,
  OnResponse<ElementProps, Response>
> &
  SWRResponse<Response, Error> & {
    cacheKey: Key
  }

export function AsyncComponentResponse<Response, ElementProps>(
  props: Props<Response, ElementProps>,
): JSX.Element {
  const [validationInProgress, setValidationInProgress] =
    useState<boolean>(false)
  useEffect(() => {
    if (props.isValidating && !validationInProgress) {
      setValidationInProgress(true)
      props.onValidationStarted && props.onValidationStarted()
      return
    }
    if (!props.isValidating && validationInProgress) {
      setValidationInProgress(true)
      props.onValidationCompleted && props.onValidationCompleted()
      return
    }
  }, [props, validationInProgress])

  if (props.error) {
    logger.trace('AsyncComponentResponse error=', props.error, props.onError)
    if (props.onError) {
      return props.onError({ error: props.error })
    }
    logger.error('AsyncComponentResponse error=', props.error)
    return <></>
  }

  if (props.data) {
    return props.child({
      response: props.data,
      props: props.props,
      mutate: props.mutate,
    })
  }
  return props.placeholder ?? <></>
}
