import React from 'react'
import { AttestationStatusLabels } from 'src/pages/Vendors/vendors.constants'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

export const AttestationFilterDropdown = (): JSX.Element => {
  const filterValues = Object.values(AttestationStatusLabels).map(
    (label: AttestationStatusLabels) => ({
      value: label,
      label: label,
    }),
  )

  return (
    <FilterDropdown
      filterParam={FilterParam.ATTESTATION}
      filterName={FilterName.ATTESTATION}
      filterValues={filterValues}
    />
  )
}
