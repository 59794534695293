import React from 'react'
import {
  ChangelogContainer,
  ChangelogHeader,
  ChangelogHeaderText,
  Logs,
} from './Changelog.styles'
import { ChangelogPage } from './ChangelogPage'
import { CHANGELOG_PAGE_SIZE } from './ChangelogPage.helpers'

export const Changelog = ({
  subjectmodelids,
  hideHeader,
  subjectIds,
  isDashboardWidget: isWide,
}: {
  subjectmodelids?: string[]
  hideHeader?: boolean
  subjectIds?: string[]
  isDashboardWidget?: boolean
}): JSX.Element => {
  return (
    <ChangelogContainer $isWide={isWide}>
      {!hideHeader && (
        <ChangelogHeader>
          <ChangelogHeaderText>Activities</ChangelogHeaderText>
        </ChangelogHeader>
      )}
      <Logs>
        <ChangelogPage
          itemsPerPage={CHANGELOG_PAGE_SIZE}
          subjectModelIds={subjectmodelids}
          subjectIds={subjectIds}
          isWide={isWide}
        />
      </Logs>
    </ChangelogContainer>
  )
}
