import React, { ChangeEvent, FormEvent, useRef, useState } from 'react'
import {
  useHideModal,
  useIsShowModal,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { DerivedTextInput } from 'src/components/Reusable/Inputs/TextInput'
import { SectionDivider } from 'src/pages/Controls/ControlsIndexPage/AddControl/AddCustom/AddCustomControlModal'
import { RISK_MATH } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { RpcError } from 'grpc-web'
import { getErrorMessage } from 'src/Utils/globalHelpers'
import { Spinner } from 'src/Throbber/Spinner'
import { FlexRow } from 'src/components/Reusable/Flex'
import { RISK_VALIDATORS } from '../risks.constants'
import { RisksMathRadioMenu } from '../menus/RisksMathRadioMenu'
import { useCreateOrUpdateRisks } from '../risks.hooks'
import { getAddRisksRequest } from '../risks.helpers'
import { RiskRadioFirstContainer } from '../risks.styles'

export const AddManualRiskModal = (): JSX.Element => {
  const createRisk = useCreateOrUpdateRisks()
  const show = useIsShowModal(ModalFormId.ADD_MANUAL_RISK)
  const back = useSetActiveModal(ModalFormId.ADD_RISK)
  // Form Field State Hooks
  const hasSubmitted = useRef<boolean>(false)
  const [name, setName] = useState<string>('') // Aliased as threat in UI
  const [customId, setCustomId] = useState<string>('')
  const [predisposingCondition, setPredisposingCondition] = useState<string>('')
  const [likelihood, setLikelihood] = useState<RISK_MATH>(RISK_MATH.NOT_SET)
  const [adverseImpact, setAdverseImpact] = useState<RISK_MATH>(
    RISK_MATH.NOT_SET,
  )
  const [customIdError, setCustomIdError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const reset = () => {
    setName('')
    setCustomId('')
    setPredisposingCondition('')
    setLikelihood(RISK_MATH.NOT_SET)
    setAdverseImpact(RISK_MATH.NOT_SET)
    setLoading(false)
    setCustomIdError('')
  }

  const hide = useHideModal({
    onHide: () => {
      hasSubmitted.current = false
      reset()
    },
    modalId: ModalFormId.ADD_MANUAL_RISK,
  })

  const saveRisk = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    if (!customId) {
      setCustomIdError('Please enter an ID for this risk')
      return
    }
    setLoading(true)
    let resError: RpcError | undefined
    try {
      e.preventDefault()
      hasSubmitted.current = true
      const req = getAddRisksRequest(
        customId,
        name,
        predisposingCondition,
        likelihood,
        adverseImpact,
      )
      await createRisk([req])
      hide()
    } catch (e) {
      resError = e as RpcError
      const errorMessage = getErrorMessage(resError)
      errorMessage && setCustomIdError(errorMessage.message)
    } finally {
      setLoading(false)
      !resError && hide()
    }
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      onBack={back}
      formId={ModalFormId.ADD_MANUAL_RISK}
      size="xl"
      title="Add a Risk Scenario"
      submitText="Add Risk"
    >
      <form id={ModalFormId.ADD_MANUAL_RISK} onSubmit={saveRisk}>
        {loading ? (
          <FlexRow>
            <Spinner color="primary" size="xxl" />
          </FlexRow>
        ) : (
          <>
            <fieldset>
              <DerivedTextInput
                required
                form={ModalFormId.ADD_MANUAL_RISK}
                label="Risk ID"
                name="modelId"
                placeholder="Coded ID to help you work with this data later"
                initVal={customId}
                isValid={
                  !customIdError &&
                  (!hasSubmitted.current ||
                    (hasSubmitted.current && !!customId))
                }
                maxInputLength={RISK_VALIDATORS.ID}
                errorMessage={customIdError}
                customOnChange={({
                  currentTarget,
                }: ChangeEvent<HTMLInputElement>) => {
                  setCustomId(currentTarget.value)
                }}
              />
            </fieldset>
            <fieldset>
              <DerivedTextInput
                required
                form={ModalFormId.ADD_MANUAL_RISK}
                label="Threat"
                name="threat"
                placeholder="Short name used to reference this risk"
                initVal={name}
                isValid={
                  (hasSubmitted.current && !!name) || !hasSubmitted.current
                }
                maxInputLength={RISK_VALIDATORS.NAME}
                errorMessage="Please name this risk"
                customOnChange={({
                  currentTarget,
                }: ChangeEvent<HTMLInputElement>) => {
                  setName(currentTarget.value)
                }}
              />
            </fieldset>
            <SectionDivider />
            <fieldset>
              <DerivedTextInput
                form={ModalFormId.ADD_MANUAL_RISK}
                label="Predisposing Condition / Vulnerability"
                name="predisposingCondition"
                placeholder="The reason this threat is relevant"
                initVal={predisposingCondition}
                customOnChange={({
                  currentTarget,
                }: ChangeEvent<HTMLInputElement>) => {
                  setPredisposingCondition(currentTarget.value)
                }}
              />
            </fieldset>
            <fieldset>
              <RiskRadioFirstContainer>
                <RisksMathRadioMenu
                  labelText="Likelihood of Occurrence"
                  onSelectCb={(riskMath) => setLikelihood(riskMath)}
                />
              </RiskRadioFirstContainer>
            </fieldset>
            <fieldset>
              <RisksMathRadioMenu
                labelText="Adverse Impact"
                onSelectCb={(riskMath) => setAdverseImpact(riskMath)}
              />
            </fieldset>
          </>
        )}
      </form>
    </ModalForm>
  )
}
