import { MarkupSection } from 'src/components/Reusable/Text/Markup'
import styled from 'styled-components/macro'

export const StyledLinkerMarkup = styled(MarkupSection).attrs({
  className: 'linker-markup',
})`
  &.linker-markup.markup-section {
    padding: 0;
  }

  a {
    margin: 0;
  }
`
