import React, { useContext } from 'react'
import ContentContext, { Content } from './ContentContext'

export const useContentContext = (): {
  content: Content
  contentDispatch: React.Dispatch<Content>
} => {
  return useContext(ContentContext)
}

export const useContent = (): Content => {
  const { content } = useContext(ContentContext)
  return content
}
