import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  Department,
  GetDepartmentForComplianceFrameworkRequest,
} from '@trustero/trustero-api-web/lib/model/department_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useDepartmentForComplianceFramework = (
  id: string,
  complianceFrameworkId: string,
): GrpcResponse<Department> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getDepartmentForComplianceFramework,
    new GetDepartmentForComplianceFrameworkRequest()
      .setId(id)
      .setComplianceFrameworkId(complianceFrameworkId),
  )
  return NewGrpcResponse(response)
}
