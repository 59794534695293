import styled from 'styled-components/macro'
import { GridRow } from '../../Grid'

export const MultiSelectRow = styled(GridRow)`
  cursor: pointer;
`

export const SelectGridRow = styled(GridRow)`
  cursor: pointer;
`
