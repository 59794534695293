import React from 'react'
import styled from 'styled-components/macro'
import {
  IsGatekeeperEnabled,
  GatekeeperFlag,
} from '../../../../context/Gatekeeper'
import { Spinner } from '../../../../Throbber/Spinner'
import { GridColumnSortProps } from './GridColumnSort.constants'

const SortByIcon = styled.div`
  display: flex;
  width: 26px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 19px;
    width: 11px;
  }
`

export const GridColumnSort = ({
  colName,
  showSpinner,
  currIcon,
  applySorting,
}: GridColumnSortProps): JSX.Element | null => {
  // TODO: Remove once AP-4146 goes live
  if (!IsGatekeeperEnabled(GatekeeperFlag.GRID_COLUMN_SORT)) {
    return null
  }
  return (
    <SortByIcon
      onClick={applySorting}
      aria-label={`Sort by the ${colName} column`}
    >
      {showSpinner ? <Spinner size="xs" color="primary" mx="xxs" /> : currIcon}
    </SortByIcon>
  )
}
