import React, { SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import isFunction from 'lodash/isFunction'
import isBoolean from 'lodash/isBoolean'
import { LayoutProps, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../../Tooltip/Tooltip'
import {
  StyledInputError,
  StyledLabel,
  StyledLabelTitle,
  StyledTextInput,
} from './styles'

export type TextInputProps = LayoutProps &
  SpaceProps & {
    name: string
    initVal: string
    as?: 'textarea'
    required?: boolean
    form?: string
    label?: string
    placeholder?: string
    isValid?: ((value: string) => boolean) | boolean
    errorMessage?: string
    onChangeCb?: (value: string) => void
    isWarning?: boolean
    isDisabledMessage?: string
    isDisabled?: boolean
  }

export const TextInput = ({
  as,
  required,
  name,
  initVal = '',
  placeholder,
  form,
  label,
  isValid = () => true,
  errorMessage,
  onChangeCb,
  isWarning,
  isDisabledMessage,
  isDisabled,
  ...styleProps
}: TextInputProps): JSX.Element => {
  const [value, setValue] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    if (!initVal) return
    setValue(initVal)
  }, [initVal])

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const val = e.target.value
    setValue(val)
    onChangeCb?.(val)
    setShowError(!isValid)
    if (required && !val) setShowError(true)
  }

  const inputProps = {
    as,
    required,
    name,
    value,
    placeholder,
    onChange,
    showError,
    disabled: isDisabled,
  }

  if (!label)
    return (
      <>
        <StyledTextInput {...inputProps} {...styleProps} />
        <StyledInputError showError={showError} isWarning={isWarning}>
          {errorMessage}
        </StyledInputError>
      </>
    )

  const showDisabledTooltip = isDisabled && isDisabledMessage

  return (
    <StyledLabel form={form} {...styleProps}>
      <StyledLabelTitle>
        <p>{label}</p>
        <>
          {showDisabledTooltip && (
            <Tooltip
              id={`text-input-tooltip-${name}`}
              tooltipBody={isDisabledMessage}
              overlayType={TooltipOverlayType.tooltip}
              placement={TooltipPositions.top}
            >
              <InfoButton />
            </Tooltip>
          )}
        </>
      </StyledLabelTitle>
      <StyledTextInput {...inputProps} />
      <StyledInputError showError={showError}>{errorMessage}</StyledInputError>
    </StyledLabel>
  )
}

type DerivedTextInputProps = TextInputProps & {
  setFormData?: React.Dispatch<SetStateAction<{ [key: string]: string }>>
  customOnChange?: React.ChangeEventHandler
  maxInputLength?: number
}

export const DerivedTextInput = ({
  as,
  setFormData,
  required,
  name,
  initVal = '',
  placeholder,
  form,
  label,
  isValid,
  customOnChange,
  errorMessage,
  maxInputLength,
  ...styleProps
}: DerivedTextInputProps): JSX.Element => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (isBoolean(isValid)) setShowError(!isValid)
  }, [isValid])

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const field = e.target.name
    const newVal = e.target.value
    isFunction(setFormData) &&
      setFormData((state: { [key: string]: string }) => ({
        ...state,
        [field]: newVal,
      }))
    if (isFunction(isValid)) setShowError(!isValid(newVal))
    else if (isBoolean(isValid)) setShowError(!isValid)
  }

  const onKeyDown: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement
    if (e.key === 'Enter' && isFunction(isValid) && !isValid(target.value)) {
      setShowError(true)
    }
  }

  const inputProps = {
    as,
    required,
    name,
    value: initVal,
    placeholder,
    onKeyDown,
    onChange: isFunction(customOnChange) ? customOnChange : onChange,
    showError,
  }

  if (!label) {
    return (
      <>
        <StyledTextInput
          {...{ ...inputProps, ...styleProps }}
          maxLength={maxInputLength}
        />
        <StyledInputError showError={showError}>
          {errorMessage}
        </StyledInputError>
      </>
    )
  }

  return (
    <>
      <StyledLabel form={form} {...styleProps}>
        <p>{label}</p>
        <StyledTextInput {...inputProps} maxLength={maxInputLength} />
        <StyledInputError showError={showError}>
          {errorMessage}
        </StyledInputError>
      </StyledLabel>
    </>
  )
}

export const DerivedTextArea = styled(DerivedTextInput).attrs({
  forwardedAs: 'textarea',
})`
  &[class^='StyledTextInput'],
  > *[class^='StyledTextInput'] {
    display: block;
    resize: none;
    height: 240px;
    padding: ${themeGet('space.s')}px;
  }
`

export const DerivedResizableTextArea = styled(DerivedTextInput).attrs({
  forwardedAs: 'textarea',
})`
  &[class^='StyledTextInput'],
  > *[class^='StyledTextInput'] {
    display: block;
    resize: vertical;
    height: 62px;
    padding: ${themeGet('space.s')}px;
  }
`
