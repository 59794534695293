import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { ComplianceFrameworkOptions } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useComplianceFrameworkOptions =
  (): GrpcResponse<ComplianceFrameworkOptions> => {
    const { response } = useSwrImmutableGrpc(
      AuditPromiseClient.prototype.getComplianceFrameworkOptions,
      new Empty(),
    )
    return NewGrpcResponse(response)
  }
