import React, { useContext } from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { getErrorMessage } from 'src/Utils/globalHelpers'
import {
  DeleteDocumentRequest,
  DeleteEvidenceGroupRequest,
  EVIDENCE_LINKAGE_TYPE,
  EvidenceGroupId,
  EvidenceLinkage,
  UnlinkEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import {
  PinkInfoBox,
  PinkInfoBoxBodyColumn,
  PinkInfoBoxHeader,
} from 'src/components/Reusable/Text/PinkInfoBox.styles'
import styled from 'styled-components/macro'
import { FlexAlign, FlexColumn } from 'src/components/Reusable/Flex'
import { useInvalidateEvidenceGroups } from 'src/pages/Evidence/evidence.hooks'
import isFunction from 'lodash/isFunction'
import {
  useBulkDeleteEvidenceGroupModal,
  useBulkDeleteEvidenceModal,
  useBulkUnlinkEvidenceModal,
} from 'src/pages/Controls/ControlsShowPage/EvidenceGrid/evidence.hooks'
import { RpcError } from 'grpc-web'
import log from 'loglevel'
import { useConfirmationModal } from '../useConfirmationModal'
import { ConfirmationContext } from '../../../Confirmation'
import { useAuthorizedGrpcClient } from '../../../adapter'
import palette from '../../../designSystem/variables/palette'
import { useHardEvidenceInvalidation } from '../../../Utils/swrCacheInvalidation/useInvalidateEvidence'
import { DeleteEvidenceBody } from './evidence.styles'

const FromRequestBodyStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  p {
    font-size: 14px;
    color: ${palette.neutral[900]};
  }
`

const EvidenceStackButton = ({
  total,
  bulkAction,
  isUnlink,
}: {
  total: number
  bulkAction: () => void
  isUnlink?: boolean
}): JSX.Element => {
  const verb = isUnlink ? 'Unlink' : 'Delete'
  return (
    <TextButton onClick={bulkAction}>
      {`${verb} All ${total} Pieces of Evidence`}
    </TextButton>
  )
}

const UnlinkControlBody = ({ name }: { name?: string }): JSX.Element => {
  const controlName = name || 'the control'
  return (
    <div>
      <DeleteEvidenceBody>
        <p>{`This evidence will be removed from ${controlName}.`}</p>
      </DeleteEvidenceBody>
    </div>
  )
}

const UnlinkRequestBody = (): JSX.Element => (
  <div>
    <DeleteEvidenceBody>
      <p>
        This evidence will be removed from the request and controls linked to
        the request.
      </p>
    </DeleteEvidenceBody>
  </div>
)

const FromControlBody = ({
  bulkDeleteSection,
  caption,
  isUnlink,
}: {
  bulkDeleteSection: JSX.Element
  caption: string
  isUnlink?: boolean
}): JSX.Element => {
  return (
    <div>
      <DeleteEvidenceBody>
        {isUnlink && (
          <p>{`"${caption}" will be unlinked from this control.`}</p>
        )}
        {!isUnlink && <p>This cannot be undone.</p>}
        {bulkDeleteSection}
      </DeleteEvidenceBody>
    </div>
  )
}

const FromRequestBody = ({
  bulkDeleteSection,
  caption,
  isUnlink,
}: {
  bulkDeleteSection: JSX.Element
  caption: string
  isUnlink?: boolean
}): JSX.Element => (
  <FromRequestBodyStyledContainer>
    {isUnlink ? (
      <p>{`"${caption}" will be unlinked from this request and all controls linked to this request.`}</p>
    ) : (
      <>
        <p>The evidence will disappear for all linked controls.</p>
        <p>
          You should clean up bad data, but be careful not to delete evidence
          that your auditor needs to see.
          <br />
          This cannot be undone.
        </p>
      </>
    )}
    {bulkDeleteSection}
  </FromRequestBodyStyledContainer>
)

type useDeleteEvidenceTypes = {
  evidenceId: string
  caption: string
  controlId: string
  total?: number
  requestId?: string
}

// TODO: Delete when evidence index page is live in production
export const useDeleteEvidence = ({
  evidenceId,
  requestId,
  caption,
  controlId,
  total,
}: useDeleteEvidenceTypes): (() => void) => {
  const { setConfirmationState } = useContext(ConfirmationContext)
  const bulkDeleteEvidence = useBulkDeleteEvidenceModal({
    caption,
    controlId,
    totalEvidenceCount: total || 0,
  })

  const refetchEvidence = useHardEvidenceInvalidation()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const bulkDeleteSection =
    !!total && controlId && total > 1 ? (
      <PinkInfoBox>
        <PinkInfoBoxHeader>
          Delete All Evidence In This Stack?
        </PinkInfoBoxHeader>
        <PinkInfoBoxBodyColumn>
          <FlexColumn align={FlexAlign.FLEX_START} gap={8}>
            {`You are deleting one piece of evidence from a stack that samples the same data over time. Would you rather delete all ${total} pieces of evidence in this stack?`}
            <EvidenceStackButton
              total={total}
              bulkAction={bulkDeleteEvidence}
            />
          </FlexColumn>
        </PinkInfoBoxBodyColumn>
      </PinkInfoBox>
    ) : (
      <></>
    )

  const deleteDocument = async () => {
    try {
      const deleteDocumentRequest = new DeleteDocumentRequest()
        .setDocumentId(evidenceId)
        .setDeleteAssociatedTests(true)
      if (requestId) {
        deleteDocumentRequest.setRequestId(
          new StringValue().setValue(requestId),
        )
      }
      await attachmentClient.deleteDocument(deleteDocumentRequest)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setConfirmationState({
        isShown: true,
        size: 'lg',
        title: 'Error Deleting Evidence',
        body: <p>{err.message}</p>,
        confirmText: 'OK',
        hideCancel: true,
        onConfirmCB: () => null,
      })
    } finally {
      refetchEvidence()
    }
  }
  const confirmationModalProps = {
    title: 'Are you sure you want to delete the evidence?',
    body: requestId ? (
      <FromRequestBody
        bulkDeleteSection={bulkDeleteSection}
        caption={caption}
      />
    ) : (
      <FromControlBody
        bulkDeleteSection={bulkDeleteSection}
        caption={caption}
      />
    ),
    confirmText: 'Delete Evidence',
    onConfirmCB: deleteDocument,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useUnlinkEvidence = ({
  caption,
  contentId,
  linkageId,
  linkageType,
  discoveryId,
  total,
  isEvidence = false,
  linkageName,
  mutateFunc,
}: {
  caption: string
  contentId: string
  linkageId: string
  linkageType: EVIDENCE_LINKAGE_TYPE
  discoveryId?: string
  total?: number
  isEvidence?: boolean
  linkageName?: string
  mutateFunc?: () => Promise<void>
}): (() => void) => {
  const { setConfirmationState } = useContext(ConfirmationContext)
  const bulkUnlinkEvidence = useBulkUnlinkEvidenceModal({
    caption,
    linkageId,
    linkageType,
    totalEvidenceCount: total || 0,
  })
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const linkageTypeString =
    linkageType === EVIDENCE_LINKAGE_TYPE.CONTROL_LINK ? 'control' : 'request'

  const bulkUnlinkSection =
    !!total && total > 1 ? (
      <PinkInfoBox>
        <PinkInfoBoxHeader>
          Unlink All Evidence In This Stack?
        </PinkInfoBoxHeader>
        <PinkInfoBoxBodyColumn>
          <FlexColumn align={FlexAlign.FLEX_START} gap={8}>
            {`This evidence is part of a stack that samples the same data over time. Would you rather unlink all ${total} pieces of evidence from this ${linkageTypeString}?`}
            <EvidenceStackButton
              total={total}
              bulkAction={bulkUnlinkEvidence}
              isUnlink
            />
          </FlexColumn>
        </PinkInfoBoxBodyColumn>
      </PinkInfoBox>
    ) : (
      <></>
    )

  const unlinkEvidence = async () => {
    try {
      const evidenceId = new EvidenceGroupId()
        .setCaption(new StringValue().setValue(caption))
        .setContentId(new StringValue().setValue(contentId))
      if (discoveryId) {
        evidenceId.setDiscoveryId(new StringValue().setValue(discoveryId))
      }
      const unlinkEvidenceReq = new UnlinkEvidenceRequest()
        .setEvidenceId(evidenceId)
        .setLinkage(
          new EvidenceLinkage()
            .setLinkageId(linkageId)
            .setLinkageType(linkageType),
        )
      await attachmentClient.unlinkEvidence(unlinkEvidenceReq)
    } catch (err: unknown) {
      const resError = err as RpcError
      const errorMessage = getErrorMessage(resError)
      if (errorMessage.shouldLog) {
        log.error('Error in Unlink Evidence modal:', errorMessage.message)
      }
      setConfirmationState({
        isShown: true,
        size: 'lg',
        title: 'Error Unlinking Evidence',
        body: <p>{errorMessage.message}</p>,
        confirmText: 'OK',
        hideCancel: true,
        onConfirmCB: () => null,
      })
    } finally {
      isFunction(mutateFunc) && (await mutateFunc())
    }
  }
  const confirmationModalProps = {
    title: isEvidence
      ? 'Are you sure you want to unlink the evidence?'
      : linkageType === EVIDENCE_LINKAGE_TYPE.CONTROL_LINK
      ? 'Unlink control and remove evidence?'
      : linkageType === EVIDENCE_LINKAGE_TYPE.REQUEST_LINK
      ? 'Unlink request and remove evidence?'
      : 'Unlink evidence?',
    body: isEvidence ? (
      linkageType === EVIDENCE_LINKAGE_TYPE.REQUEST_LINK ? (
        <FromRequestBody
          bulkDeleteSection={bulkUnlinkSection}
          caption={caption}
          isUnlink
        />
      ) : (
        <FromControlBody
          bulkDeleteSection={bulkUnlinkSection}
          caption={caption}
          isUnlink
        />
      )
    ) : linkageType === EVIDENCE_LINKAGE_TYPE.CONTROL_LINK ? (
      <UnlinkControlBody name={linkageName} />
    ) : (
      <UnlinkRequestBody />
    ),
    confirmText: isEvidence
      ? 'Unlink Evidence'
      : linkageType === EVIDENCE_LINKAGE_TYPE.CONTROL_LINK
      ? 'Unlink Control'
      : linkageType === EVIDENCE_LINKAGE_TYPE.REQUEST_LINK
      ? 'Unlink Request'
      : 'Unlink Evidence',
    onConfirmCB: unlinkEvidence,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useDeleteEvidenceGroup = ({
  caption,
  contentId,
  discoveryId,
  total,
}: {
  caption: string
  contentId: string
  discoveryId: string
  total?: number
}): (() => void) => {
  const { setConfirmationState } = useContext(ConfirmationContext)
  const bulkDeleteEvidenceGroup = useBulkDeleteEvidenceGroupModal({
    caption,
    totalEvidenceCount: total || 0,
  })

  const refetchEvidence = useInvalidateEvidenceGroups()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const bulkDeleteSection =
    !!total && total > 1 ? (
      <PinkInfoBox>
        <PinkInfoBoxHeader>
          Delete All Evidence In This Stack?
        </PinkInfoBoxHeader>
        <PinkInfoBoxBodyColumn>
          <FlexColumn align={FlexAlign.FLEX_START} gap={8}>
            {`You are deleting one piece of evidence from a stack that samples the same data over time. Would you rather delete all ${total} pieces of evidence in this stack?`}
            <EvidenceStackButton
              total={total}
              bulkAction={bulkDeleteEvidenceGroup}
            />
          </FlexColumn>
        </PinkInfoBoxBodyColumn>
      </PinkInfoBox>
    ) : (
      <></>
    )

  const deleteEvidenceGroup = async () => {
    try {
      if (!contentId) {
        throw new Error('Content ID is required to delete a piece of evidence')
      }
      const groupId = new EvidenceGroupId()
        .setCaption(new StringValue().setValue(caption))
        .setContentId(new StringValue().setValue(contentId))
      discoveryId &&
        groupId.setDiscoveryId(new StringValue().setValue(discoveryId))
      const deleteEvidenceGroupRequest = new DeleteEvidenceGroupRequest().setId(
        groupId,
      )
      await attachmentClient.deleteEvidenceGroup(deleteEvidenceGroupRequest)
    } catch (err) {
      const resError = err as RpcError
      const errorMessage = getErrorMessage(resError)
      if (errorMessage.shouldLog) {
        log.error('Error in AddCustomFrameworkModal:', errorMessage.message)
      }
      setConfirmationState({
        isShown: true,
        size: 'lg',
        title: 'Error Deleting Evidence',
        body: <p>{errorMessage.message}</p>,
        confirmText: 'OK',
        hideCancel: true,
        onConfirmCB: () => null,
      })
    } finally {
      refetchEvidence()
    }
  }
  const confirmationModalProps = {
    title: 'Are you sure you want to delete the evidence?',
    body: (
      <>
        <p>{`"${caption}" will be deleted. This cannot be undone.`}</p>
        {bulkDeleteSection}
      </>
    ),
    confirmText: 'Delete Evidence',
    onConfirmCB: deleteEvidenceGroup,
  }

  return useConfirmationModal(confirmationModalProps)
}
