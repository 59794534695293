import React from 'react'
import log from 'loglevel'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  CONTROL_STATUS,
  ControlStatusValue,
  CreateUpdateControlRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useInvalidateControlsCache } from 'src/components/async/model/control'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useCurrentControl } from 'src/context/FormContext'
import { ModalFormId } from 'src/components/ModalForms'
import {
  MODEL_TYPE,
  RESPONSIBILITY,
  ResponsibilityValue,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { ResponsibilityForm } from 'src/components/Reusable/Forms/ResponsibilityForm'
import { ERROR_MARKING_CONTROL_NA } from './ControlModals.constants'

export const ControlResponsibilityModal = (): JSX.Element => {
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const mutate = useInvalidateControlsCache()

  const { currentControl, updatedResponsibility } = useCurrentControl()

  const updateControlResponsibility = async (
    responsibility: RESPONSIBILITY,
    naReason: string,
    selectedVendor: VendorRecord | null,
  ) => {
    if (!currentControl.id) {
      throw new Error('No control id provided')
    }
    const isControlNotApplicableStatus =
      responsibility === RESPONSIBILITY.NOT_APPLICABLE ||
      responsibility === RESPONSIBILITY.OUTSOURCED_ATTESTATION ||
      responsibility === RESPONSIBILITY.INHERITED
    try {
      const req = new CreateUpdateControlRequest()
        .setId(currentControl.id)
        .setResponsibility(
          new ResponsibilityValue().setValue(Number(responsibility)),
        )
        .setNotApplicableReason(new StringValue().setValue(naReason))

      if (isControlNotApplicableStatus) {
        req.setStatus(
          new ControlStatusValue().setValue(CONTROL_STATUS.NOTAPPLICABLE),
        )
      } else {
        req.setStatus(
          new ControlStatusValue().setValue(CONTROL_STATUS.NEEDSATTENTION),
        )
      }

      if (selectedVendor) {
        req.setVendorId(new StringValue().setValue(selectedVendor.getId()))
      }
      await modelClient.createOrUpdateControl(req)
      await mutate()
    } catch (error) {
      showInfoToast(ERROR_MARKING_CONTROL_NA)
      log.error(
        `Error marking control with id ${currentControl.id} not applicable`,
        error,
      )
    }
  }

  return (
    <ResponsibilityForm
      id={currentControl.id}
      modelType={MODEL_TYPE.CONTROL}
      modalId={ModalFormId.MARK_CONTROL_NA}
      currentResponsibility={currentControl.responsibility}
      updatedResponsibility={updatedResponsibility}
      onUpdate={updateControlResponsibility}
    />
  )
}
