import React from 'react'
import { GridColumn, GridRow } from '../../../../Reusable/Grid'
import { CheckBoxInput } from '../../../../Reusable/Inputs/CheckBox'
import { ReceptorLogo } from '../../../../async/model/receptor'

type Props = {
  receptorModelId: string
  evidenceId: string
  checked: boolean
  onChange: () => void
}

export function Row(props: Props): JSX.Element {
  return (
    <GridRow>
      <GridColumn>
        <CheckBoxInput
          id={`${props.evidenceId}-input`}
          value={props.evidenceId}
          checked={props.checked}
          onChange={props.onChange}
        />
      </GridColumn>
      <GridColumn>
        <ReceptorLogo
          receptorModelId={props.receptorModelId}
          width={'24px'}
          height={'24px'}
        />
      </GridColumn>
      <GridColumn>{props.evidenceId}</GridColumn>
    </GridRow>
  )
}
