import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'

export const isNullOrEmtpy = (str?: string): boolean => (str?.length ?? 0) === 0

export function stringValue(str?: string): StringValue | undefined {
  return str ? new StringValue().setValue(str) : undefined
}

export function boolValue(p?: boolean): BoolValue | undefined {
  return p !== undefined ? new BoolValue().setValue(p) : p
}
