import React, { useEffect, useState } from 'react'
import { EmptyStateTextOnly } from 'src/components/Reusable/Text/EmptyStateTextOnly'
import { NoFilterResults } from 'src/components/PageLayout/NoFilterResults'
import { PoliciesAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { EmptyStateTextOnlySize } from 'src/components/Reusable/Text/EmptyStateTextOnly.constants'
import { ControlsListItem } from '../../IndexItem'
import { ControlGridRowsProps } from './ControlsIndexPage.constants'

export const getControlsGridEmptyState = (): JSX.Element => {
  const isPoliciesPage = location.pathname.includes(PoliciesAbsoluteRoutes.SHOW)
  // dodging a circular dependency here. need to clean it up and use a const
  const isRequestsPage = location.pathname.includes('requests/show')

  if (isPoliciesPage) {
    return (
      <EmptyStateTextOnly
        size={EmptyStateTextOnlySize.SMALL}
        text="No controls support this policy. Set this per control."
      />
    )
  } else if (isRequestsPage) {
    return (
      <EmptyStateTextOnly
        size={EmptyStateTextOnlySize.SMALL}
        text="No controls linked to this request. Try linking one. You can unlink it easily."
      />
    )
  } else {
    return <NoFilterResults />
  }
}

const BATCH_SIZE = 15 // Number of items to load in each batch
const LOAD_INTERVAL = 150

export const ControlGridRows = ({
  modelIds,
  audit,
  config,
}: ControlGridRowsProps): JSX.Element => {
  const [visibleItems, setVisibleItems] = useState<number>(BATCH_SIZE)

  useEffect(() => {
    if (visibleItems < modelIds.length) {
      const timer = setInterval(() => {
        setVisibleItems((prev) => Math.min(prev + BATCH_SIZE, modelIds.length))
      }, LOAD_INTERVAL)

      return () => clearInterval(timer) // Clear the timer on unmount or when all items are loaded
    }
  }, [visibleItems, modelIds.length])

  return (
    <>
      {modelIds.slice(0, visibleItems).map((id) => (
        <ControlsListItem
          key={id}
          controlModelId={id}
          audit={audit}
          config={config}
        />
      ))}
    </>
  )
}
