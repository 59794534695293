import React, { MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { SERVICE_ROLE } from '@trustero/trustero-api-web/lib/service/service_pb'
import { GridColumn, GridHeader, GridRow } from 'src/components/Reusable/Grid'
import { ReceptorLogo } from 'src/components/async/model/receptor'
import { Service } from 'src/xgenerated'
import { useReceptorTemplate } from 'src/components/async/model/receptor/useReceptor'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { Markup } from 'src/components/Reusable/Text/Markup'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { generatePermalink } from '../../../components/PageLayout/Permalink'
import {
  ServiceTitle,
  ServiceNameContainer,
  ServiceReceptorContainer,
  ServiceRolesContainer,
  ServicesDeleteButton,
  ServicesDeleteIcon,
  StyledServicesGridHeader,
  StyledServicesTabGridRow,
  ServicesTabText,
  ServicesExcludeIcon,
  ServicesRightAlignedColumn,
  ServicesIncludeIcon,
  StyledServicesGridRow,
  ServicesDeleteButtonBody,
  ServicesExcludeButtonBody,
} from '../services.styles'
import {
  SELECTED_ROLES_MAX,
  SERVICES_HEADERS,
  SERVICES_TAB_HEADERS,
  SERVICE_ROLE_TO_LABEL,
} from '../Services.constants'
import { ServiceRoleChip } from '../services.components'
import {
  useDismissServiceModal,
  useExcludeServiceModal,
  useIncludeServiceModal,
} from '../Services.hooks'

const ServicesRemoveButton = ({
  service,
}: {
  service: Service
}): JSX.Element => {
  const hasPermission = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  const removeService = useDismissServiceModal({
    service,
  })

  const onDismissService: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    removeService()
  }

  if (!hasPermission) {
    return <></>
  }

  return (
    <ServicesDeleteButton onClick={onDismissService}>
      <ServicesDeleteButtonBody>
        <ServicesDeleteIcon />
        Remove
      </ServicesDeleteButtonBody>
    </ServicesDeleteButton>
  )
}

const ServicesExcludeButton = ({
  service,
}: {
  service: Service
}): JSX.Element => {
  const hasPermission = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  const excludeService = useExcludeServiceModal({ service })
  const includeService = useIncludeServiceModal({ service })

  const onExcludeService: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    excludeService()
  }

  const onIncludeService: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault()
    includeService()
  }

  const isExcluded = service.is_excluded

  if (!hasPermission) {
    return <></>
  }

  return (
    <>
      {isExcluded ? (
        <ServicesDeleteButton onClick={onIncludeService}>
          <ServicesExcludeButtonBody>
            <ServicesIncludeIcon />
            Include
          </ServicesExcludeButtonBody>
        </ServicesDeleteButton>
      ) : (
        <ServicesDeleteButton onClick={onExcludeService}>
          <ServicesExcludeButtonBody>
            <ServicesExcludeIcon />
            Exclude
          </ServicesExcludeButtonBody>
        </ServicesDeleteButton>
      )}
    </>
  )
}

const ServicesGridRow = ({ service }: { service: Service }): JSX.Element => {
  const { pageContext } = useParams()
  const receptor = useReceptorTemplate({
    receptorModelId: service.receptor_id || '',
  })

  const id = service.id
  const name = service.name
  const serviceRoles = service.service_roles || []
  const hasMoreRolesThanMax = serviceRoles.length > SELECTED_ROLES_MAX
  const displayedRoles = hasMoreRolesThanMax
    ? serviceRoles.slice(0, SELECTED_ROLES_MAX)
    : serviceRoles
  const ServiceLogo = service.logo
  const permalink = generatePermalink({
    pageContext: pageContext as string,
    modelType: MODEL_TYPE.SERVICE,
    modelId: service.id,
    isInternalLink: true,
    includeSearchParams: true,
  })

  const isExcluded = service.is_excluded
  const isExcludeEnabled = IsGatekeeperEnabled(GatekeeperFlag.EXCLUDE_SERVICES)
  const showExcluded = isExcludeEnabled && isExcluded

  return (
    <StyledServicesGridRow
      id={`services-index-row-${id}`}
      as={showExcluded ? GridRow : Link}
      to={permalink}
      $isexcluded={showExcluded}
    >
      <GridColumn>
        <ServiceNameContainer>
          <ServiceLogo width="40px" height="40px" />
          {name}
        </ServiceNameContainer>
      </GridColumn>
      <GridColumn>
        {receptor && (
          <ServiceReceptorContainer>
            <ReceptorLogo
              receptorModelId={receptor.modelid}
              width={'24px'}
              height={'24px'}
            />
            {receptor.name}
          </ServiceReceptorContainer>
        )}
      </GridColumn>
      <GridColumn>
        <ServiceRolesContainer>
          {displayedRoles.map((role: SERVICE_ROLE) => (
            <Tooltip
              id={`services-index-service-role-tooltip-${service.id}-${role}`}
              placement={TooltipPositions.top}
              overlayType={TooltipOverlayType.tooltip}
              tooltipBody={
                <Markup rawHtml={SERVICE_ROLE_TO_LABEL[role] as string} />
              }
              key={`${service.id}-${role}`}
            >
              <div>
                <ServiceRoleChip role={SERVICE_ROLE_TO_LABEL[role]} />
              </div>
            </Tooltip>
          ))}
          {hasMoreRolesThanMax && (
            <ServiceRoleChip
              role={`+${serviceRoles.length - SELECTED_ROLES_MAX}`}
            />
          )}
        </ServiceRolesContainer>
      </GridColumn>
      <ServicesRightAlignedColumn>
        {!receptor ? (
          <ServicesRemoveButton service={service} />
        ) : (
          <>
            {isExcludeEnabled ? (
              <ServicesExcludeButton service={service} />
            ) : (
              <></>
            )}
          </>
        )}
      </ServicesRightAlignedColumn>
    </StyledServicesGridRow>
  )
}

export const ServicesGridRows = ({
  services,
}: {
  services: Service[]
}): JSX.Element => (
  <>
    {services.map((service: Service) => (
      <ServicesGridRow key={service.id} service={service} />
    ))}
  </>
)

export const ServicesGridHeader = (): JSX.Element => (
  <GridRow>
    {Object.values(SERVICES_HEADERS).map((header: string, idx: number) => (
      <StyledServicesGridHeader key={`${header}-${idx}`}>
        {header}
      </StyledServicesGridHeader>
    ))}
  </GridRow>
)

export const ServicesTabGridHeader = (): JSX.Element => (
  <GridRow>
    {Object.values(SERVICES_TAB_HEADERS).map((header: string) => (
      <GridHeader key={`${header}`}>{header}</GridHeader>
    ))}
  </GridRow>
)

export const ServicesTabGridRow = ({
  service,
}: {
  service: Service
}): JSX.Element => {
  const { pageContext } = useParams()
  const {
    logo: Logo,
    name,
    instance_count: instanceCount,
    dismissed,
    has_scopable_instances,
    can_be_inactive,
  } = service
  const permalink = generatePermalink({
    pageContext: pageContext as string,
    modelType: MODEL_TYPE.SERVICE,
    modelId: service.id,
    isInternalLink: true,
  })
  const hideInstanceCount = instanceCount === 0 && can_be_inactive
  const countDisplay = !has_scopable_instances
    ? 'N/A'
    : hideInstanceCount
    ? 'Service may be inactive'
    : instanceCount

  const isExcluded = service.is_excluded
  const isExcludeEnabled = IsGatekeeperEnabled(GatekeeperFlag.EXCLUDE_SERVICES)
  const showExcluded = isExcludeEnabled && isExcluded
  return (
    <StyledServicesTabGridRow
      as={showExcluded ? GridRow : Link}
      to={permalink}
      $isDismissed={dismissed}
      $possiblyInactive={
        has_scopable_instances && instanceCount === 0 && can_be_inactive
      }
      $isExcluded={showExcluded}
    >
      <GridColumn>
        <ServiceTitle>
          <Logo width="24px" height="24px" />
          <ServicesTabText>{name}</ServicesTabText>
        </ServiceTitle>
      </GridColumn>
      <GridColumn>
        <ServicesTabText>{countDisplay}</ServicesTabText>
      </GridColumn>
      <GridColumn>
        {isExcludeEnabled ? <ServicesExcludeButton service={service} /> : <></>}
      </GridColumn>
    </StyledServicesTabGridRow>
  )
}
