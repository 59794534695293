import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { StyledIcon } from '../../Icons/Basic'
import { RichStyleButton } from '../styles'

type StyleButtonProps = {
  isActive: boolean
  toggleFn: React.MouseEventHandler<HTMLButtonElement>
  icon: IconProp
}

export default function StyleButton({
  isActive,
  toggleFn,
  icon,
}: StyleButtonProps): JSX.Element {
  return (
    <RichStyleButton
      bg={isActive ? 'fill.tertiary.superlight' : 'fill.white'}
      onMouseDown={toggleFn}
    >
      <StyledIcon>
        <FontAwesomeIcon icon={icon} size={'xs'} />
      </StyledIcon>
    </RichStyleButton>
  )
}
