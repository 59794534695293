import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components/macro'
import palette from '../../../designSystem/variables/palette'

export const MultiSelectDropdownItem = styled.div`
  padding: 0;
  &:hover,
  &:focus {
    .form-check-input {
      border-color: ${themeGet('colors.fill.status.success.light')};
      box-shadow: 0 0 0 0.25rem ${palette.green['100']};
    }
  }
`

export const MultiSelectDropdownCheckBoxLabel = styled.label.attrs({
  className: 'form-check-label',
})`
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  cursor: pointer;
`

export const MultiSelectDropdownCheckBox = styled.input.attrs({
  type: 'checkbox',
  className: 'form-check-input',
})`
  min-width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 8px;
`

export const MultiSelectDropdownRadio = styled.input.attrs({
  type: 'radio',
  className: 'form-check-input',
})`
  min-width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 8px;
`
