import { useCallback } from 'react'
import { RemoveControlsRequest } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { GetPolicyControlsRequest } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { useAuthorizedGrpcClient } from '../../../adapter'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { usePolicy } from './usePolicy'

export const useRemovePolicyFromAudit = (
  policyId: string,
  auditId: string,
): (() => Promise<void>) => {
  const auditClient = useAuthorizedGrpcClient(AuditPromiseClient)
  const {
    response: { data: controls },
  } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.getControls,
    new GetPolicyControlsRequest().setId(policyId),
  )
  const { mutate } = usePolicy(policyId)

  return useCallback(async () => {
    const policyControlIds = controls?.getItemList() ?? []
    if (policyControlIds.length === 0 || !auditId) {
      return
    }
    const request = new RemoveControlsRequest()
      .setControlIdsList(policyControlIds)
      .setAuditId(auditId)
    await auditClient.removeControls(request)
    await mutate()
  }, [auditClient, auditId, controls, mutate])
}
