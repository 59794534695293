import React from 'react'
import { RESPONSIBILITY } from '@trustero/trustero-api-web/lib/common/model_pb'
import { usePolicy } from 'src/components/async/policy'
import { Tooltip } from '../Tooltip'
import { TooltipOverlayType, TooltipPositions } from '../Tooltip/Tooltip'
import { FlexAlign, FlexRow } from '../Flex'
import { PolicyErrorIcon, PolicyIcon, PolicyTitle } from './ListItem.styles'
import { LIST_ITEM_COPY } from './ListItem.constants'

export const PolicyInfo = ({
  policyName,
  isError,
  policyNaMismatch,
  isLarge,
}: {
  policyName: string
  isError: boolean
  policyNaMismatch?: boolean
  isLarge?: boolean
}): JSX.Element => {
  const icon = isError ? (
    <PolicyErrorIcon $isLarge={isLarge} />
  ) : (
    <PolicyIcon $isLarge={isLarge} />
  )

  return (
    <Tooltip
      id={`controls-index-row-policy-tooltip-${policyName}`}
      placement={TooltipPositions.top}
      overlayType={TooltipOverlayType.popover}
      tooltipBody={
        policyNaMismatch
          ? `This control's policy is marked not applicable, but the control is not.`
          : ''
      }
      disabled={!policyNaMismatch}
    >
      <FlexRow align={FlexAlign.CENTER} justify={FlexAlign.FLEX_START} gap={4}>
        {icon}
        <PolicyTitle $isError={isError} $isLarge={isLarge}>
          {policyName}
        </PolicyTitle>
      </FlexRow>
    </Tooltip>
  )
}

export const ControlPolicyInfo = ({
  policyModelId,
  isControlNa,
  isLarge,
}: {
  policyModelId: string
  isControlNa: boolean
  isLarge?: boolean
}): JSX.Element => {
  const { data, isLoading } = usePolicy(policyModelId || '')
  const isPolicyNa = data?.getResponsibility() === RESPONSIBILITY.NOT_APPLICABLE
  const policyNaMismatch = isPolicyNa && !isControlNa
  const policyName = policyNaMismatch
    ? LIST_ITEM_COPY.NOT_APPLICABLE_POLICY
    : data?.getName() || LIST_ITEM_COPY.NO_POLICY
  const isError = (!data && !isLoading) || policyNaMismatch

  return isLoading ? (
    <>-</>
  ) : (
    <PolicyInfo
      policyName={policyName}
      isError={isError}
      policyNaMismatch={policyNaMismatch}
      isLarge={isLarge}
    />
  )
}
