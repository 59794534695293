import { useCallback } from 'react'
import { AccountSettingsPromiseClient } from '@trustero/trustero-api-web/lib/accountsettings/accountsettings_grpc_web_pb'
import { ScheduleManagerPromiseClient } from '@trustero/trustero-api-web/lib/schedulemanager/schedulemanager_grpc_web_pb'
import { NotificationPromiseClient } from '@trustero/trustero-api-web/lib/notification/notification_grpc_web_pb'
import {
  CreateUpdateScheduleRequest,
  DeleteScheduleRequest,
  GetScheduleRequest,
  SCHEDULE_TYPE,
  ScheduleRecord,
} from '@trustero/trustero-api-web/lib/schedulemanager/schedulemanager_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { useGrpcRevalidateByMethod } from 'src/components'
import {
  DismissNotificationRequest,
  GetNotificationsRequest,
  GetNotificationsResponse,
  LatestNotificationRequest,
  LatestNotificationResponse,
  NotificationTypeFilter,
  NOTIFICATION_TYPE,
} from '@trustero/trustero-api-web/lib/notification/notification_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  GetDateLimitResponse,
  UpdateDateLimitRequest,
  UpdateValidateImageTimestampsRequest,
  ValidateImageTimestamps,
} from '@trustero/trustero-api-web/lib/accountsettings/accountsettings_pb'
import { useInvalidateAuditRunsCache } from '../AuditBot/AuditBot.hooks'

export const useInvalidateAccountSettings = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(AccountSettingsPromiseClient.prototype.getDateLimit),
      mutateFunc(ScheduleManagerPromiseClient.prototype.getSchedule),
      mutateFunc(
        AccountSettingsPromiseClient.prototype.getValidateImageTimestamps,
      ),
    ])
  }, [mutateFunc])
}

export const useInvalidateNotifications = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(NotificationPromiseClient.prototype.getNotifications),
      mutateFunc(NotificationPromiseClient.prototype.getLatestNotification),
    ])
  }, [mutateFunc])
}

export const useDateLimit = (): GrpcResponse<GetDateLimitResponse> => {
  const { response } = useSwrImmutableGrpc(
    AccountSettingsPromiseClient.prototype.getDateLimit,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

export const useEditDateLimit = (): ((dateLimit: Timestamp) => void) => {
  const client = useAuthorizedGrpcClient(AccountSettingsPromiseClient)
  const mutateAuditRuns = useInvalidateAuditRunsCache()
  const mutateDateLimit = useInvalidateAccountSettings()

  return async (dateLimit: Timestamp) => {
    const request = new UpdateDateLimitRequest().setDateLimit(dateLimit)
    await client.updateDateLimit(request)
    await mutateAuditRuns()
    await mutateDateLimit()
  }
}

export const useValidateImageTimestamp =
  (): GrpcResponse<ValidateImageTimestamps> => {
    const { response } = useSwrImmutableGrpc(
      AccountSettingsPromiseClient.prototype.getValidateImageTimestamps,
      new Empty(),
    )
    return NewGrpcResponse(response)
  }

export const useSetValidateImageTimestamp = (): ((toggle: boolean) => void) => {
  const client = useAuthorizedGrpcClient(AccountSettingsPromiseClient)
  const mutateAuditRuns = useInvalidateAuditRunsCache()
  const mutateValidateImageTimestamp = useInvalidateAccountSettings()

  return async (toggle: boolean) => {
    const request =
      new UpdateValidateImageTimestampsRequest().setValidateImageTimestamps(
        toggle,
      )
    await client.updateValidateImageTimestamps(request)
    await mutateAuditRuns()
    await mutateValidateImageTimestamp()
  }
}

export const useNotifications = (
  message?: string,
  types?: number[],
): GrpcResponse<GetNotificationsResponse> => {
  const request = new GetNotificationsRequest()
  message && request.setMessage(new StringValue().setValue(message))
  types &&
    request.setTypeFilter(new NotificationTypeFilter().setTypesList(types))
  const { response } = useSwrImmutableGrpc(
    NotificationPromiseClient.prototype.getNotifications,
    new GetNotificationsRequest(),
  )
  return NewGrpcResponse(response)
}

export const useLatestNotification = ({
  message,
  type,
}: {
  message: string
  type: NOTIFICATION_TYPE
}): GrpcResponse<LatestNotificationResponse> => {
  const { response } = useSwrImmutableGrpc(
    NotificationPromiseClient.prototype.getLatestNotification,
    new LatestNotificationRequest().setMessage(message).setType(type),
  )
  return NewGrpcResponse(response)
}

export const useDismissNotification = (): (({
  type,
  message,
}: {
  type: NOTIFICATION_TYPE
  message: string
}) => void) => {
  const client = useAuthorizedGrpcClient(NotificationPromiseClient)
  const mutate = useInvalidateNotifications()

  return async ({ type, message }) => {
    await client.dismissNotification(
      new DismissNotificationRequest().setMessage(message).setType(type),
    )
    await mutate()
  }
}

export const useSchedule = ({
  type,
}: {
  type: SCHEDULE_TYPE
}): GrpcResponse<ScheduleRecord> => {
  const { response } = useSwrImmutableGrpc(
    ScheduleManagerPromiseClient.prototype.getSchedule,
    new GetScheduleRequest().setScheduleType(type),
  )
  return NewGrpcResponse(response)
}

export const useCreateOrUpdateSchedule = (): (({
  scheduleType,
  frequency,
}: {
  scheduleType: SCHEDULE_TYPE
  frequency: number
}) => void) => {
  const client = useAuthorizedGrpcClient(ScheduleManagerPromiseClient)
  const mutate = useInvalidateAccountSettings()

  return async ({
    scheduleType,
    frequency,
  }: {
    scheduleType: SCHEDULE_TYPE
    frequency: number
  }) => {
    const request = new CreateUpdateScheduleRequest()
      .setScheduleType(scheduleType)
      .setFrequency(frequency)
    await client.createOrUpdateSchedule(request)
    await mutate()
  }
}

export const useDeleteSchedule = (): (({
  scheduleType,
}: {
  scheduleType: SCHEDULE_TYPE
}) => void) => {
  const client = useAuthorizedGrpcClient(ScheduleManagerPromiseClient)
  const mutate = useInvalidateAccountSettings()

  return async ({ scheduleType }: { scheduleType: SCHEDULE_TYPE }) => {
    const request = new DeleteScheduleRequest().setScheduleType(scheduleType)
    await client.deleteSchedule(request)
    await mutate()
  }
}
