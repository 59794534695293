import React from 'react'
import { getMimeTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import { getFileSize } from 'src/Utils/globalHelpers'
import { DropzoneInputProps } from 'react-dropzone'
import { Spinner } from 'src/Throbber/Spinner'
import isFunction from 'lodash/isFunction'
import {
  TypeButton,
  TypeRow,
} from 'src/components/ModalForms/FileType/TypeSelector.styles'
import { TestIds } from 'src/Utils/testIds'
import { ReactComponent as FileUploadIcon } from '../../../../components/Icons/assets/file-upload-icon.svg'
import { StyledInputError } from '../../Inputs/TextInput/styles'
import {
  FileDiv,
  FileName,
  FileSelectorText,
  FileSize,
  StyledFileClearButton,
} from './FileSelector.styles'

type FileSelectorBaseProps = {
  dropText: string
  getInputProps: () => DropzoneInputProps
}

type FileUploadAreaProps = FileSelectorBaseProps & {
  options: JSX.Element[]
}

type FileUploadDisplayProps = FileUploadAreaProps & {
  isLoading: boolean
  files?: File[]
}

export const UploadedFile = ({ file }: { file: File }): JSX.Element => {
  const size = getFileSize(file)
  const mime = file.type
  const Icon = getMimeTypeIcon(mime)

  return (
    <FileDiv>
      <Icon width="48px" height="48px" />
      <FileName>{file.name}</FileName>
      <FileSize>{`${size} KB`}</FileSize>
    </FileDiv>
  )
}

export const UploadedFiles = ({ files }: { files: File[] }): JSX.Element => {
  return (
    <>
      {files.map((file, i) => (
        <UploadedFile key={`uploaded-file-${i}`} file={file} />
      ))}
    </>
  )
}

export const FileUploadArea = ({
  dropText,
  getInputProps,
  options,
}: FileUploadAreaProps): JSX.Element => {
  if (options.length) {
    return (
      <>
        <span>Use one of the following.</span>
        <TypeRow>
          {options}
          <TypeButton>
            <FileSelectorButton
              dropText={dropText}
              getInputProps={getInputProps}
            />
          </TypeButton>
        </TypeRow>
      </>
    )
  }

  return (
    <FileSelectorButton dropText={dropText} getInputProps={getInputProps} />
  )
}

export const FileUploadLoading = (): JSX.Element => (
  <Spinner color="primary" size="xl" />
)

export const FileUploadDisplay = ({
  isLoading,
  files = [],
  dropText,
  getInputProps,
  options,
}: FileUploadDisplayProps): JSX.Element => {
  if (isLoading) {
    return <FileUploadLoading />
  }

  if (files.length) {
    return <UploadedFiles files={files} />
  }

  return (
    <FileUploadArea
      dropText={dropText}
      getInputProps={getInputProps}
      options={options}
    />
  )
}

export const FileClearButton = ({
  showClear,
  onClick,
}: {
  showClear: boolean
  onClick?: () => void
}): JSX.Element =>
  showClear ? (
    <StyledFileClearButton
      onClick={(e) => {
        e.preventDefault()
        isFunction(onClick) && onClick()
      }}
      showClear={showClear}
    >
      Clear
    </StyledFileClearButton>
  ) : (
    <></>
  )

export const FileSelectorError = ({
  errorMessage,
}: {
  errorMessage: string
}): JSX.Element => (
  <StyledInputError showError={!!errorMessage}>{errorMessage}</StyledInputError>
)

const FileSelectorButton = ({
  dropText,
  getInputProps,
}: FileSelectorBaseProps): JSX.Element => (
  <>
    <FileUploadIcon />
    <FileSelectorText>{dropText}</FileSelectorText>
    <input {...getInputProps()} data-testid={TestIds.FILE_INPUT} />
  </>
)
