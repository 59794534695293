import styled from 'styled-components/macro'
import {
  compose,
  grid,
  GridProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  border,
  color,
  ColorProps,
  textStyle,
  MarginProps,
  margin,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import 'draft-js/dist/Draft.css'

type EditorWrapperProps = GridProps & FlexboxProps & LayoutProps & ColorProps

export const EditorWrapper = styled.article.attrs<EditorWrapperProps>(
  (props) => {
    return {
      width: '100%',
      height: '100%',
      ...props,
      className: 'Rte-Wrapper',
      bg: 'fill.white',
    }
  },
)`
  ${compose(grid, flexbox, layout, color)}
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`

export const EditorHeader = styled.header.attrs({
  px: 'xl',
  py: 'm',
  borderBottom: '1px solid',
  borderColor: 'border.neutral.light',
})`
  ${compose(space, border)}
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 72px;
  margin: 0;
`

export const EditorTitle = styled.h2.attrs({
  m: 0,
  mr: 'auto',
  textStyle: 'header.2',
})<MarginProps>`
  ${compose(margin, textStyle)}
  width: 100%;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const EditorHeaderButtons = styled.div.attrs({
  ml: 'auto',
  mr: 'l',
})`
  ${space}
  display: flex;
`

const GenericContainer = styled.div`
  ${space}
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ToolbarContainer = styled(GenericContainer).attrs({
  borderBottom: '1px solid',
  borderColor: 'border.neutral.light',
})`
  ${border}
  height: 40px;
  min-height: 40px;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  max-width: 1200px;
`

export const ToolbarGroup = styled.div`
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;

  border-right: ${(props) =>
    `1px solid ${themeGet('colors.border.neutral.light')(props)}`};
  :first-child {
    border-left: ${(props) =>
      `1px solid ${themeGet('colors.border.neutral.light')(props)}`};
  }

  .dropdown-container {
    border: none;
    ul {
      #unstyled {
        font-family: Poppins;
        font-size: 14px;
        letter-spacing: -0.03;
      }

      #header-one {
        font-family: Poppins;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: -0.5;
      }

      #header-two,
      #header-three,
      #header-four,
      #header-five,
      #header-six {
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.4;
      }
    }
  }
`

type RichStyleButtonProps = SpaceProps & {
  color?: string
  bg?: string
}

export const RichStyleButton = styled.button.attrs<
  RichStyleButtonProps,
  RichStyleButtonProps
>({
  px: 's',
})`
  ${compose(space, color)}
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`

export const EditorContainer = styled(GenericContainer).attrs({
  my: 'xl',
})`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .DraftEditor-root {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    color: ${themeGet('colors.text.default')};

    .unstyled,
    ul,
    ol {
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: -0.03;
      margin: 0;
      margin-bottom: ${(props) => `${themeGet('space.xs')(props)}px`};
      padding: 0;
    }

    .header-one {
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.5;
      margin-bottom: ${(props) => `${themeGet('space.m')(props)}px`};
    }

    .header-two,
    .header-three,
    .header-four,
    .header-five,
    .header-six {
      font-family: Poppins;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.4;
      margin-bottom: ${(props) => `${themeGet('space.m')(props)}px`};
    }

    ul,
    ol {
      margin-top: ${(props) => `${themeGet('space.xs')(props)}px`};
      margin-bottom: ${(props) => `${themeGet('space.xs')(props)}px`};
      margin-left: ${(props) => `${themeGet('space.xs')(props)}px`};
    }

    a {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.03;
      text-decoration: underline;
    }
  }
`
