import React from 'react'
import { ControlsSVG } from 'src/components/Icons/Basic'
import { ModalDescription } from 'src/Modal/styles'
import {
  PinkInfoBox,
  PinkInfoBoxBody,
  PinkInfoBoxHeader,
} from 'src/components/Reusable/Text/PinkInfoBox.styles'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { P } from 'src/components/Reusable/Text/Text.styles'
import palette from 'src/designSystem/variables/palette'

export const AdditionalControlDetails = ({
  isEmptyAccount,
}: {
  isEmptyAccount: boolean
}): JSX.Element =>
  isEmptyAccount ? (
    <></>
  ) : (
    <>
      <span>Upload a CSV file of all your controls. The system will</span>
      <ul>
        <li>match your controls to existing ones if they are similar</li>
        <li>create new ones where needed and</li>
        <li>remove any default controls that aren’t in your upload</li>
      </ul>
      <PinkInfoBox>
        <PinkInfoBoxHeader>
          <ControlsSVG width="16px" height="16px" />
          This could delete your data
        </PinkInfoBoxHeader>
        <PinkInfoBoxBody>
          This will remove some controls currently in your account. If
          you&apos;re just starting out, that&apos;s great! If you&apos;re
          further along, be cautious! Evidence and tests on any control that
          isn&apos;t in your upload will be lost. Contact support if
          there&apos;s a serious problem.
        </PinkInfoBoxBody>
      </PinkInfoBox>
    </>
  )

export const ControlResponsibilityDescription = ({
  isNaStatus,
}: {
  isNaStatus: boolean
}): JSX.Element => (
  <ModalDescription>
    Read more about determining control responsibility in our{' '}
    <a href={HUBSPOT.CONTROL_RESPONSIBILITY} target="_blank" rel="noreferrer">
      Knowledge Base.
    </a>
    <P $color={palette.orange[900]}>
      {isNaStatus
        ? `Note: Control Status will also be updated to "Not Applicable"`
        : `Note: Control Status will also be updated to "Needs Attention"`}
    </P>
  </ModalDescription>
)
