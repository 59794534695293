import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite'
import { useAuthHeader } from '../../context/authContext'
import { callGrpc, SwrGrpcResponse } from './useSwrGrpc'
import { GrpcCall, useGetInfiniteCacheKey } from './utils'
import { queue } from './queue'

/**
  Hook for using SWR with pagination
  For an example of working with this hook see useChangeLogEntries.tsx
  ** This does not include logic for audit specific request. You will have to handle that in your request callback function
  * @param {GrpcCall} asyncCall - the grpc method that you want to call
  * @param {function requestFunc(pageNumber:number) {
    return Request
  }} - callback function that returns the request with the passed in page number added. See useChangeLogEntries.tsx
  * @param {SWRConfiguration} config - optional with options like disabling revalidation on focus. See SWR docs
  * @returns {SWRInfiniteResponse} { response }
*/

export const useSwrInfiniteGrpc = <Request, Response>(
  asyncCall: GrpcCall<Request, Response>,
  requestFunc: () => Request,
  pageNumber: number,
  config?: SwrGrpcResponse,
): { response: SWRInfiniteResponse<Response> } => {
  const header = useAuthHeader()
  const getInfiniteCacheKey = useGetInfiniteCacheKey()
  const response = useSWRInfinite(
    () => getInfiniteCacheKey(asyncCall, requestFunc(), pageNumber),
    (_method: string, _request: unknown, _accountId: string) =>
      queue.add(() => callGrpc(asyncCall, requestFunc(), header)),
    config,
  )
  return { response }
}
