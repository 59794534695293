import React from 'react'
import { SmartChecksProvider } from 'src/pages/Roadmap/smartchecks/context/SmartChecksContext'
import { CurrentControlContextProvider } from './CurrentControlContext'
import { CurrentFormAuditContextProvider } from './CurrentFormAuditContext'
import { CurrentEvidenceContextProvider } from './CurrentEvidenceContext'
import { CurrentPolicyContextProvider } from './CurrentPolicyContext'
import { FrameworkContextProvider } from './FrameworkWizardContext'
import { CurrentReceptorContextProvider } from './CurrentReceptorContext'
import { CurrentComplianceFrameworkObjectivesContextProvider } from './CurrentComplianceFrameworkObjectivesContext'
import { ScopingContextProvider } from './ScopingContext'

export {
  useFrameworkWizard,
  FrameworkContextProvider,
  FrameworkWizardContext,
} from './FrameworkWizardContext'
export {
  useCurrentControl,
  CurrentControlContextProvider,
  CurrentControlContext,
} from './CurrentControlContext'

export const FormContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => (
  <FrameworkContextProvider>
    <SmartChecksProvider>
      <CurrentControlContextProvider>
        <CurrentComplianceFrameworkObjectivesContextProvider>
          <CurrentReceptorContextProvider>
            <CurrentEvidenceContextProvider>
              <CurrentPolicyContextProvider>
                <CurrentFormAuditContextProvider>
                  <ScopingContextProvider>{children}</ScopingContextProvider>
                </CurrentFormAuditContextProvider>
              </CurrentPolicyContextProvider>
            </CurrentEvidenceContextProvider>
          </CurrentReceptorContextProvider>
        </CurrentComplianceFrameworkObjectivesContextProvider>
      </CurrentControlContextProvider>
    </SmartChecksProvider>
  </FrameworkContextProvider>
)
