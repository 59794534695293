import styled from 'styled-components/macro'
import {
  border,
  color,
  ColorProps,
  compose,
  space,
  SpaceProps,
  textStyle,
} from 'styled-system'

type CountChipProps = SpaceProps & Pick<ColorProps, 'bg'> & { inline?: boolean }

export const CountChip = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
  })
  .attrs<CountChipProps>((props) => ({
    px: 'xs',
    borderRadius: '2px',
    bg: props.bg || 'bg.neutralDark',
    color: props.color || 'text.default',
    textStyle: 'subtitle.3',
  }))<CountChipProps>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: center;
  ${compose(space, border, color, textStyle)}
  min-width: 20px;
`
