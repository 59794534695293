import React from 'react'
import EroBlinkGif from 'src/components/Icons/Brand/ero_blink.gif'
import {
  AuditBotThrobberIcon,
  AuditBotThrobberText,
} from './AuditBotThrobber.styles'

export const AuditBotThrobber = (): JSX.Element => {
  return (
    <>
      <AuditBotThrobberIcon src={EroBlinkGif} />
      <AuditBotThrobberText>
        Scanning initiated. This may take a few minutes.
      </AuditBotThrobberText>
    </>
  )
}
