import {
  Framework,
  Frameworks,
} from '@trustero/trustero-api-web/lib/model/framework_pb'
import { useMemo } from 'react'
import { BaseProps, OnUnpackedResponse } from '../types'

type Props<ExternalProps> = {
  modelId: string
  frameworks: Framework[]
  mutate: () => Promise<Frameworks | undefined>
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<ExternalProps, Frameworks, { framework: Framework }>
>

export function FrameworkComponentResponse<ExternalProps>(
  props: Props<ExternalProps>,
): JSX.Element {
  const frameworks = useMemo(() => {
    return props.frameworks.reduce(function (
      map: { [key: string]: Framework },
      obj: Framework,
    ) {
      map[obj.getModelId()] = obj
      return map
    },
    {})
  }, [props.frameworks])

  return props.child({ ...props, framework: frameworks[props.modelId] })
}
