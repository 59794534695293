import { useCallback } from 'react'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import {
  ListPoliciesRequest,
  PolicyRecords,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { useGrpcRevalidateByMethod } from '../useGrpcMutate'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { SwrGrpcResponse } from '../useSwrGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'

export const usePolicies = (
  request = new ListPoliciesRequest(),
  shouldFetch = true,
  config?: SwrGrpcResponse,
): GrpcResponse<PolicyRecords> => {
  const { response } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.list,
    request,
    shouldFetch,
    config,
  )
  return NewGrpcResponse(response)
}

export const usePolicyIds = (
  request: ListPoliciesRequest,
  shouldFetch = true,
  config?: SwrGrpcResponse,
): GrpcResponse<StringList> => {
  const { response } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.listIds,
    request,
    shouldFetch,
    config,
  )
  return NewGrpcResponse(response)
}

export const useInvalidatePoliciesCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(PolicyPromiseClient.prototype.list),
      mutateFunc(PolicyPromiseClient.prototype.listIds),
    ])
  }, [mutateFunc])
}
