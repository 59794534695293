import { LogLevelDesc } from 'loglevel'
import { isDev, isProduction, isStaging, isTesting } from '../Utils/environment'
import configData from './config.json'

export type Config = {
  supportEmail: string
  monitoring: {
    datadogRumApplicationId: string
    datadogRumClientToken: string
  }
  development: EnvDependentConfig
  testing: EnvDependentConfig
  staging: EnvDependentConfig
  production: EnvDependentConfig
}
export type EnvDependentConfig = {
  name: string
  ntrceApiUrl: string
  gaTrackerId?: string
  debugLevel: LogLevelDesc
  featureFlags?: { [key: string]: boolean }
  hotjar?: {
    hjid: number
    hjsv: number
    debug: boolean
    trustero_users: boolean
  }
  monitoring?: {
    datadog?: {
      clientToken: string
      forwardErrorsToLogs: boolean
      sampleRate: number
    }
  }
}

export const AppConfig = configData as Config
export const EnvConfig = ((): EnvDependentConfig => {
  const { development, testing, staging, production } = AppConfig
  if (isDev()) {
    return development
  }
  if (isTesting()) {
    return testing
  }
  if (isStaging()) {
    return staging
  }
  if (isProduction()) {
    return production
  }
  return development
})()
