import React, { useContext, useRef, useState } from 'react'
import Immutable from 'immutable'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { FlexboxProps, GridProps, LayoutProps } from 'styled-system'
import 'draft-js/dist/Draft.css'
import { stateToMarkdown } from 'draft-js-export-markdown'
import { stateFromMarkdown } from 'draft-js-import-markdown'
import {
  Editor,
  EditorProps,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  DraftBlockRenderMap,
  DefaultDraftBlockRenderMap,
} from 'draft-js'
import styled from 'styled-components/macro'
import { PERMISSIONS } from 'src/config/roleConfig'
import theme from '../../designSystem/theme'
import palette from '../../designSystem/variables/palette'
import { Spinner, ThrobberContext } from '../../Throbber'
import { PrimaryButton, SecondaryButton } from '../Reusable/Buttons'
import { FlexRow } from '../Reusable/Flex'
import { decorator } from './EntityDecorators'
import {
  EditorContainer,
  EditorHeader,
  EditorTitle,
  EditorWrapper,
  Toolbar,
  ToolbarContainer,
} from './styles'
import BlockStyleControls from './StyleControls/BlockStyleControls'
import TextStyleControls from './StyleControls/TextStyleControls'
import InlineStyleControls from './StyleControls/InlineStyleControls'
import { RteModalQueryParams } from './RteModal'

// Workaround for React 18 compatibility issues https://github.com/styled-components/styled-components/issues/3738
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditorProxy: any = Editor

export type RichTextEditorProps = {
  objectId: string
  title: string
  saveCB: (title: string, body: string) => void | Promise<unknown>
  isEditableTitle?: boolean
  markdown?: string
  editorWrapperProps?: GridProps &
    FlexboxProps &
    LayoutProps & {
      style?: React.CSSProperties
    }
  editorContainerProps?: LayoutProps & {
    style?: React.CSSProperties
  }
}

const StyledH1 = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.03px;
`

const StyledH2 = styled.h2`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.03px;
`

const StyledH3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.03px;
`

const StyledCode = styled.code`
  border: 1px solid ${theme.colors.border.neutral.light};
  border-radius: 2px;
  background-color: ${theme.colors.bg.neutral};
  color: ${palette.magenta['500']};
  font-family: Courier;
  font-size: 15px;
  letter-spacing: -0.03px;
  line-height: 22px;
`

export const RichTextEditor = ({
  title,
  isEditableTitle,
  markdown,
  saveCB,
  editorWrapperProps,
  editorContainerProps,
}: RichTextEditorProps): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const { setThrobberState } = useContext(ThrobberContext)

  const editorRef = useRef() as React.RefObject<Editor>

  const blockRenderMap: DraftBlockRenderMap = DefaultDraftBlockRenderMap.merge(
    Immutable.Map({
      'header-one': {
        element: StyledH1,
      },
      'header-two': {
        element: StyledH2,
      },
      'header-three': {
        element: StyledH3,
      },
      CODE: {
        element: StyledCode,
      },
    }),
  )

  const [editorState, setEditorState] = useState(() => {
    if (markdown) {
      const initState = stateFromMarkdown(markdown)
      return EditorState.createWithContent(initState, decorator)
    }
    return EditorState.createEmpty()
  })

  const [titleState, setTitleState] = useState(title)
  const titleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    const value = e.target.value
    setTitleState(value)
  }

  const closeRteModal = () => {
    navigate(
      queryString.exclude(
        `${location.pathname}${location.search}`,
        [
          RteModalQueryParams.SHOW_RTE_MODAL,
          RteModalQueryParams.UNIQUE_ID,
          RteModalQueryParams.SHOW_WIZARD,
          RteModalQueryParams.TYPE,
        ],
        {
          arrayFormat: 'bracket',
        },
      ),
    )
  }

  const handleSave = async (): Promise<void> => {
    setThrobberState({
      isShown: true,
      Display: <Spinner color="primary" size="xl" />,
    })

    const body = stateToMarkdown(editorState.getCurrentContent())
    await saveCB(titleState, body)

    setThrobberState({
      isShown: false,
      Display: <></>,
    })
    closeRteModal()
  }

  const keyBindingFn = (e: React.KeyboardEvent) => {
    // Handle indentation for nested list elements on `tab` or `shift + tab`
    if (e.key === 'Tab') {
      e.preventDefault()
      const newState = RichUtils.onTab(e, editorState, 4)
      if (newState) {
        setEditorState(newState)
        return 'handled'
      } else {
        return 'not-handled'
      }
    }

    return getDefaultKeyBinding(e)
  }

  const handleKeyCommand = (command: string, localEditorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(localEditorState, command)
    if (newState) {
      setEditorState(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const blockStyleFn = (): unknown => null

  const editorProps = {
    ref: editorRef,
    editorState,
    onChange: setEditorState,
    keyBindingFn,
    handleKeyCommand,
    blockStyleFn,
    blockRenderMap,
    spellCheck: true,
  } as EditorProps

  return (
    <EditorWrapper
      {...editorWrapperProps}
      onClick={() => editorRef?.current?.focus()}
    >
      <EditorHeader>
        {isEditableTitle ? (
          <EditorTitle
            as="input"
            value={titleState}
            placeholder="Enter a Title"
            onClick={(e: React.SyntheticEvent<HTMLInputElement>) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onChange={titleOnChange}
          />
        ) : (
          <EditorTitle>{titleState}</EditorTitle>
        )}
        <FlexRow gap={10} $mr={10}>
          <SecondaryButton
            onClick={closeRteModal}
            text="Clear"
            requiredPermissions={[PERMISSIONS.READ]}
          />
          <PrimaryButton onClick={handleSave} text="Save" />
        </FlexRow>
      </EditorHeader>
      <ToolbarContainer>
        <Toolbar>
          <TextStyleControls {...{ editorRef, setEditorState }} />
          <BlockStyleControls {...{ editorRef, editorState, setEditorState }} />
          <InlineStyleControls
            {...{ editorRef, editorState, setEditorState }}
          />
        </Toolbar>
      </ToolbarContainer>
      <EditorContainer {...editorContainerProps}>
        <EditorProxy {...editorProps} />
      </EditorContainer>
    </EditorWrapper>
  )
}
