import log from 'loglevel'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { SCAN_RECEPTOR_INTERVAL } from 'src/pages/Receptors/receptors.constants'
import { useInProgressReceptorScans } from 'src/pages/Receptors/receptors.hooks'

type ReceptorScanContextType = {
  receptorScanRunning: boolean
  setReceptorScanRunning: React.Dispatch<React.SetStateAction<boolean>>
  scanInProgressState: Record<string, boolean>
  setScanInProgressState: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >
}

export const ReceptorScanContext = createContext<ReceptorScanContextType>({
  receptorScanRunning: false,
  setReceptorScanRunning: () => null,
  scanInProgressState: {},
  setScanInProgressState: () => null,
})

export default ReceptorScanContext

export const ReceptorScanContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const { data, isLoading, mutate, error } = useInProgressReceptorScans()
  const interval = useRef<number | null>(0)
  const [receptorScanRunning, setReceptorScanRunning] = useState(false)
  const [scanInProgressState, setScanInProgressState] = useState<
    Record<string, boolean>
  >({})
  const currentReceptorIds = useRef<string[]>([])

  const getScanInProgressState = useCallback(
    (receptorIds: string[]): Record<string, boolean> => {
      const newReceptorIds = new Set(receptorIds)
      const previousReceptorIds = currentReceptorIds.current
      // compare the ids in the current state to the previous state
      // if the current state does not contain an id from the previous state,
      // that scan has finished, so we show a toast
      // Right now, these toasts will only appear on the receptors index or show pages
      // In the future, we may want to move the context provider, so they appear everywhere in the app
      previousReceptorIds.forEach((receptorId) => {
        if (!newReceptorIds.has(receptorId)) {
          showInfoToast(`Receptor scan finished`)
        }
      })

      return receptorIds.reduce((acc, receptorId) => {
        acc[receptorId] = true
        return acc
      }, {} as Record<string, boolean>)
    },
    [currentReceptorIds],
  )

  const stopPolling = useCallback(() => {
    if (!interval.current) return
    clearInterval(interval.current)
    interval.current = null
  }, [interval])

  const startPolling = useCallback(() => {
    if (interval.current) return
    interval.current = window.setInterval(
      async () => await mutate(),
      SCAN_RECEPTOR_INTERVAL,
    )
  }, [interval, mutate])

  useEffect(() => {
    if (!data || isLoading) return
    else if (error) {
      log.error('Error fetching in-progess receptor scans:', error)
      return
    }
    const receptorIds = data.getReceptorIdsList()
    const scansInProgress = getScanInProgressState(receptorIds)
    setScanInProgressState(scansInProgress)
    if (Object.values(scansInProgress).length > 0) {
      setReceptorScanRunning(true)
    } else {
      setReceptorScanRunning(false)
    }
    currentReceptorIds.current = receptorIds

    startPolling()

    return () => stopPolling()
  }, [
    data,
    isLoading,
    error,
    startPolling,
    stopPolling,
    getScanInProgressState,
  ])

  const value = useMemo(
    () => ({
      receptorScanRunning,
      setReceptorScanRunning,
      scanInProgressState,
      setScanInProgressState,
    }),
    [receptorScanRunning, scanInProgressState],
  )

  return (
    <ReceptorScanContext.Provider value={value}>
      {children}
    </ReceptorScanContext.Provider>
  )
}

export const useReceptorScanContext = (): ReceptorScanContextType => {
  return useContext(ReceptorScanContext)
}
