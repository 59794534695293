import React, { useMemo } from 'react'
import { ListPoliciesRequest } from '@trustero/trustero-api-web/lib/model/policy_pb'
import queryString, { ParsedQuery } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { usePolicyIds } from 'src/components/async/policy/usePolicies'
import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { PERMISSIONS } from 'src/config/roleConfig'
import { FilterName, FilterParam } from '../FilterBar.types'
import { Spinner } from '../../../../../Throbber'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Chips/BasicChip'
import { usePolicy } from '../../../../async/model'
import { ListItemPlaceholder } from '../../../../Placeholders/ListItemPlaceholder'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'

const PolicyItem = ({
  policyId,
  searchParams,
  appliedFilters,
}: {
  policyId: string
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element => {
  const navigate = useNavigate()
  const { data, error, isLoading } = usePolicy(policyId)
  if (error || isLoading) return <></>
  if (!data && policyId !== NONE_ID) return <ListItemPlaceholder height={36} />
  const policyModelId = data?.getModelId() || NONE_ID
  const policyName = data?.getName() || NONE_NAME

  const newAppliedFilters = new Set(appliedFilters)
  const isChecked = newAppliedFilters.has(policyModelId)

  isChecked
    ? newAppliedFilters.delete(policyModelId)
    : newAppliedFilters.add(policyModelId)

  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...searchParams,
        [FilterParam.POLICY]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <FilterDropdownItem replace to={href}>
      <CheckBoxInput
        id={policyModelId}
        label={policyName}
        checked={isChecked}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={() => {
          navigate(href, { replace: true })
        }}
        tabIndex={-1}
        requiredPermissions={[PERMISSIONS.READ]}
      />
    </FilterDropdownItem>
  )
}

const PolicyDropdownMenu = ({
  policyIds,
  searchParams,
  appliedFilters,
}: {
  policyIds: string[]
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element => {
  return (
    <Dropdown.Menu>
      <PolicyItem
        policyId={NONE_ID}
        searchParams={searchParams}
        appliedFilters={appliedFilters}
      />
      {policyIds.map((policyId: string) => (
        <PolicyItem
          key={policyId}
          policyId={policyId}
          searchParams={searchParams}
          appliedFilters={appliedFilters}
        />
      ))}
    </Dropdown.Menu>
  )
}

export const PolicyFilterDropdown = (): JSX.Element => {
  const findPolicyRequest = useMemo(() => new ListPoliciesRequest(), [])
  const location = useLocation()
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[FilterParam.POLICY]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams])

  const { data, isLoading, error } = usePolicyIds(findPolicyRequest)
  if (error) return <></>
  if (!data || isLoading) {
    return (
      <>
        <Dropdown.Toggle
          as={FilterDropdownToggle}
          id={`${FilterName.POLICY}-filter-dropdown`}
        >
          <p>{FilterName.POLICY}</p>
          <Spinner size="s" color="primary" mx="xxs" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      </>
    )
  }

  const policyIds = data.getItemList()

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        id={`${FilterName.POLICY}-filter-dropdown`}
      >
        <p>{FilterName.POLICY}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      <PolicyDropdownMenu
        policyIds={policyIds}
        searchParams={searchParams}
        appliedFilters={appliedFilters}
      />
    </Dropdown>
  )
}
