import React from 'react'
import { useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { useSetActiveModal } from 'src/Modal/ModalStateContext'
import { Changelog, InfoPanelContext } from '../../../../InfoPanel'
import { ModalFormId } from '../../../../components/ModalForms'
import { generatePermalinkFromId } from '../../../../components/PageLayout/Permalink'
import { useInAudit } from '../../../../context/AuditContext'
import { useCurrentControl } from '../../../../context/FormContext'
import { useRemoveControlFromRequestModal } from '../../../../Modal/useRemoveControlFromRequestModal'

export const ViewDetails = (): JSX.Element => {
  const {
    currentControl: { modelId },
  } = useCurrentControl()
  const navigate = useNavigate()
  const params = useParams()
  const onViewDetails = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      navigate(
        generatePermalinkFromId({
          pageContext: params.pageContext as string,
          modelId,
          isInternalLink: true,
        }),
      )
    },
    [modelId, navigate, params.pageContext],
  )

  return (
    <Dropdown.Item eventKey="1" as="button" onClick={onViewDetails}>
      View Details
    </Dropdown.Item>
  )
}

export const InScopeItems = (): JSX.Element => {
  const openAddEvidenceModal = useSetActiveModal(ModalFormId.ADD_EVIDENCE)

  return (
    <>
      <Dropdown.Item
        as="button"
        eventKey="2"
        onClick={(e) => {
          e.preventDefault()
          openAddEvidenceModal()
        }}
      >
        Add Evidence
      </Dropdown.Item>
      <Dropdown.Divider />
    </>
  )
}

export const RemoveControlFromAudit = (): JSX.Element => {
  const { removeControl } = useCurrentControl()

  return (
    <Dropdown.Item
      eventKey="4"
      as="button"
      onClick={(e) => {
        e.preventDefault()
        removeControl()
      }}
    >
      Remove from this Audit
    </Dropdown.Item>
  )
}

export const DeleteControl = (): JSX.Element => {
  const { auditId } = useInAudit()
  const { deleteControl } = useCurrentControl()

  return (
    <>
      {auditId && <RemoveControlFromAudit />}
      <Dropdown.Item
        eventKey="5"
        as="button"
        onClick={(e) => {
          e.preventDefault()
          deleteControl()
        }}
      >
        Delete Control
      </Dropdown.Item>
    </>
  )
}

export const CopyLink = (): JSX.Element => {
  const params = useParams()
  const {
    currentControl: { id: controlId },
  } = useCurrentControl()
  const onCopyLink = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()
      await navigator.clipboard.writeText(
        generatePermalinkFromId({
          pageContext: params.pageContext as string,
          modelId: controlId,
          isInternalLink: false,
        }),
      )
    },
    [controlId, params.pageContext],
  )
  return (
    <Dropdown.Item eventKey="6" as="button" onClick={onCopyLink}>
      Copy Permalink
    </Dropdown.Item>
  )
}

export const ViewActivities = (): JSX.Element => {
  const { setInfoPanelState } = useContext(InfoPanelContext)
  const {
    currentControl: { id: controlId },
  } = useCurrentControl()

  const onViewActivities = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setInfoPanelState({
        isShown: true,
        render: <Changelog subjectIds={[controlId]} />,
      })
    },
    [controlId, setInfoPanelState],
  )

  return (
    <Dropdown.Item eventKey="7" as="button" onClick={onViewActivities}>
      View Activities
    </Dropdown.Item>
  )
}

export const RemoveControl = ({
  requestId,
}: {
  requestId: string
}): JSX.Element => {
  const removeControl = useRemoveControlFromRequestModal({ requestId })
  return (
    <Dropdown.Item
      eventKey="8"
      as="button"
      onClick={(e) => {
        e.preventDefault()
        removeControl()
      }}
    >
      Remove Control
    </Dropdown.Item>
  )
}
