import React, { ChangeEventHandler } from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { ControlPolicyInfo } from 'src/components/Reusable/ListItem/ListItem.components'
import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  useControl,
  useControlIgnoreAudit,
} from '../../../../../components/async/model/control/useControl'
import { GridColumn, GridRow } from '../../../../../components/Reusable/Grid'
import { CheckBoxInput } from '../../../../../components/Reusable/Inputs/CheckBox'
import { Title } from '../../../../../components/Reusable/Text/Title'
import { OwnerAssignmentButton as ReusableOwnerAssignmentButton } from '../../../../../components/Reusable/Buttons'
import { DepartmentChip as ReusableDepartmentChip } from '../../../DepartmentChip'

export const ControlsIndexItem = ({
  modelId,
  selected,
  onChange,
}: {
  modelId: string
  selected: string[]
  onChange: ChangeEventHandler
}): JSX.Element => {
  const { data, isLoading } = useControl(modelId, true)

  const controlRes = data?.toObject()

  if (!isLoading && !controlRes) {
    // attempt to fetch without the audit context
    return (
      <ControlsIndexItemIgnoreAuditContext
        modelId={modelId}
        selected={selected}
        onChange={onChange}
      />
    )
  }

  if (!controlRes) {
    return <></>
  }

  const { policyId, name, ownerEmail, departmentId, status } = controlRes
  const isNotApplicable = status === CONTROL_STATUS.NOTAPPLICABLE

  return (
    <GridRow id={`control-${modelId}`}>
      <GridColumn>
        <CheckBoxInput
          value={modelId}
          onChange={onChange}
          checked={selected.includes(modelId)}
        />
      </GridColumn>
      <GridColumn
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <ControlPolicyInfo
          policyModelId={policyId}
          isControlNa={isNotApplicable}
        />
        <Title name={name} />
      </GridColumn>
      <GridColumn justifyContent="center">
        <ReusableOwnerAssignmentButton
          modelId={modelId}
          modelType={MODEL_TYPE.CONTROL}
          email={ownerEmail}
          isDisabled
        />
      </GridColumn>
      <GridColumn justifyContent="center">
        <ReusableDepartmentChip departmentId={departmentId} />
      </GridColumn>
    </GridRow>
  )
}

export const ControlsIndexItemIgnoreAuditContext = ({
  modelId,
  selected,
  onChange,
}: {
  modelId: string
  selected: string[]
  onChange: ChangeEventHandler
}): JSX.Element => {
  const { data } = useControlIgnoreAudit(modelId, true)

  const controlRes = data?.toObject()

  if (!controlRes) {
    return <></>
  }

  const { policyId, name, ownerEmail, departmentId, status } = controlRes
  const isNotApplicable = status === CONTROL_STATUS.NOTAPPLICABLE

  return (
    <GridRow id={`control-${modelId}`}>
      <GridColumn>
        <CheckBoxInput
          value={modelId}
          onChange={onChange}
          checked={selected.includes(modelId)}
        />
      </GridColumn>
      <GridColumn
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <ControlPolicyInfo
          policyModelId={policyId}
          isControlNa={isNotApplicable}
        />
        <Title name={name} />
      </GridColumn>
      <GridColumn justifyContent="center">
        <ReusableOwnerAssignmentButton
          modelId={modelId}
          modelType={MODEL_TYPE.CONTROL}
          email={ownerEmail}
          isDisabled
        />
      </GridColumn>
      <GridColumn justifyContent="center">
        <ReusableDepartmentChip departmentId={departmentId} />
      </GridColumn>
    </GridRow>
  )
}
