import {
  RISK_CATEGORY,
  RISK_MATH,
  RISK_TREATMENT,
  Risk,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { CSV_VALIDATIONS, ParseColumnType } from 'src/Utils/csv/csv.constants'
import { ImportRiskCSVRow } from './RiskModals.constants'

export const getRiskMathValueAsString = (value: string): string => {
  switch (value.trim().toLowerCase()) {
    case 'very high':
      return RISK_MATH.VERY_HIGH.toString()
    case 'high':
      return RISK_MATH.HIGH.toString()
    case 'moderate':
      return RISK_MATH.MODERATE.toString()
    case 'low':
      return RISK_MATH.LOW.toString()
    case 'very low':
      return RISK_MATH.VERY_LOW.toString()
    default:
      return RISK_MATH.NOT_SET.toString()
  }
}

export const getTreatmentValueAsString = (value: string): string => {
  switch (value.trim().toLowerCase()) {
    case 'accept':
      return RISK_TREATMENT.ACCEPT.toString()
    case 'avoid':
      return RISK_TREATMENT.AVOID.toString()
    case 'mitigate':
      return RISK_TREATMENT.MITIGATE.toString()
    case 'share':
      return RISK_TREATMENT.SHARE.toString()
    case 'transfer':
      return RISK_TREATMENT.TRANSFER.toString()
    default:
      return ''
  }
}

export const getRiskCategoryValueAsString = (value: string): string => {
  switch (value.trim().toLowerCase()) {
    case 'customer commitments':
      return RISK_CATEGORY.CUSTOMER.toString()
    case 'vendor management':
      return RISK_CATEGORY.VENDOR.toString()
    case 'leadership commitment':
      return RISK_CATEGORY.LEADERSHIP.toString()
    case 'system and technology changes':
      return RISK_CATEGORY.SYSTEMS.toString()
    case 'laws, regulations, and standards':
      return RISK_CATEGORY.LAWS.toString()
    case 'intellectual property':
      return RISK_CATEGORY.IP.toString()
    default:
      return ''
  }
}

const RISK_MATH_VALUES = [
  'very high',
  'high',
  'moderate',
  'low',
  'very low',
  'not set',
]

const RISK_TREATMENT_VALUES = [
  'accept',
  'avoid',
  'mitigate',
  'share',
  'transfer',
]

const RISK_CATEGORY_VALUES = [
  'customer commitments',
  'vendor management',
  'leadership commitment',
  'systems and technology changes',
  'laws, regulations, and standards',
  'intellectual property',
]

export const getRiskParsers = (
  userEmailSet: Set<string>,
  controlIdSet: Set<string>,
): ParseColumnType => ({
  riskId: {
    validator: (columnName, value, row) =>
      CSV_VALIDATIONS.LENGTH(columnName, value, 1, 10, row),
    mutator: (value: string) => value.slice(0, 10),
  },
  threat: {
    validator: (columnName, value, row) =>
      CSV_VALIDATIONS.LENGTH(columnName, value, 1, 255, row),
    mutator: (value: string) => value.slice(0, 255),
  },
  category: {
    validator: (_c, value, row) =>
      CSV_VALIDATIONS.VALUE_IN_ARRAY(value, row, RISK_CATEGORY_VALUES),
    mutator: (value) => getRiskCategoryValueAsString(value),
  },
  predisposingCondition: {
    validator: (_c, _v, _r) => null,
    mutator: (value) => value,
  },
  assetAtRisk: {
    validator: (_c, _v, _r) => null,
    mutator: (value) => value,
  },
  impactToCustomer: {
    validator: (_c, _v, _r) => null,
    mutator: (value) => value,
  },
  likelihood: {
    validator: (_c, value, row) =>
      CSV_VALIDATIONS.VALUE_IN_ARRAY(value, row, RISK_MATH_VALUES),
    mutator: (value) => getRiskMathValueAsString(value),
  },
  impact: {
    validator: (_c, value, row) =>
      CSV_VALIDATIONS.VALUE_IN_ARRAY(value, row, RISK_MATH_VALUES),
    mutator: (value: string) => getRiskMathValueAsString(value),
  },
  response: {
    validator: (_c, value, row) =>
      CSV_VALIDATIONS.VALUE_IN_ARRAY(value, row, RISK_TREATMENT_VALUES),
    mutator: (value) => getTreatmentValueAsString(value),
  },
  riskOwner: {
    validator: (_c, value, row) =>
      CSV_VALIDATIONS.USER_EMAIL(value, userEmailSet, row),
    mutator: (value) => value,
  },
  residualRisk: {
    validator: (_c, value, row) =>
      CSV_VALIDATIONS.VALUE_IN_ARRAY(value, row, RISK_MATH_VALUES),
    mutator: (value) => getRiskMathValueAsString(value),
  },
  controlIds: {
    validator: (_, value, row) =>
      CSV_VALIDATIONS.ID_EXISTS(value, controlIdSet, row) ||
      CSV_VALIDATIONS.ID_EXISTS_ONCE(row, value),
    mutator: (value) => value.split(',').map((v) => v.trim()),
  },
})

export const prepareRisksUpload = (
  rows: ImportRiskCSVRow[],
  controlsMap: { [x: string]: string },
): Risk[] =>
  rows.map((row) => {
    const risk = new Risk()
      .setCustomId(row.riskId)
      .setName(row.threat)
      .setCategory(Number(row.category) as RISK_CATEGORY)
      .setPredisposingCondition(row.predisposingCondition)
      .setAssetAtRisk(row.assetAtRisk)
      .setImpactToCustomer(row.impactToCustomer)
      .setLikelihood(Number(row.likelihood) as RISK_MATH)
      .setAdverseImpact(Number(row.impact) as RISK_MATH)
      .setOwnerEmail(row.riskOwner)
      .setRiskAfterTreatment(Number(row.residualRisk) as RISK_MATH)
      .setControlIds(
        row.controlIds.map((controlId) => controlsMap[controlId]).join(','),
      )
    row.response && risk.setTreatment(Number(row.response) as RISK_TREATMENT)
    return risk
  })
