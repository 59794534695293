import React, { PropsWithChildren } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components/macro'
import { CloseButton } from '../../Reusable/Buttons'

export enum RteModalQueryParams {
  /** Used in conjunction with the UNIQUE_ID param to control visibility */
  SHOW_RTE_MODAL = 'show_rte_modal',
  /** Used in combination with the SHOW_RTE_MODAL param to control visibility */
  UNIQUE_ID = 'rte_modal_id',
  /** Controls visibility of the `<RteTypeSelector>` wizard */
  SHOW_WIZARD = 'show_rte_modal_wizard',
  /**
   * Used to determine the selected `type` in the `<RteTypeSelector>` wizard,
   * which should then be used to determine
   * what value to prefill the `<RichTextEditor>`'s markdown with
   */
  TYPE = 'rte_type',
}

export function RteModal({
  show,
  children,
}: PropsWithChildren<{ show: boolean }>): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()

  const hide = () => {
    navigate(
      queryString.exclude(
        `${location.pathname}${location.search}`,
        [
          RteModalQueryParams.SHOW_RTE_MODAL,
          RteModalQueryParams.UNIQUE_ID,
          RteModalQueryParams.SHOW_WIZARD,
          RteModalQueryParams.TYPE,
        ],
        {
          arrayFormat: 'bracket',
        },
      ),
    )
  }

  return (
    <RteModalContainer show={show} dialogAs={RteModalDialog} onHide={hide}>
      <CloseButton
        position="absolute"
        top="s"
        right="s"
        zIndex="inherit"
        bg="transparent"
        onClick={(e) => {
          e.preventDefault()
          hide()
        }}
      />
      {children}
    </RteModalContainer>
  )
}

const RteModalContainer = styled(Modal)`
  padding-left: 0 !important;
  .Rte-Modal-dialog {
    z-index: inherit;
    width: 100vw;
    height: 100vh;
    .Rte-Modal-content {
      z-index: inherit;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  }
`

function RteModalDialog({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return <Modal.Dialog bsPrefix="Rte-Modal">{children}</Modal.Dialog>
}
