import React from 'react'
import styled from 'styled-components/macro'
import {
  color,
  compose,
  fontSize,
  margin,
  textAlign,
  textStyle,
} from 'styled-system'
import { $truncate } from '../../../globalStyles'
import { ErrorSVG } from '../../Icons/Basic'
import { Tooltip, TooltipOverlayType } from '../Tooltip/Tooltip'

const MissingIcon = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
  mr: 'xxs',
})`
  ${margin}
  margin-bottom: 2px;
  width: 12px;
  height: 12px;
`
const MismatchIcon = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
  ml: 'xs',
})`
  ${margin}
  width: 12px;
  height: 12px;
`

const ComplianceFrameworkInfoContainer = styled.div.attrs({
  mb: 'xxs',
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 20vw;
`

const ModelComplianceFrameWorks = styled.span.attrs<ModelComplianceFrameworksProps>(
  (props) => ({
    color: props.error
      ? 'fill.status.error.dark'
      : 'fill.status.info.extraDark',
    textStyle: 'subtitle.caption',
    fontSize: props.error ? '12px' : '14px',
    textAlign: 'right',
  }),
)<ModelComplianceFrameworksProps>`
  ${compose(color, textStyle, fontSize, textAlign)}
  ${$truncate}
`

type Props = {
  modelComplianceFrameworkNames: string
  error: boolean
  mismatch: boolean
  modelType: string
}

export const ModelComplianceFrameWorkInfo = ({
  error,
  modelComplianceFrameworkNames,
  mismatch,
  modelType,
}: Props): JSX.Element => {
  return (
    <ComplianceFrameworkInfoContainer>
      {error && <MissingIcon />}
      <ModelComplianceFrameWorks error={error}>
        {modelComplianceFrameworkNames}
      </ModelComplianceFrameWorks>
      {mismatch && (
        <Tooltip
          id="model-compliance-framework-tooltip"
          overlayType={TooltipOverlayType.popover}
          tooltipBody={`The ${modelType}'s compliance framework does not match the current audit.`}
        >
          <MismatchIcon />
        </Tooltip>
      )}
    </ComplianceFrameworkInfoContainer>
  )
}

ModelComplianceFrameWorkInfo.defaultProps = {
  mismatch: false,
  error: false,
}

type ModelComplianceFrameworksProps = {
  error?: boolean
}
