import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import palette from '../../../designSystem/variables/palette'

export const PlainButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: none;
`

export const PrevNext = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.neutral[900]};
  width: fit-content;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  white-space: nowrap;
`

export const PrevNextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`

export const NextIcon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
color: ${({ disabled }) =>
  disabled ? palette.neutral[200] : palette.neutral[900]},
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const ButtonNoStyles = styled.button`
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: none;
`

export const PrevNextP = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.neutral[900]};
  width: fit-content;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  white-space: nowrap;
`
