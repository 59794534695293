import { RecommendPromiseClient } from '@trustero/trustero-api-web/lib/recommend/recommend_grpc_web_pb'
import {
  ListSuggestionsRequest,
  ListSuggestionsResponse,
} from '@trustero/trustero-api-web/lib/recommend/recommend_pb'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'

export const useSuggestionsForAccount = (
  request: ListSuggestionsRequest,
  shouldFetch = true,
): GrpcResponse<ListSuggestionsResponse> => {
  const { response } = useSwrImmutableGrpc(
    RecommendPromiseClient.prototype.listSuggestionsForAccount,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}
