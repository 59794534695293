import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  DOCUMENT_TYPE,
  Document,
  Documents,
  BulkAddEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import log from 'loglevel'
import { stripFileExtension } from 'src/components/Reusable/Forms/FileSelector/FileSelector.helpers'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../adapter/grpcClient'
import { showInfoToast, ToastPrompts } from '../../../Utils/helpers/toast'
import { useInvalidateEvidence } from '../../../Utils/swrCacheInvalidation'
import { useAnalytics } from '../../../analytics/useAnalytics'

type BulkCreateEvidenceArgs = {
  controlId?: string
  modelId?: string
  requestId?: string
}

export const useBulkCreateEvidence = ({
  controlId,
  modelId,
  requestId,
}: BulkCreateEvidenceArgs): ((
  files: File[],
  date: Timestamp,
  uploadSize: number,
  caption?: string,
) => Promise<void>) => {
  const invalidateEvidence = useInvalidateEvidence()
  const attachmentClient = useAuthorizedGrpcClientWithContentUpdate(
    AttachmentPromiseClient,
  )
  const { track, events } = useAnalytics()

  return async (
    files: File[],
    date: Timestamp,
    uploadSize: number,
    caption?: string,
  ) => {
    const filesCount = files.length
    const evidence = new Documents()

    for (const file of files) {
      const name =
        files.length === 1
          ? caption || stripFileExtension(file?.name)
          : stripFileExtension(file?.name)

      const bodyBytes = new Uint8Array(await new Response(file).arrayBuffer())
      const newEvidence = new Document()
        .setDoctype(DOCUMENT_TYPE.EVIDENCE)
        .setMime(file.type)
        .setBody(bodyBytes)
        .setCaption(name)
        .setRelevantdate(date)
        .setSubjectmodeltype(MODEL_TYPE.CONTROL)
      if (controlId) {
        newEvidence.setSubjectid(controlId)
      }
      if (requestId) {
        newEvidence.setRequestid(requestId)
      }
      evidence.addDocuments(newEvidence)
    }

    try {
      track(events.S_EVIDENCE_UPLOAD, { count: filesCount, uploadSize })
      const request = new BulkAddEvidenceRequest()
        .setDocuments(evidence)
        .setUploadSize(uploadSize)
      if (controlId) {
        request.setIdentifier(
          new Identifier()
            .setModelid(modelId || '')
            .setModeltype(MODEL_TYPE.CONTROL),
        )
      }
      if (requestId) {
        request.setRequestId(new StringValue().setValue(requestId))
      }
      await attachmentClient.bulkAddEvidence(request)
      await invalidateEvidence()
    } catch (error) {
      log.error(
        `Error when adding new evidence in useBulkCreateEvidence hook for ${
          controlId ? `control id: ${controlId}` : `request id: ${requestId}`
        }`,
        error,
      )
      showInfoToast(ToastPrompts.EVIDENCE_UPLOAD_FAILED)
    }
  }
}
