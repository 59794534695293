import React, { createContext, useContext, useState } from 'react'
import { Spinner, ThrobberColor, ThrobberSize } from './Spinner'

export type ThrobberContextState = {
  isShown: boolean
  Display: JSX.Element
}

export type ThrobberContextType = {
  throbberState: ThrobberContextState
  setThrobberState: React.Dispatch<React.SetStateAction<ThrobberContextState>>
  startThrobber: (color?: ThrobberColor, size?: ThrobberSize) => void
  stopThrobber: () => void
}

export const ThrobberContext = createContext<ThrobberContextType>({
  throbberState: { isShown: false, Display: <></> },
  setThrobberState: () => null,
  startThrobber: () => null,
  stopThrobber: () => null,
})

export const useThrobberContext = (): ThrobberContextType =>
  useContext(ThrobberContext)

type ThrobberProviderType = {
  children: NonNullable<React.ReactNode> | Array<NonNullable<React.ReactNode>>
}

export function ThrobberProvider({
  children,
}: ThrobberProviderType): JSX.Element {
  const [throbberState, setThrobberState] = useState<ThrobberContextState>({
    isShown: false,
    Display: <></>,
  })

  const startThrobber = (
    color: ThrobberColor = 'primary',
    size: ThrobberSize = 'xl',
  ): void => {
    setThrobberState({
      isShown: true,
      Display: <Spinner color={color} size={size} />,
    })
  }

  const stopThrobber = (): void => {
    setThrobberState({
      isShown: false,
      Display: <></>,
    })
  }

  return (
    <ThrobberContext.Provider
      value={{ throbberState, setThrobberState, startThrobber, stopThrobber }}
    >
      {children}
    </ThrobberContext.Provider>
  )
}
