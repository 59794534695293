import { DOCUMENT_REQUEST_STATUS } from '@trustero/trustero-api-web/lib/request/request_pb'
import {
  AUDIT_RESULT,
  AUDIT_STATUS,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import {
  AUDITBOT_RESULT_COLORS,
  AUDITBOT_STATUS_COLORS,
  AUDIT_SCAN_FREQUENCY_STATUS,
  AUDIT_SCAN_FREQUENCY_STATUS_COLORS,
  DATE_LIMIT_STATUS,
  DATE_LIMIT_STATUS_COLORS,
  REQUEST_STATUS_COLORS,
  STATUS_TYPES,
} from './StatusIndicator.constants'

export const getStatusColor = (
  statusType: STATUS_TYPES,
  status: number,
): string | undefined => {
  switch (statusType) {
    case STATUS_TYPES.REQUEST:
      if (!REQUEST_STATUS_COLORS[status as DOCUMENT_REQUEST_STATUS]) {
        break
      }
      return REQUEST_STATUS_COLORS[status as DOCUMENT_REQUEST_STATUS]
    case STATUS_TYPES.AUDITBOT_RUN_RESULT:
      if (!AUDITBOT_RESULT_COLORS[status as AUDIT_RESULT]) {
        break
      }
      return AUDITBOT_RESULT_COLORS[status as AUDIT_RESULT]
    case STATUS_TYPES.AUDITBOT_RUN_STATUS:
      if (!AUDITBOT_STATUS_COLORS[status as AUDIT_STATUS]) {
        break
      }
      return AUDITBOT_STATUS_COLORS[status as AUDIT_STATUS]
    case STATUS_TYPES.DATE_LIMIT:
      if (!DATE_LIMIT_STATUS_COLORS[status as DATE_LIMIT_STATUS]) {
        break
      }
      return DATE_LIMIT_STATUS_COLORS[status as DATE_LIMIT_STATUS]
    case STATUS_TYPES.AUDIT_SCAN_FREQUENCY:
      if (
        !AUDIT_SCAN_FREQUENCY_STATUS_COLORS[
          status as AUDIT_SCAN_FREQUENCY_STATUS
        ]
      ) {
        break
      }
      return AUDIT_SCAN_FREQUENCY_STATUS_COLORS[
        status as AUDIT_SCAN_FREQUENCY_STATUS
      ]

    default:
      break
  }
}
