import React from 'react'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import { StyledModalDialog } from './ModalForm.styles'

export const ModalContainer = ({
  children,
  enforceFocus,
  ...props
}: ModalProps): JSX.Element => {
  return (
    <Modal {...props} enforceFocus={enforceFocus} dialogAs={StyledModalDialog}>
      {children}
    </Modal>
  )
}
