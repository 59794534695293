import React from 'react'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import {
  ShowPageBody,
  ShowPageItem,
  ShowPageItemBody,
  ShowPageItemGuidanceAndNotes,
  ShowPageLinkagesSection,
} from '../../../components/PageLayout/ShowPage'
import { RiskSectionContainer, RiskSectionHeader } from '../risks.styles'
import { AddControlsToRiskModal } from '../modals/AddControlsToRiskModal'
import { RiskLinkages } from './RisksLinkages'
import { RiskOverview } from './RisksOverview'
import { RisksResponse } from './RisksResponse'
import { RisksNotes } from './RisksNotes'
import { RisksTabs } from './RisksTabs'

export const RisksShowPageContent = ({ risk }: { risk: Risk }): JSX.Element => {
  const existingControlIds = risk.getControlIds().split(',')
  const riskId = risk.getId()
  return (
    <>
      <ShowPageItem>
        <ShowPageBody>
          <ShowPageItemGuidanceAndNotes>
            <RiskSectionContainer>
              <RiskSectionHeader>RISK</RiskSectionHeader>
              <RiskOverview risk={risk} />
            </RiskSectionContainer>
            <RiskSectionContainer>
              <RiskSectionHeader>RESPONSE</RiskSectionHeader>
              <RisksResponse risk={risk} />
            </RiskSectionContainer>
            <RiskSectionContainer>
              <RiskSectionHeader>NOTES</RiskSectionHeader>
              <RisksNotes risk={risk} />
            </RiskSectionContainer>
          </ShowPageItemGuidanceAndNotes>
          <ShowPageLinkagesSection>
            <RiskLinkages risk={risk} />
          </ShowPageLinkagesSection>
        </ShowPageBody>
        <ShowPageItemBody>
          <RisksTabs risk={risk} />
        </ShowPageItemBody>
      </ShowPageItem>
      {/* ----------- Modal(s) ----------- */}
      <AddControlsToRiskModal
        riskId={riskId}
        existingControlIds={existingControlIds}
      />
    </>
  )
}
