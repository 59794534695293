import { getPluralization } from 'src/Utils/globalHelpers'

export const getSecurityQuestionnaireStatusSectionCopy = (
  completed: number,
  total: number,
): string => {
  const phrase = completed ? 'Great job' : 'Get started'
  return `${phrase}. ${completed} out of ${total} ${getPluralization(
    'answer',
    total,
  )} have been accepted.`
}
