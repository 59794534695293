import React from 'react'
import { createRoot } from 'react-dom/client'

// WARNING: This must be the first local include
import { AppInit } from './Utils/AppInit'
import { App } from './app/App'
import { AppProviders } from './context/AppProviders'

AppInit()

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <AppProviders>
    <App />
  </AppProviders>,
)
