export enum ReceptorStatusLabels {
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
}

export const SCAN_RECEPTOR_INTERVAL = 30000

export const RECEPTOR_STATUS_FILTER = [
  {
    value: ReceptorStatusLabels.ACTIVE,
    label: ReceptorStatusLabels.ACTIVE,
  },
  {
    value: ReceptorStatusLabels.DEACTIVATED,
    label: ReceptorStatusLabels.DEACTIVATED,
  },
]

export const RECEPTORS_HEADERS = Object.freeze({
  NAME: 'Name',
  ACTIVATED: 'Activated',
  CREDENTIALS_VALID: 'Credentials Valid',
})

export const SERVICE_ACCOUNT_RECEPTOR_MODEL_IDS = ['trr-aws']

export const NON_INDEX_RECEPTOR_MODEL_IDS = ['trr-anecdotes']
