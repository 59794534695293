import {
  Control,
  CONTROL_STATUS,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import React from 'react'
import { DecoratedDropdown } from 'src/components/Reusable/DecoratedDropdown/DecoratedDropdown'
import { KeyedMutator } from 'swr'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useGrpcRevalidateByMethod } from 'src/components/async'
import { PERMISSIONS } from 'src/config/roleConfig'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { ControlStatusRequest } from '@trustero/trustero-api-web/lib/model/model_pb'
import isFunction from 'lodash/isFunction'
import {
  ControlStatusDropdownOption,
  ControlStatusDropdownOptions,
} from './Dropdowns.constants'

export const ControlStatusDropdown = ({
  selected,
  setSelected,
  filteredOptions,
  isDisabled,
  requiredPermissions,
}: {
  selected: CONTROL_STATUS
  setSelected: (v: CONTROL_STATUS) => void
  filteredOptions: ControlStatusDropdownOption[]
  isDisabled?: boolean
  requiredPermissions?: PERMISSIONS[]
}): JSX.Element => (
  <DecoratedDropdown<CONTROL_STATUS>
    selected={selected}
    options={ControlStatusDropdownOptions}
    filteredDisplayOptions={filteredOptions}
    setSelected={setSelected}
    isDisabled={isDisabled}
    requiredPermissions={requiredPermissions}
  />
)

export const ControlStatusDropdownWithUpdate = ({
  control,
  mutate,
}: {
  control: Control.AsObject
  mutate: KeyedMutator<Control>
}): JSX.Element => {
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const grpcRevalidateByMethod = useGrpcRevalidateByMethod()
  const displayOptions = ControlStatusDropdownOptions.filter(
    (option) => option.key !== CONTROL_STATUS.NOTAPPLICABLE,
  )

  const updateControlStatus = async (newStatus: CONTROL_STATUS) => {
    const identifierMsg = new Identifier()
      .setModelid(control.modelId)
      .setModeltype(MODEL_TYPE.CONTROL)
    const controlStatusMsg = new ControlStatusRequest()
      .setId(identifierMsg)
      .setStatus(newStatus as CONTROL_STATUS)
    await modelClient.setControlStatus(controlStatusMsg)
    if (isFunction(mutate)) {
      await mutate()
    }
    await grpcRevalidateByMethod(ModelPromiseClient.prototype.getControlStats)
  }
  return (
    <ControlStatusDropdown
      selected={control.status}
      filteredOptions={displayOptions}
      setSelected={updateControlStatus}
      requiredPermissions={[PERMISSIONS.AUDITOR_ACTION]}
    />
  )
}
