import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type ContextType = {
  activeQuestionnaireId: string
  setActiveQuestionnaireId: (() => void) | Dispatch<SetStateAction<string>>
}

const AnswerQuestionContext = createContext<ContextType>({
  activeQuestionnaireId: '',
  setActiveQuestionnaireId: () => undefined,
})

export const useAnswerQuestionQuestionnaire = (): ContextType => {
  return useContext(AnswerQuestionContext)
}

type Props = {
  children: React.ReactNode
}

export const AnswerQuestionContextProvider = ({
  children,
}: Props): JSX.Element => {
  const [activeQuestionnaireId, setActiveQuestionnaireId] = useState<string>('')

  return (
    <AnswerQuestionContext.Provider
      value={{
        activeQuestionnaireId,
        setActiveQuestionnaireId,
      }}
    >
      {children}
    </AnswerQuestionContext.Provider>
  )
}
