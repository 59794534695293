import React from 'react'
import { UserRoleBanner } from './UserRoleBanner'
import { InPastAuditBanner } from './InPastAuditBanner'
import { AccountPurposeBanner } from './AccountPurposeBanner'

export const HeaderBanner = ({
  noMargin,
  mb = 0,
}: {
  mb?: number
  noMargin?: boolean
}): JSX.Element => (
  <div style={{ marginBottom: `${mb}px` }}>
    <UserRoleBanner noMargin={noMargin} />
    <InPastAuditBanner noMargin={noMargin} />
    <AccountPurposeBanner noMargin={noMargin} />
  </div>
)
