import styled from 'styled-components/macro'
import BootstrapNav from 'react-bootstrap/Nav'
import BootstrapContainer from 'react-bootstrap/Container'
import BootstrapRow from 'react-bootstrap/Row'
import BootstrapTab from 'react-bootstrap/Tab'
import { color, compose, grid, textStyle, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const TabContainer = styled(BootstrapContainer)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      defaultValidatorFn(prop) || prop === 'fluid',
  })
  .attrs({ fluid: true })`
  grid-column: 1 / -1;
  ${grid}
  margin: 0;
  padding: 0;
`

export const TabRow = styled(BootstrapRow)`
  margin: 0;
`

export const TabNav = styled(BootstrapNav).attrs({
  variant: 'tabs',
})`
  flex-grow: 1;
  padding-left: 12px;
  border-bottom: 1px solid #acb3c3;
  margin-bottom: 12px;
`

export const TabTitle = styled(BootstrapNav.Link)`
  &.nav-link {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0;
    padding-left: ${themeGet('space.m')}px;
    padding-right: ${themeGet('space.m')}px;
    border: none;
    border-bottom: 4px solid transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: ${themeGet('colors.text.med')};

    ${themeGet('textStyles.subtitle.1')}
    :hover {
      background-color: ${themeGet('colors.bg.primary')};
      border-bottom: 4px solid ${themeGet('colors.border.primary.base')};
    }

    &.active {
      border: none;
      border-bottom: 4px solid ${themeGet('colors.border.primary.dark')};
      color: ${themeGet('colors.text.default')};
      font-weight: ${themeGet('fontWeights.semiBold')};
    }

    .tab-title {
      ${typography}
    }
  }
`

export const TabContent = styled(BootstrapTab.Content)`
  flex-grow: 1;
  margin: 0;
  padding: 0;
`

export const TabNote = styled.div.attrs({
  py: 'l',
  color: 'fill.quaternary.dark',
  textStyle: 'link.2',
})`
  ${compose(color, textStyle)}
  display: flex;
  align-items: center;
  min-height: 48px;
  border-radius: 2px;

  && ul {
    margin: 0;
    padding-left: 25px;
  }
`

export const TabNavItem = styled(TabNav.Item)`
  cursor: pointer;
`

export const TabSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
