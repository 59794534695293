import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import isFunction from 'lodash/isFunction'
import { useLocation } from 'react-router-dom'
import { ModalFormId } from '../components/ModalForms/ModalForm'

type ModalStateContextType = {
  openModal: (modalId: ModalFormId | null) => void
  softOpenModal: (modalId: ModalFormId | null) => void
  closeModal: (modalId?: ModalFormId) => void
  activeModal: ModalFormId | null
}

const ModalStateContext = createContext<ModalStateContextType>({
  openModal: () => undefined,
  softOpenModal: () => undefined,
  closeModal: () => undefined,
  activeModal: null,
})

/**
 * `useModalState` is a custom hook that returns the ModalContext to provide access to methods and values for managing modal states.
 * @returns {ModalStateContextType} The ModalContext object.
 */
export const useModalState = (): ModalStateContextType => {
  return useContext(ModalStateContext)
}

type ModalStateProviderProps = {
  children: React.ReactNode
}

/**
 * `ModalStateProvider` is a context provider component that wraps the application to manage the modal states.
 * @param {ModalStateProviderProps} props - The properties of the ModalProvider component.
 * @returns {JSX.Element} A React context provider component.
 */
export const ModalStateProvider = ({
  children,
}: ModalStateProviderProps): JSX.Element => {
  const location = useLocation()
  const [activeModal, setActiveModal] = useState<ModalFormId | null>(null)

  const openModal = (modalId: ModalFormId | null) => {
    setActiveModal(modalId)
  }

  // only open the modal IF no modal is open currently
  const softOpenModal = (modalId: ModalFormId | null) => {
    setActiveModal((prev) => (!prev ? modalId : prev))
  }

  const closeModal = (modalId?: ModalFormId) => {
    if (!modalId) {
      setActiveModal(null)
    } else if (activeModal === modalId) {
      setActiveModal(null)
    }
  }

  useEffect(() => {
    setActiveModal(null) // Clear the modal state when the route changes
  }, [location])

  return (
    <ModalStateContext.Provider
      value={{
        openModal,
        closeModal,
        activeModal,
        softOpenModal,
      }}
    >
      {children}
    </ModalStateContext.Provider>
  )
}

/**
 * `useSetActiveModal` is a custom hook that returns a function to open a specific modal.
 *
 * @param {ModalFormId} modalId - The ID of the modal to open.
 * @returns {Function} A function that can be called to open the specified modal.
 */
export const useSetActiveModal = (modalId?: ModalFormId): (() => void) => {
  const { openModal } = useModalState()
  return useCallback(() => openModal(modalId || null), [openModal, modalId])
}

/**
 * `useSoftSetActiveModal` is a custom hook that returns a function to open a specific modal.
 *
 * @param {ModalFormId} modalId - The ID of the modal to open.
 * @returns {Function} A function that can be called to open the specified modal.
 */
export const useSoftSetActiveModal = (modalId?: ModalFormId): (() => void) => {
  const { softOpenModal } = useModalState()
  return useCallback(
    () => softOpenModal(modalId || null),
    [softOpenModal, modalId],
  )
}

type UseHideModalProps = {
  modalId?: ModalFormId
  onHide?: () => void
}

/**
 * `useHideModal` is a custom hook that returns a function to close a modal.
 * If `modalId` is provided, it will close the specified modal, otherwise, it will close any active modal.
 *
 * @param {Object} options - The options object.
 * @param {ModalFormId} [options.modalId] - The ID of the modal to close. If not provided, it will close any active modal.
 * @param {Function} [options.onHide] - Optional callback function to be executed before closing the modal.
 * @returns {Function} A function that can be called to close the modal(s).
 */
export const useHideModal = ({
  modalId,
  onHide,
}: UseHideModalProps): (() => void) => {
  const { closeModal } = useModalState()

  return useCallback(() => {
    isFunction(onHide) && onHide()
    closeModal(modalId)
  }, [onHide, closeModal, modalId])
}

/**
 * `useIsShowModal` is a custom hook that checks if a specific modal is currently active.
 *
 * @param {ModalFormId} modalFormId - The ID of the modal to check.
 * @returns {boolean} `true` if the specified modal is active, otherwise `false`.
 */
export const useIsShowModal = (
  modalFormId: ModalFormId | ModalFormId[],
): boolean => {
  const { activeModal } = useModalState()
  if (Array.isArray(modalFormId)) {
    return modalFormId.includes(activeModal as ModalFormId)
  }

  return activeModal === modalFormId
}
