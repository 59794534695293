import React from 'react'
import { RiskTierLabels } from 'src/pages/Vendors/vendors.constants'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

export const RiskTierFilterDropdown = (): JSX.Element => {
  const filterValues = Object.values(RiskTierLabels).map(
    (label: RiskTierLabels) => ({
      value: label,
      label: label,
    }),
  )

  return (
    <FilterDropdown
      filterParam={FilterParam.RISK_TIER}
      filterName={FilterName.RISK_TIER}
      filterValues={filterValues}
    />
  )
}
