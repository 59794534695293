import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'

export enum LoginProviders {
  GOOGLE = 'Google',
  AUTH0 = 'Auth0', //Auth0 was acquired by Okta
  OKTA = 'Okta',
  ONELOGIN = 'OneLogin',
  MICROSOFT = 'Microsoft',
  ENTRA = 'Entra',
}

export type AuthorizationComponentProps = {
  login: ({
    accessToken,
    accountId,
    email,
    useCase,
  }: {
    accessToken: string
    accountId?: string
    email?: string
    redirectUrl: string
    useCase: USE_CASE
    role: USER_ROLE
  }) => void
  setErrorMsg?: React.Dispatch<React.SetStateAction<string>>
  setIsRedirecting?: React.Dispatch<React.SetStateAction<boolean>>
}
