import React from 'react'
import { ServiceRoleLabels } from 'src/pages/Services/Services.constants'
import { FilterName, FilterParam } from '../FilterBar.types'
import { NONE_VALUE } from '../FilterBar.constants'
import { FilterDropdown } from './FilterDropdown'

export const ServiceRoleFilterDropdown = (): JSX.Element => {
  const filterValues = [
    NONE_VALUE,
    ...Object.values(ServiceRoleLabels).map((label: ServiceRoleLabels) => ({
      value: label,
      label: label,
    })),
  ]

  return (
    <FilterDropdown
      filterParam={FilterParam.SERVICE_ROLE}
      filterName={FilterName.SERVICE_ROLE}
      filterValues={filterValues}
    />
  )
}
