export const isDev = () =>
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test'

export const isTesting = () => window.location.hostname.includes('testing')

export const isStaging = () => window.location.hostname.includes('staging')

export const isProduction = () => window.location.hostname.includes('app')
