import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircleCheckFillSVG, ErrorSVG } from 'src/components/Icons/Basic'
import { ShowPageTitleWithIcon } from 'src/components/PageLayout/ShowPage'
import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { Grid, GridHeader, GridRow } from 'src/components/Reusable/Grid'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const ReceptorsGrid = styled(Grid).attrs({
  gridTemplateColumns: '1fr max-content max-content',
})`
  margin-top: 32px;

  .grid-column:not(:first-child) {
    padding: 16px;
  }

  .grid-column:first-child {
    padding: 16px 16px 16px 20px;
  }

  .grid-column:last-child {
    padding: 16px 20px 16px 16px;
  }
`

export const ReceptorNameContainer = styled.div.attrs({
  className: 'receptor-name-container',
})`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const InvalidCredentialsIcon = styled(ErrorSVG)`
  path {
    fill: ${palette.magenta[600]};
  }
`

export const ReceptorOkayIcon = styled(CircleCheckFillSVG)`
  margin-left: 2px;
`

export const InsufficientPermissionsIcon = styled(ErrorSVG)`
  path {
    fill: ${palette.orange[600]};
  }
`

export const ReceptorTitleWithIcon = styled(ShowPageTitleWithIcon)`
  gap: 8px;
`

export const ReceptorsDescriptionContainer = styled.div`
  display: flex;
  padding: 0px 20px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`

export const IconSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`

export const EditReceptorButton = styled(TextButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
`

export const EditReceptorIcon = styled(FontAwesomeIcon).attrs({
  icon: faPencil,
})`
  height: 16px;
  path {
    fill: ${palette.neutral[600]};
  }
`

export const StyledReceptorsGridRow = styled(GridRow)<{
  $isDeactivated?: boolean
}>`
  cursor: pointer;
  .grid-column {
    background-color: ${({ $isDeactivated }) =>
      $isDeactivated ? palette.neutral.white : 'bg.neutral'};
  }
  .receptor-name-container {
    opacity: ${({ $isDeactivated }) => ($isDeactivated ? 0.5 : 1)};
  }
`

export const StyledReceptorsGridHeader = styled(GridHeader).attrs<{
  isIndexPage?: boolean
}>(({ isIndexPage }) => ({
  top: isIndexPage ? headerHeight + filterBarHeight : 0,
}))<{ isIndexPage?: boolean }>`
  position: sticky;
  z-index: 1;
`

export const ScanReceptorsButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
