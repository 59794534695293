import React, { ChangeEventHandler, FormEventHandler } from 'react'
import {
  useHideModal,
  useIsShowModal,
  useModalState,
} from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from '../../../components/ModalForms'
import { MultiSelectForm } from '../../../components/Reusable/Forms/MultiSelectForm'
import { useServicesContext } from '../context/ServicesContext'
import { AddCustomServiceButtonComponent } from '../services.components'
import { getAddServicesMultiSelectProps } from '../services.helpers'

export const AddServicesModal = (): JSX.Element => {
  const { servicesMap, toggleSelectService, clearState, getNoneSelected } =
    useServicesContext()

  const { openModal } = useModalState()
  const show = useIsShowModal(ModalFormId.ADD_SERVICES)
  const hide = useHideModal({
    modalId: ModalFormId.ADD_SERVICES,
    onHide: () => clearState(),
  })

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const serviceId = e.currentTarget.value
    toggleSelectService(serviceId)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    openModal(ModalFormId.ADD_SERVICE_ROLES)
  }

  const services = Object.values(servicesMap)
    .map((serviceData) => serviceData.service)
    .sort((a, b) => a.name.localeCompare(b.name))

  const addServicesMultiSelectProps = getAddServicesMultiSelectProps(
    services,
    onSubmit,
    onChange,
  )
  const isDisabled = getNoneSelected()

  return (
    <>
      <ModalForm
        show={show}
        hide={hide}
        title="Add Services"
        submitText="Add Services"
        formId={ModalFormId.ADD_SERVICES}
        footerContent={<AddCustomServiceButtonComponent />}
        disableSubmitButton={isDisabled}
      >
        <MultiSelectForm {...addServicesMultiSelectProps} />
      </ModalForm>
    </>
  )
}
