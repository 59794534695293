import React from 'react'
import styled from 'styled-components/macro'
import { color, compose, margin, padding, textStyle } from 'styled-system'
import icon from './NoFilterResults.png'

const NoFilterResultsContainer = styled.div.attrs({
  p: 'xl',
  bg: 'bg.neutral',
})`
  ${compose(padding, color)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h5.attrs({
  mt: 'm',
  mb: 'xs',
  textStyle: 'header.4',
})`
  ${compose(margin, textStyle)}
`

const Note = styled.p.attrs({
  textStyle: 'body.small',
})`
  ${textStyle}
`
export const NoFilterResults = (): JSX.Element => {
  return (
    <NoFilterResultsContainer>
      <img src={icon} />
      <Title>{"Sorry, we couldn't find any matches for that"}</Title>
      <Note>Please try adjusting the filters</Note>
    </NoFilterResultsContainer>
  )
}
