import React from 'react'
import styled from 'styled-components/macro'
import { color, compose, fontSize, margin, textStyle } from 'styled-system'
import { ErrorSVG } from '../../Icons/Basic'

const MissingIcon = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
  mr: 'xxxs',
})`
  ${margin}
  width: 12px;
  height: 12px;
`
type TitleTextProps = {
  error?: boolean
}

export const TitleText = styled.span.attrs<TitleTextProps>((props) => ({
  color: props.error ? 'fill.status.error.dark' : 'text.default',
  textStyle: 'subtitle.caption',
  fontSize: props.error ? '12px' : '14px',
}))<TitleTextProps>`
  ${compose(color, textStyle, fontSize)}
`

type Props = {
  name: string
}

export function Title(props: Props): JSX.Element {
  const err = !props.name
  const noName = 'No Name'
  return (
    <>
      {err && <MissingIcon />}
      <TitleText error={err}>{err ? noName : props.name}</TitleText>
    </>
  )
}
