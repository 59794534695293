import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import { LogText } from '../Changelog.styles'

export const SubjectContainer = styled(LogText)`
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: ${themeGet('space.xxs')}px;
  }

  > :last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  overflow: hidden;
`
