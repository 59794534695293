import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const CostContainer = styled.div`
  width: fit-content;
  padding: 10px 12px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  font-color: ${palette.neutral[900]};
  background-color: ${palette.orange[30]};
  border-left: 4px solid ${palette.orange[400]};
`

export const CostDiv = styled.div`
  width: fit-content;
  margin-top: 10px;
  padding: 4px;
  font-size: 12px;
  font-color: ${palette.neutral[900]};
  background-color: ${palette.orange[30]};
`
