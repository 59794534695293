import React from 'react'
import { useLocation } from 'react-router-dom'
import log from 'loglevel'
import { NoResults } from 'src/components/PageLayout/NoResults'
import { NoFilterResults } from 'src/components/PageLayout/NoFilterResults'
import { NoServicesIcons } from 'src/components/Icons/EmptyStateIcons/NoServicesIcons'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { ModalFormId } from 'src/components/ModalForms'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import { useServices, useServicesRequest } from '../Services.hooks'
import { ServicesGrid } from '../grid/ServicesGrid'
import { AddServicesModal } from '../modals/AddServicesModal'
import { AddServiceRolesModal } from '../modals/AddServiceRolesModal'
import { ServicesProvider } from '../context/ServicesContext'
import { getActiveServices } from '../services.helpers'
import { CreateCustomServiceModal } from '../modals/CreateCustomServiceModal'

export const ServicesIndexGrid = (): JSX.Element => {
  const location = useLocation()
  const req = useServicesRequest()
  const { data, isLoading, error } = useServices(req)

  if (isLoading) {
    return <TableListLoader />
  } else if (error) {
    showInfoToast(
      "Sorry we couldn't load your services. Please refresh and try again.",
    )
    log.error('Error loading services', error)
    return <></>
  } else if (!data) {
    showInfoToast(
      "Sorry we couldn't load your services. Please refresh and try again.",
    )
    return <></>
  }

  const totalServices = data.getTotal()
  const services = data.getServicesList()
  const activeServices = getActiveServices(services, location.search)

  return (
    <>
      {totalServices === 0 ? (
        <NoResults
          icons={<NoServicesIcons />}
          title="Mark which services you use."
          buttons={
            <StandardOpenModalButton
              variant={StandardButtonVariant.PRIMARY}
              size={StandardButtonSize.MEDIUM}
              modalId={ModalFormId.ADD_SERVICES}
              text="Add Services"
            />
          }
          largeTitle={true}
        />
      ) : (
        <>
          {activeServices.length > 0 ? (
            <ServicesGrid services={activeServices} />
          ) : (
            <NoFilterResults />
          )}
        </>
      )}
      <ServicesProvider>
        <AddServicesModal />
        <AddServiceRolesModal />
        <CreateCustomServiceModal />
      </ServicesProvider>
    </>
  )
}
