import React from 'react'
import { Service } from 'src/xgenerated'
import { StyledServicesGrid } from '../services.styles'
import { ServicesGridHeader, ServicesGridRows } from './ServicesGrid.components'

export const ServicesGrid = ({
  services,
}: {
  services: Service[]
}): JSX.Element => {
  return (
    <StyledServicesGrid>
      <ServicesGridHeader />
      <ServicesGridRows services={services} />
    </StyledServicesGrid>
  )
}
