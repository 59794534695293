import { useCallback, useContext } from 'react'
import {
  CreateUpdateControlRequest,
  Control,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { AddControlsRequest } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { useInvalidateAuditReadinessCache } from 'src/pages/AuditBot/AuditBot.hooks'
import { AuditContext } from '../../../../../context/AuditContext'
import { useAuthorizedGrpcClient } from '../../../../../adapter'
import { useInvalidateControlsCache } from '../../../../../components/async/model/control/useControls'

export const useCreateCustomControl = (): ((
  request: CreateUpdateControlRequest,
) => Promise<Control>) => {
  const mutateControls = useInvalidateControlsCache()
  const mutateAuditReadiness = useInvalidateAuditReadinessCache()
  const { auditId } = useContext(AuditContext)
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const auditClient = useAuthorizedGrpcClient(AuditPromiseClient)

  return useCallback(
    async (request: CreateUpdateControlRequest) => {
      const control = await modelClient.createOrUpdateControl(request)
      if (auditId) {
        await auditClient.addControls(
          new AddControlsRequest()
            .setAuditIdsList([auditId])
            .setControlIdsList([control.getId()]),
        )
      }
      await Promise.all([mutateControls(), mutateAuditReadiness()])
      return control
    },
    [auditClient, auditId, modelClient, mutateAuditReadiness, mutateControls],
  )
}
