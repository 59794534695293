import React from 'react'
import { FormCheck, FormCheckProps } from 'react-bootstrap'
import styled from 'styled-components/macro'

const StyledRadioInput = styled(FormCheck).attrs({
  type: 'radio',
})`
  &.form-check {
    display: flex;
    align-items: center;
    padding: 0;

    &.form-check-inline {
      display: inline-flex;
    }

    .form-check-input {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      margin: 0;
      margin-right: 8px;
    }
  }
`
// Workaround for React 18 compatibility issues https://github.com/styled-components/styled-components/issues/3738
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioInputProxy: any = StyledRadioInput

/**
 * ! IMPORTANT DETAILS FOR THE `onChange` event handler
 * - DO NOT call the event's `preventDefault` method in the handler
 * - Doing so will cause issues when clicking the label
 *   - It may not properly check / uncheck the input
 */
export const RadioInput = (
  props: Omit<FormCheckProps, 'type'>,
): JSX.Element => {
  return <RadioInputProxy {...props} />
}
