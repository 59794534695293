import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import {
  MultiSelectDropdownCheckBox,
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
} from '../../../Reusable/MultiSelectDropdown'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '../../../Reusable/StandardDropdown/StandardDropdown.styles'
import { MenuPlaceholder } from '../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Reusable/Chips/BasicChip'
import { useComplianceFrameworks } from '../../../async/complianceframework/useComplianceFrameworks'

function ComplianceFrameworkItem({
  complianceFrameworkBase,
  onComplianceFrameworkSelected,
}: {
  complianceFrameworkBase: ComplianceFramework
  onComplianceFrameworkSelected: (
    framework: ComplianceFramework,
  ) => React.ChangeEventHandler<HTMLElement>
}) {
  return (
    <Dropdown.Item
      eventKey={complianceFrameworkBase.getId()}
      as={MultiSelectDropdownItem}
    >
      <MultiSelectDropdownCheckBoxLabel>
        <>
          <MultiSelectDropdownCheckBox
            id={complianceFrameworkBase.getId()}
            onChange={onComplianceFrameworkSelected(complianceFrameworkBase)}
          />
          {complianceFrameworkBase.getName()}
        </>
      </MultiSelectDropdownCheckBoxLabel>
    </Dropdown.Item>
  )
}

function ComplianceFrameworkBasesDropdownMenu({
  onComplianceFrameworkSelected,
}: {
  onComplianceFrameworkSelected: (
    complianceFramework: ComplianceFramework,
  ) => React.ChangeEventHandler<HTMLElement>
}): JSX.Element {
  const complianceFrameworks = useComplianceFrameworks()

  if (complianceFrameworks.loading) {
    return (
      <Dropdown.Menu as={StyledDropdownMenu}>
        <MenuPlaceholder height={96} />
      </Dropdown.Menu>
    )
  }

  return (
    <Dropdown.Menu as={StyledDropdownMenu}>
      {complianceFrameworks.data
        ?.getItemsList()
        ?.reduce((res: JSX.Element[], framework: ComplianceFramework) => {
          framework.getEnabled() &&
            res.push(
              <ComplianceFrameworkItem
                key={framework.getId()}
                complianceFrameworkBase={framework}
                onComplianceFrameworkSelected={onComplianceFrameworkSelected}
              />,
            )
          return res
        }, [])}
    </Dropdown.Menu>
  )
}

export function ComplianceFrameworkBasesDropdown({
  selectedComplianceFrameworks,
  onComplianceFrameworkSelected,
  showCount = false,
  defaultText = 'Select relevant compliance frameworks',
}: {
  selectedComplianceFrameworks: ComplianceFramework[]
  onComplianceFrameworkSelected: (
    complianceFramework: ComplianceFramework,
  ) => React.ChangeEventHandler<HTMLElement>
  showCount?: boolean
  defaultText?: string
}): JSX.Element {
  return (
    <Dropdown id="add-audit-form-frameworks-dropdown" drop="down">
      <Dropdown.Toggle as={StyledDropdownToggle} width="100%">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {selectedComplianceFrameworks
            .map((complianceFramework) => complianceFramework.getName())
            .join(', ') || defaultText}
          {showCount && (
            <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
              {selectedComplianceFrameworks.length}
            </CountChip>
          )}
        </div>
      </Dropdown.Toggle>
      <ComplianceFrameworkBasesDropdownMenu
        onComplianceFrameworkSelected={onComplianceFrameworkSelected}
      />
    </Dropdown>
  )
}
