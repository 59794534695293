import React, { useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { SignUpPage } from 'src/pages/SignUpPage/SignUpPage'
import { SIGN_UP_PROPS } from 'src/pages/SignUpPage/SignUpPage.constants'
import { AppContainer, AppContent } from '../globalStyles'
import { LoginPage } from '../pages/Login'
import SignupFlow from '../components/SignupFlow'
import { HelpAbsoluteRoutes } from '../pages/Help/HelpRoutes'
import { ServiceAbsoluteRoutes } from '../pages/Services'
import {
  PoliciesAbsoluteRoutes,
  ControlsAbsoluteRoutes,
  DirectRoutes,
} from '../components/Reusable/RootPage/RootPage.constants'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'

export const AppUnauth = (): JSX.Element => {
  return (
    <AppContainer className="App">
      <Helmet>
        <title>Trustero</title>
      </Helmet>
      <AppContent className="App-Content">
        <Routes>
          <Route path="login/*" element={<LoginPage />} />
          <Route path="register" element={<SignupFlow />}>
            <Route path=":callid/*" element={<SignupFlow />} />
          </Route>
          <Route
            path={`${DirectRoutes.GENERIC_SIGNUP}/*`}
            element={<SignUpPage {...SIGN_UP_PROPS.genericSignup} />}
          />
          <Route
            path={`${DirectRoutes.REPORT_SCAN_SIGNUP}/*`}
            element={<SignUpPage {...SIGN_UP_PROPS.reportScan} />}
          />
          <Route
            path={`${DirectRoutes.SECURITY_QUESTIONNAIRE_SIGNUP}/*`}
            element={<SignUpPage {...SIGN_UP_PROPS.securityQuestionnaire} />}
          />
          <Route path=":accountId/*" element={<ValidateRoute />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </AppContent>
    </AppContainer>
  )
}

const validRoutes = [
  HelpAbsoluteRoutes.ROOT as string,
  ServiceAbsoluteRoutes.ROOT as string,
  PoliciesAbsoluteRoutes.ROOT as string,
  ControlsAbsoluteRoutes.ROOT as string,
  DirectRoutes.USERS as string,
  DirectRoutes.PROFILE as string,
  DirectRoutes.DASHBOARD as string,
]

/**
 * Validate the current route, then redirect to the Login Page
 * - If the attempted route is VALID
 *   - Store the URL in the location state
 * - If the attempted route is INVALID
 *   - DO NOT store the URL
 */
const ValidateRoute = (): JSX.Element => {
  const location = useLocation()
  const isValidRoute = useMemo(() => {
    const [_empty, _accountId, route] = location.pathname.split('/')
    return !!route && validRoutes.includes(route)
  }, [location])

  return (
    <Navigate
      replace
      to="/login"
      state={isValidRoute ? { redirect: window.location.pathname } : undefined}
    />
  )
}
