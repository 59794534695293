// ** WARNING ** - Autogenerated file

export type TestDefinition = {
  id:           string
  name:         string
  description:  string
}

const DATA:TestDefinition[] = [

{
  id:          "trt0",
  name:        "Freshness Test",
  description: "The freshness test checks to see if evidence gather from the service has been collected recently.",
},
        
{
  id:          "trt1",
  name:        "Verify MFA Is Enabled",
  description: "Scans the settings automatically gathered from the service and flags any users which do not have MFA enabled.",
},
        
{
  id:          "trt2",
  name:        "Verify Non-Author Code Review",
  description: "Scans the settings automatically gathered from the service and flags any repos that do not have at least one non-author code reviewer.",
},
        
{
  id:          "trt3",
  name:        "Verify Source Code Security Scan",
  description: "Scans the settings automatically gathered from the service and flags any repos that do not have source code security scan/alerts active.",
},
        
{
  id:          "trt4",
  name:        "Verify Encryption of Data at Rest",
  description: "Scans the settings automatically gathered from databases and flag any that do not have encryption enabled.",
},
        ]

export default DATA
