import React, { useMemo } from 'react'
import queryString, { ParsedQuery } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { Framework } from '@trustero/trustero-api-web/lib/model/framework_pb'
import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { PERMISSIONS } from 'src/config/roleConfig'
import { getFilterValuesByFrameworkModelId } from '../FilterBar.types'
import { Spinner } from '../../../../../Throbber'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../Chips/BasicChip'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { useComplianceFrameworkObjectives } from '../../../../async/Framework/useComplianceFrameworkObjectives'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'

const FrameworkItem = ({
  objective,
  searchParams,
  appliedFilters,
  filterParam,
}: {
  objective: Framework
  searchParams: ParsedQuery
  appliedFilters: string[]
  filterParam: string
}): JSX.Element => {
  const navigate = useNavigate()

  const newAppliedFilters = new Set(appliedFilters)
  const isChecked = newAppliedFilters.has(objective.getModelId())
  if (isChecked) {
    newAppliedFilters.delete(objective.getModelId())
  } else {
    newAppliedFilters.add(objective.getModelId())
  }

  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...searchParams,
        [filterParam]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <FilterDropdownItem replace to={href}>
      <CheckBoxInput
        id={objective.getModelId()}
        label={objective.getName()}
        checked={isChecked}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={() => {
          navigate(href ?? '', { replace: true })
        }}
        tabIndex={-1}
        requiredPermissions={[PERMISSIONS.READ]}
      />
    </FilterDropdownItem>
  )
}

const FrameworkDropdownMenu = ({
  objectives,
  searchParams,
  appliedFilters,
  filterParam,
}: {
  objectives: Framework[]
  searchParams: ParsedQuery
  appliedFilters: string[]
  filterParam: string
}): JSX.Element => {
  const noFramework = new Framework().setModelId(NONE_ID).setName(NONE_NAME)
  return (
    <Dropdown.Menu>
      <FrameworkItem
        objective={noFramework}
        searchParams={searchParams}
        appliedFilters={appliedFilters}
        filterParam={filterParam}
      />
      {objectives.map((objective) => (
        <FrameworkItem
          key={objective.getId()}
          objective={objective}
          searchParams={searchParams}
          appliedFilters={appliedFilters}
          filterParam={filterParam}
        />
      ))}
    </Dropdown.Menu>
  )
}

export const FrameworkFilterDropdown = ({
  complianceFramework,
}: {
  complianceFramework: ComplianceFramework
}): JSX.Element => {
  const location = useLocation()
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const filterValues = getFilterValuesByFrameworkModelId(
    complianceFramework.getModelId(),
  )

  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[filterValues.filterParam]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams, filterValues.filterParam])

  const { data: objectives, isLoading } = useComplianceFrameworkObjectives([
    complianceFramework.getId(),
  ])

  return (
    <Dropdown autoClose="outside">
      {!objectives || isLoading ? (
        <>
          <Dropdown.Toggle
            as={FilterDropdownToggle}
            id={`${filterValues.filterName}-filter-dropdown`}
          >
            <p>{filterValues.filterName}</p>
            <Spinner size="s" color="primary" mx="xxs" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuPlaceholder height={108} />
          </Dropdown.Menu>
        </>
      ) : (
        <>
          <Dropdown.Toggle
            as={FilterDropdownToggle}
            id={`${filterValues.filterName}-filter-dropdown`}
          >
            <p>{filterValues.filterName}</p>
            {appliedFilters.length > 0 && (
              <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
                {appliedFilters.length}
              </CountChip>
            )}
          </Dropdown.Toggle>
          <FrameworkDropdownMenu
            objectives={objectives.getItemsList()}
            searchParams={searchParams}
            appliedFilters={appliedFilters}
            filterParam={filterValues.filterParam}
          />
        </>
      )}
    </Dropdown>
  )
}
