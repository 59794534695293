import React from 'react'
import { TrusteroLabsLogo } from 'src/pages/ReportScan/ReportScan.components'
import { FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import { HeaderBanner } from 'src/components/Reusable/Banners/HeaderBanner'
import { TrusteroLabsRow } from '../PageLayout.styles'
import { AIShowPageDividerLine } from './showpage.styles'
import { ShowPageHeaderContainer } from './BaseComponents'

export const AIShowPageDivider = ({
  isTrusteroLabs,
}: {
  isTrusteroLabs?: boolean
}): JSX.Element => (
  <FlexColumn isFullWidth>
    <AIShowPageDividerLine />
    {isTrusteroLabs && (
      <TrusteroLabsRow>
        <TrusteroLabsLogo />
      </TrusteroLabsRow>
    )}
  </FlexColumn>
)

export const ShowPageHeader = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => (
  <ShowPageHeaderContainer>
    <HeaderBanner mb={20} />
    <FlexRow>{children}</FlexRow>
  </ShowPageHeaderContainer>
)
