import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Range } from 'react-date-range'
import log from 'loglevel'
import queryString, { ParsedQuery } from 'query-string'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getServiceTemplate } from 'src/xgenerated/service'
import {
  ExclusionsToMarkdown,
  generatePdfBlob,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { Evidence } from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { Markup } from 'src/components/Reusable/Text/Markup'
import {
  EvidenceGroup,
  ListEvidenceRequest,
  UpdateDocumentRequest,
  ListEvidenceGroupsRequest,
  Excluded,
  EVIDENCE_LINKAGE_TYPE,
  AddOrUpdateEvidenceGroupRequest,
  EvidenceGroupId,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { isViewable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { AutomaticEvidenceTable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.components'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { isEvidenceFile } from 'src/components/ModalForms/Evidence/evidenceUtils'
import { useFileTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import { debounce, isFunction } from 'lodash'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import { Tabs } from 'src/components/Reusable/Tabs'
import { useExclusions } from 'src/components/async/document/useDocument'
import {
  CloseButton,
  ImagePreview,
} from 'src/components/Reusable/Document/DocumentViewer.components'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { Spinner } from 'src/Throbber'
import { Gravatar } from 'src/components/Gravatar'
import { formatTimestamp } from 'src/Utils/formatDate'
import { showInfoToast, ToastPrompts } from 'src/Utils/helpers/toast'
import { MIME_TYPE, UrlFragments } from 'src/Utils/globalEnums'
import {
  DocumentRequest,
  ListDocumentRequestsRequest,
} from '@trustero/trustero-api-web/lib/request/request_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useDownloadEvidenceTable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.hooks'
import {
  EvidenceTabContainer,
  FileDownloadBtn,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.styles'
import { PERMISSIONS } from 'src/config/roleConfig'
import FileSaver from 'file-saver'
import { DownloadIcon } from 'src/components/Icons'
import {
  getIsMultiPartMixedMime,
  getPluralization,
  openInNewTab,
} from 'src/Utils/globalHelpers'
import { EyeIcon } from 'src/components/Icons/Basic/EyeIcon'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useModalState } from 'src/Modal/ModalStateContext'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { ModalFormId } from 'src/components/ModalForms'
import { useEvidenceResults } from 'src/components/async/suggestions'
import isString from 'lodash/isString'
import { SelectDropdown } from 'src/components/Reusable/SelectDropdown/SelectDropdown'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useInAudit } from 'src/context/AuditContext'
import { EvidenceAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import {
  ItemTitle,
  ShowPageSectionHeader,
} from 'src/components/PageLayout/ShowPage'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import {
  generatePermalink,
  linkWithSearch,
} from 'src/components/PageLayout/Permalink'
import { EditableShowPageTitle } from 'src/components/Reusable/ShowPage/Title/EditableShowPageTitle'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import { TooltipOverlayType } from 'src/components/Reusable/Tooltip/Tooltip'
import { TrusteroDateRange } from 'src/components/Reusable/TrusteroDateRange'
import { useInvalidateAudits } from 'src/components/async/model/audit/useAudit'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import utf8 from 'utf8'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import {
  ChangelogButton,
  DropdownToggleButton,
  PrimaryButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { useCurrentControl } from 'src/context/FormContext'
import { ExpandButtonRow } from 'src/components/Reusable/Document/DocumentViewer.styles'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import { Dropdown } from 'react-bootstrap'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { useUnlinkEvidence } from 'src/components/ModalForms/Evidence/useDeleteEvidence'
import { Changelog, InfoPanelContext } from 'src/InfoPanel'
import { TabPanelButtonGroup } from 'src/components/Reusable/Tabs/TabNotes/styles'
import { useDocumentRequests } from 'src/components/async/DocumentRequest'
import { useGrpcRevalidateByMethod } from 'src/components'
import { useCurrentAudit } from 'src/components/async/model/audit'
import { useHardEvidenceInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateEvidence'
import { Grid } from 'src/components/Reusable/Grid'
import { ReactComponent as VerticalEllipsesIcon } from '../../components/Icons/assets/vertical-ellipses-icon.svg'
import {
  CopyLink,
  ViewDetails,
} from '../Controls/IndexItem/ControlsListItemMenu/ControlsListItemMenuComponents'
import { ControlListItemConfig } from '../Controls/IndexItem/ControlListItem.constants'
import { getControlsListItemConfig } from '../Controls/IndexItem/ControlsListItem.helpers'
import { ControlsListItem } from '../Controls/IndexItem/ControlsListItem'
import { REQUEST_GRID_LOCATIONS } from '../Requests/RequestsIndexPage/RequestsIndexPage.constants'
import { ControlsIndexGridHeader } from '../Controls/ControlsIndexPage/ControlsIndexGrid/ControlsIndexGridHeader'
import { RequestsItem } from '../Requests/components/RequestsItem'
import { RequestsIndexGridHeader } from '../Requests/RequestsIndexPage/RequestsIndexGridHeader'
import {
  EvidenceDropdownContainer,
  EvidenceFileInfoContainer,
  EvidenceFileName,
  EvidenceShowPageIcon,
  EvidenceShowTabBody,
  ManualEvidenceContainer,
  PropertiesGrid,
  PropertiesLabel,
  PropertiesRow,
  PropertiesValue,
  RelevantDateButton,
  EvidenceShowTabContainer,
  StyledAutomatedEvidenceModal,
  StyledAutomatedEvidenceBody,
  EvidenceNoResultsTitle,
  EvidenceNoResultsIcon,
  EvidenceNoResultsContainer,
} from './evidence.styles'
import {
  useEvidenceAudits,
  useEvidenceConfig,
  useEvidenceGroup,
  useEvidenceGroupControlIds,
  useEvidenceGroupDocIds,
  useEvidenceGroups,
  useEvidenceLink,
  useInvalidateEvidenceGroups,
  useUpdateEvidence,
} from './evidence.hooks'
import { ShowEvidenceAuditsModal } from './modals/ShowEvidenceAuditsModal'
import {
  generateEvidencePdfWithExclusions,
  getControlIdForEvidence,
  getEvidenceLink,
  getEvidenceVersionDropdownOptions,
  getRequestIdForEvidence,
  getShowLatestFromQueryParams,
} from './evidence.helpers'
import { EVIDENCE_TABS } from './evidence.constants'
import { LinkEvidenceToControlsModal } from './modals/LinkEvidenceToControlsModal'
import {
  CurrentEvidenceGroupProvider,
  useCurrentEvidenceGroup,
} from './context/CurrentEvidenceGroupContext'
import { LinkEvidenceToRequestsModal } from './modals/LinkEvidenceToRequestsModal'

export const AutomatedEvidenceIcon = (): JSX.Element => {
  return <EvidenceShowPageIcon icon={faWandMagicSparkles} />
}

export const EvidenceAuditsRow = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const { data, isLoading, error } = useEvidenceAudits({
    caption: evidence.getCaption(),
    contentId: evidence.getContentId(),
    discoveryId: evidence.getDiscoveryId(),
    relevantDate: evidence.getRelevantDate() || new Timestamp(),
  })
  const { openModal } = useModalState()
  if (isLoading) {
    return <Spinner size="l" color="primary" />
  } else if (!data) {
    if (error) {
      log.error('Error loading evidence audits', error)
    }
    return <></>
  }

  const audits = data.getItemsList()

  return (
    <>
      <PropertiesRow>
        <PropertiesLabel>Audits</PropertiesLabel>
        <PropertiesValue>
          <FlexRow gap={5}>
            <EyeIcon />
            <TextButton onClick={() => openModal(ModalFormId.EVIDENCE_AUDITS)}>
              {`${audits.length} ${getPluralization('audit', audits.length)}`}
            </TextButton>
          </FlexRow>
        </PropertiesValue>
      </PropertiesRow>
      <ShowEvidenceAuditsModal audits={audits} />
    </>
  )
}

export const EvidenceAddedByRow = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const actorEmail = evidence.getActorEmail()
  const isAutomated = evidence.getIsAutomated()

  return (
    <PropertiesRow>
      <PropertiesLabel>Added By</PropertiesLabel>
      <PropertiesValue>
        {isAutomated ? (
          <FlexRow gap={5}>
            <AutomatedEvidenceIcon />
            Receptor
          </FlexRow>
        ) : (
          <Gravatar email={actorEmail} size={20} />
        )}
      </PropertiesValue>
    </PropertiesRow>
  )
}

export const EvidenceRelevantDateComponent = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const mutate = useInvalidateAudits()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const relevantDate = useMemo(() => {
    const relevantDateSeconds = evidence.getRelevantDate()?.getSeconds() || 0
    return new Date(relevantDateSeconds * 1000)
  }, [evidence])
  const [relevantDateAsRange, setRelevantDateAsRange] = useState<Range>({
    startDate: new Date(),
    endDate: relevantDate,
  })

  const updateRelevantDate = useMemo(() => {
    return debounce(async () => {
      try {
        const updateRequest = new UpdateDocumentRequest()
          .setId(evidence.getId())
          .setRelevantDate(
            Timestamp.fromDate(relevantDateAsRange.endDate as Date),
          )

        await attachmentClient.updateDocument(updateRequest)
        await mutate()
      } catch (e) {
        log.error(`Error updating relevant date`, e)
        showInfoToast(
          'Sorry, there was an error saving your changes. Please try again later.',
        )
      }
    }, 1000)
  }, [evidence, relevantDateAsRange, attachmentClient, mutate])

  useEffect(() => {
    if (relevantDate && relevantDate !== relevantDateAsRange.endDate) {
      updateRelevantDate()
    }
  }, [relevantDate, relevantDateAsRange, updateRelevantDate])

  return (
    <TrusteroDateRange
      single
      range={relevantDateAsRange}
      rangeSet={setRelevantDateAsRange}
    />
  )
}

export const EvidenceRelevantDateRow = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const hasRelevantDateForm = IsGatekeeperEnabled(
    GatekeeperFlag.RELEVANT_DATE_FORM,
  )
  const relevantDate = evidence.getRelevantDate()

  if (!relevantDate) return <></>

  return (
    <PropertiesRow>
      <PropertiesLabel>
        <FlexRow gap={8}>
          Relevant Date
          <Tooltip
            id="relevant-date-tooltip"
            overlayType={TooltipOverlayType.popover}
            tooltipBody="Relevant date that the evidence addresses. If your evidence covers a date range, use the end date."
          >
            <InfoButton />
          </Tooltip>
        </FlexRow>
      </PropertiesLabel>
      <PropertiesValue>
        {hasRelevantDateForm ? (
          <EvidenceRelevantDateButton
            evidenceId={evidence.getId()}
            caption={evidence.getCaption()}
            contentId={evidence.getContentId()}
            discoveryId={evidence.getDiscoveryId()}
            relevantDate={relevantDate}
            isEvidenceGroup
          />
        ) : (
          <EvidenceRelevantDateComponent evidence={evidence} />
        )}
      </PropertiesValue>
    </PropertiesRow>
  )
}

export const EvidencePropertiesBody = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const isManual = !evidence.getIsAutomated()
  const createdAt = evidence.getCreatedAt() || new Timestamp()
  const createdAtVerb = isManual ? 'Uploaded' : 'Created'

  return (
    <EvidenceShowTabBody>
      <PropertiesGrid>
        <EvidenceAddedByRow evidence={evidence} />
        <EvidenceAuditsRow evidence={evidence} />
        {isManual && <EvidenceRelevantDateRow evidence={evidence} />}
        <PropertiesRow>
          <PropertiesLabel>{`${createdAtVerb} Date`}</PropertiesLabel>
          <PropertiesValue>{formatTimestamp(createdAt)}</PropertiesValue>
        </PropertiesRow>
      </PropertiesGrid>
    </EvidenceShowTabBody>
  )
}

export const AutomaticEvidenceBody = ({
  body,
}: {
  body?: Uint8Array
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen)
  }
  if (!body) return <></>
  const evidence: Evidence = Evidence.deserializeBinary(body)
  return (
    <>
      <ExpandButtonRow>
        <TextButton onClick={toggleModal}>Expand</TextButton>
      </ExpandButtonRow>
      <AutomaticEvidenceTable evidence={evidence} />
      {isModalOpen && (
        <StyledAutomatedEvidenceModal onClick={toggleModal}>
          <StyledAutomatedEvidenceBody>
            <AutomaticEvidenceTable evidence={evidence} />
          </StyledAutomatedEvidenceBody>
          <CloseButton />
        </StyledAutomatedEvidenceModal>
      )}
    </>
  )
}

export const EvidenceDocumentFooter = ({
  evidenceId,
  contentId,
  mime,
  caption,
  exclusions,
  body,
  isAutomated,
}: {
  evidenceId: string
  contentId: string
  mime: string
  caption: string
  exclusions?: Excluded[]
  body: Uint8Array | string | null
  isAutomated: boolean
}): JSX.Element => {
  const downloadEvidenceTable = useDownloadEvidenceTable({
    contentId,
    mime,
  })
  const { data: exclusionsData } = useExclusions(evidenceId, !exclusions)
  const isLink = mime === MIME_TYPE.TEXT_URI_LIST

  const downloadFile = async (): Promise<void> => {
    if (body) {
      const material = documentBodyAsFile(body, caption, mime)
      FileSaver.saveAs(material)
    }
  }
  const exclusionsList = useMemo(() => {
    if (exclusions) {
      return exclusions
    }
    return exclusionsData?.getExclusions()?.getExclusionsList() || []
  }, [exclusions, exclusionsData])

  const downloadEvidencePdf = async (): Promise<void> => {
    if (!body || !exclusionsData) return
    const pdfContent = await generateEvidencePdfWithExclusions(
      body,
      caption,
      exclusionsList,
    )
    const pdfBlob = await generatePdfBlob(pdfContent)
    FileSaver.saveAs(pdfBlob, `${caption}.pdf`)
  }

  return (
    <FlexRow justify={FlexAlign.FLEX_START} $mt={10} gap={16}>
      {!isAutomated ? (
        <>
          {isLink ? (
            <></>
          ) : (
            <FileDownloadBtn
              onClick={downloadFile}
              requiredPermissions={[PERMISSIONS.EXPORT]}
            >
              <DownloadIcon height={14} width={14} fontSize={14} />
              <span>Download File</span>
            </FileDownloadBtn>
          )}
        </>
      ) : (
        <>
          <FileDownloadBtn
            onClick={downloadEvidenceTable}
            requiredPermissions={[PERMISSIONS.EXPORT]}
          >
            <DownloadIcon height={14} width={14} fontSize={14} />
            <span>Download Table</span>
          </FileDownloadBtn>
          <FileDownloadBtn
            onClick={downloadEvidencePdf}
            requiredPermissions={[PERMISSIONS.EXPORT]}
          >
            <DownloadIcon height={14} width={14} fontSize={14} />
            <span>Download All Evidence</span>
          </FileDownloadBtn>
        </>
      )}
    </FlexRow>
  )
}

export const EvidenceBody = ({
  evidenceId,
  contentId,
  body,
  caption,
  mimeType,
  isAutomated,
}: {
  evidenceId: string
  contentId: string
  body: string | Uint8Array | null
  caption: string
  mimeType: string
  isAutomated: boolean
}): JSX.Element => {
  const [file, setFile] = useState<File | null>(null)
  useEffect(() => {
    if (body && mimeType) {
      const file = documentBodyAsFile(body, caption, mimeType)
      if (isViewable(file.type)) {
        setFile(file)
      }
    }
  }, [body, setFile, mimeType, caption])

  const showAutomated = isAutomated && body instanceof Uint8Array

  const isFile = isEvidenceFile(mimeType)

  return (
    <>
      {body ? (
        <EvidenceShowTabContainer>
          {showAutomated ? (
            <AutomaticEvidenceBody body={body} />
          ) : (
            <>
              {isFile && (
                // avoid allowing file preview click if not ready/available
                <>
                  {file ? (
                    <ImagePreview file={file} isEvidenceShowPage />
                  ) : (
                    <TableListLoader />
                  )}
                </>
              )}
            </>
          )}
          <EvidenceDocumentFooter
            evidenceId={evidenceId}
            contentId={contentId}
            mime={mimeType}
            caption={caption}
            body={body}
            isAutomated={isAutomated}
          />
        </EvidenceShowTabContainer>
      ) : (
        <TableListLoader />
      )}
    </>
  )
}

export const EvidenceExcludedBody = ({
  evidenceId,
  exclusions,
}: {
  evidenceId: string
  exclusions?: Excluded[]
}): JSX.Element => {
  const { data, isLoading } = useExclusions(evidenceId, !exclusions)
  const getServiceName = useCallback(
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
    [],
  )

  const exclusionBody = useMemo(() => {
    if (exclusions) {
      return ExclusionsToMarkdown(exclusions, getServiceName)
    }
    if (isLoading) {
      return 'Loading...'
    }
    const exclusionsList = data?.getExclusions()?.getExclusionsList() || []
    return ExclusionsToMarkdown(exclusionsList, getServiceName)
  }, [data, getServiceName, isLoading, exclusions])

  return (
    <EvidenceShowTabBody>
      <Markup markdown={exclusionBody} />
    </EvidenceShowTabBody>
  )
}

export const EvidenceSourceBody = ({
  sourcesMarkdown,
}: {
  sourcesMarkdown: string
}): JSX.Element => (
  <EvidenceShowTabBody>
    <Markup markdown={sourcesMarkdown} />
  </EvidenceShowTabBody>
)

const ControlsTabNote = (): JSX.Element => {
  const { openModal } = useModalState()
  return (
    <TabPanelButtonGroup>
      <PrimaryButton
        size={StandardButtonSize.SMALL}
        requiredPermissions={[PERMISSIONS.EDIT]}
        text="Link to Controls"
        onClick={() => openModal(ModalFormId.LINK_EVIDENCE_TO_CONTROLS)}
      />
    </TabPanelButtonGroup>
  )
}

const DocumentRequestsTabNote = (): JSX.Element => {
  const { openModal } = useModalState()
  return (
    <TabPanelButtonGroup>
      <PrimaryButton
        size={StandardButtonSize.SMALL}
        requiredPermissions={[PERMISSIONS.EDIT]}
        text="Link to Requests"
        onClick={() => openModal(ModalFormId.LINK_EVIDENCE_TO_REQUESTS)}
      />
    </TabPanelButtonGroup>
  )
}

export const EvidenceControlsBody = ({
  evidence,
  body,
  controlIds,
  viaRequestIds,
  isLoading,
}: {
  evidence: EvidenceGroup
  body: Uint8Array | string | null
  controlIds: string[]
  viaRequestIds: string[]
  isLoading: boolean
}): JSX.Element => {
  const { data: audit } = useCurrentAudit()
  const [requests, setRequests] = useState<AddOrUpdateEvidenceGroupRequest[]>(
    [],
  )
  const { setCurrentEvidenceGroup } = useCurrentEvidenceGroup()

  useEffect(() => {
    const request = new AddOrUpdateEvidenceGroupRequest().setEvidenceGroup(
      evidence,
    )
    const bodyBytes =
      body instanceof Uint8Array
        ? body
        : new Uint8Array(
            isString(body)
              ? Array.from(utf8.encode(body.trim())).map((s) => s.charCodeAt(0))
              : [],
          )

    request.setBody(bodyBytes)
    setRequests([request])
    setCurrentEvidenceGroup(evidence)
  }, [evidence, body, setCurrentEvidenceGroup])

  const allControlIds = useMemo(() => {
    return Array.from(new Set([...controlIds, ...viaRequestIds]))
  }, [controlIds, viaRequestIds])

  const evidenceConfig = ControlListItemConfig.EVIDENCE
  const { template } = getControlsListItemConfig(evidenceConfig)

  return isLoading ? (
    <TableListLoader />
  ) : (
    <>
      {allControlIds.length ? (
        <Grid pb="xs" gridTemplateColumns={template}>
          <ControlsIndexGridHeader config={evidenceConfig} />
          {allControlIds.map((id) => {
            const isViaRequest = viaRequestIds.includes(id)
            return (
              <ControlsListItem
                key={id}
                controlModelId={id}
                audit={audit}
                config={
                  isViaRequest
                    ? ControlListItemConfig.EVIDENCE_VIA_REQUEST
                    : evidenceConfig
                }
              />
            )
          })}
        </Grid>
      ) : (
        <ControlsTabNoResults />
      )}
      <LinkEvidenceToControlsModal
        requests={requests}
        existingControlIds={controlIds}
      />
    </>
  )
}

export const ViewRequestDetails = ({
  requestId,
}: {
  requestId: string
}): JSX.Element => {
  const navigate = useNavigate()
  const { pageContext } = useParams()
  const onViewDetails = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      navigate(
        generatePermalink({
          pageContext: pageContext as string,
          modelId: requestId,
          modelType: MODEL_TYPE.REQUEST,
          isInternalLink: true,
        }),
      )
    },
    [requestId, navigate, pageContext],
  )

  return (
    <Dropdown.Item eventKey="1" as="button" onClick={onViewDetails}>
      View Details
    </Dropdown.Item>
  )
}

export const CopyRequestLink = ({
  requestId,
}: {
  requestId: string
}): JSX.Element => {
  const { pageContext } = useParams()
  const onCopyLink = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()
      await navigator.clipboard.writeText(
        generatePermalink({
          pageContext: pageContext as string,
          modelId: requestId,
          modelType: MODEL_TYPE.REQUEST,
          isInternalLink: false,
        }),
      )
    },
    [requestId, pageContext],
  )
  return (
    <Dropdown.Item eventKey="6" as="button" onClick={onCopyLink}>
      Copy Permalink
    </Dropdown.Item>
  )
}

export const UnlinkRequest = ({
  caption,
  contentId,
  discoveryId,
  requestId,
}: {
  caption: string
  contentId: string
  discoveryId: string
  requestId: string
}): JSX.Element => {
  const request = new ListDocumentRequestsRequest()
  const evidenceGroupId = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  discoveryId &&
    evidenceGroupId.setDiscoveryId(new StringValue().setValue(discoveryId))
  const mutateControls = useGrpcRevalidateByMethod()
  const { mutate } = useDocumentRequests({
    request,
    evidenceGroupId,
  })
  const mutateFunc = async () => {
    mutate()
    mutateControls(AttachmentPromiseClient.prototype.getEvidenceGroupControlIds)
  }
  const unlinkRequest = useUnlinkEvidence({
    caption: caption,
    contentId,
    discoveryId,
    linkageId: requestId,
    linkageType: EVIDENCE_LINKAGE_TYPE.REQUEST_LINK,
    mutateFunc,
  })

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    unlinkRequest()
  }

  return (
    <Dropdown.Item eventKey="2" as="button" onClick={onClick}>
      Unlink Request
    </Dropdown.Item>
  )
}

export const EvidenceRequestsItemMenu = ({
  evidenceGroup,
  requestId,
}: {
  evidenceGroup: EvidenceGroup
  requestId: string
}): JSX.Element => {
  const hasPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])
  if (!hasPermissions) {
    return <></>
  }

  const caption = evidenceGroup.getCaption()
  const contentId = evidenceGroup.getContentId()
  const discoveryId = evidenceGroup.getDiscoveryId()

  return (
    <Dropdown id={`evidence-requests-index-menu-${requestId}`}>
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ViewRequestDetails requestId={requestId} />
        <UnlinkRequest
          caption={caption}
          contentId={contentId}
          discoveryId={discoveryId}
          requestId={requestId}
        />
        <CopyRequestLink requestId={requestId} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const EvidenceDocumentRequestsBody = ({
  evidence,
  requests,
  isLoading,
  body,
}: {
  evidence: EvidenceGroup
  requests: DocumentRequest[]
  isLoading: boolean
  body: Uint8Array | string | null
}): JSX.Element => {
  const [reqs, setReqs] = useState<AddOrUpdateEvidenceGroupRequest[]>([])
  const { setCurrentEvidenceGroup } = useCurrentEvidenceGroup()
  const currentRequestIds = useMemo(
    () => requests.map((r) => r.getId()),
    [requests],
  )
  useEffect(() => {
    const request = new AddOrUpdateEvidenceGroupRequest().setEvidenceGroup(
      evidence,
    )
    const bodyBytes =
      body instanceof Uint8Array
        ? body
        : new Uint8Array(
            isString(body)
              ? Array.from(utf8.encode(body.trim())).map((s) => s.charCodeAt(0))
              : [],
          )

    request.setBody(bodyBytes)
    setReqs([request])
    setCurrentEvidenceGroup(evidence)
  }, [evidence, body, setCurrentEvidenceGroup])

  return isLoading ? (
    <TableListLoader />
  ) : (
    <>
      {requests.length ? (
        <Grid
          gridTemplateColumns={`min-content 1fr repeat(6, auto)`}
          gridRowGap="8px"
        >
          <RequestsIndexGridHeader
            requests={requests}
            location={REQUEST_GRID_LOCATIONS.EVIDENCE_SHOW}
          />
          <>
            {requests.map((request) => (
              <RequestsItem
                key={request.getId()}
                request={request}
                fromEvidenceShowPage
                customMenu={
                  <EvidenceRequestsItemMenu
                    requestId={request.getId()}
                    evidenceGroup={evidence}
                  />
                }
              />
            ))}
          </>
        </Grid>
      ) : (
        <DocumentRequestsTabNoResults />
      )}
      <LinkEvidenceToRequestsModal
        reqs={reqs}
        existingRequestIds={currentRequestIds}
      />
    </>
  )
}

export const EvidenceDocumentSection = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => (
  <>
    <ManualEvidenceSection evidence={evidence} />
    <EvidenceTabs evidence={evidence} />
  </>
)

export const ControlsTabNoResults = (): JSX.Element => {
  const { openModal } = useModalState()
  return (
    <EvidenceNoResultsContainer>
      <EvidenceNoResultsTitle $isWarning>
        <EvidenceNoResultsIcon />
        This evidence isn&apos;t linked to any controls.
      </EvidenceNoResultsTitle>
      <PrimaryButton
        size={StandardButtonSize.MEDIUM}
        requiredPermissions={[PERMISSIONS.EDIT]}
        text="Link to Controls"
        onClick={() => {
          openModal(ModalFormId.LINK_EVIDENCE_TO_CONTROLS)
        }}
      />
    </EvidenceNoResultsContainer>
  )
}

export const DocumentRequestsTabNoResults = (): JSX.Element => {
  const { openModal } = useModalState()
  return (
    <EvidenceNoResultsContainer>
      <EvidenceNoResultsTitle>
        This evidence isn&apos;t linked to any requests.
      </EvidenceNoResultsTitle>
      <PrimaryButton
        size={StandardButtonSize.MEDIUM}
        requiredPermissions={[PERMISSIONS.EDIT]}
        text="Link to Requests"
        onClick={() => {
          openModal(ModalFormId.LINK_EVIDENCE_TO_REQUESTS)
        }}
      />
    </EvidenceNoResultsContainer>
  )
}

export const EvidenceTabs = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const mime = evidence.getMime()
  const { auditId } = useInAudit()
  const [mimeType, setMimeType] = useState<string>(mime)
  const [body, setBody] = useState<Uint8Array | string | null>(null)
  const [sourcesMarkdown, setSourcesMarkdown] = useState<string>('')
  const [isAutomated, setIsAutomated] = useState<boolean>(
    evidence.getIsAutomated(),
  )
  const [evidenceId, setEvidenceId] = useState<string>(evidence.getId())
  const contentId = evidence.getContentId()
  const caption = evidence.getCaption()
  const discoveryId = evidence.getDiscoveryId()
  const isMultipart = getIsMultiPartMixedMime(mime)
  const isEvidenceAutomated = evidence.getIsAutomated()
  const getEvidenceConfig = useEvidenceConfig(
    contentId,
    mime,
    isEvidenceAutomated,
    caption,
  )
  const { data: controlsData, isLoading: controlsLoading } =
    useEvidenceGroupControlIds(caption, contentId, discoveryId)
  const request = new ListDocumentRequestsRequest()
  const evidenceGroupId = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  discoveryId &&
    evidenceGroupId.setDiscoveryId(new StringValue().setValue(discoveryId))
  const { data: requestsData, isLoading: requestsLoading } =
    useDocumentRequests({
      request,
      evidenceGroupId,
    })

  const controlIds = useMemo(() => {
    if (controlsData) {
      return controlsData.getControlIdsList() || []
    }
    return []
  }, [controlsData])

  const viaRequestIds = useMemo(() => {
    if (controlsData) {
      return controlsData.getViaRequestIdsList() || []
    }
    return []
  }, [controlsData])

  const allControlIds = useMemo(() => {
    return [...controlIds, ...viaRequestIds]
  }, [controlIds, viaRequestIds])

  const requests = useMemo(() => {
    if (requestsData) {
      return requestsData.getItemsList() || []
    }
    return []
  }, [requestsData])

  useEffect(() => {
    if (isFunction(getEvidenceConfig)) {
      const handleConfig = async () => {
        try {
          const config = await getEvidenceConfig()
          if (!config) return
          setBody(config.body)
          setMimeType(config.mimeType)
          setSourcesMarkdown(config.sourcesMarkdown)
          setIsAutomated(config.isAutomated)
        } catch (err) {
          log.error(
            `Error downloading document in EvidenceTabs - id: ${evidence.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        } finally {
          setEvidenceId(evidence.getId())
        }
      }
      handleConfig()
    }
  }, [getEvidenceConfig, evidence])

  if (evidenceId !== evidence.getId()) {
    return <TableListLoader />
  }

  const includeEvidenceBody = isAutomated || isViewable(mimeType)

  const evidenceTab = {
    title: EVIDENCE_TABS.EVIDENCE,
    body: (
      <EvidenceBody
        evidenceId={evidence.getId()}
        contentId={contentId}
        body={body}
        caption={caption}
        mimeType={mimeType}
        isAutomated={isAutomated}
      />
    ),
  }

  const excludedTab = {
    title: EVIDENCE_TABS.EXCLUDED,
    body: (
      <EvidenceExcludedBody
        evidenceId={evidence.getId()}
        exclusions={evidence.getAllExclusions()?.getExclusionsList()}
      />
    ),
  }

  const sourceTab = {
    title: EVIDENCE_TABS.SOURCE,
    body: <EvidenceSourceBody sourcesMarkdown={sourcesMarkdown} />,
  }

  const controlsTab = {
    title: EVIDENCE_TABS.CONTROLS,
    note: allControlIds.length ? <ControlsTabNote /> : <></>,
    body: (
      <CurrentEvidenceGroupProvider>
        <EvidenceControlsBody
          evidence={evidence as EvidenceGroup}
          body={body}
          isLoading={controlsLoading}
          controlIds={controlIds}
          viaRequestIds={viaRequestIds}
        />
      </CurrentEvidenceGroupProvider>
    ),
    count: allControlIds.length,
  }

  const requestsTab = {
    title: EVIDENCE_TABS.REQUESTS,
    note: requests.length ? <DocumentRequestsTabNote /> : <></>,
    body: (
      <EvidenceDocumentRequestsBody
        evidence={evidence as EvidenceGroup}
        requests={requests}
        isLoading={requestsLoading}
        body={body}
      />
    ),
    count: requests.length,
  }

  const propertiesTab = {
    title: EVIDENCE_TABS.PROPERTIES,
    body: <EvidencePropertiesBody evidence={evidence} />,
  }

  const tabs = [propertiesTab]

  if (auditId) {
    tabs.unshift(requestsTab)
  }

  tabs.unshift(controlsTab)

  if (isAutomated || isMultipart) {
    tabs.unshift(sourceTab)
  }
  if (isAutomated) {
    tabs.unshift(excludedTab)
  }

  includeEvidenceBody && tabs.unshift(evidenceTab)

  return (
    <EvidenceTabContainer>
      <Tabs tabs={tabs} />
    </EvidenceTabContainer>
  )
}

export const EvidenceAuditRowItem = ({
  audit,
}: {
  audit: AuditRecord
}): JSX.Element => {
  const auditName = audit.getName()
  const startDate = audit.getStartDate()
  const endDate = audit.getEndDate()
  return (
    <li>
      {auditName}. {startDate ? formatTimestamp(startDate) : ''}
      {endDate ? `-${formatTimestamp(endDate)}` : ''}
    </li>
  )
}

export const EvidenceVersionDropdown = ({
  caption,
  relevantDate,
  controlId,
  requestId,
  fromControl,
  fromRequest,
}: {
  caption: string
  relevantDate: Timestamp
  controlId?: string
  requestId?: string
  fromControl?: boolean
  fromRequest?: boolean
}): JSX.Element => {
  const { auditId } = useInAudit()
  const { pageContext } = useParams()
  const navigate = useNavigate()
  const fromIndex = !fromControl && !fromRequest
  const request = useMemo(() => {
    const req = new ListEvidenceRequest().setCaption(
      new StringValue().setValue(caption),
    )
    controlId && req.setControlId(controlId)
    requestId && req.setDocumentRequestId(requestId)
    auditId && req.setAuditId(new StringValue().setValue(auditId))
    return req
  }, [caption, controlId, requestId, auditId])
  const groupReq = useMemo(() => {
    const req = new ListEvidenceGroupsRequest().setCaption(
      new StringValue().setValue(caption),
    )
    return req
  }, [caption])
  const { data, isLoading, error } = useEvidenceResults(request, !fromIndex)
  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
  } = useEvidenceGroups(groupReq, fromIndex)

  const isDataLoading = isLoading || groupsLoading
  const noData = (!data && !fromIndex) || (!groupsData && fromIndex)
  const errorOccured = error || groupsError

  const relevantDateString = formatTimestamp(
    relevantDate,
    DATE_FORMATS.ISO_WITH_TIME,
  )
  if (isDataLoading) {
    return <Spinner size="l" color="primary" />
  } else if (noData) {
    if (errorOccured) {
      error && log.error('Error loading evidence versions', error)
      groupsError && log.error('Error loading evidence groups', groupsError)
    }
    return <></>
  }
  const items = getEvidenceVersionDropdownOptions(fromIndex ? groupsData : data)

  const onSelectCb = (values: SelectItem[]): void => {
    const selectedId = values[0]?.value
    const versionLink = linkWithSearch(
      `/${pageContext}/${EvidenceAbsoluteRoutes.SHOW}/${encodeURIComponent(
        selectedId,
      )}`,
    )
    const fragment = fromControl
      ? `#${UrlFragments.CONTROLS}`
      : fromRequest
      ? `#${UrlFragments.REQUESTS}`
      : ''
    navigate(`${versionLink}${fragment}`)
  }

  return (
    <EvidenceDropdownContainer>
      <SelectDropdown
        label=""
        placeholder={relevantDateString}
        items={items}
        onSelectCb={onSelectCb}
        isDisabled={items.length < 2}
      />
    </EvidenceDropdownContainer>
  )
}

export const ManualEvidenceBody = ({
  body,
  isLink,
}: {
  body: string
  isLink: boolean
}): JSX.Element =>
  isLink ? (
    <TextButton
      onClick={(e) => {
        e.preventDefault()
        openInNewTab(body.trim())
      }}
    >
      {body.trim()}
    </TextButton>
  ) : (
    <Markup markdown={body} />
  )

export const EvidenceShowTitle = ({
  evidence,
  isAutomated,
}: {
  evidence: EvidenceGroup
  isAutomated: boolean
}): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const mutate = useInvalidateEvidenceGroups()
  const queryParams: ParsedQuery<string> = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  })
  const controlId = getControlIdForEvidence(queryParams)
  const requestId = getRequestIdForEvidence(queryParams)
  const showLatest = getShowLatestFromQueryParams(queryParams)
  const updateEvidence = useUpdateEvidence({
    evidence,
    isEvidenceGroup: true,
    requestId,
    shouldMutate: false,
  })
  const { pageContext } = useParams()
  const onUpdate = async (newCaption: string): Promise<void> => {
    try {
      const updatedId = await updateEvidence({ newCaption: newCaption })
      if (!updatedId || updatedId === evidence.getId()) return
      const link = getEvidenceLink(
        updatedId,
        location.search,
        showLatest,
        pageContext,
        controlId,
        requestId,
      )
      navigate(link)
      await mutate()
    } catch (e) {
      log.error(`Error updating evidence caption`, e)
      showInfoToast(
        'Sorry, there was an error saving your changes. Please try again later.',
      )
    }
  }

  const caption = evidence.getCaption()

  return isAutomated ? (
    <ItemTitle>{caption}</ItemTitle>
  ) : (
    <EditableShowPageTitle
      name={caption}
      onUpdate={onUpdate}
      modelType={MODEL_TYPE.EVIDENCE}
      fieldName="caption"
    />
  )
}

export const ManualEvidenceSection = ({
  evidence,
}: {
  evidence: EvidenceGroup
}): JSX.Element => {
  const contentId = evidence.getContentId()
  const mime = evidence.getMime()
  const [evidenceId, setEvidenceId] = useState<string>(evidence.getId())
  const [body, setBody] = useState<string | Uint8Array | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [mimeType, setMimeType] = useState<string>(mime)
  const [isAutomated, setIsAutomated] = useState<boolean>(false)
  const isEvidenceAutomated = evidence.getIsAutomated()
  const caption = evidence.getCaption()
  const getEvidenceConfig = useEvidenceConfig(
    contentId,
    mime,
    isEvidenceAutomated,
    caption,
  )
  const isFile = useMemo(() => isEvidenceFile(mimeType), [mimeType])
  const FileTypeIcon = useFileTypeIcon({ mime: mimeType })

  useEffect(() => {
    if (isFunction(getEvidenceConfig)) {
      const handleDocumentBody = async () => {
        try {
          const config = await getEvidenceConfig()
          if (!config) return
          setBody(config.body)
          setMimeType(config.mimeType)
          setIsAutomated(config.isAutomated)
          setFile(config.file)
        } catch (err) {
          log.error(
            `Error downloading document body in EvidenceTabs - id: ${evidence.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        } finally {
          setEvidenceId(evidence.getId())
        }
      }
      handleDocumentBody()
    }

    return () => {
      setBody(null)
    }
  }, [evidence, getEvidenceConfig])

  if (isAutomated || !mimeType || isViewable(mimeType)) return <></>

  const downloadFile = async (): Promise<void> => {
    if (body) {
      const material = documentBodyAsFile(body, caption, mimeType)
      FileSaver.saveAs(material)
    }
  }
  const isLink = mimeType === MIME_TYPE.TEXT_URI_LIST

  if (evidenceId !== evidence.getId()) {
    return <TableListLoader />
  }

  return (
    <>
      {body ? (
        <ManualEvidenceContainer>
          <ShowPageSectionHeader> EVIDENCE </ShowPageSectionHeader>
          {isFile ? (
            <EvidenceFileInfoContainer onClick={downloadFile}>
              {file ? (
                <>
                  <FileTypeIcon />
                  <EvidenceFileName>{file.name}</EvidenceFileName>
                </>
              ) : (
                <Spinner size="m" color="primary" />
              )}
            </EvidenceFileInfoContainer>
          ) : (
            <ManualEvidenceBody body={body as string} isLink={isLink} />
          )}
          <EvidenceDocumentFooter
            evidenceId={evidence.getId()}
            contentId={contentId}
            mime={mimeType}
            caption={caption}
            body={body as Uint8Array}
            isAutomated={isAutomated}
          />
        </ManualEvidenceContainer>
      ) : (
        <TableListLoader />
      )}
    </>
  )
}

export const EvidenceRelevantDateButton = ({
  evidenceId,
  relevantDate,
  caption,
  contentId,
  discoveryId,
  isEvidenceGroup,
}: {
  evidenceId: string
  relevantDate: Timestamp
  caption: string
  contentId: string
  discoveryId?: string
  isEvidenceGroup?: boolean
}): JSX.Element => {
  const { openModal } = useModalState()
  const { setEvidenceId } = useCurrentEvidenceId()
  const { setCurrentEvidenceGroup } = useCurrentEvidenceGroup()
  const { data, error, isLoading } = useEvidenceGroup(
    caption,
    contentId,
    discoveryId,
    isEvidenceGroup,
  )

  if (isEvidenceGroup && isLoading) {
    return <Spinner size="s" color="primary" />
  }
  if (isEvidenceGroup && !data) {
    if (error) {
      log.error('Error loading evidence group', error)
    }
    return <></>
  }

  const evidenceGroup = data?.getEvidenceGroup()

  const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    if (isEvidenceGroup && evidenceGroup) {
      setCurrentEvidenceGroup(evidenceGroup)
    }
    setEvidenceId(evidenceId)
    openModal(
      isEvidenceGroup
        ? ModalFormId.EVIDENCE_GROUP_RELEVANT_DATE
        : ModalFormId.RELEVANT_DATE,
    )
  }

  return (
    <RelevantDateButton
      buttonSize={StandardButtonSize.SMALL}
      variant={StandardButtonVariant.SECONDARY}
      onClick={onClick}
    >
      {formatTimestamp(relevantDate, DATE_FORMATS.ISO_WITH_TIME)}
    </RelevantDateButton>
  )
}

export const ViewEvidenceDetails = ({
  caption,
  contentId,
  discoveryId,
  controlId,
  requestId,
  total,
}: {
  caption: string
  contentId: string
  discoveryId?: string
  controlId: string
  requestId: string
  total: number
}): JSX.Element => {
  const navigate = useNavigate()
  const evidenceLink = useEvidenceLink(
    caption,
    contentId,
    discoveryId,
    controlId,
    requestId,
    !!total,
  )

  const onViewDetails = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      navigate(evidenceLink)
    },
    [navigate, evidenceLink],
  )

  return (
    <Dropdown.Item eventKey="1" as="button" onClick={onViewDetails}>
      View Details
    </Dropdown.Item>
  )
}

export const CopyEvidenceLink = ({
  caption,
  contentId,
  discoveryId,
  controlId,
  requestId,
  total,
}: {
  caption: string
  contentId: string
  discoveryId?: string
  controlId: string
  requestId: string
  total: number
}): JSX.Element => {
  const evidenceLink = useEvidenceLink(
    caption,
    contentId,
    discoveryId,
    controlId,
    requestId,
    !!total,
  )
  const onCopyLink = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()
      await navigator.clipboard.writeText(evidenceLink)
    },
    [evidenceLink],
  )
  return (
    <Dropdown.Item eventKey="6" as="button" onClick={onCopyLink}>
      Copy Permalink
    </Dropdown.Item>
  )
}

export const ViewActivities = ({
  evidenceId,
}: {
  evidenceId: string
}): JSX.Element => {
  const { setInfoPanelState } = useContext(InfoPanelContext)

  const onViewActivities = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setInfoPanelState({
        isShown: true,
        render: <Changelog subjectIds={[evidenceId]} />,
      })
    },
    [evidenceId, setInfoPanelState],
  )

  return (
    <Dropdown.Item eventKey="7" as="button" onClick={onViewActivities}>
      View Activities
    </Dropdown.Item>
  )
}

export const UnlinkEvidence = ({
  caption,
  contentId,
  linkageId,
  linkageType,
  discoveryId,
  total,
}: {
  caption: string
  contentId: string
  linkageId: string
  linkageType: EVIDENCE_LINKAGE_TYPE
  discoveryId?: string
  total: number
}): JSX.Element => {
  const mutateFunc = useHardEvidenceInvalidation()
  const unlinkEvidence = useUnlinkEvidence({
    caption,
    contentId,
    discoveryId,
    linkageId,
    linkageType,
    total,
    isEvidence: true,
    mutateFunc,
  })
  return (
    <Dropdown.Item
      eventKey="8"
      as="button"
      onClick={(e) => {
        e.preventDefault()
        unlinkEvidence()
      }}
    >
      Unlink Evidence
    </Dropdown.Item>
  )
}

export const EvidenceRowMenu = ({
  evidenceId,
  controlId,
  requestId,
  caption,
  contentId,
  total,
  discoveryId,
}: {
  evidenceId: string
  controlId: string
  requestId: string
  caption: string
  contentId: string
  total: number
  discoveryId?: string
}): JSX.Element => {
  const hasPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])
  const linkageId = controlId || requestId
  const linkageType = controlId
    ? EVIDENCE_LINKAGE_TYPE.CONTROL_LINK
    : EVIDENCE_LINKAGE_TYPE.REQUEST_LINK

  if (!hasPermissions) return <></>

  return (
    <Dropdown id={`evidence-row-menu-${evidenceId}`}>
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ViewEvidenceDetails
          caption={caption}
          contentId={contentId}
          discoveryId={discoveryId}
          controlId={controlId}
          requestId={requestId}
          total={total}
        />
        <UnlinkEvidence
          caption={caption}
          contentId={contentId}
          discoveryId={discoveryId}
          linkageId={linkageId}
          linkageType={linkageType}
          total={total}
        />
        <CopyEvidenceLink
          caption={caption}
          contentId={contentId}
          discoveryId={discoveryId}
          controlId={controlId}
          requestId={requestId}
          total={total}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const UnlinkControl = ({
  caption,
  contentId,
  discoveryId,
  control,
}: {
  caption: string
  contentId: string
  discoveryId: string
  control: Control.AsObject
}): JSX.Element => {
  const mutate = useGrpcRevalidateByMethod()
  const mutateFunc = async (): Promise<void> => {
    await mutate(AttachmentPromiseClient.prototype.getEvidenceGroupControlIds)
  }
  const unlinkControl = useUnlinkEvidence({
    caption: caption,
    contentId: contentId,
    discoveryId: discoveryId,
    linkageId: control.id,
    linkageType: EVIDENCE_LINKAGE_TYPE.CONTROL_LINK,
    linkageName: `${control.modelId} ${control.name}`,
    mutateFunc,
  })

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    unlinkControl()
  }

  return (
    <Dropdown.Item eventKey="2" as="button" onClick={onClick}>
      Unlink Control
    </Dropdown.Item>
  )
}

export const EvidenceControlsListItemMenu = ({
  control,
}: {
  control: Control.AsObject
}): JSX.Element => {
  const { currentEvidenceGroup } = useCurrentEvidenceGroup()
  const hasPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])
  const { setCurrentControl } = useCurrentControl()
  const setCurrentControlHandler = () => {
    setCurrentControl(control)
  }

  if (!hasPermissions || !currentEvidenceGroup) {
    return <></>
  }

  const caption = currentEvidenceGroup.getCaption()
  const contentId = currentEvidenceGroup.getContentId()
  const discoveryId = currentEvidenceGroup.getDiscoveryId()

  return (
    <Dropdown id={`controls-index-menu-${control.id}`}>
      <Dropdown.Toggle
        as={DropdownToggleButton}
        onClickFunc={setCurrentControlHandler}
      >
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ViewDetails />
        <UnlinkControl
          caption={caption}
          contentId={contentId}
          discoveryId={discoveryId}
          control={control}
        />
        <CopyLink />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const EvidenceChangeLogButton = ({
  caption,
  contentId,
  discoveryId,
}: {
  caption: string
  contentId: string
  discoveryId?: string
}): JSX.Element => {
  const { data, isLoading, error } = useEvidenceGroupDocIds({
    caption,
    contentId,
    discoveryId,
  })

  if (isLoading) {
    return <></>
  } else if (!data) {
    if (error) {
      log.error('Error loading evidence group doc ids', error)
    }
    return <></>
  }
  const docIds = data.getDocumentIdsList()
  return <ChangelogButton subjectIds={docIds} />
}
