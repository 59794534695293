import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import log from 'loglevel'
import { RiskServicePromiseClient } from '@trustero/trustero-api-web/lib/risk/risk_grpc_web_pb'
import {
  CreateOrUpdateRisksRequest,
  DeleteRisksRequest,
  GetRisksRequest,
  GetRisksResponse,
  ToggleRiskLinksRequest,
  RISK_LINK_TYPE,
  RiskLink,
  Risk,
} from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { SwrGrpcResponse } from 'src/components/async/useSwrGrpc'
import { useGrpcRevalidateByMethod } from 'src/components/async'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import FileSaver from 'file-saver'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useAuthorizedGrpcClient } from '../../adapter/grpcClient'
import {
  getRisksFilterRequest,
  useFilterControlIdsByAudit,
} from './risks.helpers'

export const useInvalidateRisksCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await Promise.all([
        mutateFunc(RiskServicePromiseClient.prototype.getRisks),
      ])
    } catch (error) {
      log.error('Error when invalidating controls cache', error)
    }
  }, [mutateFunc])
}

export const useRisks = (
  request: GetRisksRequest = new GetRisksRequest(),
  shouldFetch = true,
  config?: SwrGrpcResponse,
): GrpcResponse<GetRisksResponse> => {
  const { response } = useSwrImmutableGrpc(
    RiskServicePromiseClient.prototype.getRisks,
    request,
    shouldFetch,
    config,
  )

  useFilterControlIdsByAudit(response.data?.getRisksList())
  return NewGrpcResponse(response)
}

// TODO: Come back and clean this up to use instant lookup
/**
 * Fetches a single risk from the cache.
 * @param {string} riskId - The ID of the risk to fetch.
 * @returns {Risk | null | false} The risk response, or null if it is still loading, or false if it was not found.
 */
export const useRisk = (riskId: string): Risk | null | false => {
  const { data, isLoading } = useRisks(new GetRisksRequest(), true, {
    ignoreAuditContext: true,
  })

  if (isLoading || !data) {
    return null
  }

  return (
    data.getRisksList().find((risk: Risk) => {
      return risk.getId() === riskId || risk.getCustomId() === riskId
    }) || false
  )
}

export const useCreateOrUpdateRisks = (
  isBulk?: boolean,
  shouldMutate = true,
): ((risks: Risk[]) => Promise<void>) => {
  const { track, events } = useAnalytics()
  const mutator = useInvalidateRisksCache()
  const riskClient = useAuthorizedGrpcClient(RiskServicePromiseClient)

  const createOrUpdateRisk = async (risks: Risk[]) => {
    const req = new CreateOrUpdateRisksRequest().setRisksList(risks)
    await riskClient.createOrUpdateRisks(req)
    shouldMutate && (await mutator())
    // Check if Create or Update & fire an event to track user attempting to create a risk
    const eventKey = risks[0].getId() ? events.UPDATE_RISK : events.CREATE_RISK
    const eventProps =
      eventKey === events.CREATE_RISK ? { type: isBulk ? 'csv' : 'manual' } : {}
    track(eventKey, eventProps)
    return
  }
  return createOrUpdateRisk
}

export const useDeleteRisks = (): ((riskIds: string[]) => Promise<void>) => {
  const { track, events } = useAnalytics()
  const mutator = useInvalidateRisksCache()
  const riskClient = useAuthorizedGrpcClient(RiskServicePromiseClient)

  const deleteRisks = async (riskIds: string[]) => {
    const req = new DeleteRisksRequest().setRiskIdsList(riskIds)
    await riskClient.deleteRisks(req)
    await mutator()
    track(events.DELETE_RISK)
    return
  }
  return deleteRisks
}

export const useDownloadRiskCSV = (): (() => Promise<void>) => {
  const riskClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  return async () => {
    try {
      const res = await riskClient.getRiskRegisterCSVDownload(new Empty())
      const csv = res.getCsv()
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      await FileSaver.saveAs(blob, 'risk-register.csv')
    } catch (err) {
      log.error('Error downloading risk register csv', err)
      showInfoToast('Error downloading risk register csv. Please try again.')
    }
  }
}

/**
 * If you are using this, add a try catch in the parent component to handle errors!!!
 */
export const useToggleLinks = (): (({
  links,
  type,
}: {
  links: RiskLink[]
  type: RISK_LINK_TYPE
}) => Promise<Empty>) => {
  const { track, events } = useAnalytics()
  const mutator = useInvalidateRisksCache()
  const riskClient = useAuthorizedGrpcClient(RiskServicePromiseClient)

  return async ({
    links,
    type,
  }: {
    links: RiskLink[]
    type: RISK_LINK_TYPE
  }) => {
    const req = new ToggleRiskLinksRequest().setLinksList(links).setType(type)
    const res = await riskClient.toggleRiskLinks(req)
    await mutator()
    const eventKey =
      type === RISK_LINK_TYPE.LINK ? events.LINK_CONTROL : events.UNLINK_CONTROL
    track(eventKey)
    return res
  }
}

export const useRisksRequest = (): GetRisksRequest => {
  const location = useLocation()

  return useMemo(
    () => getRisksFilterRequest(location.search),
    [location.search],
  )
}
