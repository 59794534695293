import React from 'react'
import { PoliciesSVG } from 'src/components/Icons/Basic'
import { CheckBoxInput } from 'src/components/Reusable/Inputs/CheckBox'
import {
  PinkInfoBox,
  PinkInfoBoxBodyColumn,
  PinkInfoBoxHeader,
} from 'src/components/Reusable/Text/PinkInfoBox.styles'
import { ModalDescription } from 'src/Modal/styles'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'

// TODO [AP-7701]: Update KB article link
export const PolicyResponsibilityDescription = (): JSX.Element => (
  <ModalDescription>
    Read more about determining policy responsibility in our{' '}
    <a href={HUBSPOT.POLICY_RESPONSIBILITY} target="_blank" rel="noreferrer">
      Knowledge Base.
    </a>
  </ModalDescription>
)

export const PolicyResponsibilityControlsSection = ({
  updateControls,
  setUpdateControls,
}: {
  updateControls: boolean
  setUpdateControls: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => (
  <>
    <PinkInfoBox>
      <PinkInfoBoxHeader>
        <PoliciesSVG width="16px" height="16px" />
        Make sure your controls are up to date
      </PinkInfoBoxHeader>
      <PinkInfoBoxBodyColumn>
        <p>
          Marking a policy not applicable means that the controls in scope for
          the policy should also be marked not applicable. If you have controls
          that are not applicable, make sure to update them, as well.
        </p>
        <p>
          NOTE: The status of these controls will also be updated to &quot;Not
          Applicable&quot;.
        </p>
      </PinkInfoBoxBodyColumn>
    </PinkInfoBox>
    <CheckBoxInput
      id="update-policy-controls"
      label="Update responsibility for in-scope controls"
      checked={updateControls}
      onChange={() => {
        setUpdateControls((prev) => !prev)
      }}
    />
  </>
)
