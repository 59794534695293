import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import log from 'loglevel'
import { UserPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { GetRequest } from '@trustero/trustero-api-web/lib/account/account_pb'
import { GoogleAnalyticsService } from '../analytics/googleAnalyticsService'
import { EnvConfig } from '../config/config'
import { useAuthorizedGrpcClient } from '../adapter'
import { AuthContext } from './authContext'

const logger = log.getLogger('context')

export const AnalyticsContext = createContext<{
  analyticsService?: GoogleAnalyticsService
}>({
  analyticsService: undefined,
})

interface AnalyticsProviderProps {
  children: JSX.Element
}

export const AnalyticsProvider = ({
  children,
}: AnalyticsProviderProps): JSX.Element => {
  const trackerId = EnvConfig.gaTrackerId
  const [analyticsService, setService] = useState<GoogleAnalyticsService>()
  const location = useLocation()
  const { authCtx } = useContext(AuthContext)
  const UserClient = useAuthorizedGrpcClient(UserPromiseClient)
  const isSignedIn = authCtx.isAuthenticated
  const [wasSignedIn, toggleSignedOut] = useReducer(
    (p: boolean) => !p,
    isSignedIn,
  )

  // init service
  useEffect(() => {
    if (!trackerId) {
      return
    }
    if (analyticsService) {
      return
    }

    const newService = new GoogleAnalyticsService(trackerId)
    newService.init()
    setService(newService)
  }, [analyticsService, trackerId])

  // Update userId when user signs in
  useEffect(() => {
    if (!analyticsService) {
      return
    }
    if ((wasSignedIn && isSignedIn) || (!wasSignedIn && !isSignedIn)) {
      return
    }
    const updateUserId = async () => {
      try {
        const user = await UserClient.get(
          new GetRequest().setEmail(authCtx.email),
        )
        if (user) analyticsService?.updateUser(user.getId())
      } catch (exception) {
        logger.error('error fetching user', exception)
      }
      toggleSignedOut()
    }
    updateUserId()
  }, [wasSignedIn, isSignedIn, analyticsService, UserClient, authCtx.email])

  useEffect(
    () => analyticsService?.pageView(location.pathname),
    [analyticsService, location],
  )

  if (!trackerId) {
    return <>{children}</>
  }

  return (
    <AnalyticsContext.Provider value={{ analyticsService }}>
      {children}
    </AnalyticsContext.Provider>
  )
}
