import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { AuthContext } from '../context/authContext'
import AuthAdapter, { getOAuthUrl } from '../adapter/AuthAdapter'
import { AttemptLogin } from '../pages/Login/AttemptLogin'

const signupValidation = Yup.object().shape({
  email: Yup.string().email().required('Email is a required field.'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/(?=.*[0-9])/, 'Password must contain a number.'),
})

const Register = (props) => {
  const { authDispatch } = useContext(AuthContext)

  const handleSubmit = (
    values,
    { setSubmitting, setFieldError, setStatus },
  ) => {
    setStatus('')
    AuthAdapter.register(
      authDispatch,
      values.domain,
      values.email,
      values.password,
    )
      .then(({ success, callid, errors }) => {
        setSubmitting(false)
        if (success) {
          props.callback('success', callid)
        } else {
          Object.entries(errors).map(([field, msg]) =>
            setFieldError(field, msg),
          )
        }
      })
      .catch((err) => {
        setSubmitting(false)
        setStatus(err.message)
      })
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <Formik
          initialValues={{ domain: '', email: '', password: '' }}
          validationSchema={signupValidation}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="email"
                  className="form-control"
                  autoComplete="current-email"
                />
                <ErrorMessage name="email">
                  {(msg) => <div className="alert alert-danger">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  name="password"
                  placeholder="password"
                  className="form-control"
                  autoComplete="current-password"
                />
                <ErrorMessage name="password">
                  {(msg) => <div className="alert alert-danger">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm"> </span>
                  )}
                  <span>Sign Up</span>
                </button>
              </div>
              <div className="form-group">
                {status && <div className="alert alert-danger">{status}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="card card-container">
        <div className="form-group">Or</div>
        <div className="form-group">
          <a href={getOAuthUrl('/auth/oauth2/signup/google')}>
            Signup with Google
          </a>
        </div>
        <div className="form-group">
          <AttemptLogin />
        </div>
      </div>
    </div>
  )
}

Register.propTypes = {
  callback: PropTypes.func,
}

export default Register
