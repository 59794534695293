import {
  DOCUMENT_REQUEST_STATUS,
  REQUEST_SORT_COLUMN,
} from '@trustero/trustero-api-web/lib/request/request_pb'
import { GridColumnSortRequests } from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'

export enum DocumentRequestStatusLabels {
  OPEN = 'Open',
  READY = 'Ready',
  ACCEPTED = 'Accepted',
  RETURNED = 'Returned',
}

export const DOCUMENT_REQUEST_STATUS_TO_LABEL: Record<number, string> = {
  [DOCUMENT_REQUEST_STATUS.OPEN]: DocumentRequestStatusLabels.OPEN,
  [DOCUMENT_REQUEST_STATUS.READY]: DocumentRequestStatusLabels.READY,
  [DOCUMENT_REQUEST_STATUS.ACCEPTED]: DocumentRequestStatusLabels.ACCEPTED,
  [DOCUMENT_REQUEST_STATUS.RETURNED]: DocumentRequestStatusLabels.RETURNED,
}

export const DOCUMENT_REQUEST_LABEL_TO_STATUS: Record<
  DocumentRequestStatusLabels,
  DOCUMENT_REQUEST_STATUS
> = {
  [DocumentRequestStatusLabels.OPEN]: DOCUMENT_REQUEST_STATUS.OPEN,
  [DocumentRequestStatusLabels.READY]: DOCUMENT_REQUEST_STATUS.READY,
  [DocumentRequestStatusLabels.ACCEPTED]: DOCUMENT_REQUEST_STATUS.ACCEPTED,
  [DocumentRequestStatusLabels.RETURNED]: DOCUMENT_REQUEST_STATUS.RETURNED,
}
export const REQUEST_STATUS_FILTER = [
  {
    value: DocumentRequestStatusLabels.OPEN,
    label: DocumentRequestStatusLabels.OPEN,
  },
  {
    value: DocumentRequestStatusLabels.READY,
    label: DocumentRequestStatusLabels.READY,
  },
  {
    value: DocumentRequestStatusLabels.ACCEPTED,
    label: DocumentRequestStatusLabels.ACCEPTED,
  },
  {
    value: DocumentRequestStatusLabels.RETURNED,
    label: DocumentRequestStatusLabels.RETURNED,
  },
]

export const REQUEST_SORT_COLUMNS = Object.freeze({
  [GridColumnSortRequests.EVIDENCE]: REQUEST_SORT_COLUMN.EVIDENCE,
  [GridColumnSortRequests.CONTROLS]: REQUEST_SORT_COLUMN.CONTROLS,
})
