import themeGet from '@styled-system/theme-get'
import { TypeButtonBase } from 'src/components/ModalForms/FileType/TypeSelector.styles'
import styled from 'styled-components/macro'
import { TextButton } from '../../Buttons/TextButton'

export const FileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
`

export const StyledFileClearButton = styled(TextButton).attrs({
  className: 'file-clear-button',
})<{ showClear: boolean }>`
  ${({ showClear }) => !showClear && 'visibility: hidden;'}
  margin: auto;
`

export const FileContainer = styled(TypeButtonBase)<{
  isDragOver: boolean
}>`
  padding: 46px;
  width: 100%;

  ${({ isDragOver }) =>
    isDragOver ? `border: 2px dashed black !important;` : undefined};
`

export const FileDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const FileName = styled.p`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
`
export const FileSize = styled.p`
  margin-top: 10px;
  font-size: 12px;
`

export const FileSelectorText = styled.div`
  color: ${themeGet('colors.text.icon.default')};
  font-weight: 400;
  font-size: 14px;
`
