import styled from 'styled-components/macro'
import themeGet from '@styled-system/theme-get'
import palette from '../../../designSystem/variables/palette'
import { GridColumn } from '../../../components/Reusable/Grid'

export const RadioButtonColumn = styled(GridColumn)`
  font-size: 16px;
  padding: 16px 32px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const RequestColumn = styled(GridColumn)`
  font-size: 16px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const EditableRequestColumn = styled(GridColumn)`
  font-size: 16px;
  padding: 16px 0;
  width: 100%;
`

export const StyledPShowPage = styled.p`
  word-break: break-word;
  max-width: 100%;
`

export const AuditName = styled.p`
  font-size: 12px;
  color: ${themeGet('colors.text.med')};
  margin-top: 4px;
`

export const DateLabel = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${palette.neutral['400']};
`

export const GridColumnWithFlex = styled(GridColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RequestOwnerColumnWithPadding = styled(GridColumn)`
  padding-right: 32px;
`

export const EditOnClickContainer = styled.div`
  width: 100%;
`
