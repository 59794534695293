import React, { useEffect } from 'react'

import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { PageContainer } from '../../../components/PageLayout/PageLayout.styles'
import { useInAudit } from '../../../context/AuditContext'
import { showInfoToast } from '../../../Utils/helpers/toast'
import { ROOT_ID_PARAM_TYPE } from './RootPage.constants'

type RootPageProps = {
  idParamType?: ROOT_ID_PARAM_TYPE
  indexRoute: string
  indexPage: JSX.Element
  showRoute: string
  showPage: JSX.Element
  onlyInAudit: boolean
  isGateKeeperEnabled: boolean
  notAvailableString: string
  routeDisabled?: boolean
}

export const RootPage = ({
  idParamType,
  indexRoute,
  indexPage,
  showRoute,
  showPage,
  onlyInAudit,
  isGateKeeperEnabled,
  notAvailableString,
  routeDisabled = false,
}: RootPageProps): JSX.Element => {
  const navigate = useNavigate()
  const { auditId } = useInAudit()
  const params = useParams()

  useEffect(() => {
    if (!isGateKeeperEnabled || routeDisabled) {
      navigate(`/${params.pageContext}/dashboard`)
    }
    if (onlyInAudit && !auditId) {
      showInfoToast(notAvailableString)
      navigate(`/${params.pageContext}/dashboard`)
    }
  }, [
    auditId,
    navigate,
    params.pageContext,
    isGateKeeperEnabled,
    onlyInAudit,
    notAvailableString,
    routeDisabled,
  ])

  if (onlyInAudit && !auditId) {
    return <></>
  }

  const showPath = idParamType
    ? `${showRoute}/:${idParamType}/*`
    : `${showRoute}/*`

  return (
    <PageContainer>
      <Routes>
        <Route path={indexRoute} element={indexPage} />
        <Route path={showPath} element={showPage} />
        <Route
          path="*"
          element={<Navigate replace to={`${indexRoute}?dismissed[]=false`} />}
        />
      </Routes>
    </PageContainer>
  )
}

RootPage.defaultProps = {
  onlyInAudit: false,
  notAvailableString: '',
  isGateKeeperEnabled: true,
}
