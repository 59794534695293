import React from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { OwnerAssignmentButton as ReusableOwnerAssignmentButton } from '../../../components/Reusable/Buttons'
import { ControlAndMutatorType } from '../ControlsShowPage/ControlsShowPage.constants'

export const OwnerAssignmentButton = ({
  control: { modelId, ownerEmail, isDismissed },
  mutate,
  id, // Used for Risks and future models
}: ControlAndMutatorType): JSX.Element => (
  <ReusableOwnerAssignmentButton
    modelId={id || modelId}
    email={ownerEmail}
    isDisabled={isDismissed}
    onUpdate={mutate}
    modelType={MODEL_TYPE.CONTROL}
  />
)
