import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import {
  AttemptEmailSignupRequest,
  AttemptEmailSignupResponse,
  ReceiveCognitoVerificationCodeRequest,
  ReceiveCognitoVerificationCodeResponse,
  ResendVerificationCodeRequest,
  ResendVerificationCodeResponse,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { useCallback } from 'react'
import { NTRCE_API_URL } from 'src/adapter/gRpcAdapter'

export const useAttemptEmailSignup = (): ((
  email: string,
) => Promise<AttemptEmailSignupResponse>) => {
  return useCallback(
    async (email: string): Promise<AttemptEmailSignupResponse> => {
      const client = new AccountPromiseClient(NTRCE_API_URL)
      const request = new AttemptEmailSignupRequest().setEmail(email)
      const res = await client.attemptEmailSignup(request)
      return res
    },
    [],
  )
}

type VerificationAttempt = {
  email: string
  code: string
}

export const useReceiveCognitoVerificationCode = (): (({
  email,
  code,
}: VerificationAttempt) => Promise<ReceiveCognitoVerificationCodeResponse>) => {
  return useCallback(
    async ({
      email,
      code,
    }): Promise<ReceiveCognitoVerificationCodeResponse> => {
      const client = new AccountPromiseClient(NTRCE_API_URL)
      const request = new ReceiveCognitoVerificationCodeRequest()
        .setEmail(email)
        .setVerificationCode(code)
      const res = await client.receiveCognitoVerificationCode(request)
      return res
    },
    [],
  )
}

export const useResendVerificationCode = (): ((
  email: string,
) => Promise<ResendVerificationCodeResponse>) => {
  return useCallback(
    async (email: string): Promise<ResendVerificationCodeResponse> => {
      const client = new AccountPromiseClient(NTRCE_API_URL)
      const request = new ResendVerificationCodeRequest().setEmail(email)
      const res = await client.resendCognitoVerificationCode(request)
      return res
    },
    [],
  )
}
