/**
 * @fileoverview gRPC-Web generated client stub for roadmap
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: roadmap/roadmap.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var service_service_pb = require('../service/service_pb.js')
const proto = {};
proto.roadmap = require('./roadmap_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.roadmap.RoadmapServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.roadmap.RoadmapServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.roadmap.GetRoadmapWidgetCountsRequest,
 *   !proto.roadmap.GetRoadmapWidgetCountsResponse>}
 */
const methodDescriptor_RoadmapService_GetRoadmapWidgetCounts = new grpc.web.MethodDescriptor(
  '/roadmap.RoadmapService/GetRoadmapWidgetCounts',
  grpc.web.MethodType.UNARY,
  proto.roadmap.GetRoadmapWidgetCountsRequest,
  proto.roadmap.GetRoadmapWidgetCountsResponse,
  /**
   * @param {!proto.roadmap.GetRoadmapWidgetCountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.roadmap.GetRoadmapWidgetCountsResponse.deserializeBinary
);


/**
 * @param {!proto.roadmap.GetRoadmapWidgetCountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.roadmap.GetRoadmapWidgetCountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.roadmap.GetRoadmapWidgetCountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.roadmap.RoadmapServiceClient.prototype.getRoadmapWidgetCounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/roadmap.RoadmapService/GetRoadmapWidgetCounts',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetRoadmapWidgetCounts,
      callback);
};


/**
 * @param {!proto.roadmap.GetRoadmapWidgetCountsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.roadmap.GetRoadmapWidgetCountsResponse>}
 *     Promise that resolves to the response
 */
proto.roadmap.RoadmapServicePromiseClient.prototype.getRoadmapWidgetCounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/roadmap.RoadmapService/GetRoadmapWidgetCounts',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetRoadmapWidgetCounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.roadmap.GetInitialScopingRequest,
 *   !proto.roadmap.GetInitialScopingResponse>}
 */
const methodDescriptor_RoadmapService_GetInitialScoping = new grpc.web.MethodDescriptor(
  '/roadmap.RoadmapService/GetInitialScoping',
  grpc.web.MethodType.UNARY,
  proto.roadmap.GetInitialScopingRequest,
  proto.roadmap.GetInitialScopingResponse,
  /**
   * @param {!proto.roadmap.GetInitialScopingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.roadmap.GetInitialScopingResponse.deserializeBinary
);


/**
 * @param {!proto.roadmap.GetInitialScopingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.roadmap.GetInitialScopingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.roadmap.GetInitialScopingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.roadmap.RoadmapServiceClient.prototype.getInitialScoping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/roadmap.RoadmapService/GetInitialScoping',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetInitialScoping,
      callback);
};


/**
 * @param {!proto.roadmap.GetInitialScopingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.roadmap.GetInitialScopingResponse>}
 *     Promise that resolves to the response
 */
proto.roadmap.RoadmapServicePromiseClient.prototype.getInitialScoping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/roadmap.RoadmapService/GetInitialScoping',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetInitialScoping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.roadmap.GetOrCreateComplianceRoadmapRequest,
 *   !proto.roadmap.GetOrCreateComplianceRoadmapResponse>}
 */
const methodDescriptor_RoadmapService_GetOrCreateComplianceRoadmap = new grpc.web.MethodDescriptor(
  '/roadmap.RoadmapService/GetOrCreateComplianceRoadmap',
  grpc.web.MethodType.UNARY,
  proto.roadmap.GetOrCreateComplianceRoadmapRequest,
  proto.roadmap.GetOrCreateComplianceRoadmapResponse,
  /**
   * @param {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.roadmap.GetOrCreateComplianceRoadmapResponse.deserializeBinary
);


/**
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.roadmap.GetOrCreateComplianceRoadmapResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.roadmap.GetOrCreateComplianceRoadmapResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.roadmap.RoadmapServiceClient.prototype.getOrCreateComplianceRoadmap =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/roadmap.RoadmapService/GetOrCreateComplianceRoadmap',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetOrCreateComplianceRoadmap,
      callback);
};


/**
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.roadmap.GetOrCreateComplianceRoadmapResponse>}
 *     Promise that resolves to the response
 */
proto.roadmap.RoadmapServicePromiseClient.prototype.getOrCreateComplianceRoadmap =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/roadmap.RoadmapService/GetOrCreateComplianceRoadmap',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetOrCreateComplianceRoadmap);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.roadmap.ToggleRequiredDocumentsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_RoadmapService_ToggleRequiredDocuments = new grpc.web.MethodDescriptor(
  '/roadmap.RoadmapService/ToggleRequiredDocuments',
  grpc.web.MethodType.UNARY,
  proto.roadmap.ToggleRequiredDocumentsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.roadmap.ToggleRequiredDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.roadmap.ToggleRequiredDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.roadmap.RoadmapServiceClient.prototype.toggleRequiredDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/roadmap.RoadmapService/ToggleRequiredDocuments',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_ToggleRequiredDocuments,
      callback);
};


/**
 * @param {!proto.roadmap.ToggleRequiredDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.roadmap.RoadmapServicePromiseClient.prototype.toggleRequiredDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/roadmap.RoadmapService/ToggleRequiredDocuments',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_ToggleRequiredDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.roadmap.GetLiveAuditRunsResponse>}
 */
const methodDescriptor_RoadmapService_GetLiveAuditRuns = new grpc.web.MethodDescriptor(
  '/roadmap.RoadmapService/GetLiveAuditRuns',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.roadmap.GetLiveAuditRunsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.roadmap.GetLiveAuditRunsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.roadmap.GetLiveAuditRunsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.roadmap.GetLiveAuditRunsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.roadmap.RoadmapServiceClient.prototype.getLiveAuditRuns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/roadmap.RoadmapService/GetLiveAuditRuns',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetLiveAuditRuns,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.roadmap.GetLiveAuditRunsResponse>}
 *     Promise that resolves to the response
 */
proto.roadmap.RoadmapServicePromiseClient.prototype.getLiveAuditRuns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/roadmap.RoadmapService/GetLiveAuditRuns',
      request,
      metadata || {},
      methodDescriptor_RoadmapService_GetLiveAuditRuns);
};


module.exports = proto.roadmap;

