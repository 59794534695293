import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { useModalState } from 'src/Modal/ModalStateContext'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { DocumentItemMarkdownEditor } from 'src/pages/Policies/PoliciesShowPage/DocumentGrid/DocumentItem/DocumentItemMarkdownEditor'
import {
  PrimaryButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../../Buttons'
import { RteModalQueryParams } from '../../../RichTextEditor'
import { AddDocumentForm } from '../../../ModalForms/Document'
import { ModalFormId } from '../../../ModalForms'
import { FlexAlign, FlexRow } from '../../Flex'
import { TabPanelNoteItem } from './styles'

type Props = {
  name: string
  modelId: string
  template: string
  isNotApplicable: boolean
  onUpdate: () => void
}

export const DocumentTabNote = ({
  isNotApplicable,
  modelId,
  template,
  onUpdate,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { openModal } = useModalState()

  return (
    <TabPanelNoteItem>
      <FlexRow
        gap={10}
        align={FlexAlign.FLEX_START}
        justify={FlexAlign.FLEX_START}
      >
        {template && (
          <>
            <DocumentItemMarkdownEditor
              modelId={modelId}
              title={template.split(/\r?\n/)?.[0]?.replace('# ', '')}
              markdown={template}
              onUpdate={onUpdate}
            />
            <PrimaryButton
              size={StandardButtonSize.SMALL}
              onClick={() => {
                if (isNotApplicable) {
                  openModal(ModalFormId.NA_INFO)
                  return
                }
                navigate(
                  queryString.stringifyUrl(
                    {
                      url: location.pathname,
                      query: {
                        ...queryString.parse(location.search, {
                          arrayFormat: 'bracket',
                        }),
                        [RteModalQueryParams.SHOW_RTE_MODAL]: true,
                        [RteModalQueryParams.UNIQUE_ID]: modelId,
                        [RteModalQueryParams.SHOW_WIZARD]: false,
                      },
                    },
                    {
                      arrayFormat: 'bracket',
                    },
                  ),
                )
              }}
              text="New from Template"
            />
          </>
        )}
        <StandardOpenModalButton
          modalId={
            isNotApplicable ? ModalFormId.NA_INFO : ModalFormId.ADD_DOCUMENT
          }
          text="Add Documentation"
          variant={StandardButtonVariant.PRIMARY}
          size={StandardButtonSize.SMALL}
        />
      </FlexRow>
      <AddDocumentForm policyId={modelId} openOnDrag />
    </TabPanelNoteItem>
  )
}
